export enum LocalStorage {
  TASKBIRD_SESSION = '@taskbird:session',
  ACCESS_TOKEN = '@taskbird:accessToken',
  ACCOUNT_ID = '@taskbird:accountId',
  COLLAPSE_PROJECTS = '@taskbird:collapseProjects',
  FIRST_DAY = '@taskbird:firstDay',
  LOGGED = '@taskbird:logged',
  PROJECT_IN_PROGRESS = '@taskbird:projectInProgress',
  PROJECT_OFFERS_WARNING = '@taskbird:projectOffersWarning',
  SEND_NOTIFICATION = '@taskbird:sendNotification',
  SEND_NOTIFICATION_TIMESTAMP = '@taskbird:sendNotificationTimeStamp',
  TIME_FORMAT = '@taskbird:timeFormat',
  CALENDAR_VIEW = '@taskbird:calendarView',
  WEEK_VIEW_TYPE = '@taskbird:weekViewType',
  FORGOT_USER_EMAIL = '@taskbird:forgotPasswordEmail',
  GOOGLE_CALENDAR_SELECTION = '@taskbird:googleCalendarSelection',
  USER_CHAT_PROFILE = '@taskbird:userChatProfile',

  // BOOKING CONSTS
  BOOKING_TOKEN = '@booking:token',
  BOOKING_SLUG = '@booking:slug',
  BOOKING_SLUG_UPDATED = '@booking:slug_updated',
  BOOKING_BUSINESS_HASH = '@taskbird:businessId',
  BOOKING_TIMEZONE = '@booking:timezone',
  BOOKING_TIMEZONE_CUSTOMER = '@booking:timezone_customer',

  // CHAT CONSTS
  CHAT_EVENT = '@taskbird:chatEvent',
  BOOKING_BUSINESS_FIRST_ACCESS = '@taskbird:firstAccess',

  CREATE_CUSTOMER_INFO_MESSAGE = '@taskbird:createCustomerInfoMessage',
}
