import { drawersData } from '@recoilData/index';
import { selector } from 'recoil';

const setDrawerData = selector<any>({
  key: 'setDrawerData', //@ts-ignore
  get: ({ get }) => get(drawersData),
  set: ({ get, set }, { drawer = undefined, isOpen = false }) => {
    const drawers = get(drawersData);
    const closingAllDrawers: any = Object.keys(drawers).reduce(
      (drawers, key) => {
        return { ...drawers, [key]: false };
      },
      {}
    );
    isOpen && drawer //@ts-ignore
      ? set(drawersData, { ...closingAllDrawers, [drawer]: true })
      : set(drawersData, { ...closingAllDrawers });
  },
});

const isAnyDrawerOpenData = selector({
  key: 'isAnyDrawerOpenData',
  get: ({ get }) => Object.values(get(drawersData)).includes(true),
});

const getOpenDrawer = selector({
  key: 'getOpenDrawer',
  get: ({ get }) => {
    const drawers = get(drawersData);
    return Object.keys(drawers).filter((key) => drawers[key])[0];
  },
});

export { setDrawerData, isAnyDrawerOpenData, getOpenDrawer };
