import React, { useState, useContext } from "react";
import SearchContext from "./index";

export const SearchProvider: React.FC = ({ children }) => {
  const [term, setTerm] = useState("");
  const [termLoading, setTermLoading] = useState(false);

  return (
    <SearchContext.Provider
      value={{ term, setTerm, termLoading, setTermLoading }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);
