import React, { useState } from 'react';

import { useRecoilValue } from 'recoil';

import {
  TkCrewListItem,
  TkAssigneeListItem,
  TkExtendedDrawerSearch,
} from '@components/ExtendedDrawers';
import {
  TabCustom,
  SearchBox,
  TabsCustom,
  FullWrapper,
  FullWrapperItem,
  FullWrapperIcon,
  TkListWrapperList,
  TkExtendedWrapperTabs,
} from '@components/ExtendedDrawers/styles';
import { TkIcon, TkTypography } from '@components/index';
import { EventTypeEnum } from '@consts/index';
import { FormControl, Grid, FormGroup } from '@material-ui/core';
import {
  editEventAssignees,
  editEventCrews,
  eventAssignees,
  eventCrews,
  searchAssigneesData,
  searchCrewsData,
  selectEditEventCrew,
  selectEventCrew,
} from '@recoilData/index';

interface TkAssigneeListProps {
  eventType: `${EventTypeEnum}`;
}

const TkAssigneeList = ({ eventType }: TkAssigneeListProps): JSX.Element => {
  const [search, setSearch] = useState('');
  const [value, setValue] = useState(0);
  const listFilteredAssignee = useRecoilValue(searchAssigneesData(search));
  const listFilteredCrew = useRecoilValue(searchCrewsData(search));

  const handleTabChange = (newValue: number) => {
    setValue(newValue);
    setSearch('');
  };

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <TkExtendedWrapperTabs
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-force-tabpanel-${index}`}
        aria-labelledby={`scrollable-force-tab-${index}`}
        {...other}
      >
        {value === index && children}
      </TkExtendedWrapperTabs>
    );
  };

  return (
    <>
      <TabsCustom
        value={value}
        onChange={(_, number) => handleTabChange(number)}
        aria-label="ant example"
      >
        <TabCustom
          label={
            <TkTypography
              fontSize={16}
              fontFamily="Lato"
              fontWeight="bold"
              color={value === 0 ? '#4952A9' : '#011F41'}
            >
              Teammates
            </TkTypography>
          }
        />
        <TabCustom
          label={
            <TkTypography
              fontSize={16}
              fontFamily="Lato"
              fontWeight="bold"
              color={value === 1 ? '#4952A9' : '#011F41'}
            >
              Crews
            </TkTypography>
          }
        />
      </TabsCustom>
      <SearchBox container alignItems="flex-end">
        <Grid item>
          <TkIcon className="fa fa-search" color="#4952A9" />
        </Grid>
        <Grid item sm={10}>
          <TkExtendedDrawerSearch search={search} setSearch={setSearch} />
        </Grid>
      </SearchBox>
      {value === 0 ? (
        <TabPanel value={value} index={0}>
          {listFilteredAssignee.length === 0 && !search ? (
            <FullWrapper>
              <FullWrapperItem container justify="center" alignItems="center">
                <FullWrapperIcon
                  className="fal fa-user"
                  iconSize={80}
                  color="#999999"
                />
              </FullWrapperItem>
              <FullWrapperItem container justify="center" alignItems="center">
                <TkTypography
                  fontFamily="Muli"
                  fontSize={16}
                  fontWeight="bold"
                  color="default"
                  textAlign="center"
                >
                  You don’t have any teammates
                </TkTypography>
              </FullWrapperItem>
              <FullWrapperItem container justify="center" alignItems="center">
                <TkTypography
                  fontFamily="Lato"
                  fontSize={14}
                  fontWeight="normal"
                  color="primary"
                  textAlign="center"
                >
                  Use Taskbird’s mobile app to invite some teammates from your
                  company
                </TkTypography>
              </FullWrapperItem>
            </FullWrapper>
          ) : (
            <TkListWrapperList>
              <FormControl component="fieldset">
                <FormGroup>
                  {listFilteredAssignee.map((assignee) => (
                    <TkAssigneeListItem
                      key={assignee.id}
                      assignee={assignee}
                      eventAssigneesRecoilState={
                        eventType === EventTypeEnum.NEW
                          ? eventAssignees
                          : editEventAssignees
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </TkListWrapperList>
          )}
        </TabPanel>
      ) : (
        <TabPanel value={value} index={1}>
          {listFilteredCrew.length === 0 && !search ? (
            <FullWrapper>
              <FullWrapperItem container justify="center" alignItems="center">
                <FullWrapperIcon
                  className="fal fa-user-friends"
                  iconSize={80}
                  color="#999999"
                />
              </FullWrapperItem>
              <FullWrapperItem container justify="center" alignItems="center">
                <TkTypography
                  fontFamily="Muli"
                  fontSize={16}
                  fontWeight="bold"
                  color="default"
                  textAlign="center"
                >
                  You don’t have any crews
                </TkTypography>
              </FullWrapperItem>
              <FullWrapperItem container justify="center" alignItems="center">
                <TkTypography
                  fontFamily="Lato"
                  fontSize={14}
                  fontWeight="normal"
                  color="primary"
                  textAlign="center"
                >
                  Use Taskbird’s mobile app to create your first crew
                </TkTypography>
              </FullWrapperItem>
            </FullWrapper>
          ) : (
            <TkListWrapperList>
              <FormControl component="fieldset">
                <FormGroup>
                  {listFilteredCrew.map((crew) => (
                    <TkCrewListItem
                      key={crew.id}
                      crew={crew}
                      eventCrewsRecoilState={
                        eventType === EventTypeEnum.NEW
                          ? eventCrews
                          : editEventCrews
                      }
                      selectEventCrewRecoilState={
                        eventType === EventTypeEnum.NEW
                          ? selectEventCrew
                          : selectEditEventCrew
                      }
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </TkListWrapperList>
          )}
        </TabPanel>
      )}
    </>
  );
};

export default TkAssigneeList;
