import { Grid } from "@mui/material";
import styled from "styled-components";

export const SendMeTheAppWrapper = styled(Grid)`
  display: flex;

  > span {
    text-align: left;
    font: normal normal normal 14px/14px Lato;
    letter-spacing: 0px;
    color: #011f41;
    opacity: 1;
  }
`;
