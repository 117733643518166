import React from 'react';
import styled from 'styled-components';

const PulsatingWrapper = styled.div`
  display: inline;
  position: relative;
  margin-right: 15px;
`;

const PulsatingCircle = styled.div`
  width: 6px;
  height: 6px;
  background: #1f9bdd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #1592e5;
  border-radius: 50%;
  position: absolute;
  bottom: -3.5px;
  left: 6px;
  z-index: 2;
`;

const PulsatingRing = styled.div`
  border: 3px solid #1f9bdd;
  border-radius: 30px;
  height: 12px;
  width: 12px;
  position: absolute;
  left: 3px;
  bottom: -6.5px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;

  @keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(1, 1);
      opacity: 0;
    }
  }
`;

const TkPulsatingIconSmall: React.FC = () => {
  return (
    <PulsatingWrapper>
      <PulsatingRing />
      <PulsatingCircle />
    </PulsatingWrapper>
  );
};

export default TkPulsatingIconSmall;
