export default {
  profile: 'Profile',
  name: 'Name',
  lastName: 'Last name',
  timezone: 'Time Zone',
  contactEmail: 'Contact e-mail',
  pleaseAddEmail: 'Pleas add an email',
  pleaseAddValidEmail: 'Please add a valid email',
  enterAnEmailAdress: 'Enter an email address',
  tabs: {
    profile: 'Profile',
    notifications: 'Notification settings',
    team: 'Team settings',
  },
  updateProfile: 'Update profile',
  profileUpdated: 'Profile updated successfully',
  profileUpdateError: 'Error updating profile. Please try again later',
  team_settings: {
    visibility: 'Visibility',
    selectWhatInformation:
      'Decide what information you want to share with your teammates and what they can do',
    customerName: 'Customer Name',
    taskSource: 'Task Source',
    teammatesContactInformation: "Teammates's Contact Information",
    availability: 'Availability',
    decideIfTeammatesCanSetTheirAvailability:
      'Decide if teammates can set their availability. You can edit their availability from their profiles in the <icon></icon> </icon><colored>Team</colored> section',
    allowMyTeammatesToSetTheirOwnAvailability:
      'Allow my teammates to set their own availability.',
    autoAssignedTasks: 'Auto-assigned tasks',
    ifATeammateIsNotAvailable: 'If a teammate is not available',
    assignAnywayAndNotifyMeAboutIt: 'Assign anyway and notify me about it',
    doNotAssignAndNotifyMeAboutIt: "Don't assign and notify me about it",
    tooltips: {
      customerName:
        'Display the customer’s name to your teammates on their tasks',
      taskSource:
        'Show your teammates if a task comes from Turno, Booking Site or Google Calendar',
      contactInformation:
        'Allow teammates to see each other’s email and phone number on Taskbird',
    },
  },
  password: 'Password',
  newPassword: 'New password',
  confirmPassword: 'Confirm password',
  enterNewPassword: 'Enter a new password',
  enterANewPassword: 'Repeat the new password',
  confirmPasswordError: 'Passwords do not match',
  company: 'Company',
  companyInformation: 'Company information',
  addYourCompanyName: 'Add your company name',
  pleaseAddYourCompanyName: 'Please add your company name',
  pleaseAddALastName: 'Please add a last name',
};
