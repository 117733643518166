import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import {
  AssigneeButton,
  IconAssigneeButtonHover,
  IconNotAssignedButton,
} from './styles';
import { TkIcon, TkTypography } from '@components/index';

interface TkAssigneeButtonProps {
  handleClick: () => void;
}

const TkAssigneeButton = ({
  handleClick,
}: TkAssigneeButtonProps): JSX.Element => {
  const { t } = useTranslation();
  const [assigneeHover, setAssigneeHover] = useState<boolean>(false);

  if (!assigneeHover) {
    return (
      <AssigneeButton
        container
        onClick={handleClick}
        onMouseEnter={() => setAssigneeHover(true)}
        onMouseLeave={() => setAssigneeHover(false)}
      >
        <IconNotAssignedButton>
          <TkIcon className="fa fa-question" iconSize={19} />
        </IconNotAssignedButton>
        <TkTypography fontWeight="bold" fontFamily="Lato" fontSize={19}>
          {t('unassigned', { ns: 'common' })}
        </TkTypography>
      </AssigneeButton>
    );
  }

  return (
    <AssigneeButton
      container
      onClick={handleClick}
      onMouseEnter={() => setAssigneeHover(true)}
      onMouseLeave={() => setAssigneeHover(false)}
    >
      <IconAssigneeButtonHover>
        <TkIcon className="fa fa-user" iconSize={19} />
      </IconAssigneeButtonHover>
      <TkTypography fontWeight="bold" fontFamily="Lato" fontSize={16}>
        {t('assignTask', { ns: 'events' })}
        <TkIcon
          className="fa fa-chevron-right"
          color="default"
          marginLeft={10}
          verticalAlign="middle"
          fontSize="small"
        />
      </TkTypography>
    </AssigneeButton>
  );
};

export default TkAssigneeButton;
