import styled from 'styled-components';

import { FormControlLabel } from '@mui/material';

import TkTypography from '@components/TkTypography';
import {
  Container,
  Grid,
  withStyles,
  createStyles,
  Tab,
  Tabs,
  Theme,
} from '@material-ui/core';

export const TkCalendarFilterWrapper = withStyles({
  root: {
    display: 'flex',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    flexDirection: 'column',
    padding: '12px 20px',
    position: 'relative',
    minHeight: '93%',
  },
})(Container);

const TkExtendedBase = withStyles({
  root: {
    '*::-webkit-scrollbar': {
      display: 'none',
    },
  },
})(Container);

export const TkExtendedWrapper = styled(TkExtendedBase)`
  display: flex;
  flex: 1;
  ${(props) => (props.maxWidth === 'lg' ? 'width: 408px' : 'width: 282px')};
  ${(props) => (props.maxWidth === 'xl' ? 'width: 583px' : '')};
  padding-top: 1rem;
  padding-right: 1rem;
  flex-direction: column;
  overflow: hidden;
`;

export const TkExtendedWrapperTabs = withStyles({
  root: {
    margin: 0,
  },
})(TkExtendedWrapper);

export const TabCustom = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 141,
      backgroundColor: '#f0f0f0',
    },
    selected: {
      backgroundColor: '#FFFFFF',
    },
  })
)(Tab);

export const TabsCustom = withStyles({
  root: {
    width: '100%',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 0,
      width: '100%',
      backgroundColor: '#ffffff',
    },
  },
})(Tabs);

export const SearchBox = withStyles({
  root: {
    marginLeft: '12px',
    marginRight: '12px',
  },
})(Grid);

export const TkCalendarFilterItem = styled(Grid)`
  padding: 12px 0 12px 0;
  margin-bottom: 12px;
  border-bottom: 1px #c8c7cc solid;

  &:last-child {
    border-bottom: 1px #ffffff solid;
  }
`;

export const TkCalendarFilterItemCheckBox = styled(Grid)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10px;
  width: 100%;
`;

export const TkCalendarFilterItemTitle = styled(Grid)`
  margin-bottom: 12px;
  user-select: none;
`;

export const TkCalendarFilterItemAction = styled(TkCalendarFilterItemTitle)`
  cursor: pointer;
`;

export const TkCalendarFilterItemContent = styled(Grid)``;

export const TkCalendarFilterItemCheckBoxItem = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    margin-right: 0px;
  }
`;

export const TkListWrapper = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    overflowY: 'scroll',
    overflowX: 'hidden',
    scrollbarWidth: 'none',
    padding: '0',
  },
})(Container);

export const TkListItem = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    ${(props) =>
      props.checked
        ? 'border: 0.5px solid #2699FB;'
        : 'border: 1px solid #ffffff;'}
    padding: 5px;
    margin: 2.5px 0;
    user-select: none;
    display: flex;
    width: auto;
    flex-direction: row;

    ${(props) =>
      props.checked
        ? 'background-color: rgba(38,153,251, 0.1) ; border-radius: 2px;'
        : ''}

    .MuiTypography-body1 {
      color: #011f41;
      font-size: 14px;
      font-weight: bold;
      font-family: 'Lato';
    }

    .MuiCheckbox-root {
      padding: 9px 9px 9px 0;
    }
  }
`;

export const TkLocationItemStyle = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    ${(props) =>
      props.checked
        ? 'border: 0.5px solid #2699FB;'
        : 'border: 1px solid #ffffff;'}
    padding: 5px;
    margin: 2.5px 0;
    user-select: none;
    display: flex;
    width: auto;
    flex-direction: row;
    position: relative;

    ${(props) =>
      props.checked
        ? 'background-color: rgba(38,153,251, 0.1) ; border-radius: 2px;'
        : ''}

    .MuiTypography-body1 {
      color: #011f41;
      font-size: 14px;
      font-family: 'Lato';
      margin-left: 15px;
      width: 93%;
    }

    .MuiCheckbox-root {
      position: absolute;
      padding: 2px 9px 9px 0;
      top: 7px;
      left: 5px;
    }
  }
`;

export const OverFlowText = styled.span``;

export const OverFlowTypography = styled(TkTypography)`
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
`;

export const TkSelectAllItem = styled(FormControlLabel)`
  &.MuiFormControlLabel-root {
    padding: 5px 5px 10px 5px;
    margin: 5px 0 5px 0;
    user-select: none;
    display: flex;
    flex: 1;
    flex-direction: row;
    border-bottom: 1px solid #f0f0f0;

    .MuiTypography-body1 {
      color: #4952a9;
      font-size: 14px;
      font-weight: normal;
      font-family: 'Lato';
    }

    .MuiCheckbox-root {
      padding: 9px 9px 9px 0;
    }
  }
`;

export const IconAssigneeButton = styled.div`
  min-width: 36px;
  min-height: 36px;
  border-radius: 50%;
  background-color: #4a4da6;
  border: #4a4da6 1px solid;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: 'Lato';
  font-weight: normal;
  font-size: 16px;
`;

export const UniqueSelectionDiv = styled.div`
  width: '100%';
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ImgAssignee = styled.img`
  margin-right: 10px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
`;

export const TkAssigneeCheckItem = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: '100%';
`;

export const TkTaskActions = withStyles({
  root: {
    borderTop: '1px solid #999999',
    display: 'flex',
    minHeight: '65px',
    backgroundColor: 'white',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})(Container);

export const MoreActionTag = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 5px;
  border-radius: 15px;
  height: 26px;
  font-family: 'Lato';
  font-size: 16px;
  color: #f0f0f0;
  margin: 3px 7px 3px 0px;
  cursor: pointer;
  color: #4952a9;
`;
