import React from 'react';

import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  RecoilState,
} from 'recoil';

import { Grid } from '@mui/material';

import {
  TkListItem,
  TkListItemSubText,
  TkListItemSubTextIcon,
  LocationSpan,
} from '@components/Drawers/TkEditTaskDrawer/styles';
import { TkTypography } from '@components/index';
import { formatAddress } from '@helpers/formatters';
import { useDrawer } from '@hooks/index';
import { customersData } from '@recoilData/index';

interface ITkLocationListItem {
  location: ILocationTask;
  eventLocationRecoilState: RecoilState<ILocationTask | undefined>;
  eventCustomerRecoilState: RecoilState<ICustomerTask | undefined>;
}

const TkLocationListItem = ({
  location,
  eventCustomerRecoilState,
  eventLocationRecoilState,
}: ITkLocationListItem): JSX.Element => {
  const { closeExtDrawer } = useDrawer();
  const customers = useRecoilValue(customersData);
  const setLocation = useSetRecoilState(eventLocationRecoilState);
  const [customer, setCustomer] = useRecoilState(eventCustomerRecoilState);

  const handleLocationSelect = (newLocation: ILocationTask) => {
    if (customer === undefined) {
      const newCustomer = customers.find(
        (customer) => customer.id === newLocation.customerId
      );
      setCustomer(newCustomer);
    }
    setLocation(newLocation);
    closeExtDrawer();
  };

  return (
    <TkListItem
      key={location.id}
      onClick={() => handleLocationSelect(location)}
    >
      <Grid container item alignItems="center">
        <TkTypography fontWeight="bold" fontFamily="Lato" fontSize={16}>
          {location.alias}
        </TkTypography>
      </Grid>
      <TkListItemSubText>
        <TkListItemSubTextIcon
          className="fa fa-map-marker-alt"
          fontSize="small"
          marginRight={2}
        />
        <LocationSpan>{formatAddress(location)}</LocationSpan>
      </TkListItemSubText>
      {location.customerId && (
        <TkListItemSubText>
          <TkListItemSubTextIcon className="fa fa-user-tie" fontSize="small" />
          {location.customerName}
        </TkListItemSubText>
      )}
    </TkListItem>
  );
};

export default TkLocationListItem;
