import styled from 'styled-components';
import { Select, SelectProps } from '@material-ui/core';

const TkSelect = styled(Select)<SelectProps>`
  .MuiSelect-icon {
    color: rgba(0, 0, 0);
  }

  .MuiSelect-select {
    background-color: #ffffff;
    border-color: #ffffff;
    border: none;
  }

  .MuiSelect-select option {
    text-align: left;
    font-family: Lato;
    font-size: 16px;
    letter-spacing: 0px;
    color: #3d4465;
    opacity: 1;
  }

  .MuiSelect-select:not([multiple]) option {
    background-color: #ffffff;
    border-radius: 4px;
    box-shadow: 0px 3px 10px #0000001a;
    border: none;
  }
`;

export default TkSelect;
