import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  IconWrapper,
  IconWrapperAlt,
  TagWrapper,
  TextWrapper,
  ImgWrapper,
  IconWrapperNoBackground,
} from './styles';
import { GoogleCalendar, TurnoverBNB_PNG, MoveOut } from '@assets/index';
import { TkIcon } from '@components/index';

export type ITkTagsType =
  | 'turnoverbnb'
  | 'moveout'
  | 'googleCalendar'
  | 'bookingWidget'
  | 'repeat'
  | 'completed'
  | 'completedTBnB'
  | 'notStarted'
  | 'startedLate'
  | 'finishedLate'
  | 'notCompleted'
  | 'guestArrivesSameDay';

interface ITkTags {
  type: ITkTagsType | string;
  calendarName?: string;
}

const TkTags: React.FC<ITkTags> = ({ type, calendarName }) => {
  const { t } = useTranslation();

  const integrationIcon: {
    [key in ITkTagsType]: {
      icon: JSX.Element;
      text: string | (() => string) | undefined;
    };
  } = {
    moveout: {
      icon: <ImgWrapper src={MoveOut} style={{ margin: '0 -2px 0 0' }} />,
      text: t('moveoutCom', { ns: 'common' }),
    },
    turnoverbnb: {
      icon: (
        <ImgWrapper src={TurnoverBNB_PNG} style={{ margin: '0 -2px 0 0' }} />
      ),
      text: t('turnoverBnB', { ns: 'common' }),
    },
    googleCalendar: {
      icon: <ImgWrapper src={GoogleCalendar} style={{ marginLeft: '4px' }} />,
      text: calendarName,
    },
    bookingWidget: {
      icon: (
        <IconWrapperNoBackground>
          <TkIcon
            className="fa fa-calendar-check"
            marginLeft={4}
            iconSize={14}
            color="#4952A9"
          />
        </IconWrapperNoBackground>
      ),
      text: t('tkTags.bookingWidget'),
    },
    repeat: {
      icon: (
        <IconWrapper>
          <TkIcon className="fa fa-repeat-alt" iconSize={10} />
        </IconWrapper>
      ),
      text: t('tkTags.repeating'),
    },
    completed: {
      icon: (
        <IconWrapper>
          <TkIcon className="fa fa-check" iconSize={10} />
        </IconWrapper>
      ),
      text: t('tkTags.completed'),
    },
    completedTBnB: {
      icon: (
        <IconWrapper>
          <TkIcon className="fa fa-check" iconSize={10} />
        </IconWrapper>
      ),
      text: t('tkTags.completedInTurnoverBnB'),
    },
    notStarted: {
      icon: (
        <IconWrapper>
          <TkIcon className="fa fa-pause" iconSize={10} />
        </IconWrapper>
      ),
      text: t('tkTags.notStarted'),
    },
    startedLate: {
      icon: (
        <IconWrapper>
          <TkIcon className="fa fa-clock" iconSize={10} />
        </IconWrapper>
      ),
      text: t('tkTags.startedLate'),
    },
    finishedLate: {
      icon: (
        <IconWrapper>
          <TkIcon className="fa fa-clock" marginRight={1} iconSize={10} />
        </IconWrapper>
      ),
      text: t('tkTags.finishedLate'),
    },
    notCompleted: {
      icon: (
        <IconWrapper>
          <TkIcon className="fa fa-times" marginLeft={2} iconSize={10} />
        </IconWrapper>
      ),
      text: t('tkTags.notCompleted'),
    },
    guestArrivesSameDay: {
      icon: (
        <IconWrapperAlt color="invert">
          <TkIcon className="fa fa-exchange" iconSize={10} />
        </IconWrapperAlt>
      ),
      text: t('tkTags.sameDayArrival'),
    },
  };

  return (
    <TagWrapper>
      {integrationIcon[type].icon}
      <TextWrapper>{integrationIcon[type].text}</TextWrapper>
    </TagWrapper>
  );
};

export default TkTags;
