import React from 'react';

import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import 'stream-chat-react/dist/css/v2/index.css';

import { Fade } from '@mui/material';

import Router from './router/router';
import { TkSnackbar } from '@components/index';
import { AppContextProvider } from '@contexts/AppContextProvider';

import SnackProgressBar from '@features/projectOffers/components/snackbarProgress';
import '@fortawesome/fontawesome-pro/css/brands.min.css';
import '@fortawesome/fontawesome-pro/css/duotone.min.css';
import '@fortawesome/fontawesome-pro/css/fontawesome.min.css';
import '@fortawesome/fontawesome-pro/css/light.min.css';
import '@fortawesome/fontawesome-pro/css/regular.min.css';
import '@fortawesome/fontawesome-pro/css/solid.min.css';


declare module 'notistack' {
  interface VariantOverrides {
    snackBarProgress: {
      undoableTimeout?: number;
      colorByAction?: 'error' | 'success';
    };
  }
}

const App = (): JSX.Element => (
  <AppContextProvider>
    <SnackbarProvider
      TransitionComponent={Fade}
      preventDuplicate
      Components={{
        snackBarProgress: SnackProgressBar,
      }}
    >
      <TkSnackbar />
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </SnackbarProvider>
  </AppContextProvider>
);

export default App;
