import { atom } from 'recoil';

const selectedMembersNewChat = atom<IAssignee[]>({
  key: 'selectedMembersNewChat',
  default: [],
});

const showChatChannelInfo = atom<boolean>({
  key: 'showChatChannelInfo',
  default: false,
});

const chatUnreadMessage = atom<number>({
  key: 'chatUnreadMessage',
  default: 0,
});

export { showChatChannelInfo, chatUnreadMessage, selectedMembersNewChat };
