import React, { useMemo, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { Grid, IconButton, InputAdornment } from '@mui/material';

import { HelpText, SpanFormPassword, Container } from './styles';
import { ModalContent } from '@components/TkModalAlert';
import { useSnackbar } from '@components/TkSnackbar/useSnackbar';
import {
  TkCodeInput,
  TkTypography,
  DynamicButton,
  TkInput,
  TkIcon,
} from '@components/index';
import { ApiResultsKind } from '@consts/api';
import { LocalStorage } from '@consts/localStorage';
import { useAuth, useModal } from '@contexts/index';
import { emailValidator, passwordValidator } from '@helpers/validators';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import { userMethods } from '@services/index';

interface ITkForgotPasswordModal {
  email: string;
}

const TkForgotPasswordModal: React.FC<ITkForgotPasswordModal> = ({ email }) => {
  const { t } = useTranslation();
  const { login } = useAuth();
  const { handleCloseModal } = useModal();
  const { addAlert, fireTSuccess } = useSnackbar();
  const [emailReset, setEmailReset] = useState<string>(email);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [resetCode, setResetCode] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [codeError, setCodeError] = useState<boolean>(false);
  const { recoverPassword, saveNewPassword, validateRecoveryPasswordCode } =
    userMethods;
  const [content, setScreen] = useState<
    'forgotPassword' | 'userHasCode' | 'newPassword'
  >('forgotPassword');

  const fireAlert = (message: string, severity: string) => {
    addAlert({
      message,
      severity,
    });
  };

  const getPasswordHelpTextColor = useMemo(() => {
    if (password.length >= 8) {
      return '#2DA867';
    }

    if (password.length > 0 && password.length < 8) {
      return '#FF3B30';
    }

    return '#999999';
  }, [password]);

  const { refetch } = useQuery(
    ['resetPassword'],
    () =>
      recoverPassword({
        email: emailReset,
      })
        .then(({ kind }) => {
          if (kind === ApiResultsKind.OK) {
            fireAlert(
              'Your password reset email has been sent. Please check your email for further instructions.',
              'success'
            );
            setScreen('userHasCode');
          }
        })
        .catch((e) => {
          if (e.errors?.email[0] === 'The selected email is invalid.') {
            fireAlert('No account with this email address was found.', 'error');
          }
        }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const { refetch: refetchSaveNewPassword } = useQuery(
    ['saveNewPassword'],
    async () => {
      try {
        await passwordValidator.validate(password);

        const { kind } = await saveNewPassword({
          code: resetCode,
          email: emailReset,
          password,
          confirmPassword: password,
        });

        if (kind === ApiResultsKind.OK) {
          fireTSuccess('forgotPasswordModal.passwordUpdated', {
            ns: 'app',
          });
          handleCloseModal();
        }
      } catch (e) {
        Promise.reject(e);
      }
    },

    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const {
    isFetching: isFetchingCode,
    isSuccess: isSuccessCode,
    refetch: refetchCode,
  } = useQuery(
    ['validateResetCode', resetCode],
    () =>
      /** @ts-ignore */
      validateRecoveryPasswordCode({
        code: resetCode,
      })
        .then(() => {
          setScreen('newPassword');
          return true;
        })
        .catch((e) => {
          console.error(e);
          setCodeError(true);
          return false;
        }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );

  const handleCheckCode = (code: string) => {
    setResetCode(code);
    setTimeout(() => refetchCode(), 500);
  };

  const handleHasCode = () => {
    if (emailReset) {
      emailValidator
        .validate(emailReset)
        .then(() => {
          setScreen('userHasCode');
        })
        .catch(() => {
          setEmailError(true);
        });
    } else {
      setEmailError(true);
    }
  };

  const newPasswordContent = (
    <Container direction="column">
      <TkTypography
        fontSize={24}
        fontFamily="Muli"
        fontWeight="bold"
        color="#011F41"
      >
        {t('forgotPasswordModal.resetYourPassword')}
      </TkTypography>
      <TkTypography
        fontSize={16}
        fontFamily="Lato"
        color="#011F41"
        marginTop="20px"
        marginBottom="10px"
      >
        {t('forgotPasswordModal.pleaseEnterNewPassword')}
      </TkTypography>

      <SpanFormPassword
        placeholder={`${t('login.password')}`}
        required
        style={{ marginTop: 30 }}
        type={showPassword ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setShowPassword((prev) => !prev)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        onChange={(e) => setPassword(e.target.value)}
      />
      <HelpText style={{ marginBottom: 60 }}>
        <TkIcon
          className={
            password.length >= 8 ? 'fa fa-check-circle' : 'fa fa-times-circle'
          }
          iconSize={14}
          marginRight={3}
          width={16}
          style={{
            color: getPasswordHelpTextColor,
          }}
        />
        <TkTypography
          fontFamily="Lato"
          fontSize="12px"
          fontWeight="bold"
          marginTop="1px"
          style={{ color: getPasswordHelpTextColor }}
        >
          {t('login.mustBeAtLeast8Char')}
        </TkTypography>
      </HelpText>

      <Grid container justifyContent="right">
        <DynamicButton
          bgcolor="primary"
          variant="contained"
          onClick={refetchSaveNewPassword}
        >
          <TkTypography fontSize={16} fontFamily="Lato" fontWeight="bold">
            {t('forgotPasswordModal.savePassword')}
          </TkTypography>
        </DynamicButton>
      </Grid>
    </Container>
  );

  const resetPasswordContent = (
    <Container direction="column">
      <TkTypography
        fontSize={24}
        fontFamily="Muli"
        fontWeight="bold"
        color="#011F41"
      >
        {t('forgotPasswordModal.forgotYourPassword')}
      </TkTypography>
      <TkTypography
        fontSize={16}
        fontFamily="Lato"
        color="#011F41"
        marginTop="5px"
      >
        {t('forgotPasswordModal.forgotYourPasswordText')}
      </TkTypography>

      <TkInput
        marginTop="30px"
        value={emailReset}
        fullWidth
        placeholder={t('login.emailPlaceholder')}
        onChange={(e) => setEmailReset(e.target.value)}
        error={emailError}
      />
      {emailError && (
        <HelpText error>
          <TkTypography fontFamily="Lato" fontSize="12px">
            {t('others.pleaseEnterAn', { field: 'email' })}
          </TkTypography>
        </HelpText>
      )}

      <DynamicButton
        color="primary"
        style={{ margin: '30px 0' }}
        onClick={handleHasCode}
      >
        <TkTypography fontSize={16} fontFamily="Lato" fontWeight="bold">
          {t('forgotPasswordModal.iAlreadyHaveACode')}
        </TkTypography>
      </DynamicButton>

      <Grid container justifyContent="space-between">
        <DynamicButton color="primary" onClick={handleCloseModal}>
          <TkTypography fontSize={16} fontFamily="Lato" fontWeight="bold">
            {t('cancel', { ns: 'common' })}
          </TkTypography>
        </DynamicButton>
        <DynamicButton bgcolor="primary" variant="contained" onClick={refetch}>
          <TkTypography fontSize={16} fontFamily="Lato" fontWeight="bold">
            {t('forgotPasswordModal.resetPassword')}
          </TkTypography>
        </DynamicButton>
      </Grid>
    </Container>
  );

  const codeInputPasswordContent = (
    <Container direction="column">
      <TkTypography
        fontSize={24}
        fontFamily="Muli"
        fontWeight="bold"
        color="#011F41"
      >
        {t('forgotPasswordModal.resetYourPassword')}
      </TkTypography>
      <TkTypography
        fontSize={16}
        fontFamily="Lato"
        color="#011F41"
        marginTop="5px"
      >
        <Trans
          i18nKey="forgotPasswordModal.codeSend"
          values={{ email: emailReset }}
        >
          <strong />
        </Trans>
      </TkTypography>
      <TkTypography
        fontSize={16}
        fontFamily="Lato"
        color="#011F41"
        marginTop="20px"
        marginBottom="30px"
      >
        {t('forgotPasswordModal.pleaseEnterTheCodeBelow')}
      </TkTypography>

      <TkCodeInput
        codeValues={resetCode}
        checkCode={handleCheckCode}
        isError={codeError}
        isFetching={isFetchingCode}
        isSuccess={isSuccessCode}
      />

      <DynamicButton
        color="primary"
        style={{ margin: '30px 0 0' }}
        onClick={refetch}
      >
        <TkTypography fontSize={16} fontFamily="Lato" fontWeight="bold">
          {t('forgotPasswordModal.resendEmail')}
        </TkTypography>
      </DynamicButton>
    </Container>
  );

  return (
    <ModalContent>
      {content === 'forgotPassword' && resetPasswordContent}
      {content === 'userHasCode' && codeInputPasswordContent}
      {content === 'newPassword' && newPasswordContent}
    </ModalContent>
  );
};

export default TkForgotPasswordModal;
