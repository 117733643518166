import React from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { ISimpleObject } from 'types/common.type';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Select, InputLabel, FormControl, Grid } from '@mui/material';

import {
  TkTaskRepeatOptionsDaily,
  TkTaskRepeatOptionsMonthly,
  TkTaskRepeatOptionsWeekly,
  TkTaskRepeatOptionsYearly,
} from './Options';
import { useStyles, SelectorWrapper } from './styles';
import { TaskFrequencyIntervalEnum } from '@consts/index';
import { eventTaskRepeat } from '@recoilData/index';

const TkTaskRepeatOptions = (
  errors: IErrorMessageRepeatEvents
): JSX.Element => {
  const { t } = useTranslation();
  const [taskRepeat, setTaskRepeat] = useRecoilState(eventTaskRepeat);
  const handleTypeSelection = (value: any) => {
    setTaskRepeat({ ...taskRepeat, frequency: value });
  };

  const handleIntervalChange = (value: string) => {
    setTaskRepeat({ ...taskRepeat, interval: Number(value) });
  };

  const optionsList: ISimpleObject[] = [
    {
      id: 0,
      value: TaskFrequencyIntervalEnum.DAILY,
      display: TaskFrequencyIntervalEnum.DAILY,
    },
    {
      id: 1,
      value: TaskFrequencyIntervalEnum.WEEKLY,
      display: TaskFrequencyIntervalEnum.WEEKLY,
    },
    {
      id: 2,
      value: TaskFrequencyIntervalEnum.MONTHLY,
      display: TaskFrequencyIntervalEnum.MONTHLY,
    },
    {
      id: 3,
      value: TaskFrequencyIntervalEnum.YEARLY,
      display: TaskFrequencyIntervalEnum.YEARLY,
    },
  ];

  const monthlyOnTheOptionsPeriod = [
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
  ];
  const monthlyOnTheOptionsInterval = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
  ];

  const renderOptions = (frequency: string) => {
    const options = {
      daily: (
        <TkTaskRepeatOptionsDaily
          interval={taskRepeat.interval}
          handleIntervalChange={handleIntervalChange}
        />
      ),
      weekly: (
        <TkTaskRepeatOptionsWeekly
          handleIntervalChange={handleIntervalChange}
          setTaskRepeat={setTaskRepeat}
          taskRepeat={taskRepeat}
        />
      ),
      monthly: (
        <TkTaskRepeatOptionsMonthly
          taskRepeat={taskRepeat}
          monthlyOnTheOptionsInterval={monthlyOnTheOptionsInterval}
          monthlyOnTheOptionsPeriod={monthlyOnTheOptionsPeriod}
          setTaskRepeat={setTaskRepeat}
        />
      ),
      yearly: (
        <TkTaskRepeatOptionsYearly
          taskRepeat={taskRepeat}
          setTaskRepeat={setTaskRepeat}
          handleIntervalChange={handleIntervalChange}
          monthlyOnTheOptionsPeriod={monthlyOnTheOptionsPeriod}
          monthlyOnTheOptionsInterval={monthlyOnTheOptionsInterval}
          errorMonth={errors}
        />
      ),
    };

    return options[frequency];
  };

  const classes = useStyles();

  return (
    <Grid container direction="column">
      <SelectorWrapper container>
        <FormControl className={classes.formControl}>
          <InputLabel id="select-repeat-task" variant="standard">
            {t('repeat', { ns: 'events' })}
          </InputLabel>
          <Select
            native
            fullWidth
            labelId="select-repeat-task"
            id="select-repeat-task"
            variant="standard"
            value={taskRepeat.frequency}
            onChange={(e) => handleTypeSelection(e.target.value)}
            IconComponent={ExpandMoreIcon}
            color="primary"
          >
            {optionsList.map((i) => (
              <option value={i.value} key={i.id}>
                {t(`periodInterval.${i.display}`, { ns: 'common' })}
              </option>
            ))}
          </Select>
        </FormControl>
      </SelectorWrapper>
      {renderOptions(taskRepeat.frequency)}
    </Grid>
  );
};

export default TkTaskRepeatOptions;
