import React from 'react';

import { TkTaskWrapperItem } from './styles';
import { TkDrawerTagsList } from '@components/index';

interface TkTaskTags {
  event: IEvent;
}

const TkTaskTags = ({ event }: TkTaskTags): JSX.Element => {
  return (
    <TkTaskWrapperItem padding="10px 1rem 0">
      <TkDrawerTagsList event={event} />
    </TkTaskWrapperItem>
  );
};

export default TkTaskTags;
