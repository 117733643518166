import React from 'react';
import { TkTypography } from '@components/index';
import { BookingModalContentStart } from '../styles';
import { SNAPSHOTS_PAGE_NAMES, useCrazyEggTrackByName } from '@hooks/index';
import { useTranslation } from 'react-i18next';

const StepOne = () => {
  useCrazyEggTrackByName(SNAPSHOTS_PAGE_NAMES.ON_BOARDING_ABOUT_1);
  const { t } = useTranslation();
  return (
    <>
      <TkTypography
        fontFamily="Muli"
        fontSize={20}
        color="#061F41"
        align="left"
        letterSpacing={0}
        fontWeight="bold"
      >
        {t('booking_services_easier', { ns: 'bookingWidget' })}
      </TkTypography>

      <BookingModalContentStart>
        <li>
          <TkTypography
            fontFamily="Lato"
            fontSize={16}
            color="#011F41"
            align="left"
            letterSpacing={0}
          >
            {t('let_your_customers_book_your_services', {
              ns: 'bookingWidget',
            })}
          </TkTypography>
        </li>
      </BookingModalContentStart>
    </>
  );
};

export { StepOne };
