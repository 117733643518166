import { atom } from 'recoil';

const modalQueueData = atom<ModalQueueItem[]>({
  key: 'modalQueueData',
  default: [],
});

const modalQueueIndexData = atom<number>({
  key: 'modalQueueIndexData',
  default: 0,
});

export { modalQueueData, modalQueueIndexData };
