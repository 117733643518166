import React from "react";
import { useTranslation } from "react-i18next";
import { InputDaily, OptionWrapper } from "../styles";

interface ITkTaskRepeatOptionsDaily {
  interval: number;
  handleIntervalChange: (value: string) => void;
}

const TkTaskRepeatOptionsDaily: React.FC<ITkTaskRepeatOptionsDaily> = ({
  interval,
  handleIntervalChange,
}) => {
  const { t } = useTranslation();

  return (
    <OptionWrapper container alignItems="baseline">
      {t("every", { ns: "common" })}
      <InputDaily
        type="number"
        value={interval}
        onChange={(e) => handleIntervalChange(e.target.value)}
      />
      {t("days", { ns: "common" }).toLowerCase()}
    </OptionWrapper>
  );
};

export default TkTaskRepeatOptionsDaily;
