import React, { useEffect, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { ChannelFilters, ChannelOptions } from 'stream-chat';
import {
  ChannelList,
  ChannelPreviewUIComponentProps,
  useChatContext,
} from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

import { IconButton, Badge, Box } from '@mui/material';

import { ChannelPreviewMessenger } from './ChannelPreviewMessengerCustom';
import {
  DropdownContentWrapper,
  DropDownFooter,
  SectionContent,
} from './styles';
import {
  DynamicButton,
  TkIcon,
  TkTypography,
  TkBaseDropDown,
} from '@components/index';
import { AppLocationURLs } from '@consts/index';
import { useAuth } from '@contexts/index';
import { assigneesData } from '@recoilData/index';

const TkChatMessages = (): JSX.Element => {
  const { userInfo } = useAuth();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { client } = useChatContext();
  const history = useHistory();
  const members = useRecoilValue(assigneesData);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [channelsQuantity, setChannelsQuantity] = useState(0);
  const options: ChannelOptions | undefined = { limit: 5 };
  const filters: ChannelFilters<DefaultStreamChatGenerics> | undefined = {
    members: { $in: [client?.user?.id || ''] },
  };

  const hasTeammates = members.filter(
    (member) => member.id !== userInfo?.user?.id
  );

  client?.on((event) => {
    if (event.total_unread_count !== undefined) {
      setUnreadMessages(event.total_unread_count);
    }

    if (event.queriedChannels?.channels.length !== undefined) {
      setChannelsQuantity(event.queriedChannels?.channels.length);
    }
  });

  const handleClick = () => {
    if (pathname.includes(AppLocationURLs.CHAT)) return;

    history.push(AppLocationURLs.CHAT);
  };

  const Preview = (
    props: React.PropsWithChildren<
      ChannelPreviewUIComponentProps<DefaultStreamChatGenerics>
    >
  ) => {
    const { setActiveChannel, channel, watchers } = props;

    const customSelect = () => {
      if (setActiveChannel) setActiveChannel(channel, watchers);
    };

    return <ChannelPreviewMessenger {...props} onSelect={customSelect} />;
  };

  const EmptyStateIndicator = () => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      {hasTeammates.length > 0 ? (
        <Box padding="10px">
          <TkTypography
            fontSize="16px"
            color="#707070"
            justifyContent="center"
            alignItems="center"
          >
            {t('dropDown.emptyState1', { ns: 'chat' })}
          </TkTypography>
          <DynamicButton
            variant="outlined"
            color="secondary"
            onClick={handleClick}
          >
            {t('dropDown.emptyState1Message', { ns: 'chat' })}
          </DynamicButton>
        </Box>
      ) : (
        <>
          <Box
            padding="15px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <TkTypography fontSize="16px" color="#707070" textAlign="center">
              <Trans i18nKey="dropDown.emptyState2" ns="chat">
                <br />
              </Trans>
            </TkTypography>
          </Box>
          <Box paddingBottom="15px">
            <DynamicButton
              variant="outlined"
              color="secondary"
              onClick={() => history.push(AppLocationURLs.TEAM)}
            >
              {t('dropDown.inviteTeammates', { ns: 'chat' })}
            </DynamicButton>
          </Box>
        </>
      )}
    </Box>
  );

  return (
    <TkBaseDropDown
      Activator={
        <SectionContent>
          <IconButton style={{ padding: 0, color: '#4952A9' }}>
            <Badge badgeContent={unreadMessages} color="error">
              <TkIcon
                className="fa fa-comments-alt"
                iconSize={24}
                cursor="pointer"
              />
            </Badge>
          </IconButton>
        </SectionContent>
      }
      PopoverStyle={{
        width: '337px',
        maxHeight: '366px',
        padding: '0',
      }}
      DropdownContent={
        <DropdownContentWrapper>
          <ChannelList
            setActiveChannelOnMount={false}
            Preview={Preview}
            sendChannelsToList
            options={options}
            filters={filters}
            EmptyStateIndicator={EmptyStateIndicator}
          />
          {channelsQuantity >= 1 ? (
            <DropDownFooter onClick={() => handleClick()}>
              {t('dropDown.seeAllMessages', { ns: 'chat' })}
            </DropDownFooter>
          ) : null}
        </DropdownContentWrapper>
      }
    />
  );
};

export default TkChatMessages;
