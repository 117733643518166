import { DateTime } from 'luxon';

export const compareChecklists = (initial, edited) => {
  const initialNormalized = normalizeChecklists(initial);
  const editedNormalized = normalizeChecklists(edited);

  return compareByJSONStrigify(initialNormalized, editedNormalized);
};

const normalizeChecklists = (checklists) => {
  return checklists.map((checklist) => ({
    id: checklist.id,
    title: checklist.title,
    author: checklist.author,
    description: checklist.description,
    isMandatory: !!checklist.isMandatory,
  }));
};

export const compareAssignees = (initial, edited) => {
  const initialNormalized = normalizeAssignees(initial);
  const editedNormalized = normalizeAssignees(edited);

  return compareByJSONStrigify(initialNormalized, editedNormalized);
};

const normalizeAssignees = (assignees) => {
  return assignees
    .map((assignee) => ({
      id: assignee.id,
      email: assignee.email,
      checked: assignee.checked,
      full_name: assignee.full_name,
      last_name: assignee.last_name,
      first_name: assignee.first_name,
      assignmentStatus: assignee.assignmentStatus,
      asked_to_be_removed: assignee.asked_to_be_removed,
    }))
    .sort((a, b) => a.id - b.id);
};

export const compareDates = (initial, edited) => {
  const initialDateTime = normalizeDatesToDateTime(initial);
  const editedDateTime = normalizeDatesToDateTime(edited);

  return initialDateTime.toMillis() === editedDateTime.toMillis();
};

const normalizeDatesToDateTime = (date: Date | DateTime): DateTime => {
  if (DateTime.isDateTime(date)) {
    return date;
  }
  return DateTime.fromJSDate(date);
};

export const compareByJSONStrigify = (initial, edited): boolean => {
  return JSON.stringify(initial) === JSON.stringify(edited);
};
