import { lazy } from 'react';
import { AppLocationURLs } from '@consts/index';

const IntegrationsRoutes = [
  {
    path: AppLocationURLs.INTEGRATIONS,
    component: lazy(() => import('@pages/IntegrationsPage')),
  },
];

export default IntegrationsRoutes;
