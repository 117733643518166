import React from 'react';

import { useTranslation } from 'react-i18next';

import TkIcon from '@components/TkIcon';
import { IconWrapper } from '@components/TkTags/styles';
import TkTooltip from '@components/TkTooltip';

export const RepeatingIcon = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <TkTooltip title={`${t('tkTags.repeating')}`} style={{ display: 'flex' }}>
      <IconWrapper style={{ backgroundColor: 'transparent' }}>
        <TkIcon className="fa fa-repeat-alt" iconSize={10} />
      </IconWrapper>
    </TkTooltip>
  );
};
