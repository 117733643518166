import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Grid } from '@mui/material';

import { StepOne, StepTwo } from './steps';
import {
  SettingsActions,
  BookingModalContent,
  BookingModalSection,
  BookingModalIllustration,
  ModalBookingButtons,
  ContentChevron,
  ButtonContent,
} from './styles';
import { bookingOnboardingStep1, bookingOnboardingStep2 } from '@assets/index';
import {
  TkTypography,
  DynamicButton,
  TkMobileStepper,
} from '@components/index';
import { AppLocationURLs } from '@consts/index';
import { getUserSettingsState } from '@contexts/AuthContext/state';
import { useMixpanel, useModal } from '@contexts/index';
import { useUserSettings } from '@hooks/index';
import { isFirstTimeSetup } from '@recoilData/index';

const TkBookingGetStartModal = (): JSX.Element => {
  const { t } = useTranslation();
  const { handleCloseModal, clearModalQueue, resumeModalQueue } = useModal();
  const { handleSettingsUpdate } = useUserSettings();
  const [activeStep, setActiveStep] = useState(1);
  const setFirstTimeBooking = useSetRecoilState(isFirstTimeSetup);
  const [userSession, setUserSession] = useRecoilState(getUserSettingsState);
  const {
    track,
    consts: {
      MIXPANEL_EVENT_NAMES_ORIGIN,
      MIXPANEL_EVENT_ACTIONS,
      MIXPANEL_PAGE_NAMES,
    },
  } = useMixpanel();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const questions = {
    0: <></>,
    1: <StepOne />,
    2: <StepTwo />,
  };
  const images = {
    1: bookingOnboardingStep1,
    2: bookingOnboardingStep2,
  };
  const maxSteps = Object.keys(questions).length;

  const dismissModalOnLocalStorage = () => {
    const userSessionLocal = { ...userSession, show_booking_onboarding: false };
    setUserSession(userSessionLocal);
  };

  const dismissModal = (action, callback) => {
    handleSettingsUpdate({
      show_booking_onboarding: false,
      booking_onboarding_updated: true,
    });
    setFirstTimeBooking(false);
    track(MIXPANEL_EVENT_NAMES_ORIGIN.BOOKING_WIDGET, {
      action,
      page: MIXPANEL_PAGE_NAMES.CALENDAR.HOME,
    });
    callback();
  };

  const handleRedirect = () => {
    dismissModal(
      MIXPANEL_EVENT_ACTIONS.BOOKING_WIDGET.ONBOARDING_COMPLETED,
      () => {
        dismissModalOnLocalStorage();
        window.location.href = AppLocationURLs.BOOKING_SITE_SETTINGS;
      }
    );
  };

  const handleSkip = () => {
    dismissModal(
      MIXPANEL_EVENT_ACTIONS.BOOKING_WIDGET.ONBOARDING_SKIPPED,
      () => {
        handleCloseModal();
        clearModalQueue();
        resumeModalQueue();
      }
    );
  };

  return (
    <BookingModalContent dividers>
      <Grid item xs={12} md={6} sx={{ height: '100%' }}>
        <TkMobileStepper steps={maxSteps} activeStep={activeStep} />

        <BookingModalSection>{questions[activeStep]}</BookingModalSection>
        <SettingsActions>
          <Grid container xs={12} md={12}>
            {activeStep > 1 ? (
              <ButtonContent width="44%">
                <ModalBookingButtons
                  color="primary"
                  disableElevation
                  onClick={handleBack}
                >
                  <ContentChevron>
                    <ChevronLeftIcon />
                    <TkTypography
                      fontFamily="Lato"
                      fontSize={16}
                      color="#4952A9"
                      align="center"
                      letterSpacing={0}
                    >
                      {t('back', { ns: 'common' })}
                    </TkTypography>
                  </ContentChevron>
                </ModalBookingButtons>
              </ButtonContent>
            ) : (
              <ButtonContent width="59%" />
            )}
            <ButtonContent width="16%">
              <ModalBookingButtons
                disableElevation
                color="primary"
                onClick={handleSkip}
              >
                <TkTypography
                  fontFamily="Lato"
                  fontSize={16}
                  color="#4952A9"
                  align="left"
                  letterSpacing={0}
                >
                  {t('skip', { ns: 'common' })}
                </TkTypography>
              </ModalBookingButtons>
            </ButtonContent>
            {activeStep === 2 ? (
              <ButtonContent width="40%" justify="flex-end">
                <DynamicButton
                  disableElevation
                  variant="contained"
                  bgcolor="primary"
                  onClick={handleRedirect}
                >
                  <TkTypography
                    fontFamily="Lato"
                    fontSize={14}
                    color="#FFFFFF"
                    align="right"
                    letterSpacing={0}
                    margin="8px 12px"
                  >
                    {t('learn_more', { ns: 'bookingWidget' })}
                  </TkTypography>
                </DynamicButton>
              </ButtonContent>
            ) : (
              <ButtonContent width="25%" justify="flex-end">
                <DynamicButton
                  disableElevation
                  variant="contained"
                  bgcolor="primary"
                  onClick={handleNext}
                >
                  <TkTypography
                    fontFamily="Lato"
                    fontSize={16}
                    color="#FFFFFF"
                    align="right"
                    letterSpacing={0}
                  >
                    {t('next', { ns: 'common' })}
                  </TkTypography>
                </DynamicButton>
              </ButtonContent>
            )}
          </Grid>
        </SettingsActions>
      </Grid>
      <BookingModalIllustration item xs={12} md={6}>
        <img src={images[activeStep]} />
      </BookingModalIllustration>
    </BookingModalContent>
  );
};

export { TkBookingGetStartModal };
