import styled from 'styled-components';

import {
  Box,
  Tab,
  Tabs,
  Grid,
  Container,
  TextField,
  DialogActions,
  DialogContent,
  FormControlLabel,
} from '@mui/material';

import TkIcon from '@components/TkIcon';
import TkTypography from '@components/TkTypography';
import { getColorFromTheme } from '@helpers/index';

export const TkTaskWrapper = styled(Container)`
  &.MuiContainer-root {
    display: flex;
    flex: 1;
    overflow-y: scroll;
    scrollbar-width: none;
    flex-direction: column;
    padding-bottom: 1rem;
    padding: 0;
    position: relative;
  }
`;

export const TkTimeNotSet = styled(TkTypography)`
  padding: 6px 0 2px;
  text-align: right;
  margin-right: 7px;
`;

export const TkTaskWrapperItem = styled(Box)`
  display: flex;
  flex-direction: column;
  user-select: none;
  ${(props) =>
    props.bgcolor === 'selected'
      ? `background-color: #011F41; color: #ffffff;`
      : ''}
`;

export const TkTaskIdWrapper = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: column;
  user-select: none;
  padding: 10px 1rem;
  background-color: #ffffff;
  max-height: 50px;
`;

export const TaskId = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Muli;
  font-weight: bold;
  font-size: 16px;
  user-select: none;
  padding: 0;
  margin: 0;
`;

export const Selector = styled.div`
  font: normal normal normal 16px/24px 'Lato';
  color: #4952a9;
  position: relative;
  cursor: pointer;
`;

export const CheckListSelectedItem = styled(Grid)`
  margin-bottom: 10px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid rgba(73, 82, 169, 0);
  ${(props) =>
    props.color === 'selected'
      ? 'background-color: #e8f4ff;border: 1px solid #2699fb;'
      : ''}

  .MuiGrid-container {
    align-items: center;
  }

  .MuiIcon-root {
    cursor: pointer;
  }
`;

export const HorizontalDivider = styled.div`
  background-color: #c8c7cc;
  height: 1px;
  width: 100%;
  /* margin: 10px 0; */
`;

export const TkListItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-bottom: 1px solid #cbcbcb;
  padding: 5px 0 10px 0;
  cursor: pointer;
`;

export const TkListWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;
  margin-top: 20px;

  &.MuiContainer-root {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const TkExtendedBase = styled(Container)`
  *::-webkit-scrollbar {
    display: none;
  }
`;
export const TkExtendedWrapper = styled(TkExtendedBase)`
  &.MuiContainer-root {
    display: flex;
    flex: 1;
    ${(props) => (props.maxWidth === 'lg' ? 'width: 408px' : 'width: 282px')};
    ${(props) => (props.maxWidth === 'xl' ? 'width: 583px' : '')};
    padding: 1rem;
    flex-direction: column;
    overflow: hidden;
  }
`;

export const TkExtendedWrapperTabs = styled(TkExtendedWrapper)`
  margin: 0;
`;

export const TkTaskActions = styled(Container)`
  &.MuiContainer-root {
    border-top: 1px solid #999999;
    display: flex;
    min-height: 65px;
    justify-content: space-between;
    align-items: center;
    background-color: white;
  }
`;

export const TkListItemSubText = styled.div`
  font-size: 14px;
  font-family: 'Lato';
  margin-top: 4px;
  display: inherit;
`;

export const TkChecklistItem = styled(FormControlLabel)`
  ${(props) =>
    props.checked
      ? 'border: 0.5px solid #2699FB;'
      : 'border: 0.5px solid #ffffff; border-bottom: 0.5px solid #f0f0f0;'}

  ${(props) =>
    props.checked
      ? 'background-color: rgba(38,153,251, 0.1) ; border-radius: 2px;'
      : ''}

  padding: 0 0 10px 10px;
  user-select: none;
  margin: 5px 0 5px 0;

  &.MuiFormControlLabel-root {
    margin-right: 0;
    margin-left: 0;
  }

  .MuiCheckbox-root {
    padding: 0 9px 9px 0;
    margin-left: 2px;
  }

  .MuiTypography-body1 {
    margin: 4px 0 0 0;
  }
`;

export const ImgAssignee = styled.img`
  margin: 3px 10px 0 0;
  border-radius: 50%;
  width: 36px;
  height: 36px;
`;

export const AssigneeButton = styled(Grid)`
  display: flex;
  align-items: center;
  margin-top: 5px;
  cursor: pointer;
`;

export const AssigneeSelectedButton = styled(Grid)`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0;
  cursor: pointer;
`;

export const DeleteIconAssignee = styled(Grid)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const IconAssigneeButtonHover = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #ffffff;
  border: #feb334 1px solid;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #feb334;
  text-align: center;
`;

export const IconAssigneeButton = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #4952a9;
  border: #4952a9 1px solid;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: 'Lato';
  font-weight: normal;
  font-size: 16px;
`;

export const TkListItemSubTextIcon = styled(TkIcon)`
  &.MuiIcon-root {
    font-size: 11px;
    margin-right: 4px;
    margin-top: 3px;
  }
`;

export const TkListActions = styled.div`
  border-top: 1px solid #999999;
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  min-height: 65px;
  background-color: white;
  justify-content: space-between;
  align-items: center;
`;

export const TaskNotesField = styled(TextField)`
  .MuiInput-root {
    margin-top: 10px;
    color: #011f41;
    font-size: 16px;
    font-family: 'Lato';
    background-color: #f4f6f8;
    border: 1px solid #4952a9;
    padding: 10px;
    border-radius: 4px;
  }

  .MuiFormHelperText-root {
    text-align: right;
  }
`;

export const TkCircleIcon = styled.div`
  background-color: ${(props) => props.color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

export const ImagePreviewCircle = styled.img`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  object-fit: cover;
  margin-left: -10px;
`;

export const FullWrapper = styled.div`
  display: flex;
  flex: 1;
  padding-top: 65%;
  align-items: center;
  flex-direction: column;
`;

export const FullWrapperItem = styled(Grid)`
  display: flex;
  padding: 15px 15px 0;
  align-items: center;
  flex-direction: column;
`;

export const WrapperTeammatesHeader = styled(Grid)`
  padding-bottom: 10px;
`;

export const WrapperTeammatesList = styled.div`
  display: flex;
  flex: 1;
  padding: 0 0 20px;
  overflow-y: scroll;
  scrollbar-width: none;
`;

export const WrapperBaseListItem = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-bottom: 20px;
  margin-bottom: 20px;
`;

export const WrapperTeammatesItem = styled(WrapperBaseListItem)`
  border-bottom: 1px solid #c8c7cc;
`;

export const WrapperTeammatesNotesComment = styled(Grid)`
  display: flex;
  width: 100%;
  background-color: #f4f6f8;
  border-radius: 4px;
  margin-top: 15px;
  padding: 10px;
  font-size: 14px;
  font-family: 'Lato';
`;

export const WrapperTeammatesImagesList = styled(Grid)`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin-top: 15px;
  overflow-x: scroll;
  padding-bottom: 5px;
  user-select: none;
  scrollbar-width: none;
`;

export const FullWrapperIcon = styled(TkIcon)`
  width: auto;
`;

export const ImageDiv = styled(Grid)`
  margin-top: 10px;
`;

export const TkAssigneeContent = styled(Grid)`
  margin-left: 15px;
  max-width: 223px;
`;

export const LocationSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
  display: inline-block;
`;

export const TkAssigneeItem = styled(FormControlLabel)`
  ${(props) =>
    props.checked
      ? 'border: 0.5px solid #2699FB;'
      : 'border: 1px solid #ffffff;'}
  padding: 0 0 10px 10px;
  margin: 5px 0 5px 0 !important;
  user-select: none;
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
  max-width: 254px;

  ${(props) =>
    props.checked
      ? 'background-color: rgba(38,153,251, 0.1) ; border-radius: 2px;'
      : ''}

  .MuiCheckbox-root {
    position: absolute;
    padding: 0 9px 9px 0;
    top: 25px;
    left: 0;
  }

  .MuiTypography-body1 {
    margin-top: 4px;
  }
`;

export const Span = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
`;

export const TabCustom = styled(Tab)`
  text-transform: none;
  min-width: 141px;
  background-color: #f0f0f0;
  &.Mui-selected {
    background-color: #ffffff;
  }
`;

export const TabsCustom = styled(Tabs)`
  max-width: 282px;
  .MuiTabs-indicator {
    display: flex;
    justify-content: center;
    background-color: transparent;
    & > span {
      max-width: 0;
      width: 100%;
      background-color: #ffffff;
    }
  }
`;

export const TkCrewItem = styled(FormControlLabel)`
  ${(props) =>
    props.checked
      ? 'border: 0.5px solid #2699FB;'
      : 'border: 1px solid #ffffff;'}
  padding: 0 0 10px 10px;
  user-select: none;
  margin: 0;
  align-items: center;

  ${(props) =>
    props.checked
      ? 'background-color: rgba(38,153,251, 0.1); border-radius: 2px;'
      : ''}

  .MuiCheckbox-root {
    padding: 0 9px 9px 0;
    height: 100%;
    margin-top: 35px;
    margin-left: 2px;
    align-items: start;
  }

  .MuiTypography-body1 {
    margin: 4px 0 0 0;
  }
`;

export const SearchBox = styled(Grid)`
  margin-left: 12px;
  margin-right: 12px;
`;

export const TkListWrapperList = styled(TkListWrapper)`
  margin-top: 0;
`;

export const OverlayWrapper = styled.div`
  display: flex;
  justify-content: center;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

interface OverlayContainerProps {
  backgroundImage: string;
}

export const OverlayContainer = styled.div<OverlayContainerProps>`
  display: flex;
  flex-direction: column;
  width: 520px;
  height: 520px;
  margin-top: 45px;
  background-image: url(${(props) => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: 520px 520px;
  justify-content: space-between;
`;

export const CircleButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00000066;
  cursor: pointer;

  .MuiIcon-root {
    width: auto;
  }
`;

export const ModalContent = styled(DialogContent)`
  width: 560px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .MuiFormLabel-root {
    font: normal normal normal 18px/20px Lato;
    top: -5px;
    color: ${(props) => getColorFromTheme(props.theme.palette, 'default')};
  }
  &.MuiDialogContent-dividers {
    border-top: 0;
  }
`;

export const ModalActions = styled(DialogActions)`
  padding: 1rem;
  border-top: 1px solid #f0f0f0;
`;

export const TextModal = styled(Grid)`
  padding: 10px 0 5px 0;
`;

export const SubTextModal = styled(Grid)`
  padding-bottom: 10px;
  max-width: 453px;
`;

export const DeleteIconModal = styled(Grid)`
  padding: 10px;
`;

export const DeleteButtonGroup = styled(Grid)`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  column-gap: 1rem;
`;

export const ChecklistDetail = styled(Grid)`
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 5px 5px 5px 0;
  margin-top: 2px;
`;

export const ChecklistWrapper = styled(Grid)`
  margin: 5px 0;
`;

export const ChecklistSubWrapper = styled(Grid)`
  border-radius: 4px;
  padding: 5px;
  ${(props) =>
    props.color === 'highlight' ? 'background-color: #FBF3DC' : ''};
`;

export const ChecklistWrapperFiller = styled(Grid)`
  display: flex;
  margin: 5px 0 0 30px;
  background-color: #f4f6f8;
  border-radius: 4px;
  padding: 5px;
`;

export const ChecklistDetailFill = styled(Grid)`
  display: flex;
  flex-direction: row;
`;

export const ImagePreviewSmall = styled.img`
  height: 56px;
  width: 56px;
  border-radius: 4px;
  margin-right: 5px;
  object-fit: cover;
`;

export const MoreImages = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 56px;
  width: 56px;
  border-radius: 4px;
  margin-right: 5px;
  background-color: #e1e0e2;
  user-select: none;
  cursor: pointer;
`;
