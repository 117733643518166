import { DateTime, Duration } from 'luxon';
import { getLuminance, lighten, shade } from 'polished';

import { userTimezone } from './TimezoneHelper';
import { CrewMember } from '@components/TkTeams/types';

export const getColorFromTheme = (palette: any, color: string): string => {
  const themecolor = color?.split('.');

  if (!themecolor) return palette.default.main;

  if (themecolor.length === 1 && typeof palette[themecolor[0]] === 'object') {
    return palette[themecolor[0]].main;
  }
  return themecolor.reduce((prev: string, curr: any) => prev[curr], palette);
};

export const getHoverColor = (color: string): string => {
  const baseLuminance = getLuminance('#484a49');

  if (typeof color === 'undefined') color = '#fff';
  return baseLuminance > getLuminance(color)
    ? lighten(0.1, color)
    : shade(0.1, color);
};

export const getLighterColorFromTheme = (color: string): string => {
  return lighten(0.3, color);
};

export const getOutlinedHoverColor = (color: string): string => {
  return lighten(0.35, color);
};

export const getTimezoneLuxonDateFromEpoch = (
  date: number,
  timezone: string | null
): DateTime => DateTime.fromSeconds(date).setZone(timezone ?? userTimezone());

export const getTimezoneDateFromISO = (
  date: string,
  timezone: string | null
): DateTime => DateTime.fromISO(date).setZone(timezone ?? userTimezone());

export const getDateToEpoch = (date: Date): number => {
  return Math.round(date.valueOf() / 1000);
};

export const getEpochToDate = (epoch: number): Date => {
  const date = new Date(0);
  date.setUTCSeconds(epoch);
  return date;
};

export const getDatetimeFromDate = (
  date: Date,
  timezone: string = userTimezone()
): DateTime => DateTime.fromJSDate(date).setZone(timezone);

export const getTimeFromEpoch = (epoch: number): string =>
  DateTime.fromSeconds(epoch).setZone(userTimezone()).toFormat('t');

export const generateListAssigneeString = (
  assigneeNames: string[],
  len: number
): string => {
  let names = '';
  assigneeNames.map((assignee: string, index: number) => {
    names += `${
      index > 0 ? (len === index + 1 ? ' and' : ',') : ''
    } ${assignee}`;
  });
  return names;
};

export function filterList<T>(list: T[], item: T): T[] {
  return list.filter((i) => i !== item);
}

export async function timedRequest(
  countRef: any,
  request: any,
  args: any,
  endRequest?: any,
  onError?: any,
  argsOnError?,
  onSuccess?: any,
  argsOnSuccess?: any
): Promise<void> {
  countRef && clearTimeout(countRef.current);
  countRef.current = window.setTimeout(async () => {
    const response = await request(...args);
    if (response?.status === 200 || response?.status === 201) {
      onSuccess && (await onSuccess(argsOnSuccess));
      endRequest && (await endRequest(response));
    } else {
      onError && onError(argsOnError);
    }
  }, 5500);
}

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

interface IMembersOptions {
  limit: number;
  endString?: string;
  preEndString?: string;
}

export const hashString = (str: string): number => {
  let hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export function timeConvert(amount): string {
  if (amount < 60)
    return Duration.fromObject({ minute: amount }).toFormat("m 'min'");
  if (amount % 60 === 0)
    return Duration.fromObject({ minute: amount }).toFormat("h 'hr'");
  return Duration.fromObject({ minute: amount }).toFormat("h:m 'hr'");
}

export function timeConvertMinutesToHours(amount): string {
  return Duration.fromObject({ minute: amount }).toFormat("h 'hr'");
}

export const generateMembersName = (
  members: CrewMember[] | ICrewMember[],
  options: IMembersOptions = {
    limit: 3,
    endString: 'more',
    preEndString: 'and ',
  }
) => {
  const { limit, endString, preEndString } = options;

  if (!members) return 'No Members';

  let name = '';
  const moreCount = members.length - limit;
  name += members.slice(0, limit + 1).map((member, index) => {
    if (index === limit) return ` ${preEndString}${moreCount} ${endString}`;

    return ` ${member.full_name}`;
  });

  return name;
};

export const filterCityFromLocation = (
  location: string | undefined
): string => {
  if (!location) {
    return 'No Location';
  }

  const [city, stateWithZip] = location.split('\n')[1].split(',');
  const state = stateWithZip.slice(1, 3);

  return `${city}, ${state}`;
};

export const formatDistanceFromPlace = (
  distance: number,
  unit: string
): string => {
  if (unit === 'mi') {
    return `${distance} Miles away`;
  }

  return `${distance} Kilometers away`;
};

export const formatDistanceFromPlaceWithCity = (
  distance: number,
  unit: string,
  location: ILocationTask
): string => {
  const { city, state } = location;

  if (unit === 'mi') {
    const mount =
      `${distance} Miles away` +
      (city ? ` from ${city}` : '') +
      (city && state ? `, ${state}` : '');
    return mount;
  } else {
    const mount =
      `${distance} Kilometers away` +
      (city ? ` from ${city}` : '') +
      (city && state ? `, ${state}` : '');
    return mount;
  }
};

type DateState = 'sameDate' | 'afterDate' | 'beforeDate';

/**
 * Returns the state of the date relative to the date provided
 * @param start Date The date to compare
 * @param endDate The date to compare to
 * @returns 'sameDate' | 'afterDate' | 'beforeDate'
 */
export const getDateState = (
  startDate: DateTime | null,
  endDate = DateTime.now()
): DateState => {
  if (startDate.day === endDate.day) return 'sameDate';
  if (startDate.day > endDate.day) return 'afterDate';

  return 'beforeDate';
};

export const nameSplitter = (name: string): RegExpMatchArray =>
  name.match(/(\S+)/g);

export const generateInitials = (name: string): string => {
  const nameArray = nameSplitter(name);

  if (!nameArray) {
    return 'N/A';
  }

  if (nameArray.length > 1) {
    const initials =
      nameArray[0][0].toUpperCase() + nameArray[1][0].toUpperCase();
    return initials;
  }

  return nameArray[0][0].toUpperCase();
};

export function firstLastName(el, justFirst = false) {
  const haystack = el;
  // matching a case-insensitive sequence of characters at the
  // start of the string (^), that are in the range a-z,
  // unicode accented characters, an apostrophe or
  // a hyphen (escaped with a back-slash because the '-'
  // character has a special meaning within regular
  // expressions, indicating a range, as above) followed
  // by a word-boundary (\b):
  let first = haystack.match(/^[a-z\u00C0-\u017F']+\b/i);

  // if first exists (no matching regular expression would
  // would return null) and it has a length:
  if (first && first.length) {
    // we assign the first element of the array returned by
    // String.prototype.match() to the 'first' variable:
    first = first[0];
  }

  if (justFirst) {
    return [first];
  }
  // as above but the word-boundary precedes the string of
  // of characters, and it matches a sequence at the end
  // of the string ($):
  let last = haystack.match(/\b[a-z\u00C0-\u017F']+$/i);
  if (last && last.length) {
    // as above:
    last = last[0];
  }

  // if the first and last variables are exactly equal,
  // we return only the first; otherwise we return both
  // first and last, in both cases within an array:
  return first === last ? [first] : [first, last];
}

export * from './generators';
export * from './validators';
export * from './formatters';
export * from './dateHelpers';
export * from './TimezoneHelper';
export * from './combineProviders';
export { default as Camelize } from './camelize';
export * from './debounce';
