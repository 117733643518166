import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { FormControl, Grid } from '@mui/material';

import {
  TkListItem,
  TkListWrapper,
  TkExtendedWrapper,
  TkListActions,
  TkListItemSubTextIcon,
} from '../styles';
import {
  TkLocationListItem,
  TkExtendedDrawerSearch,
} from '@components/ExtendedDrawers/index';
import { TkTypography, TkIcon, DynamicButton } from '@components/index';
import { useDrawer } from '@hooks/index';
import {
  eventCustomer,
  eventLocation,
  searchEventLocationsData,
} from '@recoilData/index';

const TkLocationList = (): JSX.Element => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const {
    closeExtDrawer,
    callDrawer,
    types: { ExtendedDrawersTypes },
  } = useDrawer();
  const locationsList = useRecoilValue(searchEventLocationsData(search));

  const changeExtendedDrawer = () =>
    callDrawer({ extended: { id: ExtendedDrawersTypes.NEW_TASK_CUSTOMER } });

  return (
    <>
      <TkExtendedWrapper>
        <FormControl>
          <TkTypography fontWeight="bold" fontFamily="Muli" fontSize={16}>
            {t('selectALocation', { ns: 'events' })}
          </TkTypography>
          <Grid container alignItems="flex-end">
            <Grid item>
              <TkIcon className="fa fa-search" color="#4952A9" />
            </Grid>
            <Grid item sm={10}>
              <TkExtendedDrawerSearch search={search} setSearch={setSearch} />
            </Grid>
          </Grid>
        </FormControl>
        <TkListWrapper>
          {locationsList.map((location) => (
            <TkLocationListItem
              key={location.id}
              location={location}
              eventCustomerRecoilState={eventCustomer}
              eventLocationRecoilState={eventLocation}
            />
          ))}
          {locationsList.length === 0 && (
            <TkListItem key={0} container direction="column">
              <Grid container item alignItems="center">
                <TkTypography fontWeight="bold" fontFamily="Lato" fontSize={16}>
                  {t('noItems', { ns: 'common' })}
                </TkTypography>
              </Grid>
            </TkListItem>
          )}
        </TkListWrapper>
      </TkExtendedWrapper>
      <TkListActions>
        <Grid
          container
          justifyContent="space-between"
          style={{ margin: '10px 0 10px 0' }}
        >
          <DynamicButton color="primary" onClick={changeExtendedDrawer}>
            <TkTypography fontWeight="normal" fontFamily="Lato" fontSize={16}>
              <TkListItemSubTextIcon className="fa fa-angle-left" />
              {t('back', { ns: 'common' })}
            </TkTypography>
          </DynamicButton>
          <DynamicButton color="primary" onClick={closeExtDrawer}>
            <TkTypography fontWeight="normal" fontFamily="Lato" fontSize={16}>
              {t('skip', { ns: 'common' })}
            </TkTypography>
          </DynamicButton>
        </Grid>
        {/* <Grid container style={{ margin: "0 0 13px 0" }}>
          <DynamicButton
            variant="outlined"
            color="secondary"
            disableElevation
            style={{ width: "100%" }}
          >
            <TkTypography fontWeight="normal" fontFamily="Lato" fontSize={16}>
              Add a New Location
            </TkTypography>
          </DynamicButton>
        </Grid> */}
      </TkListActions>
    </>
  );
};

export default TkLocationList;
