export default {
  crews: {
    crew: "Crew",
    crew_plural: "Crews",
    crew_name: "Crew Name",
    crew_create_enter_name_error: "Please enter a name",
    crew_create_successfully: "Crew created successfully",
    crew_deleted_successfully: "Crew deleted successfully",
    crew_create_enter_members_minimum:
      "A Crew must contain at least one member",
    crew_details: "Crew Details",
    click_to_see_details_and_edit:
      "Click on a Crew to see its details and edit it here",
    create_crew_drawer_warning_message:
      "Crews names are visible to all your teammates",
    no_crews_warning: "Hey, you don’t have<0/> any crews yet",
    create_crew_message:
      "Crews are the easiest way to assign a task<0/> to multiple teammates.",
    no_members_to_create_crew:
      "To create a crew you need to add members to your team",
    create_first_crew: "Create your first crew",
    remove_crews_warning: "Are you sure you want to delete this crew?",
    remove_crews_warning_message:
      "It won't affect the crew members and their assigned tasks",
    create_crew: "Create Crew",
    create_crew_drawer_message:
      "Crews are groups of members that work together, use them to organize your workload.",
    selected_crew_warning_add_members:
      "Adding or removing members from a crew doesn't affect their existing tasks",
    crews_must_contain_one_member: "Crews must contain at least one member",
    delete_crew: "Delete crew",
  },
  teammates: {
    member: "Member",
    member_plural: "Members",
    member_removed_successfully: "Teammate removed succesfully",
    click_to_see_details_and_edit: "Click on a Teammate to see and edit their profile here",
    no_teammates_warning: "Hey, you don’t have<0/>any Teammates",
    remove_teammate: "Remove teammate",
    remove_teammate_warning: "Are you sure you want to remove this teammate?",
    get_started: "Get started adding members to your<0/>company by sending them Invites.",
    team_members: "Team Members",
    pending_invites: "Pending Invites",
    pending_invites_count: "See {{ pendingInvitationsCount }} pending invites",
    resend_invite: "Resend invite",
    cancel_invite: "Cancel invite",
    invitation_canceled: "Invite canceled successfully",
    invitation_resent: "Invite resent successfully",
    no_tasks: "No tasks",
    edit_teammate: "Edit Teammate",
    Owner: "Owner",
    Administrator: "Administrator",
    Employee: "Employee",
    save: "Save",
    cancel: "Cancel",
    permissions: {
      customers: 'Add, modify and delete Customers.',
      teams: 'Add, modify and delete Teams.',
      crews: 'Create, modify and delete Crews.',
      tasks: 'Create, modify and delete Tasks.',
      assign_unassign_tasks: 'Assign and unassign Tasks.',
      start_complete_tasks: 'Start and complete Tasks'
    }
  },
  lets_send_invites_message: "Let’s send invites!",
  sms_error_message_empty: "Please enter a phone number",
  last_name_error_message_empty: "Please enter a last name",
  street_address_error_message_empty: "Please enter a street address",
  email_error_message_empty: "Please enter an email",
  sms_error_message: "Please enter a valid phone number",
  email_error_message: "Please enter an email in a valid format",
  send_invites_message: "Send invites",
  invitation_success: "Your invite is on the way!",
  invitation_success_plural: "Your invites are on the way!",
  invite_message: "Invite teammates",
  add_members: "Add members",
  add_to_crew: "Add to a Crew",
  add_another_contact: "Add another contact",
  see_tasks: "See tasks",
  search: {
    crews: "Type crew name...",
    members: "Type teammate name..."
  },
  todoToday: 'To-do Today'
};
