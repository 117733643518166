import styled from 'styled-components';

export const TagWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 5px;
  background-color: #f0f0f0;
  border-radius: 15px;
  height: 24px;
  font-family: 'Lato';
  font-size: 14px;
  color: #011f41;
  margin: 3px 4px;
`;

export const ImgWrapper = styled.img`
  width: 17px;
  height: 17px;
  vertical-align: middle;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  padding: 2px;
  padding-left: 3px;
  border-radius: 50%;
  background-color: #ffffff;
`;

export const IconWrapperAlt = styled(IconWrapper)`
  padding-left: 2px;
  ${(props) =>
    props.color === 'invert'
      ? `background-color: #ffffff;
      color: #fbb333;`
      : `background-color: #fbb333;
      color: #ffffff;
      `}
`;

export const IconWrapperNoBackground = styled(IconWrapper)`
  padding: 0;
  background-color: rgba(255, 255, 255, 0);
`;

export const TextWrapper = styled.div`
  margin-left: 5px;
  margin-right: 3px;
`;
