import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {
  SectionContentEmpty,
  SectionContentEmptyBody,
  SectionContentEmptySubBody,
  SectionContentLogoI,
} from './styles';

const EmptyNotification = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <SectionContentEmpty>
      <SectionContentLogoI color="#3699FB" />
      <SectionContentEmptyBody>
        <Trans i18nKey="emptyState.title" ns="notifications">
          <br />
        </Trans>
      </SectionContentEmptyBody>
      <SectionContentEmptySubBody>
        {t('emptyState.description', { ns: 'notifications' })}
      </SectionContentEmptySubBody>
    </SectionContentEmpty>
  );
};

export default EmptyNotification;
