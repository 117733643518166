const escapeMap: { [s: string]: string } = {
  '&#39;': "'",
};
const textRegex = RegExp(`(?:${Object.keys(escapeMap).join('|')})`);
const replaceRegex = RegExp(`(?:${Object.keys(escapeMap).join('|')})`, 'g');
export const decodeHTML = (encodedHTML: string): string => {
  if (textRegex.test(encodedHTML)) {
    const decode = encodedHTML.replace(
      replaceRegex,
      (key: string) => escapeMap[key]
    );
    return decode;
  }
  return encodedHTML;
};
