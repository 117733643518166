import { atom, atomFamily } from 'recoil';

const createAssignee = atom<boolean>({
  key: 'createAssignee',
  default: false,
});

const selectedAssignee = atom<IAssignee | undefined>({
  key: 'selectedAssignee',
  default: undefined,
});

const editedAssignee = atom<IAssignee>({
  key: 'editedAssignee',
  default: {
    id: 0,
    crews: [],
    email: '',
    full_name: '',
    checked: true,
    chat_user_id: null,
  },
});

const newAssigneeCrews = atomFamily<boolean, number>({
  key: 'newAssigneeCrews',
  default: false,
});

const isAllMembersSelected = atom<boolean>({
  key: 'isAllMembersSelected',
  default: false,
});

const showInviteTeamMemberDrawer = atom<boolean>({
  key: 'showInviteTeamMemberDrawer',
  default: false,
});

export {
  editedAssignee,
  createAssignee,
  selectedAssignee,
  newAssigneeCrews,
  isAllMembersSelected,
  showInviteTeamMemberDrawer,
};
