import React from 'react';

import { TkBaseDrawer, TkExtendedDrawer } from '@components/index';
import { useDrawer } from '@hooks/index';

const TkGlobalDrawer = (): JSX.Element => {
  const {
    currentDrawer: { main, extended },
  } = useDrawer();

  const { Component: MainComponent, props: propsMain } = main;
  const { Component: ExtendedComponent, props: propsExtended } = extended;

  return (
    <div>
      <div>
        <TkBaseDrawer open={!!main.id}>
          {MainComponent && <MainComponent {...propsMain} />}
        </TkBaseDrawer>
      </div>
      <div>
        <TkExtendedDrawer open={!!extended?.id}>
          {ExtendedComponent && <ExtendedComponent {...propsExtended} />}
        </TkExtendedDrawer>
      </div>
    </div>
  );
};

export default TkGlobalDrawer;
