import React from 'react';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { TkIcon, TkInput } from '@components/index';
import { withStyles } from '@mui/styles';

export const ButtonCustom = styled(Grid)`
  width: 40px;
  height: 34px;
  border: 1px solid #4952a9;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TkInputEdit = styled((props) => <TkInput {...props} />)`
  border: 1px solid ${(props) => (props.error ? '#FF3B30' : '#4952a9')};
  border-radius: 4px;
  display: flex;
  flex: 1;
  height: 36px;
  margin-right: 10px;
  box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.1);

  .MuiInput-input {
    padding: 0 5px 0 5px;
    color: #011f41;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: bold;

    &:focus {
      text-align: left;
    }
  }
`;

export const TaskNameDisplay = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: '1px',
  },
})(Grid);
