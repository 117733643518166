import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CircularProgress, Grid } from '@mui/material';
import { TkIcon, TkTypography } from '@components/index';
import { CodeInputWrapper, InputWrapper, Divider, InputCode } from './styles';

interface ITkCodeInput {
  position?: 'flex-end' | 'center' | 'flex-start';
  codeValues?: string;
  isFetching?: boolean;
  isSuccess?: boolean;
  isError?: boolean;
  checkCode: (value: string) => void;
}

const TkCodeInput: React.FC<ITkCodeInput> = ({
  codeValues = '',
  isError = true,
  isFetching = false,
  isSuccess = false,
  position = 'flex-start',
  checkCode,
}) => {
  const { t } = useTranslation();
  const [code, setCode] = useState(codeValues);
  const CODE_LENGTH = new Array(6).fill(0);

  useEffect(() => {
    if (code.length === 6) {
      checkCode(code);
    }
  }, [code]);

  const generateMiddleSymbol = () => {
    if (isFetching && !isError && !isSuccess) {
      return <CircularProgress color="inherit" />;
    }

    if (isSuccess && !isError && !isFetching) {
      return <TkIcon className="fa fa-check" iconSize={20} color="#4952A9" />;
    }

    return <Divider />;
  };

  const handleCode = (value: string) => {
    setCode(value.replace(/(?![0-9])./gim, ''));
  };

  return (
    <Grid container justifyContent={position}>
      <Grid position={'relative'} maxWidth={'364px'}>
        <CodeInputWrapper>
          {CODE_LENGTH.map((_, index) => (
            <>
              <InputWrapper
                style={{
                  color: code[index] ? '#3D4465' : 'rgba(61, 68, 101, 0.2)',
                  borderColor: isError ? '#FF3B30' : '#f6f6f6',
                }}
              >
                {code[index] ?? 0}
              </InputWrapper>
              {index === 2 && generateMiddleSymbol()}
            </>
          ))}
        </CodeInputWrapper>
        <InputCode
          value={code}
          onChange={(e) => handleCode(e.target.value)}
          maxLength={7}
          type="text"
          pattern="[0-9]"
        />
      </Grid>
      {isError && (
        <Grid>
          <TkTypography
            fontFamily={'Lato'}
            fontSize={'14px'}
            color={'#FF3B30'}
            marginTop={'5px'}
            fontWeight={'normal'}
          >
            {t('others.invalidCode')}
          </TkTypography>
        </Grid>
      )}
    </Grid>
  );
};

export default TkCodeInput;
