import React from 'react';

import { RecoilState, useSetRecoilState } from 'recoil';

import { TkListItem } from '@components/Drawers/TkEditTaskDrawer/styles';
import TkTypography from '@components/TkTypography';
import { EventTypeEnum } from '@consts/index';
import { useDrawer } from '@hooks/index';

interface ITkCustomerListItem {
  customer: ICustomerTask;
  eventCustomerRecoilState: RecoilState<ICustomerTask | undefined>;
  eventLocationRecoilState: RecoilState<ILocationTask | undefined>;
  eventType: `${EventTypeEnum}`;
}

const TkCustomerListItem = ({
  customer,
  eventCustomerRecoilState,
  eventLocationRecoilState,
  eventType,
}: ITkCustomerListItem): JSX.Element => {
  const {
    callDrawer,
    types: { ExtendedDrawersTypes },
  } = useDrawer();
  const setCustomer = useSetRecoilState(eventCustomerRecoilState);
  const setLocation = useSetRecoilState(eventLocationRecoilState);

  const handleSelectCustomer = (customer) => {
    setCustomer(customer);
    setLocation(undefined);
    callDrawer({
      extended: {
        id:
          eventType === EventTypeEnum.EDIT
            ? ExtendedDrawersTypes.EDIT_TASK_LOCATION
            : ExtendedDrawersTypes.NEW_TASK_LOCATION,
      },
    });
  };

  return (
    <TkListItem
      key={customer.id}
      onClick={() => handleSelectCustomer(customer)}
    >
      <TkTypography fontWeight="normal" fontFamily="Lato" fontSize={16}>
        {customer.name}
      </TkTypography>
      <TkTypography fontWeight="normal" fontFamily="Lato" fontSize={14}>
        {customer.locations.length > 1
          ? `${customer.locations.length} Locations`
          : `${customer.locations.length} Location`}
      </TkTypography>
    </TkListItem>
  );
};

export default TkCustomerListItem;
