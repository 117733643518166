import React from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Grid } from '@mui/material';

import { TkExtendedWrapper, TkListWrapper } from '../styles';
import { TkTypography, TkIcon, TkDragNDrop } from '@components/index';
import { TaskStatusEnum } from '@consts/index';
import { useDrawer } from '@hooks/index';
import {
  isEventEdit,
  editEventImages,
  getEventStatus,
  editEventImagesFile,
  removeEditEventImages,
  addEditEventImagesFile,
  removeEditEventImagesFile,
} from '@recoilData/index';

const TkTaskImages = (): JSX.Element => {
  const { t } = useTranslation();
  const { closeExtDrawer } = useDrawer();
  const status = useRecoilValue(getEventStatus);
  const images = useRecoilValue(editEventImages);
  const setEdited = useSetRecoilState(isEventEdit);
  const imagesFile = useRecoilValue(editEventImagesFile);
  const addImageFile = useSetRecoilState(addEditEventImagesFile);
  const removeImageFile = useSetRecoilState(removeEditEventImagesFile);
  const removeUploadedImage = useSetRecoilState(removeEditEventImages);

  const addImage = (img: File[]) => {
    setEdited(true);
    addImageFile(img);
  };

  const removeImage = (img: File) => {
    setEdited(true);
    removeImageFile(img);
  };

  return (
    <TkExtendedWrapper maxWidth="lg">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <TkTypography fontWeight="bold" fontFamily="Muli" fontSize={16}>
          {t('guidance_images', { ns: 'common' })}
        </TkTypography>
        <TkIcon
          onClick={closeExtDrawer}
          style={{ cursor: 'pointer' }}
          className="fa fa-chevron-right"
          color="default"
          marginLeft={10}
          verticalAlign="baseline"
          fontSize="small"
        />
      </Grid>
      <TkListWrapper>
        <TkDragNDrop
          disabled={status === TaskStatusEnum.DONE}
          flag
          images={images}
          removeUploadedImage={removeUploadedImage}
          newImages={imagesFile}
          addImage={addImage}
          removeImage={removeImage}
        />
      </TkListWrapper>
    </TkExtendedWrapper>
  );
};

export default TkTaskImages;
