import React from "react";
import styled, { css } from "styled-components";
import { getColorFromTheme, getLighterColorFromTheme } from "@helpers/index";
import { Grid, Input } from "@material-ui/core";

export const TkDatePickerWrapper = styled.div`
  .react-datepicker {
    z-index: 2;
    border: 0;
    box-shadow: 0px 3px 6px #00000033;
    width: 264px;
    min-height: 288px;
    font-family: Lato;

    .react-datepicker__day.react-datepicker__day--keyboard-selected {
      background: none;
      color: black;
    }
    .react-datepicker__month-container {
      width: inherit;
      max-height: 288px;
      .react-datepicker__monthPicker {
        height: 244px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .react-datepicker__month--selected {
          background-color: ${(props) =>
            getColorFromTheme(props.theme.palette, "primary")};
          color: #fff;
        }
        .react-datepicker__month-wrapper {
          display: flex;
          justify-content: space-between;
          .react-datepicker__month-text {
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    .react-datepicker__input-time-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
      padding-bottom: 15px;
    }

    .react-datepicker-time__caption {
      display: none;
    }

    .react-datepicker-time__input {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
    }

    .react-datepicker-time__input-container {
      width: 82.5%;
    }

    /*styling the Year View of the datepicker*/
    .react-datepicker__year-wrapper {
      width: 50%;
      margin: 0 auto;
      max-width: 210px;
      max-height: 250px;
      overflow: scroll;
      .react-datepicker__year-text {
        flex: 0 1 100%;
        height: 40px;
        &.react-datepicker__year-text--selected {
          color: #fff;
          font-size: 1.25rem;
          background-color: ${(props) =>
            getColorFromTheme(props.theme.palette, "primary")};
        }
        &.react-datepicker__year-text--keyboard-selected {
          background: none;
        }
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
      }
      ::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        height: 0;
        background: transparent; /* Optional: just make scrollbar invisible */
      }
      overflow-y: scroll;
      scrollbar-width: none;
    }
    /* styling the custom header for the datepicker*/
    .react-datepicker__custom-header {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      height: 40px;
      span {
        cursor: pointer;
      }
    }
    .react-datepicker__header {
      background-color: #f5f6fb;
      font-size: 1rem;
      font-weight: bold;
      border: 0;
    }
    .react-datepicker__day-names {
      background-color: #fff;
    }
    .react-datepicker__day--selected {
      background-color: ${(props) =>
        getColorFromTheme(props.theme.palette, "primary")};
      border-radius: 5px;
      color: #fff;
    }
    .react-datepicker__week {
      ${(props: ITkProps) =>
        props.children?.props.weekly &&
        css`
          &:hover {
            .react-datepicker__day {
              background-color: ${(props) =>
                getLighterColorFromTheme(
                  getColorFromTheme(props.theme.palette, "primary")
                )};
              border-radius: 5px;
              color: #fff;
            }
          }
        `}
      .react-datepicker__day--highlighted_week {
        background-color: ${(props) =>
          getColorFromTheme(props.theme.palette, "primary")};
        border-radius: 5px;
        color: #fff;
      }
    }
  }
`;

export const HorizontalSlider = styled.div`
  height: 1px;
  background-color: #c8c7cc;
  width: 100%;
  margin-bottom: 15px;
`;

export const TkHourPickerWrapper = styled.div`
  .react-datepicker {
    z-index: 2;
    border: 0;
    box-shadow: 0px 3px 6px #00000033;
    width: 234px;
    height: 145px;
    font-family: Lato;
    display: flex;
    right: 50px;
    /**/
    .react-datepicker__day.react-datepicker__day--keyboard-selected {
      background: none;
      color: black;
    }
    .react-datepicker__month-container {
      display: none;
    }
    .react-datepicker__input-time-container {
      margin: 0;
    }

    .react-datepicker-time__caption {
      display: none;
    }

    .react-datepicker-time__input {
      margin: 0;
    }
  }
`;

export const TimeWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TimeItem = styled(Input)`
  font: normal normal bold 32px/17px "Muli";
  color: #011f41;
  max-width: 63px;
  height: 63px;
  border-radius: 4px;
  background-color: #e5e2e2;
  border: 1px solid #e5e2e2;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .MuiInputBase-input {
    text-align: center;
    margin-top: 5px;
  }

  &.Mui-focused {
    background-color: #f5f6fb;
    border: 1px solid #4952a9;
    color: #4952a9;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const TimeSelector = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 38px;
  margin-left: 10px;
`;

export const TimeSelectorItem = styled.div`
  display: flex;
  width: 100%;
  padding-top: 1px;
  justify-content: center;
  align-items: center;
  height: 29px;
  font-family: "Muli";
  font-size: 16px;
  font-weight: bold;
  background-color: #ffffff;
  border: 1px solid #4952a9;
  cursor: pointer;
  color: #011f41;
  border-radius: ${(props) =>
    props.id === "top-item" ? "4px 4px 0 0" : "0 0 4px 4px"};
  ${(props) =>
    props.id === "top-item" ? "border-bottom: 0" : "border-top: 0"};
`;

export const TimeSelectorItemSelected = styled(TimeSelectorItem)`
  background-color: #f5f6fb;
  border: 1px solid #4952a9;
  color: #4952a9;
`;

export const Divider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  margin: 0 10px 10px 10px;
`;

export const TimeOnlyHeader = styled(Grid)`
  background-color: #F5F6FB;
  padding: 10px;
  border-radius: 4px 4px 0 0;
  margin-bottom: 20px;
  user-select: none;
`;
