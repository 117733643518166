import { AppLocationURLs } from '@consts/index';
import {
  NotificationHandler,
  NotificationMeta,
} from '@hooks/notification/types';

const getRoute = () => ({
  url: AppLocationURLs.HOME,
  drawer: '',
});

const getAttributes = (
  notificationMetadata: NotificationMeta
): NotificationMeta => ({
  type: notificationMetadata.type,
  loggedUserId: notificationMetadata.loggedUserId,
  accountId: notificationMetadata.accountId,
});

export default {
  getRoute,
  getAttributes,
} as NotificationHandler;
