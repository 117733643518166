import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";

const UseStyles = makeStyles(() =>
  createStyles({
    sectionDesktop: {
      height: "100%",
      width: "100%",
      background: "#F5F6FB",
      boxShadow: "none",
      padding: "0 24px",
      position: "inherit",
    },
    sectionContent: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: 'space-between'
    },
  })
);

const appbar: React.FC = ({ children }) => {
  const Classes = UseStyles();
  return (
    <AppBar className={Classes.sectionDesktop}>
      <div className={Classes.sectionContent}>{children}</div>
    </AppBar>
  );
};

export default appbar;
