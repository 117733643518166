import React from "react";
import { Grid } from "@mui/material";
import { TkTaskWrapperItem, TkTypography } from "@components/index";
import { useTranslation } from "react-i18next";
import { nameSplitter } from "@helpers/index";

interface ITkDrawerCustomerName {
  customerName: string | null | undefined;
  firstNameOnly?: boolean;
}

const TkDrawerCustomerName: React.FC<ITkDrawerCustomerName> = ({
  customerName,
  firstNameOnly = false,
}) => {
  const { t } = useTranslation();

  const getFirstName = (customerName) => nameSplitter(customerName)[0];

  return (
    <TkTaskWrapperItem
      icon={"fa fa-user-tie"}
      iconStyle={{ iconSize: 14, marginRight: 10 }}
    >
      <Grid container direction="row" alignItems="center">
        <TkTypography fontFamily={"Lato"} fontSize={16}>
          {`${t("customer", { ns: "common" })}: `}
        </TkTypography>
        <TkTypography
          marginLeft={"5px"}
          fontFamily={"Lato"}
          fontSize={16}
          fontWeight={"bold"}
        >
          {customerName
            ? firstNameOnly
              ? getFirstName(customerName)
              : customerName
            : t("no_customer", { ns: "common" })}
        </TkTypography>
      </Grid>
    </TkTaskWrapperItem>
  );
};

export default TkDrawerCustomerName;
