import { lazy } from 'react';
import { withAuth } from '@contexts/AuthContext/hocs';
import { AppLocationURLs } from '@consts/index';

const TeamsPage = lazy(() => import('@pages/TeamsPage'));

const TeamRoutes = [
  {
    path: AppLocationURLs.TEAM,
    component: withAuth(TeamsPage),
  },
];

export default TeamRoutes;
