import { useCallback } from 'react';

import { useIntercom } from 'react-use-intercom';

type ContactCustomerServicerReturnType = {
  handleNewCustomerServiceMessage: (taskID: number, customer?: string) => void;
};

export const useContactCustomerService =
  (): ContactCustomerServicerReturnType => {
    const { showNewMessages } = useIntercom();

    const handleNewCustomerServiceMessage = useCallback(
      (taskID: number, customer?: string) => {
        showNewMessages(
          `Hi! I need help with my task #${taskID} ${
            customer ? `for ${customer}` : ''
          }`
        );
      },
      [showNewMessages]
    );

    return {
      handleNewCustomerServiceMessage,
    };
  };
