import styled from 'styled-components';
import { Icon } from '@mui/material';

export const Container = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 200px;
  background-color: black;
  padding: 40px 110px 20px;

  ${(props) => props.theme.breakpoints.down('xs')} {
    flex-direction: column;
    padding: 32px 16px;
  }
`;

export const ResourcesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) => props.theme.breakpoints.down('xs')} {
    margin-top: 40px;
  }
`;

export const SocialMediaLinks = styled.div`
  display: flex;
`;

export const TkStyledIcon = styled(Icon)`
  color: #fff;
  font-size: 28px;
  margin-right: 8px;
`;

export const FooterLink = styled.a`
  text-decoration: none;
  line-height: 1.8;
`;
