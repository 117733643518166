import { invitationsData } from "@recoilData/atoms";
import { selectorFamily, selector } from "recoil";

const getInvitationDataById = selectorFamily({
  key: "getInvitationDataById",
  get: (id) => ({ get }) => {
    const invitations = get(invitationsData);
    return invitations.find((invitation) => invitation.id === id);
  },
});

const getInvitationsDataCount = selector({
  key: "getInvitationsDataCount",
  get: ({ get }) => {
    const invitations = get(invitationsData);
    return invitations.length;
  },
});

const removeSelectedInvitation = selector({
  key: "removeSelectedInvitation",
  get: ({ get }) => get(invitationsData),
  set: ({ set, get }, newValue) => {
    const invitations = get(invitationsData);
    const selectedInvitation = newValue as IUserInvite;

    const newInvitations: IUserInvite[] = [];

    invitations.forEach((invitation) => {
      if (selectedInvitation.id !== invitation.id) newInvitations.push(invitation);
    });

    set(invitationsData, newInvitations);
  },
});

export { 
  getInvitationDataById,
  getInvitationsDataCount,
  removeSelectedInvitation,
};
