import React from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { TkTour } from '@components/TkTourGuide';
import { CallBackProps, Step, ACTIONS, EVENTS } from 'react-joyride';
import { isFirstTimeBookingTour } from '@recoilData/index';
import TkTourGuideToolTip from '@components/TkBookingWidget/TkBookingTour/TkBookingToolTips/TkTourGuideToolTip/TkTourGuideToolTip';
import { useMixpanel } from '@contexts/index';

const TkBookingServicesTour: React.FC = () => {
  const { t } = useTranslation();
  const [bookingTourControl, setBookingTourControl] = useRecoilState(
    isFirstTimeBookingTour
  );
  const {
    track,
    consts: {
      MIXPANEL_EVENT_NAMES_ORIGIN,
      MIXPANEL_EVENT_ACTIONS,
      MIXPANEL_PAGE_NAMES,
    },
  } = useMixpanel();

  const steps: Step[] = [
    {
      target: '#TkTabsServices',
      title: t('bookingTourSteps.TkTabsServices.title', {
        ns: 'bookingWidget',
      }),
      placement: 'bottom-start',
      disableBeacon: true,
      styles: {
        overlay: { opacity: 0.7, zIndex: 1000 },
        spotlight: {
          transition: 'margin-top 250ms',
          transform: 'scale(.93, 1)',
        },
      },
      floaterProps: {
        styles: {
          floater: { zIndex: 1000 },
          content: { backgroundColor: '#011F41' },
        },
      },
      offset: -5,
      content: t('bookingTourSteps.TkTabsServices.content', {
        ns: 'bookingWidget',
      }),
    },
    {
      target: '#basicDetails',
      title: t('bookingTourSteps.basicDetails.title', { ns: 'bookingWidget' }),
      placement: 'left',
      disableBeacon: true,
      styles: {
        overlay: { opacity: 0.7, zIndex: 1000 },
        spotlight: { maxHeight: 180 },
      },
      floaterProps: {
        styles: {
          floater: { zIndex: 1000 },
          content: { backgroundColor: '#011F41' },
        },
      },
      offset: -5,
      content: t('bookingTourSteps.basicDetails.content', {
        ns: 'bookingWidget',
      }),
    },
  ];

  const paths = [];

  const handleCallback = (data: CallBackProps) => {
    const { index, action, type } = data;
    if (index === 0 && type === EVENTS.TOUR_START) {
      track(MIXPANEL_EVENT_NAMES_ORIGIN.BOOKING_WIDGET, {
        action:
          MIXPANEL_EVENT_ACTIONS.BOOKING_WIDGET.BOOKING_TOURS
            .BOOKING_TOUR_SERVICES.BOOKING_TOUR_SERVICES_START,
        page: MIXPANEL_PAGE_NAMES.BOOKING_WIDGET.SERVICE_EDIT,
      });
      return;
    }
    const isAtEnd = index === steps.length - 1;
    if (type === EVENTS.TOUR_END) {
      isAtEnd
        ? track(MIXPANEL_EVENT_NAMES_ORIGIN.BOOKING_WIDGET, {
            action:
              MIXPANEL_EVENT_ACTIONS.BOOKING_WIDGET.BOOKING_TOURS
                .BOOKING_TOUR_SERVICES.BOOKING_TOUR_SERVICES_COMPLETE,
            page: MIXPANEL_PAGE_NAMES.BOOKING_WIDGET.SERVICE_EDIT,
          })
        : track(MIXPANEL_EVENT_NAMES_ORIGIN.BOOKING_WIDGET, {
            action:
              MIXPANEL_EVENT_ACTIONS.BOOKING_WIDGET.BOOKING_TOURS
                .BOOKING_TOUR_SERVICES.BOOKING_TOUR_SERVICES_SKIP,
            page: MIXPANEL_PAGE_NAMES.BOOKING_WIDGET.SERVICE_EDIT,
            skip: index,
          });
      setBookingTourControl({
        ...bookingTourControl,
        openDraftTour: true,
        openServiceTab: false,
        openServiceTour: false,
        stepIndex: 0,
      });
      return;
    }

    if (type === EVENTS.STEP_AFTER) {
      if (action === ACTIONS.NEXT) {
        setBookingTourControl({
          ...bookingTourControl,
          stepIndex: index + 1,
        });
      }
      if (action === ACTIONS.PREV) {
        setBookingTourControl({
          ...bookingTourControl,
          stepIndex: index - 1,
        });
      }
    }
  };

  return (
    <TkTour
      tooltip={TkTourGuideToolTip}
      paths={paths}
      steps={steps}
      handleCallback={handleCallback}
      stepIndex={bookingTourControl.stepIndex}
    />
  );
};

export { TkBookingServicesTour };
