import { LocalStorage } from '@consts/localStorage';
import useScript from '@hooks/useScript';
import { getLocationTimezoneBooking } from '@services/apiBooking';
import React, { useEffect, useState } from 'react';
import { geocodeByPlaceId } from 'react-places-autocomplete';

const useSearchAddress = ({
  address,
  errorForm,
  setAddress,
  isCustomerAddress = false,
  addressHasChanged,
  setAddressHasChanged,
}: SearchAddress) => {
  const [areAddressFieldsHidden, setAreAddressFieldsHidden] =
    useState<boolean>(true);
  const [errorFormState, setErrorForm] = useState<IErrorForm>(errorForm);

  const statelessCountries = [
    'FI',
    'GR',
    'DK',
    'HU',
    'LV', // User feedback from TBnB
    'AQ',
    'AZ',
    'BO',
    'BV',
    'IO',
    'VG',
    'KY',
    'CX',
    'CC',
    'CK',
    'EC',
    'FK',
    'GS',
    'TF',
    'GI',
    'GU',
    'HM',
    'VA',
    'KE',
    'MO',
    'MT',
    'MC',
    'CW',
    'AW',
    'SX',
    'NF',
    'UM',
    'PN',
    'BL',
    'AI',
    'MF',
    'PM',
    'RS',
    'SG',
    'TK',
    'TC',
  ];

  const requiredAddressFields = [
    'city',
    'state',
    'country',
    'address',
    'zip_code',
  ];

  const status = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`
  );

  useEffect(() => {
    setErrorForm({ address: errorForm.address });
  }, [errorForm.address]);

  useEffect(() => {
    if (!areAddressFieldsHidden) return;

    const hasAnyRequiredFieldFilled = requiredAddressFields.some((field) => {
      if (field === 'address') return false;

      return (address[field].name || address[field]).length > 1;
    });

    if (hasAnyRequiredFieldFilled) {
      displayAddressFields();
    }
  }, [address, areAddressFieldsHidden]);

  useEffect(() => {
    if (!areAddressFieldsHidden) return;

    const hasError = requiredAddressFields.some((field) =>
      addressHasErrorAt(field)
    );

    if (hasError) {
      displayAddressFields();
    }
  }, [errorFormState.address, areAddressFieldsHidden]);

  const handleSelect = (_address: string, placeId: string, type: string) => {
    let zip_code = '';
    let streetNumber = '';
    let streetAddress = '';
    let city = { name: '' };
    let state = { name: '', abbr: '' };
    let country = { name: '', abbr: '' };

    geocodeByPlaceId(placeId).then(async (addr) => {
      const timezone = await getLocationTimezoneBooking(
        addr[0].geometry.location.lat(),
        addr[0].geometry.location.lng()
      );

      localStorage.setItem(LocalStorage.BOOKING_TIMEZONE, timezone);

      if (isCustomerAddress)
        localStorage.setItem(LocalStorage.BOOKING_TIMEZONE_CUSTOMER, timezone);

      addr[0].address_components.forEach((component) => {
        switch (component.types[0]) {
          case 'street_number':
            streetNumber = component.long_name;
            break;

          case 'route':
            areAddressFieldsHidden
              ? (streetAddress = addr[0].formatted_address)
              : (streetAddress = `${streetNumber} ${component.long_name}`);
            break;

          case 'locality':
            city.name = city.name || component.long_name;
            break;

          case 'administrative_area_level_2':
            city.name = city.name || component.long_name;
            break;

          case 'administrative_area_level_1':
            state.name = component.long_name;
            state.abbr = component.short_name;
            break;

          case 'postal_code':
            zip_code = component.long_name;
            break;

          case 'country':
            country.name = component.long_name;
            country.abbr = component.short_name;
            break;

          default:
            break;
        }
      });

      let auxAddress: IBookingBusinessAddress = {
        ...address,
        city,
        state,
        country,
        zip_code,
        address: streetAddress,
      };

      const isStatelessCountry = statelessCountries.includes(country.abbr);
      if (isStatelessCountry) {
        auxAddress.state.name = 'Stateless';
        auxAddress.state.abbr = 'Stateless';
      }

      setAddress(auxAddress);
      setErrorForm({
        address: requiredAddressFields.filter(
          (field) =>
            addressHasErrorAt(field) &&
            !(auxAddress[field].name || auxAddress[field]).length
        ),
      });

      displayAddressFields();
      setAddressHasChanged && !addressHasChanged && setAddressHasChanged(true);
    });
  };

  const handleChangeValue = (
    value: string | IAddressLocation,
    index: string
  ) => {
    setAddress({
      ...address,
      [index]: value,
    });
    setErrorForm({
      address: errorFormState.address.filter(
        (field) => !field.startsWith(index)
      ),
    });
    setAddressHasChanged && !addressHasChanged && setAddressHasChanged(true);
  };

  const handleChangeState = (value: string) => {
    const state = { name: value, abbr: value } as IAddressLocation;
    const isAbleToWriteStateDirectly = !['US', 'CA'].includes(
      address.country.abbr || ''
    );
    isAbleToWriteStateDirectly && handleChangeValue(state, 'state');
  };

  const handleChangeCity = (value: string) => {
    const city = { name: value } as IAddressLocation;
    handleChangeValue(city, 'city');
  };

  const addressHasErrorAt = (type: string) =>
    !!errorFormState.address.find((field) => field.startsWith(type));

  const displayAddressFields = () => setAreAddressFieldsHidden(false);

  return {
    isStatusReady: status === 'ready',
    areAddressFieldsHidden,
    displayAddressFields,
    handleSelect,
    handleChangeCity,
    handleChangeState,
    handleChangeValue,
    addressHasErrorAt,
  };
};

export default useSearchAddress;
