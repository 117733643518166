import { selector } from "recoil";
import {
  crewsData,
  editedCrew,
  selectedCrew,
  assigneesData,
  newCrewMembers,
  teamsPageExtendedDrawer,
  isAllCrewsMembersSelected,
} from "@recoilData/index";

const resetTeamsPageExtendedDrawer = selector({
  key: "resetTeamsPageExtendedDrawer",
  get: ({ get }) => get(teamsPageExtendedDrawer),
  set: ({ reset }) => {
    reset(teamsPageExtendedDrawer);
  },
});

const removeSelectedCrew = selector<ICrew>({
  key: "removeSelectedCrew",
  get: ({ get }) => get(crewsData) as any,
  set: ({ set, get }, newValue) => {
    const crews = get(crewsData);
    const selectedCrew = newValue as ICrew;

    const newCrews: ICrew[] = [];

    crews.forEach((crew) => {
      if (selectedCrew.id !== crew.id) newCrews.push(crew);
    });

    set(crewsData, newCrews);
  },
});

const setSelectedCrew = selector<ICrew | undefined>({
  key: "setSelectedCrew",
  get: ({ get }) => get(editedCrew),
  set: ({ set, get, reset }, newValue) => {
    const crew = newValue as ICrew;
    const assignees = get(assigneesData);

    assignees.forEach((assignee) => {
      const itemIsFound = crew.members.find((a) => a.id === assignee.id);
      if (itemIsFound !== undefined) set(newCrewMembers(assignee.id), true);
      else reset(newCrewMembers(assignee.id));
    });

    set(editedCrew, newValue as ICrew);
  },
});

const setEditedCrew = selector<ICrew>({
  key: "setEditedCrew",
  get: ({ get }) => get(editedCrew),
  set: ({ set, get, reset }, newValue) => {
    const crew = newValue as ICrew;
    const assignees = get(assigneesData);

    assignees.forEach((assignee) => {
      const itemIsFound = crew.members.find((a) => a.id === assignee.id);
      if (itemIsFound !== undefined) set(newCrewMembers(assignee.id), true);
      else reset(newCrewMembers(assignee.id));
    });

    set(isAllCrewsMembersSelected, false);
    set(editedCrew, newValue as ICrew);
  },
});

const setCreateNewCrew = selector<ICrew | undefined>({
  key: "setCreateNewCrew",
  get: ({ get }) => get(selectedCrew),
  set: ({ set, get, reset }, newValue) => {
    const assignees = get(assigneesData);

    assignees.forEach((assignee) => {
      reset(newCrewMembers(assignee.id));
    });
    set(isAllCrewsMembersSelected, false);
    set(selectedCrew, newValue as ICrew);
  },
});

const getSelectedNewCrewMembers = selector({
  key: "getSelectedNewCrewMembers",
  get: ({ get }) => {
    const assignees = get(assigneesData);
    const newMembers: ICrewMember[] = [];

    assignees.forEach((assignee) => {
      const memberSelected = get(newCrewMembers(assignee.id));
      if (memberSelected)
        newMembers.push(generateMember(assignee, memberSelected));
    });

    return newMembers;
  },
});

const resetSelectedNewCrewMembers = selector({
  key: "resetSelectedNewCrewMembers",
  get: ({ get }) => {
    const assignees = get(assigneesData);
    const newMembers: ICrewMember[] = [];

    assignees.forEach((assignee) => {
      const memberSelected = get(newCrewMembers(assignee.id));
      if (memberSelected)
        newMembers.push(generateMember(assignee, memberSelected));
    });

    return newMembers;
  },
  set: ({ get, reset }) =>
    get(assigneesData).forEach((assignee) =>
      reset(newCrewMembers(assignee.id))
    ),
});

const selectAllCrewsMembers = selector<boolean>({
  key: "selectAllCrewsMembers",
  get: ({ get }) => get(isAllCrewsMembersSelected),
  set: ({ set, get }, newValue) => {
    const assignees = get(assigneesData);

    set(isAllCrewsMembersSelected, newValue as boolean);

    assignees.map((assignee) => {
      set(newCrewMembers(assignee.id), newValue as boolean);
    });
  },
});

const getSelectedNewCrewMembersCount = selector({
  key: "getSelectedNewCrewMembersCount",
  get: ({ get }) => {
    const assignees = get(getSelectedNewCrewMembers);
    let count = 0;
    assignees.forEach(() => count++);
    return count;
  },
});

const crewName = selector({
  key: "crewName",
  get: ({ get }) => get(selectedCrew)?.name,
  set: ({ set, get }, newValue) => {
    const crew = get(selectedCrew);

    if (crew !== undefined)
      set(selectedCrew, { ...crew, name: newValue as string });
  },
});

const editedCrewName = selector({
  key: "editedCrewName",
  get: ({ get }) => get(editedCrew).name,
  set: ({ set, get }, newValue) =>
    set(editedCrew, { ...get(editedCrew), name: newValue as string }),
});

const generateMember = (assignee: IAssignee, checked: boolean) => {
  const newAssignee: ICrewMember = {
    id: assignee.id,
    email: assignee.email,
    first_name: assignee.first_name ? assignee.first_name : "",
    photo_url: assignee.photo_url,
    full_name: assignee.full_name,
    last_name: assignee.last_name ? assignee.last_name : "",
    phone_number: assignee.phone_number,
    street_address: assignee.street_address,
    checked: checked,
  };

  return newAssignee;
};

export {
  crewName,
  setEditedCrew,
  editedCrewName,
  setSelectedCrew,
  setCreateNewCrew,
  removeSelectedCrew,
  selectAllCrewsMembers,
  getSelectedNewCrewMembers,
  resetSelectedNewCrewMembers,
  resetTeamsPageExtendedDrawer,
  getSelectedNewCrewMembersCount,
};
