import { PopoverOrigin } from '@mui/material';
import React, { CSSProperties, useState } from 'react';
import { PopoverContainer, PopoverListWrapper, PopoverWrapper } from './styles';

interface TkBaseDropDownProps {
  Activator: JSX.Element;
  DropdownContent: JSX.Element;
  DropdownContentStyle?: CSSProperties;
  PopoverStyle?: CSSProperties;
  anchorOrigin?: PopoverOrigin | undefined;
  transformOrigin?: PopoverOrigin | undefined;
}

const TkBaseDropDown = ({
  Activator,
  PopoverStyle,
  DropdownContent,
  DropdownContentStyle = undefined,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'right',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'right',
  },
}: TkBaseDropDownProps): JSX.Element => {
  const [toggle, setToggle] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    setToggle((prevState) => !prevState);
  };

  const handleClose = () => {
    setToggle(false);
  };

  return (
    <>
      <div onClick={handleClick}>{Activator}</div>
      <PopoverContainer
        id={toggle ? 'simple-popover' : undefined}
        open={toggle}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        <PopoverWrapper style={PopoverStyle}>
          <PopoverListWrapper style={DropdownContentStyle}>
            {DropdownContent}
          </PopoverListWrapper>
        </PopoverWrapper>
      </PopoverContainer>
    </>
  );
};

export default TkBaseDropDown;
