import { Box, Grid } from '@mui/material';
import styled from 'styled-components';

export const TkTaskWrapperItem = styled(Box)`
  display: flex;
  flex-direction: column;
  user-select: none;
  border: 1px solid rgba(73, 82, 169, 0);
  ${(props) =>
    props.bgcolor === 'selected'
      ? `background-color: #011F41; color: #ffffff;`
      : ''}
  ${(props) =>
    props.bgcolor === 'filled' &&
    'background-color: #e8f4ff;border: 1px solid #2699fb;'}
  border-radius: 4px;
`;

export const ImagePreviewCircle = styled.img`
  height: 28px;
  width: 28px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  object-fit: cover;
  margin-left: -10px;
`;

export const VerticalDivider = styled.div`
  border: 1px solid #aaaaaa;
  height: 63px;
`;

export const SelectorWrapper = styled.div`
  font: normal normal normal 16px/24px 'Lato';
  color: #4952a9;
  position: relative;
  cursor: pointer;
`;

export const ChecklistSelectedItem = styled(Grid)`
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 0 10px 0;

  .MuiGrid-container {
    align-items: center;
  }

  .MuiIcon-root {
    cursor: pointer;
  }

  &:last-child {
    border-bottom: 1px solid #ffffff;
  }
`;

export const Selector = styled.div`
  font: normal normal normal 16px/24px 'Lato';
  padding-top: 20px;
  color: #4952a9;
  position: relative;
  cursor: pointer;
`;
