import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

const ReactQueryClientProvider: React.FC = ({ children }) => {
  return (
    <QueryClientProvider
      client={
        new QueryClient({
          defaultOptions: {
            // queries: {
            //   staleTime: 1000 * 20,
            // },
          },
        })
      }
    >
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

export default ReactQueryClientProvider;
