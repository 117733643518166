import styled from 'styled-components';

import { Grid, Popover } from '@mui/material';

import { PopoverStyle } from './types.type';

export const PopoverContainer = styled(Popover)<{ marginTop: string }>`
  &.MuiPopover-root {
    margin-top: ${(props) => props.marginTop};
  }
`;

export const PopoverWrapper = styled(Grid)<PopoverStyle>`
  &.MuiGrid-root {
    min-width: ${(props) => props.width};
    height: ${(props) => props.height};
    padding: ${(props) => props.padding};
    overflow: hidden;
  }
`;

export const PopoverListWrapper = styled.div<PopoverStyle>`
  width: auto;
  max-height: 290px;
  max-width: ${(props) => props.width};
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  scrollbar-width: none;
`;
