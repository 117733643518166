import React from 'react';

import { useTranslation } from 'react-i18next';

import { Grid, Box } from '@mui/material';

import {
  renderOfferPillHeader,
  renderEventPillHeader,
  formatPillTitle,
} from './helpers';
import { PillGrid, ImgWrapper } from './styles';
import { TurnoverBNB, GoogleCalendar } from '@assets/index';
import TkIcon from '@components/TkIcon';
import { TaskbirdIntegrationsEnum, TaskStatusEnum } from '@consts/index';
import { formatDistanceFromPlace } from '@helpers/index';

const TkPillWeekView: React.FC<ITkPillContentChild> = ({
  eventInfo,
  renderAssigneeIcon,
}) => {
  const { t } = useTranslation();
  const { title, extendedProps } = eventInfo.event;
  const formattedTitle = formatPillTitle(title);

  const renderOfferPillContent = (extendedProps) => {
    const { platform } = extendedProps;

    const renderTurnoverbnbPillContent = (extendedProps) => {
      return (
        <PillGrid xs={12} alignItems="center">
          <span>
            {extendedProps.location?.alias &&
            extendedProps.status !== TaskStatusEnum.PLACEHOLDER
              ? t('noLocationSet', { ns: 'common' })
              : extendedProps.location?.alias}
          </span>
          <br />
          <span>{formattedTitle}</span>
        </PillGrid>
      );
    };

    const renderBookingPillContent = (extendedProps) => {
      return (
        <PillGrid xs={12} alignItems="center">
          <span>
            {extendedProps.location?.street_address ??
              t('noLocationSet', { ns: 'common' })}
          </span>
          <br />
          <span>{formattedTitle}</span>
        </PillGrid>
      );
    };

    const renderMoveoutPillContent = (extendedProps) => {
      return (
        <PillGrid xs={12} alignItems="center">
          <span>
            {formatDistanceFromPlace(
              extendedProps.travelDistance,
              extendedProps.travelDistanceUnit
            )}
          </span>
          <br />
          <span>{t('moveout_cleaning', { ns: 'offers' })}</span>
        </PillGrid>
      );
    };

    if (platform === TaskbirdIntegrationsEnum.MOVEOUT) {
      return renderMoveoutPillContent(extendedProps);
    }

    if (platform === TaskbirdIntegrationsEnum.BOOKING_WIDGET) {
      return renderBookingPillContent(extendedProps);
    }

    if (platform === TaskbirdIntegrationsEnum.TURNOVERBNB) {
      return renderTurnoverbnbPillContent(extendedProps);
    }
  };

  const renderEventPillContent = (extendedProps) => {
    const renderIntegrationIcon = (extendedProps: any) => {
      if (extendedProps.externalId) {
        return (
          <ImgWrapper
            src={TurnoverBNB}
            style={{ margin: '0 1.5px 3px 1.5px' }}
          />
        );
      }

      if (extendedProps.accountCalendarId) {
        return <ImgWrapper src={GoogleCalendar} />;
      }

      return '';
    };

    const { status, location } = extendedProps;

    return (
      <PillGrid xs={12} alignItems="center">
        <span>
          {!location?.street_address && status !== TaskStatusEnum.PLACEHOLDER
            ? t('noLocationSet', { ns: 'common' })
            : location?.street_address}
        </span>
        <br />
        <span>
          {renderIntegrationIcon(extendedProps)}{' '}
          {status !== TaskStatusEnum.PLACEHOLDER ? formatPillTitle(title) : ''}
        </span>
      </PillGrid>
    );
  };

  return (
    <Grid container direction="column">
      <Grid container direction="row" spacing={1}>
        <PillGrid
          item
          display="inline-flex"
          alignItems="center"
          justifyContent="flex-start"
          sm={extendedProps.status === TaskStatusEnum.DONE ? 10 : 12}
        >
          {extendedProps.status !== TaskStatusEnum.PLACEHOLDER
            ? renderAssigneeIcon(extendedProps.assigneeNames)
            : ''}
          {extendedProps.isOffer
            ? renderOfferPillHeader(extendedProps, eventInfo.view.type)
            : renderEventPillHeader(extendedProps, eventInfo.view.type)}
        </PillGrid>
        {extendedProps.status === TaskStatusEnum.DONE ? (
          <Grid item sm={2}>
            <Box textAlign="right">
              <TkIcon
                className="fa fa-check-circle"
                color="navyBlue"
                iconSize={10}
              />
            </Box>
          </Grid>
        ) : (
          ''
        )}
      </Grid>
      <Grid container direction="column" spacing={1}>
        {extendedProps.isOffer
          ? renderOfferPillContent(extendedProps)
          : renderEventPillContent(extendedProps)}
      </Grid>
    </Grid>
  );
};

export default TkPillWeekView;
