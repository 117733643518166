import { createContext } from 'react';

import { IUserIntercom } from 'types/intercomUser.type';

import { SessionData, SessionUserAccount } from './types/session';

export enum ExternalLoginEnum {
  APPLE = 'APPLE',
  GOOGLE = 'GOOGLE',
  FACEBOOK = 'FACEBOOK',
  GOOGLE_V2 = 'GOOGLE_V2',
}

export enum PermissionEnum {
  OWNER = 1,
  MANAGER = 2,
  EMPLOYEE = 3,
}

export type ExternalLoginType = `${ExternalLoginEnum}`;

interface UserDataSocialLogin {
  id_token?: string;
  social_media_provider?: string;
  existing_user_only: boolean;
}

export type GoogleUserDataSocialLogin = UserDataSocialLogin;

export interface FacebookUserDataSocialLogin extends UserDataSocialLogin {
  first_name: string;
  last_name: string;
  email: string;
  social_media_id: ExternalLoginType;
}

export interface AppleUserDataSocialLogin extends UserDataSocialLogin {
  social_media_id: ExternalLoginType;
}

export interface AuthReturnType {
  success: boolean;
  message: string;
  data?: any;
}

export interface SocialMediaSignUp {
  success: boolean;
  message: string;
  data: SessionData | null;
}

export interface IAuthContext {
  signed: boolean;
  error: boolean;
  userInfo: SessionData;
  userIntercom: IUserIntercom;
  setUserPhoneValidated(): void;
  login(email: string, password: string): Promise<AuthReturnType>;
  createAccount(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    recaptcha: string
  ): Promise<AuthReturnType>;
  logout(): void;
  socialMediaSignUp(
    userSocialData: UserDataSocialLogin,
    newSocialAccount?: boolean
  ): Promise<SocialMediaSignUp>;
  newTeamLogin: (data) => void;
  continueWithSocialMedia: () => Promise<SocialMediaSignUp>;
}

export const AuthContext = createContext({} as IAuthContext);
