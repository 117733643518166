export default {
  business_details: 'Business Details',
  services_details: 'Services Details',
  services_name: 'Service Name',
  business_details_warning_modal:
    'This information will be visible to your customers on your Booking Site',
  business_faqs: 'FAQs',
  business_faqs_question: 'Question',
  business_faqs_questions: 'Questions',
  business_faqs_questions_empty: 'There are no FAQs',
  business_faqs_questions_empty_sub_label:
    'Add FAQs that will be displayed on the Booking Site so your customers can clear up any doubts when booking.',
  business_faqs_questions_empty_states_title:
    'Answer your customers questions in advance',
  business_faqs_questions_empty_states_subtitle:
    'You can add FAQs that will be displayed on the Booking Site so your customers can clear up any doubts when booking.',
  business_faqs_questions_empty_states_button: 'Add FAQs',
  business_faqs_question_error: 'Limit characters exceeded',
  business_faqs_add_question: '+ Add a new FAQ',
  business_faqs_warning_modal:
    'Here you can add FAQs that will be displayed on the Booking Site so your customers can clear up any doubts when booking.',
  booking_setup: 'BOOKING SETUP',
  need_help_setting_up_your_booking_site:
    'Need help setting up your Booking Site?',
  get_started_now: 'Get started now!',
  booking_site_help: 'Booking Site Help',
  booking_site_help_description: 'What would you like to do?',
  take_a_tour: 'Take a tour',
  not_found_options: 'Type to options ...',
  take_a_tour_description:
    'Take a quick tour to learn how to set up your Booking Site',
  schedule_a_free_demo_call: 'Schedule a Free Demo Call',
  schedule_a_free_demo_call_description:
    'Contact one of our customer success specialists to learn how to get the most out of your Booking Site',
  dont_want_demo_now: 'I don’t want a demo now',
  demo_call_scheduled: 'Demo call scheduled',
  you_scheduled_a_demo_call: 'You scheduled a demo call for <0>{{date}}</0>',
  in_the_meantime_you_can: 'In the meantime you can take a quick tour',
  view_booking_widget: 'View Booking Site',
  copy_url: 'Copy Booking Site URL',
  business_name: 'Business Name',
  business_not_created: 'Something wrong.Business not created',
  business_email_duplicated: 'Email duplicated',
  contact_info: 'Contact Information',
  tab_Appearance: 'Appearance',
  tab_FAQs: 'FAQs',
  learn_more: 'Learn more',
  get_started: 'Get Started',
  make_it_your_own: 'Make it your own',
  booking_site_setup: 'Booking Site Setup',
  add_your_services_prices_and_times:
    'Add your services and set prices and times.',
  get_notified_via_email_and_sms:
    'Get notified about new bookings on Taskbird or via email.',
  edit_option: 'Edit Option',
  customize_your_booking_widget:
    'Customize the look and feel of your Booking Site with your business colors and logo.',
  booking_services_easier: 'Booking your services is now easier',
  let_your_customers_book_your_services:
    'Let your customers select and book your services by adding the Taskbird Booking Site to your website and sharing a link on social media.',
  what_is_your_business_category: 'What describes you best?',
  business_category_placeholder: 'Business category (e.g. Cleaning, Gardening)',
  business_category_placeholder_no_options:
    'If no matches, press enter to add a new category',
  business_category_placeholder_no_options_service:
    'If no matches, press enter to add a new service',
  start_typing_and_select_matching_options_below:
    'Start typing and select an option from the list below.',
  please_provide_your_business_information:
    'Please provide your business information',
  please_provide_your_business_information_warning:
    'This information is what your customers will see on your Booking Site. You will still receive notifications in your Taskbird account email address.',
  which_are_your_general_business_hours:
    'Share your hours of operations with your customers',
  different_hours_for_some_days:
    "Different hours for some days? Don't worry! You can edit them later",
  type_your_service_and_select_from_the_options:
    'Type your service and select from the options below',
  what_services_will_your_customers_be_able_to_book:
    'What services does your business provide?',
  add_as_many_services_message:
    'Add an unlimited amount of services for your customers to book. You can customize details later on.',
  business_info_success:
    "You've successfully entered your business information.",
  business_info_success_preview: 'Your Customizable Booking Site',
  business_info_subtitle_success_preview:
    "Create your own Booking Site to add to your website and share on your social networks. Here's an example of how it could look like:",
  business_info_continue: "Let's continue setting up your Booking site",
  placeholder: { business_details_company_name: 'Business Name' },
  please_add_a: 'Please add a {{name}}',
  please_add_an: 'Please add an {{name}}',
  add_another_social_network: 'Add another Social network',
  send_booking_requests_to_another_email:
    'Send booking requests to another email',
  send_sms_notifications_to_another_number:
    'Send SMS notifications to another number',
  booking_requests_email_address: 'Booking Requests Email Address',
  notifications_phone_number: 'Notifications Phone Number',
  draft_saved: 'Draft Saved',
  notification_attribute_saved: 'Service Option saved',
  notification_saved:
    'Your changes were successfully published on your booking Site',
  question_updated: 'Question updated',
  group_option_updated: 'Service Options group updated',
  logo_uploaded: 'Logo succesfuly uploaded',
  logo_removed: 'Logo successfully removed',
  error_removing_logo: 'Error removing logo, please try again',
  notification_not_saved: "Your changes weren't published on your booking Site",
  logo_not_updated:
    'We were unable to save your edit. Please try again with another image.',
  monday: 'Mon',
  friday: 'Fri',
  saturday: 'Sat',
  sunday: 'Sun',
  thursday: 'Thu',
  tuesday: 'Tue',
  wednesday: 'Wed',
  booking_preferences: 'Booking Preferences',
  unpublished_changes: 'Unpublished changes',
  edit_preview_option_button: 'Edit Service Options',
  edit_preview_question_button: 'Edit Question',
  unpublished_changes_service:
    'Complete editing your services to get your Booking Site running',
  unpublished_changes_service_label: 'Finish set up!',
  booking_times: 'Booking times',
  booking_timeslot_info: 'Choose the booking timeslot format',
  booking_preferences_exact_time: 'Exact time',
  booking_exact_time_info_help:
    'Use precise timeslots. e.g. appointments at 8:00am, 8:30 am, 9 am (30 min intervals)',
  booking_preferences_time_window: 'Time window',
  booking_time_window_info_help:
    'Flexibility for starting the job, e.g. 8 am to 11 am (3 hours window)',
  booking_time_window_select_label: 'Window:',
  booking_time_intervals_select_label: 'Intervals:',
  booking_lead_time: 'Booking lead time',
  booking_lead_minimum_time_appontment:
    'Minimum time an appointment can be booked before it starts',
  booking_lead_far_time_appontment:
    'How far in advance new appointments can be scheduled',
  booking_apply_preferences_to_all:
    'Apply these booking preferences to all services',
  booking_preferences_tooltip:
    'Each service can have specific booking preferences or you can apply the same general preferences to all the services at once.',
  booking_apply_schedule_to_all: 'Apply this schedule to all services',
  booking_confirmation_page: 'Booking confirmation page',
  booking_confirmation_page_info_help:
    'Select what message you would like to display on the confirmation page after booking a service.',
  booking_confirmation_page_label_message_default:
    'Default confirmation message',
  booking_confirmation_page_label_message_custom: 'Custom confirmation message',
  booking_confirmation_page_message_default:
    '"Thank you for choosing us. We will contact you soon to give you further information about your booking."',
  booking_installation_title: 'Booking Site Installation',
  booking_installation_link_updated_snackbar:
    'Link updated succesfully. Make sure to update it on your social media and website!',
  booking_installation_warning_modal:
    'Since you have changed your company name, would you like to update the Booking Site link to match it?',
  copy_url_modal_title: 'Your Booking Site is not ready',
  copy_url_modal_text:
    'In order to get your Booking Site link, first you need to finish setting up your services.',
  copy_url_modal_text_open_booking:
    'In order to view your Booking Site, first you need to finish setting up your services..',
  copy_url_modal_button: 'Edit services',
  business_details_modal_name_changed_title: 'Update Booking Site Link?',
  business_details_modal_name_changed_title_updated: 'Link Updated',
  business_details_modal_name_changed_content_updated_1:
    'This is the new link to your Booking Site:',
  business_details_modal_name_changed_content_updated_2:
    'Make sure to update it on your social media and website!',
  business_details_modal_name_changed_content:
    'You can update your Booking Site link now to match your new company name, or you can do it later on the ',
  business_details_modal_name_changed_content_installation: ' Installation',
  business_details_modal_name_changed_content_installation_page: ' page.',
  business_details_modal_name_changed_button_update: 'Update link',
  business_details_modal_name_changed_button_maybe: 'Maybe later',
  booking_installation_optionOne1: 'Option 1. Link to your Booking Site',
  learn_how_to_add_link_to_your_socials:
    'Learn how to add the link to your socials:',
  booking_add_link_to_facebook:
    'Adding your Booking Site Link to your Facebook Page',
  booking_add_link_to_instagram:
    'Adding your Booking Site Link to your Instagram',
  booking_add_link_to_facebook_step1:
    'Go to your Facebook page by clicking on the page name on the top left corner',
  booking_add_link_to_facebook_step2:
    'Open the <strong>About</strong> tab, below the profile picture',
  booking_add_link_to_facebook_step3: 'Click on <strong>Add a Website</strong>',
  booking_add_link_to_facebook_step4: 'Paste your Booking Site link and save.',
  booking_add_link_to_facebook_step5:
    'Now your link is ready and your customers will see it on your page intro',
  booking_add_link_to_instagram_step1:
    'On the Instagram mobile app, tap on <strong>Edit profile</strong>',
  booking_add_link_to_instagram_step2:
    'Tap on <strong>Bio</strong> and add a text description inviting your customers to book',
  booking_add_link_to_instagram_step3: 'Click on <strong>Add Links</strong>',
  booking_add_link_to_instagram_step4:
    'Paste your Booking Site link and tap on <strong>Done</strong>',
  booking_add_link_to_instagram_step5:
    'Now your Booking Site link will be visible in your account bio',
  booking_installation_optionOne2: ' (from social media or a web page)',
  booking_installation_optionTwo1: 'Option 2. Embed your Booking Site',
  booking_installation_optionTwo2:
    ' into an existing web page by adding the text below to the page source code (basic technical skills required)',
  booking_installation_placeholder_optionOne: 'taskbird.com/companyname',
  booking_installation_placeholder_optionTwo:
    '<script src="https://petgrooming.taskbird.com/jsbundle"></script><iframe id="booking-site-iframe" src="https://edgardcleaning.launch27.com/?w" style="border:none;width:100%;min-height:2739px;overflow:hidden" scrolling="no"></iframe>',
  booking_notification_settings_checkbox1:
    'Notify me on the Taskbird mobile app',
  booking_notification_settings_checkbox2:
    'Notify me via SMS to this phone number:',
  booking_notification_settings_subtitle: 'When a service is booked:',
  booking_notification_settings_title: 'Notification Settings',
  booking_notification_settings_update_button: 'Update notifications',
  booking_notification_settings_placeholder_phone_number: '+1 23456789',
  booking_notification_settings_email_title:
    'Send me the booking summary to this email:',
  booking_notification_settings_placeholder_email: 'company@email.com',
  booking_business_hours: 'Business hours',
  booking_business_hours_to: 'to',
  booking_business_hours_apply_to_all: 'Apply this schedule to all services',
  booking_business_hours_general: 'Use general business hours',
  booking_services_hours: 'Services hours',
  booking_services_business_hours:
    "'The general business hours were applied to this service'",
  business_services_warning_modal:
    'Set the specific hours when you provide this service, or just use the general business hours. It affects the time slots the customer can book.',
  //Services
  services_list_title: 'Services',
  services_list_title_tour: 'Complete your services ',
  services_list_subtitle_tour:
    'You need to add more information (price and time) to make your services bookable. You can also add new services, modify, and remove them. Once you are done, you can publish them.',
  services_list_subtitle:
    "A service is what your customers can book. E.g. Domestic Cleaning or Lawn Mowing. Here you'll see your services and be able to modify them, add service options, and set service hours. <0>Learn more</0>",
  services_list_button: '+ Add a new Service',
  services_list_active_button: 'Active',
  services_list_inactive_button: 'Inactive',
  services_list_complete_warning: 'Complete to publish',
  services_list_inactive_warning: 'You must have at least one active Service',
  services_list_enable_warning:
    'You must have the Service complete before activation',
  //Services/Details
  services_details_tab: 'Service details',
  services_details_name: 'Service Name',
  services_details_name_placeholder: 'Enter a name (e.g. Deep Cleaning)',
  services_details_description: 'Description (optional)',
  services_details_description_placeholder: 'Enter a description',
  services_details_duration: 'Base duration',
  services_details_duration_error: 'Please set a duration',
  services_details_name_error: 'Please set a name',
  services_details_price_error: 'Please set a price',
  services_details_placeholder: '0 min',
  services_details_duration_tooltip:
    'It is the duration of the service. The complete duration of the job is defined by the basic service duration + the durations of the service options selected by the customer.',
  services_details_duration_show_when_booking: 'Show duration when booking',
  services_details_price: 'Base price',
  services_details_price_placeholder: '$ 0',
  services_details_price_tooltip:
    'This refers to how much the service costs. The final price is defined by the basic price + the prices of the service options selected by the customer.',
  services_details_price_show_when_booking: 'Show price when booking',
  services_details_where_provided: 'Where is the service provided?',
  services_details_where_provided_at_home: 'At business location',
  services_details_where_provided_on_premises: 'At customer location',
  //Service/Attributes
  services_attributes_tab: 'Service Options',
  services_attributes_title: 'Service Options',
  services_attributes_price_charge: 'Price charge',
  services_attributes_import: '+ Import Service Options',
  services_attributes_import_tooltip:
    'Add service options that you have already created on another service.',
  services_attributes_warning:
    'Service service options allow customers to customize the services by making selections. They might affect the final price and/or duration. <0>Learn more</0>',
  services_details_import_tooltip:
    'Add service options that you have already created on another service.',
  services_attributes_add_attribute: '+ Add a new service option',
  services_attributes_add_new: 'Add a new service option',
  services_attributes_import_modal_title: 'Import service options',
  services_complete_to_publish: 'Complete to publish',
  services_select: ' Select service',
  services_attributes_import_modal_subtitle: 'Import service options from',
  services_attributes_import_modal_placeholder: 'Select service',
  services_attributes_import_modal_button_import: 'Import',
  services_attributes_import_modal_button_cancel: 'Cancel',
  //Service/Attributes Empty
  services_attributes_import_title: 'This service has no options group',
  services_attributes_import_title_empty_states:
    'Customize your service with Service Options',
  services_attributes_import_subtitle_empty_states:
    'Let your customers decide what they want for their service. You can set the choices they can select from, and the price and time that each service option adds.',
  services_attributes_import_button_empty_states:
    'Create a Service Options Group',
  services_attributes_import_subtitle:
    'You will see here the new service options you create',
  services_create_new_option: 'Create New Service Options Group',
  services_attributes_import_text:
    'Create service options so your customers can customize the services. You can set the choices they can select from, and the price and time that each service option adds.',
  services_attributes_import_button_create: 'Create new Service Options',
  services_attributes_import_copy: 'Copy Service Options from other service',
  //Service/Attributes Modal New
  services_attributes_modal_new_attribute_title_create:
    'Create a New Service Options Group',
  services_attributes_modal_new_attribute_title_edit: 'Edit Service Option',
  services_attributes_modal_customer_options:
    'Set the options that the customer can select from. Each item can have its own price and duration that will be added to the service.',
  services_attributes_modal_new_attribute_title_tooltip:
    'Service options allow customers to customize the services by making selections. They might affect the final price and/or duration.',
  services_attributes_modal_new_attribute_name: 'Group Name',
  services_attributes_modal_new_attribute_name_placeholder:
    'Enter name (e.g. Number of bedrooms to clean)',
  services_attributes_modal_new_attribute_name_error: 'Please add a Group name',
  services_attributes_modal_new_attribute_name_tooltip:
    'This name is displayed at top of this Service Options page when the customer is booking.',
  services_attributes_modal_new_attribute_description_tooltip:
    'Add some optional description if you need to clarify what the service options group is about.',
  services_attributes_modal_new_attribute_description_quantity:
    'Customer selects the amount needed for each item.',
  services_attributes_modal_new_attribute_description_single_choice:
    'Customer selects only one item among all the options.',
  services_attributes_modal_new_attribute_description_multiple_choice:
    'Customer selects one or more options.',
  services_attributes_modal_new_attribute_description:
    'Group Description (optional)',
  services_attributes_modal_new_attribute_description_error:
    'Please add an Description',
  services_attributes_modal_new_attribute_description_placeholder:
    'Enter a description',
  services_attributes_modal_new_attribute_item_name_error:
    'Please add an Item name',
  services_attributes_modal_new_attribute_item_price_error:
    'Please add an Item price',
  services_attributes_modal_new_attribute_selection_title: 'Selection Type',
  services_attributes_modal_new_attribute_selection_subtitle:
    'Define how customers will select the options.',
  services_attributes_modal_new_attribute_selection_type_single:
    'Single choice',
  services_attributes_modal_new_attribute_selection_type_single_tooltip:
    'Select only one item among all the options.',
  services_attributes_modal_new_attribute_selection_type_multiple:
    'Multiple choice',
  services_attributes_modal_new_attribute_selection_type_multiple_tooltip:
    'Select one or more items.',
  services_attributes_modal_new_attribute_quantity: 'Quantity',
  services_attributes_modal_new_attribute_selection_type_quantity:
    'Single selection',
  services_attributes_modal_new_attribute_selection_type_quantity_tooltip:
    'Select the amount needed for each item.',
  services_attributes_modal_new_attribute_selection_display_each:
    'Display each option’s price next to it when booking.',
  services_attributes_modal_new_attribute_attribute_items_title: 'Options',
  services_attributes_modal_new_attribute_attribute_items_display_price:
    'Display each items price next to it when booking',
  services_attributes_modal_new_attribute_attribute_items_subtitle:
    'Set the options that the customer can select from. Each item can have its own price and duration that will be added to the service.',
  services_attributes_modal_new_attribute_attribute_items_placeholder:
    'Option name',
  services_attributes_modal_new_attribute_attribute_items_error:
    'Please add an Option name',
  services_attributes_modal_new_attribute_attribute_items_additional_price:
    'Additional price',
  services_attributes_modal_new_attribute_attribute_items_additional_price_placeholder:
    '$ 0',
  services_attributes_modal_new_attribute_attribute_items_additional_time:
    'Additional time',
  services_attributes_modal_new_attribute_attribute_items_additional_time_placeholder:
    '0 min',
  services_attributes_modal_new_attribute_attribute_items_add_item:
    'Add another Option',
  services_attributes_modal_new_attribute_attribute_show_each_item_price:
    "Show each item's price",
  services_attributes_modal_new_attribute_attribute_show_each_item_price_tooltip:
    'Select if you want to display each item’s price next to it when selecting.',
  services_attributes_modal_new_item_button: 'Add another Option',
  services_attributes_modal_additional_time: 'Additional time',
  services_attributes_modal_additional_price: 'Additional price',
  services_attributes_modal_new_attribute_attribute_items_cancel_button:
    'Cancel',
  services_attributes_modal_new_attribute_attribute_items_create_button:
    'Create',
  services_attributes_modal_new_attribute_attribute_items_update_button:
    'Update',
  services_attributes_modal_new_attribute_success_create:
    'New service option created',
  services_questions_tab: 'Questions for Customer',
  services_hours_tab: 'Service hours',
  services_questions: 'Service questions',
  services_questions_copy: 'Copy questions from other service',
  services_questions_title: 'Questions for Customer',
  services_questions_warning:
    'Create questions to gather information from your customer and allow you to provide the best service',
  services_questions_import: '+ Import questions',
  services_questions_import_tooltip:
    'Add questions that you have already created on another service.',
  services_questions_import_all: 'Import questions',
  services_questions_import_all_from: 'Import questions from',
  services_questions_new_question: '+ Add a new question',
  services_questions_modal_title_create: 'Create a New Question',
  services_questions_modal_title_none: 'There are no Questions for Customers',
  services_questions_empty_states_title:
    'Get the info you need from your customers',
  services_questions_empty_states_subtitle:
    'Create questions for your customers to answer at the time of booking, to gather the information you need to provide the best service.',
  services_questions_empty_states_button: 'Create a Question',
  services_questions_saved: 'Question saved',
  services_questions_modal_content_create:
    'Create questions for your customers to answer at the time of booking, to gather the information you need to provide the best service.',
  services_questions_modal_title_edit: 'Create a New Question',
  services_questions_modal_tooltip:
    'Ask anything you need to your customers to gather the information you require to provide the best service.',
  services_questions_modal_question: 'Question',
  services_questions_modal_question_placeholder: 'Enter question text',
  services_item_name: 'Item name',
  services_questions_modal_question_error: 'Please add a Question',
  services_questions_modal_mandatory: 'Mandatory answer',
  services_questions_modal_type_answer: 'Type of answer',
  services_questions_modal_type_answer_tooltip:
    'Select if you want the customer to write an answer or to select among different options.',
  services_questions_modal_type_answer_text: 'Text input',
  services_questions_modal_type_answer_text_tooltip: 'Customer types an answer',
  services_questions_modal_type_answer_option: 'Option selection',
  services_questions_modal_type_answer_option_tooltip:
    'Customer selects the answer among different options',
  services_questions_modal_cancel_button: 'Cancel',
  services_questions_modal_create_button: 'Create',
  services_questions_modal_update_button: 'Update',
  services_questions_modal_new_attribute_success_create: 'New question created',
  services_success_update:
    'Your changes were successfully published on your booking Site',
  services_success_updated: 'Services successfully updated!',
  services_success_deleted: 'Service successfully deleted!',
  services_success_updated_error: "Services wasn't updated!",
  services_draft_publish_button: 'Publish',
  services_draft_discard_button: 'Discard',
  services_draft_preview_button: 'Preview',
  services_draft_preview_draft_saved: 'Draft saved',
  //services preferences
  business_services_item_warning_modal:
    'This defines when a service can be booked. You can set a specific preference for this service or use the general booking preferences.',
  business_services_item_interval: 'Intervals:',
  widget: {
    poweredBy: 'Powered by',
    informationModules: {
      contact: 'Contact',
      faqs: 'FAQs',
      summary: 'Summary',
      serviceDuration: 'Service duration',
      price: 'Price',
      basePrice: 'Base price',
    },
    wizardModules: {
      servicesStep: {
        title: 'Which service would you like to book?',
      },
      questionsStep: {
        requiredAnswer: 'Please enter an answer',
      },
      timeAndDateStep: {
        title: 'Please pick a date and time',
        requiredDate: 'Please enter a date',
        requiredTime: 'Please enter a time',
        requiredDateFirst: 'Please select a date first',
      },
      contactInfoStep: {
        title: 'Please enter your contact information',
        bookNow: 'Book Now',
        requiredYourEmail: 'Please enter your email',
        requiredYourPhoneNumber: 'Please enter your phone number',
      },
      confirmationStep: {
        title: 'All done!',
        button_label: 'Book Another Service',
        defaultConfirmationMessage:
          'Thank you for choosing us. We will contact you soon to give you further information about your booking.',
      },
    },
  },
  booking_preview_back_setup: 'Back to setup',
  booking_preview: 'Preview',
  booking_preview_info: 'This is a preview of the unpublished changes you made',
  booking_customization_service_example_faq: 'FAQs',
  booking_customization_service_example_contact: 'Contact',
  booking_customization_service_example_title:
    'Which service would you like to book?',
  booking_upload: 'Upload',
  booking_upload_logo: 'Upload Logo',
  dragNDrop: {
    title: 'Drag and drop your logo file or browse',
    dragReposition: 'Drag to reposition',
    addImage: 'Add Image',
  },
  bookingLogoModalInfo: {
    typeReminder: 'We recommend you to use SVG or PNG files with no background',
    colorReminder:
      'Make sure there is a good contrast between the logo and the header color',
    heightReminder: 'Images should be more than 70 pixels in height',
    sizeReminder: 'File size must be less than 5mb',
  },
  booking_update_logo: 'Update Logo',
  file_too_large:
    'Your image is larger than 5 MB. Please choose a smaller image.',
  file_invalid_type:
    'Invalid format. Please choose an image with the following formats: jpg, png, svg, jpeg.',
  booking_upload_new: 'Upload new',
  booking_edit_thumbnail: 'Edit thumbnail',
  booking_remove_thumbnail: 'Remove',
  primary_color: 'Primary color',
  header_color: 'Header color',
  booking_upload_ok: 'OK',
  booking_upload_cancel: 'Cancel',
  // booking installation
  booking_installation: {
    empty: {
      link: {
        message:
          'Finish editing your services and publish them to get the link',
      },
      code: {
        message:
          'Finish editing your services and publish them to get the code',
      },
    },
  },
  bookingTourSteps: {
    booking: {
      title: 'Booking Site Setup',
      content:
        'Edit your Booking Site to adapt it to your company and customer needs.',
    },
    scheduling: {
      title: 'Scheduling',
      content:
        'Set your business hours and define how your services will be booked.',
    },
    services: {
      title: 'Services',
      content:
        'Add the services you want to make available for booking to your customers. Set service options, prices and times.',
    },
    customization: {
      title: 'Customization',
      content:
        'Customize the appearance of your Booking Site to match your website and logo.',
    },
    installation: {
      title: 'Use the Booking Site',
      content:
        'Once published, link your Booking Site to your socials and embed it into your website.',
    },
    TkTabsServices: {
      title: 'Service information',
      content:
        'Navigate the sections above to define service information and booking details',
    },
    basicDetails: {
      title: 'Service duration and price',
      content:
        'Set how much time your service takes and the price. Later, you can add service options to customize your service, which can add extra time and money.',
    },
    foot: {
      title: 'Draft',
      content:
        'As you make changes, they are saved on a draft. You can continue editing later and publish it when you are ready.',
    },
    draftsSaved: {
      title: 'Preview',
      content:
        'You can check your Booking Site at any time to preview how your customers will see it.',
    },
    dragNDrop: {
      title: 'Drag and drop your logo file or browse',
      dragReposition: 'Drag to reposition',
      addImage: 'Add Image',
    },
    bookingLogoModalInfo: {
      typeReminder:
        '"We recommend you to use SVG or PNG files with no background',
      colorReminder:
        'Make sure there is a good contrast between the logo and the header color',
      heightReminder: 'Images should be more than 70 pixels in height',
      sizeReminder: 'File size must be less than 5mb',
    },
  },
  new_service_options_created: 'New service options created',
  searchAddress: {
    apt_unit: 'Unit #, Building Name, etc. (optional)',
    country: 'Country',
    state: {
      US: 'State',
      CA: 'Province/Territory',
      default: 'State/Province/Region',
    },
    zip_code: {
      US: 'ZIP code',
      CA: 'Postal code',
      default: 'ZIP/Postal code',
    },
  },
};
