import styled from 'styled-components';
import Drawer from '@mui/material/Drawer';
import { getColorFromTheme } from '@helpers/index';

const drawerWidth = '420px';

const renderShadows = (anchor: any) => {
  const shadows = {
    right: '-3px 3px 6px',
    left: '3px 3px 6px',
    top: '',
    bottom: '',
  };
  return anchor === undefined
    ? ''
    : `box-shadow: ${shadows[anchor]} #00000014;`;
};

export const TkDrawerWrapper = styled(Drawer)`
  .MuiPaper-root {
    width: ${(props) =>
      props['aria-setsize'] ? `${props['aria-setsize']}px` : drawerWidth};
    ${(props) => renderShadows(props.anchor)}
    color: ${(props) => getColorFromTheme(props.theme.palette, 'navyBlue')};
    font-family: 'Muli';
    overflow: hidden;
    position: absolute;
  }
`;

export const TkExtendedDrawerWrapper = styled(TkDrawerWrapper)`
  .MuiPaper-root {
    width: auto;
    right: ${drawerWidth};
    z-index: 1199;
    position: absolute;
  }
`;
