import { crewsData } from "@recoilData/index";
import { selectorFamily, selector } from "recoil";

const searchCrewsData = selectorFamily({
  key: "searchCrewsData",
  get: (search: string) => ({ get }) => {
    const crews = get(crewsData);
    if (!search) return crews;
    return crews.filter((item) => {
      return item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase());
    });
  },
});

const getCrewDataById = selectorFamily({
  key: "getCrewDataById",
  get: (id) => ({ get }) => {
    const crews = get(crewsData);
    return crews.find((crew) => crew.id === id);
  },
});

const getCrewsDataCount = selector({
  key: "getCrewsDataCount",
  get: ({ get }) => {
    const crews = get(crewsData);
    return crews.length;
  },
});

export {
  getCrewDataById,
  searchCrewsData,
  getCrewsDataCount
};
