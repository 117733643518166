export default {
  about: 'Acerca de',
  accept: 'Aceptar',
  access_code: 'Código de acceso',
  address: 'Dirección',
  apartmentNumber: 'Unidad N°, Nombre del edificio, etc.',
  apple: 'Apple',
  apt_unit: 'Depto. / Unidad',
  back: 'Atrás',
  cancel: 'Cancelar',
  changes_successfully_saved: 'Cambios guardados con éxito',
  city: 'Ciudad',
  code: 'Código',
  connect: 'Conectar',
  contact: 'Contactar',
  couldnt_find_your_address: 'No pudiste encontrar tu dirección?',
  create: 'Crear',
  customer: 'Cliente',
  date: 'Fecha',
  defaultError: 'Ocurrió un error. Por favor, intenta de nuevo más tarde.',
  delete: 'Eliminar',
  disabled: 'Deshabilitado',
  discard: 'Descartar',
  dontSend: 'No enviar',
  downloading: 'Descargando',
  downloadItem: 'Descargar {{item}}',
  downloadXItems: 'Descargar {{count}} {{item}}',
  edit: 'Editar',
  email_address: 'Dirección de email',
  email: 'Email',
  emailPlaceholder: 'nombre@email.com',
  enabled: 'Habilitado',
  enabledBool_plural: '$t(common:disabled)',
  enabledBool: '$t(common:enabled)',
  get_started: 'Comienza a usar',
  greet: 'Anulando',
  guidance_images: 'Imágenes orientativas',
  guidance_notes: 'Notas orientativas',
  image: 'Imagen',
  images: 'Imágenes',
  lets_go: 'Comenzar',
  loading: 'Cargando',
  location: 'Ubicación',
  name: 'Nombre',
  name: 'Nombre',
  next: 'Siguiente',
  no_images: 'Sin imágenes',
  no_notes: 'Sin notas',
  notes: 'Notas',
  ok: 'OK',
  optional: 'opcional',
  permissions: 'Permisos',
  phone_number: 'Número de teléfono',
  please_select_a_time: 'Por favor, selecciona un horario',
  preview: 'Vista previa',
  previous: 'Anterior',
  profile_url: 'Enlace del perfil',
  publish: 'Publicar',
  reject: 'Rechazar',
  role: 'Función',
  save_changes: 'Guardar cambios',
  select_all_plural: 'Deseleccionar todo',
  select_all: 'Seleccionar todo',
  select_crews: 'Seleccionar equipos',
  select_teammates: 'Selecciona compañeros de equipo',
  send_message: 'Enviar un mensaje',
  send: 'Enviar',
  sendMeTheApp: '¡Enviarme la app por mensaje!',
  skip: 'Saltar',
  social_networks: 'Redes sociales',
  state: 'Estado',
  task: 'Tarea',
  teammates: 'Compañeros de equipo',
  two_more: '2 más',
  type_phone_number: 'Escribe un número de teléfono',
  unselect_all: 'Deseleccionar todo',
  xItems: '{{number}} elementos',
  xOfYDone: '{{x}}/{{y}} hechos',
  zip_code: 'Código postal / Zip',
  weekdays: {
    sunday: 'Domingo',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado',
  },
  months: {
    january: 'Enero',
    february: 'Febrero',
    march: 'Marzo',
    april: 'Abril',
    may: 'Mayo',
    june: 'Junio',
    july: 'Julio',
    august: 'Agosto',
    september: 'Septiembre',
    october: 'Octubre',
    november: 'Noviembre',
    december: 'Diciembre',
  },
  dontSend: 'No enviar',
  send: 'Enviar',
  about: 'Acerca de',
};
