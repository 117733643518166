import styled from 'styled-components';

import {
  palette,
  PaletteProps, //eslint-disable-line
  spacing,
  SpacingProps, //eslint-disable-line
} from '@mui/system';

import { Input } from '@mui/material';

const TkInput = styled(Input)<PaletteProps & SpacingProps>`
  ${palette};
  ${spacing};
  background: '#FFF';
  border-color: '#F5F6FB';

  &:hover {
    border-color: '#011f41';
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;

export default TkInput;
