import styled from 'styled-components';
import { withStyles, Container, Box, Grid } from '@material-ui/core';

export const TkOffersDetailsWrapper = withStyles({
  root: {
    display: 'flex',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    flexDirection: 'column',
    padding: '0',
    position: 'relative',
    minHeight: '93%',
  },
})(Container);

export const TkTaskActions = withStyles({
  root: {
    borderTop: '1px solid #999999',
    display: 'flex',
    minHeight: '65px',
    backgroundColor: 'white',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
})(Container);

export const TkTaskWrapperItem = styled(Box)`
  display: flex;
  flex-direction: column;
  user-select: none;
  border: 1px solid rgba(73, 82, 169, 0);
  ${(props) =>
    props.bgcolor === 'selected' &&
    'background-color: #011F41; color: #ffffff;'}
  ${(props) =>
    props.bgcolor === 'filled' &&
    'background-color: #e8f4ff;border: 1px solid #2699fb;'}
  border-radius: 4px;
`;

export const TkTaskWrapperItemFilled = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(73,82,169,0.1)',
    padding: '0',
    userSelect: 'none',
  },
})(Grid);

export const TkTaskWrapperSubItem = styled(Grid)`
  padding: 0.5rem;
  margin: 0.5rem;
  border-radius: 4px;
  border: 1px solid rgba(73, 82, 169, 0);
  ${(props) =>
    props.color === 'selected'
      ? 'background-color: rgba(73, 82, 169, 0.1);border: 1px solid #2699fb;'
      : ''}
`;

export const HorizontalDivider = styled.div`
  background-color: ${(props) =>
    props.color === 'solid' ? '#c8c7cc' : '#F0F0F0'};
  height: 1px;
  width: 100%;
`;

const TkExtendedBase = withStyles({
  root: {
    '*::-webkit-scrollbar': {
      display: 'none',
    },
  },
})(Container);

export const TkExtendedWrapper = styled(TkExtendedBase)`
  display: flex;
  flex: 1;
  ${(props) => (props.maxWidth === 'lg' ? 'width: 408px' : 'width: 282px')};
  ${(props) => (props.maxWidth === 'xl' ? 'width: 583px' : '')};
  padding-top: 1rem;
  padding-right: 1rem;
  flex-direction: column;
  overflow: hidden;
`;
