import { TkTabs } from '@components/TkTeams/TkTabs';
import { AppLocationURLs } from '@consts/index';
import {
  NotificationHandler,
  NotificationMeta,
} from '@hooks/notification/types';

const getRoute = () => ({
  url: `${AppLocationURLs.TEAM}/tabID=${TkTabs.Members}`,
  drawer: '',
});

const getAttributes = (
  notificationMetadata: NotificationMeta
): NotificationMeta => ({
  type: notificationMetadata.type,
  memberId: notificationMetadata.memberId,
});

export default {
  getRoute,
  getAttributes,
} as NotificationHandler;
