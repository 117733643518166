import React from 'react';

import { TkDrawerWrapper } from './styles';

const TkBaseDrawer: React.FC<ITkDrawer> = ({
  children,
  open,
  anchor = 'right',
  variant = 'persistent',
  size = 420,
}) => {
  return (
    <TkDrawerWrapper
      open={open}
      anchor={anchor}
      variant={variant}
      transitionDuration={300}
      aria-setsize={size}
    >
      {children}
    </TkDrawerWrapper>
  );
};

export default TkBaseDrawer;
