import React, { Suspense } from 'react';

import { Route } from 'react-router-dom';

import { CircularProgress } from '@mui/material';

import { BlockResponsive } from '@components/index';

export const TkRoute = ({ component: Component, ...rest }): JSX.Element => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <Route
        {...rest}
        render={(props) => {
          return (
            <BlockResponsive
              active={rest.blockResponsive}
              isBookingSite={!!rest.blockResponsiveBookingSite}
            >
              <Component {...props} />
            </BlockResponsive>
          );
        }}
      />
    </Suspense>
  );
};
