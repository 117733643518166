import styled from 'styled-components';

import TkButton from '../TkButton';
import TkTypography from '../TkTypography';
import {
  Toolbar,
  Paper,
  AppBar,
  Container,
  IconButton,
} from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';

export const AppRootContainer = styled.div`
  min-height: 100vh;
  width: 100%;
  position: absolute;
`;

export const TkToolbar = styled(Toolbar)`
  min-height: 60px;
`;

export const TkIconButton = styled(IconButton)`
  padding: 0;
  cursor: pointer;
`;

export const TkAppBar = styled(AppBar)`
  background-color: white;
  justify-content: center;
  align-content: center;
  position: relative;
`;

const Image = styled.img`
  width: 150px;
  height: auto;
  cursor: pointer;
`;

export const TkLogo = styled(Image)`
  width: 150px;
`;

export const TkStoreBadge = styled(Image)`
  width: 100%;
  padding-right: 37.5px;
`;

export const TkContainer = styled(Container)`
  padding: 3rem 100px;
  position: relative;
  z-index: 1;

  @media (max-width: 876px) {
    padding: 0 15px;
  }
`;

export const TkAppBarButtonsContainer = styled.div`
  justify-content: space-around;
  margin-left: auto;
`;

export const BlueGradient = styled.div`
  height: 288px;
  width: 100%;
  position: absolute;
  z-index: 0;

  background: transparent linear-gradient(180deg, #2a9bd9 0%, #4a4da6 100%) 0%
    0% no-repeat padding-box;
`;

export const TkContentContainer = styled(Paper)`
  margin-bottom: 250px;
  ${(props) => props.theme.breakpoints.down('xs')} {
    margin-bottom: 330px;
  }

  background-color: transparent;
`;

export const ModalContainer = styled(Container)`
  width: 50%;
  border-radius: 8px;
  box-shadow: 0px 3px 10px #0000001a;
  padding: ${(props) => props.theme.spacing(2, 4, 3)};
  background-color: white;
`;

export const ModalHeader = styled(MuiDialogTitle)`
  display: flex;
  justify-content: flex-end;
  padding: 0px;
`;

export const ModalCloseButton = styled(IconButton)`
  margin: -12px;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px;
`;

export const TextContainer = styled.div`
  justify-content: left;
`;

export const HeaderText = styled(TkTypography)`
  font-size: 28px;
  font-weight: bold;
  color: ${(props) => props.theme.palette.navyBlue};
`;

export const SubtitleText = styled(TkTypography)`
  font-size: 22px;
  font-weight: medium;
  padding: 10px 0px 10px 0px;
  color: ${(props) => props.theme.palette.navyBlue};
`;

export const PhoneInputButton = styled(TkButton)`
  height: 40px;
  border-radius: 0 7px 7px 0;
  font-weight: bold;
  width: 100%;
`;

export const MessageCloseButton = styled(IconButton)`
  padding: 9px;
`;

export const MessageText = styled(TkTypography)`
  font-size: 18px;
  font-weight: normal;
  padding: 0px;
  margin-left: 10px;
  color: ${(props) => props.theme.palette.navyBlue};
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 10px;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const MobileMenuContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.palette.navyBlue};
`;

export const MobileMenuOptionList = styled.div`
  padding: 0 16px;
  margin-top: 18.75px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const TkResponsiveButton = styled(TkButton)`
  ${(props) => props.theme.breakpoints.down('xs')} {
    padding: 0;
    height: 48px;
    justify-content: flex-start;
  }
`;

export const TkResponsiveTypography = styled(TkTypography)`
  ${(props) => props.theme.breakpoints.down('xs')} {
    font-size: 16px;
  }
`;

export const AppStoresContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: 37.5px;
`;
