export enum TaskbirdIntegrationsEnum {
  MOVEOUT = 'moveout',
  TURNOVERBNB = 'turnoverbnb',
  GOOGLE = 'googleCalendar',
  BOOKING_WIDGET = 'bookingWidget',
}
export enum TaskbirdIntegrationsEnumAPI {
  MOVEOUT = 'moveout.com',
  TURNOVERBNB = 'turnoverbnb',
  GOOGLE = 'google',
  BOOKING_WIDGET = 'booking',
}
export enum TaskbirdIntegrationsEnumCode {
  MOVEOUT = 1,
  TURNOVERBNB = 2,
  GOOGLE = 3,
  BOOKING = 4,
  TASKBIRD = 5,
}
