import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

  }

  body {
    -webkit-font-smoothing: antialiased;
  }

  button {
    cursor: pointer;
  }


  body > #root {
    height: 100vh;
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
`;
