import React, { createContext, useContext } from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

interface IBookingWidgetContext {
  isIframePage: boolean;
  breakpointUpMd: boolean;
}

export const BookingWidgetContext = createContext({} as IBookingWidgetContext);

const BookingWidgetProvider = ({ isIframePage = false, children }) => {
  const theme = useTheme();
  const breakpointUpMd = useMediaQuery(theme.breakpoints.up('md'));

  const hooks = {
    isIframePage,
    breakpointUpMd
  };

  return (
    <BookingWidgetContext.Provider value={hooks}>
      {children}
    </BookingWidgetContext.Provider>
  );
};

const useBookingWidgetContext = () => useContext(BookingWidgetContext);

export { BookingWidgetProvider, useBookingWidgetContext };
