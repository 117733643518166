import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { Paper, Grid } from '@mui/material';

import {
  ModalContainer,
  ModalHeader,
  ModalCloseButton,
  ModalContent,
  TextContainer,
  HeaderText,
  SubtitleText,
  PhoneInputButton,
  MessageCloseButton,
  Message,
  MessageText,
  MessageContainer,
} from './styles';
import { useSnackbar } from '@components/TkSnackbar/useSnackbar';
import { TkIcon } from '@components/index';
import { ApiResultsKind } from '@consts/api';
import { useReCaptchaVerify } from '@hooks/index';
import { apiMethods } from '@services/index';

type SendMeTheAppModalProps = {
  onClose(): void;
};

type ResponseMessageProps = SendMeTheAppModalProps & {
  error: boolean;
};

const ResponseMessage = ({ error, onClose }: ResponseMessageProps) => (
  <MessageContainer>
    {error ? (
      <Message>
        <TkIcon iconSize={22} className="fas fa-times-circle" color="red" />
        <MessageText>Something went wrong. Please try again</MessageText>
      </Message>
    ) : (
      <Message>
        <TkIcon iconSize={22} className="fas fa-check-circle" color="green" />
        <MessageText>The app was successfully send to your phone!</MessageText>
      </Message>
    )}
    <MessageCloseButton onClick={onClose}>
      <TkIcon iconSize={22} className="fas fa-times" color="gray" />
    </MessageCloseButton>
  </MessageContainer>
);

const SendMeTheAppModal = ({
  onClose,
}: SendMeTheAppModalProps): JSX.Element => {
  const { t } = useTranslation();
  const { sendMeTheApp } = apiMethods;
  const [phone, setPhone] = useState('');
  const { fireTSuccess, fireTError } = useSnackbar();
  const handleReCaptchaVerify = useReCaptchaVerify();
  const [isLoading, setIsLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [error, setError] = useState(true);

  const handleSend = async (): Promise<void> => {
    setIsLoading(true);

    if (phone.length > 0) {
      try {
        const recaptcha = await handleReCaptchaVerify('sendTheAppToSmS');

        if (!recaptcha) return;

        sendMeTheApp(phone, recaptcha).then((data) => {
          if (data.kind === ApiResultsKind.OK) {
            fireTSuccess(data.token, {});
            return;
          }
          fireTError(data.token, {});
        });
        setShowMessage(true);
        setError(false);
        setIsLoading(false);
      } catch (e) {
        setShowMessage(true);
        setError(true);
        setIsLoading(false);
      }
    } else {
      setShowMessage(true);
      setError(true);
      setIsLoading(false);
    }
  };

  return (
    <ModalContainer>
      <ModalHeader>
        <ModalCloseButton onClick={onClose}>
          <TkIcon iconSize={22} className="fas fa-times" color="navyBlue" />
        </ModalCloseButton>
      </ModalHeader>
      <ModalContent>
        <TextContainer>
          <HeaderText>{t('welcomeSetupModal.getStartRightNow')}</HeaderText>
          <SubtitleText>{t('sendMeTheAppModal.title')}</SubtitleText>
        </TextContainer>
        <Grid container spacing={0} xs={12} direction="row">
          <Paper
            elevation={3}
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
            }}
          >
            {showMessage ? (
              <ResponseMessage
                error={error}
                onClose={() => setShowMessage(false)}
              />
            ) : (
              <>
                <Grid item sm={9} md={10}>
                  <PhoneInput
                    inputStyle={{
                      height: 40,
                      width: '100%',
                      border: 0,
                    }}
                    inputProps={{
                      required: true,
                      autoFocus: true,
                    }}
                    buttonStyle={{ border: 0 }}
                    placeholder="Your number"
                    value={phone}
                    onChange={setPhone}
                  />
                </Grid>
                <Grid item sm={3} md={2}>
                  <PhoneInputButton
                    bgcolor="babyBlue"
                    color="white"
                    variant="contained"
                    onClick={handleSend}
                  >
                    {isLoading
                      ? t('sendMeTheAppModal.texting')
                      : t('sendMeTheAppModal.textMeTheApp')}
                  </PhoneInputButton>
                </Grid>
              </>
            )}
          </Paper>
        </Grid>
      </ModalContent>
    </ModalContainer>
  );
};

export default SendMeTheAppModal;
