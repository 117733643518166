import React from 'react';

import { AdditionalInfoDrawer } from '@components/Drawers/TkEditTaskDrawer/ExtendedDrawers/TkDrawerTurnoAdditionalInfo/AdditionalInfoDrawer';
import { selectedOffer, offersDetailsExtendedDrawer } from '@recoilData/index';

export const TkOfferAdditionalTurno = (): JSX.Element => {
  return (
    <AdditionalInfoDrawer
      taskState={selectedOffer}
      extendedDrawerState={offersDetailsExtendedDrawer}
    />
  );
};
