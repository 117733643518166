import { DateTime } from 'luxon';

const getLocaleTokenWeekFromDate = (date: Date) => {
  let luxonDate = DateTime.fromJSDate(date);
  const offset = date.getTimezoneOffset();

  if (offset > 0) {
    luxonDate = DateTime.fromJSDate(date).plus({ day: 1 });
  }

  const weekday = luxonDate.weekdayLong.toLowerCase();

  return { weekDayToken: `weekdays.${weekday}`, day: luxonDate.day };
};

export { getLocaleTokenWeekFromDate };
