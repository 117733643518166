import React from 'react';

import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import { TkIcon, TkTooltip, TkTypography } from '@components/index';
import { getOfferPricingLabel } from '@features/projectOffers/utils';
import { formatValueToCurrency } from '@helpers/index';

interface ITkDrawerPrice {
  price?: string | number;
  paymentType: 'HOURLY' | 'PER_PROJECT' | 'PER PROJECT';
  client?: string;
}

const TkDrawerPrice = ({ price, paymentType, client }: ITkDrawerPrice) => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="space-between">
      <Grid
        container
        direction="row"
        style={{ margin: '0 0 5px 0', maxWidth: '80%' }}
      >
        <TkIcon
          className="fa fa-credit-card"
          iconSize={14}
          width={16}
          marginTop={5}
          marginRight={7}
        />
        <TkTypography fontSize={16} fontFamily="Lato">
          {t(getOfferPricingLabel(paymentType, client), {
            ns: 'offers',
            price: formatValueToCurrency(Number(price)),
          })}
        </TkTypography>
      </Grid>
      <Grid>
        <TkTooltip
          placeholder="top"
          title={`${t('onlyAdminsCanSeePrice', { ns: 'offers' })}`}
        >
          <TkIcon
            className="fa fa-eye-slash"
            cursor="pointer"
            iconSize={14}
            width={18}
            marginTop={5}
          />
        </TkTooltip>
      </Grid>
    </Grid>
  );
};

export default TkDrawerPrice;
