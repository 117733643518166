import { atom, atomFamily } from "recoil";

const eventCrews = atomFamily<boolean, number>({
  key: "eventCrews",
  default: false,
});

const isAllEventCrewsSelected = atom<boolean>({
  key: "isAllEventCrewsSelected",
  default: false,
});

export { eventCrews, isAllEventCrewsSelected };
