import React from 'react';

import { useTranslation } from 'react-i18next';

import TkChecklistItem from './TkChecklistItemMemo';
import { Selector } from './styles';
import { TkTaskWrapperItem } from '../styles';
import {
  TkTypography,
  TkTaskWrapperItem as TkTaskWrapperItemDrawer,
} from '@components/index';
import { TaskStatusEnum, EventTypeEnum } from '@consts/index';

interface ITkDrawerChecklistListing {
  checklists: IChecklistTask[];
  type: `${EventTypeEnum}` | 'offer' | 'editRepeat';
  status?: string;
  hideIcon?: boolean;
  boldTittle?: boolean;
  handleChecklists?: () => void;
}

const TkDrawerChecklistListing: React.FC<ITkDrawerChecklistListing> = ({
  type,
  status = TaskStatusEnum.TODO,
  checklists,
  hideIcon = false,
  boldTittle = false,
  handleChecklists,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <TkTaskWrapperItemDrawer
        icon={!hideIcon ? 'fa fa-tasks' : undefined}
        iconStyle={{ iconSize: 16 }}
      >
        <TkTypography
          fontSize={16}
          fontFamily="Lato"
          fontWeight={boldTittle ? 'bold' : 'normal'}
        >
          {t('checklists', { ns: 'common' })}
        </TkTypography>
      </TkTaskWrapperItemDrawer>
      <TkTaskWrapperItem marginTop="5px">
        {checklists.map((checklist) => (
          <TkChecklistItem
            key={checklist.id}
            checklist={checklist}
            type={type}
            eventChecklists={checklists}
            status={status}
          />
        ))}
      </TkTaskWrapperItem>
      <Selector onClick={handleChecklists}>
        <TkTypography
          fontWeight="bold"
          fontFamily="Lato"
          fontSize={16}
          color="primary"
          userselect="none"
        >
          + {t('checklists', { ns: 'events' })}
        </TkTypography>
      </Selector>
    </>
  );
};

export default TkDrawerChecklistListing;
