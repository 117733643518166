import { atom, atomFamily } from "recoil";

const filterAssignees = atomFamily<boolean, number>({
  key: "filterAssignees",
  default: false,
});

const isAllFilterAssigneesSelected = atom<boolean>({
  key: "isAllFilterAssigneesSelected",
  default: false,
});

export { filterAssignees, isAllFilterAssigneesSelected };
