import { lazy } from 'react';
import { AppLocationURLs } from '@consts/index';
import { withAuth } from '@contexts/AuthContext/hocs';

const ChatPage = lazy(() => import('@pages/ChatPage'));

const ChatRoutes = [
  {
    path: AppLocationURLs.CHAT,
    component: withAuth(ChatPage),
  },
];

export default ChatRoutes;
