import React from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { TkTour } from '@components/TkTourGuide';
import { CallBackProps, Step, ACTIONS, EVENTS } from 'react-joyride';
import { isFirstTimeBookingTour } from '@recoilData/index';
import TkTourGuideToolTip from '@components/TkBookingWidget/TkBookingTour/TkBookingToolTips/TkTourGuideToolTip/TkTourGuideToolTip';
import { useMixpanel } from '@contexts/index';

const TkBookingDraftTour: React.FC = () => {
  const { t } = useTranslation();
  const [bookingTourControl, setBookingTourControl] = useRecoilState(
    isFirstTimeBookingTour
  );
  const {
    track,
    consts: {
      MIXPANEL_EVENT_NAMES_ORIGIN,
      MIXPANEL_EVENT_ACTIONS,
      MIXPANEL_PAGE_NAMES,
    },
  } = useMixpanel();

  const steps: Step[] = [
    {
      target: '#foot-wrapper-warning',
      title: t('bookingTourSteps.foot.title', { ns: 'bookingWidget' }),
      placement: 'top',
      disableBeacon: true,
      styles: {
        overlay: { opacity: 0.7, zIndex: 1000 },
        spotlight: { maxHeight: 180, minWidth: 210 },
      },
      floaterProps: {
        styles: {
          floater: { zIndex: 1000 },
          content: { backgroundColor: '#011F41' },
        },
      },
      offset: -5,
      content: t('bookingTourSteps.foot.content', { ns: 'bookingWidget' }),
    },
    {
      target: '#draftsSaved',
      title: t('bookingTourSteps.draftsSaved.title', { ns: 'bookingWidget' }),
      placement: 'top',
      disableBeacon: true,
      styles: {
        overlay: { opacity: 0.7, zIndex: 1000 },
        spotlight: { maxHeight: 180, minWidth: 210 },
      },
      floaterProps: {
        styles: {
          floater: { zIndex: 1000 },
          content: { backgroundColor: '#011F41' },
        },
      },
      offset: -5,
      content: t('bookingTourSteps.draftsSaved.content', {
        ns: 'bookingWidget',
      }),
    },
  ];

  const paths = [];

  const handleCallback = (data: CallBackProps) => {
    const { index, action, type } = data;

    const isAtEnd = index === steps.length - 1;

    if (type === EVENTS.TOUR_END) {
      isAtEnd
        ? track(MIXPANEL_EVENT_NAMES_ORIGIN.BOOKING_WIDGET, {
            action:
              MIXPANEL_EVENT_ACTIONS.BOOKING_WIDGET.BOOKING_TOURS
                .BOOKING_TOUR_EDIT_SERVICE.BOOKING_TOUR_EDIT_SERVICE_COMPLETE,
            page: MIXPANEL_PAGE_NAMES.BOOKING_WIDGET.SERVICE_EDIT,
          })
        : track(MIXPANEL_EVENT_NAMES_ORIGIN.BOOKING_WIDGET, {
            action:
              MIXPANEL_EVENT_ACTIONS.BOOKING_WIDGET.BOOKING_TOURS
                .BOOKING_TOUR_EDIT_SERVICE.BOOKING_TOUR_EDIT_SERVICE_SKIP,
            page: MIXPANEL_PAGE_NAMES.BOOKING_WIDGET.SERVICE_EDIT,
            skip: index,
          });
      setBookingTourControl({
        ...bookingTourControl,
        openDraftTour: false,
        openServiceDraft: false,
        stepIndex: 0,
      });
      return;
    }

    if (type === EVENTS.STEP_AFTER) {
      if (action === ACTIONS.NEXT) {
        setBookingTourControl({
          ...bookingTourControl,
          stepIndex: index + 1,
        });
      }
      if (action === ACTIONS.PREV) {
        setBookingTourControl({
          ...bookingTourControl,
          stepIndex: index - 1,
        });
      }
    }
  };

  return (
    <TkTour
      tooltip={TkTourGuideToolTip}
      paths={paths}
      steps={steps}
      handleCallback={handleCallback}
      stepIndex={bookingTourControl.stepIndex}
    />
  );
};

export { TkBookingDraftTour };
