import React from 'react';
import ErrorPage from "./ErrorPage";
import { ErrorBoundary } from "react-error-boundary";
export default function ReactErrorBoundary(props) {
  let isDevelopmentCheck = process.env.NODE_ENV === 'development';
  return (
    <ErrorBoundary
      FallbackComponent={ErrorPage}
      onError={(error, errorInfo) => {
        // log the error
        if (isDevelopmentCheck) {
          console.log("Error caught!");
          console.error(error);
          console.error(errorInfo);
        }

        // record the error in an APM tool...
      }}
      onReset={() => {
        // reloading the page to restore the initial state
        // of the current page
        if (isDevelopmentCheck) {
          console.log("reloading the page...");
        }
        window.location.reload();

        // other reset logic...
      }}
    >
      {props.children}
    </ErrorBoundary>
  );
}
