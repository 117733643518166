import app from './app';
import common from './common';
import dates from './dates';
import integrationsPage from './integrationsPage';
import modalAlert from './modalAlert';
import phoneVerification from './phoneVerification';
import teamsPage from './teamsPage';
import unavailableFeature from './unavailableFeature';
import webOnboarding from './webOnboarding';

export default {
  app,
  common,
  dates,
  integrationsPage,
  modalAlert,
  phoneVerification,
  teamsPage,
  unavailableFeature,
  webOnboarding,
};
