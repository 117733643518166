export default {
  login: {
    title: 'Connectez-vous avec e-mail',
  },
  event: {
    errors: {
      repeating_task_edit_dates_error:
        "Les dates d'une tâche récurrente ne peuvent pas être modifiées",
      imported_task_edit_dates_error:
        "Les dates d'une tâche importée ne peuvent pas être modifiées",
    },
  },
  navbar: {
    tasks: 'Tâches',
    team: 'Équipe',
    customers: 'Client(e)s',
    checklists: 'Listes de tâches',
    bookingWidget: 'Booking Site',
    integrations: 'Intégrations',
  },
  tkTags: {
    bookingWidget: 'Booking Site',
  },
  others: {
    invalidCode:
      'Le code que vous avez renseigné est expiré ou utilisé par un autre compte. Veuillez réessayer',
  },

  error: {
    somethingWentWrong: 'Quelque chose s’est mal passé',
    tryAgain: 'Réessayer',
  }
};
