import React from 'react';

import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import { StoreIconsWrapper } from './styles';
import { GooglePlayBlackBadge, AppStoreBlackBadge } from '@assets/index';
import { TkTypography, TkSendMeTheApp } from '@components/index';
import { decodeHTML } from '@helpers/decode';

interface IAllDoneJoinTeam {
  name: string;
  adminOnly: boolean;
  teamName: string;
}

const AllDoneJoinTeam = ({
  name,
  adminOnly,
  teamName,
}: IAllDoneJoinTeam): JSX.Element => {
  const { t } = useTranslation();

  const PLAY_STORE_LINK = process.env.REACT_APP_PLAY_STORE_LINK;
  const APP_STORE_LINK = process.env.REACT_APP_APP_STORE_LINK;

  return (
    <>
      <Grid
        container
        direction="column"
        style={{
          width: '588px',
          padding: '20px 24px',
        }}
      >
        <TkTypography fontSize="20px" fontFamily="Muli" fontWeight="bold">
          {adminOnly ? (
            t('login.adminOnlyMainMessage', { name: name })
          ) : (
            <>
              {decodeHTML(
                t('welcomeSetupModal.congratsJoinedTeam', {
                  team: teamName,
                })
              )}
              <br />
              {t('welcomeSetupModal.accountOwnersOnly')}
            </>
          )}
        </TkTypography>
        <TkTypography marginTop="20px" fontSize="16px" fontFamily="Lato">
          {t('welcomeSetupModal.congratsJoinedTeamMessage')}
        </TkTypography>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          padding="1rem"
          margin="20px 0 0"
        >
          <StoreIconsWrapper>
            <a target="_blank" rel="noopener noreferrer" href={APP_STORE_LINK}>
              <img src={AppStoreBlackBadge} alt="app-store.svg" />
            </a>
          </StoreIconsWrapper>
          <StoreIconsWrapper>
            <a target="_blank" rel="noopener noreferrer" href={PLAY_STORE_LINK}>
              <img src={GooglePlayBlackBadge} alt="google-play-store.svg" />
            </a>
          </StoreIconsWrapper>
        </Grid>
      </Grid>
      <Grid
        container
        direction="column"
        style={{
          width: '588px',
          backgroundColor: '#F5F6FB',
          padding: '20px 40px',
        }}
      >
        <TkTypography fontSize="20px" fontFamily="Lato" fontWeight="bold">
          {t('welcomeSetupModal.getStartRightNow')}
        </TkTypography>
        <TkTypography fontSize="16px" fontFamily="Lato" marginBottom="20px">
          {t('welcomeSetupModal.weWillSendYouAnSMSWithLink')}
        </TkTypography>
        <TkSendMeTheApp
          successToken={t('welcomeSetupModal.weHaveSentTheSMSLink')}
        />
      </Grid>
    </>
  );
};

export default AllDoneJoinTeam;
