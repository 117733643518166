import { selector } from "recoil";
import {
  event,
  assigneesData,
  editEventAssignees,
  isAllEditEventAssigneesSelected,
  crewsData,
  editEventCrews,
} from "@recoilData/index";
import { generateAssignee } from "@helpers/index";

const getSelectedEditEventAssignees = selector({
  key: "getSelectedEditEventAssignees",
  get: ({ get }) => {
    const currentEvent = get(event);

    const eventAssignees: IAssignee[] = currentEvent.assignedUsers?.length
      ? currentEvent.assignedUsers
      : [];

    const assignees = get(assigneesData);
    const newAssignees: IAssignee[] = [];

    assignees.forEach((assignee) => {
      const eventAssignee = eventAssignees.find((a) => a.id === assignee.id);
      const editEventState = get(editEventAssignees(assignee.id));
      if (editEventState)
        newAssignees.push(
          generateAssignee({ ...assignee, ...eventAssignee }, editEventState)
        );
    });

    return newAssignees;
  },
});

const resetEditEventAssignees = selector({
  key: "resetEditEventAssignees",
  get: ({ get }) => get(getSelectedEditEventAssignees),
  set: ({ get, reset }) => {
    const assignees = get(assigneesData);
    const crews = get(crewsData);
    assignees.forEach((assignee) => {
      reset(editEventAssignees(assignee.id));
    });

    crews.forEach((crew) => {
      reset(editEventCrews(crew.id));
    });
  },
});

const getEditEventAssigneesCount = selector({
  key: "getEditEventAssigneesCount",
  get: ({ get }) => {
    const assignees = get(getSelectedEditEventAssignees);
    let count = 0;
    assignees.forEach(() => count++);
    return count;
  },
});

const selectAllEditEventAssignees = selector<boolean>({
  key: "selectAllEditEventAssignees",
  get: ({ get }) => get(isAllEditEventAssigneesSelected),
  set: ({ set, get }, newValue) => {
    const assignees = get(assigneesData);

    set(isAllEditEventAssigneesSelected, newValue as boolean);

    assignees.forEach((assignee) => {
      set(editEventAssignees(assignee.id), newValue as boolean);
    });
  },
});

export {
  getSelectedEditEventAssignees,
  selectAllEditEventAssignees,
  getEditEventAssigneesCount,
  resetEditEventAssignees,
};
