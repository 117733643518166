import React from "react";
import { TkIcon, TkTypography } from "@components/index";
import { Grid } from "@mui/material";

interface ITkDrawerCustomWarning {
  icon: string;
  message: string;
  type: string;
}

const TkDrawerCustomWarning: React.FC<ITkDrawerCustomWarning> = ({
  icon,
  message,
  type = "message",
}) => {
  const colors = {
    warning: "error",
    message: "default",
    solid: "white",
  };

  return (
    <Grid
      container
      direction="row"
      style={{ margin: "0 0 5px 0" }}
      alignItems={"center"}
      bgcolor={type === "solid" ? "navyBlue" : undefined}
    >
      <div>
        <TkIcon
          className={icon}
          iconSize={14}
          width={14}
          marginTop={4}
          marginRight={7}
          color={colors[type]}
        />
      </div>
      <div>
        <TkTypography
          fontSize={"14px"}
          fontFamily={"Lato"}
          color={colors[type]}
        >
          {message}
        </TkTypography>
      </div>
    </Grid>
  );
};

export default TkDrawerCustomWarning;
