import styled from 'styled-components';

import { Card } from '@mui/material';

import { DoodleBackground } from '@assets/index';
import DynamicButton from '@components/DynamicButton';

export const BlockResponsiveBackground = styled.div`
  height: 100vh;
  padding: 16px 12px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${DoodleBackground});
`;

export const BlockResponsiveCard = styled(Card)`
  padding: 20px;
  max-width: 360px;
  text-align: center;
  a {
    text-decoration: none;
  }
`;

export const TaskbirdLogoImage = styled.img`
  max-width: 220px;
`;

export const GetMobileAppButton = styled(DynamicButton)`
  height: 48px;
  .MuiTypography-root {
    font-weight: bold;
  }
`;
