export default {
  title: 'Mensagens',
  sendAMessage: 'Enviar mensagem',
  suggestionNewChat: 'Digite um nome para sugestões',
  startChat: 'Iniciar uma conversa',
  chat: 'Chat',
  deleteChat: 'Deletar conversa',
  emptyStateChannelMessage:
    'Selecione um contato à esquerda para ver mensagens ou iniciar uma nova conversa',
  searchBarPlaceHolder: 'Pesquisar mensagens...',
  info: 'Informações',
  editMessagesMenu: {
    reply: 'Responder',
    copy: 'Copiar Mensagem',
    editMessage: 'Editar Mensagem',
    deleteMessage: 'Deletar Mensagem',
  },
  dropDown: {
    seeAllMessages: 'Ver todas as mensagens',
    emptyState1: 'Não há novas mensagens',
    emptyState2:
      'Vamos começar a conversa! Convide os seus colegas de equipe para conversar.',
    inviteTeammates: 'Convidar colegas de equipe',
  },
  memberInfo: {
    taskInProgressSince: 'Tarefa em andamento desde: {{time}}',
  },
};
