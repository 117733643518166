import { TkButton } from './styles';
import { getColorFromTheme } from '@helpers/index';

export { TkLoadingButton } from './styles';

export const getBackgroundFromVariant = (props): string => {
  switch (props.variant) {
    case 'outlined':
      return '#FFF';
    case 'contained':
      return getColorFromTheme(props.theme.palette, props.bgcolor ?? '');
    default:
      return 'transparent';
  }
};

export default TkButton;
