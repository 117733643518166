export default {
  step1: {
    title: "Crea y asigna tareas",
    message:
      "Gestiona tu equipo creando y asignando tareas. Automatiza tus tareas diarias y recibe notificaciones a medida que las cosas suceden.",
  },
  step2: {
    title: "Invita a tus compañeros de equipo",
    message:
      "Invita a tus empleados a colaborar mediante la aplicación móvil y haz un seguimiento del trabajo y la realización de tareas de tu equipo. Pídeles que establezcan su disponibilidad y evita que no se presenten.",
  },
  step3: {
    title: "Agrega clientes",
    message:
      "Agrega clientes y comienza a programar tareas para ellos, tanto residenciales como comerciales.",
  },
  step4: {
    title: "Lleva un control de tu trabajo",
    message:
      "Organiza y documenta tus trabajos tomando notas, cargando imágenes y usando listas de tareas con documentación fotográfica. Exige a tus compañeros de equipo que añadan fotos a la lista de tareas para garantizar la calidad del trabajo.",
  },
  step5: {
    title: "Sincroniza tus calendarios",
    message:
      "Extrae los eventos programados de tu calendario directamente a Taskbird añadiendo tu Google Calendar u Otros/iCales. Mantén tu agenda organizada en un solo lugar para que nunca te pierdas un proyecto.",
  },
  step6: {
    title: "Integra con Turno",
    message:
      "Integra con la plataforma líder de limpieza de alquileres vacacionales e importa tus proyectos y clientes directamente a Taskbird desde Turno.",
  },
};
