import React from "react";
import {
  createStyles,
  Snackbar,
  makeStyles,
  SnackbarOrigin,
} from "@material-ui/core";

interface ITkSnackbarDrawer {
  open: boolean;
  message: JSX.Element;
  action: JSX.Element;
  onClose?: () => void;
  autoHideDuration?: number;
  type: string;
  anchorOrigin?: SnackbarOrigin;
}

const colors = {
  accept: "#2DA867",
  reject: "#F84132",
  pending: "#ffd600",
  error: "#F84132",
};

const useStyles = makeStyles(() =>
  createStyles({
    customSnackBar: {
      right: 0,
      marginBottom: "4rem",
      position: "absolute",
    },
    snackbarStyleContentProps: {
      background: ({ type }: any) => colors[type],
    },
  })
);

const TkSnackbarDrawer: React.FC<ITkSnackbarDrawer> = ({
  action,
  onClose,
  open,
  message,
  type,
  autoHideDuration = 5000,
  anchorOrigin,
}) => {
  const classes = useStyles({ type: type });

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      message={message}
      anchorOrigin={{
        vertical: anchorOrigin?.vertical ?? "bottom",
        horizontal: anchorOrigin?.horizontal ?? "right",
      }}
      action={action}
      className={classes.customSnackBar} ContentProps={{
        className: classes.snackbarStyleContentProps,
      }}
    />
  );
};

export default TkSnackbarDrawer;
