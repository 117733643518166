import { AppLocationURLs } from '@consts/index';
import {
  NotificationMeta,
  NotificationHandler,
} from '@hooks/notification/types';

const getRoute = () => ({
  url: AppLocationURLs.HOME,
  drawer: '',
});

const getAttributes = (notificationMetadata: NotificationMeta) => ({
  type: notificationMetadata.type,
  taskId: notificationMetadata.taskId,
  assignmentId: notificationMetadata.assignmentId,
});

export default {
  getRoute,
  getAttributes,
} as NotificationHandler;
