import { atom } from 'recoil';
import { Crew, CrewMember } from '@components/TkTeams/types';

export const checkedMembersState = atom<CrewMember[]>({
  key: 'checkedMembersState',
  default: [],
});

export const checkedCrewsState = atom<Crew[]>({
  key: 'checkedCrewsState',
  default: [],
});

export const selectedTeamMemberIdState = atom<number>({
  key: 'selectedTeamMemberIdState',
  default: null,
});
