import React from 'react';

import {
  TkLoginDivider,
  TkLoginDividerBoard,
  TkLoginDividerLine,
  TkLoginFooter,
  TkLoginFooterContainer,
  TkLoginFooterContainerLink,
  TkLoginFooterContainerLinkWrapper,
} from './styles';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import { Icon } from '@mui/material';

const ScTkLink = styled.a`
  color: #999999;
  text-decoration: none;
  font-size: 16px;
`;

export const TkLoginContainerFooter: React.FC = ({ children }) => {
  const currentYear = DateTime.now();

  return (
    <TkLoginFooter>
      <TkLoginFooterContainer>
        <TkLoginFooterContainerLink
          href="https://intercom.help/taskbird/en/"
          target="_blank"
        >
          Help
        </TkLoginFooterContainerLink>
        <TkLoginFooterContainerLink href="https://Taskbird.com/privacy/">
          Privacy Policy
        </TkLoginFooterContainerLink>
        <p>
          <span>©</span> {currentYear.year} Taskbird. All rights reserved.
        </p>
        <TkLoginFooterContainerLinkWrapper>
          <ScTkLink href="https://www.facebook.com/TaskbirdApp/">
            <Icon
              className="fab fa-facebook fontSizeSmall"
              color="action"
              fontSize="inherit"
            />
          </ScTkLink>
          <ScTkLink href="https://www.instagram.com/TaskbirdApp/">
            <Icon
              className="fab fa-instagram fontSizeSmall"
              color="action"
              fontSize="inherit"
            />
          </ScTkLink>
          <ScTkLink href="https://twitter.com/TaskbirdApp/">
            <Icon
              className="fab fa-twitter fontSizeSmall"
              color="action"
              fontSize="inherit"
            />
          </ScTkLink>
        </TkLoginFooterContainerLinkWrapper>
      </TkLoginFooterContainer>
    </TkLoginFooter>
  );
};

export const TkLoginFormContent: React.FC = ({ children }) => {
  return (
    <TkLoginDivider>
      <TkLoginDividerLine />
      <TkLoginDividerBoard>{children}</TkLoginDividerBoard>
      <TkLoginDividerLine />
    </TkLoginDivider>
  );
};
