import React from 'react';

import { useRecoilState, useResetRecoilState } from 'recoil';

import {
  ImgAssignee,
  IconAssigneeButton,
  TkAssigneeCheckItem,
  UniqueSelectionDiv,
  TkListItem,
} from './styles';
import TkCheckBox from '@components/TkCheckBox';
import TkTypography from '@components/TkTypography';
import { generateInitials } from '@helpers/index';
import {
  closeFilterExtendedDrawer,
  filterAssignees,
  setUnassignedStatus,
} from '@recoilData/index';

interface ITkAssigneeListItem {
  assignee: IAssignee;
  popup?: boolean;
}

const TkAssigneeListItem: React.FC<ITkAssigneeListItem> = ({
  assignee,
  popup = false,
}) => {
  const closeExtendedDrawer = useResetRecoilState(closeFilterExtendedDrawer);
  const [isUnassigned, setUnassigned] = useRecoilState(setUnassignedStatus);
  const [filterAssignee, setFilterAssignee] = useRecoilState(
    filterAssignees(assignee.id)
  );

  const handleSelectAssignee = (checked: boolean, unique = false) => {
    if (isUnassigned && checked) {
      setUnassigned(false);
    }

    setFilterAssignee(checked);
    if (unique && !popup) closeExtendedDrawer();
  };

  return (
    <TkListItem
      key={assignee.id}
      checked={filterAssignee}
      control={
        <TkCheckBox
          checked={filterAssignee}
          onChange={(_, checked) => handleSelectAssignee(checked)}
        />
      }
      label={
        <TkAssigneeCheckItem>
          <UniqueSelectionDiv
            onClick={() => handleSelectAssignee(!filterAssignee, true)}
          >
            {assignee.photo_url ? (
              <ImgAssignee src={assignee.photo_url} />
            ) : (
              <IconAssigneeButton>
                {generateInitials(assignee.full_name)}
              </IconAssigneeButton>
            )}
            <TkTypography fontFamily="Lato" fontSize="14px" fontWeight="bold">
              {assignee.full_name}
            </TkTypography>
          </UniqueSelectionDiv>
        </TkAssigneeCheckItem>
      }
    />
  );
};

export default TkAssigneeListItem;
