import styled from 'styled-components';

import { Grid } from '@mui/material';

export const PillGrid = styled(Grid)`
  display: inline;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

export const PulsatingWrapper = styled.div`
  display: inline;
  position: relative;
  margin-right: 20px;
`;

export const PulsatingCircle = styled.div`
  width: 10px;
  height: 10px;
  background: #1f9bdd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #1592e5;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 7px;
  z-index: 2;
`;

export const PulsatingRing = styled.div`
  border: 3px solid #1f9bdd;
  border-radius: 30px;
  height: 20px;
  width: 20px;
  position: absolute;
  left: 2px;
  top: -3px;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;

  @keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(1, 1);
      opacity: 0;
    }
  }
`;

export const ImgWrapper = styled.img`
  width: 12px;
  height: 12px;
  vertical-align: middle;
`;
