import styled, { css } from "styled-components";
import { getColorFromTheme, getLighterColorFromTheme } from "@helpers/index";
import { Grid, Input } from "@material-ui/core";

export const TkHourPickerWrapper = styled.div`
  .react-datepicker {
    z-index: 2;
    border: 0;
    box-shadow: 0px 3px 6px #00000033;
    width: 234px;
    height: 99px;
    font-family: Lato;
    display: flex;
    right: 50px;
    /**/
    .react-datepicker__day.react-datepicker__day--keyboard-selected {
      background: none;
      color: black;
    }
    .react-datepicker__month-container {
      display: none;
    }
    .react-datepicker__input-time-container {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0;
    }

    .react-datepicker-time__caption {
      display: none;
    }

    .react-datepicker-time__input {
      margin: 0;
    }
  }
`;

export const TimeWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TimeItem = styled(Input)`
  font: normal normal bold 32px/17px "Muli";
  color: #011f41;
  max-width: 63px;
  height: 63px;
  border-radius: 4px;
  background-color: #e5e2e2;
  border: 1px solid #e5e2e2;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }

  .MuiInputBase-input {
    text-align: center;
    margin-top: 5px;
  }

  &.Mui-focused {
    background-color: #f5f6fb;
    border: 1px solid #4952a9;
    color: #4952a9;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const TimeSelector = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 38px;
  margin-left: 10px;
`;

export const TimeSelectorItem = styled.div`
  display: flex;
  width: 100%;
  padding-top: 1px;
  justify-content: center;
  align-items: center;
  height: 29px;
  font-family: "Muli";
  font-size: 16px;
  font-weight: bold;
  background-color: #ffffff;
  border: 1px solid #4952a9;
  cursor: pointer;
  color: #011f41;
  border-radius: ${(props) =>
    props.id === "top-item" ? "4px 4px 0 0" : "0 0 4px 4px"};
  ${(props) =>
    props.id === "top-item" ? "border-bottom: 0" : "border-top: 0"};
`;

export const TimeSelectorItemSelected = styled(TimeSelectorItem)`
  background-color: #f5f6fb;
  border: 1px solid #4952a9;
  color: #4952a9;
`;

export const Divider = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  margin: 0 10px 10px 10px;
`;
