import styled from 'styled-components';
import { Grid, Input, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const SelectorWrapper = styled(Grid)`
  margin-top: 20px;
  font-family: 'Lato';
`;

export const SelectorWrapperDouble = styled(Box)`
  display: flex;
  flex: 1;
  font-family: 'Lato';
`;

export const useStyles = makeStyles({
  formControl: {
    width: '100%',
    fontFamily: 'Lato',
    fontSize: 16,
  },
  buttonSelected: {
    backgroundColor: '#4952A9',
    color: '#ffffff',
    height: '24px',
    fontFamily: 'Lato',
    fontSize: '14px',
    border: '1px solid #4952A9',
    textTransform: 'none',
  },
  buttonUnselected: {
    color: '#4952A9',
    backgroundColor: '#ffffff',
    height: '24px',
    fontFamily: 'Lato',
    fontSize: '14px',
    border: '1px solid #4952A9',
    textTransform: 'none',
  },
  baseGrid: {
    marginTop: '20px',
  },
});

export const OptionWrapper = styled(Grid)`
  margin-top: 20px;
  font-family: 'Lato';
`;

export const ContentWrapper = styled(Grid)`
  margin-top: 20px;
  font-family: 'Lato';
`;

export const SelectYearWrapper = styled(Grid)`
  display: flex;
  flex: 1;
  font-family: 'Lato';
  height: 100%;
  justify-content: flex-end;
  width: 100%;
  margin-left: 20px;
`;

export const SelectYearItem = styled(Grid)`
  display: flex;
  font-family: 'Lato';
  height: 30%;
  margin-top: 9px;
  margin-bottom: 5px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const WeekItem = styled.div`
  width: 30px;
  height: 28px;
  border: 1px solid #011f41;
  font: normal normal bold 14px 'Lato';
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px 0 5px;
  user-select: none;
  border-radius: 4px;
  cursor: pointer;
`;

export const WeekItemChecked = styled(WeekItem)`
  border: 1px solid #4952a9;
  background-color: #4952a9;
  color: #ffffff;
`;

export const MonthlyDayPicker = styled(Grid)`
  display: grid;
  grid-template-columns: repeat(7, 24px);
  grid-column-gap: 8px;
`;

export const MonthlyDayItem = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 24px;
  height: 24px;
  border-radius: 2px;
  color: #011f41;
  font-family: 'Lato';
  font-size: 14px;
  margin: 4px 0 4px 0;
  cursor: pointer;
  user-select: none;
`;

export const MonthlyDayItemChecked = styled(MonthlyDayItem)`
  background-color: rgba(73, 82, 169, 0.43);
`;

export const YearlyDayPicker = styled(Grid)`
  display: grid;
  grid-template-columns: repeat(4, 36px);
  grid-column-gap: 10px;
`;

export const YearlyDayItem = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 36px;
  height: 27px;
  border-radius: 2px;
  color: #011f41;
  font-family: 'Lato';
  font-size: 14px;
  margin: 15px 0 15px 0;
  cursor: pointer;
  user-select: none;
`;

export const YearlyDayItemChecked = styled(YearlyDayItem)`
  background-color: rgba(73, 82, 169, 0.43);
`;

export const InputDaily = styled(Input)`
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
  margin-left: 10px;
  margin-right: 10px;
  width: 50px;

  .MuiInputBase-input {
    text-align: center;
  }

  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
