import { lazy } from 'react';
import { AppLocationURLs } from '@consts/index';

const PublicRoutes = [
  {
    path: AppLocationURLs.CHECKLISTS,
    component: lazy(() => import('@pages/PublicChecklists')),
    exact: true,
  },
  {
    path: AppLocationURLs.CHECKLISTS_ID,
    component: lazy(() => import('@pages/ChecklistDetails')),
  },
  {
    path: AppLocationURLs.INTERCOM,
    blockResponsive: false,
    exact: true,
    component: lazy(() => import('@pages/IntercomPage')),
  },
  {
    path: AppLocationURLs.CALENDLY,
    blockResponsive: false,
    component: lazy(() => import('@pages/CalendlyPage')),
  },
  {
    path: AppLocationURLs.LOGIN,
    component: lazy(() => import('@pages/Login')),
  },
  {
    path: AppLocationURLs.SIGN_UP,
    component: lazy(() => import('@pages/Login')),
  },
];

export default PublicRoutes;
