import { atom } from 'recoil';

export const showEditTeamMemberDrawer = atom<boolean>({
  key: 'showEditTeamMemberDrawer',
  default: false,
});

export const showPendingInvitesDrawer = atom<boolean>({
  key: 'showPendingInvitesDrawer',
  default: false,
});

export const teamsPageExtendedDrawer = atom<boolean>({
  key: 'teamsPageExtendedDrawer',
  default: false,
});

export const teamsPageType = atom<string>({
  key: 'teamsPageType',
  default: 'crews',
});
