import React from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { Grid, Divider } from '@mui/material';

import { useTaskInventory } from '@components/Drawers/TkEditTaskDrawer/hooks/useTaskInventory';
import {
  TkExtendedWrapper,
  TkTaskWrapperItem,
} from '@components/TkOfferDetailsDrawer/styles';
import { TkIcon, TkTypography } from '@components/index';
import { editEvent } from '@recoilData/index';

const TkInventoryList = (): JSX.Element => {
  const { t } = useTranslation();
  const task = useRecoilValue(editEvent);
  const { items, close } = useTaskInventory(task.inventory);

  return (
    <TkExtendedWrapper maxWidth="lg">
      <Grid
        container
        direction="row"
        alignItems="center"
        marginBottom="30px"
        justifyContent="space-between"
      >
        <TkTypography fontWeight="bold" fontFamily="Muli" fontSize={16}>
          {t('inventory', { ns: 'events' })}
        </TkTypography>
        <TkIcon
          onClick={close}
          style={{ cursor: 'pointer' }}
          className="fa fa-chevron-right"
          color="default"
          marginLeft={10}
          verticalAlign="middle"
          fontSize="small"
        />
      </Grid>
      <TkTaskWrapperItem
        padding="0 0 2rem"
        height="100%"
        style={{
          overflowY: 'scroll',
        }}
      >
        {items.map((item, index) => (
          <React.Fragment key={index}>
            <Grid
              container
              marginBottom="1rem"
              flexDirection="row"
              justifyContent="space-between"
            >
              <TkTypography sx={{ maxWidth: 200 }}>{item.name}:</TkTypography>
              <TkTypography
                marginLeft={2}
                textTransform="capitalize"
                color={item.quantity ? 'primary' : 'inherit'}
                fontWeight={item.quantity ? 'bold' : 'regular'}
              >
                {item.quantity ?? t('not_reported', { ns: 'common' })}
              </TkTypography>
            </Grid>
            <Divider sx={{ marginBottom: 4 }} />
          </React.Fragment>
        ))}
      </TkTaskWrapperItem>
    </TkExtendedWrapper>
  );
};

export default TkInventoryList;
