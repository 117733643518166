import styled from 'styled-components';

import TkIcon from '@components/TkIcon';
import {
  Tab,
  Tabs,
  Grid,
  Container,
  withStyles,
  createStyles,
  FormControlLabel,
} from '@material-ui/core';

const TabCustom = withStyles(() =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 141,
      backgroundColor: '#f0f0f0',
    },
    selected: {
      backgroundColor: '#FFFFFF',
    },
  })
)(Tab);

const SearchBox = withStyles({
  root: {
    marginLeft: '12px',
    marginRight: '12px',
  },
})(Grid);

const TabsCustom = withStyles({
  root: {
    maxWidth: '282px',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 0,
      width: '100%',
      backgroundColor: '#ffffff',
    },
  },
})(Tabs);

const FullWrapper = styled(Grid)`
  display: flex;
  flex: 1;
  padding-top: 65%;
  align-items: center;
  flex-direction: column;
`;

const FullWrapperItem = styled(Grid)`
  display: flex;
  padding: 15px 15px 0;
  align-items: center;
  flex-direction: column;
`;

const FullWrapperIcon = styled(TkIcon)`
  width: auto;
`;

const TkListWrapper = withStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    padding: '0',
    marginTop: '20px',
  },
})(Container);

const TkListWrapperList = withStyles({
  root: {
    marginTop: '0px',
  },
})(TkListWrapper);

const TkExtendedBase = withStyles({
  root: {
    '*::-webkit-scrollbar': {
      display: 'none',
    },
  },
})(Container);

const TkExtendedWrapper = styled(TkExtendedBase)`
  display: flex;
  flex: 1;
  ${(props) => (props.maxWidth === 'lg' ? 'width: 408px' : 'width: 282px')};
  ${(props) => (props.maxWidth === 'xl' ? 'width: 583px' : '')};
  padding-top: 1rem;
  padding-right: 1rem;
  flex-direction: column;
  overflow: hidden;
`;

const TkExtendedWrapperTabs = withStyles({
  root: {
    margin: 0,
  },
})(TkExtendedWrapper);

const Span = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: inline-block;
`;

const ImageDiv = styled(Grid)`
  margin-top: 10px;
`;

const ImgAssignee = styled.img`
  margin-top: 7px;
  margin-right: 10px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
`;

const TkAssigneeItem = styled(FormControlLabel)`
  ${(props) =>
    props.checked
      ? 'border: 0.5px solid #2699FB;'
      : 'border: 1px solid #ffffff;'}
  padding: 0 0 10px 10px;
  margin: 5px 0 5px 0 !important;
  user-select: none;
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
  max-width: 254px;

  ${(props) =>
    props.checked
      ? 'background-color: rgba(38,153,251, 0.1) ; border-radius: 2px;'
      : ''}

  .MuiCheckbox-root {
    position: absolute;
    padding: 0 9px 9px 0;
    top: 25px;
    left: 0;
  }

  .MuiTypography-body1 {
    margin-top: 4px;
  }
`;

const TkAssigneeContent = styled(Grid)`
  margin-left: 15px;
  max-width: 223px;
`;

const TkListItemSubText = withStyles({
  root: {
    fontSize: '14px',
    fontFamily: 'Lato',
    marginTop: '4px',
    display: 'inherit',
  },
})(Grid);

const IconAssigneeButtonHover = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #ffffff;
  border: #feb334 1px solid;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #feb334;
  text-align: center;
`;

const IconAssigneeButton = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #4952a9;
  border: #4952a9 1px solid;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: 'Lato';
  font-weight: normal;
  font-size: 16px;
`;

const TkCrewItem = styled(FormControlLabel)`
  ${(props) =>
    props.checked
      ? 'border: 0.5px solid #2699FB;'
      : 'border: 1px solid #ffffff;'}
  padding: 0 0 10px 10px;
  user-select: none;
  margin: 0;
  align-items: center;

  ${(props) =>
    props.checked
      ? 'background-color: rgba(38,153,251, 0.1); border-radius: 2px;'
      : ''}

  .MuiCheckbox-root {
    padding: 0 9px 9px 0;
    height: 100%;
    margin-top: 35px;
    margin-left: 2px;
    align-items: start;
  }

  .MuiTypography-body1 {
    margin: 4px 0 0 0;
  }
`;

export {
  Span,
  ImageDiv,
  SearchBox,
  TabCustom,
  TkCrewItem,
  TabsCustom,
  ImgAssignee,
  FullWrapper,
  TkListWrapper,
  TkAssigneeItem,
  FullWrapperIcon,
  FullWrapperItem,
  TkListWrapperList,
  TkListItemSubText,
  TkAssigneeContent,
  TkExtendedWrapper,
  IconAssigneeButton,
  TkExtendedWrapperTabs,
  IconAssigneeButtonHover,
};
