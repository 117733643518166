import styled from 'styled-components';

import { DialogContent, DialogActions } from '@mui/material';

import { getColorFromTheme } from '@helpers/index';

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 786px;
  flex-direction: column;
`;

export const WidgetWrapper = styled.div`
  min-width: 736px;
  border-radius: 4px;
  padding: 10px 0;
`;

export const ModalContent = styled(DialogContent)`
  user-select: none;

  .MuiFormLabel-root {
    font: normal normal normal 18px/20px Lato;
    top: -5px;
    color: ${(props) => getColorFromTheme(props.theme.palette, 'default')};
  }
  &.MuiDialogContent-dividers {
    border-top: 0;
  }
`;

export const ModalActions = styled(DialogActions)`
  border-top: 1px solid #f0f0f0;
`;
