import React from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Box, Grid } from '@mui/material';

import { OfferDrawerTags } from '../MicroComponents';
import { ITkExtendedOfferDetailsDrawerEnum } from '../TkExtendedOffersDetailsDrawer';
import {
  TkTaskWrapperItem,
  TkTaskWrapperItemFilled,
  TkTaskWrapperSubItem,
  HorizontalDivider,
} from '../styles';
import {
  TkTypography,
  TkDrawerHeader,
  TkDrawerTaskName,
  TkDrawerTimeInfo,
  TkDrawerCustomerName,
  TkDrawerServiceDetails,
  TkDrawerPhone,
  TkDrawerEmail,
  TkDrawerLocationDetails,
  TkTaskWrapperItem as TkTaskWrapperItemDrawer,
} from '@components/index';
import { getDatetimeFromDate } from '@helpers/index';
import {
  useProjectOfferAdditionalInfo,
  useProjectOfferServiceDetails,
} from '@hooks/index';
import {
  getUserAccountId,
  offersDetailsExtendedDrawer,
  selectedOfferFromList,
} from '@recoilData/index';

interface IContentRendersBookingWidget {
  offer: IProjectOffer;
  handleClose: () => void;
  handleBack: () => void;
}

const BookingWidget = ({
  offer,
  handleClose,
  handleBack,
}: IContentRendersBookingWidget) => {
  const { t } = useTranslation();
  const accountId = useRecoilValue(getUserAccountId);
  const [extendedDrawer, setExtendedDrawer] = useRecoilState(
    offersDetailsExtendedDrawer
  );
  const isOfferSelectedFromList = useRecoilValue<boolean>(
    selectedOfferFromList
  );

  const handleExtendedDrawer = (type: string) => {
    setExtendedDrawer({
      open: !(extendedDrawer.open && extendedDrawer.type === type),
      type,
    });
  };

  const { data: projectOfferAdditionalInfo, isSuccess } =
    useProjectOfferAdditionalInfo(accountId, offer);

  const {
    data: projectOfferServiceDetails,
    isSuccess: isSuccessServiceDetails,
  } = useProjectOfferServiceDetails(accountId, offer);

  return (
    <>
      <TkDrawerHeader
        id={offer.id}
        handlers={{
          handleClose,
          handleBack,
        }}
        dismissIcon="fa fa-times"
        showBackButton={isOfferSelectedFromList}
      />

      <TkDrawerTaskName
        name={offer.title}
        setName={() => null}
        setEdited={() => null}
        displayOnly
      />

      <OfferDrawerTags offer={offer} />

      <TkTaskWrapperItem padding="1rem">
        <TkDrawerTimeInfo
          start={getDatetimeFromDate(offer.start, offer.location.timezone)}
          finish={getDatetimeFromDate(offer.end, offer.location.timezone)}
        />
      </TkTaskWrapperItem>

      <TkTaskWrapperItemFilled>
        <TkTaskWrapperSubItem
          style={{ cursor: 'pointer' }}
          onClick={() =>
            handleExtendedDrawer(
              ITkExtendedOfferDetailsDrawerEnum.SERVICE_DETAILS
            )
          }
          color={
            extendedDrawer.open &&
            extendedDrawer.type ===
              ITkExtendedOfferDetailsDrawerEnum.SERVICE_DETAILS
              ? 'selected'
              : ''
          }
        >
          {isSuccessServiceDetails && (
            <TkDrawerServiceDetails
              price={projectOfferServiceDetails.total_estimated_price}
            />
          )}
        </TkTaskWrapperSubItem>

        <Grid container style={{ padding: '0 1rem' }}>
          <HorizontalDivider color="solid" />
        </Grid>

        <TkTaskWrapperSubItem>
          <TkDrawerCustomerName
            customerName={offer.customerName}
            firstNameOnly
          />
        </TkTaskWrapperSubItem>
      </TkTaskWrapperItemFilled>

      {offer.customerPhone && (
        <>
          <TkTaskWrapperItem padding="1rem">
            <TkDrawerPhone phoneNumber={offer.customerPhone} />
          </TkTaskWrapperItem>

          <TkTaskWrapperItem style={{ padding: '0 1rem' }}>
            <HorizontalDivider />
          </TkTaskWrapperItem>
        </>
      )}

      {offer.customerEmail && (
        <>
          <TkTaskWrapperItem padding="1rem">
            <TkDrawerEmail email={offer.customerEmail} />
          </TkTaskWrapperItem>

          <TkTaskWrapperItem style={{ padding: '0 1rem' }}>
            <HorizontalDivider />
          </TkTaskWrapperItem>
        </>
      )}

      <TkTaskWrapperItem padding="0.5rem" margin="0.5rem">
        <TkDrawerLocationDetails
          location={offer.location}
          streetOnly
          innerStyle={{ maxWidth: '100%' }}
        />
      </TkTaskWrapperItem>

      <TkTaskWrapperItem style={{ padding: '0 1rem' }}>
        <HorizontalDivider />
      </TkTaskWrapperItem>

      <TkTaskWrapperItem
        padding="0.5rem"
        margin="0.5rem"
        style={{ cursor: 'pointer' }}
        onClick={() =>
          handleExtendedDrawer(
            ITkExtendedOfferDetailsDrawerEnum.ADDITIONAL_INFO_BOOKING
          )
        }
        bgcolor={
          extendedDrawer.open &&
          extendedDrawer.type ===
            ITkExtendedOfferDetailsDrawerEnum.ADDITIONAL_INFO_BOOKING
            ? 'filled'
            : ''
        }
      >
        <TkTaskWrapperItemDrawer icon="fa fa-hashtag">
          <TkTypography fontSize={16}>
            {t('additionalInfo', { ns: 'offers' })}
          </TkTypography>
        </TkTaskWrapperItemDrawer>

        {isSuccess && (
          <TkTaskWrapperItemDrawer
            Actions={
              <TkTypography fontSize={16} fontWeight="bold">
                {projectOfferAdditionalInfo?.data.length > 1 &&
                  t('moreXQuestions', {
                    ns: 'offers',
                    count: projectOfferAdditionalInfo?.data.length - 1,
                  })}
              </TkTypography>
            }
          >
            <Box display="flex" alignItems="center">
              <TkTypography
                fontSize={16}
                fontWeight="bold"
                style={{
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {projectOfferAdditionalInfo?.data[0]?.question}
              </TkTypography>
              <TkTypography
                as="span"
                fontSize={16}
                fontWeight="normal"
                marginLeft="5px"
              >
                {projectOfferAdditionalInfo?.data[0]?.answer}
              </TkTypography>
            </Box>
          </TkTaskWrapperItemDrawer>
        )}
      </TkTaskWrapperItem>
    </>
  );
};

export default React.memo(BookingWidget);
