import { Grid } from '@mui/material';
import styled from 'styled-components';

export const CustomGrid = styled(Grid)`
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`;
