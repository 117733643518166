import React, { useState } from "react";
import styled from "styled-components";
import { getColorFromTheme, getHoverColor } from "@helpers/index";
import Switch, { SwitchProps, SwitchClassKey } from "@material-ui/core/Switch";
import { withStyles, Theme, createStyles } from "@material-ui/core/styles";
import {
  FormGroup,
  FormControlLabel,
  FormControl,
  Box,
} from "@material-ui/core";
import { FormControlLabelProps } from "@material-ui/core/FormControlLabel";

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
  formControlLabel?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
  checked: boolean;
  labelActive?: boolean;
  position?: "top" | "bottom" | "start" | "end";
  label?: string;
}

const TkSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 56,
      height: 28,
      padding: 0,
      margin: theme.spacing(1),
      overflow: "inherit",
    },
    switchBase: {
      padding: 4,
      "&$checked": {
        transform: "translateX(28px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: "#4952A9",
          opacity: 1,
          border: "none",
        },
      },
      "&$focusVisible $thumb": {
        color: "#4952A9",
        border: "6px solid #fff",
      },
    },
    thumb: {
      width: 20,
      height: 20,
    },
    track: {
      borderRadius: 14,
      backgroundColor: "#999999",
      opacity: 1,
      transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
    formControlLabel: {
      marginLeft: "-5px",
    },
  })
)(({ classes, ...props }: Props) => {
  let label = "";
  let labelActive = props.labelActive ?? true;

  if (labelActive) {
    label = props.checked == true ? "On" : "Off";
    label = props.label ? props.label : label;
  }

  return (
    <FormControl component="fieldset">
      <FormControlLabel
        classes={{ root: classes.formControlLabel }}
        label={label}
        value={props.position ?? "end"}
        labelPlacement={props.position ?? "end"}
        control={
          <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            {...props}
          />
        }
      />
    </FormControl>
  );
});

export default TkSwitch;
