import { selector, selectorFamily } from "recoil";
import {
  editEventCrews,
  isAllEditEventCrewsSelected,
  crewsData,
} from "@recoilData/index";
import { editEventAssignees } from "@recoilData/atoms";

const searchEditEventCrewData = selectorFamily({
  key: "searchEditEventCrewData",
  get: (search: string) => ({ get }) => {
    const crews = get(crewsData);
    if (!search) return crews;
    return crews.filter((item) => {
      return item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase());
    });
  },
});

const getEditEventCrews = selector({
  key: "getEditEventCrews",
  get: ({ get }) => {
    const crews = get(crewsData);
    const newCrews: ICrew[] = [];

    crews.forEach((crew) => {
      const editEventState = get(editEventCrews(crew.id));
      newCrews.push(generateCrew(crew, editEventState));
    });

    return newCrews;
  },
});

const getEditEventCrewsCount = selector({
  key: "getEditEventCrewsCount",
  get: ({ get }) => {
    const crews = get(getEditEventCrews);
    let count = 0;
    crews.forEach((crew) => {
      if (crew.checked) count++;
    });
    return count;
  },
});

const selectAllEditEventCrews = selector<boolean>({
  key: "selectAllEditEventCrews",
  get: ({ get }) => get(isAllEditEventCrewsSelected),
  set: ({ set, get }, newValue) => {
    const crews = get(crewsData);

    set(isAllEditEventCrewsSelected, newValue as boolean);

    crews.map((crew) => {
      crew.members.map((member) => {
        set(editEventAssignees(member.id), newValue as boolean);
      });

      set(editEventCrews(crew.id), newValue as boolean);
    });
  },
});

const selectEditEventCrew = selector({
  key: "selectEditEventCrew",
  get: () => {},
  set: ({ get, set }, newValue) => {
    const { id, checked } = newValue as any;
    const crew = get(crewsData).find((crew) => crew.id === id);

    set(editEventCrews(id), checked);
    if (crew !== undefined)
      crew.members.map((member) => {
        set(editEventAssignees(member.id), checked as boolean);
      });
  },
});

const generateCrew = (crew: ICrew, checked: boolean) => {
  const newCrew: ICrew = {
    id: crew.id,
    name: crew.name,
    members: crew.members,
    checked: checked,
  };

  return newCrew;
};

export {
  searchEditEventCrewData,
  selectEditEventCrew,
  getEditEventCrews,
  selectAllEditEventCrews,
  getEditEventCrewsCount,
};
