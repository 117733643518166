import React from 'react';

import { Skeleton, Grid } from '@mui/material';

import {
  TkTaskIdWrapper,
  TaskId,
  TkTaskWrapperItem,
  TaskNameDisplay,
  EditTaskTimeItem,
  VerticalDivider,
  HorizontalDivider,
} from './styles';
import { TkTaskWrapper } from '@components/Drawers/TkNewTaskDrawer/styles';
import { TkTypography } from '@components/index';

const TkLoadingEvent = (): JSX.Element => {
  return (
    <>
      <TkTaskIdWrapper>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <TaskId item>
            <TkTypography
              fontWeight="bold"
              fontSize={16}
              fontFamily="Muli"
              textAlign="center"
              marginTop={1}
            >
              <Skeleton variant="rectangular" width={385} height={20} />
            </TkTypography>
          </TaskId>
        </Grid>
      </TkTaskIdWrapper>
      <TkTaskWrapper>
        <TkTaskWrapperItem padding="0 1rem 10px">
          <TaskNameDisplay
            container
            justifyContent="center"
            alignItems="center"
          >
            <TkTypography
              fontWeight="bold"
              fontSize={20}
              fontFamily="Muli"
              textAlign="center"
              marginTop={1}
              userselect="none"
            >
              <Skeleton variant="rectangular" width={210} height={30} />
            </TkTypography>
          </TaskNameDisplay>
        </TkTaskWrapperItem>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginBottom: '10px' }}
        >
          <EditTaskTimeItem id="datepicker-left">
            <TkTypography
              fontSize={20}
              fontFamily="Muli"
              fontWeight="bold"
              marginTop="5px"
            >
              <Skeleton variant="rectangular" width={160} height={75} />
            </TkTypography>
          </EditTaskTimeItem>
          <VerticalDivider />
          <EditTaskTimeItem id="datepicker-right">
            <TkTypography
              fontSize={20}
              fontFamily="Muli"
              fontWeight="bold"
              marginTop="5px"
            >
              <Skeleton variant="rectangular" width={160} height={75} />
            </TkTypography>
          </EditTaskTimeItem>
        </Grid>
        <TkTaskWrapperItem padding="5px 1rem">
          <HorizontalDivider />
        </TkTaskWrapperItem>
        <TkTaskWrapperItem padding="10px 1rem">
          <TkTypography
            fontSize={16}
            fontFamily="Lato"
            fontWeight="normal"
            color="#011F41"
            display="inline"
          >
            <Grid container justifyContent="space-between">
              <TkTypography
                fontSize={20}
                fontFamily="Muli"
                fontWeight="bold"
                marginTop="5px"
              >
                <Skeleton variant="rectangular" width={355} height={24} />
              </TkTypography>
            </Grid>
          </TkTypography>
        </TkTaskWrapperItem>
        <TkTaskWrapperItem padding="5px 1rem">
          <HorizontalDivider />
        </TkTaskWrapperItem>
        <TkTaskWrapperItem padding="10px 1rem">
          <TkTypography
            fontSize={16}
            fontFamily="Lato"
            fontWeight="normal"
            color="#011F41"
            display="inline"
          >
            <Grid container justifyContent="space-between">
              <TkTypography
                fontSize={20}
                fontFamily="Muli"
                fontWeight="bold"
                marginTop="5px"
              >
                <Skeleton variant="rectangular" width={355} height={24} />
              </TkTypography>
            </Grid>
          </TkTypography>
        </TkTaskWrapperItem>
        <TkTaskWrapperItem padding="5px 1rem">
          <HorizontalDivider />
        </TkTaskWrapperItem>
        <TkTaskWrapperItem padding="10px 1rem">
          <TkTypography
            fontSize={16}
            fontFamily="Lato"
            fontWeight="normal"
            color="#011F41"
            display="inline"
          >
            <Grid container justifyContent="space-between">
              <TkTypography
                fontSize={20}
                fontFamily="Muli"
                fontWeight="bold"
                marginTop="5px"
              >
                <Skeleton variant="rectangular" width={355} height={24} />
              </TkTypography>
            </Grid>
          </TkTypography>
        </TkTaskWrapperItem>
        <TkTaskWrapperItem padding="5px 1rem">
          <HorizontalDivider />
        </TkTaskWrapperItem>
        <TkTaskWrapperItem padding="10px 1rem">
          <TkTypography
            fontSize={16}
            fontFamily="Lato"
            fontWeight="normal"
            color="#011F41"
            display="inline"
          >
            <Grid container justifyContent="space-between">
              <TkTypography
                fontSize={20}
                fontFamily="Muli"
                fontWeight="bold"
                marginTop="5px"
              >
                <Skeleton variant="rectangular" width={355} height={24} />
              </TkTypography>
            </Grid>
          </TkTypography>
        </TkTaskWrapperItem>
      </TkTaskWrapper>
    </>
  );
};

export default TkLoadingEvent;
