import React, { useEffect, useState } from 'react';

import { Grid, Divider } from '@mui/material';

import {
  ModalContent,
  ModalSection,
  ModalLeftContent,
  ModalRightContent,
  ModalActions,
} from './styles';
import { TkMobileStepper, TkTypography } from '@components/index';
import { MIXPANEL_EVENTS, useMixpanel } from '@contexts/index';

const TkStepperModal: React.FC<ITkStepperModal> = ({
  color,
  steps,
  contentSize = 6,
  handleCloseModal,
  contentPadding,
  hideDivider = false,
}) => {
  const maxSteps = steps.length;
  const [activeStep, setActiveStep] = useState(0);
  const { track } = useMixpanel();

  useEffect(() => {
    track(MIXPANEL_EVENTS.STARTED_ONBOARDING);
  }, []);

  useEffect(() => {
    Object.keys(MIXPANEL_EVENTS.ONBOARDING_STEPS)?.[activeStep] &&
      track(
        MIXPANEL_EVENTS.ONBOARDING_STEPS[
          Object.keys(MIXPANEL_EVENTS.ONBOARDING_STEPS)?.[activeStep]
        ]
      );
  }, [activeStep, track]);

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      handleCloseModal();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep === 0) {
      handleCloseModal();
      return;
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handlers = {
    handleBack,
    handleNext,
  };

  return (
    <ModalContent dividers className="ModalContent">
      <Grid item xs={contentSize} sx={{ height: '100%' }}>
        <TkMobileStepper
          steps={maxSteps + 1}
          activeStep={activeStep + 1}
          color={color}
        />
        <ModalSection className="ModalSection">
          <TkTypography
            align="left"
            fontSize={20}
            fontFamily="Muli"
            color="#061F41"
            letterSpacing={0}
            fontWeight="bold"
          >
            {steps[activeStep].title}
          </TkTypography>
          <ModalLeftContent
            style={{
              marginTop: `${contentPadding?.top ?? 25}px`,
              marginRight: `${contentPadding?.right ?? 0}px`,
              marginBottom: `${contentPadding?.bottom ?? 0}px`,
              marginLeft: `${contentPadding?.left ?? 15}px`,
            }}
          >
            {steps[activeStep].content}
          </ModalLeftContent>
        </ModalSection>
        {!hideDivider && <Divider />}
        <ModalActions className="ModalActions">
          <Grid container xs={12}>
            {steps[activeStep].actions(handlers)}
          </Grid>
        </ModalActions>
      </Grid>
      <Grid item xs={12 - contentSize} sx={{ height: '100%' }}>
        <ModalRightContent>{steps[activeStep].rightContent}</ModalRightContent>
      </Grid>
    </ModalContent>
  );
};

export default TkStepperModal;
