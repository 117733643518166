import React, { useState, useEffect } from 'react';
import { TkTypography } from '@components/index';
import { TextField } from '@material-ui/core';
import { ContentWrapper } from './styles';

interface TkPriceInput {
  value: number;
  error?: boolean;
  onBlur?: (e: any, value: string) => void;
  onChange?: (e: any, value: string) => void;
}

const TkPriceInput: React.FC<TkPriceInput> = ({
  error,
  value,
  onBlur,
  onChange,
}) => {
  const [stringValue, setStringValue] = useState<string>('0');

  const handleOnChangeValue = e => {
    const stringValue = e.target.value;
    let value = stringValue.replace(/[^0-9\.]/, '');
    value = value.replace(/^0+([1-9])/, '$1');
    
    const validValue = !!value.match(/^[0-9]+(\.[0-9]{0,2}|)$/) || value === '';

    validValue && setStringValue(value);

    onChange && onChange(e, stringValue);
  }

  const handleOnBlur = e => onBlur && onBlur(e, stringValue);

  useEffect(() => {
    setStringValue(''+value);
  }, [value]);

  return (
    <ContentWrapper container alignItems='center' wrap='nowrap' className='priceInput'>
      <TkTypography mr={2}>$</TkTypography>
      <TextField
        error={error}
        value={stringValue}
        onBlur={handleOnBlur}
        onChange={handleOnChangeValue}
        inputProps={{
          inputMode: 'numeric',
          pattern: '^[0-9]+(\.[0-9]{1,2}|)$',
        }}
      />
    </ContentWrapper>
  );
  
}

export default TkPriceInput;