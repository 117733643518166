import React from 'react';

import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import {
  SelectableOptionWrapper,
  SelectableOptionIconWrapper,
  SelectableOptionTextWrapper,
} from '../TkLogin/TkWelcomeSetupModal/styles';
import {
  IOptionsSelect,
  SetupStatesEnum,
} from '@components/TkModalComponents/types.type';
import { TkTypography } from '@components/index';

interface SelectableOptionProps {
  option: any;
  selectedOption: IOptionsSelect;
}

const SelectableOption = ({
  option,
  selectedOption,
}: SelectableOptionProps): JSX.Element => {
  const { t } = useTranslation();

  if (option === SetupStatesEnum.NONE_SELECTED) {
    return;
  }

  return (
    <SelectableOptionWrapper
      onClick={() => option.handleClick(option.id)}
      itemID={option.id === selectedOption ? 'selected' : ''}
    >
      <SelectableOptionIconWrapper>
        <img src={option.icon} alt={option.id} />
      </SelectableOptionIconWrapper>
      <SelectableOptionTextWrapper direction="column">
        <Grid container>
          <TkTypography
            marginBottom="10px"
            fontFamily="Muli"
            fontSize={20}
            fontWeight="bold"
          >
            {t(option.textToken)}
          </TkTypography>
        </Grid>
        <Grid container>
          <TkTypography fontFamily="Lato" fontSize={16}>
            {t(option.subTextToken)}
          </TkTypography>
        </Grid>
      </SelectableOptionTextWrapper>
    </SelectableOptionWrapper>
  );
};

export default SelectableOption;
