import React from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import {
  Container,
  ResourcesContainer,
  SocialMediaContainer,
  FooterLink,
  SocialMediaLinks,
  TkStyledIcon,
} from './styles';
import { TkTypography } from '@components/index';

const TkFooter = (): JSX.Element => {
  const { t } = useTranslation(['footer']);
  const currentYear = DateTime.now();

  const WEB_APP_BASE_URI = process.env.REACT_APP_WEB_BASE_URI;
  const MARKETING_SITE_BASE_URI = process.env.REACT_APP_MARKETING_SITE_BASE_URI;

  const FACEBOOK_SOCIAL_URL = process.env.REACT_APP_FACEBOOK;
  const INSTAGRAM_SOCIAL_URL = process.env.REACT_APP_INSTAGRAM;
  const TWITTER_SOCIAL_URL = process.env.REACT_APP_TWITTER;

  return (
    <Container>
      <ResourcesContainer>
        <TkTypography
          mb={2}
          component="span"
          color="white"
          variant="body1"
          fontWeight="bold"
          fontFamily="muli"
        >
          {t('resources')}
        </TkTypography>
        <FooterLink href={`${WEB_APP_BASE_URI}/checklists/`}>
          <TkTypography component="span" color="white" variant="body2">
            {t('cleaningCompanyChecklists')}
          </TkTypography>
        </FooterLink>
        <FooterLink href={`${MARKETING_SITE_BASE_URI}/blog/`}>
          <TkTypography component="span" color="white" variant="body2">
            {t('blog')}
          </TkTypography>
        </FooterLink>
        <FooterLink href={`${MARKETING_SITE_BASE_URI}/privacy/`}>
          <TkTypography component="span" color="white" variant="body2">
            {t('privacyPolicy')}
          </TkTypography>
        </FooterLink>
      </ResourcesContainer>
      <SocialMediaContainer>
        <TkTypography
          mb={2}
          component="span"
          color="white"
          variant="body1"
          fontWeight="bold"
          fontFamily="muli"
        >
          {t('followUs')}
        </TkTypography>
        <SocialMediaLinks>
          <a href={FACEBOOK_SOCIAL_URL} target="_blank" rel="noreferrer">
            <TkStyledIcon className="fab fa-facebook-square" />
          </a>
          <a href={INSTAGRAM_SOCIAL_URL} target="_blank" rel="noreferrer">
            <TkStyledIcon className="fab fa-instagram" />
          </a>
          <a href={TWITTER_SOCIAL_URL} target="_blank" rel="noreferrer">
            <TkStyledIcon className="fab fa-twitter" />
          </a>
        </SocialMediaLinks>
        <TkTypography mt={4} component="span" color="white" variant="body2">
          © {t('copyright', { value: currentYear.year })}
        </TkTypography>
      </SocialMediaContainer>
    </Container>
  );
};

export default TkFooter;
