import { selector, selectorFamily } from 'recoil';

import {
  filterLocations,
  isAllFilterLocationsSelected,
  locationsData,
} from '@recoilData/index';

const searchFilterLocationsData = selectorFamily({
  key: 'searchFilterLocationsData',
  get:
    (search: string) =>
    ({ get }) => {
      const locations = get(locationsData);
      if (!search) return locations;
      return locations.filter((item) => {
        return item.alias
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase());
      });
    },
});

const getFilteredLocations = selector({
  key: 'getFilteredLocations',
  get: ({ get }) => {
    const locations = get(locationsData);
    const newLocations: ILocationTask[] = [];

    locations.forEach((location) => {
      const filterState = get(filterLocations(location.id));
      newLocations.push(generateLocation(location, filterState));
    });

    return newLocations;
  },
});

const getFilteredLocationsCount = selector({
  key: 'getFilteredLocationsCount',
  get: ({ get }) => {
    const locations = get(getFilteredLocations);
    let count = 0;
    locations.forEach((location) => {
      if (location.checked) count++;
    });
    return count;
  },
});

const selectAllFilterLocations = selector<boolean>({
  key: 'selectAllFilterLocations',
  get: ({ get }) => get(isAllFilterLocationsSelected),
  set: ({ set, get }, newValue) => {
    const locations = get(locationsData);

    set(isAllFilterLocationsSelected, newValue as boolean);

    locations.map((customer) => {
      set(filterLocations(customer.id), newValue as boolean);
    });
  },
});

const generateLocation = (location: ILocationTask, checked: boolean) => {
  const newLocation: ILocationTask = {
    id: location.id,
    alias: location.alias,
    number: location.number,
    street_address: location.street_address,
    accessCode: location.accessCode,
    access_code: location.access_code,
    checked: checked,
    created_at: location.created_at,
    customerId: location.customerId,
    customerName: location.customerName,
    customer_id: location.customer_id,
    customer_name: location.customer_name,
    external_id: location.external_id,
    notes: location.notes,
    resynced: location.resynced,
    streetAddress: location.streetAddress,
    timezone: location.timezone,
    updated_at: location.updated_at,
    city: location.city ?? null,
    country: location.country ?? null,
    country_code: location.country_code ?? null,
    number_of_bedrooms: location.number_of_bedrooms ?? null,
    number_of_bathrooms: location.number_of_bathrooms ?? null,
    latitude: location.latitude ?? null,
    longitude: location.longitude ?? null,
    size: location.size ?? null,
    size_unit_value_id: location.size_unit_value_id ?? null,
    state: location.state ?? null,
    zip_code: location.zip_code ?? null,
    size_unit: location.size_unit ?? null,
    extraDetails: location.extraDetails ?? null,
  };

  return newLocation;
};

export {
  searchFilterLocationsData,
  getFilteredLocations,
  selectAllFilterLocations,
  getFilteredLocationsCount,
};
