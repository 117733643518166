export const generateAssignee = (assignee: IAssignee, checked: boolean) => {
  const newAssignee: IAssignee = {
    id: assignee.id,
    full_name: assignee.full_name,
    checked: checked,
    crews: assignee.crews,
    email: assignee.email,
    first_name: assignee.first_name,
    last_name: assignee.last_name,
    permission_id: assignee.permission_id,
    phone_number: assignee.phone_number,
    photo_url: assignee.photo_url,
    street_address: assignee.street_address,
    taskCountForMonth: assignee.taskCountForMonth,
    taskCountForToday: assignee.taskCountForToday,
    taskCountForWeek: assignee.taskCountForWeek,
    totalTaskCount: assignee.totalTaskCount,
    assignmentStatus: assignee.assignmentStatus,
    asked_to_be_removed: assignee.asked_to_be_removed ?? false,
    end_time_epoch: assignee.end_time_epoch,
    start_time_epoch: assignee.start_time_epoch,
    chat_user_id: assignee.chat_user_id,
  };

  return newAssignee;
};
