export default {
  viewBy: 'View by',
  filter: 'Filter',
  taskFilter: 'Task Filter',
  clearFilters: 'Clear Filters',
  filterTeammatesDropDrown: '{{ selected }} of {{ total }} Teammates',
  filterCustomersDropDrown: '{{ selected }} of {{ total }} Customers',
  filterLocationsDropDrown: '{{ selected }} of {{ total }} Locations',
  filterAndXMore: 'and {{ count }} more',
};
