import { selector } from "recoil";
import { draftBookingBusiness } from "@recoilData/index";

const businessSchedules = selector<IBookingBusinessSchedules | undefined>({
  key: "businessSchedules",
  get: ({ get }) => get(draftBookingBusiness).schedules,
  set: ({ set, get }, newValue) =>
    set(draftBookingBusiness, {
      ...get(draftBookingBusiness),
      schedules: newValue as IBookingBusinessSchedules | undefined
    })
});

export {
  businessSchedules,
};
