import React from 'react';

import { ComponentWithChildren } from 'types/common.type';

import { GoogleOAuthProvider } from '@react-oauth/google';

const TkGoogleReCaptchaContext = ({
  children,
}: ComponentWithChildren): JSX.Element => {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      {children}
    </GoogleOAuthProvider>
  );
};

export default TkGoogleReCaptchaContext;
