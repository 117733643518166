import { selector } from 'recoil';

import {
  alertsData,
  visibleAlertData,
  enqueuedSnackbarCount,
} from '@recoilData/atoms';

const pushNewAlert = selector<IAlert>({
  key: 'pushNewAlert',
  get: ({ get }) => get(alertsData) as any,
  set: ({ get, set }, newValue) => {
    const alerts = get(alertsData);
    const { message, severity, customComponent, autoHideDuration } =
      newValue as IAlert;

    set(alertsData, [
      ...alerts,
      {
        message,
        severity,
        visible: false,
        customComponent,
        autoHideDuration,
      },
    ]);
  },
});

const handleCloseEvent = selector({
  key: 'handleCloseEvent',
  get: ({ get }) => get(visibleAlertData),
  set: ({ get, reset }) => {
    reset(visibleAlertData);
  },
});

const countEnqueuedSnackbar = selector({
  key: 'countEnqueuedSnackbar',
  get: ({ get }) => get(enqueuedSnackbarCount),
  set: ({ get, set }, newValue) => {
    const currentCount = get(enqueuedSnackbarCount);
    set(enqueuedSnackbarCount, currentCount + (newValue as number));
  },
});

export { pushNewAlert, handleCloseEvent, countEnqueuedSnackbar };
