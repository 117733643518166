import React from 'react';
import { useRecoilValue } from 'recoil';
import { TkExtendedDrawer } from '@components/index';
import { offersDetailsExtendedDrawer } from '@recoilData/index';
import {
  TkOfferAdditionalInfoMoveout,
  TkOfferLocation,
  TkOfferServicesDetails,
  TkOfferAdditionalInfoBooking,
  TkOfferAdditionalTurno,
} from './ExtendedDrawers';

export enum ITkExtendedOfferDetailsDrawerEnum {
  ADDITIONAL_INFO_TURNO = 'additionalInfoTurno',
  ADDITIONAL_INFO_MOVEOUT = 'additionalInfoMoveout',
  ADDITIONAL_INFO_BOOKING = 'additionalInfoBooking',
  LOCATION = 'location',
  SERVICE_DETAILS = 'serviceDetails',
}

const TkExtendedOfferDetailsDrawer: React.FC = () => {
  const { type, open } = useRecoilValue(offersDetailsExtendedDrawer);
  const renderExtendedType = (type: string) => {
    const types = {
      additionalInfoTurno: <TkOfferAdditionalTurno />,
      additionalInfoMoveout: <TkOfferAdditionalInfoMoveout />,
      additionalInfoBooking: <TkOfferAdditionalInfoBooking />,
      location: <TkOfferLocation />,
      serviceDetails: <TkOfferServicesDetails />,
    };

    return types[type];
  };

  return (
    <TkExtendedDrawer open={open}>{renderExtendedType(type)}</TkExtendedDrawer>
  );
};

export default TkExtendedOfferDetailsDrawer;
