import styled from "styled-components";
import { DialogContent } from "@mui/material";
import { getColorFromTheme } from "@helpers/index";

export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 368px;
  height: 356px;
  flex-direction: column;
`;

export const ModalContent = styled(DialogContent)`
  user-select: none;

  .MuiFormLabel-root {
    font: normal normal normal 18px/20px Lato;
    top: -5px;
    color: ${(props) => getColorFromTheme(props.theme.palette, "default")};
  }
  &.MuiDialogContent-dividers {
    border-top: 0;
  }
`;
