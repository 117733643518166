import { Grid } from "@mui/material";
import styled from "styled-components";

const ChecklistSelectedBaseItem = styled(Grid)`
  cursor: pointer;

  .MuiGrid-container {
    align-items: center;
  }

  .MuiIcon-root {
    cursor: pointer;
  }
`;

export const ChecklistSelectedEdit = styled(ChecklistSelectedBaseItem)`
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid rgba(73, 82, 169, 0);

  ${(props) =>
    props.bgcolor === "selected"
      ? "background-color: #e8f4ff;border: 1px solid #2699fb;"
      : ""}
`;

export const ChecklistSelectedNew = styled(Grid)`
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 0 10px 0;

  &:last-child {
    border-bottom: 1px solid #ffffff;
  }
`;

export const Selector = styled.div`
  font: normal normal normal 16px/24px "Lato";
  color: #4952a9;
  position: relative;
  cursor: pointer;
`;
