import { getDateToEpoch } from '@helpers/index';
import api from '@services/api';

const uploadEventImages = (images: File[]) => {
  const ps = images.map((image) => {
    const formData = new FormData();
    formData.append('picture', image);
    return api
      .post(`image`, formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        const image: IImage = res.data;
        return image;
      })
      .catch((e) => {
        console.log(e);
        return null;
      });
  });

  return Promise.all(ps);
};

const generateApiEvent = async (
  event: IEvent,
  accountId: number,
  flagNotify: boolean,
  checklists: IChecklistTask[],
  assignees: IAssignee[]
): Promise<INewEventApi> => {
  const { end, title, notes, start, location, customer, imagesFile } = event;
  /** @ts-ignore */
  const newEvent: INewEventApi = {
    notify_assignees: flagNotify,
    name: title,
    account_id: accountId,
    start_time_epoch: getDateToEpoch(start).toString(),
    end_time_epoch: getDateToEpoch(end).toString(),
  };

  if (location !== undefined) {
    newEvent.location_id = location.id;
  }

  if (customer !== undefined) {
    newEvent.customer_id = customer.id;
  }

  if (notes.length !== 0) {
    newEvent.notes = notes;
  }

  if (checklists.length > 0) {
    newEvent.checklists = checklists.map((c) => {
      return {
        id: c.id,
        is_mandatory: c.isMandatory ? 1 : 0,
      };
    });
  }

  if (assignees.length > 0) {
    newEvent.user_id = assignees.map((a) => a.id);
  }

  if (imagesFile.length === 0) return newEvent;

  newEvent.pictures_ids = [];

  await uploadEventImages(imagesFile).then((images) => {
    images.forEach((i) => {
      if (i !== null) newEvent.pictures_ids?.push(i.id);
    });
  });

  return newEvent;
};

const generateRepeatApiEvent = async (
  event: IEvent,
  accountId: number,
  flagNotify: boolean,
  checklists: IChecklistTask[],
  assignees: IAssignee[]
): Promise<INewRepeatEventApi> => {
  const { end, start, taskRepeat } = event;
  const newEvent = await generateApiEvent(
    event,
    accountId,
    flagNotify,
    checklists,
    assignees
  );

  const newRepeatEvent: INewRepeatEventApi = {
    ...newEvent,
    frequency: taskRepeat.frequency,
    interval: taskRepeat.interval,
    start: getDateToEpoch(start).toString(),
    until: getDateToEpoch(end).toString(),
  };

  switch (taskRepeat.frequency) {
    case 'weekly':
      newRepeatEvent.days_of_the_week = taskRepeat.weeklyDaysRepetition;
      break;

    case 'monthly':
      newRepeatEvent.recurrence_type = taskRepeat.monthlyType;
      if (taskRepeat.monthlyType === 'bymonthday') {
        newRepeatEvent.days_of_the_month = taskRepeat.monthlyEachRepetition;
      } else if (taskRepeat.monthlyType === 'bydaytype') {
        newRepeatEvent.day_name = taskRepeat.monthlyOnTheRepetition[1];
        newRepeatEvent.day_number = taskRepeat.monthlyOnTheRepetition[0];
      }
      break;

    case 'yearly':
      newRepeatEvent.months = taskRepeat.yearlyMonthRepetition;
      newRepeatEvent.day_name = taskRepeat.yearlyOnTheRepetition[1];
      newRepeatEvent.day_number = taskRepeat.yearlyOnTheRepetition[0];
      break;

    default:
      break;
  }

  return newRepeatEvent;
};

const generateFinalEventDate = (date: Date | null, time: Date | null): Date => {
  if (date === null || time === null) return new Date();
  const newDate = new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time.getHours(),
    time.getMinutes()
  );
  return newDate;
};

type TimeType = 'YEAR' | 'HOURS' | 'MINUTES';

const addTimeToDate = (
  date: Date | null,
  minutes: number,
  type: TimeType
): Date => {
  if (!date) {
    return new Date();
  }

  const dateModified = new Date(date);

  if (type === 'YEAR') {
    dateModified.setFullYear(date.getFullYear() + minutes);
  }

  if (type === 'HOURS') {
    dateModified.setHours(date.getMinutes() + minutes);
  }

  if (type === 'MINUTES') {
    dateModified.setMinutes(date.getMinutes() + minutes);
  }

  return dateModified;
};
export { generateRepeatApiEvent, generateFinalEventDate, addTimeToDate };
