import React from 'react';

import styled, { css } from 'styled-components';

import {
  PaletteProps, //eslint-disable-line
  TypographyProps,
  SpacingProps, //eslint-disable-line
} from '@mui/system';

import TkButton, { TkLoadingButton } from '@components/TkButton/index';
import Typography from '@components/TkTypography';
import { getColorFromTheme, getOutlinedHoverColor } from '@helpers/index';
import { ITkButtonProps } from '@interfaces/ITkButton';
import { ITkTypographyProps } from '@interfaces/ITkTypography';

type ITkButton = PaletteProps & SpacingProps & ITkButtonProps;
type ITkButtonTypography = PaletteProps &
  TypographyProps &
  ITkTypographyProps &
  SpacingProps;

const baseButtonStyles = css<ITkButton>`
  border-radius: 4px;
  font-weight: 500;
  font-size: 1rem;
  text-transform: capitalize !important;
  height: ${(props) => (props.height ? props.height : '36px')};
  &:hover {
    background: ${(props) =>
      props.color &&
      getOutlinedHoverColor(
        getColorFromTheme(props.theme.palette, props.color)
      )};
  }
  .MuiButton-iconSizeMedium :first-child {
    font-size: 16px;
  }
  &.MuiButton-text {
    color: ${(props) =>
      props.color && getColorFromTheme(props.theme.palette, props.color)};
    .MuiTypography-root {
      font-weight: bold;
    }
  }
`;

const StyledLoadingButton = styled(TkLoadingButton)<
  ITkButton & PaletteProps & SpacingProps & ITkButtonProps
>`
  ${baseButtonStyles}
`;

const StyledButton = styled(TkButton)<
  ITkButton & PaletteProps & SpacingProps & ITkButtonProps
>`
  ${baseButtonStyles}
`;

const getTypographyColorFromVariant = (props: any): string => {
  switch (props.buttonvariant) {
    case 'outlined':
      return getColorFromTheme(props.theme.palette, props.color);
    case 'contained':
      return '#fff !important';
    default:
      return props.color
        ? getColorFromTheme(props.theme.palette, props.color)
        : '#fff';
  }
};

const StyledTypography = styled(Typography)<ITkButtonTypography>`
  color: ${(props) => getTypographyColorFromVariant(props)};
`;

const DynamicButton = (props: any): JSX.Element => {
  const defaultFont = {
    size: props?.font?.size ?? '1rem',
    weight: props?.font?.weight ?? 'normal',
    family: props?.font?.family ?? 'Lato',
  };

  const isLoadingButton = props?.loading !== undefined;

  const ButtonContent = () => {
    return (
      <StyledTypography
        color={props.color}
        buttonvariant={props.variant}
        fontFamily={defaultFont.family}
        fontSize={defaultFont.size}
        fontWeight={defaultFont.weight}
      >
        {props.children}
      </StyledTypography>
    );
  };

  if (isLoadingButton) {
    return (
      <StyledLoadingButton {...props} disableElevation>
        <ButtonContent />
      </StyledLoadingButton>
    );
  }

  return (
    <StyledButton {...props} disableElevation props={props.disabled}>
      <ButtonContent />
    </StyledButton>
  );
};

export default DynamicButton;
