import React from 'react';

import { Grid } from '@mui/material';

import { renderOfferPillHeader, renderEventPillHeader } from './helpers';
import { PillGrid } from './styles';
import TkIcon from '@components/TkIcon';
import { TaskStatusEnum } from '@consts/index';

const TkPillMonthView: React.FC<ITkPillContentChild> = ({
  eventInfo,
  renderAssigneeIcon,
}) => {
  const { extendedProps } = eventInfo.event;

  return (
    <Grid container direction="row" spacing={1} alignItems="center">
      <Grid item alignItems="center">
        {extendedProps.status !== TaskStatusEnum.PLACEHOLDER
          ? renderAssigneeIcon(extendedProps.assigneeNames)
          : ''}
      </Grid>
      <PillGrid item sm>
        {extendedProps.isOffer
          ? renderOfferPillHeader(extendedProps, eventInfo.view.type)
          : renderEventPillHeader(extendedProps, eventInfo.view.type)}
      </PillGrid>
      {extendedProps.status === TaskStatusEnum.DONE ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TkIcon
            className="fa fa-check-circle"
            color="navyBlue"
            iconSize={10}
          />
        </div>
      ) : (
        ''
      )}
    </Grid>
  );
};

export default TkPillMonthView;
