import { Grid } from "@mui/material";
import styled from "styled-components";
import { withStyles } from "@mui/styles";

export const AssigneeSelectedButton = withStyles({
  root: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "5px 0",
    cursor: "pointer",
  },
})(Grid);

export const IconAssigneeButton = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #4952a9;
  border: #4952a9 1px solid;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: "Lato";
  font-weight: normal;
  font-size: 16px;
`;

export const DeleteIconAssignee = withStyles({
  root: {
    display: "flex",
    flex: "1",
    justifyContent: "center",
    alignItems: "center",
  },
})(Grid);

export const TkCircleIcon = styled.div`
  background-color: ${(props) => props.color};
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

export const ImgAssignee = styled.img`
  margin: 3px 10px 0 0;
  border-radius: 50%;
  width: 36px;
  height: 36px;
`;
