import { atom, atomFamily } from "recoil";

const editEventCrews = atomFamily<boolean, number>({
  key: "editEventCrews",
  default: false,
});

const isAllEditEventCrewsSelected = atom<boolean>({
  key: "isAllEditEventCrewsSelected",
  default: false,
});

export { editEventCrews, isAllEditEventCrewsSelected };
