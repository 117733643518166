import React from 'react';

import { useRecoilValue } from 'recoil';

import TkAssigneeList from './TkAssigneeList';
import TkCustomerList from './TkCustomerList';
import TkLocationList from './TkLocationList';
import TkExtendedDrawer from '@components/TkBaseDrawer/TkExtendedDrawer';
import { filterExtendedDrawer } from '@recoilData/index';

export enum CalendarFilterExtendedDrawer {
  CUSTOMERS = 'customers',
  LOCATIONS = 'locations',
  ASSIGNEES = 'assignees',
}

const TkExtendedCalendarFilterDrawer: React.FC = () => {
  const { type, open } = useRecoilValue(
    filterExtendedDrawer
  ) as ITkExtendedObject;
  const renderExtendedType = (type: string) => {
    const types = {
      [CalendarFilterExtendedDrawer.CUSTOMERS]: <TkCustomerList />,
      [CalendarFilterExtendedDrawer.LOCATIONS]: <TkLocationList />,
      [CalendarFilterExtendedDrawer.ASSIGNEES]: <TkAssigneeList />,
    };

    return types[type];
  };

  return (
    <TkExtendedDrawer open={open}>{renderExtendedType(type)}</TkExtendedDrawer>
  );
};

export default TkExtendedCalendarFilterDrawer;
