import { assigneesData } from "@recoilData/index";
import { selectorFamily, selector } from "recoil";

const searchAssigneesData = selectorFamily({
  key: "searchAssigneesData",
  get: (search: string) => ({ get }) => {
    const assignees = get(assigneesData);
    if (!search) return assignees;
    return assignees.filter((item) => {
      return item.full_name
        .toLocaleLowerCase()
        .includes(search.toLocaleLowerCase());
    });
  },
});

const getAssigneeDataById = selectorFamily({
  key: "getAssigneeDataById",
  get: (id) => ({ get }) => {
    const assignees = get(assigneesData);
    return assignees.find((assignee) => assignee.id === id);
  },
});

const getAssigneesDataCount = selector({
  key: "getAssigneesDataCount",
  get: ({ get }) => {
    const assignees = get(assigneesData);
    return assignees.length;
  },
});

export { searchAssigneesData, getAssigneesDataCount, getAssigneeDataById };
