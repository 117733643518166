import { DateTime } from 'luxon';
import { z } from 'zod';

import { locationSchema } from '@features/customers/schema';

export const formatValueToCurrency = (
  value: number,
  currency = 'USD',
  locale = 'en-US'
): string => {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(value);
};

export const calculateEstimatedTime = (minutes: number): string => {
  const [hours, min] = (minutes / 60).toFixed(2).split('.');

  if (Number(min) === 0) {
    return `${hours}h`;
  }

  const correctedMinutes = Number(min) * 60;

  return `${hours}h ${correctedMinutes.toString().slice(0, 2)}m`;
};

export const formatToShortTime = (time: DateTime): string => {
  return time.toFormat('t').replace(/:00/g, '');
};

export const formatAddress = (
  address: z.infer<typeof locationSchema>
): string => {
  const { streetAddress, number } = address;
  let unit = '';
  if (number) {
    unit = `#${number}`;
    if (Number.isNaN(+number)) {
      unit = number;
    }
    unit += ' | ';
  }

  return `${unit}${streetAddress}`;
};
