import { selector } from 'recoil';
import { teamDrawerToShow } from '@recoilData/index';

const setTeamDrawerToShow = selector({
  key: 'setTeamDrawerToShow',
  get: ({ get }) => get(teamDrawerToShow) as any,
  set: ({ set }, newValue) => set(teamDrawerToShow, newValue as any),
});

export { setTeamDrawerToShow };
