import styled from 'styled-components';

export const TkTaskIdWrapper = styled.div`
  display: flex;
  user-select: none;
  padding: 15px 1rem;
  background-color: #ffffff;
  justify-content: space-between;
  align-items: center;
`;

export const BaseDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 0;
  margin: 0;
`;

export const TaskId = styled(BaseDiv)`
  font-family: 'Muli';
  font-weight: bold;
  font-size: 16px;
`;

export const ButtonWrapper = styled.button<{ toggle: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
  padding: 0;
  margin: 0 10px 0 0;
  width: 24px;
  height: 24px;
  border: none;
  border-radius: 4px;
  background-color: ${(props) =>
    props.toggle ? props.theme.palette.lightPurple : props.theme.palette.white};

  :hover {
    background-color: ${(props) => props.theme.palette.lightPurple};
  }
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;
  height: 50px;
  cursor: pointer;
  align-items: center;
  user-select: none;
  :hover {
    background-color: ${(props) => props.theme.palette.lightPurple};
  }
`;
