import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { FiLogOut } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import { Divider, MenuItem } from '@mui/material';

import MenuNotifications from './MenuNotifications';
import {
  ScTkTopbarRightActions,
  ScTkTopBarUser,
  TopBarWrapper,
} from './styles';
import logo from '../../images/TKTopBar/Taskbird-horizontal-dark.svg';
import TkAppBar from '../TKAppbar';
import Avatar from '../TKAvatar';
import TKBellNotification from '../TKBellNotification';
import { logoContent as LogoContent } from '../TKLogoContent';
import { TkChatMessages } from '@components/index';
import { useAuth } from '@contexts/AuthContext/AuthProvider';
import { useNotifications } from '@contexts/NotificationContext/NotificationProvider';
import { Menu } from '@material-ui/core';

interface TopBarMainProps extends TopbarBaseProps {
  actionableButtons?: React.ReactNode;
  children?: React.ReactNode;
}

const Topbar = ({
  children,
  openSideBar,
  actionableButtons,
}: TopBarMainProps): JSX.Element => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElNotification, setAnchorElNotification] =
    useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const { logout } = useAuth();
  const { loadNotifications, notificationsTotalUnread } = useNotifications();

  const style = {
    style: {
      borderRadius: '2px',
      background: '#FFFFFF',
      width: 143,
      height: 57,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    styleAvatar: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'black',
      width: '77%',
    },
  };

  const styleLogout = {
    style: {
      color: '#011F41',
      marginLeft: 14,
    },
  };

  useEffect(() => {
    loadNotifications();
    const interval = setInterval(async () => {
      loadNotifications();
    }, 40000);
    return () => clearInterval(interval);
  }, [loadNotifications]);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuCloseLogout = () => {
    setAnchorEl(null);
    logout();
  };

  const handleProfileMenuOpenNotification = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorElNotification(event.currentTarget);
  };

  const profileMenu = [
    {
      label: 'profile',
      path: '/settings/profile',
    },
    {
      label: 'tabs.team',
      path: '/settings/team',
    },
    /* {
      label: 'tabs.notifications',
      path: '/settings/notifications',
    }, */
  ];

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
      anchorReference="anchorPosition"
      anchorPosition={{ top: 56, left: window.screen.width - 60 }}
      PaperProps={{ style: { minWidth: 176 } }}
    >
      {profileMenu.map((item, index) => (
        <Link
          key={index}
          to={item.path}
          style={{
            textDecoration: 'none',
            display: 'block',
            color: '#011F41',
          }}
        >
          <MenuItem>{t(item.label, { ns: 'profile' })}</MenuItem>
        </Link>
      ))}
      <Divider sx={{ marginTop: '8px' }} />
      <MenuItem onClick={handleMenuCloseLogout} style={{ marginTop: '8px' }}>
        <FiLogOut color="#011F41" />
        <div style={styleLogout.style}>{t('logout', { ns: 'common' })}</div>
      </MenuItem>
    </Menu>
  );

  return (
    <TopBarWrapper>
      <TkAppBar>
        <LogoContent logo={logo} onClick={openSideBar} />
        {children}
        <ScTkTopbarRightActions>
          {actionableButtons && actionableButtons}
          <ScTkTopBarUser>
            <TKBellNotification
              openMenu={handleProfileMenuOpenNotification}
              notificationsNumber={notificationsTotalUnread}
            />
            <TkChatMessages />
            <Avatar openMenu={handleProfileMenuOpen} />
          </ScTkTopBarUser>
        </ScTkTopbarRightActions>
      </TkAppBar>

      {renderMenu}
      <MenuNotifications
        anchorElNotification={anchorElNotification}
        setAnchorElNotification={setAnchorElNotification}
        notificationsTotalUnread={notificationsTotalUnread}
      />
    </TopBarWrapper>
  );
};

export default Topbar;
