import React from 'react';

import { DateTime } from 'luxon';
import { CalendarEventExtendedProps } from 'types/calendar';

import { Box } from '@mui/material';

import TkPulsatingIcon from './TkPulsatingIcon';
import { ImgWrapper } from './styles';
import { TurnoverBNB_PNG, MoveOut } from '@assets/index';
import { RepeatingIcon } from '@components/TkPillContent/TkRepeatingIcon';
import { SameDayArrivalIcon } from '@components/TkPillContent/TkSameDayArrivalIcon';
import { TkTypography, TkIcon, TkTooltip } from '@components/index';
import {
  ICalendarViewEnum,
  TaskStatusEnum,
  TaskbirdIntegrationsEnum,
} from '@consts/index';
import {
  userTimezone,
  formatToShortTime,
  generateListAssigneeString,
} from '@helpers/index';

export const formatPillTitle = (title: string): string => {
  const STRING_TO_REMOVE = 'Vacation Rental';
  return title.replace(STRING_TO_REMOVE, '');
};

export const selectTimezone = (
  extendedProps: CalendarEventExtendedProps | IEvent
): string => {
  if (
    extendedProps?.location?.timezone &&
    extendedProps?.location?.timezone !== 'UTC'
  )
    return extendedProps.location.timezone;
  const timezone = userTimezone();
  return timezone;
};

export const convertTimtWithTimezone = (
  time: number,
  timezone: string
): string => {
  return formatToShortTime(
    DateTime.fromISO(DateTime.fromMillis(time).toISO(), {
      zone: timezone,
    })
  );
};

export const renderOfferPillHeader = (
  extendedProps: CalendarEventExtendedProps,
  type: string
): JSX.Element => {
  const { start_time, end_time, projectOfferSystemNotes, platform, location } =
    extendedProps;

  const timezone = selectTimezone(extendedProps);
  // @ts-ignore
  const start = convertTimtWithTimezone(start_time.ts, timezone);
  // @ts-ignore
  const end = convertTimtWithTimezone(end_time.ts, timezone);
  const isBooking = platform === TaskbirdIntegrationsEnum.BOOKING_WIDGET;
  const sameDayArrival = projectOfferSystemNotes?.guestArrivesSameDay === 1;

  const time = isBooking
    ? start && start
    : start && start + ' — ' + (end ?? '');

  return (
    <>
      <Box
        mr={1}
        alignItems="center"
        display="inline-flex"
        ml={sameDayArrival ? 1 : 0}
      >
        {sameDayArrival ? (
          <div style={{ marginRight: 4 }}>
            <SameDayArrivalIcon />
          </div>
        ) : (
          ''
        )}
        <TkTypography fontWeight="bold" fontSize={12} display="inline">
          {time}
        </TkTypography>
      </Box>
      {type === ICalendarViewEnum.MONTH_VIEW && (
        <span>{location?.street_address}</span>
      )}
    </>
  );
};

export const renderEventPillHeader = (
  extendedProps: CalendarEventExtendedProps,
  type: string
): JSX.Element => {
  const {
    status,
    end_time,
    location,
    start_time,
    isRecurring,
    sameDayArrival,
  } = extendedProps;

  const hasTooltipIcon = sameDayArrival || isRecurring;

  let startTime: DateTime | string = DateTime.now();
  let endTime: DateTime | string = DateTime.now();
  if (status !== TaskStatusEnum.PLACEHOLDER) {
    endTime = formatToShortTime(end_time);
    startTime = formatToShortTime(start_time);
  }

  return (
    <>
      <Box
        mr={1}
        alignItems="center"
        display="inline-flex"
        ml={hasTooltipIcon ? 1 : 0}
      >
        {status === TaskStatusEnum.PLACEHOLDER ? null : (
          <>
            {hasTooltipIcon ? (
              <div style={{ marginRight: 4 }}>
                {sameDayArrival ? <SameDayArrivalIcon /> : <RepeatingIcon />}
              </div>
            ) : (
              ''
            )}
            <TkTypography fontWeight="bold" fontSize={12} display="inline">
              {startTime &&
                startTime +
                  ' — ' +
                  (status !== TaskStatusEnum.IN_PROGRESS ? endTime : '')}
            </TkTypography>
            {status === TaskStatusEnum.IN_PROGRESS ? <TkPulsatingIcon /> : ''}
          </>
        )}
      </Box>
      {type === ICalendarViewEnum.MONTH_VIEW && (
        <span>{location?.street_address}</span>
      )}
    </>
  );
};

export const renderIntegrations = (
  extendedProps: CalendarEventExtendedProps
): JSX.Element | null => {
  const { platform } = extendedProps;

  if (!platform) {
    return null;
  }

  const integrationIcons: any = {
    bookingWidget: (
      <TkIcon iconSize={12} className="fa fa-calendar-check" color="#4952A9" />
    ),
    moveout: <ImgWrapper src={MoveOut} style={{ marginBottom: '4px' }} />,
    turnoverbnb: (
      <ImgWrapper src={TurnoverBNB_PNG} style={{ margin: '0px 0px 3px 1px' }} />
    ),
  };

  return integrationIcons[platform];

  // TODO: ADD PLATFORM LISTING OPTION BOOKING OFFER LIST
};

export const renderAssigneeIcon = (
  assigneeNames: Array<string>
): JSX.Element => {
  const len = assigneeNames.length;
  const tooltipProps = {
    title: 'Unassigned',
    icon: 'fa fa-question-circle',
  };

  if (len === 1) {
    tooltipProps.title = assigneeNames[0];
    tooltipProps.icon = 'fa fa-user';
  } else if (len > 1) {
    tooltipProps.title = generateListAssigneeString(assigneeNames, len);
    tooltipProps.icon = 'fa fa-user-friends';
  }

  return (
    <TkTooltip title={tooltipProps.title}>
      <TkIcon
        iconSize={12}
        color="navyBlue"
        style={{ maxWidth: '13px' }}
        className={tooltipProps.icon}
      />
    </TkTooltip>
  );
};
