import { assigneesData, getUserAccountId } from '@recoilData/index';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { loadAssigneeApiData } from '../services/apiLoaders';

const useMembersMethods = () => {
  const accountId = useRecoilValue(getUserAccountId);
  const setAssignees = useSetRecoilState(assigneesData);

  const fetchMembersData = async () => {
    if (!accountId) {
      return;
    }

    const members = await loadAssigneeApiData(accountId);
    setAssignees(members);
  };

  return { fetchMembersData };
};

export { useMembersMethods };
