import React, { useMemo } from 'react';
import { TkInputWidgetBooking } from './styles';
import { useTranslation } from 'react-i18next';
import { TkFormControlInputWrapper } from '@components/index';

interface IFormControlInput {
  type: string;
  value: string;
  hasError: boolean;
  countryCode?: string;
  onChange: (value: string, index: string) => void;
}

const FormControlInput: React.FC<IFormControlInput> = ({
  type,
  value,
  hasError,
  countryCode = '',
  onChange,
}) => {
  const { t } = useTranslation();
  const auxType = type === 'complement' ? 'apt_unit' : type;
  const handleChange = (e) => onChange(e.target.value, type);

  const placeholder = t(
    [
      `searchAddress.${auxType}.${countryCode}`,
      `searchAddress.${auxType}.default`,
      `searchAddress.${auxType}`,
    ],
    { ns: 'bookingWidget' }
  );

  const errorTokenizedLabel = useMemo(() => {
    const field =
      countryCode === 'CA' ? placeholder.toLowerCase() : placeholder;
    return t('others.pleaseEnterA', {
      ns: 'app',
      interpolation: { escapeValue: false },
      field,
    });
  }, [placeholder]);

  return (
    <TkFormControlInputWrapper
      error={hasError}
      errorLabel={errorTokenizedLabel}
    >
      <TkInputWidgetBooking
        id={auxType}
        error={hasError}
        variant="standard"
        value={value || ''}
        onChange={handleChange}
        placeholder={placeholder}
        aria-describedby="component-error-text"
      />
    </TkFormControlInputWrapper>
  );
};

export default FormControlInput;
