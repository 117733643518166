export default {
  login: {
    title: 'Login with e-mail',
    orContinueWith: 'OR CONTINUE WITH',
    wrongEmailOrPassword: 'Wrong email or password.',
    forgotPassword: 'Forgot password?',
    newToTaskbird: 'New to Taskbird?',
    alreadyHaveAnAccount: 'Already have an account',
    createAccount: 'Create account',
    firstName: 'First name',
    lastName: 'Last name',
    login: 'Log in',
    signUp: 'Sign Up',
    password: 'Password',
    emailPlaceholder: 'name@email.com',
    signUpMessage: 'Sign up to start working with your team!',
    taskbirdWebAppIsOnlyForAdmins:
      'Taskbird web app is intended only for Administrators.',
    invalidCredentials: 'Wrong email or password.',
    invalidCredentialsSocialMedia:
      'Invalid credentials. Access denied with Social Media.',
    signUpSubMessage:
      'Taskbird web is only for team owners. To join a team, please log in to our mobile app available for free for <0>iOS</0> and <1>Android</1>.',
    illusText:
      'The free and easy-to-use cleaning<0 />and maintenance company<1/>software for you and your team',
    mustBeAtLeast8Char: 'Must be at least 8 characters long',
    pleaseEnterYourFirstName: 'Please enter your first name',
    pleaseEnterYourLastName: 'Please enter your last name',
    pleaseEnterAPassword: 'Please enter a password',
    doYouWantToCreateNewAccountOrTryLogin:
      'Do you want create a new Taskbird account or try to log in using a different method?',
    createNewAccount: ' Create a new account',
    useDifferentMethod: 'Log in with a different method',
    useDifferentEmail: 'Use a different email',
    loginWithOtherAccountMessage:
      'Do you want to log in to your existing account or use a different email?',
    emailAlreadyInUse: 'This email address is already used by another account',
    adminOnlyMainMessage:
      'Hey, {{name}}! Our website is only for account owners and admins, but Taskbird has a great mobile app.',
    socialLoginError:
      'We couldn’t find any Taskbird<0/> account related to your<0/> {{social}} account',
    modal: {
      needANewAccount: ' Need a new Taskbird account?',
      getStartRightNow: 'Get started right now!',
      description:
        'Taskbird works mainly as a mobile app. Our website is intended for<0/> current users who prefer managing tasks from a computer. To create a<0/>new account please download our mobile app available for free for<0/>iOS and Android.',
    },
  },
  forgotPasswordModal: {
    forgotYourPassword: 'Forgot your password?',
    forgotYourPasswordText:
      'Don´t worry, resetting your password is easy, just tell us the email you registered with Taskbird',
    iAlreadyHaveACode: 'I already have a code',
    resetPassword: 'Reset Password',
    resetYourPassword: 'Reset your password',
    codeSend: 'We have sent a recovery code to <0>{{email}}<0>',
    pleaseEnterTheCodeBelow:
      'Please enter the code below to set a new password:',
    resendEmail: 'Resend the email',
    pleaseEnterNewPassword: 'Please enter your new password:',
    savePassword: 'Save Password',
    passwordUpdated: "Password updated successfully. Log in using your new credentials.",
  },
  welcomeSetupModal: {
    welcomeToTaskbirdName: 'Welcome to Taskbird, {{name}}!',
    whatsYourMainGoal: 'What’s your main goal today?',
    setupNewTeamText: 'Set Up a New Team',
    setupNewTeamSubText:
      'I manage a team and want to invite my teammates to collaborate',
    joinTeamText: 'Join a Team',
    joinTeamSubText: 'I was invited to join a team by email or SMS',
    letsAddYourTeamName: "Let's add your Team name:",
    teamName: 'Team name',
    didYouReceiveAnInvite: 'Did you receive an invite? <0>Join a team</0>',
    enterYourInvitationCode: 'Enter your invitation code',
    invitationCodeSent: 'The invitation code is in the invitation sent to you',
    doYouManageATeamQuestion:
      'Do you manage a team and want to invite your teammates?',
    congratsAllSet: 'Congrats! You are all set!',
    congratsAllSetMessage:
      'You are now ready to start creating tasks. But first we would like to introduce you to some of the features available on Taskbird.',
    congratsJoinedTeam: 'Congrats! You are now part of {{team}}.',
    accountOwnersOnly:
      'Our website is only for account owners and admins, but Taskbird has a great mobile app.',
    weHaveSentTheSMSLink: 'We have sent you an SMS with the link to the app',
    congratsJoinedTeamMessage:
      'To work with your team and start completing tasks, please log in to our mobile app available for free for iOS and Android.',
    getStartRightNow: 'Get started right now!',
    weWillSendYouAnSMSWithLink:
      'We will send you an SMS message with a link to the app',
    teamNameRequired: 'Please enter your Team name',
  },
  sendMeTheAppModal: {
    title: 'We will send you a SMS message with a link to your phone',
    texting: 'Texting...',
    textMeTheApp: 'Text me the App!',
  },
  calendar: {
    search_no_results:
      '<0>No tasks were found.</0> Please <2>clear the search to try again.</2>',
  },
  event: {
    errors: {
      repeating_task_edit_dates_error: 'Repeating task dates cannot be edited',
      imported_task_edit_dates_error: 'Imported task dates cannot be edited',
    },
  },
  task: {
    tasks_today: 'Tasks Today',
    task_singular: 'Task',
    tasks_plural: 'Tasks',
    no_task_today: 'No Tasks Today',
    x_tasks_today: '1 Task Today',
    x_tasks_today_plural: '{{count}} Tasks Today',
    no_task_week: 'No Tasks This Week',
    x_tasks_week: '1 Task This Week',
    x_tasks_week_plural: '{{count}} Tasks This Week',
  },
  navbar: {
    tasks: 'Tasks',
    team: 'Team',
    customers: 'Customers',
    checklists: 'Checklists',
    bookingWidget: 'Booking Site',
    integrations: 'Integrations',
    chat: 'Chat',
  },
  tkTags: {
    sameDayArrival: 'Same day arrival',
    notCompleted: 'Not Completed',
    finishedLate: 'Finished Late',
    startedLate: 'Started Late',
    notStarted: 'Not Started',
    completedInTurnoverBnB: 'Completed in Turno',
    completed: 'Completed',
    repeating: 'Repeating',
    bookingWidget: 'Booking Site',
  },
  others: {
    pleaseEnterYour: 'Please enter your {{field}}',
    pleaseEnterA: 'Please enter a {{field}}',
    pleaseEnterAn: 'Please enter an {{field}}',
    lastName: 'Last Name',
    validEmail: 'Please enter a valid email',
    invalidCode:
      'The code you entered is either expired or already used. Please try again',
    recaptchaFail: 'Failed on reCAPTCHA verification',
  },
  locations: {
    youDontHaveAnyLocations: "You don't have any locations",
    thisCustomerDoesntHaveAnyLocations:
      "This customer doesn't have any locations",
  },
  error: {
    somethingWentWrong: 'Something went wrong',
    tryAgain: 'Try again',
  }
};
