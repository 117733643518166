import { AppLocationURLs } from '@consts/index';
import { isNewTaskExternal, selectEventCrew } from '@recoilData/index';
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';

export const useAssignCrewTask = () => {
  const setNewTaskExternal = useSetRecoilState(isNewTaskExternal);
  const setEventCrew = useSetRecoilState(selectEventCrew);
  const history = useHistory();

  const assignCrewTask = (crewId: number) => {
    // @ts-ignore
    setEventCrew({ id: crewId, checked: true });
    setNewTaskExternal(true);
    history.push(AppLocationURLs.HOME);
  };

  return {
    assignCrewTask,
  };
};
