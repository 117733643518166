import React from 'react';

import { useHistory } from 'react-router-dom';

import { MenuItem } from '@mui/material';

import { useStyles } from '../TKTopbar/styles';
import { NotificationContent } from '@components/index';
import { useNotifications } from '@contexts/index';
import { INotificationItem } from '@hooks/notification/types';

interface NotificationItemProps {
  notification: INotificationItem;
  handleMenuCloseNotification: () => void;
}

const NotificationItem = ({
  notification,
  handleMenuCloseNotification,
}: NotificationItemProps): JSX.Element => {
  const {
    id,
    read_at: readAt,
    data: { meta },
  } = notification;
  const classes = useStyles();
  const history = useHistory();
  const { markAsRead, getNotificationHandler, setSelectedNotification } =
    useNotifications();
  const { getRoute } = getNotificationHandler(meta);

  const handleMarkNotification = (id: string, read_at: string | null) => {
    if (read_at === null) {
      markAsRead(id);
    }
  };

  const handleClick = () => {
    handleMarkNotification(id, readAt);
    handleMenuCloseNotification();
    const routes = getRoute();
    setSelectedNotification(meta);
    history.push(routes.url);
  };

  return (
    <MenuItem
      key={notification?.id}
      onClick={() => handleClick()}
      disableGutters
      classes={{ root: classes.root }}
    >
      <NotificationContent
        notification={notification}
        read={notification?.read_at === null}
      />
    </MenuItem>
  );
};

export default NotificationItem;
