import TkIcon from '@components/TkIcon';
import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TooltipRenderProps } from 'react-joyride';
import { BoxTooltip, BoxTooltipButtonSkip, BoxTooltipButtonStep, BoxTooltipButtonStepFinish, BoxTooltipContent, BoxTooltipContentButton, BoxTooltipContentButtonControls, BoxTooltipProgressDots, BoxTooltipTitle } from './styles';

function TkTourGuideToolTip({
  size,
  step,
  index,
  isLastStep,
  skipProps,
  backProps,
  continuous,
  tooltipProps,
  primaryProps,
}: TooltipRenderProps) {
 const { t } = useTranslation();
 return (
    <BoxTooltip {...tooltipProps}>
      <Box sx={{ height: "76%" }}>
        {step.title &&
          <BoxTooltipTitle>
            <h3>
              {step.title}
            </h3>
          </BoxTooltipTitle>
        }
        {step.content &&
          <BoxTooltipContent>
            {step.content}
          </BoxTooltipContent>
        }
      </Box>
      <BoxTooltipContentButton>
        <BoxTooltipContentButtonControls>
          {!isLastStep &&
            <BoxTooltipButtonSkip variant="text" id="skip" {...skipProps}>
              {t('skip', { ns: 'common' })}
            </BoxTooltipButtonSkip>
          }
          <BoxTooltipProgressDots variant='dots' steps={size > 7 ? 7 : size} backButton={<></>} nextButton={<></>} activeStep={index}/>
        </BoxTooltipContentButtonControls>
        <BoxTooltipContentButtonControls>
          {index > 0 ?
            <BoxTooltipButtonStep {...backProps}>
              <TkIcon id="back" className="fa fa-chevron-left" iconSize={11} />
            </BoxTooltipButtonStep> 
            : <div></div>
          }
          {index == 4 || index == 6 || index == 8 || isLastStep  ?
            <BoxTooltipButtonStepFinish {...primaryProps}>Got it!</BoxTooltipButtonStepFinish>
            : <BoxTooltipButtonStep {...primaryProps}>
              {continuous ? 
                <TkIcon id="next" className="fa fa-chevron-right" iconSize={11} />
                : <BoxTooltipButtonStepFinish>Got it!</BoxTooltipButtonStepFinish>
              }
            </BoxTooltipButtonStep>
          }
        </BoxTooltipContentButtonControls>
      </BoxTooltipContentButton>
    </BoxTooltip>
  )
}

export default TkTourGuideToolTip;
