export default {
  step1: {
    title: "Create and Assign Tasks",
    message:
      "Manage your team by creating and assigning tasks. Automate your daily tasks and get notified as things happen.",
  },
  step2: {
    title: "Invite your Teammates",
    message:
      "Invite your employees to collaborate using the mobile app and track your team’s work and task completion. Ask them to set up their availability and avoid no-shows.",
  },
  step3: {
    title: "Add Customers",
    message:
      "Add customers and start scheduling tasks from your residential and commercial customers.",
  },
  step4: {
    title: "Keep Track of your Work",
    message:
      "Organize and document your jobs with note taking, image upload, and Checklists with photo documentation. Require your teammates to add photos on specific checklist tasks to ensure quality.",
  },
  step5: {
    title: "Sync a Calendar",
    message:
      "Pull scheduled events from your Calendar directly into Taskbird by adding your Google Calendar or Other/iCals. Keep your schedule organized in one place so you never miss a project.",
  },
  step6: {
    title: "Integrate with Turno (formely TurnoverBnB)",
    message:
      "Integrate with the leading vacation rental cleaning platform and pull your projects and customers directly into Taskbird from Turno.",
  },
};
