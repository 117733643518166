import React from 'react';
import { Box, Grid } from '@mui/material';
import {
  TkTaskWrapperItemFilled,
  HorizontalDivider,
  TkTaskWrapperSubItem,
} from './styles';
import {
  TkIcon,
  TkTypography,
} from '@components/index';
import { useTeamNotesAndImages } from '../hooks/useTeamNotesAndImages';

interface TeamNotesAndImages {
  selectedEvent: IEvent;
}

const TkTeamNotesAndImages = ({
  selectedEvent
}: TeamNotesAndImages) => {
  const {
    t,
    extendedObject,
    teammatesImageCount,
    statusIsToDoOrInProgress,
    handleTeammatesNotes,
    handleTeammatesImages,
  } = useTeamNotesAndImages();

  return (
    <TkTaskWrapperItemFilled>
      <TkTaskWrapperSubItem
        container
        justifyContent='space-between'
        color={
          extendedObject.type === 'teammatesNotes' ? 'selected' : ''
        }
        onClick={handleTeammatesNotes}
        style={{
          cursor: statusIsToDoOrInProgress ? 'pointer' : 'initial',
        }}
      >
        <Grid>
          <TkTypography fontSize='16px' fontFamily='Lato'>
            <TkIcon
              className='fa fa-pencil-alt'
              marginRight={5}
              iconSize={16}
              verticalAlign='sub'
            />
            {t('teammatesNotes', { ns: 'events' })}:
          </TkTypography>
        </Grid>
        <Grid>
          <TkTypography
            fontSize='16px'
            fontFamily='Lato'
            textAlign='right'
            color='rgba(1,31,65,0.8)'
          >
            {selectedEvent.crewAssignmentsNotes === null ||
            selectedEvent.crewAssignmentsNotes.length === 0
              ? t('no_notes', { ns: 'common' })
              : selectedEvent.crewAssignmentsNotes.length}
          </TkTypography>
        </Grid>
      </TkTaskWrapperSubItem>
      <Box>
        <HorizontalDivider />
      </Box>
      <TkTaskWrapperSubItem
        container
        justifyContent='space-between'
        color={
          extendedObject.type === 'teammatesImages' ? 'selected' : ''
        }
        onClick={handleTeammatesImages}
        style={{
          cursor: statusIsToDoOrInProgress ? 'pointer' : 'initial',
        }}
      >
        <Grid>
          <TkTypography fontSize='16px' fontFamily='Lato'>
            <TkIcon
              className='fa fa-camera'
              marginRight={5}
              iconSize={14}
              verticalAlign='middle'
            />
            {t('teammateImages', { ns: 'events' })}
          </TkTypography>
        </Grid>
        <Grid>
          <TkTypography
            fontSize='16px'
            fontFamily='Lato'
            textAlign='right'
            fontWeight={teammatesImageCount === 0 ? 'normal' : 'bold'}
            color='rgba(1,31,65,0.8)'
          >
            {teammatesImageCount === 0
              ? t('no_images', { ns: 'common' })
              : teammatesImageCount}
          </TkTypography>
        </Grid>
      </TkTaskWrapperSubItem>
    </TkTaskWrapperItemFilled>
  );
}

export default TkTeamNotesAndImages;