import styled from 'styled-components';

import { Typography } from '@mui/material';
import {
  spacing,
  SpacingProps, // eslint-disable-line
  palette,
  PaletteProps, //eslint-disable-line
  typography,
  TypographyProps, //eslint-disable-line
} from '@mui/system';

import { ITkTypographyProps } from '@interfaces/ITkTypography';

const TkTypography = styled(Typography)<
  PaletteProps & TypographyProps & ITkTypographyProps & SpacingProps
>`
  ${palette}
  ${spacing}
  ${typography}
  ${(props) => (props.userselect ? `user-select: ${props.userselect}` : '')}
`;

export default TkTypography;
