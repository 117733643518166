export enum CalendarDrawerTypes {
  offer = 'offer',
  filter = 'filter',
  newTask = 'newTask',
  editTask = 'editTask',
  listOffers = 'listOffers',
}

export enum EventTypeEnum {
  EDIT = 'edit',
  NEW = 'new',
}
