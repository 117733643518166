import React from 'react';

import { useSetRecoilState, useRecoilValue, RecoilState } from 'recoil';

import {
  TkCrewItem,
  TkListItemSubText,
} from '@components/ExtendedDrawers/styles';
import TkCheckBox from '@components/TkCheckBox';
import TkIcon from '@components/TkIcon';
import TkTypography from '@components/TkTypography';
import { useDrawer } from '@hooks/index';
import { Grid } from '@material-ui/core';

interface TkCrewListItemProps {
  crew: ICrew;
  selectEventCrewRecoilState: RecoilState<void>;
  eventCrewsRecoilState(param: number): RecoilState<boolean>;
}

const TkCrewListItem = ({
  crew,
  eventCrewsRecoilState,
  selectEventCrewRecoilState,
}: TkCrewListItemProps): JSX.Element => {
  const { closeExtDrawer } = useDrawer();
  const crewState = useRecoilValue(eventCrewsRecoilState(crew.id));

  const setEditEventCrew = useSetRecoilState(selectEventCrewRecoilState);

  const handleSelectCrew = (checked: boolean, closeTaskFlag = false) => {
    setEditEventCrew({ id: crew.id, checked } as any);
    if (closeTaskFlag) closeExtDrawer();
  };

  return (
    <TkCrewItem
      key={crew.id}
      name={`crew-${crew.name}`}
      checked={crewState}
      control={
        <TkCheckBox
          checked={crewState}
          onChange={(_, checked) => handleSelectCrew(checked)}
        />
      }
      label={
        <div onClick={() => handleSelectCrew(!crewState, true)}>
          <Grid container direction="column">
            <TkTypography fontWeight="bold" fontFamily="Lato" fontSize={16}>
              {crew.name}
            </TkTypography>
            <TkListItemSubText item>
              <TkIcon
                className="fa fa-exclamation-triangle"
                color="#011F41"
                iconSize={12}
                width={15}
                marginTop={5}
                marginRight={5}
              />
              {crew.members.length} Members
            </TkListItemSubText>
          </Grid>
        </div>
      }
    />
  );
};

export default TkCrewListItem;
