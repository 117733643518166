import {
  DeleteCalendarInput,
  DeleteCalendarResult,
  GoogleCalendarApiDataInput,
  GoogleCalendarApiDataResult,
  IntegrateWithMoveOutInput,
  IntegrateWithMoveOutResult,
  IntegrateWithTBnBBodyRequest,
  IntegrateWithTBnBResult,
  RequestPlatformInput,
  RequestPlatformResult,
  SyncToGoogleCalendarInput,
  SyncToGoogleCalendarResult,
  ToggleMoveOutRequestsInput,
  ToggleMoveOutRequestsResult,
  ValidateTBnBIntegrationCodeResult,
} from 'types/apiMethods.type';

import { ApiResultsKind } from '@consts/api';
import api from '@services/api';

const loadIntegrationsApiData = (
  accountId: number
): Promise<{
  kind: ApiResultsKind;
  integrations: any;
}> =>
  api
    .get(`account/${accountId}/integrations`)
    .then(({ data }) => {
      Object.entries(data).forEach(([k, v]) => {
        if (Array.isArray(v) && v.length === 0) data[k] = undefined;
      });

      return {
        kind: ApiResultsKind.OK,
        integrations: data,
      };
    })
    .catch(() => {
      return Promise.reject({
        kind: ApiResultsKind.ERROR,
        integrations: null,
      });
    });

const requestPlatform = ({
  userId,
  platformName,
}: RequestPlatformInput): Promise<RequestPlatformResult> =>
  api
    .post(`user/${userId}/makeSuggestion`, { text: platformName })
    .then(() => ({
      kind: ApiResultsKind.OK,
    }))
    .catch(() => {
      return Promise.reject({ kind: ApiResultsKind.ERROR });
    });

const integrateWithTurnoverBnB = (
  accountId: number,
  body: IntegrateWithTBnBBodyRequest
): Promise<IntegrateWithTBnBResult> =>
  api
    .post(
      `account/${accountId}/integrations/integrateTBnBAccount`,
      {
        ...body,
      },
      { headers: { DontLogout: 'true' } }
    )
    .then(() => {
      return { kind: ApiResultsKind.OK, errorToken: undefined };
    })
    .catch(({ response }) => {
      return Promise.reject({
        kind: ApiResultsKind.ERROR,
        errorToken: response.data,
      });
    });

const validateTBnBIntegrationCode = (
  accountId: number,
  code: string
): Promise<ValidateTBnBIntegrationCodeResult> =>
  api
    .post(
      `account/${accountId}/integrations/validateTBnBIntegrationCode`,
      {
        code,
      },
      { headers: { DontLogout: 'true' } }
    )
    .then(() => {
      return { kind: ApiResultsKind.OK, errorToken: undefined };
    })
    .catch(({ response }) => {
      return Promise.reject({
        kind: ApiResultsKind.ERROR,
        errorToken: response.data,
      });
    });

const integrateWithMoveOut = ({
  rate,
  accountId,
}: IntegrateWithMoveOutInput): Promise<IntegrateWithMoveOutResult> =>
  api
    .post(`account/${accountId}/integrations/optInMoveout`, { rate })
    .then(() => ({ kind: ApiResultsKind.OK }))
    .catch(() => Promise.reject({ kind: ApiResultsKind.ERROR }));

const editMoveOutRate = ({
  rate,
  accountId,
}: IntegrateWithMoveOutInput): Promise<IntegrateWithMoveOutResult> =>
  api
    .post(`account/${accountId}/rate`, { rate })
    .then(() => ({ kind: ApiResultsKind.OK }))
    .catch(() => Promise.reject({ kind: ApiResultsKind.ERROR }));

const toggleMoveOutRequests = ({
  moveoutAppearance,
  accountId,
}: ToggleMoveOutRequestsInput): Promise<ToggleMoveOutRequestsResult> =>
  api
    .put(`account/${accountId}/settings`, {
      moveout_appearance: moveoutAppearance,
    })
    .then(() => ({ kind: ApiResultsKind.OK }))
    .catch(() => Promise.reject({ kind: ApiResultsKind.ERROR }));

const loadGoogleCalendarApiData = ({
  accountId,
}: GoogleCalendarApiDataInput): Promise<GoogleCalendarApiDataResult> =>
  api
    .get(`account/${accountId}/integrations/calendar/google`)
    .then(({ data }) => {
      return { kind: ApiResultsKind.OK, calendars: data };
    })
    .catch(() => Promise.reject({ kind: ApiResultsKind.ERROR }));

const syncToGoogleCalendar = ({
  accountId,
  calendars,
}: SyncToGoogleCalendarInput): Promise<SyncToGoogleCalendarResult> =>
  api
    .post(`account/${accountId}/integrations/calendar/google`, { calendars })
    .then(() => ({ kind: ApiResultsKind.OK }))
    .catch(() => Promise.reject({ kind: ApiResultsKind.ERROR }));

const deleteCalendar = ({
  accountId,
  calendarId,
}: DeleteCalendarInput): Promise<DeleteCalendarResult> =>
  api
    .delete(`/account/${accountId}/integrations/calendar/${calendarId}`)
    .then(() => ({ kind: ApiResultsKind.OK }))
    .catch(() => Promise.reject({ kind: ApiResultsKind.ERROR }));

export {
  deleteCalendar,
  editMoveOutRate,
  integrateWithMoveOut,
  integrateWithTurnoverBnB,
  loadGoogleCalendarApiData,
  loadIntegrationsApiData,
  requestPlatform,
  syncToGoogleCalendar,
  toggleMoveOutRequests,
  validateTBnBIntegrationCode,
};
