import React from "react";

import { Container, LogoContent, IconContent } from "./styles";
import MenuIcon from "@material-ui/icons/Menu";

interface ILogoContent {
  onClick?: () => void;
  logo: string;
  color?: string;
  width?: string;
}

const logoContent: React.FC<ILogoContent> = (props) => {
  return (
    <Container onClick={props.onClick} color={props.color} width={props.width}>
      <IconContent color={props.color}>
        <MenuIcon />
      </IconContent>
      <LogoContent>
        <img src={props.logo} alt="Logo" />
      </LogoContent>
    </Container>
  );
};

const logoContentDark: React.FC<ILogoContent> = (props) => {
  return (
    <Container onClick={props.onClick} color={props.color} width={props.width}>
      <IconContent color={"#f5f6fb"}>
        <MenuIcon />
      </IconContent>
      <LogoContent>
        <img src={props.logo} alt="Logo" />
      </LogoContent>
    </Container>
  );
};

export { logoContent, logoContentDark };
