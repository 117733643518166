import React from 'react';

import { useRecoilState, useResetRecoilState } from 'recoil';

import { Grid } from '@mui/material';

import { OverFlowTypography, TkLocationItemStyle } from './styles';
import { TkIcon, TkTypography, TkCheckBox } from '@components/index';
import { formatAddress } from '@helpers/formatters';
import { closeFilterExtendedDrawer, filterLocations } from '@recoilData/index';

interface TkLocationListItemProps {
  location: ILocationTask;
  popup?: boolean;
}

const TkLocationListItem = ({
  location,
  popup = false,
}: TkLocationListItemProps): JSX.Element => {
  const closeExtendedDrawer = useResetRecoilState(closeFilterExtendedDrawer);
  const [filterLocation, setFilterLocation] = useRecoilState(
    filterLocations(location.id)
  );

  const handleSelectLocation = (checked: boolean, unique = false) => {
    setFilterLocation(checked);
    if (unique && !popup) closeExtendedDrawer();
  };

  return (
    <TkLocationItemStyle
      key={location.id}
      checked={filterLocation}
      control={
        <TkCheckBox
          checked={filterLocation}
          onChange={(_, checked) => handleSelectLocation(checked)}
        />
      }
      label={
        <div onClick={() => handleSelectLocation(!filterLocation, true)}>
          <Grid>
            <TkTypography fontFamily="Lato" fontWeight="bold" fontSize="14px">
              {location.alias}
            </TkTypography>
            <OverFlowTypography
              fontFamily="Lato"
              fontSize="14px"
              marginTop="5px"
            >
              <TkIcon
                className="fa fa-map-marker-alt"
                iconSize={14}
                marginRight={2}
              />
              {formatAddress({
                streetAddress: location.street_address,
                ...location,
              })}
            </OverFlowTypography>
            {location.customerName ? (
              <OverFlowTypography
                fontFamily="Lato"
                fontSize="14px"
                marginTop="5px"
              >
                <TkIcon
                  className="fa fa-user-tie"
                  iconSize={14}
                  marginRight={3}
                />
                {location.customerName}
              </OverFlowTypography>
            ) : null}
          </Grid>
        </div>
      }
    />
  );
};

export default TkLocationListItem;
