import { atom } from 'recoil';

const businessSetup = atom<ITkBusinessSetup>({
  key: 'businessSetup',
  default: {
    name: '',
    categories: [{ id: 0, name: '' }],
    address: {
      id: 0,
      address: '',
      city: { name: '' },
      complement: '',
      state: { abbr: '', name: '' },
      country: { abbr: '', name: '' },
      zip_code: '',
    },
    emails: [
      {
        email: '',
        primary: true,
      },
    ],
    phones: [
      {
        phone_number: '',
      },
    ],
    schedule: {
      sunday: [],
      monday: [{ weekday: 'monday', start: '09:00', end: '17:00' }],
      tuesday: [{ weekday: 'tuesday', start: '09:00', end: '17:00' }],
      wednesday: [{ weekday: 'wednesday', start: '09:00', end: '17:00' }],
      thursday: [{ weekday: 'thursday', start: '09:00', end: '17:00' }],
      friday: [{ weekday: 'friday', start: '09:00', end: '17:00' }],
      saturday: [],
    },
    services: [],
  },
});

export { businessSetup };
