import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import { offersDetailsExtendedDrawer, selectedOffer } from '@recoilData/index';
import { TkExtendedWrapper, TkTaskWrapperItem } from '../styles';
import { TkTypography, TkIcon } from '@components/index';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { TaskbirdIntegrationsEnum } from '@consts/index';

const TkOfferAdditionalInfoMoveout: React.FC = () => {
  const { t } = useTranslation();
  const offer = useRecoilValue(selectedOffer);
  const closeExtendedDrawer = useResetRecoilState(offersDetailsExtendedDrawer);

  useEffect(() => {
    if (offer.platform !== TaskbirdIntegrationsEnum.MOVEOUT) {
      closeExtendedDrawer();
      return;
    }
  }, [offer, closeExtendedDrawer]);

  return (
    <TkExtendedWrapper>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <TkTypography fontWeight="bold" fontFamily="Muli" fontSize={16}>
          {t('additionalInfo', { ns: 'offers' })}
        </TkTypography>
        <TkIcon
          onClick={() => closeExtendedDrawer()}
          style={{ cursor: 'pointer' }}
          className="fa fa-chevron-right"
          color="default"
          marginLeft={10}
          verticalAlign="baseline"
          fontSize="small"
        />
      </Grid>
      <TkTaskWrapperItem padding="20px 0 10px">
        <Grid container direction="row" style={{ margin: '0 0 5px 0' }}>
          <TkTypography fontSize={16} fontFamily={'Lato'} fontWeight={'bold'}>
            {offer.location?.number_of_bedrooms &&
              t('bedroom', {
                ns: 'offers',
                count: offer.location?.number_of_bedrooms,
              })}
          </TkTypography>
          <TkTypography fontSize={16} fontFamily={'Lato'} marginLeft={'5px'}>
            {offer.location?.number_of_bedrooms ?? 0}
          </TkTypography>
        </Grid>
      </TkTaskWrapperItem>
      <TkTaskWrapperItem padding="10px 0">
        <Grid container direction="row" style={{ margin: '0 0 5px 0' }}>
          <TkTypography fontSize={16} fontFamily={'Lato'} fontWeight={'bold'}>
            {offer.location?.number_of_bathrooms &&
              t('bathroom', {
                ns: 'offers',
                count: offer.location?.number_of_bathrooms,
              })}
          </TkTypography>
          <TkTypography fontSize={16} fontFamily={'Lato'} marginLeft={'5px'}>
            {offer.location?.number_of_bathrooms ?? 0}
          </TkTypography>
        </Grid>
      </TkTaskWrapperItem>
      <TkTaskWrapperItem padding="10px 0">
        <Grid container direction="row" style={{ margin: '0 0 5px 0' }}>
          <TkTypography fontSize={16} fontFamily={'Lato'} fontWeight={'bold'}>
            {t('unit_size', { ns: 'offers' })}
          </TkTypography>
          <TkTypography fontSize={16} fontFamily={'Lato'} marginLeft={'5px'}>
            {offer.location?.size ?? 0} {t('sq_ft', { ns: 'offers' })}
          </TkTypography>
        </Grid>
      </TkTaskWrapperItem>
    </TkExtendedWrapper>
  );
};

export default TkOfferAdditionalInfoMoveout;
