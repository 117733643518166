import React from 'react';

import { TooltipWrapper } from './styles';
import { TooltipProps } from '@material-ui/core/Tooltip';

const TkTooltip: React.FC<TooltipProps> = (
  { children, title, placement = 'top' },
  props
) => {
  return (
    <TooltipWrapper title={title} placement={placement} {...props}>
      {children}
    </TooltipWrapper>
  );
};

export default TkTooltip;
