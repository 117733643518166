import { useQuery } from 'react-query';
import { getUserAccountId } from '@recoilData/index';
import { useRecoilValue } from 'recoil';
import api from '@services/api';

export const useGetAccountCrews = (search: string, page: number) => {
  const accountId = useRecoilValue(getUserAccountId);
  return useQuery(['accountCrews', accountId, { search, page }], async () => {
    const { data } = await api.get(`account/${accountId}/crew?page=${page}`, {
      params: {
        search_term: search,
      },
    });
    return data;
  });
};
