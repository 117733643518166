import React from 'react';
import { EmptyNotification, NotificationItem } from '../TkNotifications';
import { useNotifications } from '@contexts/index';

interface NotificationListProps {
  handleMenuCloseNotification: () => void;
}

const NotificationList = ({
  handleMenuCloseNotification,
}: NotificationListProps): JSX.Element => {
  const { notificationsArray } = useNotifications();

  if (notificationsArray.length === 0) {
    return <EmptyNotification />;
  }

  return (
    <>
      {notificationsArray.map((notification) => (
        <NotificationItem
          key={notification.id}
          notification={notification}
          handleMenuCloseNotification={handleMenuCloseNotification}
        />
      ))}
    </>
  );
};

export default NotificationList;
