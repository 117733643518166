import React from "react";
import { TkListItem } from "./styles";
import TkCheckBox from "@components/TkCheckBox";
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from "recoil";
import {
  closeFilterExtendedDrawer,
  filterCrews,
  selectFilterCrew,
  setUnassignedStatus,
} from "@recoilData/index";

interface ITCrewListItem {
  crew: ICrew;
}

const TkCrewListItem: React.FC<ITCrewListItem> = ({ crew }) => {
  const closeExtendedDrawer = useResetRecoilState(closeFilterExtendedDrawer);
  const filterCrew = useRecoilValue(filterCrews(crew.id));
  const [isUnassigned, setUnassigned] = useRecoilState(setUnassignedStatus);
  const setFilterCrew = useSetRecoilState(selectFilterCrew);

  const handleSelectCrew = (checked: boolean, unique: boolean = false) => {
    if (isUnassigned && checked) {
      setUnassigned(false);
    }
    setFilterCrew({ id: crew.id, checked } as any);
    if (unique) closeExtendedDrawer();
  };

  return (
    <TkListItem
      key={crew.id}
      checked={filterCrew}
      control={
        <TkCheckBox
          checked={filterCrew}
          onChange={(_, checked) => handleSelectCrew(checked)}
        />
      }
      label={
        <div onClick={() => handleSelectCrew(!filterCrew, true)}>
          {crew.name}
        </div>
      }
    />
  );
};

export default TkCrewListItem;
