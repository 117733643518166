import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Grid, Box } from '@mui/material';

import { TkTaskWrapperItem } from '../styles';
import {
  TkTaskWrapperItem as TkTaskWrapperItemDrawer,
  TkTypography,
  TkIcon,
} from '@components/index';

interface ITkDrawerLocationDetails {
  location?: ILocationTask;
  streetOnly?: boolean;
  aliasOnly?: boolean;
  editable?: boolean;
  addible?: boolean;
  selected?: boolean;
  editButton?: JSX.Element;
  style?: React.CSSProperties | undefined;
  innerStyle?: React.CSSProperties | undefined;
  onClick?: () => void;
}

const TkDrawerLocationDetails: React.FC<ITkDrawerLocationDetails> = ({
  location,
  streetOnly = false,
  aliasOnly = false,
  selected = false,
  editButton = null,
  editable = false,
  style,
  innerStyle,
  onClick,
  addible = false,
}) => {
  const { t } = useTranslation();
  const [showLocationInfo, setShowLocationInfo] = useState<boolean>(false);
  const [showEditLocation, setShowEditLocation] = useState<boolean>(false);

  const handleLocationInfo = () => setShowLocationInfo((prev) => !prev);

  const showMoreInfoIcon = () => {
    if (aliasOnly || streetOnly) {
      return false;
    }

    return true;
  };

  return (
    <>
      <TkTaskWrapperItemDrawer
        icon="fa fa-map-marker-alt"
        iconStyle={{ iconSize: 16, cursor: 'pointer' }}
        onMouseEnter={() => setShowEditLocation(true)}
        onMouseLeave={() => setShowEditLocation(false)}
        style={style}
        innerStyle={innerStyle}
        childrenStyle={{ maxWidth: '93%', textAlign: 'left' }}
        type={selected ? 'solid' : undefined}
        Actions={
          <>
            {location && showMoreInfoIcon() && (
              <Box fontSize={16} onClick={handleLocationInfo}>
                <TkIcon
                  verticalAlign="middle"
                  style={{ cursor: 'pointer' }}
                  className={
                    showLocationInfo ? 'fa fa-angle-up' : 'fa fa-angle-down'
                  }
                  fontSize="inherit"
                />
              </Box>
            )}
            {!location && addible && (
              <TkTypography
                fontSize={16}
                fontWeight="bold"
                fontFamily="Lato"
                userselect="none"
                onClick={onClick}
                textAlign="right"
                style={{ cursor: 'pointer' }}
                color={selected ? 'inherit' : '#4952a9'}
              >
                + {t('location', { ns: 'common' })}
              </TkTypography>
            )}
          </>
        }
      >
        <TkTypography fontSize={16} display="inline" fontFamily="Lato">
          {t('location', { ns: 'common' })}:
        </TkTypography>
        <TkTypography
          fontSize={16}
          display="inline"
          fontFamily="Lato"
          fontWeight="bold"
          marginLeft={1}
        >
          {streetOnly
            ? location?.street_address || location?.streetAddress
            : location?.alias}
          {location && editable && showEditLocation && editButton}
        </TkTypography>
      </TkTaskWrapperItemDrawer>
      {location?.number && (
        <TkTaskWrapperItem padding="5px 1.5rem">
          <Grid container>
            <TkTypography
              fontFamily="Lato"
              fontSize="16px"
              color="default"
              display="inline"
            >
              {t('apartmentNumber', { ns: 'common' })}:
              <TkTypography
                marginLeft="3px"
                fontFamily="Lato"
                fontSize="16px"
                color="default"
                fontWeight="bold"
                display="inline"
              >
                {location.number}
              </TkTypography>
            </TkTypography>
          </Grid>
        </TkTaskWrapperItem>
      )}
      {showLocationInfo && (
        <TkTaskWrapperItem padding={style?.padding}>
          {!streetOnly && (
            <TkTypography
              fontWeight="normal"
              fontFamily="Lato"
              fontSize="16px"
              marginBottom="5px"
              color="secondary"
            >
              {location?.street_address || location?.streetAddress}
            </TkTypography>
          )}
          <Grid container justifyContent="space-between">
            {location?.number && (
              <TkTypography
                fontFamily="Lato"
                fontSize="16px"
                color="default"
                display="inline"
              >
                {t('apartmentNumber', { ns: 'common' })}:
                <TkTypography
                  marginLeft="3px"
                  fontFamily="Lato"
                  fontSize="16px"
                  color="default"
                  fontWeight="bold"
                  display="inline"
                >
                  {location.number}
                </TkTypography>
              </TkTypography>
            )}
            {(location?.access_code || location?.accessCode) && (
              <TkTypography
                fontFamily="Lato"
                fontSize="16px"
                color="default"
                display="inline"
                textAlign="left"
              >
                {t('access_code', { ns: 'common' })}:
                <TkTypography
                  marginLeft="3px"
                  fontFamily="Lato"
                  fontSize="16px"
                  color="default"
                  fontWeight="bold"
                  display="inline"
                >
                  {location?.access_code || location?.accessCode}
                </TkTypography>
              </TkTypography>
            )}
          </Grid>
          <TkTypography
            marginTop="5px"
            fontFamily="Lato"
            fontSize="16px"
            color="default"
            display="inline"
            fontWeight="bold"
          >
            {location?.notes && (
              <>
                {t('notes', { ns: 'common' })}:
                <TkTypography
                  marginLeft="3px"
                  fontFamily="Lato"
                  fontSize="16px"
                  fontWeight="normal"
                  color="default"
                  display="inline"
                >
                  {location?.notes}
                </TkTypography>
              </>
            )}
          </TkTypography>
        </TkTaskWrapperItem>
      )}
    </>
  );
};

export default TkDrawerLocationDetails;
