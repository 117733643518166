import { selector } from 'recoil';

import {
  isFilterSet,
  assigneesData,
  locationsData,
  crewsData,
  filterAssignees,
  filterCrews,
  filterLocations,
  statusData,
  filterExtendedDrawer,
  customersData,
  filterCustomers,
  filterStatus,
} from '@recoilData/index';

const setFilter = selector<boolean>({
  key: 'setFilter',
  get: ({ get }) => get(isFilterSet),
  set: ({ set }, newValue) => set(isFilterSet, newValue),
});

const resetFilter = selector<boolean>({
  key: 'resetFilter',
  get: ({ get }) => get(isFilterSet),
  set: ({ get, reset }) => {
    get(assigneesData).map((assignee) => reset(filterAssignees(assignee.id)));
    get(customersData).map((customer) => reset(filterCustomers(customer.id)));
    get(crewsData).map((crew) => reset(filterCrews(crew.id)));
    get(locationsData).map((location) => reset(filterLocations(location.id)));
    get(statusData).map((s) => reset(filterStatus(s.id)));
    reset(isFilterSet);
  },
});

const changeFilterExtendedDrawer = selector({
  key: 'changeFilterExtendedDrawer',
  get: ({ get }) => get(filterExtendedDrawer),
  set: ({ set }, newValue) =>
    set(filterExtendedDrawer, {
      type: newValue,
      open: true,
    }),
});

const closeFilterExtendedDrawer = selector({
  key: 'closeFilterExtendedDrawer',
  get: ({ get }) => get(filterExtendedDrawer),
  set: ({ reset }) => reset(filterExtendedDrawer),
});

export {
  setFilter,
  resetFilter,
  closeFilterExtendedDrawer,
  changeFilterExtendedDrawer,
};
