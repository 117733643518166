import React from 'react';
import { useTranslation } from 'react-i18next';
import { TkIcon, TkTypography } from '@components/index';
import { TkTaskWrapperItem } from '../styles';
import { Grid } from '@material-ui/core';

interface ITkDrawerAdditionalInfoMoveout {
  location: ILocationTask | undefined;
}

const TkDrawerAdditionalInfoMoveout: React.FC<
  ITkDrawerAdditionalInfoMoveout
> = ({ location }) => {
  const { t } = useTranslation();

  return (
    <>
      <TkTaskWrapperItem>
        <Grid container direction="row">
          <TkIcon
            className="fa fa-hashtag"
            iconSize={14}
            width={13}
            marginTop={7}
            marginRight={7}
          />
          <TkTypography fontSize={16} fontFamily={'Lato'}>
            {t('additionalInfo', { ns: 'offers' })}
          </TkTypography>
        </Grid>
      </TkTaskWrapperItem>
      <TkTaskWrapperItem marginTop={'5px'}>
        <Grid container justify="space-between">
          <Grid
            container
            direction="row"
            style={{ margin: '0 0 5px 0', maxWidth: '80%' }}
          >
            <TkTypography fontSize={16} fontFamily={'Lato'} fontWeight={'bold'}>
              {location?.number_of_bedrooms &&
                t('bedroom', {
                  ns: 'offers',
                  count: location?.number_of_bedrooms,
                })}
            </TkTypography>
            <TkTypography fontSize={16} fontFamily={'Lato'} marginLeft={'5px'}>
              {location?.number_of_bedrooms ?? 0}
            </TkTypography>
          </Grid>
          <Grid justify="flex-end">
            <TkTypography
              fontSize={16}
              fontFamily={'Lato'}
              marginLeft={'5px'}
              fontWeight={'bold'}
            >
              + {t('two_more', { ns: 'common' })}
            </TkTypography>
          </Grid>
        </Grid>
      </TkTaskWrapperItem>
    </>
  );
};

export default TkDrawerAdditionalInfoMoveout;
