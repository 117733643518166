import { calendarGridView, calendarWeekViewType } from '@recoilData/index';
import { selector } from 'recoil';
import { LocalStorage } from '@consts/index';

const calendarGridViewSelector = selector<string>({
  key: 'calendarGridViewSelector',
  get: ({ get }) => get(calendarGridView),
  set: ({ set }, newValue) => {
    localStorage.setItem(LocalStorage.CALENDAR_VIEW, newValue as string);
    set(calendarGridView, newValue);
  },
});

const calendarWeekViewSelector = selector<string>({
  key: 'calendarWeekViewSelector',
  get: ({ get }) => get(calendarWeekViewType),
  set: ({ set }, newValue) => {
    localStorage.setItem(LocalStorage.WEEK_VIEW_TYPE, newValue as string);
    set(calendarWeekViewType, newValue);
  },
});

export { calendarGridViewSelector, calendarWeekViewSelector };
