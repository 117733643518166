import { checklistsData } from "@recoilData/atoms";
import { selectorFamily, selector } from "recoil";

const searchChecklistsData = selectorFamily({
  key: "searchChecklistsData",
  get: (search: string) => ({ get }) => {
    const checklists = get(checklistsData);
    if (!search) return checklists;
    return checklists.filter((item) => {
      return item.title
        .toLocaleLowerCase()
        .includes(search.toLocaleLowerCase());
    });
  },
});

const getChecklistsDataCount = selector({
  key: "getChecklistsDataCount",
  get: ({ get }) => {
    const checklists = get(checklistsData);
    return checklists.length;
  },
});

export { searchChecklistsData, getChecklistsDataCount };
