export default {
  crews: {
    crew: "Equipe",
    crew_plural: "Equipes",
    delete_crew: "Apagar equipe",
  },
  teammates: {
    member: "Membro",
    member_plural: "Membros",
    member_removed_successfully: "Colega de equipe removido com sucesso",
    click_to_see_details_and_edit: "Clique em um colega de equipe para ver e editar seu perfil aqui",
    remove_teammate: "Remover colega de equipe",
    get_started: "Comece a adicionar membros à sua empresa enviando-lhes convites.",
    team_members: "Membros da equipe",
    pending_invites: "Convites pendentes",
    resend_invite: "Reenviar convite",
    cancel_invite: "Cancelar convite",
    invitation_canceled: "Convite cancelado com sucesso",
    invitation_resent: "Convite reenviado com sucesso",
    no_tasks: "Nenhuma tarefa",
  },
  sms_error_message_empty: "Por favor, digite um número de telefone",
  last_name_error_message_empty: "Por favor, digite um sobrenome",
  street_address_error_message_empty: "Por favor, digite um endereço",
  email_error_message_empty: "Por favor, digite um email",
  sms_error_message: "Por favor, digite um número de telefone válido",
  email_error_message: "Por favor, digite um e-mail em um formato válido",
  invitation_success: "Seu convite está a caminho!",
  invitation_success_plural: "Seus convites estão a caminho!",
  add_to_crew: "Adicionar a uma Equipe",
};
