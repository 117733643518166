import React from 'react';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { ComponentWithChildren } from 'types/common.type';

const TkGoogleReCaptchaContext = ({
  children,
}: ComponentWithChildren): JSX.Element => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_PROD_SITE_KEY ?? ''}
      language="en"
    >
      {children}
    </GoogleReCaptchaProvider>
  );
};

export default TkGoogleReCaptchaContext;
