import React from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { ISimpleObject } from 'types/common.type';

import { TkCalendarFilterItemCheckBoxItem } from './styles';
import { TkCheckBox, TkTypography } from '@components/index';
import {
  filterStatus,
  getFilteredAssigneesCount,
  resetFilteredAssignees,
} from '@recoilData/index';

interface TkStatusListItemProps {
  status: ISimpleObject;
}

const TkStatusListItem = ({ status }: TkStatusListItemProps): JSX.Element => {
  const { t } = useTranslation();
  const [statusState, setStatusState] = useRecoilState(filterStatus(status.id));
  const assigneesCount = useRecoilValue(getFilteredAssigneesCount);
  const resetAssignees = useResetRecoilState(resetFilteredAssignees);

  const handleSelectedStatus = (checked: boolean) => {
    if (status.id === 0 && assigneesCount > 0 && checked) {
      resetAssignees();
    }
    setStatusState(checked);
  };

  return (
    <TkCalendarFilterItemCheckBoxItem
      control={
        <TkCheckBox
          checked={statusState}
          onChange={(_, check) => handleSelectedStatus(check)}
          name="check-alert-assignee"
        />
      }
      label={
        <TkTypography
          fontFamily="Lato"
          fontWeight="normal"
          fontSize={14}
          userselect="none"
        >
          {t(status.display, { ns: 'common' })}
        </TkTypography>
      }
    />
  );
};

export default TkStatusListItem;
