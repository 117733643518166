export default {
  login: {
    title: 'Entrar con e-mail',
  },
  forgotPasswordModal: {
    passwordUpdated: "Contraseña actualizada con éxito. Inicia sesión con tus nuevas credenciales."
  },
  event: {
    errors: {
      repeating_task_edit_dates_error:
        'Las fechas de las tareas recurrentes no pueden ser editadas',
      imported_task_edit_dates_error:
        'Las fechas de las tareas importadas no pueden ser editadas',
    },
  },
  task: {
    tasks_today: 'Tareas Hoy',
    no_task_today: 'Ninguna Tarea Hoy',
    x_tasks_today: '1 Tarea Hoy',
    x_tasks_today_plural: '{{count}} Tareas Hoy',
    no_task_week: 'Ninguna Tarea Esta Semana',
    x_tasks_week: '1 Tarea Esta Semana',
    x_tasks_week_plural: '{{count}} Tareas Esta Semana',
  },
  navbar: {
    tasks: 'Tareas',
    team: 'Equipo',
    customers: 'Clientes',
    checklists: 'Listas de tareas',
    bookingWidget: 'Herramienta de reserva',
    integrations: 'Integraciones',
  },
  tkTags: {
    notCompleted: 'No completada',
    finishedLate: 'Completada tarde',
    startedLate: 'Comenzada tarde',
    notStarted: 'No comenzada',
    completedInTurnoverBnB: 'Completada en Turno',
    completed: 'Completada',
    repeating: 'Recurrente',
    bookingWidget: 'Herramienta de reserva',
  },
  others: {
    invalidCode:
      'El código que ingresaste se venció o ya fue usado. Intenta nuevamente',
  },
  error: {
    somethingWentWrong: 'Algo salió mal',
    tryAgain: 'Intenta nuevamente',
  }
};
