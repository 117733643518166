import React from 'react';

import { Divider } from '@mui/material';

import { TkTaskWrapperItem, TkTaskWrapperSubItem } from './styles';
import { useTaskInventory } from '../hooks/useTaskInventory';
import { TkIcon, TkTypography } from '@components/index';

const TkTaskInventory = ({
  inventory,
}: {
  inventory: TaskInventory;
}): JSX.Element => {
  const { t, wrapperColor, itemsCount, handleInventory } =
    useTaskInventory(inventory);

  return (
    <div
      style={{
        padding: '.25rem 1rem 0',
      }}
    >
      <TkTaskWrapperItem
        margin="0 -1rem"
        padding="0 1rem"
        bgcolor={wrapperColor}
        sx={{ cursor: 'pointer' }}
      >
        <TkTaskWrapperSubItem
          container
          padding="0.5rem 0"
          onClick={handleInventory}
          justifyContent="space-between"
        >
          <TkTypography>
            <TkIcon
              iconSize={14}
              marginRight={5}
              verticalAlign="sub"
              sx={{ width: '16px' }}
              className="fas fa-toilet-paper"
            />
            {t('inventory', { ns: 'events' })}:
          </TkTypography>
          <TkTypography fontWeight="bold" textAlign="right" color="primary9">
            {itemsCount === 0
              ? t('noItems', { ns: 'common' })
              : t('itemsCount', { ns: 'common', count: itemsCount })}
          </TkTypography>
        </TkTaskWrapperSubItem>
      </TkTaskWrapperItem>
      <Divider sx={{ marginTop: '.25rem' }} />
    </div>
  );
};

export default TkTaskInventory;
