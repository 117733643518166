import React from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import TkTypography from '@components/TkTypography';
import { TaskStatusEnum } from '@consts/index';
import { editEventNotes, getEventStatus } from '@recoilData/index';

interface ITkEditTaskNotes {
  readyOnly?: boolean;
  notes?: string;
  selected?: boolean;
}

const TkEditTaskNotes = ({
  readyOnly = false,
  notes = '',
  selected = false,
}: ITkEditTaskNotes): JSX.Element => {
  const { t } = useTranslation();
  const eventNotes = useRecoilValue(editEventNotes);
  const status = useRecoilValue(getEventStatus);

  if (!readyOnly) {
    return (
      <>
        {eventNotes.length > 0 && (
          <TkTypography
            fontSize={16}
            display="inline"
            fontFamily="Lato"
            fontWeight="bold"
            textAlign="right"
            style={{ cursor: 'pointer' }}
            marginLeft={1}
          >
            {eventNotes.slice(0, 15)}...
          </TkTypography>
        )}
        {status !== TaskStatusEnum.DONE && eventNotes.length === 0 && (
          <TkTypography
            fontSize={16}
            fontWeight="bold"
            fontFamily="Lato"
            userselect="none"
            textAlign="right"
            style={{ cursor: 'pointer' }}
            color={selected ? 'white' : 'primary'}
          >
            {`+ ${t('notes', { ns: 'common' })}`}
          </TkTypography>
        )}
      </>
    );
  }

  // Readonly version
  return (
    <>
      {readyOnly && notes.length > 0 && (
        <TkTypography
          fontSize={16}
          display="inline"
          fontFamily="Lato"
          fontWeight="bold"
          textAlign="right"
          marginLeft={1}
        >
          {notes.slice(0, 15)}...
        </TkTypography>
      )}
      {readyOnly && notes.length === 0 && (
        <TkTypography
          fontSize={16}
          fontFamily="Lato"
          userselect="none"
          textAlign="right"
          fontWeight="bold"
          color={selected ? 'inherit' : 'primary'}
        >
          {t('no_notes', { ns: 'common' })}
        </TkTypography>
      )}
    </>
  );
};

export default TkEditTaskNotes;
