export default {
  title: "Integrações",
  tbnbIntegrationCard: {
    title: "Turno",
    textContent: "Sincronize automaticamente seus projetos da Turno e gerencie-os no Taskbird.",
    integratedTextContext: "Agora você pode designar projetos para seus colegas de equipe e completá-los no Taskbird.",
    integratedTextContextWithMoveout: "Agora você pode designar projetos para seus colegas de equipe e completá-los no Taskbird. Eles serão marcados como concluídos na Turno",
    connectedOn: "<0>Conectado</0> em",
    sellingPointOne: "Seus projetos e clientes se manterão sincronizados.",
    sellingPointTwo: "Quando um projeto é atualizado na Turno, ele será atualizado no Taskbird.",
    sellingPointThree: "Quando um projeto é completado no Taskbird, nós o marcaremos como completo na Turno.",
    connectModal: {
      title: "Conecte-se com Turno",
      textContent: "Insira seu e-mail e senha da Turno",
      email_required: "Insira seu e-mail",
      email_invalid: "Insira um e-mail válido",
      password_required: "Insira sua senha",
      success: "Sua conta Turno foi conectada com sucesso",
      invalid_credentials: "E-mail ou senha incorretos.",
      email_already_integrated: "Este e-mail já está integrado ao Taskbird. Se você precisa de ajuda, entre em contato com o suporte ao cliente.",
      invalid_account_type: "Você só pode integrar uma conta de diarista da Turno.",
      account_already_integrated_with_client: "Esta conta já está integrada. Por favor entre em contato com o suporte ao cliente.",
    }
  },
  moveoutIntegrationCard: {
    title: "Moveout.com",
    textContent: "Junte-se ao novo Marketplace para fazer seu negócio crescer.",
    join: "Junte-se ao Moveout.com",
    joinedOn: "<0>Membro</0> desde {{- date}}",
    editPriceTooltip: "Editar valor por hora por pessoa",
    editPriceSuccess: "Taxa atualizado com sucesso",
    integratedContent: {
      enabledRequests: "Atualmente você está enviando ofertas de limpeza de mudança automática com base em suas configurações abaixo.",
      disabledRequests: "Sua conta Moveout está agora em pausa. Você não receberá nenhum pedido de Mudança.",
    },
    joinModal: {
      stepOne: {
        title: "Novo Marketplace para Crescer seu Negócio",
        textContent: "Moveout.com é um marketplace para prestadores de serviços de limpeza em mudança. Os pedidos e preços de limpeza da mudança são gerenciados através do Taskbird.",
        textContentOne: "<0>Conveniência:</0> É completamente gratuito inscrever-se no Moveout.com para os diaristas existentes no Marketplace da Turno.",
        textContentTwo: "<0>Acesso a mais trabalho:</0> Semelhante a Turno, aceite os projetos de limpeza de mudança que você quer e rejeite os projetos que você não quer.",
        textContentThree: "<0>Integração sem problemas:</0> Basta definir sua tarifa e começar a receber solicitações.",
        actionBack: "$t(common:cancel)",
        actionNext: "$t(common:next): $t(integrationsPage:moveoutIntegrationCard.joinModal.stepTwo.title)",
      },
      stepTwo: {
        title: "Como funciona?",
        textContentOne: "Usaremos sua <1>Foto do Perfil e classificação da Turno</1> para listar você como um prestador de serviços de limpeza em Moveout.com.",
        textContentTwo: "Você só precisa <1> estabelecer sua tarifa horária por pessoa.</1>",
        textContentThree: "Você aparecerá automaticamente nos resultados de pesquisa da Moveout.com em sua área e os clientes lhe enviarão projetos de limpeza. <1>Aceite apenas os projetos que você deseja fazer.</1>",
        actionBack: "$t(common:back)",
        actionNext: "$t(common:next): $t(integrationsPage:moveoutIntegrationCard.joinModal.stepThree.title)",
      },
      stepThree: {
        title: "Defina sua tarifa",
        ratePerPerson: "Minha tarifa por hora </1> por pessoa",
        rateTip: "Você pode ajustar este preço mais tarde quantas vezes você precisar",
        rateBelow: "Esta taxa está abaixo da média. Você pode obter um pouco mais!",
        rateFair: "Esta é uma taxa justa. Você tem uma boa chance de conseguir clientes!",
        rateAbove: "Esta taxa está acima da média. Pode ser difícil conseguir clientes",
        howMuchEarn: "Este é um exemplo de como seu preço funcionaria:",
        peopleWorking: "Pessoas trabalhando:",
        hoursSpent: "Horas gastas:",
        totalEarnings: "Total de ganhos",
        rightContent: {
          title: "Por que por hora?",
          textContentOne: "Os projetos de limpeza de mudança podem variar em duração, dependendo das condições do imóvel.",
          textContentTwo: "Cobrando uma taxa horária, você receberá uma taxa justa caso a limpeza da mudança dure mais do que o esperado.",
        },
        formError: {
          rate_min: "O preço é muito baixo",
          rate_required: "Por favor, digite um preço"
        },
        actionBack: "$t(common:back)",
        actionNext: "$t(integrationsPage:moveoutIntegrationCard.join)"
      },
      stepFinal: {
        title: "Você agora faz parte da Moveout.com",
        textContentOne: "Você receberá ofertas para pedidos de limpeza de mudança em seu calendário.",
        textContentTwo: "Aceite as Tarefas que se ajustam ao seu cronograma e receba as informações de contato de seu novo cliente.",
        textContentThree: "Uma vez concluída a tarefa, você receberá o pagamento em sua conta Stripe dentro de 5 dias úteis.",
        actionNext: "Entendi!"
      },
    }
  },
  requestPlatformPanel: {
    title: "Quer integrar com outra plataforma?",
    textContent: "Estamos sempre trabalhando para melhorar nosso produto. Conte-nos qual plataforma você gostaria de integrar:",
    platformPlaceholder: "Escreva o nome de uma plataforma",
    submitButtonContent: "Enviar solicitação",
    success: "Obrigado pelo seu pedido!",
    error: "Algo deu errado com sua sugestão, por favor tente novamente mais tarde.",
  }
};
