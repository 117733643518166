export enum TaskFrequencyEnum {
  BY_MONTH_DAY = 'bymonthday',
  BY_DAY_TYPE = 'bydaytype',
}

export enum EventBillingTypeEnum {
  HOURLY = 'HOURLY',
  PER_PROJECT = 'PER_PROJECT',
}

export enum TaskFrequencyIntervalEnum {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}
