import { atom } from "recoil";
import { memoize } from "@fullcalendar/react";

const multiInputValueById = memoize((id: string) =>
  atom({
    key: id,
    default: "",
  })
);

export { multiInputValueById };
