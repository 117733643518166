import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useResetRecoilState } from 'recoil';

import {
  ImgAssignee,
  DeleteIconAssignee,
  IconAssigneeButton,
  AssigneeSelectedButton,
} from '../styles';
import TkIcon from '@components/TkIcon';
import TkTypography from '@components/TkTypography';
import { generateInitials } from '@helpers/index';
import { Box, Grid } from '@material-ui/core';
import { eventAssignees } from '@recoilData/index';

interface ITkAssigneeSelectedListItem {
  assignee: IAssignee;
}

const TkAssigneeSelectedListItem: React.FC<ITkAssigneeSelectedListItem> = ({
  assignee,
}) => {
  const { t } = useTranslation();
  const removeEventAssignee = useResetRecoilState(eventAssignees(assignee.id));

  return (
    /** @ts-ignore */
    <AssigneeSelectedButton container justify="space-between">
      <Box
        mr={1}
        display="flex"
        flexDirection="row"
        width="90%"
        alignItems="center"
      >
        <Grid>
          {assignee.photo_url !== null ? (
            <ImgAssignee src={assignee.photo_url} />
          ) : (
            <IconAssigneeButton>
              {generateInitials(assignee.full_name)}
            </IconAssigneeButton>
          )}
        </Grid>
        <Grid item direction="column">
          <TkTypography
            fontWeight="bold"
            fontFamily="Lato"
            fontSize={14}
            color="default"
          >
            {assignee.full_name}
          </TkTypography>
          <TkTypography
            fontSize={12}
            fontFamily="Lato"
            fontWeight="normal"
            color="rgba(1,31,65,0.8)"
          >
            {assignee.taskCountForToday !== undefined &&
            assignee.taskCountForToday > 0 ? (
              <>
                <TkIcon
                  width={20}
                  iconSize={14}
                  marginRight={1}
                  className="fa fa-exclamation-triangle"
                  color="#011F41"
                  fontSize="inherit"
                  verticalAlign="text-top"
                />
                <Trans
                  i18nKey="task.x_tasks_today"
                  count={assignee.taskCountForToday}
                >
                  {{ count: assignee.taskCountForToday }} Tasks Today
                </Trans>
              </>
            ) : (
              t('task.no_task_today')
            )}
          </TkTypography>
        </Grid>
      </Box>
      <DeleteIconAssignee>
        <TkIcon
          onClick={() => removeEventAssignee()}
          className="fa fa-trash"
          cursor="pointer"
          color="#011F41"
        />
      </DeleteIconAssignee>
    </AssigneeSelectedButton>
  );
};

export default React.memo(TkAssigneeSelectedListItem);
