import React, { useState } from 'react';

import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { Grid } from '@mui/material';

import { Container, ModalActions, ModalContent } from './styles';
import { DynamicButton, TkTypography } from '@components/index';
import { ApiResultsKind } from '@consts/api';
import { useAuth, useModal, useSnackbar } from '@contexts/index';
import { getUserAccountId, showDemoCall } from '@recoilData/index';
import { saveNewCalendlyEvent } from '@services/apiMethods';

interface TkDemoCallModalProps {
  type: 'bookingService' | 'welcomeCall';
}

interface CalendarType {
  [key: string]: {
    url: string;
    eventService: string;
    eventType: string;
    title: string;
    description: string;
    footer: string;
  };
}

const TkDemoCallModal = ({ type }: TkDemoCallModalProps): JSX.Element => {
  const accountId = useRecoilValue(getUserAccountId);
  const { userInfo } = useAuth();
  const { t } = useTranslation('demoCall');
  const { resumeModalQueue, handleCloseModal } = useModal();
  const { fireTSuccess } = useSnackbar();
  const [demoScheduled, setDemoScheduled] = useState(false);
  const setShowDemoCall = useSetRecoilState(showDemoCall);

  const isWelcomeCall = type === 'welcomeCall';

  const dismissDemoCall = () => {
    if (isWelcomeCall) {
      setShowDemoCall(false);
      resumeModalQueue();
      return;
    }

    handleCloseModal();
  };

  const calendlyURLs: CalendarType = {
    bookingService: {
      url: process.env.REACT_APP_BOOKING_SERVICE_CALENDLY ?? '',
      eventService: 'CALENDLY',
      eventType: 'BOOKING_DEMO_CALL',
      title: 'widget.bookingService.title',
      description: 'widget.bookingService.description',
      footer: 'widget.bookingService.footer',
    },
    welcomeCall: {
      url: process.env.REACT_APP_DEFAULT_CALENDLY ?? '',
      eventService: 'CALENDLY',
      eventType: 'DEMO_CALL',
      title: 'widget.welcomeCall.title',
      description: 'widget.welcomeCall.description',
      footer: 'widget.welcomeCall.footer',
    },
  };

  const calendly = calendlyURLs[type];

  const pageSettings = {
    backgroundColor: 'ffffff',
    hideEventTypeDetails: true,
    hideLandingPageDetails: true,
    primaryColor: '00a2ff',
    textColor: '4d5055',
  };

  const getEventUuid = (uri: string) => {
    const uriArray = uri.split('/');
    return uriArray[uriArray.length - 1];
  };

  useCalendlyEventListener({
    onEventScheduled: async (e) => {
      const {
        event: { uri },
      } = e.data.payload;

      const uuid = getEventUuid(uri);

      const { kind } = await saveNewCalendlyEvent({
        accountId: accountId,
        uuid: uuid,
        uri: uri,
        eventService: calendly.eventService,
        eventType: calendly.eventType,
        userId: Number(userInfo.user.id ?? '0'),
      });

      if (kind === ApiResultsKind.OK) {
        fireTSuccess('widget.demoCallScheduled', { ns: 'demoCall' });
        setDemoScheduled(true);

        if (isWelcomeCall) {
          resumeModalQueue();
          return;
        }
      }

      setTimeout(() => {
        handleCloseModal();
      }, 5000);
    },
  });

  return (
    <Container>
      <ModalContent style={{ padding: '20px 20px 10px' }}>
        <TkTypography
          fontWeight="bold"
          fontSize="24px"
          fontFamily="Muli"
          color="#3D4465"
        >
          {t(calendly.title)}
        </TkTypography>
        <TkTypography fontSize="16px" color="#3D4465">
          <Trans i18nKey={calendly.description} ns="demoCall">
            <strong />
          </Trans>
        </TkTypography>

        <Grid container justifyContent="center" marginTop="10px">
          <InlineWidget
            styles={{
              height: '600px',
              width: '600px',
              overflow: 'hidden',
            }}
            url={calendly.url}
            pageSettings={pageSettings}
          />
        </Grid>
      </ModalContent>
      <ModalActions>
        <Grid container padding="5px">
          <DynamicButton color="primary" onClick={dismissDemoCall}>
            {demoScheduled ? t('close', { ns: 'common' }) : t(calendly.footer)}
          </DynamicButton>
        </Grid>
      </ModalActions>
    </Container>
  );
};

export default TkDemoCallModal;
