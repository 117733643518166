import React from 'react';

import { useTranslation } from 'react-i18next';

import { Box, Grid } from '@mui/material';

import { ProjectOfferRequestAction } from '../projectOffersList';
import { MoveOut_PNG, TurnoverBNB_PNG } from '@assets/index';
import { SameDayArrivalIcon } from '@components/TkPillContent/TkSameDayArrivalIcon';
import {
  ContainerOfferListItem,
  ImgClientNormal,
  ImgClientRound,
} from '@components/TkProjectOffersDrawer/styles';
import { DynamicButton, TkIcon, TkTypography } from '@components/index';
import { getOfferPricingLabel } from '@features/projectOffers/utils';
import {
  firstLastName,
  formatAddress,
  getEpochToDate,
  formatToShortTime,
  getDatetimeFromDate,
  formatValueToCurrency,
  formatDistanceFromPlaceWithCity,
} from '@helpers/index';
import { useOfferDrawer } from '@pages/Home/hooks';

interface ProjectOfferItemProps {
  projectOffer: IProject;
  handleProjectAction: (
    projectOfferID: number,
    action: ProjectOfferRequestAction,
    openTask?: boolean
  ) => void;
  isMutating: boolean;
}

export const ProjectOfferItem = ({
  projectOffer,
  handleProjectAction,
}: ProjectOfferItemProps): JSX.Element => {
  const { t } = useTranslation();
  const { openProjectOfferFromList } = useOfferDrawer();
  const isGuestArrivesSameDay =
    projectOffer.project_offer_system_notes?.guest_arrives_same_day === 1;

  const getProjectOfferClient = (clientName: string) => {
    return {
      Turno: clientName.includes('Turno'),
      Moveout: clientName.includes('Moveout'),
      Booking: clientName.includes('Booking'),
    };
  };

  const getProjectOfferClientLogo = (clientName: string) => {
    const client = getProjectOfferClient(clientName);

    if (client.Booking) {
      return (
        <TkIcon
          iconSize={36}
          marginTop={7}
          marginRight={10}
          className="fa fa-calendar-check"
          color="#4952A9"
        />
      );
    }

    if (client.Moveout) {
      return <ImgClientNormal src={MoveOut_PNG} />;
    }

    if (client.Turno) {
      return <ImgClientRound src={TurnoverBNB_PNG} />;
    }
  };

  const projectOfferName = (name: string) => {
    const start = getEpochToDate(projectOffer.start_time_epoch);
    const finish = getEpochToDate(projectOffer.end_time_epoch);
    const client = getProjectOfferClient(projectOffer.client);

    return (
      <Box display="flex" alignItems="center">
        {isGuestArrivesSameDay && <SameDayArrivalIcon iconSize={12} />}
        <TkTypography
          fontWeight="bold"
          fontSize={14}
          color="default"
          marginRight="0.5rem"
        >
          {`${
            start
              ? formatToShortTime(
                  getDatetimeFromDate(start, projectOffer.location.timezone)
                )
              : ''
          } - ${
            finish
              ? formatToShortTime(
                  getDatetimeFromDate(finish, projectOffer.location.timezone)
                )
              : ''
          }`}
        </TkTypography>
        <TkTypography
          width="150px"
          fontWeight="normal"
          fontFamily="Lato"
          fontSize={14}
          color="default"
          noWrap
        >
          {client.Moveout ? t('moveout_cleaning', { ns: 'offers' }) : name}
        </TkTypography>{' '}
      </Box>
    );
  };

  const handleLocationAlias = (): string => {
    return projectOffer?.location?.alias + ' •';
  };

  const handleLocation = () => {
    const projectOfferClient = getProjectOfferClient(projectOffer.client);
    if (projectOfferClient.Moveout) {
      return formatDistanceFromPlaceWithCity(
        projectOffer.travel_distance,
        projectOffer.travel_distance_unit,
        projectOffer.location
      );
    }
    return formatAddress({
      streetAddress: projectOffer?.location.street_address,
      ...projectOffer?.location,
    });
  };

  return (
    <ContainerOfferListItem container justify="space-between">
      <Box
        mr={1}
        display="flex"
        flexDirection="row"
        width="95%"
        style={{
          cursor: 'pointer',
        }}
        onClick={() => openProjectOfferFromList(projectOffer)}
      >
        <Grid item>
          {projectOffer.customer.name !== null &&
            getProjectOfferClientLogo(projectOffer.client)}
        </Grid>
        <Grid item direction="column">
          {projectOfferName(projectOffer.name)}
          <TkTypography
            fontWeight="bold"
            fontFamily="Lato"
            fontSize={14}
            color="default"
            lineHeight="24px"
          >
            {firstLastName(
              projectOffer.customer.name,
              projectOffer.client.includes('Moveout')
            ).join(' ') ?? t('no_customer', { ns: 'common' })}
          </TkTypography>
          <Grid container direction="row" alignItems="center">
            <TkIcon
              className="fa fa-map-marker-alt"
              iconSize={14}
              marginRight={2}
            />
            <TkTypography
              fontSize={14}
              marginRight={1}
              fontWeight="bold"
              lineHeight="24px"
              color="rgba(1,31,65,0.8)"
            >
              {handleLocationAlias()}
            </TkTypography>
            <TkTypography
              fontSize={14}
              color="rgba(1,31,65,0.8)"
              lineHeight="24px"
            >
              {handleLocation()}
            </TkTypography>
          </Grid>
          <Grid container direction="row" alignItems="center">
            <TkIcon
              className="fa fa-credit-card"
              iconSize={14}
              marginRight={5}
            />
            <TkTypography
              fontSize={14}
              fontFamily="Lato"
              fontWeight="normal"
              color="rgba(1,31,65,0.8)"
              lineHeight="24px"
            >
              {t(
                getOfferPricingLabel(
                  projectOffer.project_offer_payment_type,
                  projectOffer.client
                ),
                {
                  ns: 'offers',
                  price: formatValueToCurrency(Number(projectOffer.price)),
                }
              )}
            </TkTypography>
          </Grid>
        </Grid>
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center">
        <DynamicButton
          onClick={() =>
            handleProjectAction(
              projectOffer.id,
              ProjectOfferRequestAction.Reject
            )
          }
          style={{
            margin: '0 4rem 0 2rem',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TkTypography
            fontWeight="bold"
            fontFamily="Lato"
            fontSize={16}
            color="babyBlue"
          >
            {t('reject', { ns: 'common' })}
          </TkTypography>
        </DynamicButton>
        <DynamicButton
          onClick={() =>
            handleProjectAction(
              projectOffer.id,
              ProjectOfferRequestAction.Accept,
              false
            )
          }
          style={{
            margin: '0 2rem 0 4rem',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <TkTypography
            fontWeight="bold"
            fontFamily="Lato"
            fontSize={16}
            color="#4952A9"
          >
            {t('accept', { ns: 'common' })}
          </TkTypography>
        </DynamicButton>
      </Box>
      <hr
        style={{
          height: '2px',
          width: '90%',
          margin: '0.5rem auto',
          borderWidth: '0',
          backgroundColor: '#D9DAD8',
        }}
      />
    </ContainerOfferListItem>
  );
};
