import { useEffect, useRef } from 'react';

import { useMixpanel } from './MixpanelProvider';

function useMixpanelDetectPage(screen_name: string): void {
  const { track, trackDebounced } = useMixpanel();
  const nameRef = useRef('');
  useEffect(() => {
    if (
      nameRef.current !== screen_name &&
      screen_name !== '' &&
      screen_name !== undefined
    ) {
      nameRef.current = screen_name;
      trackDebounced('User Page View', {
        screen_name: screen_name,
        web_or_app: 'web',
      });
    }
  }, []);
  useEffect(() => {
    if (
      nameRef.current !== screen_name &&
      screen_name !== '' &&
      screen_name !== undefined
    ) {
      nameRef.current = screen_name;
      track('User Page View', {
        screen_name: screen_name,
        web_or_app: 'web',
      });
    }
  }, [screen_name, nameRef, track]);
}
export { useMixpanelDetectPage };
