import unavailableFeature from './unavailableFeature';
import phoneVerification from './phoneVerification';
import integrationsPage from './integrationsPage';
import blockResponsive from './blockResponsive';
import notifications from './notifications';
import webOnboarding from './webOnboarding';
import modalAlert from './modalAlert';
import teamsPage from './teamsPage';
import demoCall from './demoCall';
import common from './common';
import dates from './dates';
import chat from './chat';
import app from './app';

export default {
  unavailableFeature,
  phoneVerification,
  integrationsPage,
  blockResponsive,
  webOnboarding,
  notifications,
  modalAlert,
  teamsPage,
  demoCall,
  common,
  dates,
  chat,
  app,
};
