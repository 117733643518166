import { selector } from "recoil";
import { draftBookingBusiness, selectedCustomization } from "@recoilData/index";

const customizationObject = selector<IBookingCustomization | undefined>({
  key: "customizationObject",
  get: ({ get }) => get(draftBookingBusiness).customization,
  set: ({ set, get }, newValue) =>
    set(draftBookingBusiness, {
      ...get(draftBookingBusiness),
      customization: newValue as IBookingCustomization | undefined
    })
});

const customizationImageFile = selector<IImageBooking[]>({
  key: "customizationImageFile",
  get: ({ get }) => get(selectedCustomization).logo,
  set: ({ get, set }, newValue) => {
    const newImages = newValue as any;
    const logo: IImageBooking[] = [];

    logo.push(newImages);

    set(selectedCustomization, { ...get(selectedCustomization), logo });
  }
});

const newCustomizationImageFile = selector<File[]>({
  key: "newCustomizationImageFile",
  get: ({ get }) => get(selectedCustomization).newLogo,
  set: ({ get, set }, newValue) => {
    const newImages = newValue as any;
    const newLogo: File[] = [];

    newLogo.push(newImages[0]);

    set(selectedCustomization, { ...get(selectedCustomization), newLogo });
  }
});

const addCustomizationLogoFile = selector<File[]>({
  key: "addCustomizationLogoFile",
  get: ({ get }) => get(newCustomizationImageFile),
  set: ({ get, set }, newValue) => {
    const newImages = newValue as any;
    const newLogo: File[] = [];

    newLogo.push(newImages[0]);

    set(selectedCustomization, { ...get(selectedCustomization), newLogo });
  }
});

const removeLogoImagesFile = selector({
  key: "removeLogoImagesFile",
  get: ({ get }) => get(newCustomizationImageFile),
  set: ({ get, set }, newValue) => {
    const newLogo = get(newCustomizationImageFile).filter(i => {
      if (i !== (newValue as File)) return i;
    });
    set(selectedCustomization, { ...get(selectedCustomization), newLogo });
  }
});

const removeLogoImages = selector({
  key: "removeLogoImages",
  get: ({ get }) => get(customizationImageFile),
  set: ({ get, set }, newValue) => {
    const logo = get(customizationImageFile).filter(i => {
      if (i.id !== newValue) return i;
    });
    set(selectedCustomization, { ...get(selectedCustomization), logo });
  }
});

const customizationPrimaryColor = selector<string>({
  key: "customizationPrimaryColor",
  get: ({ get }) => get(selectedCustomization).primaryColor,
  set: ({ set, get }, newValue) =>
    set(selectedCustomization, {
      ...get(selectedCustomization),
      primaryColor: newValue as string
    })
});

const customizationHeaderColor = selector<string>({
  key: "customizationHeaderColor",
  get: ({ get }) => get(selectedCustomization).headerColor,
  set: ({ set, get }, newValue) =>
    set(selectedCustomization, {
      ...get(selectedCustomization),
      headerColor: newValue as string
    })
});

const customizationBackgroundColor = selector<string>({
  key: "customizationBackgroundColor",
  get: ({ get }) => get(selectedCustomization).backgroundColor,
  set: ({ set, get }, newValue) =>
    set(selectedCustomization, {
      ...get(selectedCustomization),
      backgroundColor: newValue as string
    })
});

const customizationImage = selector<IImageBooking[]>({
  key: "customizationImage",
  get: ({ get }) => get(selectedCustomization).logo,
  set: ({ set, get }, newValue) =>
    set(selectedCustomization, {
      ...get(selectedCustomization),
      logo: newValue as any
    })
});

export {
  customizationObject,
  customizationImageFile,
  addCustomizationLogoFile,
  removeLogoImagesFile,
  customizationPrimaryColor,
  customizationBackgroundColor,
  customizationHeaderColor,
  newCustomizationImageFile,
  removeLogoImages
};
