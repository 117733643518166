import { selector } from "recoil";
import {
  checklistsData,
  editEventChecklists,
  isAllEditEventChecklistsSelected,
} from "@recoilData/index";

const getSelectedEditEventChecklists = selector({
  key: "getSelectedEditEventChecklists",
  get: ({ get }) => {
    const checklists = get(checklistsData);
    const newChecklists: IChecklistTask[] = [];

    checklists.forEach((checklist) => {
      const { checked, isMandatory } = get(editEventChecklists(checklist.id));
      if (checked)
        newChecklists.push(generateChecklist(checklist, checked, isMandatory));
    });

    return newChecklists;
  },
});

const getEditEventChecklistsCount = selector({
  key: "getEditEventChecklistsCount",
  get: ({ get }) => {
    const checklists = get(getSelectedEditEventChecklists);
    let count = 0;
    checklists.forEach(() => count++);
    return count;
  },
});

const resetEditEventChecklists = selector({
  key: "resetEditEventChecklists",
  get: ({ get }) => get(getSelectedEditEventChecklists),
  set: ({ get, reset }) => {
    const checklists = get(checklistsData);
    checklists.map((checklist) => {
      reset(editEventChecklists(checklist.id));
    });
  },
});

const selectAllEditEventChecklists = selector({
  key: "selectAllEditEventChecklists",
  get: ({ get }) => get(isAllEditEventChecklistsSelected),
  set: ({ set, get }, newValue) => {
    const checklists = get(checklistsData);

    set(isAllEditEventChecklistsSelected, newValue as boolean);

    checklists.map((checklist) => {
      set(editEventChecklists(checklist.id), {
        ...get(editEventChecklists(checklist.id)),
        checked: newValue as boolean,
      });
    });
  },
});

export const generateChecklist = (
  checklist: IChecklistTask,
  checked: boolean,
  isMandatory: boolean
) => {
  const newChecklist: IChecklistTask = {
    checked,
    isMandatory,
    id: checklist.id,
    tags: checklist.tags,
    title: checklist.title,
    author: checklist.author,
    is_public: checklist.is_public,
    used_count: checklist.used_count,
    views_count: checklist.views_count,
    externalId: checklist.externalId,
    is_official: checklist.is_official,
    likes_count: checklist.likes_count,
    external_id: checklist.externalId
      ? Number(checklist.externalId)
      : undefined,
    description: checklist.description,
    numberOfItems: checklist.numberOfItems,
    commentsCount: checklist.commentsCount,
    completedItemsCount: checklist.completedItemsCount,
    projectChecklistId: checklist.projectChecklistId,
    items: checklist.items,
  };

  return newChecklist;
};

export {
  getSelectedEditEventChecklists,
  selectAllEditEventChecklists,
  getEditEventChecklistsCount,
  resetEditEventChecklists,
};
