import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { EventTypeEnum } from '@consts/index';
import { useDrawer } from '@hooks/index';
import {
  event as eventRecoil,
  getEditEventChecklistsCount,
  getSelectedEditEventChecklists,
} from '@recoilData/index';

export const useTaskChecklists = () => {
  const { t } = useTranslation();
  const {
    callDrawer,
    currentDrawer: { extended },
    types: { ExtendedDrawersTypes },
  } = useDrawer();

  const isChecklistDrawerOpen =
    extended && extended.id === ExtendedDrawersTypes.TASK_CHECKLIST;

  // Recoil Values
  const event = useRecoilValue(eventRecoil);
  const checklists = useRecoilValue(getSelectedEditEventChecklists);
  const checklistsCount = useRecoilValue(getEditEventChecklistsCount);
  const changeExtendedDrawer = () =>
    callDrawer({
      extended: {
        id: ExtendedDrawersTypes.TASK_CHECKLIST,
        props: {
          eventType: EventTypeEnum.EDIT,
        },
      },
    });

  return {
    t,
    event,
    checklists,
    checklistsCount,
    changeExtendedDrawer,
    isChecklistDrawerOpen,
  };
};
