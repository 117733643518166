import { atom } from 'recoil';

const bookingDraft = atom<IBookingSetupTabChanged>({
  key: 'bookingDraft',
  default: {
    services: false,
    'business-details': false,
    scheduling: false,
    customization: false,
    installation: false,
  },
});

export { bookingDraft };
