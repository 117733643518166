import { atom } from 'recoil';

enum BookingOrigin {
  'listOfServices' = 'listOfServices',
  'insideService' = 'insideService',
}

interface IbookingOriginPreviewData {
  origin: BookingOrigin | undefined;
}

const bookingOriginPreviewData = atom<IbookingOriginPreviewData>({
  key: 'bookingOriginPreviewData',
  default: { origin: undefined },
});

export { bookingOriginPreviewData };
