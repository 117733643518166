import React, { useState } from 'react';

import AllDoneJoinTeam from './AllDoneJoinTeam';
import AllDoneNewTeamContent from './AllDoneNewTeamContent';
import JoinTeamContent from './JoinTeamContent';
import NewTeamContent from './NewTeamContent';
import WelcomeBaseContent from './WelcomeBaseContent';
import { IOptionStruct } from './types.type';
import { NewTeamIcon, JoinTeamIcon } from '@assets/index';
import { ModalContent } from '@components/TkModalAlert';
import {
  IOptionsSelect,
  SetupStatesEnum,
} from '@components/TkModalComponents/types.type';
import { SessionData } from '@contexts/AuthContext/types/session';

interface TkWelcomeSetupModalProps {
  userData: SessionData;
  adminOnly?: boolean;
}

const TkWelcomeSetupModal = ({
  userData,
  adminOnly = false,
}: TkWelcomeSetupModalProps): JSX.Element => {
  const name = userData.user.first_name;
  const [teamName, setTeamName] = useState('');
  const [selectedOption, setSelectedOption] = useState<IOptionsSelect>(
    adminOnly
      ? SetupStatesEnum.ALL_DONE_JOIN_TEAM
      : SetupStatesEnum.NONE_SELECTED
  );

  const handleSelectOption = (option: IOptionsSelect) => {
    if (option !== selectedOption) setSelectedOption(option);
  };

  const options: Record<string, IOptionStruct> = {
    [SetupStatesEnum.NEW_TEAM]: {
      id: 'newTeam',
      icon: NewTeamIcon,
      textToken: 'welcomeSetupModal.setupNewTeamText',
      subTextToken: 'welcomeSetupModal.setupNewTeamSubText',
      handleClick: handleSelectOption,
    },
    [SetupStatesEnum.JOIN_TEAM]: {
      id: 'joinTeam',
      icon: JoinTeamIcon,
      textToken: 'welcomeSetupModal.joinTeamText',
      subTextToken: 'welcomeSetupModal.joinTeamSubText',
      handleClick: handleSelectOption,
    },
  };

  const contentOptions: Record<IOptionsSelect, JSX.Element> = {
    [SetupStatesEnum.NEW_TEAM]: (
      <NewTeamContent
        options={options}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        setTeamName={setTeamName}
        teamName={teamName}
        userData={userData}
        name={name}
      />
    ),
    [SetupStatesEnum.JOIN_TEAM]: (
      <JoinTeamContent
        options={options}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        setTeamName={setTeamName}
        userData={userData}
        name={name}
      />
    ),
    [SetupStatesEnum.ALL_DONE_NEW_TEAM]: <AllDoneNewTeamContent />,
    [SetupStatesEnum.ALL_DONE_JOIN_TEAM]: (
      <AllDoneJoinTeam adminOnly={adminOnly} teamName={teamName} name={name} />
    ),
    [SetupStatesEnum.NONE_SELECTED]: (
      <WelcomeBaseContent
        options={options}
        selectedOption={selectedOption}
        name={name}
      />
    ),
  };

  return (
    <ModalContent
      style={{
        padding:
          selectedOption === SetupStatesEnum.ALL_DONE_JOIN_TEAM
            ? '0'
            : '20px 24px',
      }}
    >
      {contentOptions[selectedOption]}
    </ModalContent>
  );
};

export default TkWelcomeSetupModal;
