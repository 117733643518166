import React from 'react';

import { TkExtendedDrawerWrapper } from './styles';

const TkExtendedDrawer: React.FC<ITkDrawer> = ({
  children,
  open,
  anchor = 'right',
  variant = 'persistent',
}) => {
  return (
    <TkExtendedDrawerWrapper open={open} anchor={anchor} variant={variant}>
      {children}
    </TkExtendedDrawerWrapper>
  );
};

export default TkExtendedDrawer;
