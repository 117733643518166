import { selector } from 'recoil';

import {
  editEvent,
  editEventExtendedDrawer,
  editEventSelectedChecklist,
  isEventDeleted,
  isEventEdit,
  assigneesData,
  checklistsData,
  crewsData,
  editEventAssignees,
  editEventChecklists,
  editEventCrews,
  isAllEditEventAssigneesSelected,
  isAllEditEventChecklistsSelected,
  isAllEditEventCrewsSelected,
} from '@recoilData/index';

const verifyExtendedDrawer = (
  newValue: string,
  open: boolean,
  type: string
) => {
  return open && type === newValue ? false : true;
};

const changeEditEventExtendedDrawer = selector<any>({
  key: 'changeEditEventExtendedDrawer',
  get: ({ get }) => get(editEventExtendedDrawer),
  set: ({ set, get }, newValue) => {
    const { open, type } = get(editEventExtendedDrawer);

    set(editEventExtendedDrawer, {
      type: newValue as any,
      open: verifyExtendedDrawer(newValue as any, open, type),
    });
  },
});

const closeEditEventExtendedDrawer = selector({
  key: 'closeEditEventExtendedDrawer',
  get: ({ get }) => get(editEventExtendedDrawer),
  set: ({ reset }) => reset(editEventExtendedDrawer),
});

const editEventTitle = selector<string>({
  key: 'editEventTitle',
  get: ({ get }) => get(editEvent).title,
  set: ({ set, get }, newValue) =>
    set(editEvent, { ...get(editEvent), title: newValue as string }),
});

const editEventTaskType = selector<string>({
  key: 'editEventTaskType',
  get: ({ get }) => get(editEvent).taskType,
  set: ({ set, get }, newValue) =>
    set(editEvent, { ...get(editEvent), taskType: newValue as string }),
});

const editEventTimezone = selector<string | null>({
  key: 'editEventTimezone',
  get: ({ get }) => get(editEvent).timezone,
  set: ({ set, get }, newValue) =>
    set(editEvent, { ...get(editEvent), timezone: newValue as string | null }),
});

const editEventStart = selector<Date>({
  key: 'editEventStart',
  get: ({ get }) => get(editEvent).start,
  set: ({ set, get }, newValue) =>
    set(editEvent, { ...get(editEvent), start: newValue as Date }),
});

const editEventEnd = selector<Date>({
  key: 'editEventEnd',
  get: ({ get }) => get(editEvent).end,
  set: ({ set, get }, newValue) =>
    set(editEvent, { ...get(editEvent), end: newValue as Date }),
});

const editEventNotes = selector<string>({
  key: 'editEventNotes',
  get: ({ get }) => get(editEvent).notes,
  set: ({ set, get }, newValue) =>
    set(editEvent, { ...get(editEvent), notes: newValue as string }),
});

const editEventCustomer = selector<ICustomerTask | undefined>({
  key: 'editEventCustomer',
  get: ({ get }) => get(editEvent).customer,
  set: ({ set, get }, newValue) => {
    set(editEvent, {
      ...get(editEvent),
      customer: newValue as ICustomerTask | undefined,
    });
  },
});

const editEventLocation = selector<ILocationTask | undefined>({
  key: 'editEventLocation',
  get: ({ get }) => get(editEvent).location,
  set: ({ set, get }, newValue) => {
    set(editEvent, {
      ...get(editEvent),
      location: newValue as ILocationTask | undefined,
    });
  },
});

const editEventImages = selector<IImage[]>({
  key: 'editEventImages',
  get: ({ get }) => get(editEvent).images,
});

const editEventImagesFile = selector<File[]>({
  key: 'editEventImagesFile',
  get: ({ get }) => get(editEvent).imagesFile,
});

const addEditEventImagesFile = selector<File[]>({
  key: 'addEditEventImagesFile',
  get: ({ get }) => get(editEventImagesFile),
  set: ({ get, set }, newValue) => {
    const newImages = newValue as any;
    const imagesFile: File[] = [];
    const oldImagesFile = get(editEventImagesFile);

    oldImagesFile.forEach((image) => imagesFile.push(image));

    if (newImages.length === 1) {
      imagesFile.push(newImages[0]);
    } else {
      newImages.forEach((image) => imagesFile.push(image));
    }

    set(editEvent, { ...get(editEvent), imagesFile });
  },
});

const removeEditEventImagesFile = selector<File>({
  key: 'removeEditEventImagesFile',
  get: ({ get }) => get(editEventImagesFile) as any,
  set: ({ get, set }, newValue) => {
    const imagesFile = get(editEventImagesFile).filter((i) => {
      if (i !== (newValue as File)) return i;
    });
    set(editEvent, { ...get(editEvent), imagesFile });
  },
});

const removeEditEventImages = selector<number>({
  key: 'removeEditEventImages',
  get: ({ get }) => get(editEventImages) as any,
  set: ({ get, set }, newValue) => {
    const images = get(editEventImages).filter((i) => {
      if (i.id !== newValue) return i;
    });
    set(editEvent, { ...get(editEvent), images });
  },
});

const setEventEdit = selector<IEvent>({
  key: 'setEventEdit',
  get: ({ get }) => get(editEvent),
  set: ({ set }, newValue) => {
    const event = newValue as IEvent;
    set(editEvent, event);
    event.checklists.forEach((checklist) =>
      set(editEventChecklists(checklist.id), {
        checked: true,
        isMandatory: checklist.isMandatory === 1 ? true : false,
      })
    );

    event.assignedUsers?.forEach((assignee) => {
      set(editEventAssignees(assignee.id), true);
    });
  },
});

const resetEditEvent = selector<IEvent>({
  key: 'resetEditEvent',
  get: ({ get }) => get(editEvent),
  set: ({ reset, get }) => {
    get(assigneesData).forEach((assignee) =>
      reset(editEventAssignees(assignee.id))
    );
    get(checklistsData).forEach((checklist) =>
      reset(editEventChecklists(checklist.id))
    );
    get(crewsData).forEach((crew) => reset(editEventCrews(crew.id)));

    reset(editEvent);
    reset(isEventEdit);
    reset(isEventDeleted);
    reset(editEventExtendedDrawer);
    reset(editEventSelectedChecklist);
    reset(isAllEditEventAssigneesSelected);
    reset(isAllEditEventChecklistsSelected);
    reset(isAllEditEventCrewsSelected);
  },
});

export {
  removeEditEventImagesFile,
  changeEditEventExtendedDrawer,
  closeEditEventExtendedDrawer,
  addEditEventImagesFile,
  removeEditEventImages,
  editEventImagesFile,
  editEventCustomer,
  editEventLocation,
  editEventTaskType,
  editEventTimezone,
  editEventImages,
  editEventStart,
  editEventTitle,
  editEventNotes,
  resetEditEvent,
  setEventEdit,
  editEventEnd,
};
