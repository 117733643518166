import { NotificationHandler } from '@hooks/notification/types';
import UpcomingTasksHandler from './UpcomingTasksHandler';
import TaskUnassignedHandler from './TaskUnassignedHandler';
import TaskNotStartedHandler from './TaskNotStartedHandler';
import ChatNewMessageHandler from './ChatNewMessageHandler';
import AskedToBeRemovedHandler from './AskedToBeRemovedHandler';
import NewBookingRequestHandler from './NewBookingRequestHandler';
import NewMoveoutRequestHandler from './NewMoveoutRequestHandler';
import DemoCallScheduledHandler from './DemoCallScheduledHandler';
import NewIntercomMessageHandler from './NewIntercomMessageHandler';
import NewTaskNotificationHandler from './NewTaskNotificationHandler';
import DefaultNotificationHandler from './DefaultNotificationHandler';
import NewToCrewNotificationHandler from './NewToCrewNotificationHandler';
import NewTeammateNotificationHandler from './NewTeammateNotificationHandler';
import TaskRemovalNotificationHandler from './TaskRemovalNotificationHandler';
import UserSupportNotificationHandler from './UserSupportNotificationHandler';
import TaskCreatedByIntegrationHandler from './TaskCreatedByIntegrationHandler';
import TaskNotCompletedForAdminHandler from './TaskNotCompletedForAdminHandler';
import TaskDeletedByIntegrationHandler from './TaskDeletedByIntegrationHandler';
import NewRecurringTaskNotificationHandler from './NewRecurringTaskNotificationHandler';
import EditTeammateAvailabilityTeaserHandler from './EditTeammateAvailabilityTeaserHandler';
import MoveoutCleaningCanceledCardAuthHandler from './MoveoutCleaningCanceledCardAuthHandler';
import TaskStartedForAdminNotificationHandler from './TaskStartedForAdminNotificationHandler';
import TaskCompletedForAdminNotificationHandler from './TaskCompletedForAdminNotificationHandler';
import DeletedMemberByManagerNotificationHandler from './DeletedMemberByManagerNotificationHandler';
import TeammateEditedAvailabilityNotificationHandler from './TeammateEditedAvailabilityNotificationHandler';
import AdminEditedTeammateAvailabilityNotificationHandler from './AdminEditedTeammateAvailabilityNotificationHandler';

export const notificationTypesList: Record<string, NotificationHandler> = {
  NewToCrewNotification: NewToCrewNotificationHandler,
  ChatNewMessage: ChatNewMessageHandler,
  DemoCallScheduled: DemoCallScheduledHandler,
  AdminEditedTeammateAvailabilityNotification:
    AdminEditedTeammateAvailabilityNotificationHandler,
  EditTeammateAvailabilityTeaser: EditTeammateAvailabilityTeaserHandler,
  MoveoutCleaningCanceledCardAuth: MoveoutCleaningCanceledCardAuthHandler,
  newBookingRequest: NewBookingRequestHandler,
  NewRecurringTaskNotification: NewRecurringTaskNotificationHandler,
  NewTaskNotification: NewTaskNotificationHandler,
  TaskRemovalNotification: TaskRemovalNotificationHandler,
  TaskCompletedForAdminNotification: TaskCompletedForAdminNotificationHandler,
  NewMoveoutRequest: NewMoveoutRequestHandler,
  TaskDeletedByIntegration: TaskDeletedByIntegrationHandler,
  TaskNotCompletedForAdmin: TaskNotCompletedForAdminHandler,
  TaskStartedForAdminNotification: TaskStartedForAdminNotificationHandler,
  TaskUnassigned: TaskUnassignedHandler,
  AskedToBeRemoved: AskedToBeRemovedHandler,
  TeammateEditedAvailabilityNotification:
    TeammateEditedAvailabilityNotificationHandler,
  NewTeammateNotification: NewTeammateNotificationHandler,
  DeletedMemberByManagerNotification: DeletedMemberByManagerNotificationHandler,
  TaskCreatedByIntegration: TaskCreatedByIntegrationHandler,
  NewIntercomMessage: NewIntercomMessageHandler,
  TaskNotStarted: TaskNotStartedHandler,
  UpcomingTasks: UpcomingTasksHandler,
  UserSupportNotification: UserSupportNotificationHandler,
  DefaultNotificationHandler,
};
