import { atom } from 'recoil';

type CustomersDrawerState =
  | 'unselected'
  | 'new-location'
  | 'edit-location'
  | 'location-details'
  | 'new-customer'
  | 'customer-details'
  | 'edit-customer';

export const customersDrawerState = atom<CustomersDrawerState>({
  key: 'customersDrawerState',
  default: 'unselected',
});

type CustomersExtendedDrawerState =
  | 'add-location'
  | 'edit-location'
  | 'customers'
  | null;

export const customersExtendedDrawerState = atom<CustomersExtendedDrawerState>({
  key: 'customersExtendedDrawerState',
  default: null,
});

export const selectedCustomerIdState = atom<number | null>({
  key: 'selectedCustomerIdState',
  default: null,
});

export const sortCustomersState = atom<string>({
  key: 'sortCustomersState',
  default: 'asc',
});

export const sortCustomersByState = atom<string>({
  key: 'sortCustomersByState',
  default: 'name',
});

export const selectedLocationIdState = atom<number | null>({
  key: 'selectedLocationIdState',
  default: null,
});
