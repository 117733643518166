import React, { lazy } from 'react';
import { withAuth } from '@contexts/AuthContext/hocs';
import { AppLocationURLs } from '@consts/index';

const BookingWidgetSettingsPage = lazy(() =>
  import('@pages/BookingWidgetSettings')
);
const BookingWidgetPage = lazy(() => import('@pages/BookingWidget'));

const BookingPrivateRoutes = [
  {
    exact: true,
    path: AppLocationURLs.BOOKING_SITE_SETTINGS_ROUTE,
    blockResponsiveBookingSite: true,
    component: withAuth(BookingWidgetSettingsPage),
  },
  {
    exact: true,
    path: AppLocationURLs.BOOKING_SITE_SETTINGS,
    blockResponsiveBookingSite: true,
    component: withAuth(BookingWidgetSettingsPage),
  },
];

const BookingPublicRoutes = [
  {
    exact: true,
    path: AppLocationURLs.BOOKING_SITE_ID,
    blockResponsive: false,
    component: () => <BookingWidgetPage />,
  },
  {
    exact: true,
    path: AppLocationURLs.BOOKING_SITE_IFRAME_ID,
    blockResponsive: false,
    component: () => <BookingWidgetPage isIframePage />,
  },
];

export { BookingPrivateRoutes, BookingPublicRoutes };
