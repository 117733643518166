import { selector } from "recoil";
import { event } from "@recoilData/index";

const eventTaskRepeat = selector<ITaskRepeat>({
  key: "eventTaskRepeat",
  get: ({ get }) => get(event).taskRepeat,
  set: ({ get, set }, newValue) =>
    set(event, { ...get(event), taskRepeat: newValue as ITaskRepeat }),
});

export { eventTaskRepeat };
