import React from 'react';

import { IntercomProvider } from 'react-use-intercom';

const IntercomChatProvider = ({ children }): JSX.Element => {
  return (
    <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID}>
      {children}
    </IntercomProvider>
  );
};

export default IntercomChatProvider;
