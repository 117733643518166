import React from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { Grid, Box, FormControlLabel } from '@mui/material';

import { CheckListSelectedItem } from './styles';
import { TkCheckBox, TkTooltip, TkIcon, TkTypography } from '@components/index';
import { EventTypeEnum, TaskStatusEnum } from '@consts/index';
import { useDrawer } from '@hooks/drawer';
import { ExtendedDrawersTypes } from '@hooks/drawer/drawer.types';
import {
  editEventChecklists,
  editEventSelectedChecklist,
  changeEditEventExtendedDrawer,
} from '@recoilData/index';

interface ITkChecklistSelectedListItem {
  checklist: IChecklistTask;
  status: string;
  eventChecklists: IChecklistTask[];
}

const TkChecklistSelectedListItem: React.FC<ITkChecklistSelectedListItem> = ({
  checklist,
  status,
  eventChecklists,
}) => {
  const { t } = useTranslation();
  const [checkListState, setCheckListState] = useRecoilState(
    editEventChecklists(checklist.id)
  );
  const { callDrawer } = useDrawer();
  const [selectedChecklist, setSelectedChecklist] = useRecoilState(
    editEventSelectedChecklist
  );

  const eventChecklist = () => {
    return eventChecklists.find(
      (evtChecklist) => evtChecklist.id === checklist.id
    );
  };

  const selectCheckList = () => {
    setSelectedChecklist(eventChecklist());
    if (status === TaskStatusEnum.DONE || status === TaskStatusEnum.IN_PROGRESS)
      callDrawer({
        extended: {
          id: ExtendedDrawersTypes.EDIT_TASK_CHECKLIST_LIST,
          props: {
            eventType: EventTypeEnum.EDIT,
          },
        },
      });
  };

  const handleMandatoryCompletion = (isMandatory: boolean) => {
    setCheckListState({ ...checkListState, isMandatory });
  };

  if (status === TaskStatusEnum.TODO) {
    return (
      <CheckListSelectedItem
        container
        style={{
          padding: '5px',
        }}
        id={`checklist-${checklist?.id}`}
        onClick={selectCheckList}
      >
        <Grid container justifyContent="space-between">
          <Box mr={1} display="inline" width="auto" justifyContent="center">
            <TkTypography
              fontWeight="normal"
              fontFamily="Lato"
              fontSize={16}
              color="default"
            >
              {checklist.title}
            </TkTypography>
          </Box>
          <Box fontSize={16} width="30%">
            <TkTypography
              fontWeight="bold"
              fontFamily="Lato"
              fontSize={16}
              textAlign="right"
              color="default"
            >
              {t('xItems', { ns: 'common', number: checklist.numberOfItems })}
            </TkTypography>
          </Box>
        </Grid>
        <Grid container>
          <FormControlLabel
            key={checklist.id}
            checked={checkListState.isMandatory}
            control={
              <TkCheckBox
                onChange={(_, checked) => handleMandatoryCompletion(checked)}
                checked={checkListState.isMandatory}
              />
            }
            label={
              <TkTypography
                fontSize={14}
                fontFamily="Lato"
                fontWeight="normal"
                color="#011F41"
              >
                {t('mandatoryCompletion', { ns: 'events' })}
                <TkTooltip
                  title={`${t('mandatoryCompletionTooltip', { ns: 'events' })}`}
                >
                  <TkIcon
                    marginLeft={10}
                    className="fa fa-info-circle"
                    color="#1565C0"
                    fontSize="inherit"
                    verticalAlign="text-top"
                  />
                </TkTooltip>
              </TkTypography>
            }
          />
        </Grid>
      </CheckListSelectedItem>
    );
  }

  const evtChecklist =
    status === TaskStatusEnum.DONE ? eventChecklist() : checklist;

  return (
    <CheckListSelectedItem
      container
      color={selectedChecklist?.id === evtChecklist?.id ? 'selected' : ''}
      style={{
        padding: '10px',
      }}
      id={`checklist-${evtChecklist?.id}`}
      onClick={selectCheckList}
    >
      <Grid container justifyContent="space-between">
        <Box
          mr={1}
          display="inline"
          width="auto"
          justifyContent="center"
          maxWidth="50%"
        >
          <TkTypography
            fontWeight="normal"
            fontFamily="Lato"
            fontSize={16}
            color="default"
            style={{
              width: '100%',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {evtChecklist?.title}
          </TkTypography>
        </Box>
        <Box fontSize={16} width="45%">
          <TkTypography
            fontWeight="bold"
            fontFamily="Lato"
            fontSize={16}
            textAlign="right"
            color="default"
          >
            {t('xOfYDone', {
              ns: 'common',
              x: evtChecklist?.completedItemsCount ?? 0,
              y: evtChecklist?.numberOfItems,
            })}
            <TkIcon
              className="fa fa-comment"
              iconSize={16}
              width={18}
              color="default"
              marginLeft={10}
              marginRight={5}
              marginBottom={2}
              verticalAlign="text-bottom"
            />
            {evtChecklist?.commentsCount}
          </TkTypography>
        </Box>
      </Grid>
      {checkListState.isMandatory && (
        <Grid container>
          <TkTypography
            fontWeight="bold"
            fontFamily="Lato"
            fontSize={14}
            color="default"
          >
            <Box
              marginTop="5px"
              bgcolor="rgba(31, 155, 221, 0.3)"
              borderRadius="11px"
              padding="0 5px 2px 5px"
              justifyContent="center"
              alignItems="center"
            >
              {t('mandatory', { ns: 'events' })}
            </Box>
          </TkTypography>
        </Grid>
      )}
    </CheckListSelectedItem>
  );
};

export default TkChecklistSelectedListItem;
