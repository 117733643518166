import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import {
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState,
  useRecoilState,
  RecoilState,
} from 'recoil';

import { Grid, Box } from '@mui/material';

import TkExtendedCalendarFilterDrawer, {
  CalendarFilterExtendedDrawer,
} from './TkExtendedCalendarFilterDrawer';
import TkNamePills from './TkNamePills';
import TkStatusListItem from './TkStatusListItem';
import {
  TkCalendarFilterWrapper,
  TkCalendarFilterItem,
  TkCalendarFilterItemTitle,
  TkCalendarFilterItemAction,
  TkCalendarFilterItemContent,
  TkCalendarFilterItemCheckBox,
  TkTaskActions,
  MoreActionTag,
} from './styles';
import { TkBaseDrawer, TkTypography, DynamicButton } from '@components/index';
import { CalendarDrawerTypes } from '@consts/index';
import {
  setFilter,
  resetFilter,
  filterCustomers,
  filterAssignees,
  filterLocations,
  getFilteredStatus,
  filterExtendedDrawer,
  getFilteredCustomers,
  getFilteredLocations,
  getFilteredAssignees,
  getFilteredAssigneesCount,
  getFilteredCustomersCount,
  getFilteredLocationsCount,
} from '@recoilData/index';

interface FilterItemSelectorProps {
  actionText: string;
  count: number;
  data: any[];
  filterData: (param: number) => RecoilState<boolean>;
  title: string;
  type: string;
  itemNameTag: string;
}

const TkCalendarFilter = ({
  setDrawerOpen,
  drawers,
}: ITkDrawerFunctions): JSX.Element => {
  const { t } = useTranslation();
  const setFilterAction = useSetRecoilState(setFilter);
  const customers = useRecoilValue(getFilteredCustomers);
  const assignees = useRecoilValue(getFilteredAssignees);
  const locations = useRecoilValue(getFilteredLocations);
  const filterOptions = useRecoilValue(getFilteredStatus);
  const resetFilterData = useResetRecoilState(resetFilter);
  const customersCount = useRecoilValue(getFilteredCustomersCount);
  const assigneesCount = useRecoilValue(getFilteredAssigneesCount);
  const locationsCount = useRecoilValue(getFilteredLocationsCount);

  const MAX_PILL_SHOW_COUNT = 5;
  const MORE_SHOW_ALERT = MAX_PILL_SHOW_COUNT + 1;

  const [extendedObject, setExtendedObject] =
    useRecoilState(filterExtendedDrawer);

  useEffect(() => {
    if (!drawers.filter)
      setExtendedObject({
        open: false,
        type: '',
      });
  }, [drawers.filter]);

  const onClickOut = () => {
    if (extendedObject.open) setExtendedObject({ type: '', open: false });
  };

  const handleSetFilters = (setFilter = false) => {
    if (setFilter) setFilterAction(true);

    setDrawerOpen({
      isOpen: !drawers.filter,
      drawer: CalendarDrawerTypes.filter,
    });
  };

  const openSelectedDrawer = (drawer) => {
    setExtendedObject({
      type: drawer,
      open:
        extendedObject.open && extendedObject.type === drawer ? false : true,
    });
  };

  const filterItem = {
    [CalendarFilterExtendedDrawer.ASSIGNEES]: {
      actionText: t('teammates', { ns: 'common' }),
      count: assigneesCount,
      data: assignees,
      filterData: filterAssignees,
      itemNameTag: 'full_name',
      title: t('assignedTo', { ns: 'events' }),
      type: CalendarFilterExtendedDrawer.ASSIGNEES,
    },
    [CalendarFilterExtendedDrawer.CUSTOMERS]: {
      actionText: t('customers', { ns: 'common' }),
      count: customersCount,
      data: customers,
      filterData: filterCustomers,
      itemNameTag: 'name',
      title: t('customers', { ns: 'common' }),
      type: CalendarFilterExtendedDrawer.CUSTOMERS,
    },
    [CalendarFilterExtendedDrawer.LOCATIONS]: {
      actionText: t('locations', { ns: 'common' }),
      count: locationsCount,
      data: locations,
      filterData: filterLocations,
      itemNameTag: 'alias',
      title: t('locations', { ns: 'common' }),
      type: CalendarFilterExtendedDrawer.LOCATIONS,
    },
  };

  const FilterItemSelector = ({
    title,
    type,
    actionText,
    data,
    count,
    filterData,
    itemNameTag,
  }: FilterItemSelectorProps) => (
    <TkCalendarFilterItem container>
      <TkCalendarFilterItemTitle container>
        <TkTypography fontFamily="Lato" fontSize={16} fontWeight="bold">
          {title}
        </TkTypography>
      </TkCalendarFilterItemTitle>
      <TkCalendarFilterItemAction
        container
        onClick={() => openSelectedDrawer(type)}
      >
        <TkTypography
          fontFamily="Lato"
          fontSize={16}
          fontWeight="bold"
          color="primary"
        >
          + {actionText}
        </TkTypography>
      </TkCalendarFilterItemAction>
      <TkCalendarFilterItemContent container>
        {data.map((item, index) =>
          item.checked ? (
            index <= MAX_PILL_SHOW_COUNT ? (
              <TkNamePills
                key={`${title}-pill-${item.id}`}
                id={item.id}
                filterData={filterData}
                name={item[itemNameTag]}
                handleAction={() => null}
              />
            ) : null
          ) : null
        )}
        {count > MORE_SHOW_ALERT ? (
          <MoreActionTag onClick={() => openSelectedDrawer(type)}>
            {t('calendar:filterAndXMore', {
              count: count - MORE_SHOW_ALERT,
            })}
          </MoreActionTag>
        ) : null}
      </TkCalendarFilterItemContent>
    </TkCalendarFilterItem>
  );

  return (
    <>
      <TkBaseDrawer open={drawers.filter ?? false}>
        <TkCalendarFilterWrapper onClick={onClickOut}>
          <Grid container alignItems="center" justifyContent="center">
            <TkTypography
              fontFamily="Muli"
              fontSize={20}
              fontWeight="bold"
              color="default"
            >
              {t('taskFilter', { ns: 'calendar' })}
            </TkTypography>
          </Grid>

          <TkCalendarFilterItem container>
            <TkCalendarFilterItemTitle container>
              <TkTypography fontFamily="Lato" fontSize={16} fontWeight="bold">
                {t('status', { ns: 'common' })}
              </TkTypography>
            </TkCalendarFilterItemTitle>
            <TkCalendarFilterItemCheckBox>
              {filterOptions.map((status, index) => (
                <TkStatusListItem key={index} status={status} />
              ))}
            </TkCalendarFilterItemCheckBox>
          </TkCalendarFilterItem>

          {Object.values(filterItem).map((item, index) => (
            <FilterItemSelector key={index} {...item} />
          ))}
        </TkCalendarFilterWrapper>

        <TkTaskActions>
          <Box display="flex" flexDirection="row">
            <DynamicButton
              color="primary"
              disableElevation
              onClick={() => handleSetFilters()}
              style={{ marginRight: '10px' }}
            >
              <TkTypography fontWeight="normal" fontFamily="Lato" fontSize={16}>
                {t('cancel', { ns: 'common' })}
              </TkTypography>
            </DynamicButton>
            <DynamicButton
              variant="contained"
              bgcolor="secondary"
              disableElevation
              onClick={() => resetFilterData()}
            >
              <TkTypography fontWeight="bold" fontFamily="Lato" fontSize={16}>
                {t('clearFilters', { ns: 'calendar' })}
              </TkTypography>
            </DynamicButton>
          </Box>
          <DynamicButton
            variant="contained"
            bgcolor="primary"
            disableElevation
            onClick={() => handleSetFilters(true)}
          >
            <TkTypography fontWeight="bold" fontFamily="Lato" fontSize={16}>
              {t('common:apply')}
            </TkTypography>
          </DynamicButton>
        </TkTaskActions>
      </TkBaseDrawer>
      <TkExtendedCalendarFilterDrawer />
    </>
  );
};

export default TkCalendarFilter;
