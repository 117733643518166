import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { FormControl, Grid } from '@mui/material';

import {
  TkListWrapper,
  TkExtendedWrapper,
  TkListActions,
  TkListItemSubTextIcon,
  FullWrapper,
  FullWrapperItem,
  FullWrapperIcon,
} from '../styles';
import {
  TkLocationListItem,
  TkExtendedDrawerSearch,
} from '@components/ExtendedDrawers/index';
import { TkTypography, TkIcon, DynamicButton } from '@components/index';
import { useDrawer } from '@hooks/index';
import {
  searchEditEventLocationsData,
  editEventCustomer,
  editEventLocation,
} from '@recoilData/index';

const TkLocationList = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    closeExtDrawer,
    callDrawer,
    types: { ExtendedDrawersTypes },
  } = useDrawer();
  const [search, setSearch] = useState('');
  const customer = useRecoilValue(editEventCustomer);
  const locationsList = useRecoilValue(searchEditEventLocationsData(search));
  const changeExtendedDrawer = () =>
    callDrawer({
      extended: { id: ExtendedDrawersTypes.EDIT_TASK_CUSTOMER },
    });

  return (
    <>
      <TkExtendedWrapper>
        {locationsList.length === 0 && !search ? (
          <FullWrapper>
            <FullWrapperItem
              container
              justifyContent="center"
              alignItems="center"
            >
              <FullWrapperIcon
                className="fal fa-map-marker-alt-slash"
                iconSize={80}
                color="gray"
              />
            </FullWrapperItem>
            <FullWrapperItem
              container
              justifyContent="center"
              alignItems="center"
            >
              <TkTypography
                fontFamily="Muli"
                fontSize={16}
                fontWeight="bold"
                color="default"
                textAlign="center"
              >
                {customer === undefined
                  ? t('locations.youDontHaveAnyLocations', { ns: 'app' })
                  : t('locations.thisCustomerDoesntHaveAnyLocations', {
                      ns: 'app',
                    })}
              </TkTypography>
            </FullWrapperItem>
            {/* <FullWrapperItem container justify="center" alignItems="center">
              <DynamicButton
                variant="outlined"
                color="primary"
                disableElevation
              >
                <TkTypography
                  fontWeight="normal"
                  fontFamily="Lato"
                  fontSize={16}
                >
                  Add a New Location
                </TkTypography>
              </DynamicButton>
            </FullWrapperItem> */}
          </FullWrapper>
        ) : (
          <>
            <FormControl>
              <TkTypography fontWeight="bold" fontFamily="Muli" fontSize={16}>
                {t('selectALocation', { ns: 'events' })}
              </TkTypography>
              <Grid container alignItems="flex-end">
                <Grid item>
                  <TkIcon className="fa fa-search" color="#4952A9" />
                </Grid>
                <Grid item sm={10}>
                  <TkExtendedDrawerSearch
                    search={search}
                    setSearch={setSearch}
                  />
                </Grid>
              </Grid>
            </FormControl>
            <TkListWrapper>
              {locationsList.map((location) => (
                <TkLocationListItem
                  key={location.id}
                  location={location}
                  eventCustomerRecoilState={editEventCustomer}
                  eventLocationRecoilState={editEventLocation}
                />
              ))}
            </TkListWrapper>
          </>
        )}
      </TkExtendedWrapper>
      <TkListActions>
        <Grid
          container
          justifyContent="space-between"
          style={{ margin: '10px 0 10px 0' }}
        >
          <DynamicButton color="primary" onClick={changeExtendedDrawer}>
            <TkTypography fontWeight="normal" fontFamily="Lato" fontSize={16}>
              <TkListItemSubTextIcon className="fa fa-angle-left" />
              {t('back', { ns: 'common' })}
            </TkTypography>
          </DynamicButton>
          <DynamicButton color="primary" onClick={closeExtDrawer}>
            <TkTypography fontWeight="normal" fontFamily="Lato" fontSize={16}>
              {t('skip', { ns: 'common' })}
            </TkTypography>
          </DynamicButton>
        </Grid>
        {/* <Grid container style={{ margin: "0 0 13px 0" }}>
          <DynamicButton
            variant="outlined"
            color="secondary"
            disableElevation
            style={{ width: "100%" }}
          >
            <TkTypography fontWeight="normal" fontFamily="Lato" fontSize={16}>
              Add a New Location
            </TkTypography>
          </DynamicButton>
        </Grid> */}
      </TkListActions>
      {/* {locationsList.length === 0 ? (
        ""
      ) : (
        <TkListActions>
          <Grid container>
            <DynamicButton
              variant="outlined"
              color="primary"
              disableElevation
              fullWidth
            >
              <TkTypography fontWeight="normal" fontFamily="Lato" fontSize={16}>
                Add a New Location
              </TkTypography>
            </DynamicButton>
          </Grid>
        </TkListActions>
      )} */}
    </>
  );
};

export default TkLocationList;
