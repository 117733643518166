import React from 'react';
import { SelectorWrapper } from '../styles';
import { useTranslation } from 'react-i18next';
import { TkTypography } from '@components/index';
import { TkIconSelected } from '@web-ui/index';
interface ITkLocationSelector {
  location: ILocationTask | undefined;
  handleClick: () => void;
}

const TkLocationSelector: React.FC<ITkLocationSelector> = ({
  location,
  handleClick,
}) => {
  const { t } = useTranslation();

  if (!location) {
    return (
      <SelectorWrapper onClick={handleClick}>
        <TkTypography
          fontWeight="bold"
          fontFamily="Lato"
          fontSize={16}
          color="primary"
          userselect="none"
        >
          + {t('location', { ns: 'common' })}
        </TkTypography>
      </SelectorWrapper>
    );
  }

  return (
    <SelectorWrapper onClick={handleClick}>
      <TkTypography
        fontWeight="normal"
        fontFamily="Lato"
        fontSize={16}
        color="#011F41"
      >
        {location.alias}
        <TkIconSelected
          className="fa fa-pencil"
          verticalAlign="middle"
          style={{ top: 6 }}
        />
      </TkTypography>
      <TkTypography
        fontWeight="normal"
        fontFamily="Lato"
        fontSize={14}
        color="#999999"
        marginTop="5px"
      >
        {location.street_address || location.streetAddress}
      </TkTypography>
    </SelectorWrapper>
  );
};

export default TkLocationSelector;
