import React, { useContext, useState } from 'react';

import { CircularProgress } from '@mui/material';

import LoadingContext from './index';
import { AbsoluteBackdrop } from './styles';

type LoadingProviderProps = {
  children: React.ReactNode;
};

interface ILoadingHook {
  showLoading: boolean;
  toggleShowLoading: (toggle: boolean) => void;
}

export const LoadingProvider = ({
  children,
}: LoadingProviderProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(false);

  const toggleShowLoading = (toggle: boolean) => {
    setOpen(toggle);
  };

  const hooks: ILoadingHook = {
    showLoading: open,
    toggleShowLoading,
  };

  return (
    <LoadingContext.Provider value={hooks}>
      <AbsoluteBackdrop open={open}>
        <CircularProgress color="inherit" />
      </AbsoluteBackdrop>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = (): ILoadingHook => useContext(LoadingContext);
