import { Grid } from "@mui/material";
import { withStyles } from "@mui/styles";
import styled from "styled-components";

export const AssigneeButton = withStyles({
  root: {
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
    cursor: "pointer",
  },
})(Grid);

const assigneeButtonHeight = "36px";
const assigneeButtonWidth = "36px";

export const IconAssigneeButtonHover = styled.div`
  display: flex;
  width: ${assigneeButtonWidth};
  height: ${assigneeButtonHeight};
  border-radius: 50%;
  border: #feb334 1px solid;
  text-align: center;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  color: #feb334;
  background-color: #ffffff;
`;

export const IconNotAssignedButton = styled(IconAssigneeButtonHover)`
  color: #fff;
  background-color: #feb334;
`;
