import React from 'react';

import { AdditionalInfoDrawer } from './AdditionalInfoDrawer';
import { editEvent, offersDetailsExtendedDrawer } from '@recoilData/index';

export const TkDrawerTurnoProjectAdditionalInfo = (): JSX.Element => {
  return (
    <AdditionalInfoDrawer
      taskState={editEvent}
      extendedDrawerState={offersDetailsExtendedDrawer}
    />
  );
};
