import { atom, atomFamily } from "recoil";

const editEventChecklists = atomFamily<IEventChecklists, number>({
  key: "editEventChecklists",
  default: {
    checked: false,
    isMandatory: false,
  },
});

const isAllEditEventChecklistsSelected = atom<boolean>({
  key: "isAllEditEventChecklistsSelected",
  default: false,
});

export { editEventChecklists, isAllEditEventChecklistsSelected };
