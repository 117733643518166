import React from "react";

import GlobalStyles from "@styles/globals";

const GlobalStylesProvider: React.FC = ({ children }) => {
  return (
    <>
      {children}
      <GlobalStyles />
    </>
  );
};

export default GlobalStylesProvider;
