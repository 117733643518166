import { lazy } from 'react';
import { withAuth } from '@contexts/AuthContext/hocs';
import { AppLocationURLs } from '@consts/index';

//
const CustomersPage = lazy(() =>
  /* webpackChunkName: customers-page */ import(
    '@pages/Customers/components/customers-page'
  )
);

const CustomersRoutes = [
  {
    path: AppLocationURLs.CUSTOMERS,
    component: withAuth(CustomersPage),
  },
];

export default CustomersRoutes;
