import { selector } from 'recoil';

import { LocalStorage } from '@consts/index';
import { sessionState } from '@contexts/AuthContext/state';
import { userData } from '@recoilData/atoms';

const getUserToken = selector({
  key: 'getUserToken',
  get: ({ get }) => {
    const user = get(userData);
    return user.access_token;
  },
});

const getUserChatProfile = selector({
  key: 'getUserChatProfile',
  get: ({ get }) => {
    const user = get(userData);
    return user.chat_profile;
  },
});

const getUserInfo = selector({
  key: 'getUserInfo',
  get: ({ get }) => {
    const user = get(userData);
    return user.user;
  },
});

const getUserAccount = selector({
  key: 'getUserAccount',
  get: ({ get }) => {
    const user = get(userData);
    return user.account;
  },
});

const setUserAccount = selector({
  key: 'setUserAccount',
  get: ({ get }) => {
    const user = get(userData);
    return user.account;
  },
  set: ({ get, set }, newValue) => {
    set(userData, { ...get(userData), account: newValue as any });
  },
});

const getUserAccountId = selector({
  key: 'getUserAccountId',
  get: ({ get }) => {
    const user = get(sessionState);

    const localAccountId = localStorage.getItem(LocalStorage.ACCOUNT_ID);

    return user?.account?.id || parseInt(localAccountId);
  },
});

export {
  getUserInfo,
  getUserToken,
  setUserAccount,
  getUserAccount,
  getUserAccountId,
  getUserChatProfile,
};
