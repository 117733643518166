import { useTranslation } from 'react-i18next';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { handleCloseEvent, pushNewAlert } from '@recoilData/index';

export const useSnackbar = () => {
  const { t } = useTranslation();

  const fireTError = (token, options) =>
    addAlert({
      message: t(token, options),
      severity: 'error',
    });

  const fireTSuccess = (token, options, duration?: number) => {
    addAlert({
      message: t(token, options),
      severity: 'success',
      autoHideDuration: duration || 5000,
    });
  };

  const addAlert = useSetRecoilState(pushNewAlert);
  const handleClose = useResetRecoilState(handleCloseEvent);

  return {
    addAlert,
    handleClose,
    fireTError,
    fireTSuccess,
  };
};
