import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { RecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { Grid, Divider } from '@mui/material';

import {
  TkExtendedWrapper,
  TkTaskWrapperItem,
} from '@components/TkOfferDetailsDrawer/styles';
import { TkIcon, TkTypography } from '@components/index';
import { TaskbirdIntegrationsEnum } from '@consts/integrations';
import { getOfferAdditionalItems } from '@helpers/offers';

const SizeUnitMap = {
  SQUARE_FEET: 'sq_ft',
};

const IconMap = {
  guest_arrives_same_day: 'fa fa-exchange',
  number_of_bedrooms: 'fas fa-bed',
  number_of_bathrooms: 'fas fa-bath',
  size: 'fas fa-ruler-combined',
  next_booking_info: 'fas fa-alarm-clock',
  next_guest_name: 'fas fa-id-badge',
};

const useFormatItemValue = (item, offer): number | string => {
  const { t } = useTranslation();
  if (item.value === null) return t('notAvailable', { ns: 'common' }) as string;

  const value = Number.isNaN(+item.value) ? item.value : +item.value;
  const shortestNumber = parseFloat(value.toFixed(2));
  if (item.name === 'size') {
    return `${shortestNumber} ${t(SizeUnitMap[offer.location.size_unit], {
      ns: 'offers',
    })}`;
  }

  return shortestNumber;
};

const AdditionalInfoContent = ({ item, offer }): JSX.Element => {
  const { t } = useTranslation();
  const value = useFormatItemValue(item, offer);

  switch (item.name) {
    case 'guest_arrives_same_day':
      return (
        <TkTypography fontWeight="bold">
          {t(`items.guest_arrives_same_day`, { ns: 'offers' })}
        </TkTypography>
      );
    default:
      return (
        <>
          <TkTypography>
            {t(`items.${item.name}`, { ns: 'offers' })}:
          </TkTypography>
          {typeof item.value !== 'boolean' && (
            <TkTypography fontWeight="bold" marginLeft={2}>
              {value}
            </TkTypography>
          )}
        </>
      );
  }
};

export const AdditionalInfoItem = ({ item, offer }): JSX.Element => {
  const icon = IconMap[item.name];
  const color = item.name === 'guest_arrives_same_day' ? '#fbb333' : 'inherit';
  return (
    <Grid
      item
      display="flex"
      flexDirection="row"
      alignItems="center"
      marginBottom="1rem"
    >
      <TkIcon
        iconSize={16}
        color={color}
        className={icon}
        marginRight={16}
        sx={{ width: 'initial' }}
      />
      <AdditionalInfoContent item={item} offer={offer} />
    </Grid>
  );
};

export const AdditionalInfoDrawer = ({
  taskState,
  extendedDrawerState,
}: {
  taskState: RecoilState<IProjectOffer | IEvent>;
  extendedDrawerState: RecoilState<ITkExtendedObject>;
}): JSX.Element => {
  const { t } = useTranslation();
  const task = useRecoilValue(taskState);
  const { items } = getOfferAdditionalItems(task);
  const closeExtendedDrawer = useResetRecoilState(extendedDrawerState);

  useEffect(() => {
    if (task?.platform !== TaskbirdIntegrationsEnum.TURNOVERBNB) {
      closeExtendedDrawer();
      return;
    }
  }, [task, closeExtendedDrawer]);

  return (
    <TkExtendedWrapper maxWidth="lg">
      <Grid
        container
        direction="row"
        alignItems="center"
        marginBottom="30px"
        justifyContent="space-between"
      >
        <TkTypography fontWeight="bold" fontFamily="Muli" fontSize={16}>
          {t('additionalInfo', { ns: 'offers' })}
        </TkTypography>
      </Grid>
      <TkTaskWrapperItem
        padding="0 0 2rem"
        height="100%"
        style={{
          overflowY: 'scroll',
        }}
      >
        <Grid marginBottom="15px">
          {items.map((item, index) => (
            <React.Fragment key={index}>
              {item.map((item, index) => (
                <AdditionalInfoItem key={index} offer={task} item={item} />
              ))}
              <Divider sx={{ marginBottom: 4 }} />
            </React.Fragment>
          ))}
        </Grid>
      </TkTaskWrapperItem>
    </TkExtendedWrapper>
  );
};
