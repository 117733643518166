import React, { useCallback } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';
import { match } from 'ts-pattern';

import { ClickAwayListener, Box } from '@mui/material';

import DynamicButton from '@components/DynamicButton';
import TkTypography from '@components/TkTypography';
import { EventTypeEnum } from '@consts/drawers';
import { ProjectOfferRequestAction } from '@features/projectOffers/components/projectOffersList';
import { useDrawer } from '@hooks/drawer';
import { ExtendedDrawersTypes } from '@hooks/drawer/drawer.types';
import { selectedEventId } from '@recoilData/atoms';

interface ProjectOffersListActionsProps {
  snackBarId;
  action: ProjectOfferRequestAction;
  closeSnackbar: (key?: number) => void;
  doMutation: (callback?: (offerID: number) => void) => void;
  cancelMutation: () => void;
}

export const ProjectOfferSnackbarActions = React.forwardRef<
  HTMLDivElement,
  ProjectOffersListActionsProps
>((props, ref) => {
  const { snackBarId, action, closeSnackbar, doMutation, cancelMutation } =
    props;
  const { t } = useTranslation();
  const [eventID, setEventID] = useRecoilState(selectedEventId);
  const { callDrawer } = useDrawer();

  const handleClose = (closeCallback: () => void) => {
    closeCallback();
    closeSnackbar(snackBarId);
  };

  const handleCancelMutation = () => handleClose(cancelMutation);
  const handleDoMutation = (mutationCallback?: (offerId) => void) => {
    handleClose(() => doMutation(mutationCallback));
  };

  const handleAssign = useCallback(() => {
    doMutation((offerID) => {
      setEventID((prevID) => (prevID === offerID ? null : offerID));
      callDrawer({
        extended: {
          id: ExtendedDrawersTypes.TASK_ASSIGNEE_LIST,
          props: {
            eventType: EventTypeEnum.EDIT,
          },
        },
      });
    });
  }, [eventID]);

  return (
    <ClickAwayListener onClickAway={() => handleDoMutation()}>
      <Box ref={ref}>
        {match(action)
          .with(ProjectOfferRequestAction.Accept, () => (
            <>
              <DynamicButton
                variant="outlined"
                onClick={() => {
                  handleAssign();
                  closeSnackbar(snackBarId);
                }}
                style={{
                  backgroundColor: 'transparent',
                  borderColor: 'white',
                  marginRight: '3rem',
                  maxHeight: '1.8rem',
                }}
                size="small"
              >
                <TkTypography color="white" fontWeight="bold">
                  {t('assign', { ns: 'common' })}
                </TkTypography>
              </DynamicButton>
              <DynamicButton
                onClick={handleCancelMutation}
                style={{ backgroundColor: 'transparent' }}
                color="secondary"
                size="small"
              >
                <TkTypography color="white">
                  {t('undo', { ns: 'common' })}
                </TkTypography>
              </DynamicButton>
            </>
          ))
          .with(ProjectOfferRequestAction.Reject, () => (
            <Box
              sx={{ width: '180px', display: 'flex', justifyContent: 'end' }}
            >
              <DynamicButton
                onClick={handleCancelMutation}
                style={{ backgroundColor: 'transparent' }}
                color="secondary"
                size="small"
              >
                <TkTypography color="white">
                  {t('undo', { ns: 'common' })}
                </TkTypography>
              </DynamicButton>
            </Box>
          ))
          .otherwise(() => null)}
      </Box>
    </ClickAwayListener>
  );
});

ProjectOfferSnackbarActions.displayName = 'ProjectOfferSnackbarActions';
