import React, { useEffect } from 'react';

import { useRecoilState } from 'recoil';

import { useSnackbar } from './useSnackbar';
import { TkAlert } from '@components/index';
import { Snackbar, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import { AlertProps } from '@material-ui/lab/Alert';
import { alertsData, visibleAlertData } from '@recoilData/atoms';

const SlideTransition = (props: TransitionProps) => {
  return <Slide {...props} direction="down" />;
};

const TkSnackbar: React.FC = () => {
  const { handleClose } = useSnackbar();
  const [alerts, setAlerts] = useRecoilState(alertsData);
  const [visibleAlert, setVisibleAlert] = useRecoilState(visibleAlertData);

  useEffect(() => {
    if (alerts.length > 0 && visibleAlert.visible === false) {
      setVisibleAlert({ ...alerts[0], visible: true });
    }
  }, [alerts]);

  useEffect(() => {
    if (visibleAlert.visible === true) return;

    const alertsArray = alerts.slice(1, alerts.length);
    setAlerts(alertsArray);
  }, [visibleAlert]);

  const severity = visibleAlert?.severity as AlertProps['severity'];
  const component = visibleAlert.customComponent || (
    <TkAlert severity={severity}>{visibleAlert?.message}</TkAlert>
  );

  return (
    <Snackbar
      onClose={handleClose}
      autoHideDuration={visibleAlert?.autoHideDuration || 5000}
      key={SlideTransition.name}
      open={visibleAlert.visible}
      TransitionComponent={SlideTransition}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      {component}
    </Snackbar>
  );
};

export default TkSnackbar;
