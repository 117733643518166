import React from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import TkAssigneeListItem from '@components/TkCalendarFilter/TkAssigneeListItem';
import TkCustomerListItem from '@components/TkCalendarFilter/TkCustomerListItem';
import TkDropDownListPills from '@components/TkCalendarFilter/TkDropDownListPills';
import TkLocationListItem from '@components/TkCalendarFilter/TkLocationListItem';
import TkNamePills from '@components/TkCalendarFilter/TkNamePills';
import { SelectedFilterOptionsWrapper } from '@components/TkMainCalendar/styles';
import {
  assigneesData,
  customersData,
  filterStatus,
  getAssigneesDataCount,
  getCustomersDataCount,
  getFilteredAssigneesCount,
  getFilteredCustomersCount,
  getFilteredLocationsCount,
  getFilteredStatus,
  getLocationsDataCount,
  isAllFilterAssigneesSelected,
  isAllFilterCustomersSelected,
  isAllFilterLocationsSelected,
  locationsData,
  selectAllFilterAssignees,
  selectAllFilterCustomers,
  selectAllFilterLocations,
  setUnassignedStatus,
} from '@recoilData/index';

const FilterHeader = (): JSX.Element => {
  const { t } = useTranslation();
  const customers = useRecoilValue(customersData);
  const assignees = useRecoilValue(assigneesData);
  const locations = useRecoilValue(locationsData);
  const status = useRecoilValue(getFilteredStatus);
  const customersDataCount = useRecoilValue(getCustomersDataCount);
  const locationsDataCount = useRecoilValue(getLocationsDataCount);
  const assigneesDataCount = useRecoilValue(getAssigneesDataCount);
  const filteredCustomersCount = useRecoilValue(getFilteredCustomersCount);
  const filteredAssigneesCount = useRecoilValue(getFilteredAssigneesCount);
  const filteredLocationsCount = useRecoilValue(getFilteredLocationsCount);
  const [isUnassigned, setUnassigned] = useRecoilState(setUnassignedStatus);

  const isAllTeammatesSelected =
    filteredAssigneesCount === 0 && !isUnassigned
      ? t('all', { ns: 'common' })
      : filteredAssigneesCount;

  const isAllCustomersSelected =
    filteredCustomersCount === 0
      ? t('all', { ns: 'common' })
      : filteredCustomersCount;

  const isAllLocationsSelected =
    filteredLocationsCount === 0
      ? t('all', { ns: 'common' })
      : filteredLocationsCount;

  return (
    <SelectedFilterOptionsWrapper>
      {status.map((status) =>
        status.checked ? (
          <TkNamePills
            key={status.id}
            id={status.id}
            name={t(status.display, { ns: 'common' })}
            handleAction={() => null}
            filterData={filterStatus}
          />
        ) : null
      )}
      <TkDropDownListPills
        name={t('calendar:filterTeammatesDropDrown', {
          selected: isAllTeammatesSelected,
          total: assigneesDataCount,
        })}
        isAllFilterDataSelected={isAllFilterAssigneesSelected}
        selectAllFilterData={selectAllFilterAssignees}
        handleSelectAll={(checked: boolean, selectAllItems) => {
          if (isUnassigned && checked) {
            setUnassigned(false);
          }
          selectAllItems(checked);
        }}
        ListItem={() => (
          <>
            {assignees.map((assignee: IAssignee) => (
              <TkAssigneeListItem key={assignee.id} assignee={assignee} popup />
            ))}
          </>
        )}
      />
      <TkDropDownListPills
        name={t('calendar:filterCustomersDropDrown', {
          selected: isAllCustomersSelected,
          total: customersDataCount,
        })}
        handleSelectAll={(checked: boolean, selectAllItems) => {
          selectAllItems(checked);
        }}
        isAllFilterDataSelected={isAllFilterCustomersSelected}
        selectAllFilterData={selectAllFilterCustomers}
        ListItem={() => (
          <>
            {customers.map((customer: ICustomerTask) => (
              <TkCustomerListItem key={customer.id} customer={customer} popup />
            ))}
          </>
        )}
      />
      <TkDropDownListPills
        name={t('calendar:filterLocationsDropDrown', {
          selected: isAllLocationsSelected,
          total: locationsDataCount,
        })}
        isAllFilterDataSelected={isAllFilterLocationsSelected}
        selectAllFilterData={selectAllFilterLocations}
        handleSelectAll={(checked: boolean, selectAllItems) => {
          selectAllItems(checked);
        }}
        ListItem={() => (
          <>
            {locations.map((location: ILocationTask) => (
              <TkLocationListItem key={location.id} location={location} popup />
            ))}
          </>
        )}
      />
    </SelectedFilterOptionsWrapper>
  );
};

export default FilterHeader;
