import React, { useEffect, useRef, useMemo } from 'react';

// eslint-disable-next-line import/order
import FullCalendar, { CalendarApi, EventDropArg } from '@fullcalendar/react';
// eslint-disable-next-line import/order
import { ResourceLabelContentArg } from '@fullcalendar/resource-common';
// eslint-disable-next-line import/order
import dayGridPlugin from '@fullcalendar/daygrid';
// eslint-disable-next-line import/order
import timeGridPlugin from '@fullcalendar/timegrid';
// eslint-disable-next-line import/order
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
// eslint-disable-next-line import/order
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import CalendarDatePickerActivator from './CalendarDatePickerActivator';
import SettingsModal from './SettingsModal';
import { handleEventFilter } from './helpers';
import {
  FullCalendarWrapper,
  FullCalendarWrapperHeader,
  MoreLinks,
  SettingsButton,
  WeekViewHeaderItem,
  WeekViewHeaderWrapper,
  useStyles,
  ResourceHeaderContent,
  ResourceHeaderTask,
  ResourceHeaderTitle,
  ChangeViewButton,
  ChangeViewButtonGroup,
  GridDatePickerWrapper,
} from './styles';
import { ITkFullCalendar } from './types';
import { IconNotAssignedButton } from '@components/Drawers/TkNewTaskDrawer/styles';
import TkAssigneeAlertModal from '@components/TkAssigneeAlertModal';
import TkLoadingBackground from '@components/TkLoadingBackground';
import FilterHeader from '@components/TkMainCalendar/FilterHeader';
import {
  DynamicButton,
  IconAssigneeButton,
  ImgAssignee,
  TkDatePicker,
  TkFormControl,
  TkIcon,
  TkModalAlert,
  TkPillContent,
  TkSelect,
} from '@components/index';
import {
  CalendarDrawerTypes,
  ICalendarViewEnum,
  TaskStatusEnum,
  WeekViewTypesEnum,
} from '@consts/index';
import { useModal } from '@contexts/index';
import { generateInitials, getLocaleTokenWeekFromDate } from '@helpers/index';
import { useUserSettings } from '@hooks/index';
import { Box, FormControl, Grid, IconButton, Select } from '@material-ui/core';
import { isFirstDaySunday, isSameResource } from '@pages/Home/helpers';
import { useHomePage } from '@pages/Home/hooks';
import {
  resetFilter,
  openLoneTask,
  isFilterToday,
  assigneesData,
  isNewTaskExternal,
  getFilteredStatus,
  setEventCustomerById,
  getFilteredAssignees,
  getFilteredCustomers,
  getFilteredLocations,
  addSingleAssigneeById,
  calendarWeekViewSelector,
  getSelectedEventAssignees,
  getOffersCountByInterval,
  tasksFilter,
} from '@recoilData/index';

const TkMainCalendar = ({
  props,
  events,
  resources,
  isFetching,
  refetch,
}: ITkFullCalendar): JSX.Element => {
  const { t } = useTranslation();
  const styleClasses = useStyles();
  const calendarRef = useRef<any>();
  const filterSet = useRecoilValue(resetFilter);
  const assignees = useRecoilValue(assigneesData);
  const filteredTasks = useRecoilValue(tasksFilter);
  const { userSettings } = useUserSettings();
  const { setupModal, handleCloseModal } = useModal();
  const filteredStatus = useRecoilValue(getFilteredStatus);
  const resetFilterData = useResetRecoilState(resetFilter);
  const filteredCustomers = useRecoilValue(getFilteredCustomers);
  const filteredAssignees = useRecoilValue(getFilteredAssignees);
  const filteredLocations = useRecoilValue(getFilteredLocations);
  const addAssigneeById = useSetRecoilState(addSingleAssigneeById);
  const {
    calendarView,
    setCalendarView,
    drawers,
    eventId,
    isAnyDrawerOpen: collapsed,
    offerId,
    openNewTask,
    selectedDateCalendar,
    setDrawerEvent,
    setDrawerOpen,
    setSelectedDateCalendar,
    updateEvent,
    updateResourceEvent,
    isMonthView,
    isWeekView,
    isWeekViewTypeAssignee,
    isWeekViewTypeCustomer,
    dateModifierFlag,
    setEventId,
  } = useHomePage();
  const setNewEventCustomer = useSetRecoilState(setEventCustomerById);
  const [weekViewType, setWeekViewType] = useRecoilState(
    calendarWeekViewSelector
  );
  const newTaskExternalValue = useRecoilValue(isNewTaskExternal);
  const [filteredToday, setFilterToday] = useRecoilState(isFilterToday);
  const selectedEventAssignees = useRecoilValue(getSelectedEventAssignees);
  const [openLoneTaskValue, setOpenLoneTask] = useRecoilState(openLoneTask);

  const offersCount = useRecoilValue(
    getOffersCountByInterval({
      initialDate: DateTime.fromJSDate(selectedDateCalendar)
        .startOf(dateModifierFlag)
        .minus({ day: isFirstDaySunday(userSettings.first_day) })
        .toSeconds(),
      finalDate: DateTime.fromJSDate(selectedDateCalendar)
        .endOf(dateModifierFlag)
        .minus({ day: isFirstDaySunday(userSettings.first_day) })
        .toSeconds(),
    })
  );

  const classes = {
    unassigned: 'fc-event-unassigned',
    toDo: 'fc-event-to-do',
    inProgress: 'fc-event-in-progress',
    done: 'fc-event-completed',
    integration: 'fc-event-integrations',
    placeholder: 'fc-event-placeholder',
  };

  const internalEvents = useMemo(() => {
    const newEvents = !filterSet
      ? events
      : handleEventFilter(
          events,
          filteredStatus,
          filteredAssignees,
          filteredCustomers,
          filteredLocations,
          filteredToday,
          filteredTasks
        );

    if (filterSet && filteredToday && newEvents.length)
      setTimeout(() => setFilterToday(false), 2000);

    return newEvents;
  }, [
    events,
    resources,
    filterSet,
    filteredStatus,
    filteredAssignees,
    filteredCustomers,
    filteredLocations,
    filteredTasks,
    filteredToday,
  ]);

  useEffect(() => {
    if (!openLoneTaskValue) return;
    if (!internalEvents.length) return;
    if (internalEvents.length === 1) {
      const loneEvent = internalEvents[0];
      setDrawerEvent(loneEvent?.id);
    }
    setOpenLoneTask(false);
  }, [internalEvents, openLoneTaskValue]);

  useEffect(() => {
    return calendarRef.current.getApi().gotoDate(selectedDateCalendar);
  }, [selectedDateCalendar]);

  useEffect(() => {
    if (drawers.newTask === false) {
      const calendar: CalendarApi = calendarRef.current.getApi();
      const evtOld = calendar.getEventById(TaskStatusEnum.PLACEHOLDER);
      evtOld?.remove();
    }
  }, [drawers.newTask]);

  const handleWeekViewType = (value: any): void => {
    setWeekViewType(value);
  };

  const changeSelectedDate = (date: Date) => {
    setSelectedDateCalendar(date);
    setTimeout(() => {
      refetch();
    }, 300);
  };

  //calendar overall functions/handlers
  const handleNextClick = async () => {
    const calendar: CalendarApi = await calendarRef.current.getApi();
    if (isMonthView) calendar.next();
    else calendar.incrementDate({ days: 7 });
    setSelectedDateCalendar(
      isMonthView
        ? calendar.view.currentStart
        : calendar.getCurrentData().currentDate
    );
    refetch();
  };

  const handleBackClick = async () => {
    const calendar: CalendarApi = await calendarRef.current.getApi();
    if (isMonthView) calendar.prev();
    else calendar.incrementDate({ days: -7 });
    setSelectedDateCalendar(
      isMonthView
        ? calendar.view.currentStart
        : calendar.getCurrentData().currentDate
    );
    refetch();
  };

  const handleSetToday = async () => {
    const calendar: CalendarApi = await calendarRef.current.getApi();
    await calendar.today();
    calendar.incrementDate({ day: 1 });
    setSelectedDateCalendar(calendar.getCurrentData().currentDate);
    refetch();
  };

  const handleChangeView = (viewToChange: string) => {
    setCalendarView(viewToChange);
    return calendarRef.current.getApi().changeView(viewToChange);
  };

  const handleFilterClick = () => {
    return setDrawerOpen({
      isOpen: !drawers.filter,
      drawer: CalendarDrawerTypes.filter,
    });
  };

  //rendering event wrapper classes based on status
  const renderEventClass = (args: EventContentArg) => {
    const state = args.event.extendedProps.status;
    const isOffer = args.event.extendedProps.isOffer;
    const classNames = [classes[isOffer ? 'integration' : state]];

    if (eventId === args.event.extendedProps.id) {
      classNames.push('dragging');
    }

    if (offerId === args.event.extendedProps.id) {
      classNames.push('dragging');
    }

    return classNames;
  };

  //creating placeholder event

  useEffect(() => {
    if (!newTaskExternalValue) return;
    const firstAssigneeId =
      selectedEventAssignees.length > 0 ? selectedEventAssignees[0].id : '';
    createEventExternal(firstAssigneeId);
  }, [newTaskExternalValue]);

  const createEventExternal = async (resourceId) => {
    await createNewEvent({
      resourceId,
      date: new Date().toISOString().slice(0, 10),
    });
  };

  const createNewEvent = async ({ date, resourceId }): Promise<void> => {
    const calendar: CalendarApi = calendarRef.current.getApi();

    const evtOld = calendar.getEventById(TaskStatusEnum.PLACEHOLDER);
    evtOld?.remove();

    const evt = {
      date,
      title: '',
      resourceId,
      id: TaskStatusEnum.PLACEHOLDER,
      extendedProps: {
        status: TaskStatusEnum.PLACEHOLDER,
        time: '',
        assigneeNames: [],
      },
    };

    await calendar.addEvent(evt);

    openNewTask(date);
  };

  const createEventOnDateClick = async (
    dateClickInfo: DateClickArg
  ): Promise<void> => {
    setEventId(0);
    const calendar: CalendarApi = calendarRef.current.getApi();
    const evtOld = calendar.getEventById(TaskStatusEnum.PLACEHOLDER);
    evtOld?.remove();

    const resourceId =
      dateClickInfo.resource?.id !== undefined
        ? Number(dateClickInfo.resource?.id)
        : 0;

    if (isWeekViewTypeCustomer && resourceId !== 0) {
      setNewEventCustomer(resourceId);
    }

    if (isWeekViewTypeAssignee && resourceId !== 0) {
      addAssigneeById(resourceId);
    }

    const evt = {
      id: TaskStatusEnum.PLACEHOLDER,
      title: '',
      date: dateClickInfo.dateStr,
      resourceId:
        dateClickInfo.resource !== undefined
          ? dateClickInfo.resource._resource.id
          : '',
      extendedProps: {
        status: TaskStatusEnum.PLACEHOLDER,
        time: '',
        assigneeNames: [],
      },
    };
    await calendar.addEvent(evt);
    openNewTask(dateClickInfo.dateStr);
  };

  const handleResources = (successCallback: (events: any) => void) => {
    successCallback(resources);
  };

  const callModalAlert = (
    type: 'repeat' | 'integrated' | 'turnoverbnb' | 'inProgress',
    id: number
  ) => {
    refetch();
    setDrawerEvent(id);
    setupModal({
      content: <TkModalAlert type={type} handleCloseModal={handleCloseModal} />,
    });
  };

  const handleUpdateCalendar = (notify: boolean, info: EventDropArg) => {
    const extendedProps = info.event.extendedProps;

    setDropEvent({
      extendedProps,
      start: info.event.start,
      end: DateTime.fromJSDate(info.event.end).minus({ day: 1 }).toJSDate(),
      calendarApi: calendarRef.current.getApi(),
      oldResource: info.oldResource?.id,
      newResource: info.newResource?.id,
      notify,
    });
  };

  const handleModifyEvent = (notify: boolean, info: EventDropArg) => {
    handleCloseModal();
    handleUpdateCalendar(notify, info);
  };

  const weekViewSlotLabelFormatter = (date: Date): string => {
    const { day, weekDayToken } = getLocaleTokenWeekFromDate(date);
    return `${t(weekDayToken, { ns: 'dates' }).slice(0, 3)} ${day}`;
  };

  //handling event drag-and-drop update function
  const handleEventDrop = async (info: EventDropArg) => {
    const eventId = info.event.extendedProps.id;
    const extendedProps = info.event.extendedProps;
    const assigneeIds: number[] = extendedProps.assigneeIds ?? [];

    const getAssigneeToNotify = (ids: number[]) => {
      const assigneesTask: IAssignee[] = [];

      ids.forEach((id) => {
        assignees.find((assignee) => {
          if (assignee.id === id) {
            assigneesTask.push(assignee);
          }
        });
      });

      const newResource = info.newResource;

      if (
        isWeekViewTypeAssignee &&
        newResource !== undefined &&
        newResource !== null
      ) {
        assignees.find((assignee) => {
          const resourceId = Number(newResource.id);
          if (resourceId !== 0 && assignee.id === resourceId) {
            assigneesTask.push(assignee);
          }
        });
      }
      return assigneesTask;
    };

    const assigneesEvent = getAssigneeToNotify(assigneeIds);

    if (
      extendedProps.is_recurring === 1 &&
      isSameResource(info.newResource?.id, info.oldResource?.id)
    ) {
      callModalAlert('repeat', eventId);
      return;
    }

    if (
      extendedProps.status === TaskStatusEnum.IN_PROGRESS ||
      extendedProps.status === TaskStatusEnum.DONE
    ) {
      callModalAlert(TaskStatusEnum.IN_PROGRESS, eventId);
      return;
    }

    if (
      !isSameResource(info.newResource?.id, info.oldResource?.id) &&
      extendedProps.externalId !== null &&
      isWeekViewTypeCustomer
    ) {
      callModalAlert('turnoverbnb', eventId);
      return;
    }

    //TODO: TEST IF FROM EXTERNAL CALENDAR
    // if (
    //   isSameResource(info.newResource?.id, info.oldResource?.id) &&
    //   extendedProps.external_id !== null
    // ) {
    //   callModalAlert("integrated", eventId);
    //   return;
    // }

    if (
      !isSameResource(info.newResource?.id, info.oldResource?.id) &&
      isWeekViewTypeAssignee
    ) {
      setupModal({
        content: (
          <TkAssigneeAlertModal
            eventTitle={info.event.title}
            handleCloseModal={(notify) => handleModifyEvent(notify, info)}
            assignees={
              assigneesEvent.length === 0
                ? getAssigneeToNotify([Number(info.newResource?.id)])
                : assigneesEvent
            }
          />
        ),
        disableBackdropClick: true,
      });
    } else {
      handleModifyEvent(false, info);
    }
  };

  const setDropEvent = (data) => {
    //persisting time through date change
    let incomingStart = DateTime.fromJSDate(data.start);
    let incomingEnd = DateTime.fromJSDate(data.end);

    incomingEnd = incomingEnd.isValid ? incomingEnd : incomingStart;

    const currentTimeStart = data.extendedProps.start_time;
    const currentTimeEnd = data.extendedProps.end_time;

    incomingStart = incomingStart.set({
      hour: currentTimeStart.hour,
      minute: currentTimeStart.minute,
      second: currentTimeStart.second,
    });

    incomingEnd = incomingEnd.set({
      hour: currentTimeEnd.hour,
      minute: currentTimeEnd.minute,
      second: currentTimeEnd.second,
    });

    const dropData = {
      id: data.extendedProps.id,
      selectedStart: incomingStart.toSeconds(),
      selectedEnd: incomingEnd.toSeconds(),
      oldResource: data.oldResource,
      newResource: data.newResource,
      weekViewType: data.weekViewType,
      extendedProps: data.extendedProps,
      notify: data.notify,
    };

    if (data.extendedProps.externalId !== null) {
      setupModal({
        content: (
          <TkModalAlert
            handleCloseModal={handleCloseModal}
            type="wontReflectOnTurnoverBNB"
          />
        ),
      });
    }

    try {
      if (isMonthView) {
        updateEvent.mutate(dropData);
      } else {
        updateResourceEvent.mutate(dropData);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleResizeEvent = (info) => {
    const extendedProps = info.event.extendedProps;
    const calendarApi = calendarRef.current.getApi();
    //TODO: updating internal calendar before refetching to avoid "snap back" bug on calendar
  };

  //rendering navigation bar after initialization to avoid errors
  const renderNavigationAfterInit = () => {
    return (
      <Grid container>
        <Grid container>
          <Grid
            container
            item
            xs={8}
            alignItems="center"
            justify="flex-start"
            spacing={6}
          >
            <Grid item>
              <DynamicButton
                onClick={() => handleSetToday()}
                variant="outlined"
                color="primary"
              >
                {t('today', { ns: 'common' })}
              </DynamicButton>
            </Grid>
            <GridDatePickerWrapper item>
              <TkDatePicker
                startDate={selectedDateCalendar}
                weekly={isWeekView}
                handleDateChange={changeSelectedDate}
                activator={
                  <CalendarDatePickerActivator gridView={calendarView} />
                }
              />
            </GridDatePickerWrapper>
            <Box paddingRight={0}>
              <Grid item>
                <IconButton onClick={handleBackClick}>
                  <TkIcon className="fas fa-chevron-left" color="navyBlue" />
                </IconButton>
              </Grid>
            </Box>
            <Box paddingLeft={0}>
              <Grid item>
                <IconButton onClick={handleNextClick}>
                  <TkIcon className="fas fa-chevron-right" color="navyBlue" />
                </IconButton>
              </Grid>
            </Box>
            <Grid item>
              <DynamicButton
                onClick={handleFilterClick}
                variant={filterSet ? 'contained' : 'outlined'}
                color="primary"
                disableElevation
                startIcon={
                  <TkIcon
                    className="fas fa-sliders-h"
                    iconSize={16}
                    color={filterSet ? '#ffffff' : 'primary'}
                  />
                }
              >
                {t('filter', { ns: 'calendar' })}
              </DynamicButton>
              {filterSet ? (
                <DynamicButton
                  onClick={resetFilterData}
                  variant="outlined"
                  color="primary"
                  style={{ marginLeft: '10px' }}
                >
                  {t('clearFilters', { ns: 'calendar' })}
                </DynamicButton>
              ) : null}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={4}
            alignItems="center"
            justify="space-between"
          >
            <Grid xs={6} container item justify="space-between">
              {!collapsed ? (
                <ChangeViewButtonGroup variant="text">
                  <ChangeViewButton
                    onClick={() =>
                      handleChangeView(ICalendarViewEnum.WEEK_VIEW)
                    }
                    color="primary"
                    selected={isWeekView}
                  >
                    {t('week', { ns: 'common' })}
                  </ChangeViewButton>
                  <ChangeViewButton
                    onClick={() =>
                      handleChangeView(ICalendarViewEnum.MONTH_VIEW)
                    }
                    color="primary"
                    selected={isMonthView}
                  >
                    {t('month', { ns: 'common' })}
                  </ChangeViewButton>
                </ChangeViewButtonGroup>
              ) : (
                <TkFormControl variant="outlined">
                  <TkSelect
                    native
                    value={calendarView}
                    onChange={(event) =>
                      handleChangeView(event.currentTarget.value as string)
                    }
                  >
                    <option value={ICalendarViewEnum.WEEK_VIEW}>
                      {t('week', { ns: 'common' })}
                    </option>
                    <option value={ICalendarViewEnum.MONTH_VIEW}>
                      {t('month', { ns: 'common' })}
                    </option>
                  </TkSelect>
                </TkFormControl>
              )}
            </Grid>
            <Grid xs={6} container item justify="flex-end">
              <SettingsButton
                onClick={() =>
                  setupModal({
                    content: (
                      <SettingsModal handleCloseModal={handleCloseModal} />
                    ),
                  })
                }
                color="navyBlue"
                startIcon={<TkIcon className="fas fa-cog" color="navyBlue" />}
                endIcon={
                  <TkIcon className="fas fa-angle-down" color="navyBlue" />
                }
              >
                {!collapsed ? t('settings', { ns: 'common' }) : ''}
              </SettingsButton>
            </Grid>
          </Grid>
        </Grid>
        {filterSet ? <FilterHeader /> : null}
      </Grid>
    );
  };

  const renderResourceContent = (arg: ResourceLabelContentArg) => {
    const resource = arg.resource._resource;
    const resourceTaskCount = resource.extendedProps.count;

    if (isWeekViewTypeCustomer && Number(resource.id) === 0) {
      return (
        <Grid container direction="column">
          <ResourceHeaderContent>
            <ResourceHeaderTitle>{resource.title}</ResourceHeaderTitle>
            <ResourceHeaderTask>
              <Grid>
                <strong>
                  {resourceTaskCount === 0
                    ? t('no', { ns: 'common' })
                    : resourceTaskCount}
                </strong>
              </Grid>
              <Grid>
                {resourceTaskCount === 1
                  ? t('task.task_singular', { ns: 'app' })
                  : t('task.tasks_plural', { ns: 'app' })}
              </Grid>
            </ResourceHeaderTask>
            <ResourceHeaderTask>
              <Grid>
                <strong>
                  {offersCount === 0 ? t('no', { ns: 'common' }) : offersCount}
                </strong>
              </Grid>
              <Grid>
                {offersCount === 1
                  ? t('requests_singular', { ns: 'offers' })
                  : t('requests_plural', { ns: 'offers' })}
              </Grid>
            </ResourceHeaderTask>
          </ResourceHeaderContent>
          {/* <ResourceHeaderButtonWrapper>
            <ResourceHeaderButtonItem>
              <TkTypography
                fontFamily="Lato"
                fontSize={14}
                fontWeight="bold"
                color="primary"
                style={{ cursor: "pointer" }}
              >
                + {t("customer", { ns: "common" })}
              </TkTypography>
            </ResourceHeaderButtonItem>
            <ResourceHeaderButtonItem>
              <TkTypography
                fontFamily="Lato"
                fontSize={14}
                fontWeight="bold"
                color="primary"
                style={{ cursor: "pointer" }}
              >
                + {t("location", { ns: "common" })}
              </TkTypography>
            </ResourceHeaderButtonItem>
          </ResourceHeaderButtonWrapper> */}
        </Grid>
      );
    }

    if (isWeekViewTypeAssignee && Number(resource.id) === 0) {
      return (
        <ResourceHeaderContent>
          <ResourceHeaderTitle>
            <IconNotAssignedButton>
              <TkIcon className="fa fa-question" iconSize={19} />
            </IconNotAssignedButton>
            <p>{resource.title}</p>
          </ResourceHeaderTitle>
          <ResourceHeaderTask>
            <Grid>
              <strong>
                {resourceTaskCount === 0
                  ? t('no', { ns: 'common' })
                  : resourceTaskCount}
              </strong>
            </Grid>
            <Grid>
              {resourceTaskCount === 1
                ? t('task.task_singular')
                : t('task.tasks_plural')}
            </Grid>
          </ResourceHeaderTask>

          <ResourceHeaderTask>
            <Grid>
              <strong>
                {offersCount === 0 ? t('no', { ns: 'common' }) : offersCount}
              </strong>
            </Grid>
            <Grid>
              {offersCount === 1
                ? t('requests_singular', { ns: 'offers' })
                : t('requests_plural', { ns: 'offers' })}
            </Grid>
          </ResourceHeaderTask>
        </ResourceHeaderContent>
      );
    }

    return (
      <ResourceHeaderContent>
        <ResourceHeaderTitle>
          {resource.extendedProps.photo_url !== null ? (
            <ImgAssignee src={resource.extendedProps.photo_url} />
          ) : (
            <IconAssigneeButton>
              {generateInitials(resource.title)}
            </IconAssigneeButton>
          )}
          <p>{resource.title}</p>
        </ResourceHeaderTitle>
        <ResourceHeaderTask>
          <Grid>
            <strong>
              {resourceTaskCount === 0
                ? t('no', { ns: 'common' })
                : resourceTaskCount}
            </strong>
          </Grid>
          <Grid>
            {resourceTaskCount === 1
              ? t('task.task_singular')
              : t('task.tasks_plural')}
          </Grid>
        </ResourceHeaderTask>
      </ResourceHeaderContent>
    );
  };

  const resourceHeader = (
    <WeekViewHeaderWrapper>
      <WeekViewHeaderItem style={{ marginRight: '10px' }}>
        {t('viewBy', { ns: 'calendar' })}
      </WeekViewHeaderItem>
      <WeekViewHeaderItem>
        <FormControl className={styleClasses.formControl}>
          <Select
            native
            fullWidth
            color="primary"
            value={weekViewType}
            name="weekType"
            className={styleClasses.selectEmpty}
            onChange={(e) => handleWeekViewType(e.target.value)}
          >
            <option value={WeekViewTypesEnum.CUSTOMER}>
              {t('customers', { ns: 'common' })}
            </option>
            <option value={WeekViewTypesEnum.ASSIGNEE}>
              {t('teammates', { ns: 'common' })}
            </option>
          </Select>
        </FormControl>
      </WeekViewHeaderItem>
    </WeekViewHeaderWrapper>
  );

  return (
    <FullCalendarWrapper
      collapsed={collapsed}
      gridView={calendarView}
      style={{ overflow: 'scroll' }}
    >
      <FullCalendarWrapperHeader>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
        >
          {calendarRef.current !== null ? renderNavigationAfterInit() : ''}
        </Grid>
      </FullCalendarWrapperHeader>
      <FullCalendar
        ref={calendarRef}
        plugins={[
          dayGridPlugin,
          timeGridPlugin,
          resourceTimelinePlugin,
          interactionPlugin,
        ]}
        resourceAreaHeaderContent={resourceHeader}
        resourceLabelContent={renderResourceContent}
        editable
        eventDragMinDistance={1}
        initialView={calendarView}
        eventOrder={(a: any, b: any) => {
          const startA = a.extendedProps.start_time_epoch;
          const startB = b.extendedProps.start_time_epoch;
          return startA > startB ? 1 : -1;
        }}
        initialDate={selectedDateCalendar}
        firstDay={userSettings.first_day === 'sunday' ? 0 : 1}
        weekNumberCalculation="local"
        dayMaxEvents={userSettings.collapse_projects ? 3 : false}
        dateClick={createEventOnDateClick}
        fixedWeekCount={false}
        eventClick={(info) => {
          calendarRef.current
            .getApi()
            .getEventById(TaskStatusEnum.PLACEHOLDER)
            ?.remove();

          setDrawerEvent(
            info.event.extendedProps.id,
            info.event.extendedProps.isOffer
          );
        }}
        eventDrop={(info) => {
          handleEventDrop(info);
        }}
        dayPopoverFormat={{ weekday: 'long', month: 'short', day: 'numeric' }}
        moreLinkContent={(arg) => {
          return <MoreLinks>{arg.text.replace('+', 'And ')}</MoreLinks>;
        }}
        height={filterSet ? '79.5vh' : '84.5vh'}
        schedulerLicenseKey={process.env.REACT_APP_SCHEDULER_KEY}
        views={{
          resourceWeekly: {
            type: 'resourceTimeline',
            duration: { weeks: 1 },
            slotDuration: { days: 1 },
            slotLabelInterval: { day: 1 },
            slotLabelFormat: (date) =>
              weekViewSlotLabelFormatter(date.date.marker),
          },
        }}
        viewDidMount={(info: any) => {
          if (info.view.type !== calendarView) {
            setCalendarView(info.view.type);
          }
        }}
        events={(_info, successCallback) => {
          successCallback(internalEvents);
        }}
        resourceOrder="order,title"
        resources={(_info, successCallback) => handleResources(successCallback)}
        eventContent={(eventInfo) => <TkPillContent eventInfo={eventInfo} />}
        eventClassNames={renderEventClass}
        datesSet={() => null}
        headerToolbar={false}
        {...props}
      />
      <TkLoadingBackground loading={isFetching} />
    </FullCalendarWrapper>
  );
};

export default TkMainCalendar;
