import { useState } from 'react';
import {
  selectedCrewIdState,
  getUserAccountId,
  checkedMembersState,
} from '@recoilData/index';
import api from '@services/api';
import { useMutation, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { useSnackbar } from '@components/TkSnackbar/useSnackbar';
import { useTranslation } from 'react-i18next';

type CrewEdit = {
  name: string;
  members_ids: number[];
};

export const useEditCrew = () => {
  const [edited, setEdited] = useState(false);
  const accountId = useRecoilValue(getUserAccountId);
  const crewId = useRecoilValue(selectedCrewIdState);
  const members = useRecoilValue(checkedMembersState);
  const { fireTSuccess } = useSnackbar();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(
    (values: CrewEdit) =>
      api.put(`account/${accountId}/crew/${crewId}`, values),
    {
      onSuccess: () => {
        fireTSuccess('changes_successfully_saved', { ns: 'common' });
        queryClient.invalidateQueries(['getCrew', accountId, crewId]);
        queryClient.invalidateQueries(['accountCrews', accountId]);
        setEdited(false);
      },
    }
  );

  const editCrew = (name?: string) => {
    mutate({ name, members_ids: members.map((member) => member.id) });
  };

  return {
    setEdited,
    edited,
    editCrew,
    isLoading,
  };
};
