import React from 'react';

import { useTranslation } from 'react-i18next';
import { ComponentWithChildren } from 'types/common.type';

import { Grid } from '@mui/material';

import { Container } from './styles';
import { IOptionStruct } from '../TkWelcomeSetupModal/types.type';
import { IOptionsSelect } from '@components/TkModalComponents/types.type';
import { TkTypography, SelectableOption } from '@components/index';

interface ChosenOptionWrapperProps extends ComponentWithChildren {
  name: string;
  options: Record<string, IOptionStruct>;
  selectedOption: IOptionsSelect;
}

const ChosenOptionWrapper = ({
  name,
  options,
  children,
  selectedOption,
}: ChosenOptionWrapperProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container direction="column">
      <TkTypography
        textAlign="center"
        fontSize="24px"
        fontWeight="bold"
        fontFamily="Muli"
        margin="20px 0 10px"
      >
        {t('welcomeSetupModal.welcomeToTaskbirdName', { name: name })}
      </TkTypography>
      <Grid container direction="column" justifyContent="center">
        <SelectableOption
          option={options[selectedOption]}
          selectedOption={selectedOption}
        />
        {children}
      </Grid>
    </Container>
  );
};

export default ChosenOptionWrapper;
