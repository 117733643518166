import React, { useState } from "react";
import { apiMethods } from "@services/index";
import { useTranslation } from "react-i18next";
import { TkPhoneInputNumberWithFlag, TextMeButton } from "@components/index";
import { useSnackbar } from "@components/TkSnackbar/useSnackbar";
import { SendMeTheAppWrapper } from "./styles";
import { useReCaptchaVerify } from '@hooks/index';

interface ITkSendMeTheApp {
  showLabel?: boolean;
  value?: string | null;
  successToken?: string;
}

const TkSendMeTheApp: React.FC<ITkSendMeTheApp> = ({
  value,
  showLabel = true,
  successToken = undefined,
}) => {
  const { t } = useTranslation();
  const { sendMeTheApp } = apiMethods;
  const { fireTSuccess, fireTError } = useSnackbar();
  const handleReCaptchaVerify = useReCaptchaVerify();
  const [phoneNumber, setPhoneNumber] = useState<string>(value || "+1");

  const sendToApp = async () => {
    const recaptcha = await handleReCaptchaVerify('sendTheAppToSmS');

    if (!recaptcha) return;

    sendMeTheApp(phoneNumber, recaptcha)
      .then((data) => {
        if (data.kind === "ok") {
          fireTSuccess(data.token, {});
          return;
        }
        fireTError(data.token, {});
      });
  };

  return (
    <SendMeTheAppWrapper direction={"column"}>
      {showLabel ? <span>{t("phone_number", { ns: "common" })}</span> : null}
      <TkPhoneInputNumberWithFlag
        mt={2.5}
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        Button={() =>
          <div>
            <TextMeButton
              variant="contained"
              bgcolor="primary"
              onClick={sendToApp}
            >
              {t("sendMeTheApp", { ns: "common" })}
            </TextMeButton>
          </div>
        }
      />
    </SendMeTheAppWrapper>
  );
};

export default TkSendMeTheApp;
