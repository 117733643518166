import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import { RightImageContainer, Step6Subtitle } from './styles';
import {
  WebOnboardingStep1,
  WebOnboardingStep2,
  WebOnboardingStep3,
  WebOnboardingStep4,
  WebOnboardingStep5,
  WebOnboardingStep6,
} from '@assets/index';
import { DynamicButton, TkStepperModal, TkTypography } from '@components/index';
import { MIXPANEL_EVENTS, useMixpanel, useModal } from '@contexts/index';
import { useUserSettings } from '@hooks/index';

const TkWebOnboarding = (): JSX.Element => {
  const { t } = useTranslation();
  const { resumeModalQueue } = useModal();
  const { handleSettingsUpdate } = useUserSettings();

  const { track } = useMixpanel();

  const dismissModal = (isGetStarted = false) => {
    if (isGetStarted !== true) {
      track(MIXPANEL_EVENTS.ONBOARDING_WELCOME_GUIDE_SKIP);
    }
    track(MIXPANEL_EVENTS.FINISHED_ONBOARDING);
    handleSettingsUpdate({
      show_onboarding: false,
      show_booking_onboarding: false,
    });
    resumeModalQueue();
  };

  const rightContentGenerator = (
    image: string,
    alt: string,
    placement = 'center'
  ) => (
    <RightImageContainer style={{ alignItems: placement }}>
      <img src={image} alt={alt} />
    </RightImageContainer>
  );

  const contentGenerator = (message: string) => (
    <Grid container>
      <TkTypography fontSize="16px" color="#3D4465" marginRight="10px">
        •
      </TkTypography>
      <TkTypography fontSize="16px" style={{ maxWidth: '90%' }}>
        {message}
      </TkTypography>
    </Grid>
  );

  const skipNextCombo = (handlers) => (
    <Grid container justifyContent="flex-end">
      <DynamicButton
        color="primary"
        style={{ marginRight: '20px' }}
        onClick={dismissModal}
      >
        {t('skip', { ns: 'common' })}
      </DynamicButton>
      <DynamicButton
        bgcolor="primary"
        variant="contained"
        onClick={handlers.handleNext}
      >
        {t('next', { ns: 'common' })}
      </DynamicButton>
    </Grid>
  );

  const step6TitleGenerator = (fullTitle) => {
    const [title, subTitle] = fullTitle.replace('(', ',(').split(',');
    return (
      <TkTypography fontFamily="Lato" fontWeight="bold" fontSize={20}>
        {title}
        <Step6Subtitle>{subTitle}</Step6Subtitle>
      </TkTypography>
    );
  };

  const steps: StepContent[] = [
    {
      title: t('step1.title', { ns: 'webOnboarding' }),
      content: contentGenerator(t('step1.message', { ns: 'webOnboarding' })),
      actions: (handlers) => skipNextCombo(handlers),
      rightContent: rightContentGenerator(
        WebOnboardingStep1,
        'welcomeGuideStep1.svg'
      ),
    },
    {
      title: t('step2.title', { ns: 'webOnboarding' }),
      content: contentGenerator(t('step2.message', { ns: 'webOnboarding' })),
      actions: (handlers) => skipNextCombo(handlers),
      rightContent: rightContentGenerator(
        WebOnboardingStep2,
        'welcomeGuideStep2.svg'
      ),
    },
    {
      title: t('step3.title', { ns: 'webOnboarding' }),
      content: contentGenerator(t('step3.message', { ns: 'webOnboarding' })),
      actions: (handlers) => skipNextCombo(handlers),
      rightContent: rightContentGenerator(
        WebOnboardingStep3,
        'welcomeGuideStep3.svg',
        'flex-end'
      ),
    },
    {
      title: t('step4.title', { ns: 'webOnboarding' }),
      content: contentGenerator(t('step4.message', { ns: 'webOnboarding' })),
      actions: (handlers) => skipNextCombo(handlers),
      rightContent: rightContentGenerator(
        WebOnboardingStep4,
        'welcomeGuideStep4.svg'
      ),
    },
    {
      title: t('step5.title', { ns: 'webOnboarding' }),
      content: contentGenerator(t('step5.message', { ns: 'webOnboarding' })),
      actions: (handlers) => skipNextCombo(handlers),
      rightContent: rightContentGenerator(
        WebOnboardingStep5,
        'welcomeGuideStep5.svg'
      ),
    },
    {
      // @ts-ignore
      title: step6TitleGenerator(t('step6.title', { ns: 'webOnboarding' })),
      content: contentGenerator(t('step6.message', { ns: 'webOnboarding' })),
      // eslint-disable-next-line react/display-name
      actions: () => (
        <Grid container justifyContent="flex-end">
          <DynamicButton
            bgcolor="primary"
            variant="contained"
            onClick={() => dismissModal(true)}
          >
            {t('get_started', { ns: 'common' })}!
          </DynamicButton>
        </Grid>
      ),
      rightContent: rightContentGenerator(
        WebOnboardingStep6,
        'welcomeGuideStep6.svg',
        'flex-end'
      ),
    },
  ];

  return (
    <TkStepperModal
      steps={steps}
      handleCloseModal={dismissModal}
      contentPadding={{ left: 0 }}
      hideDivider
    />
  );
};

export default TkWebOnboarding;
