import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { FormControl, Grid } from '@mui/material';

import {
  TkListWrapper,
  TkExtendedWrapper,
  FullWrapper,
  FullWrapperItem,
  FullWrapperIcon,
} from '../styles';
import {
  TkCustomerListItem,
  TkExtendedDrawerSearch,
} from '@components/ExtendedDrawers/index';
import { TkTypography, TkIcon } from '@components/index';
import {
  editEventCustomer,
  editEventLocation,
  searchCustomersData,
} from '@recoilData/index';

const TkCustomerList = (): JSX.Element => {
  const { t } = useTranslation(['events']);
  const [search, setSearch] = useState('');
  const customersList = useRecoilValue(searchCustomersData(search));

  return (
    <>
      <TkExtendedWrapper>
        {customersList.length === 0 && !search ? (
          <FullWrapper>
            <FullWrapperItem
              container
              justifyContent="center"
              alignItems="center"
            >
              <FullWrapperIcon
                className="fa fa-user-tie"
                iconSize={80}
                color="#999999"
              />
            </FullWrapperItem>
            <FullWrapperItem
              container
              justifyContent="center"
              alignItems="center"
            >
              <TkTypography
                fontFamily="Muli"
                fontSize={16}
                fontWeight="bold"
                color="default"
                textAlign="center"
              >
                {t('customersExtendedDrawer.emptyTittle')}
              </TkTypography>
            </FullWrapperItem>
            {/* <FullWrapperItem container justify="center" alignItems="center">
              <DynamicButton
                variant="outlined"
                color="primary"
                disableElevation
              >
                <TkTypography
                  fontWeight="normal"
                  fontFamily="Lato"
                  fontSize={16}
                >
                  Add a New Customer
                </TkTypography>
              </DynamicButton>
            </FullWrapperItem> */}
          </FullWrapper>
        ) : (
          <>
            <FormControl>
              <TkTypography fontWeight="bold" fontFamily="Muli" fontSize={16}>
                {t('selectACustomer')}
              </TkTypography>
              <Grid container alignItems="flex-end">
                <Grid item>
                  <TkIcon className="fa fa-search" color="#4952A9" />
                </Grid>
                <Grid item sm={10}>
                  <TkExtendedDrawerSearch
                    search={search}
                    setSearch={setSearch}
                  />
                </Grid>
              </Grid>
            </FormControl>
            <TkListWrapper>
              {customersList.map((customer: ICustomerTask) => (
                <TkCustomerListItem
                  key={customer.id}
                  eventType="edit"
                  customer={customer}
                  eventCustomerRecoilState={editEventCustomer}
                  eventLocationRecoilState={editEventLocation}
                />
              ))}
            </TkListWrapper>
          </>
        )}
      </TkExtendedWrapper>
      {/* {customersList.length === 0 ? (
        ""
      ) : (
        <TkTaskActions>
          <DynamicButton
            variant="outlined"
            color="primary"
            disableElevation
            fullWidth
          >
            <TkTypography fontWeight="normal" fontFamily="Lato" fontSize={16}>
              Add a New Customer
            </TkTypography>
          </DynamicButton>
        </TkTaskActions>
      )} */}
    </>
  );
};

export default TkCustomerList;
