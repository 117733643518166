import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { Box, Grid } from '@mui/material';

import {
  ImgAssignee,
  TkCircleIcon,
  DeleteIconAssignee,
  IconAssigneeButton,
  AssigneeSelectedButton,
} from './styles';
import { TkTypography, TkIcon, TkPulsatingIcon } from '@components/index';
import { TaskStatusEnum, EventTypeEnum } from '@consts/index';
import { generateInitials, getTimeFromEpoch } from '@helpers/index';
import { editEventAssignees, getEventStatus } from '@recoilData/index';

interface ITkDrawerAssigneeSelectedListItem {
  assignee: IAssignee;
  type: `${EventTypeEnum}`;
}

const TkDrawerAssigneeSelectedListItem: React.FC<
  ITkDrawerAssigneeSelectedListItem
> = ({ type, assignee }) => {
  const { t } = useTranslation();
  const status = useRecoilValue(getEventStatus);
  const removeEditEventAssignee = useResetRecoilState(
    editEventAssignees(assignee.id)
  );

  const RenderNewContent = (
    <TkTypography
      fontSize={12}
      fontFamily="Lato"
      fontWeight="normal"
      color="rgba(1,31,65,0.8)"
    >
      {assignee.taskCountForToday !== undefined &&
      assignee.taskCountForToday > 0 ? (
        <>
          <TkIcon
            width={20}
            iconSize={14}
            marginRight={1}
            className="fa fa-exclamation-triangle"
            color="#011F41"
            fontSize="inherit"
            verticalAlign="text-top"
          />
          <Trans
            i18nKey="task.x_tasks_today"
            count={assignee.taskCountForToday}
          >
            {{ count: assignee.taskCountForToday }} Tasks Today
          </Trans>
        </>
      ) : (
        t('task.no_task_today')
      )}
    </TkTypography>
  );

  const RenderEditContent = (
    <Box display="flex" alignItems="center">
      {assignee.assignmentStatus === TaskStatusEnum.TODO && (
        <>
          <TkCircleIcon color="#fd3b2f" />
          <TkTypography
            fontSize={14}
            fontFamily="Lato"
            fontWeight="bold"
            color="rgba(1,31,65)"
            display="inline"
            marginLeft="5px"
          >
            {t('notStarted', { ns: 'events' })}
          </TkTypography>
        </>
      )}

      {assignee.assignmentStatus === TaskStatusEnum.IN_PROGRESS && (
        <>
          <TkPulsatingIcon type="small" />
          <TkTypography
            fontSize={14}
            fontFamily="Lato"
            fontWeight="bold"
            color="rgba(1,31,65)"
            display="inline"
            marginLeft="5px"
          >
            {t('inProgress', { ns: 'common' })}
          </TkTypography>
          {assignee.start_time_epoch && (
            <TkTypography
              fontSize={14}
              fontFamily="Lato"
              color="rgba(1,31,65)"
              display="inline"
              marginLeft="5px"
            >
              {`${getTimeFromEpoch(assignee?.start_time_epoch)} - ...`}
            </TkTypography>
          )}
        </>
      )}
      {assignee.assignmentStatus === TaskStatusEnum.DONE && (
        <>
          <TkCircleIcon color="#2DA867" />
          <TkTypography
            fontSize={14}
            fontFamily="Lato"
            fontWeight="bold"
            color="rgba(1,31,65)"
            display="inline"
            marginLeft="5px"
          >
            {t('completed', { ns: 'common' })}
          </TkTypography>
          {assignee.start_time_epoch && assignee.end_time_epoch && (
            <TkTypography
              fontSize={14}
              fontFamily="Lato"
              color="rgba(1,31,65)"
              display="inline"
              marginLeft="5px"
            >
              {`${getTimeFromEpoch(
                assignee?.start_time_epoch
              )} - ${getTimeFromEpoch(assignee?.end_time_epoch)}`}
            </TkTypography>
          )}
        </>
      )}
    </Box>
  );

  return (
    <AssigneeSelectedButton container justifyContent="space-between">
      <Box
        mr={1}
        display="flex"
        flexDirection="row"
        width="90%"
        alignItems="center"
      >
        <Grid>
          {assignee.photo_url ? (
            <ImgAssignee src={assignee.photo_url} />
          ) : (
            <IconAssigneeButton>
              {generateInitials(assignee.full_name)}
            </IconAssigneeButton>
          )}
        </Grid>
        <Grid item direction="column">
          <TkTypography
            fontWeight="bold"
            fontFamily="Lato"
            fontSize={14}
            color="default"
          >
            {assignee.full_name}
          </TkTypography>
          {type === EventTypeEnum.NEW && RenderNewContent}
          {type === EventTypeEnum.EDIT && RenderEditContent}
        </Grid>
      </Box>
      {(type === EventTypeEnum.NEW || status !== TaskStatusEnum.DONE) && (
        <DeleteIconAssignee>
          <TkIcon
            onClick={() => removeEditEventAssignee()}
            className="fa fa-trash"
            color="#011F41"
            cursor="pointer"
          />
        </DeleteIconAssignee>
      )}
    </AssigneeSelectedButton>
  );
};

export default React.memo(TkDrawerAssigneeSelectedListItem);
