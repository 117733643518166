type AdditionalInfoItem = {
  name: string;
  value?: number | boolean;
};

type AdditionalInfoSection = AdditionalInfoItem[];
type AdditionalInfoItems = Array<AdditionalInfoSection>;

interface AdditionalItemsData {
  restItemsCount: number;
  items: AdditionalInfoItems;
  firstSection: AdditionalInfoSection;
}

const getLocationInfo = (offer: IProjectOffer | IEvent, name: string) => {
  const location = {
    name,
    value: null,
  };
  if (offer.location) {
    location.value = offer.location[name] ?? null;
  }

  return location;
};

export const getOfferAdditionalItems = (
  offer: IProjectOffer | IEvent,
  isAdditionalInfoDrawer = true
): AdditionalItemsData => {
  let turnoAdditionalInfoItems: AdditionalInfoSection[] = [];

  if (
    // @ts-ignore
    offer?.projectOfferSystemNotes?.guestArrivesSameDay ||
    offer?.systemNotes?.guestArrivesSameDay
  ) {
    turnoAdditionalInfoItems = [
      [
        {
          name: 'guest_arrives_same_day',
          value: true,
        },
      ],
    ];
  }

  const fixedItems = [
    ['number_of_bedrooms', 'number_of_bathrooms'],
    ['size'],
    // ['next_booking_info', 'next_guest_name'],
  ].map((item): AdditionalInfoSection => {
    return item.map((subItem) => getLocationInfo(offer, subItem));
  });

  let items: AdditionalInfoItems = [...fixedItems, ...turnoAdditionalInfoItems];
  if (isAdditionalInfoDrawer) {
    items = [...turnoAdditionalInfoItems, ...fixedItems];
  }

  const firstSection = items[0];
  const filteredItems = items.flat().filter((item) => {
    return item.value !== null && item.value !== undefined;
  });
  const restItemsCount = filteredItems.length - firstSection.length;

  return {
    items,
    firstSection,
    restItemsCount,
  };
};
