import styled from "styled-components";
import { Grid } from "@material-ui/core";

export const TimeItem = styled(Grid)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  .react-datepicker-popper {
    ${(props) =>
      props.id === "datepicker-left"
        ? "margin: 30px 0 0 10px;"
        : "margin: 30px 0 0 0;"};
    ${(props) =>
      props.id === "datepicker-right"
        ? "transform: translate3d(150px, 106px, 0px) !important;"
        : ""};
    ${(props) =>
      props.id === "datepicker-hour-left"
        ? "transform: translate3d(60px, 106px, 0px) !important;"
        : ""};
    ${(props) =>
      props.id === "datepicker-hour-right"
        ? "transform: translate3d(225px, 106px, 0px) !important;"
        : ""};
  }
`;
