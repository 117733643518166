import { Grid } from '@mui/material';
import PhoneInput from 'react-phone-input-2';
import styled from 'styled-components';

export const TkSelectFlag = styled(PhoneInput)``;

export const PhoneNumberInput = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const PhoneNumberTextSelect = styled(Grid)`
  position: relative;
  &::after {
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    border-bottom: 1px solid #cacaca;
    transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .inputStyle,
  .buttonStyle {
    border-radius: 0px;
    border: none !important;
  }

  :has(.inputStyle.invalid-number)::after {
    border-bottom-color: #ff3b30 !important;
  }

  .countryList {
    position: fixed;
    margin: 0 0 10px 0;
    box-shadow: none;

    &:first-child {
      margin-top: 10px;
    }
  }
`;
