import styled from "styled-components";
import { getColorFromTheme } from "@helpers/index";
import { Alert } from "@material-ui/lab";

const TkAlert = styled(Alert)`
  color: #fff;
  .MuiAlert-icon {
    color: #fff;
  }
  &.MuiAlert-standardError {
    background-color: ${(props) =>
      props.severity === "error" &&
      getColorFromTheme(props.theme.palette, "error")};
  }
  &.MuiAlert-standardSuccess {
    background-color: ${(props) =>
      getColorFromTheme(props.theme.palette, "success")};
  }
  &.MuiAlert-standardWarning {
    background-color: ${(props) =>
      props.severity === "warning" &&
      getColorFromTheme(props.theme.palette, "warning")};
  }
  &.MuiAlert-standardInfo {
    background-color: ${(props) =>
      props.severity === "info" &&
      getColorFromTheme(props.theme.palette, "info")};
  }
`;

export default TkAlert;
