import { selector } from "recoil";
import {
  crewsData,
  assigneesData,
  editedAssignee,
  newAssigneeCrews,
  isAllMembersSelected
} from "@recoilData/index";

const removeSelectedAssignee = selector({
  key: "removeSelectedAssignee",
  get: ({ get }) => get(assigneesData),
  set: ({ set, get }, newValue) => {
    const assignees = get(assigneesData);
    const selectedAssignee = newValue as IAssignee;

    const newAssignees: IAssignee[] = [];

    assignees.forEach((assignee) => {
      if (selectedAssignee.id !== assignee.id) newAssignees.push(assignee);
    });

    set(assigneesData, newAssignees);
  },
});

const setEditedAssignee = selector<IAssignee>({
  key: "setEditedAssignee",
  get: ({ get }) => get(editedAssignee),
  set: ({ set, get, reset }, newValue) => {
    const assignee = newValue as IAssignee;
    const crews = get(crewsData);
    
    crews.forEach((crew) => {
      const itemIsFound = assignee.crews && assignee.crews.find((c) => c.id === crew.id);
      if (itemIsFound !== undefined) 
        set(newAssigneeCrews(crew.id), true);
      else 
        reset(newAssigneeCrews(crew.id));
    });

    // set(isAllCrewsMembersSelected, false);
    set(editedAssignee, newValue as IAssignee);
  },
});

const getSelectedNewAssigneeCrews = selector({
  key: "getSelectedNewAssigneeCrews",
  get: ({ get }) => {
    const crews = get(crewsData);
    const newCrews: ICrew[] = [];

    crews.forEach((crew) => {
      const crewSelected = get(newAssigneeCrews(crew.id));
      if (crewSelected)
        newCrews.push(generateCrew(crew, crewSelected));
    });

    return newCrews;
  },
});

const selectAllCrews = selector<boolean>({
  key: "selectAllCrews",
  get: ({ get }) => get(isAllMembersSelected),
  set: ({ set, get }, newValue) => {
    const crews = get(crewsData);

    set(isAllMembersSelected, newValue as boolean);

    crews.map((crew) => {
      set(newAssigneeCrews(crew.id), newValue as boolean);
    });
  },
});

const generateCrew = (crew: ICrew, checked: boolean) => {
  return {
    ...crew,
    checked
  };
};

export {
  selectAllCrews,
  setEditedAssignee,
  removeSelectedAssignee,
  getSelectedNewAssigneeCrews
}