import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { FormControl, Grid, FormGroup } from '@mui/material';

import {
  TkListWrapper,
  TkExtendedWrapper,
  TkListItem,
  FullWrapper,
  FullWrapperItem,
  FullWrapperIcon,
} from '../Drawers/TkEditTaskDrawer/styles';
import {
  TkChecklistListItem,
  TkExtendedDrawerSearch,
} from '@components/ExtendedDrawers';
import { TkIcon, TkTypography } from '@components/index';
import { EventTypeEnum } from '@consts/index';
import {
  eventChecklists,
  editEventChecklists,
  searchChecklistsData,
  getChecklistsDataCount,
} from '@recoilData/index';

interface ITkChecklistsList {
  eventType: `${EventTypeEnum}`;
}

const TkChecklistsList = ({ eventType }: ITkChecklistsList): JSX.Element => {
  const { t } = useTranslation(['events']);
  const [search, setSearch] = useState('');
  const checklistsList = useRecoilValue(searchChecklistsData(search));
  const checklistsDataCount = useRecoilValue(getChecklistsDataCount);

  return (
    <TkExtendedWrapper>
      {checklistsDataCount === 0 && !search ? (
        <FullWrapper>
          <FullWrapperItem
            container
            justifyContent="center"
            alignItems="center"
          >
            <FullWrapperIcon
              className="fal fa-clipboard-check"
              iconSize={80}
              color="#999999"
            />
          </FullWrapperItem>
          <FullWrapperItem
            container
            justifyContent="center"
            alignItems="center"
          >
            <TkTypography
              fontFamily="Muli"
              fontSize={16}
              fontWeight="bold"
              color="default"
              textAlign="center"
            >
              {t('checklistsExtendedDrawer.emptyTittle')}
            </TkTypography>
          </FullWrapperItem>
          <FullWrapperItem
            container
            justifyContent="center"
            alignItems="center"
          >
            <TkTypography
              fontFamily="Lato"
              fontSize={14}
              fontWeight="normal"
              color="primary"
              textAlign="center"
            >
              {t('checklistsExtendedDrawer.emptySubTittle')}
            </TkTypography>
          </FullWrapperItem>
        </FullWrapper>
      ) : (
        <>
          <FormControl>
            <TkTypography fontWeight="bold" fontFamily="Lato" fontSize={16}>
              {t('checklistsExtendedDrawer.selectChecklists')}
            </TkTypography>
            <Grid container alignItems="flex-end">
              <Grid item>
                <TkIcon className="fa fa-search" color="#4952A9" />
              </Grid>
              <Grid item sm={10}>
                <TkExtendedDrawerSearch search={search} setSearch={setSearch} />
              </Grid>
            </Grid>
          </FormControl>
          <TkListWrapper>
            <FormControl component="fieldset" style={{ display: 'flex' }}>
              <FormGroup>
                {checklistsList.map((checklist) => (
                  <TkChecklistListItem
                    key={checklist.id}
                    checklist={checklist}
                    eventChecklistsRecoilState={
                      eventType === EventTypeEnum.NEW
                        ? eventChecklists
                        : editEventChecklists
                    }
                  />
                ))}
                {checklistsDataCount === 0 && (
                  <TkListItem>
                    <Grid container item alignItems="center">
                      <TkTypography
                        fontWeight="bold"
                        fontFamily="Lato"
                        fontSize={16}
                      >
                        {t('noItems', { ns: 'common' })}
                      </TkTypography>
                    </Grid>
                  </TkListItem>
                )}
              </FormGroup>
            </FormControl>
          </TkListWrapper>
        </>
      )}
    </TkExtendedWrapper>
  );
};

export default TkChecklistsList;
