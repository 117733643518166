import React, { forwardRef } from 'react';

import { CustomContentProps, SnackbarContent } from 'notistack';

import CancelSharpIcon from '@mui/icons-material/CancelSharp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from '@mui/material';

import { CircularLoading } from '@features/projectOffers/components/circularLoading';

interface SnackProgressBarProps extends CustomContentProps {
  undoableTimeout?: number;
  colorByAction?: 'error' | 'success';
}

const CustomIconVariant = {
  error: <CheckCircleIcon sx={{ color: 'white' }} />,
  success: <CheckCircleIcon sx={{ color: 'white' }} />,
  requestError: <CancelSharpIcon sx={{ color: 'white' }} />,
};

const SnackProgressBar = forwardRef<HTMLDivElement, SnackProgressBarProps>(
  (
    {
      id,
      message,
      action: componentOrFunctionAction,
      undoableTimeout,
      colorByAction,
      hideIconVariant,
      style,
    },
    ref
  ) => {
    let action = componentOrFunctionAction;
    if (typeof action === 'function') {
      action = action(id);
    }

    return (
      <SnackbarContent ref={ref} style={style}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            position: 'absolute',
            zIndex: -1,
          }}
        >
          <CircularLoading
            undoTimeout={undoableTimeout}
            color={colorByAction}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '1rem',
            alignItems: 'center',
            flexGrow: 1,
            maxHeight: '2.5rem',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexGrow: 1,
            }}
          >
            {message}
            {!hideIconVariant && CustomIconVariant[colorByAction]}
          </Box>
          {action && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: 'auto',
                paddingLeft: '16px',
                marginRight: '-8px',
              }}
            >
              {action}
            </div>
          )}
        </Box>
      </SnackbarContent>
    );
  }
);

SnackProgressBar.displayName = 'SnackProgressBar';

export default SnackProgressBar;
