import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { TkListItem, TkListWrapper, TkExtendedWrapper } from '../styles';
import {
  TkCustomerListItem,
  TkExtendedDrawerSearch,
} from '@components/ExtendedDrawers/index';
import TkIcon from '@components/TkIcon';
import TkTypography from '@components/TkTypography';
import { FormControl, Grid } from '@material-ui/core';
import {
  eventCustomer,
  eventLocation,
  searchCustomersData,
  getCustomersDataCount,
} from '@recoilData/index';

const TkCustomerList = (): JSX.Element => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const customersList = useRecoilValue(searchCustomersData(search));
  const customersCount = useRecoilValue(getCustomersDataCount);

  return (
    <>
      <TkExtendedWrapper>
        <FormControl>
          <TkTypography fontWeight="bold" fontFamily="Muli" fontSize={16}>
            {t('selectACustomer', { ns: 'events' })}
          </TkTypography>
          <Grid container alignItems="flex-end">
            <Grid item>
              <TkIcon className="fa fa-search" color="#4952A9" />
            </Grid>
            <Grid item sm={10}>
              <TkExtendedDrawerSearch search={search} setSearch={setSearch} />
            </Grid>
          </Grid>
        </FormControl>
        <TkListWrapper>
          {customersList.map((customer: ICustomerTask) => (
            <TkCustomerListItem
              key={customer.id}
              eventType="new"
              customer={customer}
              eventCustomerRecoilState={eventCustomer}
              eventLocationRecoilState={eventLocation}
            />
          ))}
          {customersCount === 0 && (
            <TkListItem key={0} container direction="column">
              <Grid container item alignItems="center">
                <TkTypography fontWeight="bold" fontFamily="Lato" fontSize={16}>
                  {t('noItems', { ns: 'common' })}
                </TkTypography>
              </Grid>
            </TkListItem>
          )}
        </TkListWrapper>
      </TkExtendedWrapper>
      {/* <TkNewTaskActions>
        <DynamicButton
          variant="outlined"
          color="secondary"
          disableElevation
          style={{ width: "100%" }}
        >
          <TkTypography fontWeight="normal" fontFamily="Lato" fontSize={16}>
            Add a New Customer
          </TkTypography>
        </DynamicButton>
      </TkNewTaskActions> */}
    </>
  );
};

export default TkCustomerList;
