import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { Grid, Skeleton } from '@mui/material';

import { TkExtendedWrapper, TkTaskWrapperItem } from '../styles';
import {
  TkTypography,
  TkAlert,
  TkExtendedDrawerServicesAttributes,
  TkHorizontalDivider,
} from '@components/index';
import { TaskbirdIntegrationsEnum } from '@consts/index';
import { calculateEstimatedTime, formatValueToCurrency } from '@helpers/index';
import { useDrawer, useProjectServiceDetails } from '@hooks/index';
import { event as eventRecoil, getUserAccountId } from '@recoilData/index';

const TkProjectServicesDetails = (): JSX.Element => {
  const { t } = useTranslation('offers');
  const { closeExtDrawer } = useDrawer();
  const event = useRecoilValue(eventRecoil);
  const accountId = useRecoilValue(getUserAccountId);

  useEffect(() => {
    if (event.platform !== TaskbirdIntegrationsEnum.BOOKING_WIDGET) {
      closeExtDrawer();
      return;
    }
  }, [event, closeExtDrawer]);

  const {
    data: serviceDetails,
    isLoading,
    isSuccess,
  } = useProjectServiceDetails(accountId, event);

  if (isLoading) {
    return (
      <TkExtendedWrapper maxWidth="lg">
        <Skeleton
          variant="rounded"
          height={30}
          style={{ marginBottom: '20px' }}
        />
        <Skeleton
          variant="rounded"
          height={30}
          style={{ marginBottom: '20px' }}
        />
        <Skeleton
          variant="rounded"
          height={100}
          style={{ marginBottom: '20px' }}
        />
        <Skeleton variant="rounded" height={30} />
      </TkExtendedWrapper>
    );
  }

  if (!isSuccess) {
    return (
      <TkAlert severity="error">
        {t('errorDataUnavailable', { ns: 'common' })}
      </TkAlert>
    );
  }

  return (
    <TkExtendedWrapper maxWidth="lg">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <TkTypography fontWeight="bold" fontFamily="Muli" fontSize={16}>
          {t('serviceDetails', { ns: 'offers' })}
        </TkTypography>
      </Grid>

      <TkTaskWrapperItem
        padding="0 0 2rem"
        height="100%"
        style={{
          overflowY: 'scroll',
        }}
      >
        <Grid container direction="row" justifyContent="space-between">
          <Grid maxWidth="60%">
            <TkTypography fontSize={16} fontWeight="bold">
              {t('bookingWidget.domesticCleaning')}
            </TkTypography>
          </Grid>
          <Grid>
            <TkTypography fontSize={16} fontWeight="bold">
              {formatValueToCurrency(serviceDetails?.base_price)}
            </TkTypography>
          </Grid>
        </Grid>

        <TkTaskWrapperItem style={{ padding: '1rem 0' }}>
          <TkHorizontalDivider />
        </TkTaskWrapperItem>

        {serviceDetails?.service_attributes?.map((attribute, index) => (
          <TkExtendedDrawerServicesAttributes
            key={index}
            serviceAttribute={attribute}
          />
        ))}

        <TkTaskWrapperItem style={{ padding: '1rem 0' }}>
          <TkHorizontalDivider />
        </TkTaskWrapperItem>
        <Grid container direction="row" justifyContent="space-between">
          <Grid maxWidth="60%">
            <TkTypography fontSize={16}>
              {t('bookingWidget.estimatedPrice')}
            </TkTypography>
          </Grid>
          <Grid maxWidth="40%">
            <TkTypography fontSize={16} fontWeight="bold">
              {formatValueToCurrency(serviceDetails?.total_estimated_price)}
            </TkTypography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="space-between"
          marginTop="20px"
        >
          <Grid maxWidth="60%">
            <TkTypography fontSize={16}>
              {t('bookingWidget.estimatedTime')}
            </TkTypography>
          </Grid>
          <Grid maxWidth="40%">
            <TkTypography fontSize={16} fontWeight="bold">
              {calculateEstimatedTime(serviceDetails?.duration_in_minutes)}
            </TkTypography>
          </Grid>
        </Grid>
      </TkTaskWrapperItem>
    </TkExtendedWrapper>
  );
};

export default TkProjectServicesDetails;
