import { createTheme as createThemeV5 } from '@mui/material/styles';

import { createMuiTheme as createThemeV4 } from '@material-ui/core/styles';

const customTheme: any = {
  typography: {
    fontFamily: ['Lato', 'muli', 'Roboto'].join(','),
    htmlFontSize: 16,
    h1: {
      fontSize: '1.7rem',
      fontFamily: ['Muli', 'Roboto'].join(','),
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '1.5rem',
      fontFamily: ['Muli', 'Roboto'].join(','),
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '1.25rem',
      fontFamily: ['Muli', 'Roboto'].join(','),
      fontWeight: 'bold',
    },
    h4: {
      fontSize: '1rem',
      fontFamily: ['Muli', 'Roboto'].join(','),
      fontWeight: 'bold',
    },
    h5: {
      fontSize: '1rem',
      fontFamily: ['Lato', 'Roboto'].join(','),
      fontWeight: 'bold',
    },
    h6: {
      fontSize: '0.875rem',
      fontFamily: ['Lato', 'Roboto'].join(','),
      fontWeight: 'bold',
    },
    subtitle1: {
      lineHeight: 1.2,
    },
    subtitle2: {
      lineHeight: 1.2,
    },
  },
  spacing: 4,
  palette: {
    default: {
      main: '#011F41',
    },
    primary: {
      main: '#4952A9',
    },
    secondary: {
      main: '#2699FB',
    },
    alert: {
      main: '#FEB334',
    },
    delete: {
      main: '#E50101',
    },
    info: {
      main: '#1565C0',
    },
    warning: {
      main: '#F57846',
    },
    success: {
      main: '#2DA867',
    },
    error: {
      main: '#FF3B30',
    },
    search: {
      main: '#2699FB',
    },
    tbnb: {
      main: '#29C2AF',
    },
    moveout: {
      main: '#FE3E91',
      secondary: '#6121A8',
    },
    navyBlue: '#011F41',
    babyBlue: '#1F9BDD',
    opaqueBlue: '#a9bfe2',
    purple: '#4A4DA6',
    white: '#FFF',
    lightPurple: '#F5F6FB',
    grays: ['#E0E0E0'],
    gray: {
      main: '#999999',
      light: '#c8c7cc',
    },
    progressError: {
      main: '#d9322a',
    },
    progressSuccess: {
      main: '#4bb67e',
    },
  },
};

const themeV4 = createThemeV4(customTheme);

const themeV5 = createThemeV5(customTheme);

export { themeV4, themeV5 };
