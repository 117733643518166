import styled from 'styled-components';

export const SectionContent = styled.div`
  padding: 0 1rem;
  margin-left: 0.75rem;
`;

export const DropDownFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
  font-family: 'Lato';
  padding: 0 10px 10px;
  color: #4952a9;
  cursor: pointer;
`;

export const DropdownContentWrapper = styled.div`
  .str-chat__load-more-button {
    display: none;
  }

  .str-chat {
    --str-chat__font-family: 'Lato';
    --str-chat__primary-color: #4952a9;
    --str-chat__channel-preview-active-background-color: #f5f6fb;
    --str-chat__channel-preview-hover-background-color: #f5f6fb;
    --str-chat__surface-color: #f5f5f5;
    --str-chat__primary-surface-color: #ecebeb;
    --str-chat__primary-surface-color-low-emphasis: #edf7f7;
    --str-chat__secondary-surface-color: #ffffff;
    --str-chat__avatar-border-radius: 50%;
    --str-chat__avatar-background-color: #2599fb;
    --str-chat__border-radius-circle: 6px;
    --str-chat__attachment-list-border-radius: 4px;
    flex-direction: column;
  }
`;
