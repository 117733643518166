import React from 'react';
import { Grid } from '@mui/material';
import { TkTypography, TkHorizontalDivider } from '@components/index';

import { TkTaskWrapperItem } from '../styles';
interface ITkExtendedDrawerAdditionalInfoItem {
  additionalInfo: IBookingAdditionalInfoBookingAnswer;
  withDivider: boolean;
}

const TkExtendedDrawerAdditionalInfoItem = ({
  additionalInfo,
  withDivider = false,
}: ITkExtendedDrawerAdditionalInfoItem) => {
  return (
    <>
      <Grid container marginBottom={'15px'}>
        <Grid container>
          <TkTypography fontWeight="bold" fontSize={16}>
            {additionalInfo.question}
          </TkTypography>
        </Grid>
        <Grid container>
          <TkTypography fontSize={16}>{additionalInfo.answer}</TkTypography>
        </Grid>
      </Grid>

      {withDivider && (
        <TkTaskWrapperItem style={{ padding: '0 0 1rem 0' }}>
          <TkHorizontalDivider />
        </TkTaskWrapperItem>
      )}
    </>
  );
};

export default React.memo(TkExtendedDrawerAdditionalInfoItem);
