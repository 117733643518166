import React from 'react';

import { useTranslation } from 'react-i18next';

import { TkInput } from '@components/index';

interface TkExtendedDrawerSearchProps {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const TkExtendedDrawerSearch = ({
  search,
  setSearch,
}: TkExtendedDrawerSearchProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <TkInput
      marginTop="5px"
      marginLeft="10px"
      fullWidth
      value={search}
      placeholder={`${t('search', { ns: 'common' })}...`}
      style={{ width: '229px' }}
      onChange={(event) =>
        event.target.value.length === 0
          ? setSearch('')
          : setSearch(event.target.value)
      }
      aria-describedby="component-error-text"
    />
  );
};

export default TkExtendedDrawerSearch;
