import { selector } from "recoil";
import {
  assigneesData,
  eventAssignees,
  isAllEventAssigneesSelected,
} from "@recoilData/index";
import { generateAssignee } from "@helpers/index";

const getSelectedEventAssignees = selector({
  key: "getSelectedEventAssignees",
  get: ({ get }) => {
    const assignees = get(assigneesData);
    const newAssignees: IAssignee[] = [];

    assignees.forEach((assignee) => {
      const eventState = get(eventAssignees(assignee.id));
      if (eventState) newAssignees.push(generateAssignee(assignee, eventState));
    });

    return newAssignees;
  },
});

const getSelectedEventAssigneesCount = selector({
  key: "getSelectedEventAssigneesCount",
  get: ({ get }) => {
    const assignees = get(getSelectedEventAssignees);
    let count = 0;
    assignees.forEach(() => count++);
    return count;
  },
});

const addSingleAssigneeById = selector<number>({
  key: "addSingleAssigneeById",
  get: () => 0,
  set: ({ get, set, reset }, newValue) => {
    const assignees = get(assigneesData);
    assignees.forEach((assignee) => {
      reset(eventAssignees(assignee.id));
    });

    set(eventAssignees(newValue as number), true);
  },
});

const removeSingleAssigneeById = selector<number>({
  key: "removeSingleAssigneeById",
  get: ({ get }) => 0,
  set: ({ set }, newValue) => set(eventAssignees(newValue as number), false),
});

const selectAllEventAssignees = selector<boolean>({
  key: "selectAllEventAssignees",
  get: ({ get }) => get(isAllEventAssigneesSelected),
  set: ({ set, get }, newValue) => {
    const assignees = get(assigneesData);

    set(isAllEventAssigneesSelected, newValue as boolean);

    assignees.map((assignee) => {
      set(eventAssignees(assignee.id), newValue as boolean);
    });
  },
});

export {
  getSelectedEventAssigneesCount,
  getSelectedEventAssignees,
  removeSingleAssigneeById,
  selectAllEventAssignees,
  addSingleAssigneeById,
};
