import React from "react";
import { FormControl, FormHelperText } from "@mui/material";
import styled from "styled-components";
import { Grid } from '@mui/material';

interface ITkFormControlInputWrapper {
  error: boolean;
  errorLabel: string | JSX.Element;
  width?: string;
  endAdornment?: boolean | JSX.Element;
}

const FormControlWrapper = styled(FormControl)`
  width: 100%;

  p {
    margin-left: 0;
  }
`;

const TkFormControlInputWrapper: React.FC<ITkFormControlInputWrapper> = ({
  error,
  width,
  children,
  errorLabel,
  endAdornment
}) => {
  return (
    <FormControlWrapper style={{ width }} error={error}>
      {children}
      {error || endAdornment ?
        <Grid container justifyContent="space-between">
            <Grid item>
              {error ?
                  <FormHelperText id="component-error-text">{errorLabel}</FormHelperText>
                  : (
                ''
              )}
            </Grid>
            {endAdornment ?
              <Grid item>
                {endAdornment}
              </Grid>
            : ''
          }
        </Grid>
        : ''
    }
    </FormControlWrapper>
  );
};

export default TkFormControlInputWrapper;
