import React from 'react';
import { IconButton, Badge } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import styled from 'styled-components';

interface IAvatar {
  notificationsNumber?: number;
  openMenu?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ScTkBellNotificationWrapper = styled.div`
  padding: 0 1rem;
  margin-left: 0.75rem;
`;

const TKBellNotification = (props: IAvatar) => {
  return (
    <ScTkBellNotificationWrapper>
      <IconButton
        style={{
          padding: 0,
          color: '#4952A9',
        }}
        onClick={props.openMenu}
      >
        <Badge badgeContent={props.notificationsNumber} color="error">
          <NotificationsIcon />
        </Badge>
      </IconButton>
    </ScTkBellNotificationWrapper>
  );
};

export default TKBellNotification;
