import React, { useState } from 'react';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';
import {
  event as eventRecoil,
  editEvent,
  isEventEdit,
  editEventTitle,
} from '@recoilData/index';

export const useEditNameDisplay = () => {
  const [editName, setEditName] = useState<boolean>(false);
  const [nameChange, setNameChange] = useState<string>('');
  const [showEditNameIcon, setShowEditNameIcon] = useState<boolean>(false);

  // Recoil Values
  const event = useRecoilValue(eventRecoil);
  const selectedEvent = useRecoilValue(editEvent);
  const setEdited = useSetRecoilState(isEventEdit);
  const [name, setName] = useRecoilState(editEventTitle);

  const applyNameChange = (name: string) => {
    setName(name);
    setEditName(false);
    setEdited(true);
  };

  const cancelNameChange = () => {
    setNameChange(selectedEvent.title);
    setEditName(false);
  };

  return {
    name,
    event,
    editName,
    nameChange,
    showEditNameIcon,
    setEditName,
    setNameChange,
    applyNameChange,
    cancelNameChange,
    setShowEditNameIcon,
  }
}