import React from 'react';

import { Menu } from '@mui/material';

import { styleNotifications, useStyles } from './styles';
import { NotificationList } from '@components/TkNotifications';
import { useNotifications } from '@contexts/NotificationContext/NotificationProvider';
import { IMenuNotifications } from '@hooks/notification/types';

const MenuNotifications = ({
  anchorElNotification,
  setAnchorElNotification,
  notificationsTotalUnread,
}: IMenuNotifications): JSX.Element => {
  const { markAllAsRead, getMoreOrAllNotifications } = useNotifications();
  const isMenuOpenNotifications = Boolean(anchorElNotification);
  const classes = useStyles();

  const handleMenuCloseNotification = () => {
    if (notificationsTotalUnread > 0) {
      markAllAsRead();
    }
    setAnchorElNotification(null);
  };

  const handleScrollDivElement = (
    el: React.UIEvent<HTMLDivElement, UIEvent>
  ) => {
    const fatherElement = el.currentTarget.children[2];
    const currentPos = fatherElement.scrollHeight - fatherElement.clientHeight;
    const fullHeight = fatherElement.scrollTop;
    if (currentPos === fullHeight) getMoreOrAllNotifications();
  };

  return (
    <Menu
      PaperProps={styleNotifications}
      anchorReference="anchorPosition"
      anchorPosition={{
        top: 52,
        left: window.screen.width - (10 / 100) * window.screen.width,
      }}
      keepMounted
      MenuListProps={{ disablePadding: true }}
      open={isMenuOpenNotifications}
      onClose={handleMenuCloseNotification}
      classes={{ paper: classes.menuPaper }}
      onScrollCapture={(e) => handleScrollDivElement(e)}
    >
      <NotificationList
        handleMenuCloseNotification={handleMenuCloseNotification}
      />
    </Menu>
  );
};

export default MenuNotifications;
