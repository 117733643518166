export interface CallDrawer {
  id: string;
  props?: any;
}

export interface Drawer extends CallDrawer {
  Component: (props) => JSX.Element;
}

export enum MainDrawersTypes {
  NEW_TASK = 'newTask',
  EDIT_TASK = 'editTask',
}

export enum ExtendedDrawersTypes {
  // NEW TASK EXTENDED DRAWER TYPES
  NEW_TASK_CUSTOMER = 'newTaskCustomer',
  NEW_TASK_LOCATION = 'newTaskLocation',

  // SERVICE EXTENDED DRAWER TYPES
  TASK_ASSIGNEE_LIST = 'taskAssigneeList',
  TASK_CHECKLIST = 'taskChecklist',

  // EDIT TASK EXTENDED DRAWER TYPES
  EDIT_TASK_CUSTOMER = 'editTaskCustomer',
  EDIT_TASK_LOCATION = 'editTaskLocation',
  EDIT_TASK_ADDITIONAL_INFO_TURNO = 'additionalInfoTurno',
  EDIT_TASK_ADDITIONAL_INFO_BOOKING = 'additionalInfoBooking',
  EDIT_TASK_SERVICE_DETAILS = 'serviceDetails',
  EDIT_TASK_CHECKLIST_LIST = 'taskChecklistList',
  EDIT_TASK_TEAMMATES_IMAGES = 'teammatesImages',
  EDIT_TASK_TEAMMATES_NOTES = 'teammatesNotes',
  EDIT_TASK_IMAGES = 'taskimages',
  EDIT_TASK_NOTES = 'taskNotes',
  EDIT_TASK_INVENTORY_LIST = 'taskInventory',
}
