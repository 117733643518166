import {
  ImgAssignee,
  TkCircleIcon,
  IconAssigneeButton,
  IconAssigneeWrapper,
  AssigneeSelectedButton,
} from './styles';
import React from 'react';
import { Box, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generateInitials, getTimeFromEpoch } from '@helpers/index';
import { TkTypography, TkIcon } from '@components/index';
import TkPulsatingIconSmall from './TkPulsatingIconSmall';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { editEventAssignees, getEventStatus } from '@recoilData/index';
import { TaskStatusEnum } from '@consts/index';
import { useStartChat } from '@hooks/index';
import { useAuth } from '@contexts/index';

interface ITkAssigneeSelectedListItem {
  assignee: IAssignee;
}

const TkAssigneeSelectedListItem: React.FC<ITkAssigneeSelectedListItem> = ({
  assignee,
}) => {
  const { t } = useTranslation();
  const { userInfo } = useAuth();
  const { handleStartChat } = useStartChat();
  const status = useRecoilValue(getEventStatus);
  const removeEditEventAssignee = useResetRecoilState(
    editEventAssignees(assignee.id)
  );

  const loggedUserIsAssignee = assignee.id === userInfo?.user.id;
  const renderChatIcon = assignee.chat_user_id && loggedUserIsAssignee;

  const isAssignmentStatus = (
    statusToCheck: typeof TaskStatusEnum[keyof typeof TaskStatusEnum]
  ) => assignee.assignmentStatus === statusToCheck;

  return (
    <AssigneeSelectedButton container justifyContent="space-between">
      <Box
        mr={1}
        display="flex"
        flexDirection="row"
        width="80%"
        alignItems="center"
      >
        <Grid>
          {assignee.photo_url ? (
            <ImgAssignee src={assignee.photo_url} />
          ) : (
            <IconAssigneeButton>
              {generateInitials(assignee.full_name)}
            </IconAssigneeButton>
          )}
        </Grid>
        <Grid item direction="column">
          <TkTypography
            fontWeight="bold"
            fontFamily="Lato"
            fontSize={14}
            color="default"
          >
            {loggedUserIsAssignee
              ? t('you', { ns: 'common' })
              : assignee.full_name}
          </TkTypography>
          <Box display="flex" alignItems="center">
            {isAssignmentStatus(TaskStatusEnum.TODO) && (
              <>
                <TkCircleIcon color="#fd3b2f" />
                <TkTypography
                  fontSize={14}
                  fontFamily="Lato"
                  fontWeight="normal"
                  color="rgba(1,31,65)"
                  display="inline"
                  marginLeft="5px"
                >
                  {t('notStarted', { ns: 'events' })}
                </TkTypography>
              </>
            )}

            {isAssignmentStatus(TaskStatusEnum.IN_PROGRESS) && (
              <>
                <TkPulsatingIconSmall />
                <TkTypography
                  fontSize={14}
                  fontFamily="Lato"
                  fontWeight="normal"
                  color="rgba(1,31,65)"
                  display="inline"
                  marginLeft="5px"
                >
                  {t('inProgress', { ns: 'common' })}
                </TkTypography>
                {assignee.start_time_epoch && (
                  <TkTypography
                    fontSize={14}
                    fontFamily="Lato"
                    color="rgba(1,31,65)"
                    display="inline"
                    marginLeft="5px"
                  >
                    {`${getTimeFromEpoch(assignee?.start_time_epoch)} - ...`}
                  </TkTypography>
                )}
              </>
            )}
            {isAssignmentStatus(TaskStatusEnum.DONE) && (
              <>
                <TkCircleIcon color="#2DA867" />
                <TkTypography
                  fontSize={14}
                  fontFamily="Lato"
                  fontWeight="normal"
                  color="rgba(1,31,65)"
                  display="inline"
                  marginLeft="5px"
                >
                  {t('completed', { ns: 'common' })}
                </TkTypography>
                {assignee.start_time_epoch && assignee.end_time_epoch && (
                  <TkTypography
                    fontSize={14}
                    fontFamily="Lato"
                    color="rgba(1,31,65)"
                    display="inline"
                    marginLeft="5px"
                  >
                    {`${getTimeFromEpoch(
                      assignee?.start_time_epoch
                    )} - ${getTimeFromEpoch(assignee?.end_time_epoch)}`}
                  </TkTypography>
                )}
              </>
            )}
          </Box>
        </Grid>
      </Box>
      <>
        {renderChatIcon && (
          <IconAssigneeWrapper>
            <TkIcon
              onClick={() => handleStartChat(assignee.chat_user_id)}
              className="fa fa-comments-alt"
              color="#011F41"
              cursor="pointer"
            />
          </IconAssigneeWrapper>
        )}
        {status !== TaskStatusEnum.DONE && (
          <IconAssigneeWrapper>
            <TkIcon
              onClick={() => removeEditEventAssignee()}
              className="fa fa-trash"
              color="#011F41"
              cursor="pointer"
            />
          </IconAssigneeWrapper>
        )}
      </>
    </AssigneeSelectedButton>
  );
};

export default TkAssigneeSelectedListItem;
