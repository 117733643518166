import { selectorFamily } from "recoil";
import { editEventCustomer, searchLocationsData } from "@recoilData/index";

const searchEditEventLocationsData = selectorFamily({
  key: "searchEditEventLocationsData",
  get: (search: string) => ({ get }) => {
    const locations = get(searchLocationsData(search));
    const eventCustomer = get(editEventCustomer);
    if (eventCustomer === undefined) return locations;
    return locations.filter(
      (location) => location.customerId === eventCustomer.id
    );
  },
});

export { searchEditEventLocationsData };
