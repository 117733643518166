import { selector } from "recoil";
import { serviceData } from "@recoilData/index";

const getServiceAttributes = selector({
  key: "getServiceAttributes",
  get: ({ get }) => get(serviceData).attributes,
});

const getServiceQuestions = selector({
  key: "getServiceQuestions",
  get: ({ get }) => get(serviceData).questions,
});
export { getServiceAttributes, getServiceQuestions };
