import React from 'react';

import styled from 'styled-components';

import { IconButton, Avatar } from '@mui/material';

import { useAuth } from '@contexts/index';
import { generateInitials } from '@helpers/index';

interface AvatarProps {
  openMenu?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const ScTkAvatarWrapper = styled.div`
  position: relative;
  margin-left: 0.75rem;
`;

const TkAvatar = (props: AvatarProps): JSX.Element => {
  const { userInfo } = useAuth();
  const userPhoto = userInfo.user.full_photo_url;
  const userFullName = userInfo.user.full_name;

  return (
    <ScTkAvatarWrapper>
      <IconButton
        onClick={props.openMenu}
        edge="end"
        aria-label="account of current user"
      >
        {userPhoto ? (
          <Avatar src={userPhoto} />
        ) : (
          <Avatar>{generateInitials(userFullName)}</Avatar>
        )}
      </IconButton>
    </ScTkAvatarWrapper>
  );
};

export default TkAvatar;
