import React from 'react';
import styled from 'styled-components';

export interface TkFormLabelProps {
  id: string;
  label: string;
}

export const ScTkFormLabel = styled.label`
  font-weight: bold;
`;

export const TkFormLabel = ({
  id,
  label,
  ...props
}: TkFormLabelProps): JSX.Element => {
  return (
    <ScTkFormLabel className="tk-form-label" htmlFor={id} {...props}>
      {label}
    </ScTkFormLabel>
  );
};

export default TkFormLabel;
