import { atom } from 'recoil';

const bookingActiveStep = atom<number>({
  key: 'bookingActiveStep',
  default: 0,
});

const openPortalAtom = atom<boolean>({
  key: 'openPortalAtom',
  default: false,
});

export { bookingActiveStep, openPortalAtom };
