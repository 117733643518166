import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ISimpleObject } from 'types/common.type';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Button, ButtonGroup, FormControl, Grid, Select } from '@mui/material';

import {
  MonthlyDayItem,
  MonthlyDayItemChecked,
  MonthlyDayPicker,
  OptionWrapper,
  SelectorWrapperDouble,
  useStyles,
} from '../styles';
import { TaskFrequencyEnum } from '@consts/index';

interface ITkTaskRepeatOptionsMonthly {
  taskRepeat: ITaskRepeat;
  setTaskRepeat: (taskRepeat: ITaskRepeat) => void;
  monthlyOnTheOptionsInterval: string[];
  monthlyOnTheOptionsPeriod: string[];
}

const TkTaskRepeatOptionsMonthly: React.FC<ITkTaskRepeatOptionsMonthly> = ({
  taskRepeat,
  setTaskRepeat,
  monthlyOnTheOptionsInterval,
  monthlyOnTheOptionsPeriod,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleMonthlyOnTheSelection = (value: any, flag: number) => {
    const oL: string[] = [];
    taskRepeat.monthlyOnTheRepetition.forEach((item) => oL.push(item));
    oL[flag] = value;
    setTaskRepeat({ ...taskRepeat, monthlyOnTheRepetition: oL });
  };

  const handleMonthlyTypeChange = (
    value: TaskFrequencyEnum.BY_MONTH_DAY | TaskFrequencyEnum.BY_DAY_TYPE
  ) => {
    setTaskRepeat({ ...taskRepeat, monthlyType: value });
  };

  const [daysOptionList, setDaysOptionList] = useState<ISimpleObject[]>([
    { id: 0, value: '1', checked: false },
    { id: 1, value: '2', checked: false },
    { id: 2, value: '3', checked: false },
    { id: 3, value: '4', checked: false },
    { id: 4, value: '5', checked: false },
    { id: 5, value: '6', checked: false },
    { id: 6, value: '7', checked: false },
    { id: 7, value: '8', checked: false },
    { id: 8, value: '9', checked: false },
    { id: 9, value: '10', checked: false },
    { id: 10, value: '11', checked: false },
    { id: 11, value: '12', checked: false },
    { id: 12, value: '13', checked: false },
    { id: 13, value: '14', checked: false },
    { id: 14, value: '15', checked: false },
    { id: 15, value: '16', checked: false },
    { id: 16, value: '17', checked: false },
    { id: 17, value: '18', checked: false },
    { id: 18, value: '19', checked: false },
    { id: 19, value: '20', checked: false },
    { id: 20, value: '21', checked: false },
    { id: 21, value: '22', checked: false },
    { id: 22, value: '23', checked: false },
    { id: 23, value: '24', checked: false },
    { id: 24, value: '25', checked: false },
    { id: 25, value: '26', checked: false },
    { id: 26, value: '27', checked: false },
    { id: 27, value: '28', checked: false },
    { id: 28, value: '29', checked: false },
    { id: 29, value: '30', checked: false },
    { id: 30, value: '31', checked: false },
  ]);

  const handleCheckMonthlyItem = (day: ISimpleObject) => {
    const oL: number[] = [];
    taskRepeat.monthlyEachRepetition.forEach((item) => oL.push(item));

    if (!day.checked) {
      oL.push(day.id + 1);
      setTaskRepeat({ ...taskRepeat, monthlyEachRepetition: oL });
    } else {
      const nL = oL.filter((n) => {
        if (n !== day.id + 1) return n;
      });
      setTaskRepeat({ ...taskRepeat, monthlyEachRepetition: nL });
    }

    const nL = daysOptionList.map((i) => {
      if (i.id === day.id) i.checked = !i.checked;
      return i;
    });

    setDaysOptionList(nL);
  };

  return (
    <OptionWrapper container justifyContent="center" alignItems="center">
      <Grid container>
        <ButtonGroup
          size="small"
          aria-label="small outlined button group"
          fullWidth
        >
          <Button
            className={
              taskRepeat.monthlyType === TaskFrequencyEnum.BY_MONTH_DAY
                ? classes.buttonSelected
                : classes.buttonUnselected
            }
            onClick={() =>
              handleMonthlyTypeChange(TaskFrequencyEnum.BY_MONTH_DAY)
            }
          >
            {t('each', { ns: 'common' })}
          </Button>
          <Button
            className={
              taskRepeat.monthlyType === TaskFrequencyEnum.BY_DAY_TYPE
                ? classes.buttonSelected
                : classes.buttonUnselected
            }
            onClick={() =>
              handleMonthlyTypeChange(TaskFrequencyEnum.BY_DAY_TYPE)
            }
          >
            {t('onThe', { ns: 'common' })}
          </Button>
        </ButtonGroup>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        className={classes.baseGrid}
      >
        {taskRepeat.monthlyType === TaskFrequencyEnum.BY_MONTH_DAY ? (
          <MonthlyDayPicker>
            {daysOptionList.map((day) =>
              day.checked ? (
                <MonthlyDayItemChecked
                  onClick={() => handleCheckMonthlyItem(day)}
                >
                  {day.value}
                </MonthlyDayItemChecked>
              ) : (
                <MonthlyDayItem onClick={() => handleCheckMonthlyItem(day)}>
                  {day.value}
                </MonthlyDayItem>
              )
            )}
          </MonthlyDayPicker>
        ) : (
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            spacing={2}
          >
            <SelectorWrapperDouble marginRight={2}>
              <FormControl className={classes.formControl}>
                <Select
                  native
                  variant="standard"
                  fullWidth
                  labelId="select-repeat-task"
                  id="select-repeat-task"
                  value={taskRepeat.monthlyOnTheRepetition[0]}
                  onChange={(e) =>
                    handleMonthlyOnTheSelection(e.target.value, 0)
                  }
                  IconComponent={ExpandMoreIcon}
                  color="primary"
                >
                  {monthlyOnTheOptionsPeriod.map((i) => (
                    <option value={i.toLowerCase()} key={i.toLowerCase()}>
                      {t(`positionInterval.${i}`, { ns: 'common' })}
                    </option>
                  ))}
                  <option disabled>───────────────</option>
                  <option value="last">
                    {t('positionInterval.last', { ns: 'common' })}
                  </option>
                </Select>
              </FormControl>
            </SelectorWrapperDouble>
            <SelectorWrapperDouble marginLeft={2}>
              <FormControl className={classes.formControl}>
                <Select
                  native
                  variant="standard"
                  fullWidth
                  labelId="select-repeat-task"
                  id="select-repeat-task"
                  value={taskRepeat.monthlyOnTheRepetition[1]}
                  onChange={(e) =>
                    handleMonthlyOnTheSelection(e.target.value, 1)
                  }
                  IconComponent={ExpandMoreIcon}
                  color="primary"
                >
                  {monthlyOnTheOptionsInterval.map((i) => (
                    <option value={i.toLowerCase()} key={i.toLowerCase()}>
                      {t(`weekdays.${i}`, { ns: 'common' })}
                    </option>
                  ))}
                  <option disabled>───────────────</option>
                  <option value="day">{t('day', { ns: 'common' })}</option>
                </Select>
              </FormControl>
            </SelectorWrapperDouble>
          </Grid>
        )}
      </Grid>
    </OptionWrapper>
  );
};

export default TkTaskRepeatOptionsMonthly;
