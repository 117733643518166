import { selector } from 'recoil';

import { blankSelectAll } from '@recoilData/index';

const blankSelectAllData = selector<boolean>({
  key: 'blankSelectAllData',
  get: ({ get }) => get(blankSelectAll),
  set: ({ get, set }) => set(blankSelectAll, !get(blankSelectAll)),
});

export * from './multiInput';
export { blankSelectAllData };
