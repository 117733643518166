import { lazy } from 'react';
import { withAuth } from '@contexts/AuthContext/hocs';

const HomePage = lazy(() => import('@pages/Home'));

const CalendarRoutes = [
  {
    path: '/home',
    component: withAuth(HomePage),
  },
];

export default CalendarRoutes;
