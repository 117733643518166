import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { IconContext } from 'react-icons';
import { useRecoilState } from 'recoil';

import './Navbar.css';
import { Sidebar } from './Sidebar';
import logo from '../../images/TKTopBar/Taskbird-horizontal-light.png';
import { logoContentDark as LogoContentDark } from '../TKLogoContent';
import TopBar from '../TKTopbar';
import { TkBookingTour } from '@components/TkBookingWidget/TkBookingTour';
import useKeyboardShortcut from '@components/TkBookingWidget/hooks/useKeyboardShortcut';
import { AppLocationURLs } from '@consts/index';
import { useDebouncedCallback } from '@hooks/index';
import { isFirstTimeBookingTour } from '@recoilData/index';

interface NavbarProps {
  actionableButtons?: React.ReactNode;
  children?: React.ReactNode;
}
interface ISection {
  id: string;
  icon: string;
  path: string;
  title: string;
}

const Navbar = ({ children, actionableButtons }: NavbarProps): JSX.Element => {
  const { t } = useTranslation();
  const [startBookingTour, setStartBookingTour] = useRecoilState(
    isFirstTimeBookingTour
  );
  const onChangedDebounce = useDebouncedCallback(() => {
    const test = {
      openDraftTour: false,
      openServiceDraft: false,
      openServiceTab: false,
      openServiceTour: false,
      openSideBar: true,
      openTour: true,
      stepIndex: 0,
    };
    setStartBookingTour(test);
  }, 500);

  const handleKeyboardShortcut = () => {
    setSidebar(true);
    onChangedDebounce();
  };

  const keys = ['Shift', 'A'];
  useKeyboardShortcut(keys, handleKeyboardShortcut);

  const [sidebar, setSidebar] = useState(false || startBookingTour.openSideBar);
  const closeSidebar = () => setSidebar(false);
  const toggleSidebar = () => setSidebar((prev) => !prev);

  const sideBarLocations: ISection[] = [
    {
      id: 'tasks',
      path: AppLocationURLs.HOME,
      icon: 'fa fa-check-circle',
      title: t('navbar.tasks', { ns: 'app' }),
    },
    {
      id: 'team',
      path: AppLocationURLs.TEAM,
      icon: 'fa fa-users',
      title: t('navbar.team', { ns: 'app' }),
    },
    {
      id: 'chat',
      path: AppLocationURLs.CHAT,
      icon: 'fa fa-comments-alt',
      title: t('navbar.chat', { ns: 'app' }),
    },
    {
      id: 'customers',
      path: AppLocationURLs.CUSTOMERS,
      icon: 'fa fa-user-tie',
      title: t('navbar.customers', { ns: 'app' }),
    },
    {
      id: 'checklists',
      path: AppLocationURLs.CHECKLISTS,
      icon: 'fas fa-tasks',
      title: t('navbar.checklists', { ns: 'app' }),
    },
    {
      id: 'booking',
      path: AppLocationURLs.BOOKING_SITE_SETTINGS,
      icon: 'fas fa-concierge-bell',
      title: t('navbar.bookingWidget', { ns: 'app' }),
    },
    {
      id: 'integrations',
      path: AppLocationURLs.INTEGRATIONS,
      icon: 'fas fa-sync-alt',
      title: t('navbar.integrations', { ns: 'app' }),
    },
  ];
  return (
    <IconContext.Provider value={{ color: '#fff' }}>
      <TopBar openSideBar={toggleSidebar} actionableButtons={actionableButtons}>
        {startBookingTour.openTour && <TkBookingTour />}
        {children}
      </TopBar>
      <nav
        className={sidebar ? 'nav-menu active' : 'nav-menu'}
        style={{ zIndex: 1000 }}
      >
        <div className="nav-menu-items">
          <div className="menu-bars" onClick={toggleSidebar}>
            <LogoContentDark logo={logo} color="#011f41" width="100%" />
          </div>
          <div>
            <Sidebar
              locations={sideBarLocations}
              closeSidebar={closeSidebar}
              sidebar={sidebar}
            />
          </div>
        </div>
      </nav>
    </IconContext.Provider>
  );
};

export default Navbar;
