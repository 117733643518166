import React, { useState } from 'react';

import { DateTime } from 'luxon';

import { Card } from '@mui/material';

import { useStyles } from './styles';
import { NotificationProps } from '@hooks/notification/types';

const Notification = ({
  notification,
  read: isRead,
}: NotificationProps): JSX.Element => {
  const styles = useStyles();
  const [read, setRead] = useState(isRead);

  return (
    <Card
      className={read ? styles.sectionContent : styles.sectionContentRead}
      onClick={() => setRead(false)}
    >
      <div
        className={
          read ? styles.sectionContentActive : styles.sectionContentActiveRead
        }
      />
      <div className={styles.sectionContentInfo}>
        <div className={styles.sectionContentInfoTitle}>
          {notification?.data?.title}
        </div>
        <div className={styles.sectionContentInfoBody}>
          {notification?.data?.body}
        </div>
        <div className={styles.sectionContentInfoDate}>
          {DateTime.fromISO(notification.created_at ?? '').toFormat(
            't - ccc, LLL d'
          )}
        </div>
      </div>
    </Card>
  );
};

export default Notification;
