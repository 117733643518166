import { DialogActions, DialogContent } from "@mui/material";
import styled from "styled-components";

export const ModalLeftContent = styled.div``;

export const ModalRightContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebecef;
`;

export const ModalSection = styled.div`
  height: 300px;
  display: flex;
  padding: 24px 32px;
  flex-direction: column;
`;

export const ModalContent = styled(DialogContent)`
  margin: -1px;

  &.MuiDialogContent-root {
    width: 800px;
    height: 380px;
    max-height: 380px;
    max-width: 800px;
    border-top: none;
    overflow-y: hidden;
    display: flex;
  }
  &.MuiDialogContent-dividers {
    padding: 0;
  }
  &.MuiDialog-paper {
    overflow-y: none;
  }
`;

export const ModalActions = styled(DialogActions)`
  width: 100%;

  &.MuiDialogActions-spacing {
    padding: 10px 35px 10px 23px;
  }
`;
