import { atom, atomFamily } from "recoil";

const filterLocations = atomFamily<boolean, number>({
  key: "filterLocations",
  default: false,
});

const isAllFilterLocationsSelected = atom<boolean>({
  key: "isAllFilterLocationsSelected",
  default: false,
});

export { filterLocations, isAllFilterLocationsSelected };
