export enum NotificationTypesEnum {
  ADDED_TO_CREW = 'NewToCrewNotification',
  ADMIN_EDITED_TEAMMATE_AVAILABILITY = 'AdminEditedTeammateAvailabilityNotification',
  ASSIGNMENT_IMAGE_UPLOADED = 'Default',
  ASSIGNMENT_NOTE_UPLOADED = 'Default',
  CHAT_NEW_MESSAGE = 'ChatNewMessage',
  DEMO_CALL_SCHEDULED = 'DemoCallScheduled',
  EDIT_TEAMMATE_AVAILABILITY_TEASER = 'EditTeammateAvailabilityTeaser',
  MOVEOUT_CLEANING_CANCELED_CARD_AUTH = 'MoveoutCleaningCanceledCardAuth',
  NEW_BOOKING_PROJECT_REQUEST = 'newBookingRequest',
  NEW_MOVEOUT_REQUEST = 'NewMoveoutRequest',
  NEW_RECURRING_TASK_NOTIFICATION = 'NewRecurringTaskNotification',
  NEW_TASK_ASSIGNED = 'NewTaskNotification',
  NEW_TASKS_IMPORTED = 'TaskCreatedByIntegration',
  REMOVED_FROM_TASK = 'TaskRemovalNotification',
  SUPPORT_TEAM_MESSAGE = 'NewIntercomMessage',
  TASK_COMPLETED = 'TaskCompletedForAdminNotification',
  TASK_DELETED_BY_INTEGRATION = 'TaskDeletedByIntegration',
  TASK_NOT_COMPLETED = 'TaskNotCompletedForAdmin',
  TASK_NOT_STARTED = 'TaskNotStarted',
  TASK_STARTED = 'TaskStartedForAdminNotification',
  TASK_UNASSIGNED = 'TaskUnassigned',
  TEAMMATE_ASK_TO_BE_REMOVED = 'AskedToBeRemoved',
  TEAMMATE_EDITED_AVAILABILITY = 'TeammateEditedAvailabilityNotification',
  TEAMMATE_JOINS_COMPANY = 'NewTeammateNotification',
  TEAMMATE_REMOVED_BY_MANAGER = 'DeletedMemberByManagerNotification',
  UPCOMING_TASKS = 'UpcomingTasks',
  USER_SUPPORT_NOTIFICATION = 'UserSupportNotification',
  DEFAULT = 'Default',
}
