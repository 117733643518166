import styled, { css } from 'styled-components';

import { Icon } from '@mui/material';
import {
  palette,
  PaletteProps, //eslint-disable-line
} from '@mui/system';

interface TkIconProps {
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  marginBottom?: number;
  iconSize?: number;
  verticalAlign?: string;
  width?: number;
  opacity?: number;
  cursor?: string;
  iconColor?: any;
}
// fa fa-{icon} for solid icons
// far fa-{icon} for regular icons
const TkIcon = styled(Icon)<PaletteProps & TkIconProps>`
  &.MuiIcon-root {
    cursor: ${(props) => props.cursor ?? 'default'};
    font-size: ${(props) => (props.iconSize ? props.iconSize : 16)}px;
    ${palette}
    color: ${(props) => props.iconColor || props.iconColor};
    margin: ${(props) => (props.marginTop ? props.marginTop : 0)}px
      ${(props) => (props.marginRight ? props.marginRight : 0)}px
      ${(props) => (props.marginBottom ? props.marginBottom : 0)}px
      ${(props) => (props.marginLeft ? props.marginLeft : 0)}px;
    opacity: ${(props) => (props.opacity ? props.opacity : 1)};

    ${({ width }) =>
      width &&
      css`
        width: ${width}px;
      `}
    ${({ verticalAlign }) =>
      verticalAlign &&
      css`
        vertical-align: ${verticalAlign};
      `};
  }
`;

export default TkIcon;
