import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { TkFormLabel } from '@components/TkUI/TkForm/TkFormLabel';
import { Box } from '@mui/material';
import { newUUIDv4 } from '@helpers/unique-ids';
import { TkFormError } from '@components/TkUI/TkForm/TkFormError';

export interface TkFormGroupProps {
  children?: ReactNode;
  error?: ReactNode;
  hint?: ReactNode;
  id?: string;
  label: ReactNode;
  required?: boolean;
}

export const ScTkFormGroupOptional = styled.span`
  font-weight: normal;
  margin-left: 5px;
`;

export const TkFormGroup = ({
  children,
  error,
  id,
  hint,
  label,
}: TkFormGroupProps): JSX.Element => {
  let optionalLabel: ReactNode;
  let labelElement: ReactNode;
  let formHint: ReactNode;
  let formError: ReactNode;

  if (hint) {
    formHint = hint;
  }

  if (error) {
    formError =
      typeof error === 'string' ? <TkFormError errorMessage={error} /> : error;
  }

  if (formHint) {
    formHint = <Box sx={{ marginTop: 0.5 }}>{formHint}</Box>;
  }

  labelElement =
    typeof label === 'string' ? (
      <TkFormLabel id={id ? id : newUUIDv4()} label={label} />
    ) : (
      label
    );

  labelElement = labelElement && (
    <Box margin={{ bottom: 0.5 }}>
      {labelElement}
      {typeof label === 'string' && optionalLabel}
    </Box>
  );

  return (
    <Box
      sx={{
        position: 'relative',
        flexGrow: 1,
      }}
    >
      {labelElement}
      {children}
      {formHint}
      {formError}
    </Box>
  );
};
