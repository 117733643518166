import React from 'react';
import { SelectorWrapper } from '../styles';
import { useTranslation } from 'react-i18next';
import { TkTypography } from '@components/index';
import { TkIconSelected } from '@web-ui/index';
interface ITkDrawerCustomerSelector {
  customer: ICustomerTask | undefined;
  handleClick: () => void;
}

const TkDrawerCustomerSelector: React.FC<ITkDrawerCustomerSelector> = ({
  customer,
  handleClick,
}) => {
  const { t } = useTranslation();

  if (!customer) {
    return (
      <SelectorWrapper onClick={handleClick}>
        <TkTypography
          fontWeight="bold"
          fontFamily="Lato"
          fontSize={16}
          userselect="none"
          color="primary"
        >
          + {t('customer', { ns: 'common' })}
        </TkTypography>
      </SelectorWrapper>
    );
  }

  return (
    <SelectorWrapper onClick={handleClick}>
      <TkTypography
        fontWeight="normal"
        fontFamily="Lato"
        fontSize={16}
        userselect="none"
        color="#011F41"
      >
        {customer.name}
        <TkIconSelected
          className="fa fa-pencil"
          verticalAlign="middle"
          style={{ top: 6 }}
        />
      </TkTypography>
    </SelectorWrapper>
  );
};

export default TkDrawerCustomerSelector;
