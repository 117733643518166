import { useRecoilState } from 'recoil';

import { ExtendedDrawers, MainDrawers } from './availableDrawers';
import {
  CallDrawer,
  Drawer,
  ExtendedDrawersTypes,
  MainDrawersTypes,
} from './drawer.types';
import { extendedDrawerState, mainDrawerState } from '@recoilData/index';

export const useDrawer = () => {
  enum DrawerTypes {
    MAIN = 'MAIN',
    EXTENDED = 'EXTENDED',
  }

  const [mainDrawer, setMainDrawer] = useRecoilState(mainDrawerState);
  const [extendedDrawer, setExtendedDrawer] =
    useRecoilState(extendedDrawerState);

  const closeDrawers = (extendedOnly = false) => {
    closeExtDrawer();

    if (extendedOnly) return;

    setMainDrawer({
      id: null,
      Component: mainDrawer?.Component ?? null,
    });
  };

  const closeExtDrawer = () => {
    setExtendedDrawer({
      id: null,
      Component: extendedDrawer?.Component ?? null,
      props: extendedDrawer?.props ?? null,
    });
  };

  const callDrawer = (drawer: { main?: CallDrawer; extended?: CallDrawer }) => {
    const { main, extended } = drawer;

    if (main) {
      /*  if (mainDrawer && main.id === mainDrawer.id) {
        closeDrawers();
        return;
      } */

      const Drawer = MainDrawers[main.id];

      setMainDrawer({
        id: main.id,
        Component: Drawer,
        props: main?.props,
      });
    }

    if (extended) {
      if (extendedDrawer && extended.id === extendedDrawer?.id) {
        closeDrawers(true);
        return;
      }

      const ExtDrawer = ExtendedDrawers[extended.id];
      setExtendedDrawer({
        id: extended.id,
        Component: ExtDrawer,
        props: extended?.props,
      });
    }
  };

  const setDrawer = (drawer: Drawer, type: `${DrawerTypes}`) => {
    if (type === DrawerTypes.MAIN) {
      setMainDrawer(drawer);
    }

    if (type === DrawerTypes.EXTENDED) {
      setExtendedDrawer(drawer);
    }
  };

  return {
    currentDrawer: {
      main: {
        id: mainDrawer?.id ?? null,
        Component: mainDrawer?.Component ?? null,
        props: mainDrawer?.props ?? null,
      },
      extended: {
        id: extendedDrawer?.id ?? null,
        Component: extendedDrawer?.Component ?? null,
        props: extendedDrawer?.props ?? null,
      },
    },
    setDrawer,
    callDrawer,
    closeDrawers,
    closeExtDrawer,
    types: { DrawerTypes, MainDrawersTypes, ExtendedDrawersTypes },
  };
};
