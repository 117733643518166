import TkIcon from '@components/TkIcon';
import TkTooltip from '@components/TkTooltip';
import { styled, Tab, TabProps } from '@mui/material';
import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { useTranslation } from 'react-i18next';

interface StyledTabProps extends TabProps {
  hasChange: boolean;
}
const TabStyle = styled(({ style, ...props }: StyledTabProps) => (
  <Tab {...props} style={style} />
))(() => ({
  height: '48px',
  width: '225px',
  minHeight: '48px',
}));
//

export const TKTab = (props) => {
  const { t } = useTranslation();
  return (
    <TabStyle
      {...props}
      icon={
        props?.hasChange ? (
          <TkTooltip
            placeholder="top"
            title={`${t('unpublished_changes', { ns: 'bookingWidget' })}`}
          >
            <TkIcon
              className="fa fa-pencil"
              color="#4952A9"
              style={{
                fontSize: '12px',
                marginBottom: '-2px',
                marginLeft: '6px',
                cursor: 'pointer',
              }}
            />
          </TkTooltip>
        ) : undefined
      }
      iconPosition="end"
    />
  );
};
