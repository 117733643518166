export default {
  step1: {
    title: "Créez et Assignez des Tâches",
    message:
      "Gérez votre équipe en créant et en assignant des tâches. Automatisez vos tâches quotidiennes et soyez notifié(e) à mesure qu'elles sont réalisées.",
  },
  step2: {
    title: "Invitez vos Collaborateurs/trices",
    message:
      "Invitez vos employé(e)s à collaborer en utilisant notre application mobile et suivez ainsi le travail de vos équipes et la réalisation de leurs tâches. Demandez-leur d'indiquer leurs disponibilités et évitez ainsi les rendez-vous manqués.",
  },
  step3: {
    title: "Ajouter des Client(e)s",
    message:
      "Ajoutez des client(e)s et commencez à organiser des tâches pour vos client(e)s (individuel(le)s et entreprises).",
  },
  step4: {
    title: "Gardez une trace de votre travail",
    message:
      "Organisez et documentez votre travail grâce aux possibilités de prise de notes, d'ajout de photos et de liste de tâches avec photos illustratives. Demandez à vos collaborateurs/trices d'ajouter des photos de certains éléments de votre liste de tâches pour en assurer la qualité.",
  },
  step5: {
    title: "Synchronisez un Calendrier",
    message:
      "Importez les événements prévus dans votre calendrier directement sur Taskbird en ajoutant votre Calendrier Google ou autre/calendrier iCal. Votre emploi du temps organisé en un seul endroit vous aidera à éviter tout raté.",
  },
  step6: {
    title: "Intégrez votre compte à Turno",
    message:
      "Intégrez votre compte à la plateforme leader pour l'entretien des logements de vacances et importez vos missions et client(e)s dans Taskbird depuis Turno.",
  },
};
