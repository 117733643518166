export default {
  widget: {
    welcomeCall: {
      title: 'Ten una demo gratuita',
      description:
        '<0>¿Necesitas ayuda personalizada para comenzar</0>? Agenda una llamada con nuestros especialistas en satisfacción del cliente para obtener el mayor valor posible de Taskbird.',
      footer: 'I don’t want a Demo Now',
    },
  },
};
