import styled from 'styled-components';

import { DialogContent, DialogActions, Grid } from '@mui/material';

import { getColorFromTheme } from '@helpers/index';

export const ModalContent = styled(DialogContent)`
  .MuiFormLabel-root {
    font: normal normal normal 18px/20px Lato;
    top: -5px;
    color: ${(props) => getColorFromTheme(props.theme.palette, 'default')};
  }
  &.MuiDialogContent-dividers {
    border-top: 0;
  }
`;

export const ModalActions = styled(DialogActions)`
  padding: 1rem;
  border-top: 1px solid #f0f0f0;
  justify-content: center;
`;

export const TextModal = styled(Grid)`
  padding: 10px 0 5px 0;
  max-width: 352px;
`;

export const SubTextModal = styled(Grid)`
  padding-bottom: 10px;
`;

export const DeleteIconModal = styled(Grid)`
  padding: 10px;
`;
