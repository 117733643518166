import { customersData } from "@recoilData/index";
import { selectorFamily, selector } from "recoil";

const searchCustomersData = selectorFamily({
  key: "searchCustomersData",
  get: (search: string) => ({ get }) => {
    const customers = get(customersData);
    if (!search) return customers;
    return customers.filter((item) => {
      return item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase());
    });
  },
});

const getCustomersDataCount = selector({
  key: "getCustomersDataCount",
  get: ({ get }) => {
    const customers = get(customersData);
    return customers.length;
  },
});

export { searchCustomersData, getCustomersDataCount };
