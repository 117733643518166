import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ISimpleObject } from 'types/common.type';

import { Grid } from '@mui/material';

import {
  ContentWrapper,
  InputDaily,
  OptionWrapper,
  WeekItem,
  WeekItemChecked,
} from '../styles';

interface ITkTaskRepeatOptionsWeekly {
  taskRepeat: ITaskRepeat;
  setTaskRepeat: (taskRepeat: ITaskRepeat) => void;
  handleIntervalChange: (value: string) => void;
}

const TkTaskRepeatOptionsWeekly: React.FC<ITkTaskRepeatOptionsWeekly> = ({
  taskRepeat,
  setTaskRepeat,
  handleIntervalChange,
}) => {
  const { t } = useTranslation();

  const [weekOptionList, setWeekOptionList] = useState<ISimpleObject[]>([
    { id: 0, value: 'su', display: 'sunday', checked: false },
    { id: 1, value: 'mo', display: 'monday', checked: false },
    { id: 2, value: 'tu', display: 'tuesday', checked: false },
    { id: 3, value: 'we', display: 'wednesday', checked: false },
    { id: 4, value: 'th', display: 'thursday', checked: false },
    { id: 5, value: 'fr', display: 'friday', checked: false },
    { id: 6, value: 'sa', display: 'saturday', checked: false },
  ]);

  const handleCheckWeekItem = (newItem: ISimpleObject) => {
    if (!newItem.checked) {
      const oL: string[] = [];
      taskRepeat.weeklyDaysRepetition.forEach((item) => oL.push(item));
      oL.push(newItem.value);
      setTaskRepeat({ ...taskRepeat, weeklyDaysRepetition: oL });
    } else {
      const oL: string[] = [];
      taskRepeat.weeklyDaysRepetition.forEach((item) => {
        if (item !== newItem.value) oL.push(item);
      });
      setTaskRepeat({ ...taskRepeat, weeklyDaysRepetition: oL });
    }

    const nL = weekOptionList.map((i) => {
      if (i.id === newItem.id) i.checked = !i.checked;
      return i;
    });

    setWeekOptionList(nL);
  };

  return (
    <OptionWrapper container justifyContent="center" alignItems="center">
      <Grid container justifyContent="center" alignItems="baseline">
        {t('every', { ns: 'common' })}
        <InputDaily
          type="number"
          value={taskRepeat.interval}
          onChange={(e) => handleIntervalChange(e.target.value)}
        />
        {t('weeksOn', { ns: 'common' }).toLowerCase()}:
      </Grid>
      <ContentWrapper container justifyContent="center" direction="row">
        {weekOptionList.map((i) =>
          i.checked ? (
            <WeekItemChecked onClick={() => handleCheckWeekItem(i)}>
              {t(`weekdays.${i.display}`, { ns: 'common' }).substring(0, 1)}
            </WeekItemChecked>
          ) : (
            <WeekItem onClick={() => handleCheckWeekItem(i)}>
              {t(`weekdays.${i.display}`, { ns: 'common' }).substring(0, 1)}
            </WeekItem>
          )
        )}
      </ContentWrapper>
    </OptionWrapper>
  );
};

export default TkTaskRepeatOptionsWeekly;
