import React from 'react';

import { ComponentWithChildren } from 'types/common.type';

import { createGenerateClassName, StylesProvider } from '@mui/styles';

const generateClassName = createGenerateClassName({
  productionPrefix: 'v5',
});

export const TkStyleProvider = ({
  children,
}: ComponentWithChildren): JSX.Element => {
  return (
    <StylesProvider generateClassName={generateClassName}>
      {children}
    </StylesProvider>
  );
};
