import { useMutation, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';

import { ProjectOfferBulkAction } from '@features/projectOffers/models';
import { getUserAccountId } from '@recoilData/index';
import api from '@services/api';
//
export const useProjectOfferBulkAction = () => {
  const accountId = useRecoilValue(getUserAccountId);
  const queryClient = useQueryClient();
  const { mutate: handleProjectOfferBulkAction, isLoading: isBulkingMutating } =
    useMutation(
      ({
        projectOfferIDs,
        type,
      }: {
        projectOfferIDs: number[];
        type: ProjectOfferBulkAction;
      }) =>
        api.post(`/projectOffer/${type}`, {
          account_id: accountId,
          project_offers: projectOfferIDs,
        }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries('accountEvents');
        },
        onError: () => {
          queryClient.invalidateQueries('accountEvents');
        },
      }
    );
  return { handleProjectOfferBulkAction, isBulkingMutating };
};
