import { lazy } from 'react';
import { withAuth } from '@contexts/AuthContext/hocs';

const ProfilePageRoot = lazy(() =>
  /* webpackChunkName: profile-page */
  import('@pages/Profile/components/settings-page')
);

const ProfileRoute = [
  {
    path: '/settings/:tab',
    component: withAuth(ProfilePageRoot),
  },
];

export default ProfileRoute;
