import React, { useState } from 'react';

import {
  RecoilState,
  SetterOrUpdater,
  useRecoilValue,
  useSetRecoilState,
} from 'recoil';

import { Grid } from '@mui/material';

import { PopoverContainer, PopoverWrapper, PopoverListWrapper } from './styles';
import { PopoverStyle, PopoverOrigin } from './types.type';
import { blankSelectAll, blankSelectAllData } from '@recoilData/index';

interface TkDropDownProps {
  isAllDataSelected?: RecoilState<boolean>;
  HeaderPopover?: ({
    selectAll,
    selectAllItems,
  }: {
    selectAll: boolean;
    selectAllItems: SetterOrUpdater<boolean>;
  }) => JSX.Element;
  Activator: ({ toggle }: { toggle: any }) => JSX.Element;
  ListItem: () => JSX.Element;
  popoverStyle?: PopoverStyle;
  selectAllData?: RecoilState<boolean>;
}

const TkDropDown = ({
  isAllDataSelected,
  Activator,
  HeaderPopover,
  ListItem,
  popoverStyle,
  selectAllData,
}: TkDropDownProps): JSX.Element => {
  const [toggle, setToggle] = useState(false);
  const selectAll = useRecoilValue(isAllDataSelected ?? blankSelectAll);
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const anchorOrigin: PopoverOrigin = {
    vertical: popoverStyle?.PopoverAnchorOrigin?.vertical ?? 'bottom',
    horizontal: popoverStyle?.PopoverAnchorOrigin?.horizontal ?? 'left',
  };

  const transformOrigin: PopoverOrigin = {
    vertical: popoverStyle?.PopoverTransformOrigin?.vertical ?? 'top',
    horizontal: popoverStyle?.PopoverTransformOrigin?.horizontal ?? 'left',
  };

  const selectAllItems = useSetRecoilState(selectAllData ?? blankSelectAllData);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
    setToggle((prevState) => !prevState);
  };

  return (
    <div>
      <div onClick={(e) => handleClick(e)}>
        <Activator toggle={toggle} />
      </div>
      <PopoverContainer
        id={toggle ? 'simple-popover' : undefined}
        open={toggle}
        anchorEl={anchorEl}
        onClose={() => setToggle(false)}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        marginTop={popoverStyle?.marginTop ?? '5px'}
      >
        <PopoverWrapper
          width={popoverStyle?.width ?? '247px'}
          height={popoverStyle?.height ?? '356px'}
          padding={popoverStyle?.padding ?? '0 10px'}
        >
          <Grid container>
            {HeaderPopover && selectAllData && (
              <HeaderPopover
                selectAll={selectAll}
                selectAllItems={selectAllItems}
              />
            )}
          </Grid>
          <PopoverListWrapper width={popoverStyle?.width ?? '237px'}>
            <ListItem />
          </PopoverListWrapper>
        </PopoverWrapper>
      </PopoverContainer>
    </div>
  );
};

export default TkDropDown;
