import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputContent = styled.div`
  width: 100%;
  max-width: 236.5px;
  height: 20px;
  display: flex;

  .Iconclass {
    .fa {
      font-weight: 700;
    }

    margin-right: 9px;
    margin-top: 2px;
    opacity: 0.7;
  }

  .Inputclass {
    width: 100%;

    .MuiInputBase-root {
      width: 100%;
      height: 100%;

      &.MuiInput-underline {
        &:before {
          border-bottom: 1px solid rgba(153, 153, 153, 0.5);
        }
      }

      span.fa {
        margin-bottom: 6px;
        margin-right: 5px;
        cursor: pointer;
      }

      > input {
        text-align: left;
        letter-spacing: 0px;
        color: #999999;
        padding: 0 2px;
        margin: 0px 0px 10px 3px;
        font-size: 14px;

        ::placeholder {
          color: rgba(88, 88, 88, 1);
        }

        &:focus {
          margin: 0px 0px 10px 3px;
          letter-spacing: 0px;
          color: #3d4465;
          opacity: 1;
        }
      }
    }
  }
`;
