import { atom } from 'recoil';
const serviceDataErrors = atom<any /*ITkItemServicePropertiesErrors*/>({
  key: 'serviceDataErrors',
  default: {
    //Details
    name: { state: false, description: 'services_details_name_error' },
    price: { state: false, description: 'services_details_price_error' },
    duration: { state: false, description: 'services_details_duration_error' },
    //Hours

    //Preferences
  },
});

export { serviceDataErrors };
