import React from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { Grid, Box } from '@mui/material';

import {
  TkTaskIdWrapper,
  TaskId,
  TkTaskWrapperItem,
  TaskNameDisplay,
  EditTaskTimeItem,
  VerticalDivider,
  HorizontalDivider,
} from './styles';
import { useDeletedEvent } from '../hooks/useDeletedEvent';
import { TkTaskWrapper } from '@components/Drawers/TkNewTaskDrawer/styles';
import { TkIcon, TkTypography } from '@components/index';

interface IDeletedEvent {
  events: IEvent;
  handleDismiss: () => void;
}

const TkDeletedEvent = ({
  events,
  handleDismiss,
}: IDeletedEvent): JSX.Element => {
  const { t } = useTranslation();
  const { name, customer, location } = useDeletedEvent();

  const startTime = DateTime.fromISO(events.start).toJSDate();
  const endTime = DateTime.fromISO(events.end).toJSDate();

  return (
    <>
      <TkTaskIdWrapper>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <TaskId item>
            <TkTypography
              fontWeight="bold"
              fontSize={16}
              fontFamily="Muli"
              textAlign="center"
              marginTop={1}
            >
              {t('task', { ns: 'common' })} #{events.id}
            </TkTypography>
          </TaskId>
          <Grid direction="row">
            <TkIcon
              className="fa fa-chevron-right"
              color="#011F41"
              verticalAlign="middle"
              marginLeft={30}
              style={{ cursor: 'pointer' }}
              onClick={() => handleDismiss()}
            />
          </Grid>
        </Grid>
      </TkTaskIdWrapper>
      <TkTaskWrapper style={{ padding: 0 }}>
        <TkTaskWrapperItem padding="0 1rem 10px">
          <TaskNameDisplay
            container
            justifyContent="center"
            alignItems="center"
          >
            <TkTypography
              fontWeight="bold"
              fontSize={20}
              fontFamily="Muli"
              textAlign="center"
              marginTop={1}
              userselect="none"
            >
              {name}
            </TkTypography>
          </TaskNameDisplay>
        </TkTaskWrapperItem>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <EditTaskTimeItem id="datepicker-left">
            <TkTypography fontSize={12} fontFamily="Lato" color="#999999">
              {t('start_by', { ns: 'common' })}
            </TkTypography>
            <>
              <TkTypography
                fontSize={20}
                fontFamily="Muli"
                fontWeight="bold"
                marginTop="5px"
              >
                {DateTime.fromJSDate(startTime).toFormat('t')}
              </TkTypography>
              <TkTypography fontSize={14} fontFamily="Lato">
                {DateTime.fromJSDate(startTime).toFormat("LLL dd '('ccc')'")}
              </TkTypography>
            </>
          </EditTaskTimeItem>
          <VerticalDivider />
          <EditTaskTimeItem id="datepicker-right">
            <TkTypography fontSize={12} fontFamily="Lato" color="#999999">
              {t('finish_by', { ns: 'common' })}
            </TkTypography>
            <>
              <TkTypography
                fontSize={20}
                fontFamily="Muli"
                fontWeight="bold"
                marginTop="5px"
              >
                {endTime ? DateTime.fromJSDate(endTime).toFormat('t') : ''}
              </TkTypography>
              <TkTypography fontSize={14} fontFamily="Lato">
                {endTime
                  ? DateTime.fromJSDate(endTime).toFormat("LLL dd '('ccc')'")
                  : ''}
              </TkTypography>
            </>
          </EditTaskTimeItem>
        </Grid>
        <TkTaskWrapperItem
          padding="10px 0"
          justifyContent="center"
          width="100%"
          alignItems="center"
          flexDirection="row"
        >
          <TkTypography fontFamily="Muli" fontSize={18} fontWeight="bold">
            <TkIcon
              className="fa fa-trash"
              iconSize={18}
              color="#E80B18"
              marginRight={5}
              verticalAlign="middle"
            />
            {t('deletedTask.warning', { ns: 'events' })}
          </TkTypography>
        </TkTaskWrapperItem>
        <TkTaskWrapperItem padding="5px 1rem">
          <HorizontalDivider />
        </TkTaskWrapperItem>
        <TkTaskWrapperItem padding="10px 1rem">
          <TkTypography
            fontSize={16}
            fontFamily="Lato"
            fontWeight="normal"
            color="#011F41"
            display="inline"
          >
            <Grid container justifyContent="space-between">
              <Box width="auto" position="relative">
                <Box fontSize={16} display="inline" marginRight="5px">
                  <TkIcon
                    className="fa fa-user-tie"
                    color="#011F41"
                    fontSize="inherit"
                  />
                </Box>
                {t('customer', { ns: 'common' })}:
                <TkTypography
                  fontSize={16}
                  display="inline"
                  fontFamily="Lato"
                  fontWeight="bold"
                  color="#011F41"
                  marginLeft={1}
                >
                  {customer !== undefined
                    ? customer.name
                    : t('no_customer', { ns: 'common' })}
                </TkTypography>
              </Box>
            </Grid>
          </TkTypography>
        </TkTaskWrapperItem>
        <TkTaskWrapperItem padding="5px 1rem">
          <HorizontalDivider />
        </TkTaskWrapperItem>
        <TkTaskWrapperItem padding="10px 1rem">
          <TkTypography
            fontSize={16}
            fontFamily="Lato"
            fontWeight="normal"
            color="#011F41"
            display="inline"
          >
            <Grid container justifyContent="space-between">
              <Box width="auto" position="relative">
                <Box fontSize={16} display="inline" marginRight="5px">
                  <TkIcon
                    className="fa fa-map-marker-alt"
                    color="#011F41"
                    fontSize="inherit"
                  />
                </Box>
                {t('location', { ns: 'common' })}:
                <TkTypography
                  fontSize={16}
                  display="inline"
                  fontFamily="Lato"
                  fontWeight="bold"
                  color="#011F41"
                  marginLeft={1}
                >
                  {location !== undefined
                    ? location.alias
                    : t('noLocation', { ns: 'common' })}
                </TkTypography>
              </Box>
            </Grid>
          </TkTypography>
        </TkTaskWrapperItem>
      </TkTaskWrapper>
    </>
  );
};

export default TkDeletedEvent;
