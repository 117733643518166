import React, { useEffect } from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';

import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, Grid } from '@mui/material';

import { ProjectOfferBulkAction } from '../../models';
import { ProjectOfferItem } from '../projectOfferItem';
import { MultipleOffersModal } from '@components/TkProjectOffersDrawer/MultipleOffersModal';
import {
  TkTaskActions,
  TkTaskActionsTop,
  TkTaskWrapper,
} from '@components/TkProjectOffersDrawer/styles';
import { DynamicButton, TkTypography } from '@components/index';
import { useModal, useSnackbar as useNotification } from '@contexts/index';
import {
  useAccountProjectOffers,
  useProjectOfferBulkAction,
  useProjectOfferMutation,
} from '@features/projectOffers/hooks';
import { useOfferDrawer } from '@pages/Home/hooks';
import { getUserAccountId } from '@recoilData/index';

export enum ProjectOfferRequestAction {
  Accept = 'accept',
  Reject = 'reject',
}

const ProjectOfferRequestActionTextToken = {
  [ProjectOfferBulkAction.AcceptAll]: 'acceptedSuccessfully',
  [ProjectOfferBulkAction.RejectAll]: 'rejectedSuccessfully',
} as const;

export const ProjectOffersList = (): JSX.Element => {
  const accountId = useRecoilValue(getUserAccountId);
  const queryClient = useQueryClient();
  const { closeProjectOfferList } = useOfferDrawer();

  const { fireTSuccess } = useNotification();
  const { setupModal, handleCloseModal } = useModal();
  const { t } = useTranslation();

  const { projectOffers, isLoading } = useAccountProjectOffers();
  const projectOfferRequestsCount = projectOffers
    ? Object.entries(projectOffers)
        ?.map((project) => project[1].map((p) => p.id))
        .flat().length
    : 0;
  const { handleProjectAction, isMutating } = useProjectOfferMutation();
  const { handleProjectOfferBulkAction, isBulkingMutating } =
    useProjectOfferBulkAction();
  const MINIMUM_BULK_ACTION_REQUESTS = 5;

  useEffect(() => {
    if (projectOfferRequestsCount === 0 && !isMutating) {
      closeProjectOfferList();
    }
  }, [projectOfferRequestsCount, isMutating]);

  const handleBulkProjectAction = (type: ProjectOfferBulkAction) => {
    setupModal({
      content: (
        <MultipleOffersModal
          handleCloseModal={handleCloseModal}
          type={type}
          onSubmitAction={() => {
            handleProjectOfferBulkAction(
              {
                projectOfferIDs: Object.entries(projectOffers)
                  ?.map((project) => project[1].map((p) => p.id))
                  .flat(),
                type,
              },
              {
                onSuccess: (data) => {
                  handleCloseModal();
                  fireTSuccess(`${ProjectOfferRequestActionTextToken[type]}`, {
                    ns: 'offers',
                    // @ts-ignore
                    offerCount: data?.data.length,
                  });
                  queryClient.invalidateQueries([
                    'accountProjectOffers',
                    accountId,
                  ]);
                },
              }
            );
          }}
        />
      ),
    });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <TkTaskActionsTop style={{ minHeight: '50px' }}>
        <TkTypography fontWeight="bold" fontFamily="Lato" fontSize={16}>
          {t('requests', { ns: 'offers' })}
        </TkTypography>
        <CloseIcon
          onClick={closeProjectOfferList}
          style={{ cursor: 'pointer', color: 'black' }}
          fontSize="small"
        />
      </TkTaskActionsTop>
      <TkTaskWrapper>
        {Object.entries(projectOffers)
          .sort((a, b) => {
            return new Date(a[0]).getDate() - new Date(b[0]).getDate();
          })
          .map((projectOffer, index) => (
            <Grid container key={`projectOffer-${index}`}>
              <Grid
                container
                style={{
                  backgroundColor: '#ECEDF6',
                  width: '100vw',
                  position: 'relative',
                  padding: '4px 0px 4px 2vw',
                  marginLeft: '-16px',
                  marginRight: '-16px',
                  overflow: 'hidden auto',
                }}
              >
                <Grid
                  container
                  style={{
                    marginLeft: '-15px',
                  }}
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <Grid item xs="auto">
                    <CalendarTodayIcon
                      fontSize="small"
                      style={{ marginTop: '5px' }}
                    />
                  </Grid>
                  <Grid item xs="auto">
                    <TkTypography
                      fontWeight="bold"
                      fontFamily="Lato"
                      fontSize={14}
                      userselect="none"
                      marginLeft="5px"
                    >
                      {DateTime.fromISO(projectOffer[0]).toFormat(
                        'cccc, LLLL d'
                      )}
                    </TkTypography>
                  </Grid>
                </Grid>
              </Grid>
              {projectOffer[1].map((project) => (
                <ProjectOfferItem
                  key={project.id}
                  projectOffer={project}
                  handleProjectAction={handleProjectAction}
                  isMutating={isMutating}
                />
              ))}
            </Grid>
          ))}
      </TkTaskWrapper>
      {projectOfferRequestsCount >= MINIMUM_BULK_ACTION_REQUESTS && (
        <TkTaskActions>
          <DynamicButton
            border="1"
            variant="outlined"
            color="babyBlue"
            disableElevation
            onClick={() =>
              handleBulkProjectAction(ProjectOfferBulkAction.RejectAll)
            }
            style={{ minWidth: '8rem' }}
          >
            {isBulkingMutating ? (
              <CircularProgress color="inherit" size="1.5rem" />
            ) : (
              <TkTypography fontWeight="normal" fontFamily="Lato" fontSize={16}>
                {t('rejectAll', { ns: 'offers' })}
              </TkTypography>
            )}
          </DynamicButton>
          <DynamicButton
            border="1"
            variant="contained"
            bgcolor="primary"
            disableElevation
            onClick={() =>
              handleBulkProjectAction(ProjectOfferBulkAction.AcceptAll)
            }
            style={{ minWidth: '8rem' }}
          >
            {isBulkingMutating ? (
              <CircularProgress color="inherit" size="1.5rem" />
            ) : (
              <TkTypography fontWeight="normal" fontFamily="Lato" fontSize={16}>
                {t('acceptAll', { ns: 'offers' })}
              </TkTypography>
            )}
          </DynamicButton>
        </TkTaskActions>
      )}
    </>
  );
};
