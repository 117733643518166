import React from 'react';
import { ActivatorSlot } from './styles';
import TkIcon from '@components/TkIcon';
import { DateTime } from 'luxon';
import { ICalendarViewEnum } from '@consts/index';

const CalendarDatePickerActivator = (props: any) => {
  let selectedDate = DateTime.fromFormat(props.value, 'M/d/y');
  let title = '';
  if (props.gridView === ICalendarViewEnum.MONTH_VIEW) {
    title = selectedDate.toFormat('LLLL y');
  } else if (props.gridView === ICalendarViewEnum.WEEK_VIEW) {
    let startRange = selectedDate.set({ weekday: 0 });
    let endRange = selectedDate.set({ weekday: 6 });
    let year = startRange.toFormat('y');
    title = `${startRange.toFormat('LLLL d')}-${endRange.toFormat(
      'd'
    )}, ${year}`;
  }
  return (
    <ActivatorSlot>
      <span>
        {title}
        <TkIcon className="fas fa-angle-down" color="primary" />
      </span>
    </ActivatorSlot>
  );
};

export default CalendarDatePickerActivator;
