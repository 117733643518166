import React from 'react';
import { TkTypography } from '@components/index';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { TimeItem } from './styles';
import { VerticalDivider } from '../styles';
import { Grid } from '@mui/material';

interface ITkDrawerTimeInfo {
  start: DateTime | null;
  finish: DateTime | null;
}

const TkDrawerTimeInfo: React.FC<ITkDrawerTimeInfo> = ({ start, finish }) => {
  const { t } = useTranslation();

  return (
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <TimeItem>
        <TkTypography fontSize={12} fontFamily="Lato" color="#999999">
          {t('start_by', { ns: 'common' })}
        </TkTypography>
        <TkTypography
          fontSize={20}
          fontFamily="Muli"
          fontWeight="bold"
          marginTop="5px"
        >
          {start ? start.toFormat('t') : ''}
        </TkTypography>
        <TkTypography fontSize={14} fontFamily="Lato">
          {start ? start.toFormat("LLL dd '('ccc')'") : ''}
        </TkTypography>
      </TimeItem>
      <VerticalDivider />
      <TimeItem>
        <TkTypography fontSize={12} fontFamily="Lato" color="#999999">
          {t('finish_by', { ns: 'common' })}
        </TkTypography>
        {finish && (
          <>
            <TkTypography
              fontSize={20}
              fontFamily="Muli"
              fontWeight="bold"
              marginTop="5px"
            >
              {finish.toFormat('t')}
            </TkTypography>
            <TkTypography fontSize={14} fontFamily="Lato">
              {finish.toFormat("LLL dd '('ccc')'")}
            </TkTypography>
          </>
        )}
      </TimeItem>
    </Grid>
  );
};

export default TkDrawerTimeInfo;
