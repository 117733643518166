import styled, { css } from "styled-components";
import { getColorFromTheme, getLighterColorFromTheme } from "@helpers/index";
import React from "react";

export const TkDatePickerWrapper = styled.div`
  .react-datepicker {
    z-index: 2;
    border: 0;
    box-shadow: 0px 3px 6px #00000033;
    width: 264px;
    min-height: 288px;
    font-family: Lato;
    /**/
    .react-datepicker__day.react-datepicker__day--keyboard-selected {
      background: none;
      &:not(.react-datepicker__day--disabled) {
        color: black;
      }
    }
    .react-datepicker__month-container {
      width: inherit;
      max-height: 288px;
      .react-datepicker__monthPicker {
        height: 244px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .react-datepicker__month--selected {
          background-color: ${(props) =>
            getColorFromTheme(props.theme.palette, "primary")};
          color: #fff;
        }
        .react-datepicker__month-wrapper {
          display: flex;
          justify-content: space-between;
          .react-datepicker__month-text {
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
    /*styling the Year View of the datepicker*/
    .react-datepicker__year-wrapper {
      width: 50%;
      margin: 0 auto;
      max-width: 210px;
      max-height: 250px;
      overflow: scroll;
      .react-datepicker__year-text {
        flex: 0 1 100%;
        height: 40px;
        &.react-datepicker__year-text--selected {
          color: #fff;
          font-size: 1.25rem;
          background-color: ${(props) =>
            getColorFromTheme(props.theme.palette, "primary")};
        }
        &.react-datepicker__year-text--keyboard-selected {
          background: none;
        }
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
      }
      ::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        height: 0;
        background: transparent; /* Optional: just make scrollbar invisible */
      }
      overflow-y: scroll;
      scrollbar-width: none;
    }
    /* styling the custom header for the datepicker*/
    .react-datepicker__custom-header {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      height: 40px;
      span {
        cursor: pointer;
      }
    }
    .react-datepicker__header {
      background-color: #f5f6fb;
      font-size: 1rem;
      font-weight: bold;
      border: 0;
    }
    .react-datepicker__day-names {
      background-color: #fff;
    }
    .react-datepicker__day--selected {
      background-color: ${(props) =>
        getColorFromTheme(props.theme.palette, "primary")};
      border-radius: 5px;
      color: #fff;
    }
    .react-datepicker__day--today {
      color: ${(props) => getColorFromTheme(props.theme.palette, "secondary")};
      &.react-datepicker__day--selected {
        color: #fff;
      }
    }
    .react-datepicker__day--outside-month {
      opacity: 0.4;
      border-radius: 0.3rem;
      background-color: #f0f0f0;
    }
    .react-datepicker__day--disabled.react-datepicker__day--excluded {
      color: #CBCBCB;
      pointer-events: none;
    }
    .react-datepicker__week {
      ${(props: ITkProps) =>
        props.children?.props.weekly &&
        css`
          &:hover {
            .react-datepicker__day {
              background-color: ${(props) =>
                getLighterColorFromTheme(
                  getColorFromTheme(props.theme.palette, "primary")
                )};
              border-radius: 5px;
              color: #fff;
            }
          }
        `}
      .react-datepicker__day--highlighted_week {
        background-color: ${(props) =>
          getColorFromTheme(props.theme.palette, "primary")};
        border-radius: 5px;
        color: #fff;
      }
    }
  }
`;
