export enum WeekViewTypesEnum {
  CUSTOMER = 'customer',
  ASSIGNEE = 'assignee',
  LOCATION = 'location',
}

export enum ExtendedObjectEnum {
  CUSTOMER = 'customer',
  ASSIGNEE = 'assignee',
}

export enum TaskStatusEnum {
  DONE = 'done',
  TODO = 'toDo',
  IN_PROGRESS = 'inProgress',
  UNASSIGNED = 'unassigned',
  NOT_STARTED = 'notStarted',
  NOT_COMPLETED = 'notCompleted',
  PLACEHOLDER = 'placeholder',
  REQUEST = 'request',
}

export enum CalendarFilterEnum {
  CUSTOMER = 'customer',
  ASSIGNEE = 'assignee',
  LOCATION = 'location',
}
