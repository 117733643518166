import React from 'react';
import { Grid } from '@mui/material';
import { CustomGrid } from './styles';
import { TkTypography } from '@components/index';
import { formatValueToCurrency } from '@helpers/index';

interface IServicesAttributes {
  serviceAttribute: IBookingServiceAttributes;
}

const TkExtendedDrawerServicesAttributes = ({
  serviceAttribute,
}: IServicesAttributes) => {

  return (
    <CustomGrid container>
      <TkTypography fontWeight="bold" fontSize={16}>
        {serviceAttribute.name}
      </TkTypography>
      {serviceAttribute.items.map((item) => (
       <Grid
          container
          direction="row"
          justifyContent="space-between"
          marginTop={'5px'}
        >
          <Grid maxWidth={'60%'}>
            <TkTypography fontSize={16} color={'#4952A9'}>
              {serviceAttribute.type === 'quantity' ? `${item.quantity} ${item.name}` : `${item.name}`}
            </TkTypography>
          </Grid>
          <Grid>
            <TkTypography fontSize={16}>
              {formatValueToCurrency(item.price)}
            </TkTypography>
          </Grid>
        </Grid>
      ))}
    </CustomGrid>
  );
};

export default React.memo(TkExtendedDrawerServicesAttributes);
