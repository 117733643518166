import React from 'react';

import TkGlobalDrawer from './components/TkGlobalDrawer';
import { Container, MainContainer } from './styles';
import TkNavbar from '@components/TkNavbar';

interface TkBasePageProps {
  children: React.ReactNode;
  childrenProps?: {
    direction?: React.CSSProperties['flexDirection'];
  };
  navBarContent?: React.ReactNode;
  navBarActionableButtons?: React.ReactNode;
}

const TkBasePage = ({
  children,
  navBarContent,
  navBarActionableButtons,
}: TkBasePageProps): JSX.Element => {
  return (
    <>
      <TkNavbar actionableButtons={navBarActionableButtons}>
        {navBarContent ? (
          <div style={{ display: 'flex', flex: 1 }}>{navBarContent}</div>
        ) : (
          <div style={{ width: '100%' }} />
        )}
      </TkNavbar>
      <MainContainer>
        <Container>{children}</Container>
        <TkGlobalDrawer />
      </MainContainer>
    </>
  );
};

export default TkBasePage;
