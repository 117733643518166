export default {
  address: 'Adresse',
  apt_unit: "N° d'appart. / Bâtiment",
  back: 'Précédent',
  cancel: 'Annuler',
  changes_successfully_saved: 'Modifications enregistrées avec succès',
  city: 'Ville',
  code: 'Code',
  connect: 'Synchroniser',
  contact: 'Contacter',
  couldnt_find_your_address: 'Vous ne trouvez pas votre adresse ?',
  create: 'Créer',
  date: 'Date',
  defaultError: 'Une erreur est survenue, veuillez réessayer plus tard.',
  delete: 'Supprimer',
  disabled: 'Désactivé',
  discard: 'Supprimer',
  edit: 'Modifier',
  email_address: 'Adresse E-mail',
  email: 'Email',
  emailPlaceholder: 'exemple@email.com',
  enabled: 'Activé',
  get_started: 'Commencer',
  greet: 'Prioritaire',
  lets_go: "C'est parti !",
  loading: 'En cours de chargement',
  location: 'Localisation',
  next: 'Suivant',
  ok: 'OK',
  phone_number: 'Numéro de Téléphone',
  please_select_a_time: 'Veuillez sélectionner un horaire',
  preview: 'Pré-visualisation',
  previous: 'Précédent',
  profile_url: 'Lien URL du Profil',
  publish: 'Publier',
  save_changes: 'Enregistrer les modifications',
  select_all_plural: 'Tout Désélectionner',
  select_all: 'Tout Sélectionner',
  select_crews: 'Sélectionner des Équipes',
  select_teammates: 'Sélectionner des Agent(e)s',
  send_message: 'Envoyer un message',
  sendMeTheApp: "Recevoir le lien vers l'appli mobile!",
  skip: 'Passer',
  social_networks: 'Réseaux Sociaux',
  state: 'État / Département',
  task: 'Tâche',
  type_phone_number: 'Saisissez un numéro de téléphone',
  unselect_all: 'Tout Désélectionner',
  zip_code: 'Code Postal',
  weekdays: {
    sunday: 'Dimanche',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    friday: 'Vendredi',
    saturday: 'Samedi',
  },
  months: {
    january: 'Janvier',
    february: 'Février',
    march: 'Mars',
    april: 'Avril',
    may: 'Mai',
    june: 'Juin',
    july: 'Juillet',
    august: 'Août',
    september: 'Septembre',
    october: 'Octobre',
    november: 'Novembre',
    december: 'Décembre',
  },
  // easy development use
  enabledBool: '$t(common:enabled)',
  enabledBool_plural: '$t(common:disabled)',
  dontSend: 'Ne pas envoyer',
  send: 'Envoyer',
  about: 'À propos',
};
