import React from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilState, RecoilState } from 'recoil';

import { Grid } from '@mui/material';

import {
  TkListItemSubText,
  TkChecklistItem,
} from '@components/Drawers/TkEditTaskDrawer/styles';
import { TkTypography, TkCheckBox } from '@components/index';
import { useDrawer } from '@hooks/index';

interface ChecklistListItemProps {
  checklist: IChecklistTask;
  eventChecklistsRecoilState(param: number): RecoilState<IEventChecklists>;
}

const TkChecklistListItem = ({
  checklist,
  eventChecklistsRecoilState,
}: ChecklistListItemProps): JSX.Element => {
  const { t } = useTranslation();
  const { closeExtDrawer } = useDrawer();

  const [checklistState, setChecklistState] = useRecoilState(
    eventChecklistsRecoilState(checklist.id)
  );

  const handleChange = (checked: boolean, unique = false) => {
    setChecklistState({ ...checklistState, checked });
    if (unique) closeExtDrawer();
  };

  return (
    <TkChecklistItem
      key={checklist.id}
      checked={checklistState.checked}
      control={
        <TkCheckBox
          checked={checklistState.checked}
          onChange={(_, checked) => handleChange(checked)}
        />
      }
      label={
        <div onClick={() => handleChange(!checklistState.checked, true)}>
          <Grid container direction="column">
            <TkTypography fontWeight="bold" fontFamily="Lato" fontSize={16}>
              {checklist.title}
            </TkTypography>
            <TkListItemSubText>
              {t('xItems', { ns: 'common', number: checklist.numberOfItems })}
            </TkListItemSubText>
          </Grid>
        </div>
      }
    />
  );
};

export default TkChecklistListItem;
