import { useBookingWidgetContext } from '@pages/BookingWidget/BookingWidgetContext';
import React from 'react';

import { TkMobileStepperWrapper } from './styles';

interface ITkMobileStepper {
  steps: number;
  color?: string;
  activeStep: number;
  backgroundColor?: string;
  isResponsiveView?: boolean;
}

const TkMobileStepper: React.FC<ITkMobileStepper> = ({
  color,
  steps,
  activeStep,
  backgroundColor = '#ffffff',
  isResponsiveView = false,
}) => {

  const { breakpointUpMd } = useBookingWidgetContext();

  return (
    <TkMobileStepperWrapper
      color={color}
      variant="progress"
      steps={steps}
      position="static"
      activeStep={activeStep}
      LinearProgressProps={{
        sx: {
          marginTop: isResponsiveView && !breakpointUpMd ? '2px' : 0,
          padding: 0,
          width: '100%',
          height: [4, 8],
          backgroundColor
        }
      }}
      sx={{
        backgroundColor: isResponsiveView && !breakpointUpMd ? '#e8e8e8' : '#fff',
        display: 'flex',
        alignItems: 'center',
        padding: 0
      }}
      nextButton={<></>} /** @ts-ignore */
      showBackButton={<></>}
    />
  );
};

export default TkMobileStepper;
