import { generateAssignee } from '@helpers/index';

export const setDefaultCrews = (crews: ICrew[]) => {
  const newCrewList = crews.map((crew) => {
    const newCrew: ICrew = {
      id: crew.id,
      name: crew.name,
      members: crew.members,
      checked: false,
    };

    return newCrew;
  });

  return newCrewList;
};

export const setDefaultCustomers = (customers: ICustomerTask[]) => {
  const newCustomersList = customers.map((customer) => {
    const newCustomer: ICustomerTask = {
      id: customer.id,
      name: customer.name,
      locations: customer.locations,
      account_id: customer.account_id,
      checked: false,
      createdAt: customer.createdAt,
      email: customer.email,
      phone_number: customer.phone_number,
      photo_url: customer.photo_url,
      street_address: customer.street_address,
    };

    return newCustomer;
  });

  return newCustomersList;
};

export const setDefaultLocations = (locations: ILocationTask[]) => {
  const newLocationsList = locations.map((location) => {
    const newLocation: ILocationTask = {
      id: location.id,
      alias: location.alias,
      number: location.number,
      street_address: location.street_address,
      streetAddress: location.streetAddress,
      accessCode: location.accessCode,
      access_code: location.access_code,
      checked: false,
      created_at: location.created_at,
      customerId: location.customerId,
      customerName: location.customerName,
      customer_id: location.customer_id,
      customer_name: location.customer_name,
      external_id: location.external_id,
      notes: location.notes,
      resynced: location.resynced,
      timezone: location.timezone,
      updated_at: location.updated_at,
      city: location.city ?? null,
      country: location.country ?? null,
      country_code: location.country_code ?? null,
      number_of_bedrooms: location.number_of_bedrooms ?? null,
      number_of_bathrooms: location.number_of_bathrooms ?? null,
      latitude: location.latitude ?? null,
      longitude: location.longitude ?? null,
      size: location.size ?? null,
      size_unit_value_id: location.size_unit_value_id ?? null,
      state: location.state ?? null,
      zip_code: location.zip_code ?? null,
      size_unit: location.size_unit ?? null,
      extraDetails: location.extraDetails ?? null,
    };

    return newLocation;
  });

  return newLocationsList;
};

export const setDefaultAssignees = (assignees: IAssignee[]) => {
  const newAssigneesList = assignees.map((assignee) =>
    generateAssignee(assignee, false)
  );

  return newAssigneesList;
};

export const setDefaultInvitations = (invitations: IUserInvite[]) => {
  const newInvitationsList = invitations.map((invitation) => {
    const newInvitation: IUserInvite = {
      id: invitation.id,
      email: invitation.email,
      created_at: invitation.created_at,
      phone_number: invitation.phone_number,
      invitation_token: invitation.invitation_token,
    };

    return newInvitation;
  });

  return newInvitationsList;
};

export const setDefaultChecklists = (checklists: any[]) => {
  const newChecklistList = checklists.map((checklist) => {
    const newChecklistItem: IChecklistTask = {
      checked: false,
      isMandatory: checklist.is_mandatory_checklist_default,
      id: checklist.id,
      tags: checklist.tags,
      title: checklist.title,
      author: checklist.author,
      is_public: checklist.is_public,
      used_count: checklist.used_count,
      numberOfItems: checklist.number_of_items,
      description: checklist.description,
      external_id: checklist.externalId
        ? Number(checklist.externalId)
        : undefined,
      is_official: checklist.is_official,
      likes_count: checklist.likes_count,
      views_count: checklist.views_count,
      completedItemsCount: checklist.completedItemsCount,
      externalId: checklist.externalId,
      commentsCount: checklist.commentsCount,
      projectChecklistId: checklist.projectChecklistId,
      items: checklist.items,
    };

    return newChecklistItem;
  });

  return newChecklistList;
};
