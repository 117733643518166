import styled from "styled-components";

export const RightImageContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: linear-gradient(315deg, #4952a9, #647eed);
`;

export const Step6Subtitle = styled.span`
  font-size: 14px;
`;