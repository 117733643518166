import { ITkInputProps } from '@interfaces/ITkInput';
import { PaletteProps } from '@material-ui/system';
import { Popper, TextField, Select, Grid } from '@mui/material';
import styled from 'styled-components';

interface ITkSelectWidgetBookingProps {
  isReadOnly: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SuggestionList = styled(Popper)`
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 7px;
  background-color: #ffffff;
  box-shadow: 0px 3px 10px #0000001a;
`;

export const SuggestionItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-family: 'Lato';
  font-size: 18px;
  padding: 5px;
  min-height: 25px;
  cursor: pointer;
`;

export const ContainerSpan = styled.div`
  width: 100%;
  display: flex;
  margin-top: 4px;
  height: 15px;
  position: relative;
  @media (min-width: 600px) {
    justify-content: flex-end;
    p {
      position: absolute;
      max-width: 50%;
    }
  }
`;

export const ContainerSpanAddress = styled(Grid)``;

export const TkInputWidgetBooking = styled(TextField)<
  PaletteProps & ITkInputProps
>`
  .MuiInput-root {
    margin-top: 15px;
    width: ${(props) => (props.width ? props.width : '100%')};
    &:hover:not(.Mui-disabled)::before {
      border-bottom: 1px solid rgb(153, 153, 153) !important;
    }
    &:has(.MuiInput-input[readonly]):after {
      border-bottom-color: transparent !important;
    }
  }
`;

export const TkSelectWidgetBooking = styled(
  Select
)<ITkSelectWidgetBookingProps>`
  margin-top: ${(props) => (props.error ? '0px' : '15px')};
  .MuiSelect-select {
    color: ${(props) => (props.value ? 'initial' : 'gray')};
    &:focus {
      background-color: transparent;
    }
  }
  &:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid rgb(153, 153, 153) !important;
  }
  &:after {
    border-bottom-color: ${({ isReadOnly }) =>
      isReadOnly ? 'transparent' : 'initial'} !important;
  }
`;
