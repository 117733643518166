import React from 'react';
import styled from 'styled-components';
import { TkIcon, TkInput } from '@components/index';
import { Box, Grid } from '@material-ui/core';

export const InputWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  user-select: none;
  width: 100%;
  ${(props) =>
    props.bgcolor === 'selected'
      ? `background-color: #011F41; color: #ffffff;`
      : ''}
`;

export const NameDisplay = styled(Grid)`
  width: 100%;
  padding: 1px;
  display: flex;
  position: relative;
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justify};
`;

export const TkInputEdit = styled((props) => <TkInput {...props} />)`
  border: 1px solid #4952a9;
  border-radius: 4px;
  display: flex;
  flex: 1;
  height: 36px;
  margin-right: 10px;
  box-shadow: -3px 3px 6px rgba(0, 0, 0, 0.1);

  .MuiInput-input {
    padding: 0 5px 0 5px;
    color: #011f41;
    font-size: 16px;
    font-family: 'Lato';
    font-weight: bold;

    &:focus {
      text-align: left;
    }
  }
`;

export const ButtonCustom = styled(Grid)`
  width: 40px;
  height: 34px;
  background-color: ${(props) => props.color};
  border: 1px solid #4952a9;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
`;
