export default {
  step1: {
    title: "Crie e Atribua Tarefas",
    message:
      "Gerencie sua equipe, criando e atribuindo tarefas. Automatize suas tarefas diárias e seja notificado à medida que as coisas acontecem.",
  },
  step2: {
    title: "Convide seus colegas de equipe",
    message:
      "Convide seus funcionários para colaborar usando o aplicativo móvel e acompanhar o trabalho de sua equipe e a conclusão das tarefas. Peça a eles que configurem sua disponibilidade e evitem o não comparecimento.",
  },
  step3: {
    title: "Adicione clientes",
    message:
      "Adicione clientes e comece a programar tarefas de seus clientes residenciais e comerciais.",
  },
  step4: {
    title: "Mantenha o controle de seu trabalho",
    message:
      "Organize e documente seus trabalhos com anotações, upload de imagens e listas de tarefas com documentação fotográfica. Solicite a seus colegas de equipe que adicionem fotos em tarefas específicas da lista de verificação para garantir a qualidade.",
  },
  step5: {
    title: "Sincronize Calendário",
    message:
      "Puxe os eventos programados de seu Calendário diretamente para o Taskbird adicionando seu Calendário Google ou Outros/iCals. Mantenha sua agenda organizada em um só lugar para que você nunca perca um projeto.",
  },
  step6: {
    title: "Integre com a Turno",
    message:
      "Integre-se com a plataforma de limpeza líder de aluguel de férias e puxe seus projetos e clientes diretamente para o Taskbird da Turno.",
  },
};
