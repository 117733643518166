import React, { useEffect, useRef, useState } from 'react';

import clsx from 'clsx';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import {
  Avatar as DefaultAvatar,
  ChannelPreviewUIComponentProps,
  useChatContext,
} from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';

import { TkIcon } from '@components/index';
import { userTimezone } from '@helpers/index';
import { generateChannelNames } from '@pages/ChatPage/helpers';

const UnMemoizedChannelPreviewMessenger = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(
  props: ChannelPreviewUIComponentProps<StreamChatGenerics>
) => {
  const {
    active,
    Avatar = DefaultAvatar,
    channel,
    className: customClassName = '',
    displayImage,
    displayTitle,
    lastMessage,
    latestMessage,
    onSelect: customOnSelectChannel,
    setActiveChannel,
    unread,
    watchers,
  } = props;

  const { t } = useTranslation();
  const { client } = useChatContext();
  const [isMyLastMessageRead, setIsMyLastMessageRead] = useState(false);
  const history = useHistory();

  const channelPreviewButton = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    const isRead = Object.values(channel.state.read).reduce(
      (_, currentValue) => {
        return currentValue.unread_messages > 0 ? false : true;
      },
      false
    );

    setIsMyLastMessageRead(isRead);
  }, [channel, unread]);

  const isLastMessageMine = lastMessage?.user?.id === client.userID;

  const lastDateMessage = channel.state.last_message_at
    ? DateTime.fromJSDate(channel.state.last_message_at).setZone(userTimezone())
    : undefined;

  const calculateDifference = (lastDateMessage: DateTime) => {
    const now = DateTime.now().setZone(userTimezone());

    const diff = now.diff(lastDateMessage, ['days', 'minutes']);

    if (diff.days > 1) return lastDateMessage.toFormat('LLL dd');
    if (diff.days === 1) return t('yesterday', { ns: 'common' });
    if (diff.days === 0) {
      if (diff.minutes < 1) return `${t('now', { ns: 'common' })}`;

      if (diff.minutes < 60)
        return `${t('today', { ns: 'common' })}, ${Math.round(diff.minutes)}m`;

      return `${t('today', { ns: 'common' })}, ${Math.round(
        diff.minutes / 60
      )}h`;
    }

    return '';
  };

  const lastDateToday = lastDateMessage
    ? calculateDifference(lastDateMessage)
    : '';

  const onSelectChannel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (customOnSelectChannel) {
      customOnSelectChannel(e);
    } else if (setActiveChannel) {
      setActiveChannel(channel, watchers);
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur();
    }

    history.push(`/chat?channel=${channel.id}`);
  };

  const channelName =
    displayTitle && displayTitle.length !== 0
      ? displayTitle
      : generateChannelNames(channel);

  return (
    <button
      aria-label={`Select Channel: ${displayTitle || ''}`}
      aria-selected={active}
      className={clsx(
        `str-chat__channel-preview-messenger str-chat__channel-preview`,
        active && 'str-chat__channel-preview-messenger--active',
        unread && unread >= 1 && 'str-chat__channel-preview-messenger--unread',
        customClassName
      )}
      style={{
        paddingLeft: '15px',
        paddingRight: '15px',
      }}
      data-testid="channel-preview-button"
      onClick={onSelectChannel}
      ref={channelPreviewButton}
      role="option"
    >
      <div className="str-chat__channel-preview-messenger--left">
        <Avatar image={displayImage} name={channelName} size={40} />
      </div>
      <div className="str-chat__channel-preview-messenger--right str-chat__channel-preview-end">
        <div className="str-chat__channel-preview-end-first-row">
          <div className="str-chat__channel-preview-messenger--name">
            <span>{channelName}</span>
          </div>
          <div
            style={{
              textAlign: 'right',
              color: '#7A7A7A',
              fontSize: '12px',
              fontFamily: 'Lato',
              fontWeight: 'normal',
            }}
            className="str-chat__channel-preview-messenger--name"
            data-testid="unread-badge"
          >
            {lastDateToday}
          </div>
        </div>
        <div className="str-chat__channel-preview-end-first-row">
          <div
            className="str-chat__channel-preview-messenger--name str-chat__channel-preview-messenger--last-message"
            style={{
              color: '#7A7A7A',
              fontFamily: 'Lato',
              fontSize: '14px',
              fontWeight: 'normal',
            }}
          >
            <span>{latestMessage}</span>
          </div>

          {unread ? (
            <div
              className="str-chat__channel-preview-unread-badge"
              style={{
                borderRadius: '50%',
                maxWidth: '20px',
                maxHeight: '20px',
                fontSize: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              data-testid="unread-badge"
            >
              {unread >= 99 ? '99+' : unread}
            </div>
          ) : (
            <div
              style={{
                borderRadius: '50%',
                padding: '0 2px',
                minWidth: '20px',
                height: '20px',
                fontSize: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {isMyLastMessageRead && isLastMessageMine ? (
                <>
                  <TkIcon
                    className="fas fa-check"
                    iconSize={12}
                    color="#2699FB"
                  />
                  <TkIcon
                    className="fas fa-check"
                    iconSize={12}
                    marginLeft={-6}
                    color="#2699FB"
                  />
                </>
              ) : isLastMessageMine && !isMyLastMessageRead ? (
                <TkIcon
                  className="fas fa-check"
                  iconSize={12}
                  color="#7A7A7A"
                />
              ) : null}
            </div>
          )}
        </div>
      </div>
    </button>
  );
};

/**
 * CUSTOM COMPONENT BASED ON THE ORIGINAL LIBRARY
 * https://github.com/GetStream/stream-chat-react/blob/master/src/components/ChannelPreview/ChannelPreviewMessenger.tsx
 **/
export const ChannelPreviewMessenger = React.memo(
  UnMemoizedChannelPreviewMessenger
) as typeof UnMemoizedChannelPreviewMessenger;
