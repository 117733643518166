import React from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilState, useSetRecoilState } from 'recoil';

import { Grid, Box, FormControlLabel } from '@mui/material';

import { ChecklistSelectedEdit, ChecklistSelectedNew } from './styles';
import { TkCheckBox, TkTooltip, TkIcon, TkTypography } from '@components/index';
import { TaskStatusEnum, EventTypeEnum } from '@consts/index';
import {
  eventChecklists as eventChecklistsNew,
  editEventChecklists,
  editEventSelectedChecklist,
  changeEditEventExtendedDrawer,
} from '@recoilData/index';

type ChecklistTaskType = `${EventTypeEnum}` | 'offer' | 'editRepeat';

interface TkChecklistItemProps {
  checklist: IChecklistTask;
  status?: string;
  eventChecklists?: IChecklistTask[];
  type: ChecklistTaskType;
}

const TkChecklistItemMemo = ({
  type,
  status,
  checklist,
  eventChecklists,
}: TkChecklistItemProps) => {
  const { t } = useTranslation();
  const changeExtendedDrawer = useSetRecoilState(changeEditEventExtendedDrawer);
  const [checkListEditState, setChecklistEditState] = useRecoilState(
    editEventChecklists(checklist.id)
  );
  const [checkListNewState, setChecklistNewState] = useRecoilState(
    eventChecklistsNew(checklist.id)
  );
  const [selectedChecklist, setSelectedChecklist] = useRecoilState(
    editEventSelectedChecklist
  );

  const eventChecklist = () => {
    if (type === EventTypeEnum.NEW) {
      return undefined;
    }

    return eventChecklists?.find(
      (evtChecklist) => evtChecklist.id === checklist.id
    );
  };

  const selectChecklist = () => {
    setSelectedChecklist(eventChecklist());
    if (status === TaskStatusEnum.DONE)
      changeExtendedDrawer('taskChecklistList');
  };

  const removeChecklist = () => {
    if (type === EventTypeEnum.NEW) {
      setChecklistNewState({ ...checkListNewState, checked: false });
    }

    if (type === 'editRepeat') {
      setChecklistEditState({ ...checkListEditState, checked: false });
    }
  };

  const handleMandatoryCompletion = (isMandatory: boolean) => {
    if (type === EventTypeEnum.EDIT) {
      setChecklistEditState({ ...checkListEditState, isMandatory });
    }

    if (type === EventTypeEnum.NEW) {
      setChecklistNewState({ ...checkListNewState, isMandatory });
    }
  };

  if (type === EventTypeEnum.EDIT) {
    if (status === TaskStatusEnum.TODO) {
      return (
        <ChecklistSelectedEdit container>
          <Grid container justifyContent="space-between">
            <Box mr={1} display="inline" width="auto" justifyContent="center">
              <TkTypography
                fontWeight="normal"
                fontFamily="Lato"
                fontSize={16}
                color="default"
              >
                {checklist.title}
              </TkTypography>
            </Box>
            <Box fontSize={16} width="30%">
              <TkTypography
                fontWeight="bold"
                fontFamily="Lato"
                fontSize={16}
                textAlign="right"
                color="default"
              >
                {t('xItems', { ns: 'common', number: checklist.numberOfItems })}
              </TkTypography>
            </Box>
          </Grid>
          <Grid container>
            <FormControlLabel
              key={checklist.id}
              checked={checkListEditState.isMandatory}
              control={
                <TkCheckBox
                  onChange={(_, checked) => handleMandatoryCompletion(checked)}
                  checked={checkListEditState.isMandatory}
                />
              }
              label={
                <TkTypography
                  fontSize={14}
                  fontFamily="Lato"
                  fontWeight="normal"
                  color="#011F41"
                >
                  {t('mandatoryCompletion', { ns: 'events' })}
                  <TkTooltip
                    title={`${t('mandatoryCompletionTooltip', {
                      ns: 'events',
                    })}`}
                  >
                    <TkIcon
                      marginLeft={10}
                      className="fa fa-info-circle"
                      color="#1565C0"
                      fontSize="inherit"
                      verticalAlign="text-top"
                    />
                  </TkTooltip>
                </TkTypography>
              }
            />
          </Grid>
        </ChecklistSelectedEdit>
      );
    }

    const evtChecklist =
      status === TaskStatusEnum.DONE ? eventChecklist() : checklist;

    return (
      <ChecklistSelectedEdit
        container
        bgcolor={selectedChecklist?.id === evtChecklist?.id ? 'selected' : ''}
        style={{
          padding: '10px',
        }}
        id={`checklist-${evtChecklist?.id}`}
        onClick={selectChecklist}
      >
        <Grid container justifyContent="space-between">
          <Box
            mr={1}
            display="inline"
            width="auto"
            justifyContent="center"
            maxWidth="50%"
          >
            <TkTypography
              fontWeight="normal"
              fontFamily="Lato"
              fontSize={16}
              color="default"
              style={{
                width: '100%',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {evtChecklist?.title}
            </TkTypography>
          </Box>
          <Box fontSize={16} width="45%">
            <TkTypography
              fontWeight="bold"
              fontFamily="Lato"
              fontSize={16}
              textAlign="right"
              color="default"
            >
              {t('xOfYDone', {
                ns: 'common',
                x: evtChecklist?.completedItemsCount ?? 0,
                y: evtChecklist?.numberOfItems,
              })}
              <TkIcon
                className="fa fa-comment"
                iconSize={16}
                width={18}
                color="default"
                marginLeft={10}
                marginRight={5}
                marginBottom={2}
                verticalAlign="text-bottom"
              />
              {evtChecklist?.commentsCount}
            </TkTypography>
          </Box>
        </Grid>
        {checkListEditState.isMandatory && (
          <Grid container>
            <TkTypography
              fontWeight="bold"
              fontFamily="Lato"
              fontSize={14}
              color="default"
            >
              <Box
                marginTop="5px"
                bgcolor="rgba(31, 155, 221, 0.3)"
                borderRadius="11px"
                padding="0 5px 2px 5px"
                justifyContent="center"
                alignItems="center"
              >
                {t('mandatory', { ns: 'events' })}
              </Box>
            </TkTypography>
          </Grid>
        )}
      </ChecklistSelectedEdit>
    );
  }

  if (type === EventTypeEnum.NEW || type === 'editRepeat') {
    return (
      <ChecklistSelectedNew container>
        <Grid container justifyContent="space-between">
          <Box mr={1} display="inline" width="90%" justifyContent="center">
            <TkTypography
              fontWeight="normal"
              fontFamily="Lato"
              fontSize={16}
              color="default"
            >
              {checklist.title}
            </TkTypography>
          </Box>
          <Box fontSize={16}>
            <TkIcon
              onClick={removeChecklist}
              className="fa fa-trash"
              color="#011F41"
              cursor="pointer"
            />
          </Box>
        </Grid>
        <Grid container>
          <FormControlLabel
            key={checklist.id}
            checked={checkListNewState.isMandatory}
            control={
              <TkCheckBox
                onChange={(_, checked) => handleMandatoryCompletion(checked)}
                checked={checkListNewState.isMandatory}
              />
            }
            label={
              <TkTypography
                fontSize={14}
                fontFamily="Lato"
                fontWeight="normal"
                color="rgba(1,31,65,0.8)"
              >
                {t('mandatoryCompletion', { ns: 'events' })}
                <TkTooltip
                  title={`${t('mandatoryCompletionTooltip', { ns: 'events' })}`}
                >
                  <TkIcon
                    marginLeft={10}
                    className="fa fa-info-circle"
                    color="#1565C0"
                    fontSize="inherit"
                    verticalAlign="text-top"
                  />
                </TkTooltip>
              </TkTypography>
            }
          />
        </Grid>
      </ChecklistSelectedNew>
    );
  }

  return (
    <Grid container justifyContent="space-between">
      <Grid
        container
        direction="row"
        style={{ margin: '0 0 5px 0', maxWidth: '80%' }}
      >
        <TkTypography fontSize={16} fontFamily="Lato">
          {checklist.title}
        </TkTypography>
      </Grid>
      <Grid justifyContent="flex-end">
        <TkTypography
          fontSize={16}
          fontFamily="Lato"
          marginLeft="5px"
          fontWeight="bold"
        >
          {`${checklist.items.length} ${t('items', {
            ns: 'common',
          }).toLowerCase()}`}
        </TkTypography>
      </Grid>
    </Grid>
  );
};

export default React.memo(TkChecklistItemMemo);
