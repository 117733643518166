import React from 'react';

import { DateTime } from 'luxon';
import styled from 'styled-components';

import {
  InputAdornment,
  FormControl,
  FormHelperText,
  TextField,
} from '@material-ui/core';

interface ITkDatePickerActivatorProps {
  ErrorForm?: boolean;
  ErrorFormMessage?: string;
  StartAdornment: any;
  EndAdornment: any;
  Placeholder: string;
  StartDate: Date | null;
  Format: string;
}

const TkInput = styled(TextField)`
  background: '#FFF';
  border-color: '#F5F6FB';

  &:hover {
    border-color: '#011f41';
  }
`;

const TkFormHelperText = styled(FormHelperText)`
  color: #ff3b30;
`;

const TkDatePickerActivator: React.FC<ITkDatePickerActivatorProps> = ({
  ErrorForm,
  ErrorFormMessage,
  StartAdornment,
  EndAdornment,
  Format,
  Placeholder,
  StartDate,
}) => {
  return (
    <FormControl>
      <TkInput
        fullWidth
        label={Placeholder}
        value={
          StartDate === null
            ? Placeholder
            : DateTime.fromJSDate(StartDate).toFormat(Format)
        }
        aria-describedby="component-error-text"
        error={ErrorForm}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <StartAdornment fontSize="small" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <EndAdornment fontSize="small" />
            </InputAdornment>
          ),
        }}
      />
      {ErrorForm ? (
        <TkFormHelperText id="component-error-text" color="danger">
          {ErrorFormMessage}
        </TkFormHelperText>
      ) : (
        ''
      )}
    </FormControl>
  );
};

export default TkDatePickerActivator;
