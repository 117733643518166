import styled, { css } from 'styled-components';

import { DialogContent } from '@mui/material';

import { ICalendarWrapper } from './types';
import { DynamicButton } from '@components/index';
import { ICalendarViewEnum, LocalStorage } from '@consts/index';
import { getColorFromTheme, getOutlinedHoverColor } from '@helpers/index';
import {
  Grid,
  Backdrop,
  DialogTitle,
  ButtonGroup,
  DialogActions,
  makeStyles,
} from '@material-ui/core';
import {
  PaletteProps, //eslint-disable-line
  SpacingProps, //eslint-disable-line
} from '@material-ui/system';

export const GridDatePickerWrapper = styled(Grid)`
  min-width: 165px;
  .react-datepicker-wrapper {
    width: 100%;
  }
`;

export const SettingsButton = styled(DynamicButton)`
  &:hover {
    background: ${(props) =>
      props.color &&
      getOutlinedHoverColor(getColorFromTheme(props.theme.palette, 'primary'))};
  }
`;

export const CustomToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const AbsoluteBackdrop = styled(Backdrop)`
  position: absolute;
  z-index: 0;
  background-color: rgba(255, 255, 255, 0.5);
`;

export const FullCalendarWrapper = styled.div<ICalendarWrapper>`
  width: ${(props) => (props.collapsed ? 'calc(100% - 420px)' : '100%')};
  position: relative;
  height: 100%;
  .fc {
    position: relative;
    z-index: 0;
    height: ${(props) => (props.filterSet ? '91%' : '95%')};

    .fc-daygrid-body {
      tr {
        ${() =>
          localStorage.getItem(LocalStorage.COLLAPSE_PROJECTS) &&
          `height: 15.75vh;`};
        ${() =>
          !localStorage.getItem(LocalStorage.COLLAPSE_PROJECTS) &&
          `min-height: 15.75vh;`};
      }
    }

    .fc-col-header-cell-cushion {
      font-family: 'Muli';
      font-size: 16px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .fc-datagrid-cell-cushion {
      width: 100%;
    }

    .fc-highlight {
      background-color: rgba(255, 255, 255, 0);
      border: 2px dashed #999;
    }

    .fc-popover {
      max-width: 250px;

      .fc-popover-header {
        background-color: ${(props) =>
          getColorFromTheme(props.theme.palette, 'white')};
        color: ${(props) => getColorFromTheme(props.theme.palette, 'navyBlue')};
        margin: 4px;
        font-size: 14px;
        font-weight: bold;
        font-family: 'Lato';
      }

      .fc-popover-body {
        padding: 4px 25px 10px 4px;
        max-height: 130px;
        overflow-y: scroll;
        scrollbar-width: none;
      }
    }
  }

  .fc-timeline-more-link {
    background: transparent;
  }

  .fc-daygrid-event-harness,
  .fc-timeline-event-harness {
    padding: 4px 4px 0 4px;
  }
  .fc-timeline-slot,
  .fc-daygrid-day {
    &.fc-day-today {
      background-color: var(--fc-today-bg-color, rgba(190, 222, 253, 0.15));
    }
  }
  .fc-daygrid-event,
  .fc-timeline-event {
    height: ${(props) =>
      props.gridView === ICalendarViewEnum.MONTH_VIEW ? 24 : 58}px;
    border-radius: 4px;
    padding: 0 3px;

    &.fc-event-unassigned {
      background-color: #fabe4f;
      border: 1px solid #fabe4f;

      &.dragging {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #fab333;
        border: 2px solid #fab333;
      }
    }
    &.fc-event-to-do {
      background-color: #fbbce8;
      border: 1px solid #fbbce8;
      &.dragging {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #fbbce8;
        border: 2px solid #fbbce8;
      }
    }
    &.fc-event-in-progress {
      background-color: #c1ddfe;
      border: 1px solid #c1ddfe;
      &.dragging {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #c1ddfe;
        border: 2px solid #c1ddfe;
      }
    }
    &.fc-event-completed {
      background-color: #aaf6ae;
      border: 1px solid #aaf6ae;

      &.dragging {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #aaf6ae;
        border: 2px solid #aaf6ae;
      }
    }

    &.fc-event-integrations {
      background-color: #f4f4f4;
      border: 1px dashed #061f41;

      &.dragging {
        background: #ffffff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #b5b1b1;
        border: 1px dashed #061f41;
      }
    }

    &.fc-event-placeholder {
      background-color: #f4f4f4;
      border: 1px dashed #061f41;
    }

    .fc-event-main {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 4px;
      border-radius: 4px;
      overflow: hidden;
      color: ${(props) => getColorFromTheme(props.theme.palette, 'navyBlue')};
      font: normal normal normal 12px/18px Lato;
      .fa {
        width: 100%;
        display: inline;
      }
    }
  }

  font-family: 'Lato';
`;

export const FullCalendarWrapperHeader = styled.div`
  border: 1px solid #cbcbcb;
  border-bottom: 0;
  border-radius: 4px 4px 0 0;
  background-color: #f5f6fb;
  padding: 8px;
`;

export const SettingModal = styled.div`
  max-width: 600px;
`;

export const SettingsContent = styled(DialogContent)`
  .MuiFormLabel-root {
    font: normal normal normal 18px/20px Lato;
    top: -5px;
    color: ${(props) => getColorFromTheme(props.theme.palette, 'default')};
  }
  &.MuiDialogContent-dividers {
    border-top: 0;
  }
`;

export const SettingsTitle = styled(DialogTitle)`
  text-align: center;
  color: #3d4465;
`;

export const ContentChevron = styled.div`
  display: flex;
`;

export const SettingsActions = styled(DialogActions)`
  padding: 1rem;
`;

export const ChangeViewButton = styled(DynamicButton)<
  PaletteProps & SpacingProps
>`
  border-bottom: 0;
  .MuiTypography-root {
    color: ${(props) => getColorFromTheme(props.theme.palette, 'default')};
  }
  padding: 6px 32px;
  transition: border-bottom 0.1s ease;
  ${(props) =>
    props.selected &&
    css<PaletteProps>`
      border-bottom: ${(props) =>
        '2px solid' + getColorFromTheme(props.theme.palette, props.color)};
      .MuiTypography-root {
        color: ${(props) =>
          getColorFromTheme(props.theme.palette, props.color)};
      }
    `}
  border-radius: 0px;
`;

export const ChangeViewButtonGroup = styled(ButtonGroup)`
  .MuiButton-text {
    border-right: 0;
  }
`;

export const ActivatorSlot = styled.div`
  cursor: pointer;
  color: ${(props) => getColorFromTheme(props.theme.palette, 'default')};
  font-weight: bold;
  display: flex;
  justify-content: center;
  width: 100%;
  span {
    margin-left: 3px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }
`;

export const MoreLinks = styled.span`
  margin-left: 4px;
  font-weight: bold;
  color: ${(props) => getColorFromTheme(props.theme.palette, 'primary')};
  font-family: 'Lato';
`;

export const SelectedFilterOptionsWrapper = styled(Grid)`
  margin: 10px 0 5px 0;
  display: flex;
  width: 100%;
  flex-direction: row;
`;

export const WeekViewHeaderWrapper = styled(Grid)`
  display: flex;
  align-items: center;
  flex-direction: row;
  color: #011f41;
`;

export const WeekViewHeaderItem = styled(Grid)`
  display: flex;
  min-height: 50px;
  align-items: center;
  justify-content: center;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: normal;

  .MuiSelect-select {
    height: 35px;
  }
`;

export const ResourceHeaderContent = styled(Grid)`
  display: flex;
  min-height: 50px;
  font-family: 'Lato';
  font-size: 16px;
  font-weight: bold;
  color: #011f41;
`;

export const ResourceHeaderTitle = styled(Grid)`
  display: flex;
  align-items: center;
  flex: 1;
  white-space: nowrap;

  p {
    width: 90%;
    white-space: pre-wrap;
  }
`;

export const ResourceHeaderButtonWrapper = styled(Grid)`
  display: flex;
  flex: 1;
  align-items: flex-end;
  height: 300px;
`;

export const ResourceHeaderButtonItem = styled(Grid)`
  display: flex;
  align-items: left;
  margin-right: 20px;
  margin: 10px 20px 10px 0;
`;

export const ResourceHeaderTask = styled(Grid)`
  display: flex;
  min-width: 50px;
  height: 45px;
  padding-left: 5px;
  margin-left: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 1px solid;
  font-weight: normal;
`;

export const useStyles = makeStyles((theme) => ({
  formControl: {
    fontFamily: 'Lato',
    fontSize: 18,
    minWidth: 120,
  },
  selectEmpty: {
    height: 50,
  },
}));
