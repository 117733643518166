import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  editEventTitle,
  editEventCustomer,
  editEventLocation,
} from '@recoilData/index';

export const useDeletedEvent = () => {
  const name = useRecoilValue(editEventTitle);
  const customer = useRecoilValue(editEventCustomer);
  const location = useRecoilValue(editEventLocation);

  return {
    name,
    location,
    customer,
  }
}