import styled from 'styled-components';

export const TkLoginDivider = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  font: normal normal bold 14px/19px Lato;
  letter-spacing: 0px;
  color: #999999;
  opacity: 0.8;
  margin: 30px 0 10px;
`;

export const TkLoginDividerBoard = styled.div`
  display: flex;
  padding: 7px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid #ffffff;
  border-radius: 50%;
  opacity: 0.8;
`;

export const TkLoginDividerLine = styled.div`
  display: flex;
  flex: 1;
  border-top: 2px solid;
  opacity: 0.8;
`;

export const TkLoginFooter = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const TkLoginFooterContainer = styled.div`
  width: 45%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font: normal normal normal 16px/24px Lato;
  letter-spacing: 0px;
  color: #999999;
  opacity: 1;
`;

export const TkLoginFooterContainerLink = styled.a`
  text-decoration: none;
  color: #999999;
`;

export const TkLoginFooterContainerLinkWrapper = styled.div`
  a {
    margin-left: 15px;
  }
`;
