export default {
  title: "Intégrations",
  tbnbIntegrationCard: {
    title: "Turno",
    textContent: "Synchronisez automatiquement vos missions Turno et gérez-les depuis Taskbird.",
    integratedTextContext: "Vous pouvez désormais assigner des missions de travail à vos collaborateurs/trices et réaliser ces missions depuis Taskbird.",
    integratedTextContextWithMoveout: "Vous pouvez désormais assigner des missions de travail à vos collaborateurs/trices et réaliser ces missions depuis Taskbird. Elles seront parallèllement marquées comme terminées sur Turno",
    connectedOn: "<0>Connecté(e)</0> sur",
    sellingPointOne: "Vos missions et client(e)s seront synchronisés.",
    sellingPointTwo: "Lorsqu'une mission est modifiée sur Turno, elle sera également modifiée sur Taskbird.",
    sellingPointThree: "Lorsqu'une mission est terminée sur Taskbird, elle sera également marquée comme terminée sur Turno.",
    connectModal: {
      title: "Synchroniser avec Turno",
      textContent: "Veuillez saisir vos identifiant et mot de passe Turno",
      email_required: "Veuillez saisir une adresse e-mail",
      email_invalid: "Veuillez saisir une adresse e-mail valide",
      password_required: "Veuillez saisir votre mot de passe",
      success: "Votre compte Turno a été synchronisé avec succès",
      invalid_credentials: "E-mail ou mot de passe incorrect.",
      email_already_integrated: "Cette adresse e-mail est déjà présente sur Taskbird. Si vous avez besoin d'aide, veuillez contacter notre service client.",
      invalid_account_type: "Vous pouvez seulement importer un compte d'Agent(e) d'entretien qui existe déjà sur Turno.",
      account_already_integrated_with_client: "Ce compte a déjà été importé. Veuillez contacter notre service client.",
    }
  },
  moveoutIntegrationCard: {
    title: "Moveout.com",
    textContent: "Rejoignez notre nouveau Marketplace pour développer vos activités.",
    join: "Rejoignez Moveout.com",
    joinedOn: "<0>Membre</0> depuis le {{- date}}",
    editPriceTooltip: "Modifier le taux horaire par personne",
    editPriceSuccess: "Tarif modifié avec succès !",
    integratedContent: {
      enabledRequests: "Vous envoyez actuellement des offres automatiques pour de l'entretien de logements dans le cadre de déménagements. Vos offres sont basées sur les éléments exposés ci-dessous.",
      disabledRequests: "Votre compte Moveout est mis en pause. Vous ne recevrez plus d'opportunités de missions de la part de Moveout.",
    },
    joinModal: {
      stepOne: {
        title: "Un nouveau Marketplace pour développer vos activités",
        textContent: "Moveout.com est un Marketplace dédié aux missions d'entretien dans le cadre de déménagements. Les opportunités de missions de Move-out ainsi que le déroulement de ces missions sont gérés via Taskbird.",
        textContentOne: "<0>Praticité :</0> L'inscription à Moveout.com est entièrement gratuite pour les agent(e)s d'entretien du Marketplace de Turno.",
        textContentTwo: "<0>Accès à davantage de travail :</0> Comme sur Turno, acceptez toutes les missions d'entretien Move-out que vous souhaitez et refusez celles que vous ne souhaitez pas réaliser.",
        textContentThree: "<0>Intégration fluide :</0> Fixez simplement votre tarif et vous commencerez à recevoir des opportunités de travail.",
        actionBack: "$t(common:cancel)",
        actionNext: "$t(common:next): $t(integrationsPage:moveoutIntegrationCard.joinModal.stepTwo.title)",
      },
      stepTwo: {
        title: "Comment ça marche ?",
        textContentOne: "Nous utiliserons votre <1>Photo de Profil et vos Évaluations de Turno</1> pour créer votre profil d'agent(e) d'entretien sur Moveout.com.",
        textContentTwo: "Veuillez simplement <1>fixer votre taux horaire par personne.</1>",
        textContentThree: "Vous apparaîtrez automatiquement dans les résultats des recherches sur Moveout.com dans votre région et les client(e)s vous enverront des opportunités de missions. <1>N'acceptez que les missions que vous souhaitez réaliser.</1>",
        actionBack: "$t(common:back)",
        actionNext: "$t(common:next): $t(integrationsPage:moveoutIntegrationCard.joinModal.stepThree.title)",
      },
      stepThree: {
        title: "Fixez votre tarif",
        ratePerPerson: "Mon taux horaire </1> par personne",
        rateTip: "Vous aurez toujours la possibilité de modifier ce tarif plus tard",
        rateBelow: "Ce tarif est en-dessous de la moyenne. Vous pouvez l'augmenter encore un peu !",
        rateFair: "Ce tarif est correct. Vous avez de bonnes chances d'obtenir des client(e)s !",
        rateAbove: "Ce tarif est au-dessus de la moyenne. Vous pourriez avoir des difficultés à obtenir des client(e)s",
        howMuchEarn: "Voici un exemple du fonctionnement de votre tarification :",
        peopleWorking: "Nombre d'agent(e)s :",
        hoursSpent: "Heures travaillées :",
        totalEarnings: "Revenu total ",
        rightContent: {
          title: "Pourquoi un tarif horaire ?",
          textContentOne: "La durée des missions d'entretien dans le cadre de déménagements peut grandement varier selon l'état du logement.",
          textContentTwo: "Fixer un taux horaire vous assurera de ne pas en ressortir perdant(e) dans l'éventualité où la mission d'entretien durerait plus longtemps que prévu.",
        },
        formError: {
          rate_min: "Ce tarif est trop bas",
          rate_required: "Veuillez indiquer un tarif"
        },
        actionBack: "$t(common:back)",
        actionNext: "$t(integrationsPage:moveoutIntegrationCard.join)"
      },
      stepFinal: {
        title: "Super ! Vous faites désormais partie de Moveout.com",
        textContentOne: "Vous recevrez dans votre emploi du temps des opportunités de missions dans le cadre de déménagements.",
        textContentTwo: "Acceptez celles qui vous conviennent et vous recevrez les informations de contact de votre nouveau/elle client(e).",
        textContentThree: "Une fois votre mission terminée, vous recevrez votre rémunération sur votre compte Stripe dans un délai de 5 jours ouvrables.",
        actionNext: "J'ai compris !"
      },
    }
  },
  requestPlatformPanel: {
    title: "Vous souhaitez intégrer une autre plateforme ?",
    textContent: "Nous travaillons constamment à améliorer nos produits et services. Dites-nous quelle plateforme vous souhaiteriez intégrer :",
    platformPlaceholder: "Veuillez écrire le nom d'une plateforme",
    submitButtonContent: "Envoyer",
    success: "Nous vous remercions !",
    error: "Une erreur est survenue, veuillez réessayer plus tard.",
  }
};
