export default {
  tkModalAlertIntegrated:
    "Esta tarefa é de um calendário externo e a data não pode ser alterada",
  tkModalAlertRepeat:
    "Esta tarefa é um exemplo de uma série de repetições e não pode ser transferida para outra data.",
  tkModalAlertTurnoverbnb:
    "Esta tarefa é da Turno e o cliente não pode ser alterado",
  tkModalAlertInProgress:
    "Você não pode editar datas para tarefas em andamento ou concluídas",
  tkModalAlertWontReflectOnTurnoverBNB:
    "Quaisquer mudanças feitas na tarefa não serão refletidas na Turno",
  alerts: {
    sendNotifications: "Enviar notificação?",
    doYouWantToNotify: "Você deseja notificar?",
    doNotShowAgainAndSendAllNotifications: "Não mostrar novamente e enviar todas as notificações",
  }
};
