import React from 'react';

import { RecoilState, useSetRecoilState } from 'recoil';
import styled from 'styled-components';

import { TkIcon } from '@components/index';

interface ITkNamePills {
  id: number;
  name: string;
  handleAction: any;
  filterData: (param: number) => RecoilState<boolean>;
}

const TagWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 5px;
  background-color: #4952a9;
  border-radius: 15px;
  height: 26px;
  font-family: 'Lato';
  font-size: 14px;
  color: #f0f0f0;
  margin: 3px 7px 3px 0px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding: 2px;
  cursor: pointer;
`;

const TextWrapper = styled.div`
  margin: 0 5px 2px 5px;
  user-select: none;
`;

const TkNamePills = ({
  id,
  name,
  handleAction,
  filterData,
}: ITkNamePills): JSX.Element => {
  const setFilterData = useSetRecoilState(filterData(id));

  function onClick() {
    handleAction();
    setFilterData(false);
  }

  return (
    <TagWrapper>
      <TextWrapper>{name}</TextWrapper>
      <IconWrapper onClick={onClick}>
        <TkIcon
          className="fa fa-times-circle"
          color="#ffffff"
          iconSize={14}
          cursor="pointer"
        />
      </IconWrapper>
    </TagWrapper>
  );
};

export default TkNamePills;
