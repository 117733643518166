import React from "react";
import styled from "styled-components";

const PulsatingWrapperBase = styled.div`
  display: inline;
  position: relative;
`;

const PulsatingWrapper = styled(PulsatingWrapperBase)`
  margin-right: 20px;
`;

const PulsatingWrapperSmall = styled(PulsatingWrapperBase)`
  margin-right: 15px;
`;

const PulsatingCircleBase = styled.div`
  background: #1f9bdd 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 8px #1592e5;
  border-radius: 50%;
  position: absolute;
  z-index: 2;
`;

const PulsatingCircle = styled(PulsatingCircleBase)`
  width: 10px;
  height: 10px;
  top: 3px;
  left: 10px;
`;

const PulsatingCircleSmall = styled(PulsatingCircleBase)`
  width: 6px;
  height: 6px;
  bottom: -3.5px;
  left: 6px;
`;

const PulsatingRingBase = styled.div`
  border: 3px solid #1f9bdd;
  border-radius: 30px;
  position: absolute;
  animation: pulsate 1s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;

  @keyframes pulsate {
    0% {
      -webkit-transform: scale(0.1, 0.1);
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      -webkit-transform: scale(1, 1);
      opacity: 0;
    }
  }
`;

const PulsatingRing = styled(PulsatingRingBase)`
  height: 20px;
  width: 20px;
  left: 5px;
  top: -2px;
`;

const PulsatingRingSmall = styled(PulsatingRingBase)`
  height: 12px;
  width: 12px;
  left: 3px;
  bottom: -6.5px;
`;

interface ITkPulsatingIcon {
  type: "normal" | "small";
}

const TkPulsatingIcon: React.FC<ITkPulsatingIcon> = ({ type }) => {
  if (type === "small") {
    return (
      <PulsatingWrapperSmall>
        <PulsatingRingSmall />
        <PulsatingCircleSmall />
      </PulsatingWrapperSmall>
    );
  }

  return (
    <PulsatingWrapper>
      <PulsatingRing />
      <PulsatingCircle />
    </PulsatingWrapper>
  );
};

export default TkPulsatingIcon;
