import React, { memo } from 'react';

import { Redirect, useHistory } from 'react-router';

import { AppLocationURLs } from '@consts/index';
import { useAuth } from '@contexts/index';

export const withAuth = (Component) => {
  const AuthWrapper = memo((props) => {
    const { signed } = useAuth();
    const location = useHistory();

    if (!signed) {
      return (
        <Redirect
          to={{
            pathname: AppLocationURLs.LOGIN,
            state: { from: location.location },
          }}
        />
      );
    }

    return <Component {...props} />;
  });
  AuthWrapper.displayName = `withAuth`;
  return AuthWrapper;
};
