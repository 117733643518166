import { DateTime } from 'luxon';
import queryString from 'query-string';
import { useQuery } from 'react-query';
import { useRecoilValue } from 'recoil';

import { ProjectOffers } from '@features/projectOffers/models';
import { encodedUserTimezone } from '@helpers/index';
import { getUserAccountId } from '@recoilData/index';
import api from '@services/api';

export const useAccountProjectOffers = () => {
  const accountId = useRecoilValue(getUserAccountId);
  const { data, isLoading } = useQuery(
    ['accountProjectOffers', accountId],
    async () => {
      const qs = queryString.stringify(
        {
          group_by: 'start_day',
          start_time_from: DateTime.local().startOf('day').toSeconds(),
          timezone: encodedUserTimezone(),
        },
        {
          encode: false,
        }
      );
      const { data } = await api.get(
        `/account/${accountId}/projectOffer?${qs}`
      );
      return data as ProjectOffers;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  return {
    projectOffers: data,
    isLoading,
  };
};
