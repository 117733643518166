export default {
  widget: {
    welcomeCall: {
      title: 'Get a Free Demo Call',
      description:
        '<0>Do you want personalized help getting started</0>? Book a call with our customer success specialists to the most value out of Taskbird.',
      footer: 'I don’t want a Demo Now',
    },
    bookingService: {
      title: 'Get a Free Demo Call',
      description:
        '<0>Do you want personalized help to set up your Booking Site</0>? Schedule a call with one of our customer success specialists to get it running.',
      footer: 'I don’t want a Demo Now',
    },
    demoCallScheduled: 'Demo call scheduled',
  },
};
