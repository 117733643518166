import React from "react";
import { useTranslation } from "react-i18next";
import { FormHelperText, Grid } from "@mui/material";
import { TkInput, TkTypography } from "@components/index";

interface ITkDrawerTaskNameInput {
  name: string;
  error: boolean;
  setName: (valOrUpdater: string | ((currVal: string) => string)) => void;
  hideTitle?: boolean;
}

const TkDrawerTaskNameInput: React.FC<ITkDrawerTaskNameInput> = ({
  name,
  error,
  setName,
  hideTitle = false,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container direction="column">
      {!hideTitle && (
        <TkTypography
          fontWeight="bold"
          fontFamily="Lato"
          fontSize={16}
          userselect="none"
        >
          {t("taskName", { ns: "events" })}
        </TkTypography>
      )}
      <TkInput
        marginTop="5px"
        value={name}
        placeholder={`${t("nameYourTask", { ns: "events" })}`}
        fullWidth
        onChange={(e) => setName(e.target.value)}
        aria-describedby="component-error-text"
        error={error}
      />
      {error && (
        <FormHelperText id="component-error-text" style={{ marginLeft: 0 }}>
          {t("pleaseAddAName", { ns: "events" })}
        </FormHelperText>
      )}
    </Grid>
  );
};

export default TkDrawerTaskNameInput;
