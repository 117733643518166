import React, { useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { ButtonCustom, TaskNameDisplay, TkInputEdit } from './styles';
import { TkIcon, TkTypography } from '@components/index';
import { TaskStatusEnum } from '@consts/index';
import { TkIconSelected } from '@web-ui/index';

interface ITkDrawerTaskName {
  status?: string;
  name: string;
  error?: boolean;
  displayOnly?: boolean;
  setName?: (value: string) => void;
  setEdited?: (value: boolean) => void;
}

const TkDrawerTaskName: React.FC<ITkDrawerTaskName> = ({
  status = TaskStatusEnum.TODO,
  name,
  setName = (_: string) => null,
  setEdited = (_: boolean) => null,
  error = false,
  displayOnly = false,
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [nameChange, setNameChange] = useState<string>('');
  const [showEditNameIcon, setShowEditNameIcon] = useState<boolean>(false);
  const [errorValidator, setErrorValidator] = useState<boolean>(false);

  useEffect(() => {
    setNameChange(name);
  }, [name]);

  useEffect(() => {
    if (errorValidator && nameChange.length > 0) {
      setErrorValidator(false);
    }
  }, [nameChange, errorValidator]);

  useEffect(() => {
    if (error) setErrorValidator(true);
  }, [error]);

  const isEditable = () => {
    if (displayOnly) {
      return false;
    }

    if (showEditNameIcon && status === TaskStatusEnum.TODO) {
      return true;
    }

    return false;
  };

  const applyNameChange = (newName: string) => {
    if (newName.length === 0) {
      setErrorValidator(true);
      return;
    }
    setName(newName);
    setEditing(false);
    setEdited(true);
  };

  const cancelNameChange = () => {
    setNameChange(name);
    setEditing(false);
  };

  if (!displayOnly && editing) {
    return (
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        direction="row"
      >
        <TkInputEdit
          value={nameChange}
          disableUnderline
          onChange={(e) => setNameChange(e.target.value)}
          error={errorValidator}
        />
        <ButtonCustom
          style={{ marginRight: '10px', backgroundColor: '#FFFFFF' }}
          onClick={cancelNameChange}
        >
          <TkIcon
            marginLeft={5}
            className="fa fa-times"
            iconSize={16}
            color="primary"
            cursor="pointer"
          />
        </ButtonCustom>
        <ButtonCustom
          style={{ backgroundColor: '#4952a9' }}
          onClick={() => applyNameChange(nameChange)}
        >
          <TkIcon
            className="fa fa-check"
            iconSize={16}
            color="white"
            cursor="pointer"
          />
        </ButtonCustom>
      </Grid>
    );
  }

  return (
    <TaskNameDisplay
      container
      justifyContent="center"
      alignItems="center"
      onMouseEnter={() => setShowEditNameIcon(true)}
      onMouseLeave={() => setShowEditNameIcon(false)}
    >
      <TkTypography
        fontWeight="bold"
        fontSize={20}
        fontFamily="Muli"
        textAlign="center"
        marginTop={1}
        userselect="none"
      >
        {name}
        {isEditable() && (
          <TkIconSelected
            className="fa fa-pencil"
            verticalAlign="middle"
            style={{ top: 6 }}
            onClick={() => setEditing(true)}
          />
        )}
      </TkTypography>
    </TaskNameDisplay>
  );
};

export default TkDrawerTaskName;
