import React from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { EventTypeEnum, TaskStatusEnum } from '@consts/index';
import { ExtendedDrawersTypes } from '@hooks/drawer/drawer.types';
import { useDrawer } from '@hooks/index';
import {
  event as eventRecoil,
  editEventExtendedDrawer,
  editEventTeammatesImageCount,
} from '@recoilData/index';

export const useTeamNotesAndImages = () => {
  const { t } = useTranslation();
  const { callDrawer } = useDrawer();

  // Recoil Values
  const event = useRecoilValue(eventRecoil);
  const [teammatesImageCount, setImgCount] = useRecoilState(
    editEventTeammatesImageCount
  );
  const [extendedObject, setExtendedObject] = useRecoilState(
    editEventExtendedDrawer
  );

  const statusIsToDoOrInProgress =
    event.status === TaskStatusEnum.DONE ||
    event.status === TaskStatusEnum.IN_PROGRESS;

  const handleTeammatesNotes = () =>
    callDrawer({
      extended: {
        id: ExtendedDrawersTypes.EDIT_TASK_TEAMMATES_NOTES,
        props: {
          eventType: EventTypeEnum.EDIT,
        },
      },
    });

  const handleTeammatesImages = () => {
    if (statusIsToDoOrInProgress) {
      callDrawer({
        extended: {
          id: ExtendedDrawersTypes.EDIT_TASK_TEAMMATES_IMAGES,
          props: {
            eventType: EventTypeEnum.EDIT,
          },
        },
      });
    }
  };

  return {
    t,
    extendedObject,
    teammatesImageCount,
    statusIsToDoOrInProgress,
    handleTeammatesNotes,
    handleTeammatesImages,
  };
};
