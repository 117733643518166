import { atom } from 'recoil';
import { ICalendarViewEnum, LocalStorage } from '@consts/index';
import { DateTime } from 'luxon';

const selectedDateCalendarData = atom<Date>({
  key: 'selectedDateCalendarData',
  default: DateTime.now().plus({ day: 1 }).toJSDate(),
});

const calendarGridView = atom<string>({
  key: 'calendarGridView',
  default:
    localStorage.getItem(LocalStorage.CALENDAR_VIEW) ??
    ICalendarViewEnum.MONTH_VIEW,
});

const calendarWeekViewType = atom<string>({
  key: 'calendarWeekViewType',
  default: localStorage.getItem(LocalStorage.WEEK_VIEW_TYPE) ?? 'customer',
});

const openLoneTask = atom<boolean>({
  key: 'openLoneTask',
  default: false,
});

const isNewTaskExternal = atom<boolean>({
  key: 'isNewTaskExternal',
  default: false,
});

export {
  selectedDateCalendarData,
  calendarGridView,
  calendarWeekViewType,
  openLoneTask,
  isNewTaskExternal,
};
