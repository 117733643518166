import React from 'react';

import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import {
  ModalContent,
  ModalActions,
  DeleteIconModal,
  TextModal,
  SubTextModal,
} from './styles';
import { TkIcon, TkTypography, DynamicButton } from '@components/index';
import { useAccountProjectOffers } from '@features/projectOffers/hooks';
import { ProjectOfferBulkAction } from '@features/projectOffers/models';

interface ProjectRequestsBulkActionConfirmationModalProps {
  type: ProjectOfferBulkAction;
  handleCloseModal: () => void;
  onSubmitAction: () => void;
}

const MultipleOffersModal = ({
  type,
  handleCloseModal,
  onSubmitAction,
}: ProjectRequestsBulkActionConfirmationModalProps): JSX.Element => {
  const { t } = useTranslation();
  const { projectOffers } = useAccountProjectOffers();
  const projectOfferRequestsCount = projectOffers
    ? Object.entries(projectOffers)
        ?.map((project) => project[1].map((p) => p.id))
        .flat().length
    : 0;

  const options = {
    [ProjectOfferBulkAction.AcceptAll]: (
      <>
        <ModalContent>
          <Grid container>
            <DeleteIconModal container justify="center" alignItems="center">
              <TkIcon
                className="fal fa-exclamation-circle"
                iconSize={72}
                color="#4952A9"
                width={81}
              />
            </DeleteIconModal>
            <TextModal
              container
              justify="center"
              alignItems="center"
              color="default"
            >
              <TkTypography
                fontFamily="Muli"
                fontWeight="bold"
                fontSize={24}
                color="#011F41"
              >
                {projectOfferRequestsCount > 1
                  ? t('acceptMultiplePlural', {
                      ns: 'offers',
                      projectOffersCount: projectOfferRequestsCount,
                    })
                  : t('acceptMultipleSingular', {
                      ns: 'offers',
                      projectOffersCount: projectOfferRequestsCount,
                    })}
              </TkTypography>
            </TextModal>
            <SubTextModal container justify="center" alignItems="center">
              <Grid container justifyContent="center" alignItems="center">
                <TkTypography
                  fontFamily="Lato"
                  fontWeight="normal"
                  fontSize={16}
                  color="#011F41"
                >
                  {t('confirmAccept', {
                    ns: 'offers',
                    offerCount: projectOfferRequestsCount,
                  })}
                </TkTypography>
              </Grid>
            </SubTextModal>
          </Grid>
        </ModalContent>
        <ModalActions>
          <Grid container>
            <Grid item xs={6}>
              <DynamicButton color="primary" onClick={() => handleCloseModal()}>
                {t('cancel', { ns: 'common' })}
              </DynamicButton>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <DynamicButton
                variant="contained"
                bgcolor="primary"
                onClick={() => onSubmitAction()}
                disableElevation
              >
                {t('accept', { ns: 'common' })}
              </DynamicButton>
            </Grid>
          </Grid>
        </ModalActions>
      </>
    ),
    [ProjectOfferBulkAction.RejectAll]: (
      <>
        <ModalContent>
          <Grid container>
            <DeleteIconModal container justify="center" alignItems="center">
              <TkIcon
                className="fal fa-exclamation-circle"
                iconSize={72}
                color="#4952A9"
                width={81}
              />
            </DeleteIconModal>
            <TextModal
              container
              justify="center"
              alignItems="center"
              color="default"
            >
              <TkTypography
                fontFamily="Muli"
                fontWeight="bold"
                fontSize={24}
                color="#011F41"
              >
                {projectOfferRequestsCount > 1
                  ? t('rejectMultiplePlural', {
                      ns: 'offers',
                      projectOffersCount: projectOfferRequestsCount,
                    })
                  : t('rejectMultipleSingular', {
                      ns: 'offers',
                      projectOffersCount: projectOfferRequestsCount,
                    })}
              </TkTypography>
            </TextModal>
            <SubTextModal container justify="center" alignItems="center">
              <Grid container justifyContent="center" alignItems="center">
                <TkTypography
                  fontFamily="Lato"
                  fontWeight="normal"
                  fontSize={16}
                  color="#011F41"
                >
                  {t('confirmReject', {
                    ns: 'offers',
                    offerCount: projectOfferRequestsCount,
                  })}
                </TkTypography>
              </Grid>
            </SubTextModal>
          </Grid>
        </ModalContent>
        <ModalActions>
          <Grid container>
            <Grid item xs={6}>
              <DynamicButton color="primary" onClick={() => handleCloseModal()}>
                {t('cancel', { ns: 'common' })}
              </DynamicButton>
            </Grid>
            <Grid item container xs={6} justifyContent="flex-end">
              <DynamicButton
                variant="contained"
                bgcolor="primary"
                onClick={() => onSubmitAction()}
                disableElevation
              >
                {t('reject', { ns: 'common' })}
              </DynamicButton>
            </Grid>
          </Grid>
        </ModalActions>
      </>
    ),
  };

  return options[type];
};

export { MultipleOffersModal };
