import React from 'react';
import { useRecoilValue, useResetRecoilState } from 'recoil';
import { TkTypography, TkIcon, TkGoogleMap } from '@components/index';
import { TkExtendedWrapper, TkTaskWrapperItem } from '../styles';
import { selectedOffer, offersDetailsExtendedDrawer } from '@recoilData/index';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

const TkOfferLocation: React.FC = () => {
  const { t } = useTranslation();
  const offer = useRecoilValue(selectedOffer);
  const closeExtendedDrawer = useResetRecoilState(offersDetailsExtendedDrawer);

  return (
    <TkExtendedWrapper maxWidth={'lg'}>
      <Grid
        container
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <TkTypography fontWeight={'bold'} fontFamily={'Muli'} fontSize={16}>
          {t('location', { ns: 'common' })}
        </TkTypography>
        <TkIcon
          onClick={() => closeExtendedDrawer()}
          style={{ cursor: 'pointer' }}
          className={'fa fa-chevron-right'}
          color={'default'}
          marginLeft={10}
          verticalAlign={'baseline'}
          fontSize={'small'}
        />
      </Grid>
      <TkTaskWrapperItem padding={'1.5rem 0 1rem'} height={'100%'}>
        <TkGoogleMap
          latitude={Number(offer.location?.latitude)}
          longitude={Number(offer.location?.longitude)}
          height={'80vh'}
        />
      </TkTaskWrapperItem>
    </TkExtendedWrapper>
  );
};

export default TkOfferLocation;
