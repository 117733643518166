import { atom, atomFamily } from 'recoil';
import { ISimpleObject } from 'types/common.type';

import { TaskStatusEnum } from '@consts/index';

const status: ISimpleObject[] = [
  {
    id: 0,
    value: TaskStatusEnum.UNASSIGNED,
    checked: false,
    display: TaskStatusEnum.UNASSIGNED,
  },
  {
    id: 1,
    value: TaskStatusEnum.IN_PROGRESS,
    checked: false,
    display: TaskStatusEnum.IN_PROGRESS,
  },
  {
    id: 2,
    value: TaskStatusEnum.NOT_STARTED,
    checked: false,
    display: TaskStatusEnum.NOT_STARTED,
  },
  {
    id: 3,
    value: TaskStatusEnum.TODO,
    checked: false,
    display: TaskStatusEnum.TODO,
  },
  {
    id: 4,
    value: TaskStatusEnum.DONE,
    checked: false,
    display: 'completed',
  },
  {
    id: 5,
    value: TaskStatusEnum.NOT_COMPLETED,
    checked: false,
    display: TaskStatusEnum.NOT_COMPLETED,
  },
  {
    id: 6,
    value: TaskStatusEnum.REQUEST,
    checked: false,
    display: TaskStatusEnum.REQUEST,
  },
];

const statusData = atom<ISimpleObject[]>({
  key: 'statusData',
  default: status,
});

const filterStatus = atomFamily<boolean, number>({
  key: 'filterStatus',
  default: false,
});

export { statusData, filterStatus };
