export default {
  widget: {
    welcomeCall: {
      title: 'Agende uma Chamada Demo Gratuita',
      description:
        '<0>Você quer ajuda personalizada para começar</0>? Marque uma ligação com nossos especialistas em sucesso do cliente para obter o melhor do Taskbird.',
      footer: 'I don’t want a Demo Now',
    },
  },
};
