import { useMutation, useQueryClient } from 'react-query';
import { getUserAccountId } from '@recoilData/index';
import { useRecoilValue } from 'recoil';
import { AxiosError, AxiosResponse } from 'axios';
import { ICrewApi } from '@components/TkTeams/types';
import api from '@services/api';

export const useCreateCrew = () => {
  const accountId = useRecoilValue(getUserAccountId);
  const queryClient = useQueryClient();

  return useMutation<AxiosResponse, AxiosError, ICrewApi>(
    (payload) => api.post(`account/${accountId}/crew`, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['accountCrews', accountId]);
      },
    }
  );
};
