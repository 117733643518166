import styled from "styled-components";

import {
  PaletteProps, //eslint-disable-line
} from "@material-ui/system";

interface ILogoContent {
  onClick?: () => void;
  width?: string;
}

export const Container = styled.div<ILogoContent & PaletteProps>`
  display: flex;
  color: #011f41;
  align-items: center;
  width: ${(props) => (props.width ? props.width : "30%")};
  background: ${(props) => (props.color ? props.color : "#f5f6fb")};
  cursor: pointer;
`;

export const IconContent = styled.div<PaletteProps>`
  width: 10%;
  min-width: 30px;
  padding-right: 12px;
  margin-right: 24px;
  display: flex;
  align-items: center;
  color: ${(props) => props.color};
`;

export const LogoContent = styled.div`
  width: 90%;
  padding: 0px;
  display: flex;
  max-width: 139px;
  align-items: center;

  > img {
    width: 100%;
  }
`;
