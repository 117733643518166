import { ISimpleObject } from 'types/common.type';

import { ICalendarEvent } from './types';
import { TaskStatusEnum } from '@consts/index';

export const handleEventFilter = (
  events: ICalendarEvent[],
  status: ISimpleObject[],
  assignees: IAssignee[],
  customers: ICustomerTask[],
  locations: ILocationTask[],
  isTodayFilter = false,
  filteredTasks: number[]
): ICalendarEvent[] => {
  const newEvents = events.filter((event) => {
    const allUnselected = status.every((options) => options.checked === false);

    if (allUnselected) return event;

    return status.some((s) => {
      if (!s.checked) return false;

      switch (s.value) {
        case TaskStatusEnum.UNASSIGNED:
          return event.extendedProps.isOffer
            ? false
            : event.extendedProps.status === s.value;

        case TaskStatusEnum.REQUEST:
          return event.extendedProps.isOffer;
        default:
          return event.extendedProps.status === s.value;
      }
    });
  });

  const newEventsWithTeammates = newEvents.filter((event) => {
    const allUnselected = assignees.every(
      (assignee) => assignee.checked === false
    );

    if (allUnselected) return event;

    return assignees.some(
      (assignee) =>
        assignee.checked &&
        event.extendedProps.assigneeNames.includes(assignee.full_name)
    );
  });

  const newEventsWithCustomers = newEventsWithTeammates.filter((event) => {
    const allUnselected = customers.every(
      (customer) => customer.checked === false
    );

    if (allUnselected) return event;

    return customers.some(
      (customer) =>
        customer.checked && event.extendedProps.customer?.id === customer.id
    );
  });

  const newEventsWithLocations = newEventsWithCustomers.filter((event) => {
    const allUnselected = locations.every(
      (location) => location.checked === false
    );

    if (allUnselected) return event;

    if (event.extendedProps.address !== null) {
      return locations.some((location) => {
        if (location.checked && location.streetAddress) {
          return event.extendedProps.address.includes(location.streetAddress);
        }

        if (location.checked && location.street_address) {
          return event.extendedProps.address.includes(location.street_address);
        }

        return false;
      });
    }
  });

  const newEventsWithSelectedTasksId = newEventsWithLocations.filter(
    (event) => {
      if (filteredTasks.length === 0) return event;

      return filteredTasks.includes(event.id);
    }
  );

  if (!isTodayFilter) return newEventsWithSelectedTasksId;

  const todayDate = new Date().getDate();
  const newEventsToday = newEventsWithSelectedTasksId.filter(
    (event) => new Date(event.start).getDate() === todayDate
  );

  return newEventsToday;
};

export const verifyResources = (newResource, oldResource): boolean => {
  return newResource !== null && oldResource !== null;
};
