import { selectorFamily, selector } from "recoil";
import { multiInputValueById } from "@recoilData/atoms";

const getMultiInputValueById = selectorFamily<string[], string[]>({
  key: "getMultiInputValueById",
  get: (ids) => ({ get }) => {
    const selectedIds: string[] = ids as string[];
    const itemValues: string[] = [];

    selectedIds.forEach((id) => {
      const value = get(multiInputValueById(id));
      if (value.length > 0) itemValues.push(value);
    });

    return itemValues;
  },
});

const clearMultiInputValueById = selector({
  key: "clearMultiInputValueById",
  set: ({ set }, ids) => {
    const selectedIds: string[] = ids as string[];
    selectedIds.forEach((id) => {
      set(multiInputValueById(id), "");
    });
  },
  get: (ids) => () => {},
});


export { getMultiInputValueById, clearMultiInputValueById };
