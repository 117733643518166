import { atom, atomFamily } from 'recoil';

const filterCustomers = atomFamily<boolean, number>({
  key: 'filterCustomers',
  default: false,
});

const isAllFilterCustomersSelected = atom<boolean>({
  key: 'isAllFilterCustomersSelected',
  default: false,
});

export { filterCustomers, isAllFilterCustomersSelected };
