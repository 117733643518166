import React, { useState, useEffect } from 'react';
import TkInputDisplay from "./TkInputDisplay";

const ITkInputPriceDisplay: React.FC<ITkInputPriceDisplay> = ({
  value,
  preIcon,
  setValue,
  displayProps,
  displayTextProps,
  editIconTip = '',
  commitChange = () => {}
}) => {
  const [stringValue, setStringValue] = useState<string>('0.00');
  
  const handleOnChangeValue = valueS => {
    valueS = valueS.replace(/[^\d\.]/, '');
    valueS = valueS.replace(/^0+([1-9])/, '$1');
    valueS = valueS.replace(/^(\d+\.\d{0,2})\d*$/, '$1');
    
    const validValue = !!valueS.match(/^\d+(\.\d{0,2}|)$/) || valueS === '';

    return validValue ? valueS : value;
  }

  useEffect(() => {
    setStringValue(''+value);
  }, [value]);

  return (
    <TkInputDisplay
      {...{
        preIcon,
        setValue,
        editIconTip,
        displayProps,
        commitChange,
        displayTextProps,
      }}
      value={stringValue}
      alwaysShowEditIcon={true}
      onChange={handleOnChangeValue}
    />
  );
}

export default ITkInputPriceDisplay;