import React from 'react';

import { DateTime } from 'luxon';

import { KeyboardArrowDown as KeyboardArrowDownIcon } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';

import { TkPulsatingIcon, TkDatePickerActivator } from './index';
import { EditTaskTimeItem, VerticalDivider } from './styles';
import { useStatusAndTimeDisplay } from '../hooks/useStatusAndTimeDisplay';
import { selectTimezone } from '@components/TkPillContent/helpers';
import { TkTypography, TkFullDatePicker } from '@components/index';
import { TaskStatusEnum } from '@consts/index';
import { useUserSettings } from '@hooks/index';
interface StatusAndTimeDisplay {
  allDayEnd: boolean;
  allDayStart: boolean;
  selectedEvent: IEvent;
  setAllDayEnd: Dispatch<SetStateAction<boolean>>;
  setAllDayStart: Dispatch<SetStateAction<boolean>>;
}

const TkStatusAndTimeDisplay = ({
  allDayEnd,
  allDayStart,
  selectedEvent,
  setAllDayEnd,
  setAllDayStart,
}: StatusAndTimeDisplay): JSX.Element => {
  const { userSettings } = useUserSettings();
  const {
    t,
    end,
    event,
    start,
    hourView,
    isRepeat,
    statusIsToDoOrInProgress,
    triggerAlert,
    handleSetEnd,
    handleSetStart,
    taskStatusIndicator,
  } = useStatusAndTimeDisplay({
    allDayEnd,
    allDayStart,
    setAllDayEnd,
    setAllDayStart,
  });
  const timezone = selectTimezone(event);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: '10px' }}
    >
      <EditTaskTimeItem
        id={hourView ? 'datepicker-hour-left' : 'datepicker-left'}
      >
        <TkTypography fontSize={12} fontFamily="Lato" color="#999999">
          {taskStatusIndicator(event.status, 'start')}
        </TkTypography>
        {event.status === TaskStatusEnum.TODO && (
          <>
            <TkFullDatePicker
              startDate={new Date(start)}
              handleDateChange={handleSetStart}
              format24h={userSettings.time_format}
              timezone={timezone}
              hourOnly={hourView}
              isRepeat={isRepeat}
              activator={
                <TkDatePickerActivator
                  StartDate={new Date(start)}
                  Format="t"
                  EndAdornment={KeyboardArrowDownIcon}
                  activatorTrigger={triggerAlert}
                  timezone={timezone}
                  notSet={allDayStart}
                />
              }
            />
            <TkTypography fontSize={14} fontFamily="Lato">
              {DateTime.fromJSDate(new Date(start))
                .setZone(timezone)
                .toFormat("LLL dd '('ccc')'")}
            </TkTypography>
          </>
        )}

        {statusIsToDoOrInProgress && selectedEvent.startedAt && (
          <>
            <TkTypography
              fontSize={20}
              fontFamily="Muli"
              fontWeight="bold"
              marginTop="5px"
            >
              {DateTime.fromJSDate(selectedEvent.startedAt)
                .setZone(timezone)
                .toFormat('t')}
            </TkTypography>
            <TkTypography fontSize={14} fontFamily="Lato">
              {DateTime.fromJSDate(selectedEvent.startedAt)
                .setZone(timezone)
                .toFormat("LLL dd '('ccc')'")}
            </TkTypography>
          </>
        )}
        {statusIsToDoOrInProgress && (
          <>
            <TkTypography
              fontFamily="Lato"
              fontSize={12}
              color="#999999"
              marginTop="10px"
            >
              {t('requestedStart', { ns: 'events' })}:
            </TkTypography>
            <TkTypography fontFamily="Lato" fontSize={12} color="#999999">
              {DateTime.fromJSDate(start)
                .setZone(timezone)
                .toFormat("t, LLL dd '('ccc')'")}
            </TkTypography>
          </>
        )}
      </EditTaskTimeItem>
      <VerticalDivider />
      <EditTaskTimeItem
        id={hourView ? 'datepicker-hour-right' : 'datepicker-right'}
      >
        <TkTypography fontSize={12} fontFamily="Lato" color="#999999">
          {taskStatusIndicator(event.status, 'end')}
        </TkTypography>
        {event.status === TaskStatusEnum.TODO && (
          <>
            <TkFullDatePicker
              startDate={new Date(end)}
              handleDateChange={handleSetEnd}
              format24h={userSettings.time_format}
              timezone={timezone}
              hourOnly={hourView}
              isRepeat={isRepeat}
              activator={
                <TkDatePickerActivator
                  StartDate={new Date(end)}
                  Format="t"
                  timezone={timezone}
                  EndAdornment={KeyboardArrowDownIcon}
                  activatorTrigger={triggerAlert}
                  notSet={allDayEnd}
                />
              }
            />
            <TkTypography fontSize={14} fontFamily="Lato">
              {DateTime.fromJSDate(new Date(end))
                .setZone(timezone)
                .toFormat("LLL dd '('ccc')'")}
            </TkTypography>
          </>
        )}
        {event.status === TaskStatusEnum.DONE && (
          <>
            <TkTypography
              fontSize={20}
              fontFamily="Muli"
              fontWeight="bold"
              marginTop="5px"
            >
              {selectedEvent.finishedAt
                ? DateTime.fromJSDate(selectedEvent.finishedAt)
                    .setZone(timezone)
                    .toFormat('t')
                : t('notSet', { ns: 'events' })}
            </TkTypography>
            <TkTypography fontSize={14} fontFamily="Lato">
              {selectedEvent.finishedAt
                ? DateTime.fromJSDate(selectedEvent.finishedAt)
                    .setZone(timezone)
                    .toFormat("LLL dd '('ccc')'")
                : t('notSet', { ns: 'events' })}
            </TkTypography>
          </>
        )}
        {event.status === TaskStatusEnum.IN_PROGRESS && (
          <>
            <TkTypography
              fontSize={20}
              fontFamily="Muli"
              fontWeight="bold"
              marginTop="5px"
            >
              <TkPulsatingIcon />
              <Box marginRight="10px" display="inline" />
              {t('inProgress', { ns: 'common' })}
            </TkTypography>
            <TkTypography fontSize={14} fontFamily="Lato">
              {DateTime.fromJSDate(new Date())
                .setZone(timezone)
                .toFormat("LLL dd '('ccc')'")}
            </TkTypography>
          </>
        )}
        {statusIsToDoOrInProgress && (
          <>
            <TkTypography
              fontFamily="Lato"
              fontSize={12}
              color="#999999"
              marginTop="10px"
            >
              {t('requestedFinish', { ns: 'events' })}:
            </TkTypography>
            <TkTypography fontFamily="Lato" fontSize={12} color="#999999">
              {DateTime.fromJSDate(end)
                .setZone(timezone)
                .toFormat("t, LLL dd '('ccc')'")}
            </TkTypography>
          </>
        )}
      </EditTaskTimeItem>
    </Grid>
  );
};

export default TkStatusAndTimeDisplay;
