import { createContext } from 'react';

import { alias, identify, track, reset } from 'mixpanel-browser';

type TrackEvent = typeof track;

const MIXPANEL_EVENTS = {
  SIGN_UP_PAGE_VIEW: 'Sign up page view',
  CLICK_TO_DOWNLOAD_APP: 'Click to Download App inside the Sign up Page',
  WELCOME_TO_TASKBIRD_JOIN_A_TEAM: 'Welcome to Taskbird - Join a Team',
  WELCOME_TO_TASKBIRD_SET_UP_A_NEW_TEAM:
    'Welcome to Taskbird - Set Up a New Team',
  ONBOARDING_WELCOME_GUIDE_SKIP: 'Onboarding/Welcome Guide Skip',
  STARTED_ONBOARDING: 'Started Onboarding',
  FINISHED_ONBOARDING: 'Finished Onboarding',
  ONBOARDING_STEPS: {
    ONBOARDING_WELCOME_GUIDE_STEP_1_CREATE_AND_ASSIGN_TASKS_NEXT:
      'Onboarding/Welcome Guide - Step 1 - Create and Assign Tasks - Next',
    ONBOARDING_WELCOME_GUIDE_STEP_2_INVITE_YOU_TEAMMATES_NEXT:
      'Onboarding/Welcome Guide - Step 2 - Invite you Teammates - Next',
    ONBOARDING_WELCOME_GUIDE_STEP_3_ADD_CUSTOMERS_NEXT:
      'Onboarding/Welcome Guide - Step 3 - Add Customers - Next',
    ONBOARDING_WELCOME_GUIDE_STEP_4_KEEP_TRACK_OF_YOUR_WORK_NEXT:
      'Onboarding/Welcome Guide - Step 4 - Keep Track of your Work - Next',
    ONBOARDING_WELCOME_GUIDE_STEP_5_SYNC_A_CALENDAR_NEXT:
      'Onboarding/Welcome Guide - Step 5 - Sync a Calendar - Next',
    ONBOARDING_WELCOME_GUIDE_STEP_6_INTEGRATE_WITH_TURNO_GET_STARTED:
      'Onboarding/Welcome Guide - Step 6 - Integrate with Turno - Get Started',
  },
  LOGIN: 'User login',

  PROMPTED_TO_CONFIRM_PHONE_NUMBER: 'Prompted to confirm phone number',

  CLICK_ON_ADD_PHONE_NUMBER: 'Click on add phone number',
  CLICK_ON_RESEND_CONFIRMATION_SMS: 'Click on resend confirmation SMS',
  CLICK_ON_CHANGE_NUMBER: 'Click on change number',
  PHONE_NUMBER_CONFIRMED: 'Phone Number confirmed',
  CLICK_ON_TEAM_BUTTON_ON_MENU: 'Click on Team button on menu',
  VIEW_TEAM_PAGE: 'View Team page',
  CLICK_PLUS_MEMBER: 'Click “+ Member”',
  CLICK_ON_SEND_INVITE: 'Click on Send Invite',
} as const;

const MIXPANEL_PAGE_NAMES = {
  BOOKING_WIDGET: {
    BUSINESS_CATEGORIES: 'BUSINESS_CATEGORIES',
    BUSINESS_INFORMATION: 'BUSINESS_INFORMATION',
    BUSINESS_HOURS: 'BUSINESS_HOURS',
    BUSINESS_SERVICES: 'BUSINESS_SERVICES',
    BUSINESS_SETUP_END: 'BUSINESS_SETUP_END',
    ONBOARDING_REDIRECTED: 'ONBOARDING_REDIRECTED',
    SERVICE_EDIT: 'SERVICE_EDIT',
    SERVICES_LIST: 'SERVICES_LIST',
    BUSINESS_INSTALLATION: 'BUSINESS_INSTALLATION',
    END_USER: 'END_USER',
  },
  CALENDAR: { HOME: 'HOME' },
} as const;
const MIXPANEL_EVENT_NAMES_ORIGIN = {
  BOOKING_WIDGET: 'BOOKING_WIDGET',
  CALENDAR: 'CALENDAR',
} as const;

const MIXPANEL_EVENT_ACTIONS = {
  BOOKING_WIDGET: {
    INSIDE_SETUP: 'INSIDE_SETUP',
    CLICK_NEW_SERVICE: 'CLICK_NEW_SERVICE',
    ONBOARDING_SKIPPED: 'ONBOARDING_SKIPPED',
    ONBOARDING_COMPLETED: 'ONBOARDING_COMPLETED',
    BOOKING_TOURS: {
      BOOKING_TOUR_FINISH_INITIAL_SETUP: {
        BOOKING_TOUR_FINISH_INITIAL_SETUP_START:
          'BOOKING_TOUR_FINISH_INITIAL_SETUP_START',
        BOOKING_TOUR_FINISH_INITIAL_SETUP_COMPLETE:
          'BOOKING_TOUR_FINISH_INITIAL_SETUP_COMPLETE',
        BOOKING_TOUR_FINISH_INITIAL_SETUP_SKIP:
          'BOOKING_TOUR_FINISH_INITIAL_SETUP_SKIP',
      },
      BOOKING_TOUR_SERVICES: {
        BOOKING_TOUR_SERVICES_START: 'BOOKING_TOUR_SERVICES_START',
        BOOKING_TOUR_SERVICES_COMPLETE: 'BOOKING_TOUR_SERVICES_COMPLETE',
        BOOKING_TOUR_SERVICES_SKIP: 'BOOKING_TOUR_SERVICES_SKIP',
      },
      BOOKING_TOUR_EDIT_SERVICE: {
        BOOKING_TOUR_EDIT_SERVICE_START: 'BOOKING_TOUR_EDIT_SERVICE_START',
        BOOKING_TOUR_EDIT_SERVICE_COMPLETE:
          'BOOKING_TOUR_EDIT_SERVICE_COMPLETE',
        BOOKING_TOUR_EDIT_SERVICE_SKIP: 'BOOKING_TOUR_EDIT_SERVICE_SKIP',
      },
    },
    COMPLETE_SERVICE: 'COMPLETE_SERVICE',
    PUBLISH_SERVICE: 'PUBLISH_SERVICE',
    COPY_LINK: 'COPY_LINK',
    COPY_IFRAME: 'COPY_IFRAME',
    VIEWED: 'VIEWED',
  },
  CALENDAR: {
    CLICK_NEW_TASK: 'CLICK_NEW_TASK',
  },
} as const;

type MixpanelEventNamesOrigin =
  typeof MIXPANEL_EVENT_NAMES_ORIGIN[keyof typeof MIXPANEL_EVENT_NAMES_ORIGIN];

type MixpanelPageNames = string;

type MixpanelActionNames = string;

interface TrackFunction extends Omit<TrackEvent, 'event_name'> {
  (
    origin_name: MixpanelEventNamesOrigin | string,
    props?:
      | {
          action: MixpanelActionNames;
          page?: MixpanelPageNames;
          skip?: number;
        }
      | {
          screen_name: string;
          web_or_app: 'web';
        }
      | any,
    callback?: () => void
  ): void;
}

export interface IMixpanelContext {
  identify: typeof identify;
  alias: typeof alias;
  reset: typeof reset;
  setOnceProps: (props: Record<string, any>) => void;
  track: TrackFunction;
  trackDebounced: TrackFunction;
  consts: {
    MIXPANEL_EVENT_ACTIONS: typeof MIXPANEL_EVENT_ACTIONS;
    MIXPANEL_EVENT_NAMES_ORIGIN: typeof MIXPANEL_EVENT_NAMES_ORIGIN;
    MIXPANEL_PAGE_NAMES: typeof MIXPANEL_PAGE_NAMES;
  };
}

const MixpanelContext = createContext({} as IMixpanelContext);

export {
  MixpanelContext,
  MIXPANEL_EVENT_NAMES_ORIGIN,
  MIXPANEL_PAGE_NAMES,
  MIXPANEL_EVENTS,
  MIXPANEL_EVENT_ACTIONS,
};
