import { useEffect, useState } from 'react';

export type Status = 'idle' | 'loading' | 'ready' | 'error';
export type CrazyEggHook = { status: Status; removeScript: () => void };
export type ScriptElt = HTMLScriptElement | null;
enum SNAPSHOTS_PAGE_NAMES {
  CALENDAR_SIDE_MENU = 'Calendar Side Menu',
  //booking
  SERVICES_LIST = 'Services list',
  DATE_AND_TIME = 'Date and time',
  END_USER_CONTACT_INFORMATION = 'End User - Contact Information',
  SERVICE_DETAILS = 'Service details',
  SERVICE_OPTIONS = 'Service Options',
  QUESTIONS_FOR_CUSTOMER = 'Questions for customer',
  SERVICE_HOURS = 'Service hours',
  SERVICE_PREFERENCES = 'Service preferences',
  BUSINESS_DETAILS = 'Business Details',
  SCHEDULING = 'Scheduling',
  PREFERENCES = 'Preferences',
  CUSTOMIZATION_APPEARANCE = 'Customization Appearance',
  CUSTOMIZATION_FAQS = 'Customization FAQs',
  INSTALLATION = 'Installation',
  ON_BOARDING_ABOUT_1 = 'On boarding - About 1',
  ON_BOARDING_ABOUT_2 = 'On boarding - About 2',
  ONBOARDING_WIZARD_INTRO = 'Onboarding Wizard - Intro',
  ONBOARDING_WIZARD_SELECT_CATEGORY = 'Onboarding Wizard - Select category',
  ONBOARDING_WIZARD_BUSINESS_INFO = 'Onboarding Wizard - Business Info',
  ONBOARDING_WIZARD_OPERATION_HOURS = 'Onboarding Wizard - Operation hours',
  ONBOARDING_WIZARD_SELECT_SERVICES = 'Onboarding Wizard - Select services',
  ONBOARDING_WIZARD_SUCCESS = 'Onboarding Wizard - Success',
  NEW_SERVICE_OPTIONS = 'New Service Options',
  NEW_QUESTION_FOR_CUSTOMER = 'New Question for Customer',
}

type SnapshotsNames =
  typeof SNAPSHOTS_PAGE_NAMES[keyof typeof SNAPSHOTS_PAGE_NAMES];

function useCrazyEggTrackByName(name: SnapshotsNames): CrazyEggHook {
  const [status, setStatus] = useState<Status>(name ? 'loading' : 'idle');
  const removeScript = () => {
    const script = document.querySelector('[data-crazyEggTrackByName]');
    if (script) {
      script.remove();
    }
  };
  const createScript = (script, name) => {
    // Create script
    script = document.createElement('script');
    script.type = 'text/javascript';
    script.setAttribute('data-crazyEggTrackByName', name);
    script.text = `var CE_SNAPSHOT_NAME = "${name}";`;
    // Add script to document body
    document.body.appendChild(script);
  };
  useEffect(() => {
    if (!name) {
      setStatus('idle');
      return;
    }

    const script = document.querySelector('[data-crazyEggTrackByName]');
    if (!script) {
      createScript(script, name);
    } else {
      // remove script if the name is different
      if (script.getAttribute('data-crazyEggTrackByName') !== name) {
        script.remove();
        createScript(script, name);
      }
    }
    // Remove script on cleanup
    return () => {
      if (script) {
        script.remove();
      }
    };
  }, [name]);

  return { status, removeScript };
}

export { useCrazyEggTrackByName, SNAPSHOTS_PAGE_NAMES };
