import { selector, selectorFamily } from 'recoil';

import {
  filterCustomers,
  isAllFilterCustomersSelected,
  customersData,
} from '@recoilData/index';

const searchFilterCustomerData = selectorFamily({
  key: 'searchFilterCustomerData',
  get:
    (search: string) =>
    ({ get }) => {
      const customers = get(customersData);
      if (!search) return customers;
      return customers.filter((item) => {
        return item.name
          .toLocaleLowerCase()
          .includes(search.toLocaleLowerCase());
      });
    },
});

const getFilteredCustomers = selector({
  key: 'getFilteredCustomers',
  get: ({ get }) => {
    const customers = get(customersData);
    const newCustomers: ICustomerTask[] = [];

    customers.forEach((customer) => {
      const filterState = get(filterCustomers(customer.id));
      newCustomers.push(generateCustomer(customer, filterState));
    });

    return newCustomers;
  },
});

const getFilteredCustomersCount = selector({
  key: 'getFilteredCustomersCount',
  get: ({ get }) => {
    const customers = get(getFilteredCustomers);
    let count = 0;
    customers.forEach((customer) => {
      if (customer.checked) count++;
    });
    return count;
  },
});

const selectAllFilterCustomers = selector<boolean>({
  key: 'selectAllFilterCustomers',
  get: ({ get }) => get(isAllFilterCustomersSelected),
  set: ({ set, get }, newValue) => {
    const customers = get(customersData);

    set(isAllFilterCustomersSelected, newValue as boolean);

    customers.map((customer) => {
      set(filterCustomers(customer.id), newValue as boolean);
    });
  },
});

export const setFilterByCustomerIdState = selectorFamily({
  key: 'setFilterByCustomerID',
  get:
    (id: number) =>
    ({ get }) => {
      const filterState = get(filterCustomers(id));
      return filterState;
    },
  set:
    (id: number) =>
    ({ get, set }, newValue) => {
      const filterState = get(filterCustomers(id));
      set(selectAllFilterCustomers, false);
      set(filterCustomers(id), !filterState);
    },
});

const generateCustomer = (customer: ICustomerTask, checked: boolean) => {
  const newCustomer: ICustomerTask = {
    id: customer.id,
    name: customer.name,
    locations: customer.locations,
    account_id: customer.account_id,
    checked: checked,
    createdAt: customer.createdAt,
    email: customer.email,
    phone_number: customer.phone_number,
    photo_url: customer.photo_url,
    street_address: customer.street_address,
  };

  return newCustomer;
};

export {
  searchFilterCustomerData,
  getFilteredCustomers,
  selectAllFilterCustomers,
  getFilteredCustomersCount,
};
