import React from 'react';

import TkPillMonthView from './TkPillMonthView';
import TkPillWeekView from './TkPillWeekView';
import { renderAssigneeIcon, renderIntegrations } from './helpers';
import { ICalendarViewEnum } from '@consts/calendar';

const TkPillContent: React.FC<ITkPillContent> = ({ eventInfo }) => {
  const { extendedProps } = eventInfo.event;

  const renderPillIcon = (assigneeNames: Array<string>) => {
    if (extendedProps.isOffer) return renderIntegrations(extendedProps);

    return renderAssigneeIcon(assigneeNames);
  };

  if (eventInfo.view.type === ICalendarViewEnum.MONTH_VIEW)
    return (
      <TkPillMonthView
        renderAssigneeIcon={renderPillIcon}
        eventInfo={eventInfo}
      />
    );

  return (
    <TkPillWeekView renderAssigneeIcon={renderPillIcon} eventInfo={eventInfo} />
  );
};

export default TkPillContent;
