import React from 'react';

import Dropzone, { FileRejection } from 'react-dropzone';

import {
  ImageInput,
  ImagePreview,
  ImageInputList,
  DeleteIcon,
  AddNewImage,
  ImageWrapper,
} from './styles';
import TkIcon from '@components/TkIcon';
import { useSnackbar } from '@components/TkSnackbar/useSnackbar';
import { Box } from '@material-ui/core';

const TkDragNDrop: React.FC<ITkDropzone> = ({
  newImages,
  images,
  addImage,
  removeImage,
  removeUploadedImage,
  flag,
  disabled,
}) => {
  const { addAlert } = useSnackbar();
  const triggerRemove = (
    i: File,
    evt: React.MouseEvent<HTMLSpanElement, MouseEvent>
  ) => {
    evt.stopPropagation();
    removeImage(i);
  };

  const showAlerts = (fileRejections: FileRejection[]) => {
    fileRejections.map((file) => {
      addAlert({
        message: `The image ${file.file.name} is bigger than 1MB in size`,
        severity: 'error',
      });
    });
  };

  if (!flag || flag === undefined) {
    return (
      <Dropzone
        accept="image/jpeg, image/jpg, image/png, image/bmp"
        onDrop={(acceptedFiles) => {
          if (acceptedFiles.length !== 0) addImage(acceptedFiles);
        }}
        onDropRejected={(fileRejections) => showAlerts(fileRejections)}
        maxSize={1024000}
      >
        {({ getRootProps, getInputProps }) =>
          newImages.length === 0 && images.length === 0 ? (
            <ImageInput {...getRootProps()}>
              <input {...getInputProps()} />
              <p>Drag/Drop or click to add new images to your task</p>
            </ImageInput>
          ) : (
            <ImageInputList {...getRootProps()}>
              <AddNewImage {...getRootProps()}>
                <input {...getInputProps()} />
                Add Image
              </AddNewImage>
              <input {...getInputProps()} />
              {newImages.map((i) => (
                <div
                  style={{ position: 'relative', marginTop: '5px' }}
                  {...getRootProps()}
                >
                  <DeleteIcon>
                    <TkIcon
                      color="navyBlue"
                      className="fa fa-times-circle"
                      onClick={(e) => triggerRemove(i, e)}
                    />
                  </DeleteIcon>
                  <ImagePreview src={URL.createObjectURL(i)} />
                </div>
              ))}
              {images.map((i) => (
                <div style={{ position: 'relative', marginTop: '5px' }}>
                  <DeleteIcon>
                    <TkIcon
                      color="navyBlue"
                      className="fa fa-times-circle"
                      onClick={() => removeUploadedImage(i.id)}
                    />
                  </DeleteIcon>
                  <ImagePreview src={i.fullImageUrl} />
                </div>
              ))}
            </ImageInputList>
          )
        }
      </Dropzone>
    );
  } else {
    return (
      <Box margin="10px 0">
        <ImageWrapper container>
          {images.map((i) => (
            <div style={{ position: 'relative' }}>
              {disabled ? (
                ''
              ) : (
                <DeleteIcon>
                  <TkIcon
                    color="navyBlue"
                    className="fa fa-times-circle"
                    onClick={() => removeUploadedImage(i.id)}
                  />
                </DeleteIcon>
              )}
              <ImagePreview src={i.fullImageUrl} />
            </div>
          ))}
          {newImages.map((i) => (
            <div style={{ position: 'relative' }}>
              <DeleteIcon>
                <TkIcon
                  color="navyBlue"
                  className="fa fa-times-circle"
                  onClick={() => removeImage(i)}
                />
              </DeleteIcon>
              <ImagePreview src={URL.createObjectURL(i)} />
            </div>
          ))}
        </ImageWrapper>
        {disabled ? (
          ''
        ) : (
          <Dropzone onDrop={(acceptedFiles) => addImage(acceptedFiles)}>
            {({ getRootProps, getInputProps }) => (
              <ImageInput {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag/Drop or click to add new images to your task</p>
              </ImageInput>
            )}
          </Dropzone>
        )}
      </Box>
    );
  }
};

export default TkDragNDrop;
