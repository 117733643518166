import styled from "styled-components";
//TODO: UPDATE TEXTFIELD TO MUI5
import { TextField } from "@material-ui/core";

export const TkNotesField = styled(TextField)`
  .MuiInput-root {
    margin-top: 10px;
  }

  .MuiFormHelperText-root {
    text-align: right;
  }
`;


export default TkNotesField;
