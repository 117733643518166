import { atom, atomFamily } from "recoil";

const eventChecklists = atomFamily<IEventChecklists, number>({
  key: "eventChecklists",
  default: {
    checked: false,
    isMandatory: false,
  },
});

const isAllEventChecklistsSelected = atom<boolean>({
  key: "isAllEventChecklistsSelected",
  default: false,
});

export { eventChecklists, isAllEventChecklistsSelected };
