import React from "react";
import { TkListItem } from "./styles";
import TkCheckBox from "@components/TkCheckBox";
import { useRecoilState, useResetRecoilState } from "recoil";
import { closeFilterExtendedDrawer, filterCustomers } from "@recoilData/index";

interface ITkCustomerListItem {
  customer: ICustomerTask;
  popup?: boolean;
}

const TkCustomerListItem: React.FC<ITkCustomerListItem> = ({
  customer,
  popup = false,
}) => {
  const closeExtendedDrawer = useResetRecoilState(closeFilterExtendedDrawer);
  const [filterCustomer, setFilterCustomer] = useRecoilState(
    filterCustomers(customer.id)
  );

  const handleSelectCustomer = (checked: boolean, unique: boolean = false) => {
    setFilterCustomer(checked);
    if (unique && !popup) closeExtendedDrawer();
  };

  return (
    <TkListItem
      key={customer.id}
      checked={filterCustomer}
      control={
        <TkCheckBox
          checked={filterCustomer}
          onChange={(_, checked) => handleSelectCustomer(checked)}
        />
      }
      label={
        <div onClick={() => handleSelectCustomer(!filterCustomer, true)}>
          {customer.name}
        </div>
      }
    />
  );
};

export default TkCustomerListItem;
