import React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { Container } from './styles';
import TkWelcomeSetupModal from '@components/TkLogin/TkWelcomeSetupModal';
import { ModalActions, ModalContent } from '@components/TkModalAlert';
import { DynamicButton, TkTypography } from '@components/index';
import { ExternalLoginType } from '@contexts/AuthContext/AuthContext';
import { useAuth, useModal } from '@contexts/index';

interface TkSocialLoginModalProps {
  socialPlatform: ExternalLoginType;
}

const TkSocialLoginModal = ({
  socialPlatform,
}: TkSocialLoginModalProps): JSX.Element => {
  const { t } = useTranslation();
  const { continueWithSocialMedia } = useAuth();
  const { handleCloseModal, setupModal } = useModal();

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  }

  async function createAccountWithSocialMedia() {
    const { data } = await continueWithSocialMedia();

    setupModal({
      content: <TkWelcomeSetupModal userData={data} />,
      disableBackdropClick: true,
    });
  }

  return (
    <>
      <ModalContent>
        <Container direction="column">
          <TkTypography
            fontFamily="Muli"
            fontSize="24px"
            fontWeight="bold"
            textAlign="center"
          >
            <Trans i18nKey="login.socialLoginError">
              <br />
              {{ social: capitalizeFirstLetter(socialPlatform) }}
            </Trans>
          </TkTypography>
          <TkTypography
            fontFamily="Lato"
            fontSize="16px"
            marginTop="20px"
            padding="0 20px"
          >
            {t('login.doYouWantToCreateNewAccountOrTryLogin')}
          </TkTypography>
        </Container>
      </ModalContent>
      <ModalActions>
        <Container flex={1} direction="column" padding="0" alignItems="center">
          <DynamicButton
            bgcolor="primary"
            variant="contained"
            onClick={createAccountWithSocialMedia}
            style={{ margin: 5, minWidth: '70%' }}
          >
            <TkTypography fontFamily="Lato" fontSize="16px">
              {t('login.createNewAccount')}
            </TkTypography>
          </DynamicButton>
          <DynamicButton
            color="primary"
            onClick={handleCloseModal}
            style={{ margin: 5 }}
          >
            <TkTypography fontFamily="Lato" fontSize="16px">
              {t('login.useDifferentMethod')}
            </TkTypography>
          </DynamicButton>
        </Container>
      </ModalActions>
    </>
  );
};

export default TkSocialLoginModal;
