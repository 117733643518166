import {
  CreateUserSessionRequest,
  CreateUserSessionResult,
  LoginRequest,
  LoginResult,
  SocialLoginRequest,
  SocialLoginResult,
} from 'types/session.type';

import api from './api';
import { AccessOriginId, ApiResultsKind, DeviceId } from '@consts/index';

const socialMediaSession = ({
  socialLoginDataRequest,
  socialLoginHeaders,
}: SocialLoginRequest): Promise<SocialLoginResult> =>
  api
    .post('socialMediaSession', socialLoginDataRequest, {
      headers: socialLoginHeaders,
    })
    .then((response) => ({
      kind: ApiResultsKind.OK,
      session: response.data,
    }))
    .catch((e) =>
      Promise.reject({
        kind: ApiResultsKind.ERROR,
        session: e.response.data,
      })
    );

const userSession = ({
  email,
  password,
  timezone,
}: LoginRequest): Promise<LoginResult> =>
  api
    .post('session', {
      email,
      password,
      timezone,
      origin: {
        device_id: DeviceId.COMPUTER,
        access_origin_id: AccessOriginId.WEB_APP,
      },
    })
    .then((response) => ({
      kind: ApiResultsKind.OK,
      sessionData: response.data,
    }))
    .catch((e) =>
      Promise.reject({
        kind: ApiResultsKind.ERROR,
        sessionData: e.response.data,
      })
    );

const createUserSession = ({
  firstName,
  lastName,
  email,
  password,
  recaptcha,
}: CreateUserSessionRequest): Promise<CreateUserSessionResult> =>
  api
    .post('user', {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      recaptcha,
    })
    .then((response) => ({
      kind: ApiResultsKind.OK,
      user: response.data,
    }))
    .catch((e) =>
      Promise.reject({
        kind: ApiResultsKind.ERROR,
        user: e.response.data,
      })
    );

export { userSession, socialMediaSession, createUserSession };
