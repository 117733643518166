import { useRecoilState } from 'recoil';
import { IProjectOfferWarningLocalStorage } from 'types/userContext.type';

import { ApiResultsKind, LocalStorage } from '@consts/index';
import { getUserSettingsState } from '@contexts/AuthContext/state';
import { SessionUserSettings } from '@contexts/AuthContext/types/session';
import { useLocalStorage } from '@hooks/useLocalStorage';
import { updateUserSettings } from '@services/userMethods';

interface UseUserSettings {
  userSettings: SessionUserSettings;
  handleSettingsUpdate: (
    settingsToUpdate: Partial<SessionUserSettings>
  ) => void;
  projectOfferWarning: IProjectOfferWarningLocalStorage;
  setProjectOfferWarning: (
    value:
      | IProjectOfferWarningLocalStorage
      | ((
          val: IProjectOfferWarningLocalStorage
        ) => IProjectOfferWarningLocalStorage)
  ) => void;
}

export const useUserSettings = (): UseUserSettings => {
  const [userSettings, setUserSettings] = useRecoilState(getUserSettingsState);
  const [projectOfferWarning, setProjectOfferWarning] =
    useLocalStorage<IProjectOfferWarningLocalStorage>(
      LocalStorage.PROJECT_OFFERS_WARNING,
      {
        dismissWarning: true,
        accounts: [],
      }
    );

  const handleSettingsUpdate = async (
    settingsToUpdate: Partial<SessionUserSettings>
  ) => {
    const { kind, settings: newSettings } = await updateUserSettings({
      settings: {
        ...userSettings,
        ...settingsToUpdate,
      },
    });
    if (kind === ApiResultsKind.OK) {
      setUserSettings(newSettings as SessionUserSettings);
    }
  };

  return {
    userSettings,
    handleSettingsUpdate,
    setProjectOfferWarning,
    projectOfferWarning,
  };
};
