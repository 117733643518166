import { atom, atomFamily } from 'recoil';
import { Crew } from '@components/TkTeams/types';

export const crewSelected = atom<Crew | null>({
  key: 'crewSelected',
  default: null,
});

const selectedCrew = atom<ICrew | undefined>({
  key: 'selectedCrew',
  default: undefined,
});

const editedCrew = atom<ICrew>({
  key: 'editedCrew',
  default: {
    id: 0,
    name: '',
    members: [],
    checked: true,
  },
});

const createCrew = atom<boolean>({
  key: 'createCrew',
  default: false,
});

const newCrewMembers = atomFamily<boolean, number>({
  key: 'newCrewMembers',
  default: false,
});

const isAllCrewsMembersSelected = atom<boolean>({
  key: 'isAllCrewsMembersSelected',
  default: false,
});

type CrewDrawers = 'create' | 'edit' | null;

export const crewDrawerState = atom<CrewDrawers>({
  key: 'crewDrawerState',
  default: null,
});

type TeamsDrawer =
  | 'create-crew'
  | 'edit-crew'
  | 'view-member'
  | 'edit-member'
  | 'invite-members'
  | 'pending-invites'
  | 'unselected';

export const teamsDrawerState = atom<TeamsDrawer>({
  key: 'teamsDrawer',
  default: null,
});

const selectedCrewIdState = atom<number | null>({
  key: 'selectedCrewIdState',
  default: null,
});

export {
  editedCrew,
  createCrew,
  selectedCrew,
  newCrewMembers,
  isAllCrewsMembersSelected,
  selectedCrewIdState,
};
