import { atom } from 'recoil';

import { Drawer } from '@hooks/drawer/drawer.types';

const drawersData = atom<IDrawers>({
  key: 'drawersData',
  default: {
    editTask: false,
    newTask: false,
    offer: false,
    filter: false,
    listOffers: false,
  },
});

const mainDrawerState = atom<Drawer | null>({
  key: 'mainDrawerState',
  default: null,
});

const extendedDrawerState = atom<Drawer | null>({
  key: 'extendedDrawerState',
  default: null,
});

export { drawersData, mainDrawerState, extendedDrawerState };
