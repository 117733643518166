import styled, { css } from 'styled-components';

import LoadingButton from '@mui/lab/LoadingButton';

import { getBackgroundFromVariant } from './index';
import { getColorFromTheme, getHoverColor } from '@helpers/index';
import Button from '@material-ui/core/Button';
import {
  palette,
  PaletteProps, //eslint-disable-line
  spacing,
  SpacingProps, //eslint-disable-line
} from '@material-ui/system';

const baseButtonStyles = css<PaletteProps & SpacingProps>`
  border-radius: 10px;
  text-transform: none;
  ${palette};
  ${spacing};
  background: ${(props) => getBackgroundFromVariant(props)};
  &.MuiButton-outlined {
    border-color: ${(props) =>
      props.color && getColorFromTheme(props.theme.palette, props.color)};
  }

  &:hover {
    background: ${(props) =>
      props.bgcolor || props.color
        ? getHoverColor(
            getColorFromTheme(props.theme.palette, props.bgcolor ?? props.color)
          )
        : '#FFF'};
  }
`;

export const TkButton = styled(Button)<PaletteProps & SpacingProps>`
  ${baseButtonStyles};
`;

export const TkLoadingButton = styled(LoadingButton)<
  PaletteProps & SpacingProps
>`
  ${baseButtonStyles};
  &.MuiLoadingButton-root.Mui-disabled {
    background: ${(props) => getBackgroundFromVariant(props)};
  }
  &.MuiLoadingButton-root.Mui-disabled .MuiTypography-root {
    color: transparent !important;
  }
  &.MuiLoadingButton-root.Mui-disabled .MuiLoadingButton-loadingIndicator {
    color: #fff;
  }
`;
