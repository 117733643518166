import React from 'react';
import { useTranslation } from 'react-i18next';
import "./ErrorPage.css";
import { DynamicButton, TkTypography } from '@components/index';
export default function ErrorPage(props) {
  const { t } = useTranslation();
  return (
    <div className={"error-page"}>
      <div className={"oops"}>Oops!</div>
      <div className={"message"}>
        <TkTypography
          fontSize={'16px'}
          color={'#3D4465'}
          fontFamily={'Lato'}
          textAlign={'center'}
        >
          {t('error.somethingWentWrong', {
            ns: 'app'
          })}
        </TkTypography>
      </div>
      {props.resetErrorBoundary && (
        <div>
          <DynamicButton
            disableElevation
            onClick={props.resetErrorBoundary}
          >
            <TkTypography
              fontSize={'16px'}
              color={'#3D4465'}
              fontFamily={'Lato'}
              textAlign={'center'}
            >
              {t('error.tryAgain', {
                ns: 'app'
              })}
            </TkTypography>
          </DynamicButton>
        </div>
      )}
    </div>
  );
}
