import { atom } from 'recoil';

const enqueuedSnackbarCount = atom<number>({
  key: 'enqueuedSnackbarCount',
  default: 0,
});

const alertsData = atom<IAlert[]>({
  key: 'alertsData',
  default: [],
});

const visibleAlertData = atom<IAlert>({
  key: 'visibleAlertData',
  default: {
    message: '',
    severity: '',
    visible: false,
    customComponent: undefined,
    autoHideDuration: undefined,
  },
});

export { alertsData, visibleAlertData, enqueuedSnackbarCount };
