import React from 'react';
import styled from 'styled-components';

const HorizontalDivider = styled.div`
  background-color: ${(props) =>
    props.color === 'solid' ? '#c8c7cc' : '#F0F0F0'};
  height: 1px;
  width: 100%;
`;

interface ITkHorizontalDivider {
  type?: 'solid' | 'default';
}

const TkHorizontalDivider = ({ type = 'default' }: ITkHorizontalDivider) => {
  return <HorizontalDivider color={type} />;
};

export default TkHorizontalDivider;
