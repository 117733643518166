import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { Grid, Skeleton } from '@mui/material';

import { TkExtendedWrapper, TkTaskWrapperItem } from '../styles';
import {
  TkAlert,
  TkTypography,
  TkExtendedDrawerAdditionalInfoItem,
} from '@components/index';
import { TaskbirdIntegrationsEnum } from '@consts/integrations';
import { useProjectOfferAdditionalInfo } from '@hooks/index';
import {
  selectedOffer,
  getUserAccountId,
  offersDetailsExtendedDrawer,
} from '@recoilData/index';

const TkOfferAdditionalInfoBooking = (): JSX.Element => {
  const { t } = useTranslation();
  const offer = useRecoilValue(selectedOffer);
  const accountId = useRecoilValue(getUserAccountId);
  const closeExtendedDrawer = useResetRecoilState(offersDetailsExtendedDrawer);

  useEffect(() => {
    if (offer.platform !== TaskbirdIntegrationsEnum.BOOKING_WIDGET) {
      closeExtendedDrawer();
      return;
    }
  }, [offer, closeExtendedDrawer]);

  const {
    data: additionalInfoList,
    isLoading,
    isSuccess,
  } = useProjectOfferAdditionalInfo(accountId, offer);

  if (isLoading) {
    return (
      <TkExtendedWrapper maxWidth="lg">
        <Skeleton
          variant="rounded"
          height={30}
          style={{ marginBottom: '20px' }}
        />
        <Skeleton
          variant="rounded"
          height={100}
          style={{ marginBottom: '10px' }}
        />
        <Skeleton
          variant="rounded"
          height={100}
          style={{ marginBottom: '10px' }}
        />
      </TkExtendedWrapper>
    );
  }

  if (!isSuccess) {
    return (
      <TkAlert severity="error">
        {t('errorDataUnavailable', { ns: 'common' })}
      </TkAlert>
    );
  }

  return (
    <TkExtendedWrapper maxWidth="lg">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="20px"
      >
        <TkTypography fontWeight="bold" fontFamily="Muli" fontSize={16}>
          {t('additionalInfo', { ns: 'offers' })}
        </TkTypography>
      </Grid>
      <TkTaskWrapperItem
        padding="0 0 2rem"
        height="100%"
        style={{
          overflowY: 'scroll',
        }}
      >
        {additionalInfoList?.data.map((additionalInfo) => (
          <TkExtendedDrawerAdditionalInfoItem
            additionalInfo={additionalInfo}
            key={additionalInfo.id}
            withDivider
          />
        ))}
      </TkTaskWrapperItem>
    </TkExtendedWrapper>
  );
};

export default TkOfferAdditionalInfoBooking;
