import { Grid } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 588px;
  min-height: 480px;
  padding: 5px 2px;
`;

export const SelectableOptionWrapper = styled.button`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.itemID === 'selected' ? '#e9f5ff' : '#ffffff'};
  border: 2px solid #4952a9;
  border-radius: 5px;
  width: 100%;
  min-height: 152px;
  position: relative;
  padding: 10px;
  margin: 10px 0;
  max-height: 160px;

  &:hover {
    background-color: #e9f5ff;
  }
`;

export const SelectableOptionIconWrapper = styled(Grid)`
  display: flex;
  flex: 1;
  max-width: 40%;
  width: 109px;
  justify-content: center;
  align-items: center;
`;

export const SelectableOptionTextWrapper = styled(Grid)`
  display: flex;
  flex: 1;
  justify-content: center;
  text-align: left;
`;

export const AllDoneNewTeamImageWrapper = styled(Grid)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 250px;
  width: 230px;
`;

export const StoreIconsWrapper = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 40px;
`;
