import { atom } from "recoil";

const isFilterSet = atom({
  key: "isFilterSet",
  default: false,
});

const filterExtendedDrawer = atom<ITkExtendedObject>({
  key: "filterExtendedDrawer",
  default: {
    open: false,
    type: "",
  },
});

const isFilterToday = atom<boolean>({
  key: "isFilterToday",
  default: false
});

export { isFilterSet, isFilterToday, filterExtendedDrawer };
