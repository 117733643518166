import { TFunction, useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { TaskStatusEnum } from '@consts/index';
import { ExtendedDrawersTypes } from '@hooks/drawer/drawer.types';
import { useDrawer } from '@hooks/index';
import { event as eventRecoil } from '@recoilData/index';

interface TaskInventoryHookParams {
  t: TFunction<'translation'>;
  wrapperColor: 'selected' | '';
  itemsCount: number;
  handleInventory(): void;
  items: InventoryItem[];
  close(): void;
}

export const useTaskInventory = (
  inventory: TaskInventory
): TaskInventoryHookParams => {
  const { t } = useTranslation();
  const { callDrawer, currentDrawer, closeExtDrawer } = useDrawer();

  // Recoil Values
  const event = useRecoilValue(eventRecoil);

  const wrapperColor =
    currentDrawer?.extended.id === ExtendedDrawersTypes.EDIT_TASK_INVENTORY_LIST
      ? 'selected'
      : '';

  const shouldOnlyDisplayNotReported =
    event.status !== TaskStatusEnum.DONE &&
    event.status !== TaskStatusEnum.IN_PROGRESS;

  const items =
    inventory?.items.map((item) => ({
      ...item,
      quantity: shouldOnlyDisplayNotReported ? null : item.quantity,
    })) || [];

  const itemsCount = items.length || 0;

  const handleInventory = () =>
    callDrawer({
      extended: {
        id: ExtendedDrawersTypes.EDIT_TASK_INVENTORY_LIST,
      },
    });

  return {
    t,
    items,
    wrapperColor,
    itemsCount,
    handleInventory,
    close: closeExtDrawer,
  };
};
