import { atom, atomFamily } from "recoil";

const eventAssignees = atomFamily<boolean, number>({
  key: "eventAssignees",
  default: false,
});

const isAllEventAssigneesSelected = atom<boolean>({
  key: "isAllEventAssigneesSelected",
  default: false,
});

export { eventAssignees, isAllEventAssigneesSelected };
