import { atom } from 'recoil';

const serviceTabChanged = atom<IServiceTabChanged>({
  key: 'serviceTabChanged',
  default: {
    details: false,
    attributes: false,
    questions: false,
    hours: false,
    preferences: false,
  },
});

export { serviceTabChanged };
