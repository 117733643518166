import React from 'react';

import { useTranslation } from 'react-i18next';
import { CallBackProps, Step, ACTIONS, EVENTS } from 'react-joyride';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useRecoilState } from 'recoil';

import TkTourGuideToolTip from '@components/TkBookingWidget/TkBookingTour/TkBookingToolTips/TkTourGuideToolTip/TkTourGuideToolTip';
import { TkTour } from '@components/TkTourGuide';
import { AppLocationURLs } from '@consts/index';
import { useMixpanel } from '@contexts/index';
import { isFirstTimeBookingTour } from '@recoilData/index';

const TkBookingTour: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const isRoot = useRouteMatch(AppLocationURLs.HOME);
  const [bookingTourControll, setBookingTourControll] = useRecoilState(
    isFirstTimeBookingTour
  );
  const {
    track,
    trackDebounced,
    consts: {
      MIXPANEL_EVENT_NAMES_ORIGIN,
      MIXPANEL_EVENT_ACTIONS,
      MIXPANEL_PAGE_NAMES,
    },
  } = useMixpanel();

  const steps: Step[] = [
    {
      target: '#booking',
      title: t('bookingTourSteps.booking.title', { ns: 'bookingWidget' }),
      placement: 'right',
      disableBeacon: true,
      styles: { overlay: { opacity: 0.7, zIndex: 1000 } },
      floaterProps: {
        styles: {
          floater: { zIndex: 1000 },
          content: { backgroundColor: '#011F41' },
        },
      },
      placementBeacon: 'bottom',
      offset: -5,
      content: t('bookingTourSteps.booking.content', { ns: 'bookingWidget' }),
    },
    {
      target: '#services',
      title: t('bookingTourSteps.services.title', { ns: 'bookingWidget' }),
      styles: { overlay: { opacity: 0 } },
      placement: 'right',
      disableBeacon: true,
      offset: -5,
      content: t('bookingTourSteps.services.content', { ns: 'bookingWidget' }),
    },
    {
      target: '#scheduling',
      title: t('bookingTourSteps.scheduling.title', { ns: 'bookingWidget' }),
      placement: 'right',
      disableBeacon: true,
      styles: { overlay: { opacity: 0 } },
      placementBeacon: 'bottom',
      offset: -5,
      content: t('bookingTourSteps.scheduling.content', {
        ns: 'bookingWidget',
      }),
    },
    {
      target: '#customization',
      title: t('bookingTourSteps.customization.title', { ns: 'bookingWidget' }),
      placement: 'right',
      disableBeacon: true,
      styles: { overlay: { opacity: 0 } },
      offset: -5,
      content: t('bookingTourSteps.customization.content', {
        ns: 'bookingWidget',
      }),
    },
    {
      target: '#installation',
      title: t('bookingTourSteps.installation.title', { ns: 'bookingWidget' }),
      placement: 'right',
      disableBeacon: true,
      styles: { overlay: { opacity: 0 } },
      offset: -5,
      content: t('bookingTourSteps.installation.content', {
        ns: 'bookingWidget',
      }),
    },
  ];

  const paths = [
    '/booking-site-settings/services',
    '/booking-site-settings/scheduling',
    '/booking-site-settings/customization',
    '/booking-site-settings/installation',
  ];

  const handleCallback = (data: CallBackProps) => {
    const { index, action, type } = data;
    if (index === 0 && type === EVENTS.TOUR_START) {
      if (isRoot.isExact) return;
      trackDebounced(MIXPANEL_EVENT_NAMES_ORIGIN.BOOKING_WIDGET, {
        action:
          MIXPANEL_EVENT_ACTIONS.BOOKING_WIDGET.BOOKING_TOURS
            .BOOKING_TOUR_FINISH_INITIAL_SETUP
            .BOOKING_TOUR_FINISH_INITIAL_SETUP_START,
        page: MIXPANEL_PAGE_NAMES.CALENDAR.HOME,
      });
      setBookingTourControll({
        ...bookingTourControll,
        openSideBar: true,
        stepIndex: 0,
      });
      history.push(AppLocationURLs.HOME);
      return;
    }

    const isAtEnd = index === steps.length - 1;

    if (type === EVENTS.TOUR_END) {
      isAtEnd
        ? track(MIXPANEL_EVENT_NAMES_ORIGIN.BOOKING_WIDGET, {
            action:
              MIXPANEL_EVENT_ACTIONS.BOOKING_WIDGET.BOOKING_TOURS
                .BOOKING_TOUR_FINISH_INITIAL_SETUP
                .BOOKING_TOUR_FINISH_INITIAL_SETUP_COMPLETE,
            page: MIXPANEL_PAGE_NAMES.BOOKING_WIDGET.SERVICES_LIST,
          })
        : isRoot?.isExact
        ? track(MIXPANEL_EVENT_NAMES_ORIGIN.BOOKING_WIDGET, {
            action:
              MIXPANEL_EVENT_ACTIONS.BOOKING_WIDGET.BOOKING_TOURS
                .BOOKING_TOUR_FINISH_INITIAL_SETUP
                .BOOKING_TOUR_FINISH_INITIAL_SETUP_SKIP,
            page: MIXPANEL_PAGE_NAMES.CALENDAR.HOME,
            skip: index,
          })
        : track(MIXPANEL_EVENT_NAMES_ORIGIN.BOOKING_WIDGET, {
            action:
              MIXPANEL_EVENT_ACTIONS.BOOKING_WIDGET.BOOKING_TOURS
                .BOOKING_TOUR_FINISH_INITIAL_SETUP
                .BOOKING_TOUR_FINISH_INITIAL_SETUP_SKIP,
            page: MIXPANEL_PAGE_NAMES.BOOKING_WIDGET.SERVICES_LIST,
            skip: index,
          });
      setBookingTourControll({
        openTour: false,
        openSideBar: false,
        openServiceTab: false,
        openServiceTour: true,
        openServiceDraft: false,
        stepIndex: 0,
      });
      history.push('/booking-site-settings/services');
      return;
    }

    if (action === ACTIONS.NEXT && type === EVENTS.STEP_AFTER) {
      if (index === 0) {
        setBookingTourControll({
          ...bookingTourControll,
          openSideBar: false,
          stepIndex: 1,
        });
        history.push(paths[0]);
        return;
      }

      let auxControl = {
        ...bookingTourControll,
        openSideBar: false,
        stepIndex: bookingTourControll.stepIndex + 1,
      };
      if (index === 4) {
        auxControl = {
          ...auxControl,
          openServiceTab: true,
        };
      }

      setBookingTourControll(auxControl);
      history.push(paths[bookingTourControll.stepIndex]);
      return;
    }

    if (action === ACTIONS.PREV && type === EVENTS.STEP_AFTER) {
      if (index === 1) {
        setBookingTourControll({
          ...bookingTourControll,
          openSideBar: true,
          stepIndex: 0,
        });
        history.push(AppLocationURLs.HOME);
        return;
      }

      setBookingTourControll({
        ...bookingTourControll,
        openSideBar: false,
        stepIndex: bookingTourControll.stepIndex - 1,
      });
      history.push(paths[bookingTourControll.stepIndex - 2]);
      return;
    }
  };

  return (
    <TkTour
      paths={paths}
      steps={steps}
      tooltip={TkTourGuideToolTip}
      handleCallback={handleCallback}
      stepIndex={bookingTourControll.stepIndex}
    />
  );
};

export { TkBookingTour };
