import { selector } from 'recoil';

import { customersData } from '@recoilData/atoms';
import { event } from '@recoilData/index';

const eventTitle = selector<string>({
  key: 'eventTitle',
  get: ({ get }) => get(event).title,
  set: ({ set, get }, newValue) =>
    set(event, { ...get(event), title: newValue as string }),
});

const eventNotes = selector<string>({
  key: 'eventNotes',
  get: ({ get }) => get(event).notes,
  set: ({ set, get }, newValue) =>
    set(event, { ...get(event), notes: newValue as string }),
});

const eventTaskType = selector<string>({
  key: 'eventTaskType',
  get: ({ get }) => get(event)?.taskType || 'single-task',
  set: ({ set, get }, newValue) =>
    set(event, { ...get(event), taskType: newValue as string }),
});

const eventCustomer = selector<ICustomerTask | undefined>({
  key: 'eventCustomer',
  get: ({ get }) => get(event).customer,
  set: ({ set, get }, newValue) => {
    set(event, {
      ...get(event),
      customer: newValue as ICustomerTask | undefined,
    });
  },
});

const setEventCustomerById = selector<number>({
  key: 'setEventCustomerById',
  get: () => 0,
  set: ({ set, get }, newValue) => {
    const id = newValue as number;
    const customer = get(customersData).find((customer) => customer.id === id);
    const locations =
      customer?.locations.length === 1 ? customer.locations[0] : undefined;

    set(event, {
      ...get(event),
      customer: customer,
      location: locations,
    });
  },
});

const eventLocation = selector<ILocationTask | undefined>({
  key: 'eventLocation',
  get: ({ get }) => get(event).location,
  set: ({ set, get }, newValue) => {
    set(event, {
      ...get(event),
      location: newValue as ILocationTask | undefined,
    });
  },
});

export {
  setEventCustomerById,
  eventCustomer,
  eventLocation,
  eventTaskType,
  eventTitle,
  eventNotes,
};
