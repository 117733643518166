import React from "react";
import Joyride, { CallBackProps, Step, TooltipRenderProps } from "react-joyride";
interface ITkTour {
  steps: Step[];
  paths: string[];
  stepIndex?: number;
  tooltip: React.FC<TooltipRenderProps>;
  handleCallback: (data: CallBackProps) => void;
}

const TkTour: React.FC<ITkTour> = ({
  steps,
  stepIndex,
  tooltip,
  handleCallback
}) => {
  return (
    <Joyride
      steps={steps}
      stepIndex={stepIndex}
      disableOverlayClose
      continuous
      showProgress
      styles={{options: { arrowColor: "#011F41" },}}
      showSkipButton
      callback={handleCallback}
      tooltipComponent={tooltip}
    />
  );
};

export { TkTour };
