import styled from "styled-components";

import {
  spacing,
  SpacingProps,
  palette,
  PaletteProps, //eslint-disable-line
} from "@material-ui/system";

const TkChip = styled.span<PaletteProps & SpacingProps>`
  font-size: 14px;
  border-radius: 11px;
  margin-left: 5px;
  padding: 2px 10px;
  font-weight: bold;

  ${palette}
  ${spacing}
`;

export default TkChip;
