import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';

import { SessionData, SessionUserSettings } from '../types/session';
import { LocalStorage } from '@consts/index';

export const { persistAtom } = recoilPersist({
  key: LocalStorage.TASKBIRD_SESSION,
});

export const sessionState = atom<SessionData | null>({
  key: 'session',
  default: null,
  effects_UNSTABLE: [persistAtom],
});

export const getSessionUserState = selector<SessionData['user']>({
  key: 'getSessionUser',
  get: ({ get }) => {
    const session = get(sessionState);
    return session?.user;
  },
  set: ({ set, get }, newValue) => {
    const session = get(sessionState);
    set(sessionState, { ...session, user: newValue });
  },
});

export const isLoggedInState = selector<boolean>({
  key: 'isLoggedIn',
  get: ({ get }) => {
    const session = get(sessionState);
    return session !== null;
  },
});

export const getAccessTokenState = selector<string>({
  key: 'getAccessToken',
  get: ({ get }) => {
    const session = get(sessionState);
    return session?.access_token;
  },
});

export const getUserSettingsState = selector<SessionUserSettings>({
  key: 'getUserSettings',
  get: ({ get }) => {
    const session = get(sessionState);
    return session?.settings;
  },
  set: ({ set, get }, newValue) => {
    const session = get(sessionState);
    set(sessionState, { ...session, settings: newValue });
  },
});

export const getUserAccountState = selector<SessionData['account']>({
  key: 'getUserAccountState',
  get: ({ get }) => {
    const session = get(sessionState);
    return session?.account;
  },
  set: ({ set, get }, newValue) => {
    const session = get(sessionState);
    set(sessionState, { ...session, account: newValue });
  },
});
