export default {
  connect: 'Conectar',
  defaultError: 'Algo deu errado, por favor tente novamente mais tarde.',
  apartmentNumber: 'Número da propriedade, Nome do prédio, etc.',
  ok: 'OK',
  edit: 'Editar',
  task: 'Tarefa',
  next: 'Próximo',
  skip: 'Pular',
  back: 'Voltar',
  city: 'Cidade',
  state: 'Estado',
  delete: 'Apagar',
  create: 'Criar',
  cancel: 'Cancelar',
  contact: 'Contato',
  address: 'Endereço',
  publish: 'Publicar',
  profile_url: 'URL do Perfil',
  get_started: 'Comece a usar',
  phone_number: 'Número de Telefone',
  save_changes: 'Salvar Mudanças',
  send_message: 'Envie uma mensagem',
  email_address: 'Endereço de Email',
  social_networks: 'Redes Sociais',
  emailPlaceholder: 'nome@email.com',
  select_all: 'Selecionar Todos',
  select_all_plural: 'Desselecionar Todos',
  select_crews: 'Selecionar Equipes',
  sendMeTheApp: 'Envie-me o aplicativo!',
  select_teammates: 'Selecione Colegas de Equipe',
  type_phone_number: 'Digite um número de telefone',
  please_select_a_time: 'Por favor, selecione um horário',
  couldnt_find_your_address: 'Não encontrou seu endereço?',
  changes_successfully_saved: 'Alterações salvas com sucesso',
  date: 'Data',
  code: 'Código',
  enabled: 'Habilitado',
  disabled: 'Desabilitado',
  unselect_all: 'Desselecionar Todos',
  weekdays: {
    sunday: 'Domingo',
    monday: 'Segunda',
    tuesday: 'Terça',
    wednesday: 'Quarta',
    thursday: 'Quinta',
    friday: 'Sexta',
    saturday: 'Sábado',
  },
  months: {
    january: 'Janeiro',
    february: 'Fevereiro',
    march: 'Março',
    april: 'Abril',
    may: 'Maio',
    june: 'Junho',
    july: 'Julho',
    august: 'Agosto',
    september: 'Setembro',
    october: 'Outubro',
    november: 'Novembro',
    december: 'Dezembro',
  },
  // easy development use
  enabledBool: '$t(common:enabled)',
  enabledBool_plural: '$t(common:disabled)',
  role: 'Função',
  name: 'Nome',
  optional: 'opcional',
  permissions: 'Permissões',
  email: 'Email',
  send: 'Enviar',
  about: 'Sobre',
  enabledBool: '$t(common:enabled)',
  enabledBool_plural: '$t(common:disabled)',
};
