import { CircularProgress } from "@material-ui/core";
import { AbsoluteBackdrop } from "./styles";
import React from "react";

interface ITkLoadingBackground {
  loading: boolean;
}

const TkLoadingBackground: React.FC<ITkLoadingBackground> = ({ loading }) => {
  return (
    <AbsoluteBackdrop open={loading}>
      <CircularProgress color="inherit" />
    </AbsoluteBackdrop>
  );
};

export default TkLoadingBackground;
