import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, ContainerSpan, ContainerSpanAddress } from './styles';
import { Skeleton, Grid } from '@mui/material';
import { TkTypography, TkIcon } from '@components/index';
import FormControlInput from './FormControlInput';
import useSearchAddress from './useSearchAddress';
import AutocompleteLocation from '@components/TkSearchLocation/AutoCompleteLocation';

const TkSearchLocation: React.FC<TkSearchLocation> = ({
  address,
  isCustomerAddress = false,
  errorForm,
  setAddress,
  addressHasChanged,
  setAddressHasChanged,
}) => {
  const { t } = useTranslation();

  const countryCode = address?.country?.abbr || '';

  const {
    isStatusReady,
    areAddressFieldsHidden,
    displayAddressFields,
    handleSelect,
    handleChangeCity,
    handleChangeState,
    handleChangeValue,
    addressHasErrorAt,
  } = useSearchAddress({
    address,
    isCustomerAddress,
    errorForm,
    setAddress,
    addressHasChanged,
    setAddressHasChanged,
  });

  return (
    <Container>
      {isStatusReady ? (
        <>
          <AutocompleteLocation
            type="address"
            value={address?.address}
            handleSelect={handleSelect}
            hasError={addressHasErrorAt('address')}
          />
          <ContainerSpan>
            <TkTypography
              align="left"
              color="#4952A9"
              letterSpacing={0}
              style={{ cursor: 'pointer' }}
              onClick={displayAddressFields}
            >
              {t('couldnt_find_your_address', { ns: 'common' })}
            </TkTypography>
          </ContainerSpan>
          <ContainerSpanAddress>
            <Grid item xs={12} md={7}>
              <FormControlInput
                type="complement"
                onChange={handleChangeValue}
                value={address?.complement}
                hasError={addressHasErrorAt('complement')}
              />
            </Grid>
          </ContainerSpanAddress>
          {areAddressFieldsHidden ? null : (
            <>
              <ContainerSpanAddress>
                <AutocompleteLocation
                  type="country"
                  handleSelect={handleSelect}
                  value={address?.country?.name}
                  hasError={addressHasErrorAt('country')}
                  popupIcon={<TkIcon className="fas fa-angle-down" />}
                />
              </ContainerSpanAddress>
              <ContainerSpanAddress
                container
                spacing={0.4}
                justifyContent="space-between"
              >
                <Grid item xs={9} md={5.8}>
                  <AutocompleteLocation
                    type="state"
                    countryCode={countryCode}
                    value={address?.state?.name}
                    handleSelect={handleSelect}
                    setValue={handleChangeState}
                    hasError={addressHasErrorAt('state')}
                    isReadOnly={address?.state?.name === 'Stateless'}
                  />
                </Grid>
                <Grid item xs={9} md={5.8}>
                  <AutocompleteLocation
                    type="city"
                    countryCode={countryCode}
                    value={address?.city?.name}
                    handleSelect={handleSelect}
                    setValue={handleChangeCity}
                    hasError={addressHasErrorAt('city')}
                  />
                </Grid>
              </ContainerSpanAddress>
              <ContainerSpanAddress>
                <Grid item xs={9} md={5.8}>
                  <FormControlInput
                    type="zip_code"
                    value={address?.zip_code}
                    countryCode={countryCode}
                    onChange={handleChangeValue}
                    hasError={addressHasErrorAt('zip_code')}
                  />
                </Grid>
              </ContainerSpanAddress>
            </>
          )}
        </>
      ) : (
        <Skeleton variant="rectangular" width={'100%'} height={'65px'} />
      )}
    </Container>
  );
};

export default TkSearchLocation;
