import React, { useEffect, useState } from 'react';

import { LinearProgress } from '@mui/material';

interface CircularLoadingProps {
  undoTimeout: number;
  color?: 'error' | 'success';
  style?: React.CSSProperties;
}

const MapColorByAction = {
  error: 'progressError',
  success: 'progressSuccess',
} as const;

const MapColor = {
  error: 'error',
  success: 'success',
  requestError: 'error',
} as const;

export const CircularLoading = ({
  undoTimeout,
  color,
}: CircularLoadingProps) => {
  const [progress, setProgress] = useState(0);

  const [timeCount, setTimeCount] = useState(undoTimeout);

  useEffect(() => {
    const increaseProgress = 100 / undoTimeout;
    const timer = setInterval(() => {
      setTimeCount((prevProgress) => prevProgress - 1);
      setProgress((prevProgress) => prevProgress + increaseProgress);
    }, 1000);

    if (timeCount === 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [timeCount, undoTimeout]);

  return (
    <LinearProgress
      sx={{
        height: '2.5rem',
        backgroundColor: (theme) => theme.palette[MapColor[color]].main,
      }}
      color={MapColorByAction[color]}
      variant="determinate"
      value={progress}
    />
  );
};
