import styled from "styled-components";
import { Box, Button, MobileStepper } from "@mui/material";

export const BoxTooltip = styled(Box)`
  background-color: #011F41 ;
  border-radius: 5px;
  padding: 10px;
  color: #FFFFFF;
  width: 284px;
  height: auto;
  margin-left: 0px;
`;

export const BoxTooltipTitle = styled(Box)`
  text-align: start;
  h3 {
    font-family: "Muli";
    font-size: 16px;
    font-style: normal;
    font-variant: normal;
    font-weight: bold;
  }
`;

export const BoxTooltipContent = styled(Box)`
  text-align: start;
  padding: 20px 0px;
  font-family: "Lato";
  font-size: 14px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  letter-spacing: 0;
  opacity: 1;
`;

export const BoxTooltipContentButton = styled(Box)`
  width: 100%;
  height: 24%;
  display: flex;
  justify-content: space-between;
`;

export const BoxTooltipContentButtonControls = styled(Box)`
  width: 50%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  `;

export const BoxTooltipButtonStepFinish = styled(Button)`

&.MuiButton-root {
  height: 36px;
  min-width: 53px;
  width: 53px;
  background-color: #2699FA;
  border: 1px solid #2699FA;
  border-radius: 5px;
  box-shadow: 0px 5px 20px #00000026;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  color: #ffffff;
  font: normal normal bold 12px Lato;
  text-transform: none;

  :hover {
    background-color: #2699FA;
  }
}

  `;

export const BoxTooltipButtonStep = styled(Button)`

&.MuiButton-root {
  height: 36px;
  min-width: 53px;
  width: 53px;
  background-color: #F4F6F8;
  border: 1px solid #616579;
  border-radius: 5px;
  box-shadow: 0px 5px 20px #00000026;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;

  >span {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  :hover {
    border: 1px solid #2699FB;
    color: #2699FB;
    background-color: #F4F6F8;
  }
}

  `;

export const BoxTooltipButtonSkip = styled(Button)`

  &.MuiButton-root {
    padding: 0;
    min-width: 0;
    text-transform: none;
    text-align: start;
    font-family: "Lato";
    color: #FFFFFF;
    font-size: 12px;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    letter-spacing: 0;
    opacity: 1;
  }

  `;

export const BoxTooltipProgressDots = styled(MobileStepper)`

  &.MuiMobileStepper-root {
    background: #011F41;
    position: relative;
    .MuiMobileStepper-dot{
      width: 7px;
      height: 7px;
      background-color: #616579;
    }
    .MuiMobileStepper-dotActive{
      width: 7px;
      height: 7px;
      background-color: #2699FA;
    }
  }


  `;
