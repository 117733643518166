import { offersData } from '@recoilData/atoms';
import { selector, selectorFamily } from 'recoil';

const getOffersCount = selector({
  key: 'getOffersCount',
  get: ({ get }) => get(offersData).length,
});

const getOffersCountByInterval = selectorFamily({
  key: 'getOffersCountByInterval',
  get:
    (date: { initialDate: number; finalDate: number }) =>
    ({ get }) => {
      const offers = get(offersData);
      let offersCount = 0;

      offers.forEach((offer) => {
        const { start_time_epoch, end_time_epoch } = offer as any;

        if (
          (end_time_epoch && end_time_epoch < date.initialDate) ||
          (start_time_epoch && start_time_epoch > date.finalDate)
        ) {
          return;
        }

        offersCount++;
      });

      return offersCount;
    },
});

export { getOffersCount, getOffersCountByInterval };
