import { selector, selectorFamily } from "recoil";
import {
  filterCrews,
  isAllFilterCrewsSelected,
  crewsData,
} from "@recoilData/index";
import { filterAssignees } from "@recoilData/atoms";

const searchFilterCrewData = selectorFamily({
  key: "searchFilterCrewData",
  get: (search: string) => ({ get }) => {
    const crews = get(crewsData);
    if (!search) return crews;
    return crews.filter((item) => {
      return item.name.toLocaleLowerCase().includes(search.toLocaleLowerCase());
    });
  },
});

const getFilteredCrews = selector({
  key: "getFilteredCrews",
  get: ({ get }) => {
    const crews = get(crewsData);
    const newCrews: ICrew[] = [];

    crews.forEach((crew) => {
      const filterState = get(filterCrews(crew.id));
      newCrews.push(generateCrew(crew, filterState));
    });

    return newCrews;
  },
});

const getFilteredCrewsCount = selector({
  key: "getFilteredCrewsCount",
  get: ({ get }) => {
    const crews = get(getFilteredCrews);
    let count = 0;
    crews.forEach((crew) => {
      if (crew.checked) count++;
    });
    return count;
  },
});

const selectAllFilterCrews = selector<boolean>({
  key: "selectAllFilterCrews",
  get: ({ get }) => get(isAllFilterCrewsSelected),
  set: ({ set, get }, newValue) => {
    const crews = get(crewsData);

    set(isAllFilterCrewsSelected, newValue as boolean);

    crews.forEach((crew) => {
      crew.members.forEach((member) => {
        set(filterAssignees(member.id), newValue as boolean);
      });

      set(filterCrews(crew.id), newValue as boolean);
    });
  },
});

const selectFilterCrew = selector({
  key: "selectFilterCrew",
  get: () => {},
  set: ({ get, set }, newValue) => {
    const { id, checked } = newValue as any;
    const crew = get(crewsData).find((crew) => crew.id === id);

    set(filterCrews(id), checked);
    if (crew !== undefined)
      crew.members.forEach((member) => {
        set(filterAssignees(member.id), checked as boolean);
      });
  },
});

const generateCrew = (crew: ICrew, checked: boolean) => {
  const newCrew: ICrew = {
    id: crew.id,
    name: crew.name,
    members: crew.members,
    checked: checked,
  };

  return newCrew;
};

export {
  searchFilterCrewData,
  selectFilterCrew,
  getFilteredCrews,
  selectAllFilterCrews,
  getFilteredCrewsCount,
};
