import { selector } from 'recoil';

import {
  event,
  crewsData,
  eventCrews,
  assigneesData,
  checklistsData,
  eventAssignees,
  eventChecklists,
  isNewTaskExternal,
  isAllEventCrewsSelected,
  isAllEventAssigneesSelected,
  isAllEventChecklistsSelected,
} from '@recoilData/index';

const resetEvent = selector<IEvent>({
  key: 'resetEvent',
  get: ({ get }) => get(event),
  set: ({ reset, get }) => {
    if (!get(isNewTaskExternal)) {
      get(assigneesData).forEach((assignee) =>
        reset(eventAssignees(assignee.id))
      );
    }
    get(checklistsData).forEach((checklist) =>
      reset(eventChecklists(checklist.id))
    );
    get(crewsData).forEach((crew) => reset(eventCrews(crew.id)));
    reset(event);
    reset(isAllEventAssigneesSelected);
    reset(isAllEventChecklistsSelected);
    reset(isAllEventCrewsSelected);
  },
});

const getEventStatus = selector<string>({
  key: 'getEventStatus',
  get: ({ get }) => get(event).status,
});

const getEventId = selector<number>({
  key: 'getEventId',
  get: ({ get }) => get(event).id,
});

const getEventCrewAssignmentsNotes = selector<ITkTeammateComment[]>({
  key: 'getEventCrewAssignmentsNotes',
  get: ({ get }) => get(event).crewAssignmentsNotes,
});

const getEventCrewAssignmentsImages = selector<ITkTeammateImages[]>({
  key: 'getEventCrewAssignmentsImages',
  get: ({ get }) => get(event).crewAssignmentsImages,
});

const getEventAssignmentId = selector<number>({
  key: 'getEventAssignmentId',
  get: ({ get }) => get(event).assignmentId,
});

const eventImagesFile = selector<File[]>({
  key: 'eventImagesFile',
  get: ({ get }) => get(event).imagesFile,
});

const eventStart = selector<Date>({
  key: 'eventStart',
  get: ({ get }) => get(event).start,
  set: ({ set, get }, newValue) =>
    set(event, { ...get(event), start: newValue as Date }),
});

const eventEnd = selector<Date>({
  key: 'eventEnd',
  get: ({ get }) => get(event).end,
  set: ({ set, get }, newValue) =>
    set(event, { ...get(event), end: newValue as Date }),
});

const addEventImagesFile = selector<File[]>({
  key: 'addEventImagesFile',
  get: ({ get }) => get(eventImagesFile),
  set: ({ get, set }, newValue) => {
    const newImages = newValue as any;
    const imagesFile: File[] = [];
    const oldImagesFile = get(eventImagesFile);

    oldImagesFile.forEach((image) => imagesFile.push(image));

    if (newImages.length === 1) {
      imagesFile.push(newImages[0]);
    } else {
      newImages.forEach((image) => imagesFile.push(image));
    }

    set(event, { ...get(event), imagesFile });
  },
});

const removeEventImagesFile = selector<any>({
  key: 'removeEventImagesFile',
  get: ({ get }) => get(eventImagesFile),
  set: ({ get, set }, newValue) => {
    const imagesFile = get(eventImagesFile).filter((i) => {
      if (i !== (newValue as File)) return i;
    });
    set(event, { ...get(event), imagesFile });
  },
});

export {
  getEventCrewAssignmentsImages,
  getEventCrewAssignmentsNotes,
  removeEventImagesFile,
  getEventAssignmentId,
  addEventImagesFile,
  eventImagesFile,
  getEventStatus,
  resetEvent,
  getEventId,
  eventStart,
  eventEnd,
};
