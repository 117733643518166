import { atom, atomFamily } from "recoil";

const editEventAssignees = atomFamily<boolean, number>({
  key: "editEventAssignees",
  default: false,
});

const isAllEditEventAssigneesSelected = atom<boolean>({
  key: "isAllEditEventAssigneesSelected",
  default: false,
});

export { editEventAssignees, isAllEditEventAssigneesSelected };
