export default {
  title: "Integraciones",
  tbnbIntegrationCard: {
    title: "Turno",
    textContent:
      "Sincroniza automáticamente tus proyectos de Turno y gestiónalos en Taskbird.",
    integratedTextContext:
      "Ahora puedes asignar proyectos a tus compañeros de equipo y completarlos en Taskbird.",
    integratedTextContextWithMoveout:
      "Ahora podes asignar proyectos a tus compañeros de equipo y completarlos en Taskbird. Se marcarán como completados en Turno",
    connectedOn: "<0>Conectado</0> en",
    sellingPointOne: "Tus proyectos y clientes se mantendrán sincronizados.",
    sellingPointTwo:
      "Cuando un proyecto sea actualizado en Turno, será actualizado en Taskbird.",
    sellingPointThree:
      "Cuando un proyecto sea completado en Taskbird, lo marcaremos como completado en Turno.",
    connectModal: {
      title: "Conectar con Turno",
      email_required: "Por favor ingresa un email",
      email_invalid: "Por favor ingresa un email válido",
      password_required: "Por favor ingresa la contraseña",
      success: "Tu cuenta de Turno fue conectada correctamente",
      invalid_credentials: "Email o contraseña equivocados.",
      email_already_integrated:
        "Este email ya fue integrado con Taskbird. Si necesitas ayuda por contacta a nuestro servicio de atención al cliente..",
      invalid_account_type:
        "Solo puedes integrar una cuenta de persona de limpieza de Turno.",
      account_already_integrated_with_client:
        "Esta cuenta ya fue integrada con Taskbird. Si necesitas ayuda por contacta a nuestro servicio de atención al cliente.",
      textContent: "Por favor, ingresa tu cuenta de Turno",
      enterTheCodeToConnectAccount:
        "Ingresa el código para conectar tus cuentas",
      enterTheCodeToConnectAccountMessage:
        "Te enviamos un código de 6 dígitos a <0>{{ email }}</0> y un <0>mensaje de texto</0> al número de teléfono vinculado a la cuenta. Por favor, ingrésalo debajo:",
      codeResendSuccessfully: "Código reenviado con éxito",
      email_not_found:
        "Este correo electrónico no es utilizado por una cuenta de Turno.",
    },
  },
  moveoutIntegrationCard: {
    title: "Moveout.com",
    textContent: "Únete al nuevo Marketplace para hacer crecer tu negocio.",
    join: "Únete a Moveout.com",
    joinedOn: "<0>Te uniste</0> el {{- date}}",
    editPriceTooltip: "Edita la tarifa por hora por persona",
    editPriceSuccess: "Tarifa actualizada con éxito",
    integratedContent: {
      enabledRequests:
        "Actualmente estás enviando ofertas automáticas de limpieza por mudanza en base a tu configuración de abajo.",
      disabledRequests:
        "Tu cuenta de Moveout actualmente está pausada. No recibirás ninguna solicitud de Moveout.",
    },
    joinModal: {
      stepOne: {
        title: "Un nuevo Marketplace para hacer crecer tu negocio",
        textContent:
          "Moveout.com es un Marketplace dedicado a proveedores de limpieza por mudanza. Las solicitudes de limpieza por mudanza y de tareas son administradas a través de Taskbird.",
        textContentOne:
          "<0>Conveniencia:</0> Registrarse a Moveout.com es completamente gratuito para personas de limpieza del Marketplace de Turno.",
        textContentTwo:
          "<0>Acceso a más trabajos:</0> Como en Turno, acepta los proyectos de limpieza por mudanza que quieres y rechaza los que no quieres hacer.",
        textContentThree:
          "<0>Integración sencilla:</0> Simplemente fija tu tarifa y empieza a recibir solicitudes.",
        actionBack: "$t(common:cancel)",
        actionNext:
          "$t(common:next): $t(integrationsPage:moveoutIntegrationCard.joinModal.stepTwo.title)",
      },
      stepTwo: {
        title: "¿Cómo funciona?",
        textContentOne:
          "Usaremos tu <1>foto de perfil y tu calificación de Turno</1> para incluirte a la lista de proveedores de limpieza por mudanza en Moveout.com.",
        textContentTwo:
          "Sólo necesitas <1>fijar tu tarifa por hora por persona.</1>",
        textContentThree:
          "Aparecerás automáticamente en los resultados de búsqueda de Moveout.com en tu área, y los clientes te enviarán ofertas de proyectos de limpieza. <1>Acepta solo los proyectos en los que quieres trabajar.</1>",
        actionBack: "$t(common:back)",
        actionNext:
          "$t(common:next): $t(integrationsPage:moveoutIntegrationCard.joinModal.stepThree.title)",
      },
      stepThree: {
        title: "Configura tu tarifa",
        ratePerPerson: "Mi tarifa por hora </1> por persona",
        rateTip:
          "Puedes ajustar este precio más tarde tantas veces como necesites",
        rateBelow:
          "Esta tarifa es inferior al promedio. ¡Puedes aumentar un poco más!",
        rateFair:
          "Esta es una tarifa justa. ¡Tienes grandes posibilidades de conseguir clientes!",
        rateAbove:
          "Esta tarifa es superior al promedio. Puedes tener dificultades para conseguir clientes",
        howMuchEarn: "Este es un ejemplo de cómo funcionan tus precios:",
        peopleWorking: "Personas trabajando:",
        hoursSpent: "Horas utilizadas:",
        totalEarnings: "Ganancia total",
        rightContent: {
          title: "¿Por qué una tarifa por hora?",
          textContentOne:
            "La duración de un proyecto de limpieza de por mudanza puede variar dependiendo de las condiciones de la propiedad.",
          textContentTwo:
            "Cobrar una tarifa por hora te asegura recibir un pago justo en caso de que la limpieza por mudanza requiera más tiempo del esperado.",
        },
        formError: {
          rate_min: "El precio es muy bajo",
          rate_required: "Por favor ingresa un precio",
        },
        actionBack: "$t(common:back)",
        actionNext: "$t(integrationsPage:moveoutIntegrationCard.join)",
      },
      stepFinal: {
        title: "¡Choca esos cinco! Ahora eres parte de Moveout.com",
        textContentOne:
          "Recibirás ofertas de limpieza por mudanza en tu calendario.",
        textContentTwo:
          "Acepta las solicitudes que se ajusten a tu horario y recibe la información de contacto de tu nuevo cliente.",
        textContentThree:
          "Una vez que la tarea esté completa, recibirás el pago en tu cuenta de Stripe dentro de 5 días hábiles.",
        actionNext: "¡Entendido!",
      },
    },
  },
  requestPlatformPanel: {
    title: "¿Quieres integrar con otras plataformas?",
    textContent:
      "Siempre estamos trabajando para mejorar nuestro producto. Cuéntanos con qué plataforma te gustaría integrar:",
    platformPlaceholder: "Escribe el nombre de una plataforma",
    submitButtonContent: "Enviar solicitud",
    success: "¡Muchas gracias!",
    error: "Ocurrió un error. Por favor intenta de nuevo más tarde.",
  },
};
