import React from 'react';

import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import { TkEditTaskNotes } from '@components/Drawers/TkEditTaskDrawer/components/index';
import { TkIcon, TkTypography } from '@components/index';

interface ITkDrawerGuidanceNotes {
  notes: string | undefined;
}

const TkDrawerGuidanceNotes = ({ notes }: ITkDrawerGuidanceNotes) => {
  const { t } = useTranslation();

  return (
    <TkTypography
      fontSize={16}
      fontFamily="Lato"
      fontWeight="normal"
      display="inline"
    >
      <Grid container justifyContent="space-between">
        <Grid container direction="row" style={{ maxWidth: '50%' }}>
          <TkIcon
            className="fa fa-file"
            fontSize="inherit"
            marginRight={5}
            marginTop={4}
          />
          <TkTypography fontSize={16} fontFamily="Lato">
            {t('guidance_notes', { ns: 'common' })}:
          </TkTypography>
        </Grid>
        <Grid container style={{ maxWidth: '50%' }} justifyContent="flex-end">
          <TkEditTaskNotes notes={notes} readyOnly />
        </Grid>
      </Grid>
    </TkTypography>
  );
};

export default TkDrawerGuidanceNotes;
