import { atom } from 'recoil';
//import { getBookingBusinessId } from "@services/apiBooking";

const serviceData = atom<ITkItemServiceProperties>({
  key: 'serviceData',
  default: {
    id: 0,
    business_id: 0,
    questions: [],
    schedule: {
      sunday: [],
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
    },
    business: null,
    attributes: [],
    name: '',
    active: false,
    description: '',
    duration_in_minutes: 999,
    price: 0,
    show_duration: false,
    show_price: false,
    apply_general_business_hours: true,
    apply_general_preferences: true,
    service_provide_type: 'at_customer_location',
    order: {},
    status: 'draft',
    isDraft: false,
  },
});
const hasServiceInfoChanged = atom<boolean>({
  key: 'hasServiceInfoChanged',
  default: false,
});

export { serviceData, hasServiceInfoChanged };
