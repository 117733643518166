export default {
  assignedTo: 'Asignado a',
  assignTask: 'Asignar tarea',
  checklists: 'Listas de tareas',
  customerAndLocation: 'Cliente y ubicación',
  dateMustBeSet:
    'Por favor, añade un horario de {{period}} para guardar los cambios',
  endDate: 'Fecha de finalización',
  endDateCantBeInThePast: 'La fecha de finalización no puede ser pasada',
  endDateCantBeTheSameDay: 'La fecha de finalización no puede ser el mismo día',
  endRepeat: 'Finalizar repetición',
  endTime: 'Hora de finalización',
  endTimeCantBeBeforeStart:
    'La hora de finalización no puede ser anterior a la hora de inicio',
  endTimeCantBeInThePast: 'La hora de finalización no puede ser pasada',
  errorCreatingTask:
    'Se ha producido un error al crear la tarea. Por favor, intenta de nuevo',
  inProgress: 'En progreso',
  leaveANoteToYourTeammates: 'Deja una nota a tus compañeros',
  mandatory: 'obligatorio',
  mandatoryCompletion: 'Realización obligatoria',
  mandatoryCompletionTooltip:
    'Pide a tus compañeros que comprueben cada punto de tu lista de tareas antes de completar la tarea.',
  nameYourTask: 'Nombra tu tarea',
  pleaseAddAName: 'Por favor, añade un nombre',
  pleaseSelectADate: 'Por favor, selecciona una fecha',
  pleaseSelectATime: 'Por favor, selecciona un horario',
  repeat: 'Repetir',
  saveChanges: 'Guardar cambios',
  singleTask: 'Tarea única',
  startDate: 'Fecha de inicio',
  startDateCantBeInThePast: 'La fecha de inicio no puede ser pasada',
  startDateCantBeTheSameDay: 'La fecha de inicio no puede ser el mismo día',
  startTime: 'Hora de inicio',
  startTimeBeforeEndTimeWarning:
    'La hora de inicio de tu tarea debe ser anterior a su hora de finalización',
  startTimeCantBeEqualEnd:
    'La hora de inicio no puede ser igual a la hora de finalización',
  startTimeCantBeInThePast: 'La hora de inicio no puede ser pasada',
  taskName: 'Nombre de la tarea',
  teammateImages: 'Imágenes del compañero',
  teammatesNotes: 'Notas de los compañeros',
  unassigned: 'No asignado',
  editRepeatDrawer: {
    datesAndRepetitionCantBeEdited:
      'Las fechas y la configuración de repetición no pueden ser editadas',
  },
};
