import { selectorFamily, selector } from 'recoil';

import { locationsData } from '@recoilData/index';

const searchLocationsData = selectorFamily({
  key: 'searchLocationsData',
  get:
    (search: string) =>
    ({ get }) => {
      const locations = get(locationsData);
      if (!search) return locations;
      return locations.filter((location) => {
        return (
          location.alias
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()) ||
          location.street_address
            ?.toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()) ||
          location.streetAddress
            ?.toLocaleLowerCase()
            .includes(search.toLocaleLowerCase()) ||
          location.number
            ?.toLocaleLowerCase()
            .includes(search.toLocaleLowerCase())
        );
      });
    },
});

const getLocationsDataCount = selector({
  key: 'getLocationsDataCount',
  get: ({ get }) => {
    const locations = get(locationsData);
    return locations.length;
  },
});

export { searchLocationsData, getLocationsDataCount };
