import React from 'react';

import AppleSignIn from 'react-apple-signin-auth';
import { useHistory } from 'react-router-dom';

import { AppleButton } from '@components/TkAuthButtons';
import TkSocialLoginModal from '@components/TkLogin/TkSocialLoginModal';
import { AppLocationURLs, LoginEnum } from '@consts/index';
import {
  AppleUserDataSocialLogin,
  ExternalLoginEnum,
} from '@contexts/AuthContext/AuthContext';
import { useSnackbar, useAuth, useModal } from '@contexts/index';

const AppleLoginButton = (): JSX.Element => {
  const history = useHistory();
  const { setupModal } = useModal();
  const { fireTError } = useSnackbar();
  const { socialMediaSignUp } = useAuth();

  const handleClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    callback: () => void
  ) => {
    e.preventDefault();

    if (history.location.pathname.match(AppLocationURLs.SIGN_UP))
      history.push(AppLocationURLs.LOGIN);

    callback();
  };

  const fireAlert = () =>
    fireTError('login.socialLoginError', { social: 'Apple' });

  const handleAppleLogin = async (response: any) => {
    if (response.authorization) {
      const userSocialData: AppleUserDataSocialLogin = {
        id_token: response.authorization.id_token,
        social_media_id: response.authorization.code,
        social_media_provider: ExternalLoginEnum.APPLE,
        existing_user_only: true,
      };

      const { success, message } = await socialMediaSignUp(userSocialData);

      if (!success && message === LoginEnum.ACCOUNT_NOT_FOUND) {
        setupModal({
          content: (
            <TkSocialLoginModal socialPlatform={ExternalLoginEnum.APPLE} />
          ),
        });
      }
    } else fireAlert();
  };

  return (
    <AppleSignIn
      authOptions={{
        clientId: process.env.REACT_APP_APPLE_APP_ID,
        response_mode: 'form_post',
        scope: 'email name',
        redirectURI: process.env.REACT_APP_WEB_BASE_URI,
        usePopup: true,
      }}
      onSuccess={(response) => handleAppleLogin(response)}
      onError={(error) => console.error(error)}
      render={(renderProps) => (
        <div onClick={(e) => handleClick(e, renderProps.onClick)}>
          <AppleButton />
        </div>
      )}
    />
  );
};

export default AppleLoginButton;
