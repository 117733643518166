import axios, { AxiosRequestConfig } from 'axios';
import axiosRetry from 'axios-retry';

import { ApiResultsKind, HttpStatusCode, LocalStorage } from '@consts/index';

const createApiBooking = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_BOOKING_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json, multipart/form-data',
      'Access-Control-Allow-Origin': '*',
    },
    timeout: 10000,
  });
};

const apiBooking = createApiBooking();

export const authenticateAndSetToken = async (): Promise<any> => {
  const data = await authenticateBooking();
  localStorage.setItem(LocalStorage.BOOKING_TOKEN, data.access_token);
  return data;
};

export const setRetryRequest = async (): Promise<void> => {
  const data = await authenticateAndSetToken();
  const business = localStorage.getItem(LocalStorage.BOOKING_BUSINESS_HASH);

  if (data.business && !business) {
    localStorage.setItem(
      LocalStorage.BOOKING_BUSINESS_HASH,
      data.business.hash
    );
  }
};

axiosRetry(apiBooking, {
  retries: 3,
  retryDelay: (retryCount) => {
    setRetryRequest();
    return retryCount * 1000;
  },
  retryCondition: (error) => {
    return error.response.status === HttpStatusCode.UNAUTHORIZED;
  },
});

export const authenticateBooking = async (): Promise<any> => {
  try {
    const { data } = await apiBooking.get('auth');
    if (data.data.business)
      localStorage.setItem(
        LocalStorage.BOOKING_TIMEZONE,
        data.data.business.timezone
      );
    return data.data;
  } catch (e) {
    console.error(e);
  }
};

const getBookingToken = (): string => {
  return localStorage.getItem(LocalStorage.BOOKING_TOKEN);
};

const getTaskbirdToken = (): string => {
  return localStorage.getItem(LocalStorage.ACCESS_TOKEN);
};

const getBookingBusinessId = (): any => {
  const storedBusinessId =
    localStorage.getItem(LocalStorage.BOOKING_BUSINESS_HASH) || '';
  const hasDoubleQuotes = storedBusinessId?.includes('"');
  let businessId = storedBusinessId;
  if (!hasDoubleQuotes) {
    businessId = `\"${businessId}\"`;
    localStorage.setItem(LocalStorage.BOOKING_BUSINESS_HASH, businessId);
  }
  return JSON.parse(businessId);
};

const getBookingBusinessSlug = (): any => {
  const storedBusinessSlug =
    localStorage.getItem(LocalStorage.BOOKING_SLUG) || '';
  const hasDoubleQuotes = storedBusinessSlug?.includes('"');
  let businessSlug = storedBusinessSlug;
  if (!hasDoubleQuotes) {
    businessSlug = `\"${businessSlug}\"`;
    localStorage.setItem(LocalStorage.BOOKING_SLUG, businessSlug);
  }
  return JSON.parse(businessSlug);
};

const getBookingCategories = async (): Promise<any> => {
  try {
    const { data } = await apiBooking.get(`category`);
    return data.data;
  } catch (error) {
    return [];
  }
};

const getBookingAllServicesByCategory = async (
  idCategory: number
): Promise<any> => {
  try {
    const { data } = await apiBooking.get(
      `service?category=${idCategory}&public=1`
    );
    return data.data;
  } catch (error) {
    return [];
  }
};

const getBookingAllServices = async (): Promise<ITkItemServiceProperties[]> => {
  try {
    const { data }: { data: { data: ITkItemServiceProperties[] } } =
      await apiBooking.get(
        `service?include=questions,attributes,business,schedules&public=0`
      );
    return data.data;
  } catch (error: any) {
    return [];
  }
};

const getBookingBusiness = async (): Promise<any> => {
  try {
    const { data } = await apiBooking.get(
      `business/${getBookingBusinessId()}?include=faqs,colors,image`
    );
    return data.data;
  } catch (error) {
    return [];
  }
};

const getLocationTimezoneBooking = async (lat: number, lng: number) => {
  try {
    const { data } = await axios.get(
      `https://maps.googleapis.com/maps/api/timezone/json?location=${lat}%2C${lng}&timestamp=1331161200&key=${process.env.REACT_APP_GOOGLE_API_KEY}`
    );
    return data?.timeZoneId;
  } catch (error) {
    return [];
  }
};

const getBookingBusinessSlugUpdated = async () => {
  try {
    const { data } = await apiBooking.get(
      `business/${getBookingBusinessId()}/slug`
    );
    return data.data;
  } catch (error) {
    return [];
  }
};

const getBookingBusinessAllowToCopyLinkInstallation =
  async (): Promise<boolean> => {
    try {
      await apiBooking.get(`service/availability/${getBookingBusinessId()}`);
      return true;
    } catch (error) {
      return false;
    }
  };

const getBookingBusinessFistStates = async (): Promise<any> => {
  try {
    const { data } = await apiBooking.get(
      `business/${getBookingBusinessId()}/user/settings`
    );
    return data.data;
  } catch (error) {
    return [];
  }
};

const getBookingScheduling = async (): Promise<any> => {
  try {
    const { data } = await apiBooking.get(
      `business/${getBookingBusinessId()}/schedule`
    );
    return data.data;
  } catch (error) {
    return [];
  }
};

const uploadBookingImage = async (image): Promise<any> => {
  try {
    const formData = new FormData();
    formData.append('image', image);
    const { data } = await apiBooking.post(
      `business/${getBookingBusinessId()}/image`,
      formData
    );
    return data.data;
  } catch (error) {
    return [];
  }
};

const deleteImage = async (id: string): Promise<DeleteImageResult> => {
  try {
    await apiBooking.delete(`business/${getBookingBusinessId()}/image/${id}`);
    return {
      kind: ApiResultsKind.OK,
    };
  } catch (error) {
    return { kind: ApiResultsKind.ERROR };
  }
};

const fetchImage = async (): Promise<any> => {
  try {
    const { data } = await apiBooking.get(
      `business/${getBookingBusinessId()}/image`
    );
    return data;
  } catch (error) {
    return [];
  }
};

const getBookingPreferences = async (): Promise<any> => {
  try {
    const { data } = await apiBooking.get(
      `business/${getBookingBusinessId()}/preference`
    );
    return data.data;
  } catch (error) {
    return [];
  }
};

const getBookingSettings = async (): Promise<any> => {
  try {
    const { data } = await apiBooking.get(
      `business/${getBookingBusinessId()}/settings`
    );
    return data.data;
  } catch (error) {
    return [];
  }
};

const apiBookingPristine = createApiBooking();

const getBusinessBookingWidget = async (id: string): Promise<any> => {
  try {
    const { data } = await apiBookingPristine.get(
      `business/${id}/booking?include=services.attributes,services.questions`
    );
    return data.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

const createBooking = async (id: string, booking): Promise<any> => {
  try {
    const { data } = await apiBookingPristine.post(
      `business/${id}/booking`,
      booking
    );
    return data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const createBookingBusiness = async (business): Promise<any> => {
  try {
    const { data } = await apiBooking.post(
      `business?include=schedules`,
      business
    );
    return data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateBookingPreferences = async (business): Promise<any> => {
  try {
    const { data } = await apiBooking.post(
      `business/${getBookingBusinessId()}/preference`,
      business
    );
    return data.data;
  } catch (error) {
    return [];
  }
};

const updateBookingSettings = async (business): Promise<any> => {
  try {
    const { data } = await apiBooking.post(
      `business/${getBookingBusinessId()}/settings`,
      business
    );
    return data.data;
  } catch (error) {
    return [];
  }
};

const updateBookingStatesSettings = async (settings) => {
  try {
    const { data } = await apiBooking.post(
      `business/${getBookingBusinessId()}/user/settings`,
      settings
    );
    return data;
  } catch (error) {
    return [];
  }
};

const updateBookingBusiness = async (business) => {
  try {
    const { data } = await apiBooking.put(
      `business/${getBookingBusinessId()}/`,
      business
    );
    return data.data;
  } catch (error) {
    return [];
  }
};

const updateBookingBusinessSlugLink = async (newSlug) => {
  try {
    const { data } = await apiBooking.patch(
      `business/${getBookingBusinessId()}/slug`,
      {
        slug: newSlug,
      }
    );
    return data;
  } catch (error) {
    return [];
  }
};

const createBookingSchedule = async (business) => {
  const { data } = await apiBooking.post(
    `business/${getBookingBusinessId()}/schedule`,
    business
  );
  return data.data;
};

const getBookingCustomization = async () => {
  try {
    const { data } = await apiBooking.get(
      `business/${getBookingBusinessId()}/customization`
    );
    return data.data;
  } catch (error) {
    return [];
  }
};

const updateBookingCustomization = async (customization) => {
  try {
    const { data } = await apiBooking.post(
      `business/${getBookingBusinessId()}/customization`,
      customization
    );
    return data.data;
  } catch (error) {
    return [];
  }
};

const createBookingPreferences = async (preferences) => {
  try {
    const { data } = await apiBooking.post(
      `business/${getBookingBusinessId()}/preference`,
      preferences
    );
    return data.data;
  } catch (error) {
    return [];
  }
};

const createBookingScheduling = async (scheduling) => {
  try {
    const { data } = await apiBooking.post(
      `business/${getBookingBusinessId()}/schedule`,
      scheduling
    );
    return data.data;
  } catch (error) {
    return [];
  }
};

const createService = async (service): Promise<ITkItemServiceProperties> => {
  return await apiBooking
    .post(`service?include=questions,attributes,business,schedules`, service)
    .then(({ data }) => {
      console.log(data);
      return data.data;
    });
};

const updateService = async (service): Promise<ITkItemServiceProperties> => {
  return await apiBooking
    .put(
      `service/${service.id}?include=questions,attributes,business,schedules`,
      service
    )
    .then(({ data }) => {
      console.log(data);
      return data.data;
    });
};

const updateServicePreference = async (
  service
): Promise<ITkItemServiceProperties> => {
  return await apiBooking
    .post(
      `business/${getBookingBusinessId()}/service/${service.id}/preference`,
      service.preference
    )
    .then(({ data }) => {
      console.log(data);
      return data.data;
    });
};

const updateServiceStatus = async (
  service
): Promise<ITkItemServiceProperties> => {
  return await apiBooking
    .put(
      `service/${service.id}/status?include=questions,attributes,business,schedules`,
      service
    )
    .then(({ data }) => {
      return data.data;
    });
};

const deleteService = async (service): Promise<ITkItemServiceProperties> => {
  return await apiBooking
    .delete(`service/${service.id}`, service)
    .then(({ data }) => {
      return data.data;
    });
};
const updateServices = async (
  arrayServices: { id: number; active: boolean }[]
): Promise<ITkServices> => {
  return await apiBooking
    .post(
      `service/many/${getBookingBusinessId()}?include=questions,attributes,business,schedules`,
      {
        services: arrayServices,
      }
    )
    .then((res) => {
      return { kind: ApiResultsKind.OK, res };
    });
};

const getStates = async () => {
  try {
    const { data } = await apiBooking.get('state');
    return data.data;
  } catch (error) {
    return [];
  }
};

const requestInterceptor = async (config: AxiosRequestConfig) => {
  const authAccessToken = getBookingToken();
  const authTaskbirdToken = getTaskbirdToken();
  const authHeader = {
    Authorization: `Bearer ${authAccessToken}`,
    ['taskbird-token']: `${authTaskbirdToken}`,
  };
  const newConfig = {
    ...config,
    headers: {
      ...config.headers,
      ...authHeader,
    },
  };

  return newConfig;
};

apiBooking.interceptors.request.use(requestInterceptor);

apiBooking.interceptors.response.use(
  (config) => {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default apiBooking;

export {
  apiBooking,
  createBookingSchedule,
  getBookingBusinessAllowToCopyLinkInstallation,
  updateBookingStatesSettings,
  getBookingBusinessFistStates,
  updateBookingBusinessSlugLink,
  getBookingCategories,
  getBookingToken,
  getBookingBusiness,
  getBookingBusinessId,
  getBookingBusinessSlug,
  getBookingBusinessSlugUpdated,
  createBookingBusiness,
  getBookingCustomization,
  updateBookingCustomization,
  getBookingPreferences,
  createBookingPreferences,
  getBookingScheduling,
  getBookingAllServices,
  createBookingScheduling,
  updateBookingBusiness,
  getBookingAllServicesByCategory,
  createService,
  updateService,
  updateBookingSettings,
  updateBookingPreferences,
  getBookingSettings,
  updateServices,
  getBusinessBookingWidget,
  createBooking,
  updateServiceStatus,
  getLocationTimezoneBooking,
  deleteService,
  updateServicePreference,
  uploadBookingImage,
  fetchImage,
  deleteImage,
  getStates,
};
