export default {
  crews: {
    crew: "Équipe",
    crew_plural: "Équipes",
    delete_crew: "Supprimer cette Équipe",
    crew_name: "Nom d'équipe",
    crew_create_enter_name_error: "Veuillez saisir un nom",
    crew_create_successfully: "Équipe créée avec succès",
    crew_deleted_successfully: "Équipe supprimée avec succès",
    crew_create_enter_members_minimum:
      "Une Équipe doit contenir au minimum un(e) membre",
    crew_details: "Détails de l'Équipe",
    click_to_see_details_and_edit:
      "Cliquez sur une Équipe pour voir ses détails et les modifier",
    create_crew_drawer_warning_message:
      "Les noms d'équipes sont visibles par tous ses membres",
    no_crews_warning: "Vous n'avez encore<0/> aucune équipe",
    create_crew_message:
      "Créer une Équipe est le moyen le plus<0/> facile d'assigner une tâche à plusieurs coéquipiers à la fois.",
    no_members_to_create_crew:
      "Pour créer une équipe, veuillez ajouter des membres à cette équipe",
    create_first_crew: "Créez votre première équipe",
    remove_crews_warning:
      "Êtes-vous sûr(e) de vouloir supprimer cette équipe ?",
    remove_crews_warning_message:
      "Ceci n'aura aucun impact sur les membres de cette équipe ni sur les tâches qui leur sont assignées",
    create_crew: "Créer une Équipe",
    create_crew_drawer_message:
      "Les Équipes sont des groupes de membres qui travaillent ensemble, utilisez cette fonctionnalité pour organiser votre charge de travail.",
    selected_crew_warning_add_members:
      "Ajouter ou retirer des membres d'une équipe n'aura aucun impact sur les tâches qui leur sont déjà assignées",
    crews_must_contain_one_member:
      "Les équipes doivent contenir au moins un(e) membre",
  },
  teammates: {
    member: "Membre",
    member_plural: "Membres",
    member_removed_successfully: "Agent(e) retiré(e) avec succès",
    click_to_see_details_and_edit: "Cliquez sur un(e) Agent(e) pour voir et modifier son profil",
    remove_teammate: "Retirer cet(te) Agent(e)",
    get_started: "Commencez à ajouter des membres à votre<0/>équipe en envoyant des Invitations à vos collaborateurs/trices.",
    team_members: "Membres de l'Équipe",
    pending_invites: "Invitations en attente",
    pending_invites_count: "Voir {{ pendingInvitationsCount }} invitations en attente",
    resend_invite: "Renvoyer l'invitation",
    cancel_invite: "Annuler l'invitation",
    invitation_canceled: "Invitation annulée avec succès",
    invitation_resent: "Invitation renvoyée avec succès",
    no_tasks: "Aucune tâche",
    no_teammates_warning: "Vous n'avez encore<0/> aucun(e) co-équipier/ère",
    remove_teammate_warning:
      "Êtes-vous sûr(e) de vouloir retirer ce(tte) co-équipier/ère ?",
  },
  sms_error_message_empty: "Veuillez saisir un numéro de téléphone",
  last_name_error_message_empty: "Veuillez indiquer un nom de famille",
  street_address_error_message_empty: "Veuillez indiquer une adresse",
  email_error_message_empty: "Veuillez indiquer une adresse e-mail",
  sms_error_message: "Veuillez indiquer un numéro de téléphone valide",
  email_error_message: "Veuillez indiquer une adresse e-mail au format valide",
  invitation_success: "Votre invitation est en route !",
  invitation_success_plural: "Vos invitations sont en route !",
  add_to_crew: "Ajouter à l'Équipe",
  lets_send_invites_message: "Envoyons des invitations !",
  send_invites_message: "Envoyer invitations",
  invite_message: "Inviter des co-équipiers/ères",
  add_members: "Ajouter des membres",
  add_another_contact: "Ajouter un autre contact",
  see_tasks: "Afficher les tâches",
};
