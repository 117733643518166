import React from 'react';

import { useTranslation } from 'react-i18next';
import { RecoilState } from 'recoil';
import styled from 'styled-components';

import { TkSelectAllItem } from './styles';
import {
  TkCheckBox,
  TkTypography,
  TkIcon,
  TkDropDown,
} from '@components/index';

interface TkDropDownPillsProps {
  name: string;
  isAllFilterDataSelected: RecoilState<boolean>;
  selectAllFilterData: RecoilState<boolean>;
  handleSelectAll: (checked: boolean, selectAllItems: any) => void;
  ListItem: () => JSX.Element;
}

const TagWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 5px;
  background-color: #2699fb;
  border-radius: 15px;
  height: 26px;
  font-family: 'Lato';
  font-size: 14px;
  color: #f0f0f0;
  margin: 3px 7px 3px 0px;
  cursor: pointer;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  padding: 2px;
  cursor: pointer;
`;

const TextWrapper = styled.div`
  margin: 0 5px 2px 5px;
  user-select: none;
`;

const FilterActivator = ({ name, toggle }) => {
  return (
    <TagWrapper>
      <TextWrapper>{name}</TextWrapper>
      <IconWrapper>
        <TkIcon
          className={`fa ${toggle ? 'fa-angle-up' : 'fa-angle-down'}`}
          color="#ffffff"
          iconSize={14}
        />
      </IconWrapper>
    </TagWrapper>
  );
};

const TkDropDownListPills = ({
  name,
  isAllFilterDataSelected,
  selectAllFilterData,
  handleSelectAll,
  ListItem,
}: TkDropDownPillsProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <TkDropDown
      Activator={({ toggle }) => (
        <FilterActivator name={name} toggle={toggle} />
      )}
      HeaderPopover={({ selectAll, selectAllItems }) => (
        <TkSelectAllItem
          checked={selectAll}
          control={
            <TkCheckBox
              checked={selectAll}
              onChange={(_, checked) =>
                handleSelectAll(checked, selectAllItems)
              }
              name="check-alert-assignee"
            />
          }
          label={
            <TkTypography fontFamily="Lato" fontSize="14px">
              {selectAll
                ? t('unselect_all', { ns: 'common' })
                : t('select_all', { ns: 'common' })}
            </TkTypography>
          }
        />
      )}
      ListItem={ListItem}
      isAllDataSelected={isAllFilterDataSelected}
      selectAllData={selectAllFilterData}
    />
  );
};

export default TkDropDownListPills;
