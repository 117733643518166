export default {
  tkModalAlertIntegrated:
    "This task is from an external calendar and the date can’t be changed",
  tkModalAlertRepeat:
    "This task is instance of a repeating series and can’t be moved to another date",
  tkModalAlertTurnoverbnb:
    "This task is from Turno and the customer can’t be changed",
  tkModalAlertInProgress:
    "You can't edit dates for tasks in progress or completed",
  tkModalAlertWontReflectOnTurnoverBNB:
    "Any changes made to the task will not be reflected in Turno",
  alerts: {
    sendNotifications: "Send notifications?",
    doYouWantToNotify: "Do you want to notify?",
    doNotShowAgainAndSendAllNotifications: "Don't show again and send all notifications",
  }
};
