import { useRecoilValue } from 'recoil';
import { getUserAccountId, selectedCrewIdState } from '@recoilData/index';
import { useQuery } from 'react-query';
import api from '@services/api';
import { Crew } from '@components/TkTeams/types';

export const useGetCrew = () => {
  const accountId = useRecoilValue(getUserAccountId);
  const crewId = useRecoilValue(selectedCrewIdState);

  return useQuery(['getCrew', accountId, crewId], async () => {
    const { data } = await api.get(`account/${accountId}/crew/${crewId}`);
    return data as Crew;
  });
};
