import { Popover } from '@mui/material';
import styled from 'styled-components';

export const PopoverContainer = styled(Popover)`
  margin-top: 14px;
`;

export const PopoverWrapper = styled.div`
  min-width: 247px;
  height: 100%;
  max-height: 366px;
  padding: 0 10px;
  overflow: hidden;
`;

export const PopoverListWrapper = styled.div`
  width: auto;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-x: hidden;
  scrollbar-width: none;
`;
