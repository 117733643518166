export default {
  title: "Integrations",
  tbnbIntegrationCard: {
    title: "Turno",
    textContent:
      "Automatically sync with projects in Turno and manage them in Taskbird.",
    integratedTextContext:
      "You can now assign projects to your Teammates and complete them in Taskbird.",
    integratedTextContextWithMoveout:
      "You can now assign projects to your Teammates and complete them in Taskbird. They will be marked as completed on Turno",
    connectedOn: "<0>Connected</0> on {{- date}}",
    sellingPointOne: "Your projects and customers will be kept synced.",
    sellingPointTwo:
      "When a project is updated on Turno, it will be updated on Taskbird.",
    sellingPointThree:
      "When a project is completed on Taskbird, we will mark it as complete on Turno.",
    connectModal: {
      title: "Connect with Turno",
      textContent: "Please enter your Turno account",
      email_required: "Please enter an email",
      email_invalid: "Please enter a valid email",
      password_required: "Please enter the password",
      success: "Your Turno account was succesfully connected",
      invalid_credentials: "Wrong email or password.",
      email_already_integrated:
        "This email is already integrated with Taskbird. If you need help please contact Customer Service.",
      invalid_account_type:
        "You can only integrate a Cleaner Account from Turno.",
      account_already_integrated_with_client:
        "This account is already integrated. Please contact customer service.",
      enterTheCodeToConnectAccount: "Enter the code to connect your accounts",
      enterTheCodeToConnectAccountMessage:
        "We sent you a 6 digit-code to <0>{{ email }}</0> and an <0>SMS</0> to the phone number related to the account. Please enter it below:",
      codeResendSuccessfully: "Code resent successfully",
      email_not_found:
        "This email is not used on a Turno Cleaner account.",
    },
  },
  moveoutIntegrationCard: {
    title: "Moveout.com",
    textContent: "Join the new Marketplace to grow your business.",
    join: "Join Moveout.com",
    joinedOn: "<0>Joined</0> on {{- date}}",
    editPriceTooltip: "Edit hourly rate per person",
    editPriceSuccess: "Rate updated successfully",
    integratedContent: {
      enabledRequests:
        "You are currently sending automatic move-out cleaning offers based on your settings bellow.",
      disabledRequests:
        "Your Moveout account is now paused. You will not receive any Moveout requests.",
    },
    joinModal: {
      stepOne: {
        title: "New Marketplace to Grow your Business",
        textContent:
          "Moveout.com is a marketplace for move-out cleaning service providers. Move-out cleaning requests and tasks are managed through Taskbird.",
        textContentOne:
          "<0>Convenience:</0> It’s completely free to sign up to Moveout.com for existing Turno Marketplace cleaners.",
        textContentTwo:
          "<0>Access to more work:</0> Similar to Turno, accept the move-out cleaning projects you want and reject the projects you don’t want.",
        textContentThree:
          "<0>Seamless integration:</0> Simply set your rate and start receiving requests.",
        actionBack: "$t(common:cancel)",
        actionNext:
          "$t(common:next): $t(integrationsPage:moveoutIntegrationCard.joinModal.stepTwo.title)",
      },
      stepTwo: {
        title: "How it works?",
        textContentOne:
          "We will use your <1>Profile Picture and Rating from Turno</1> to list you as a move-out cleaning provider on Moveout.com.",
        textContentTwo:
          "You only need to <1>set your hourly rate per person.</1>",
        textContentThree:
          "You will automatically appear on Moveout.com search results in your area and customers will send you cleaning projects. <1>Only accept the projects you want to do.</1>",
        actionBack: "$t(common:back)",
        actionNext:
          "$t(common:next): $t(integrationsPage:moveoutIntegrationCard.joinModal.stepThree.title)",
      },
      stepThree: {
        title: "Set your rate",
        ratePerPerson: "My rate per hour </1> per person",
        rateTip: "You can adjust this price later as many times as you need",
        rateBelow: "This rate is below average. You can get a bit more!",
        rateFair:
          "This is a fair rate. You have a good chance of getting customers!",
        rateAbove:
          "This rate is above average. It might be hard to get customers",
        howMuchEarn: "This is an example of how your pricing would work:",
        peopleWorking: "People working:",
        hoursSpent: "Hours spent:",
        totalEarnings: "Total earnings",
        rightContent: {
          title: "Why hourly?",
          textContentOne:
            "Move-out cleaning projects can vary in duration depending on the condition of the property.",
          textContentTwo:
            "Charging an hourly rate will ensure you receive a fair rate in case the move-out cleaning lasts longer than expected.",
        },
        formError: {
          rate_min: "The price is too low",
          rate_required: "Please enter a price",
        },
        actionBack: "$t(common:back)",
        actionNext: "$t(integrationsPage:moveoutIntegrationCard.join)",
      },
      stepFinal: {
        title: "High five! You are now part of Moveout.com",
        textContentOne:
          "You will receive offers for move-out cleaning requests in your schedule.",
        textContentTwo:
          "Accept the Tasks that fit your schedule and receive your new customer contact information.",
        textContentThree:
          "Once the task is completed, you will receive the payment in your Stripe account within 5 business days.",
        actionNext: "Got it!",
      },
    },
  },
  googleCalendar: {
    title: "Google calendar",
    textContent:
      "Import all your scheduled events from your calendars as Tasks and assign them to your teammates.",
    addCalendar: "Add calendar",
    addNewCalendar: "Add a new calendar",
    syncedOn: "Synced on {{- date}}",
    syncModal: {
      title: "Sync with Google Calendar",
      calendarRequiredError:
        "Please select one or more calendars before continuing",
      selectCalendars: "Select the calendars you want to sync with Taskbird",
      syncSuccess:
        "We successfully connected your Google Calendar with Taskbird. We will create tasks for all the events in your calendar",
      syncCalendar: "Sync Calendar",
    },
    deleteModal: {
      title: "Remove calendar",
      textContent1: "Are you sure you want to delete this Calendar?",
      textContent2: "All projects associated to this calendar will be deleted.",
      deleteSuccess: "The calendar and its associated tasks were deleted",
    },
    allSyncedWarningModal: {
      title:
        "All the available calendars of your Google account are already synced with Taskbird",
    },
  },
  requestPlatformPanel: {
    title: "Want to integrate with another platform?",
    textContent:
      "We are always working to improve our product. Let us know which platform you would like to integrate:",
    platformPlaceholder: "Write a platform name",
    submitButtonContent: "Send request",
    success: "Thanks for your request!",
    error: "Something went wrong with your suggestion, please try again later.",
  },
};
