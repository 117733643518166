import React from 'react';
import { Grid } from '@mui/material';
import {
  ModalContent,
  ModalActions,
  DeleteIconModal,
  TextModal,
} from './styles';
import { TkIcon, TkTypography, DynamicButton } from '@components/index';
import { useTranslation } from 'react-i18next';

interface IAlertContent {
  type:
    | 'repeat'
    | 'integrated'
    | 'turnoverbnb'
    | 'inProgress'
    | 'wontReflectOnTurnoverBNB';
  handleCloseModal: () => void;
}

const TkModalAlert = (props: IAlertContent): JSX.Element => {
  const { type, handleCloseModal } = props;
  const { t } = useTranslation();

  const textOptions = {
    integrated: t('tkModalAlertIntegrated', { ns: 'modalAlert' }),
    repeat: t('tkModalAlertRepeat', { ns: 'modalAlert' }),
    turnoverbnb: t('tkModalAlertTurnoverbnb', { ns: 'modalAlert' }),
    inProgress: t('tkModalAlertInProgress', { ns: 'modalAlert' }),
    wontReflectOnTurnoverBNB: t('tkModalAlertWontReflectOnTurnoverBNB', {
      ns: 'modalAlert',
    }),
  };

  return (
    <>
      <ModalContent>
        <Grid container justifyContent="center">
          <DeleteIconModal
            container
            justifyContent="center"
            alignItems="center"
          >
            <TkIcon
              className="fal fa-exclamation-triangle"
              iconSize={72}
              color="#F57846"
              width={81}
            />
          </DeleteIconModal>
          <TextModal
            container
            justifyContent="center"
            alignItems="center"
            color="default"
          >
            <TkTypography
              fontFamily="Lato"
              fontWeight="normal"
              fontSize={16}
              textAlign="center"
              color="#011F41"
            >
              {textOptions[type]}
            </TkTypography>
          </TextModal>
        </Grid>
      </ModalContent>
      <ModalActions>
        <DynamicButton
          variant="contained"
          bgcolor="primary"
          onClick={handleCloseModal}
          disableElevation
        >
          {t('ok', { ns: 'common' })}
        </DynamicButton>
      </ModalActions>
    </>
  );
};

export default TkModalAlert;
export * from './styles';
