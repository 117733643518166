import React, { useState, useContext } from 'react';

import { useHistory } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ComponentWithChildren } from 'types/common.type';
import { SocialLoginResult } from 'types/session.type';
import { IUserIntercom } from 'types/intercomUser.type';

import {
  AuthContext,
  AuthReturnType,
  IAuthContext,
  SocialMediaSignUp,
  PermissionEnum,
  FacebookUserDataSocialLogin,
  GoogleUserDataSocialLogin,
  AppleUserDataSocialLogin,
} from './AuthContext';
import { isLoggedInState, sessionState } from './state';
import {
  AppLocationURLs,
  LocalStorage,
  LoginEnum,
  LoginResponseEnum,
} from '@consts/index';
import { SessionData } from '@contexts/AuthContext/types/session';
import { useMixpanel, MIXPANEL_EVENTS } from '@contexts/index';
import { userTimezone } from '@helpers/index';
import { showDemoCall } from '@recoilData/index';
import { handleLogout } from '@services/api';
import {
  createUserSession,
  socialMediaSession,
  userSession,
} from '@services/sessionMethods';
import { fetchUserInfo } from '@services/userMethods';

const isAllowed = (permission: number): boolean => {
  return (
    permission &&
    (permission === PermissionEnum.OWNER ||
      permission === PermissionEnum.MANAGER)
  );
};

type AuthProviderProps = ComponentWithChildren;

const AuthProvider = ({ children }: AuthProviderProps): JSX.Element => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const isLoggedIn = useRecoilValue(isLoggedInState);
  const setShowDemoCall = useSetRecoilState(showDemoCall);
  const [session, setSession] = useRecoilState(sessionState);
  const [socialMediaData, setSocialMediaData] = useState<
    | FacebookUserDataSocialLogin
    | AppleUserDataSocialLogin
    | GoogleUserDataSocialLogin
    | undefined
  >(undefined);

  const { identify, reset, setOnceProps, track } = useMixpanel();
  const signed = isLoggedIn;
  const userInfo = session;
  const userIntercom: IUserIntercom = {
    user_id: userInfo?.user?.id.toString() || '',
    name: userInfo?.user?.first_name || '',
    email: userInfo?.user?.email || '',
    accountName: userInfo?.account?.name || '',
    accountId: userInfo?.account?.id || '',
    hasIntegration: userInfo?.account?.hasIntegration ? 'true' : 'false' || '',
  };

  const logout = (): void => {
    reset();
    handleLogout();
    window.location.assign(AppLocationURLs.HOME);
  };

  const setUserPhoneValidated = () => {
    setSession({
      ...session,
      user: {
        ...session.user,
        phone_validated_at: new Date().toISOString(),
        phone_validation_required: false,
      },
    });
  };

  const setAuthTokens = (accessToken: string) => {
    localStorage.setItem(LocalStorage.ACCESS_TOKEN, accessToken);

    localStorage.setItem(
      LocalStorage.BOOKING_BUSINESS_FIRST_ACCESS,
      JSON.stringify({
        first_access_service_options: true,
        first_access_services: true,
        first_access_service_questions: true,
        first_access_faqs: true,
      })
    );
  };

  const newTeamLogin = async (data: SessionData): Promise<void> => {
    setAuthTokens(data.access_token);

    try {
      const { user } = await fetchUserInfo();
      track(MIXPANEL_EVENTS.LOGIN);
      setShowDemoCall(true);
      setSession(user);
    } catch (error) {
      console.error('teamLoginError', error);
    }
  };

  const setUserCredentials = (session: SessionData): boolean => {
    if (!isAllowed(session.permission_id)) return false;

    setAuthTokens(session.access_token);
    localStorage.setItem(
      LocalStorage.ACCOUNT_ID,
      session.account.id.toString()
    );
    setSession(session);
    setError(false);
    return true;
  };

  const login = async (
    email: string,
    password: string
  ): Promise<AuthReturnType> => {
    try {
      const { sessionData } = await userSession({
        email,
        password,
        timezone: userTimezone(),
      });

      track(MIXPANEL_EVENTS.LOGIN);

      if (!setUserCredentials(sessionData)) {
        handleLogout();

        return {
          success: false,
          message: LoginEnum.NOT_ADMIN,
          data: sessionData,
        };
      }

      localStorage.setItem(LocalStorage.ACCESS_TOKEN, sessionData.access_token);

      identify(sessionData.user.id.toString());

      setOnceProps({
        email: sessionData.user.email,
        name: sessionData.user.full_name,
        created: sessionData.user.created_at,
        'First Name': sessionData.user.first_name,
        'Last Name': sessionData.user.last_name,
      });

      history.replace(AppLocationURLs.HOME);

      return { success: true, message: '', data: '' };
    } catch (err) {
      setError(true);
      return { success: false, message: LoginEnum.ERROR_REQUEST, data: '' };
    }
  };

  const createAccount = async (
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    recaptcha: string
  ): Promise<AuthReturnType> => {
    try {
      const { user } = await createUserSession({
        firstName,
        lastName,
        email,
        password,
        recaptcha,
      });

      setAuthTokens(user.access_token);

      identify(user.user.id.toString());

      setOnceProps({
        Email: email,
        email: email,
        name: user.user.full_name,
        created: user.user.created_at,
        'User ID': user.user.id,
        'First Name': firstName,
        'Last Name': lastName,
      });

      return { success: true, message: '', data: user };
    } catch (res: any) {
      const { errors } = res.user;

      if (
        errors.recaptcha &&
        errors.recaptcha[0] === LoginResponseEnum.RECAPTCHA_FAIL
      ) {
        return {
          success: false,
          message: LoginEnum.RECAPTCHA_FAIL,
          data: undefined,
        };
      }

      if (errors.email && errors.email[0] === LoginResponseEnum.EMAIL_TAKEN) {
        return {
          success: false,
          message: LoginEnum.EMAIL_TAKEN,
          data: undefined,
        };
      }

      return { success: false, message: '', data: undefined };
    }
  };

  const socialMediaSignUp = async (
    userSocialData:
      | FacebookUserDataSocialLogin
      | AppleUserDataSocialLogin
      | GoogleUserDataSocialLogin,
    newSocialAccount = false
  ): Promise<SocialMediaSignUp> => {
    const socialLoginHeaders = { DontLogout: 'true' };

    try {
      const { session } = await socialMediaSession({
        socialLoginDataRequest: userSocialData,
        socialLoginHeaders,
      });

      if (newSocialAccount) {
        setAuthTokens(session.access_token);

        return {
          success: true,
          message: '',
          data: session,
        };
      }

      return {
        success: setUserCredentials(session),
        message: '',
        data: session,
      };
    } catch (res) {
      setSocialMediaData(userSocialData);

      const { session } = res as SocialLoginResult;

      const errorMessages: Record<string, string> = {
        [LoginResponseEnum.ACCOUNT_NOT_FOUND]: LoginEnum.ACCOUNT_NOT_FOUND,
        [LoginResponseEnum.INVALID_CREDENTIALS]: LoginEnum.INVALID_CREDENTIALS,
      };

      const errorMessage =
        errorMessages[session.message] ?? LoginEnum.ADMIN_ONLY;

      return {
        success: false,
        message: errorMessage,
        data: null,
      };
    }
  };

  const continueWithSocialMedia = async (): Promise<SocialMediaSignUp> => {
    if (socialMediaData === undefined)
      return {
        success: false,
        message: LoginEnum.SOCIAL_MEDIA_ERROR,
        data: null,
      };

    const res = await socialMediaSignUp(
      { ...socialMediaData, existing_user_only: false },
      true
    );
    return res;
  };

  const hooks: IAuthContext = {
    continueWithSocialMedia,
    createAccount,
    userInfo,
    userIntercom,
    signed,
    error,
    login,
    logout,
    newTeamLogin,
    setUserPhoneValidated,
    socialMediaSignUp,
  };

  return <AuthContext.Provider value={hooks}>{children}</AuthContext.Provider>;
};

const useAuth = (): IAuthContext => useContext(AuthContext);

export { AuthProvider, useAuth };
