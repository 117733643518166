import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Grid } from '@mui/material';

import ChosenOptionWrapper from './ChosenOptionWrapper';
import { IOptionStruct } from './types.type';
import {
  IOptionsSelect,
  SetupStatesEnum,
} from '@components/TkModalComponents/types.type';
import { TkTypography, TkCodeInput, DynamicButton } from '@components/index';
import { ApiResultsKind } from '@consts/api';
import { SessionData } from '@contexts/AuthContext/types/session';
import { useMixpanel, MIXPANEL_EVENTS } from '@contexts/index';
import { apiMethods } from '@services/index';

interface JoinTeamContentProps {
  name: string;
  userData: SessionData;
  options: Record<string, IOptionStruct>;
  selectedOption: IOptionsSelect;
  setTeamName(name: string): void;
  setSelectedOption(option: IOptionsSelect): void;
}

const JoinTeamContent = ({
  name,
  options,
  selectedOption,
  setSelectedOption,
  setTeamName,
  userData,
}: JoinTeamContentProps): JSX.Element => {
  const { t } = useTranslation();
  const [codeError, setCodeError] = useState(false);
  const { acceptInvite } = apiMethods;

  const { track } = useMixpanel();

  useEffect(() => {
    track(MIXPANEL_EVENTS.WELCOME_TO_TASKBIRD_JOIN_A_TEAM);
  }, []);

  const handleCheckCode = (code: string) => {
    acceptInvite(code, userData.user.id)
      .then((res) => {
        if (res.kind === ApiResultsKind.OK) {
          setTeamName(res.data.name);
          setSelectedOption(SetupStatesEnum.ALL_DONE_JOIN_TEAM);
        }
      })
      .catch(() => {
        setCodeError(true);
      });
  };

  return (
    <ChosenOptionWrapper
      options={options}
      selectedOption={selectedOption}
      name={name}
    >
      <Grid container direction="column">
        <TkTypography
          fontFamily="Lato"
          fontWeight="bold"
          fontSize="20px"
          color="#3D4465"
          margin="10px 0"
          textAlign="center"
        >
          {t('welcomeSetupModal.enterYourInvitationCode')}
        </TkTypography>
        <TkTypography
          fontFamily="Lato"
          fontSize="16px"
          marginBottom="20px"
          textAlign="center"
        >
          {t('welcomeSetupModal.invitationCodeSent')}
        </TkTypography>
        <Grid container justifyContent="center" alignItems="center">
          <TkCodeInput
            isError={codeError}
            checkCode={handleCheckCode}
            position="center"
          />
        </Grid>
        <TkTypography
          fontFamily="Lato"
          fontSize="16px"
          margin="20px 0 10px"
          textAlign="center"
        >
          {t('welcomeSetupModal.doYouManageATeamQuestion')}
        </TkTypography>
        <DynamicButton
          color="primary"
          onClick={() => setSelectedOption(SetupStatesEnum.NEW_TEAM)}
        >
          {t('welcomeSetupModal.setupNewTeamText')}
        </DynamicButton>
      </Grid>
    </ChosenOptionWrapper>
  );
};

export default JoinTeamContent;
