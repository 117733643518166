import { useRecoilState } from 'recoil';

import { ITkExtendedOfferDetailsDrawerEnum } from '@components/TkOfferDetailsDrawer/TkExtendedOffersDetailsDrawer';
import { editEventExtendedDrawer } from '@recoilData/index';

export const useBookingProject = () => {
  const [extendedObject, setExtendedObject] = useRecoilState(
    editEventExtendedDrawer
  );

  const handleProjectDetails = () => {
    setExtendedObject({
      type: ITkExtendedOfferDetailsDrawerEnum.SERVICE_DETAILS,
      open:
        extendedObject.open &&
        extendedObject.type ===
          ITkExtendedOfferDetailsDrawerEnum.SERVICE_DETAILS
          ? false
          : true,
    });
  };

  const handleProjectAdditionalInfo = () => {
    setExtendedObject({
      type: ITkExtendedOfferDetailsDrawerEnum.ADDITIONAL_INFO_BOOKING,
      open:
        extendedObject.open &&
        extendedObject.type ===
          ITkExtendedOfferDetailsDrawerEnum.ADDITIONAL_INFO_BOOKING
          ? false
          : true,
    });
  };

  return {
    handleProjectDetails,
    handleProjectAdditionalInfo,
  };
};
