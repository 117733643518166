import { useSetRecoilState } from 'recoil';
import { teamsDrawerState, teamsPageExtendedDrawer } from '@recoilData/index';

export const useResetTeamsDrawer = () => {
  const teamsDrawer = useSetRecoilState(teamsDrawerState);
  const setExtendedDrawer = useSetRecoilState(teamsPageExtendedDrawer);

  const resetTeamsDrawer = () => {
    teamsDrawer('unselected');
    setExtendedDrawer(false);
  };

  return {
    resetTeamsDrawer,
  };
};
