import React from 'react';

import { useRecoilValue } from 'recoil';

import { Grid } from '@mui/material';

import {
  TkExtendedWrapper,
  WrapperTeammatesList,
  WrapperTeammatesItem,
  ImgAssignee,
  WrapperTeammatesHeader,
  WrapperTeammatesNotesComment,
  IconAssigneeButton,
} from '../styles';
import TkIcon from '@components/TkIcon';
import { TkTypography } from '@components/index';
import { generateInitials } from '@helpers/index';
import { useDrawer } from '@hooks/index';
import { getEventCrewAssignmentsNotes } from '@recoilData/index';

const TkTeammatesNotes = (): JSX.Element => {
  const { closeExtDrawer } = useDrawer();
  const teammatesNotes = useRecoilValue(getEventCrewAssignmentsNotes);

  return (
    <TkExtendedWrapper maxWidth="xl">
      <WrapperTeammatesHeader
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <TkTypography fontWeight="bold" fontFamily="Muli" fontSize={20}>
          Teammates Notes
        </TkTypography>
        <TkIcon
          onClick={closeExtDrawer}
          style={{ cursor: 'pointer' }}
          className="fa fa-chevron-right"
          color="default"
          marginLeft={10}
          verticalAlign="baseline"
          fontSize="small"
        />
      </WrapperTeammatesHeader>
      <WrapperTeammatesList>
        {teammatesNotes.map((teammateNote, index) => (
          <WrapperTeammatesItem key={index}>
            <Grid container alignItems="center" direction="row">
              {teammateNote.photoUrl !== null ? (
                <ImgAssignee
                  src={teammateNote.photoUrl}
                  style={{ marginTop: 0 }}
                />
              ) : (
                <IconAssigneeButton>
                  {generateInitials(teammateNote.fullName)}
                </IconAssigneeButton>
              )}
              <TkTypography fontSize={16} fontWeight="bold" fontFamily="Lato">
                {teammateNote.fullName}
              </TkTypography>
            </Grid>
            <Grid container>
              <WrapperTeammatesNotesComment>
                {teammateNote.notes}
              </WrapperTeammatesNotesComment>
            </Grid>
          </WrapperTeammatesItem>
        ))}
      </WrapperTeammatesList>
    </TkExtendedWrapper>
  );
};

export default TkTeammatesNotes;
