import React, { useState, useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';

import { Grid, FormControl } from '@mui/material';

import TkLocationListItem from './TkLocationListItem';
import { TkListWrapper, TkExtendedWrapper, TkSelectAllItem } from './styles';
import { TkExtendedDrawerSearch } from '@components/ExtendedDrawers';
import { TkCheckBox, TkIcon, TkTypography } from '@components/index';
import {
  searchFilterLocationsData,
  isAllFilterLocationsSelected,
  getFilteredLocations,
  selectAllFilterLocations,
} from '@recoilData/index';

const TkLocationList = (): JSX.Element => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const locationsList = useRecoilValue(searchFilterLocationsData(search));
  const filterLocations = useRecoilValue(getFilteredLocations);
  const handleSelectAll = useSetRecoilState(selectAllFilterLocations);
  const [selectAll, setSelectAll] = useRecoilState(
    isAllFilterLocationsSelected
  );

  useEffect(() => {
    const res = filterLocations.every((c) => c.checked === true);
    if (res) setSelectAll(true);
  }, [filterLocations]);

  useEffect(() => {
    const res = filterLocations.some((c) => c.checked === false);
    if (res) setSelectAll(false);
  }, [filterLocations]);

  return (
    <TkExtendedWrapper>
      <FormControl>
        <TkTypography fontWeight="bold" fontFamily="Muli" fontSize={16}>
          {t('selectALocation', { ns: 'events' })}
        </TkTypography>
        <Grid container alignItems="flex-end">
          <Grid item>
            <TkIcon className="fa fa-search" color="#4952A9" />
          </Grid>
          <Grid item sm={10}>
            <TkExtendedDrawerSearch search={search} setSearch={setSearch} />
          </Grid>
        </Grid>
      </FormControl>
      <Grid container>
        <TkSelectAllItem
          checked={selectAll}
          control={
            <TkCheckBox
              checked={selectAll}
              onChange={(_, checked) => handleSelectAll(checked)}
              name="check-alert-assignee"
            />
          }
          label={
            <TkTypography fontFamily="Lato" fontSize="14px">
              {selectAll
                ? t('unselect_all', { ns: 'common' })
                : t('select_all', { ns: 'common' })}
            </TkTypography>
          }
        />
      </Grid>
      <TkListWrapper>
        {locationsList.map((location: ILocationTask) => (
          <TkLocationListItem key={location.id} location={location} />
        ))}
      </TkListWrapper>
    </TkExtendedWrapper>
  );
};

export default TkLocationList;
