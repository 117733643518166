import { MobileStepper } from '@mui/material';
import { styled } from '@mui/material/styles';

import { getColorFromTheme } from '@helpers/index';

export const TkMobileStepperWrapper = styled(MobileStepper)`
  & .MuiLinearProgress-bar {
    background-color: ${({ color, theme }) =>
      color || getColorFromTheme(theme.palette, 'primary')};
  }
`;
