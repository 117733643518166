import { selector } from "recoil";
import {
  checklistsData,
  eventChecklists,
  generateChecklist,
  isAllEventChecklistsSelected,
} from "@recoilData/index";

const getSelectedEventChecklists = selector({
  key: "getSelectedEventChecklists",
  get: ({ get }) => {
    const checklists = get(checklistsData);
    const newChecklists: IChecklistTask[] = [];

    checklists.forEach((checklist) => {
      const { checked, isMandatory } = get(eventChecklists(checklist.id));
      if (checked)
        newChecklists.push(generateChecklist(checklist, checked, isMandatory));
    });

    return newChecklists;
  },
});

const getEventChecklistsCount = selector({
  key: "getEventChecklistsCount",
  get: ({ get }) => {
    const checklists = get(getSelectedEventChecklists);
    let count = 0;
    checklists.forEach(() => count++);
    return count;
  },
});

const selectAllEventChecklists = selector({
  key: "selectAllEventChecklists",
  get: ({ get }) => get(isAllEventChecklistsSelected),
  set: ({ set, get }, newValue) => {
    const checklists = get(checklistsData);

    set(isAllEventChecklistsSelected, newValue as boolean);

    checklists.map((checklist) => {
      set(eventChecklists(checklist.id), {
        ...get(eventChecklists(checklist.id)),
        checked: newValue as boolean,
      });
    });
  },
});

export {
  getSelectedEventChecklists,
  selectAllEventChecklists,
  getEventChecklistsCount,
};
