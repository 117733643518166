import React from "react";
import { AllSetPerson } from "@assets/index";
import { TkTypography } from "@components/index";
import { useTranslation } from "react-i18next";
import { Container, AllDoneNewTeamImageWrapper } from "./styles";

const TkWelcomeSetupModal: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container
      direction={"column"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <TkTypography
        fontSize={"24px"}
        fontFamily={"Muli"}
        fontWeight={"bold"}
        color={"#011F41"}
        margin={"20px 0"}
      >
        {t("welcomeSetupModal.congratsAllSet")}
      </TkTypography>

      <AllDoneNewTeamImageWrapper>
        <img src={AllSetPerson} alt={"allSet.svg"} />
      </AllDoneNewTeamImageWrapper>

      <TkTypography
        fontSize={"16px"}
        fontFamily={"Lato"}
        margin={"20px 0"}
        textAlign={"center"}
      >
        {t("welcomeSetupModal.congratsAllSetMessage")}
      </TkTypography>
    </Container>
  );
};

export default TkWelcomeSetupModal;
