import { AppLocationURLs } from '@consts/index';
import { addSingleAssigneeById, isNewTaskExternal } from '@recoilData/index';
import { useHistory } from 'react-router';
import { useSetRecoilState } from 'recoil';

export const useAssignMemberTask = () => {
  const setNewTaskExternal = useSetRecoilState(isNewTaskExternal);
  const addAssigneeById = useSetRecoilState(addSingleAssigneeById);
  const history = useHistory();

  const assignMemberTask = (memberId: number) => {
    setNewTaskExternal(true);
    addAssigneeById(memberId);
    history.push(AppLocationURLs.HOME);
  };

  return {
    assignMemberTask,
  };
};
