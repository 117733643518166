import React from 'react';

import { useTranslation } from 'react-i18next';

import { ImagePreviewCircle } from '../styles';
import { TkIcon, TkTypography } from '@components/index';
import { Grid } from '@material-ui/core';

interface ITkDrawerGuidanceImages {
  images: IImage[];
}

const TkDrawerGuidanceImages: React.FC<ITkDrawerGuidanceImages> = ({
  images,
}) => {
  const { t } = useTranslation();
  return (
    <TkTypography
      fontSize={16}
      fontFamily="Lato"
      fontWeight="normal"
      display="inline"
    >
      <Grid container justify="space-between">
        <Grid container direction="row" style={{ maxWidth: '50%' }}>
          <TkIcon
            className="fa fa-images"
            fontSize="inherit"
            marginRight={5}
            iconSize={14}
            width={18}
            marginTop={4}
          />
          <TkTypography fontSize={16} fontFamily="Lato">
            {t('guidance_images', { ns: 'common' })}:
          </TkTypography>
        </Grid>
        <Grid container style={{ maxWidth: '50%' }} justify="flex-end">
          {images.length > 0 ? (
            <>
              {images.map(
                (i, index) =>
                  index <= 1 && <ImagePreviewCircle src={i.fullImageUrl} />
              )}
              <TkTypography
                fontSize={16}
                display="inline"
                fontFamily="Lato"
                fontWeight="bold"
                textAlign="right"
                marginLeft={1}
              >
                {images.length}
              </TkTypography>
            </>
          ) : (
            <TkTypography fontSize={16} fontFamily="Lato" fontWeight="bold">
              {t('no_images', { ns: 'common' })}
            </TkTypography>
          )}
        </Grid>
      </Grid>
    </TkTypography>
  );
};

export default TkDrawerGuidanceImages;
