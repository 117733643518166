import { Member } from '@components/TkTeams/types';
import { checkedMembersState } from '@recoilData/index';
import { useRecoilState } from 'recoil';

export const useCheckableMembers = () => {
  const [checkedMembers, setCheckedMembers] =
    useRecoilState(checkedMembersState);

  const handleCheckMember = (member: Member) => {
    if (checkedMembers.find((item) => item.id === member.id)) {
      setCheckedMembers((prev) => prev.filter((item) => item.id !== member.id));
    } else {
      setCheckedMembers([...checkedMembers, member]);
    }
  };

  const handleCheckAllMembers = (checked: boolean, members: Member[]) => {
    if (checked) {
      setCheckedMembers([
        ...checkedMembers,
        ...members.filter((member) => {
          return !checkedMembers.find((item) => item.id === member.id);
        }),
      ]);
    } else {
      setCheckedMembers([]);
    }
  };

  return {
    handleCheckMember,
    handleCheckAllMembers,
    checkedMembers,
  };
};
