import { selector } from 'recoil';
import { ISimpleObject } from 'types/common.type';

import { statusData, filterStatus } from '@recoilData/index';

const getFilteredStatus = selector({
  key: 'getFilteredStatus',
  get: ({ get }) => {
    const statusList = get(statusData);
    const newStatus: ISimpleObject[] = [];

    statusList.forEach((status) => {
      const filterState = get(filterStatus(status.id));
      newStatus.push(generateStatus(status, filterState));
    });

    return newStatus;
  },
});

const setUnassignedStatus = selector<boolean>({
  key: 'setUnassignedStatus',
  get: ({ get }) => get(filterStatus(0)),
  set: ({ set }, newValue) => set(filterStatus(0), newValue),
});

const setNotStartedStatus = selector<boolean>({
  key: 'setNotStartedStatus',
  get: ({ get }) => get(filterStatus(2)),
  set: ({ set }, newValue) => set(filterStatus(2), newValue),
});

const setToDoStatus = selector<boolean>({
  key: 'setToDoStatus',
  get: ({ get }) => get(filterStatus(3)),
  set: ({ set }, newValue) => set(filterStatus(3), newValue),
});

const setInProgressStatus = selector<boolean>({
  key: 'setInProgressStatus',
  get: ({ get }) => get(filterStatus(1)),
  set: ({ set }, newValue) => set(filterStatus(1), newValue),
});

const generateStatus = (
  status: ISimpleObject,
  checked: boolean
): ISimpleObject => ({
  ...status,
  checked: checked,
});

export {
  getFilteredStatus,
  setUnassignedStatus,
  setNotStartedStatus,
  setToDoStatus,
  setInProgressStatus,
};
