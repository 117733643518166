import styled from 'styled-components';
import TkIcon from '@components/TkIcon';
import { withStyles } from '@mui/styles';
import {
  Grid,
  Divider,
  Checkbox,
  Container,
  Theme,
  createStyles,
  Tab,
  Tabs,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material';

export const TkTaskWrapper = styled(Container)`
  &.MuiContainer-root {
    display: flex;
    flex: 1;
    overflow-y: scroll;
    scrollbar-width: none;
    flex-direction: column;
    padding: 0 1rem 1rem;
  }
`;

export const TkListWrapper = styled(Container)`
  &.MuiContainer-root {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    scrollbar-width: none;
    padding: 0;
    margin-top: 20px;
  }
`;

export const TkListWrapperList = withStyles({
  root: {
    marginTop: '0px',
  },
})(TkListWrapper);

export const TkListItem = withStyles({
  root: {
    display: 'flex',
    width: '100%',
    borderBottom: '1px solid #CBCBCB',
    padding: '12px 0 12px 0',
    cursor: 'pointer',
  },
})(Grid);

export const DeleteIconAssignee = withStyles({
  root: {
    display: 'flex',
    flex: '1',
    justifyContent: 'center',
    alignItems: 'center',
  },
})(Grid);

export const SearchBox = withStyles({
  root: {
    marginLeft: '12px',
    marginRight: '12px',
  },
})(Grid);

export const TkAssigneeItem = styled(FormControlLabel)`
  ${(props) =>
    props.checked
      ? 'border: 0.5px solid #2699FB;'
      : 'border: 1px solid #ffffff;'}
  padding: 0 0 10px 10px;
  margin: 5px 0 5px 0 !important;
  user-select: none;
  margin: 0;
  display: flex;
  flex: 1;
  flex-direction: row;
  position: relative;
  max-width: 254px;

  ${(props) =>
    props.checked
      ? 'background-color: rgba(38,153,251, 0.1) ; border-radius: 2px;'
      : ''}

  .MuiCheckbox-root {
    position: absolute;
    padding: 0 9px 9px 0;
    top: 25px;
    left: 0;
  }

  .MuiTypography-body1 {
    margin-top: 4px;
  }
`;

export const TkCrewItem = styled(FormControlLabel)`
  ${(props) =>
    props.checked
      ? 'border: 0.5px solid #2699FB;'
      : 'border: 1px solid #ffffff;'}
  padding: 0 0 10px 10px;
  user-select: none;
  margin: 0;
  align-items: center;

  ${(props) =>
    props.checked
      ? 'background-color: rgba(38,153,251, 0.1) ; border-radius: 2px;'
      : ''}

  .MuiCheckbox-root {
    padding: 0 9px 9px 0;
    height: 100%;
    margin-top: 35px;
    margin-left: 2px;
    align-items: start;
  }

  .MuiTypography-body1 {
    margin: 4px 0 0 0;
  }
`;

export const TkChecklistItem = styled(FormControlLabel)`
  ${(props) =>
    props.checked
      ? 'border: 0.5px solid #2699FB;'
      : 'border: 1px solid #ffffff; border-bottom: 1px solid #f0f0f0;'}
  padding: 0 0 10px 10px;
  user-select: none;
  margin: 5px 0 5px 0;

  ${(props) =>
    props.checked
      ? 'background-color: rgba(38,153,251, 0.1) ; border-radius: 2px;'
      : ''}

  .MuiCheckbox-root {
    padding: 0 9px 9px 0;
    margin-left: 2px;
  }

  .MuiTypography-body1 {
    margin: 4px 0 0 0;
  }
`;

export const TkListItemSubText = withStyles({
  root: {
    fontSize: '14px',
    fontFamily: 'Lato',
    marginTop: '2px',
    display: 'inherit',
  },
})(Grid);

export const LocationSpan = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
  display: inline-block;
`;

export const Span = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 95%;
  display: inline-block;
`;

export const TkListItemSubTextIcon = styled(TkIcon)`
  font-size: 11px;
  margin-right: 4px;
  margin-top: 3px;
`;

export const TkNewTaskActions = styled(Container)`
  &.MuiContainer-root {
    border-top: 1px solid #999999;
    display: flex;
    min-height: 65px;
    background-color: white;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ImageDiv = styled(Grid)`
  margin-top: 10px;
`;

export const TkAssigneeContent = styled(Grid)`
  margin-left: 15px;
  max-width: 223px;
`;

export const TkListActions = styled(Container)`
  &.MuiContainer-root {
    border-top: 1px solid #999999;
    display: flex;
    min-height: 65px;
    background-color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const TkRadio = styled(Radio)`
  .MuiSvgIcon-root {
    color: #4952a9 !important;
  }
`;

export const ChecklistSelectedItem = styled(Grid)`
  border-bottom: 1px solid #f0f0f0;
  padding: 10px 0 10px 0;

  .MuiGrid-container {
    align-items: center;
  }

  .MuiIcon-root {
    cursor: pointer;
  }

  &:last-child {
    border-bottom: 1px solid #ffffff;
  }
`;

export const TkExtendedWrapper = styled(Container)`
  &.MuiContainer-root {
    width: 282px;
    padding: 1rem;
    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const TkExtendedWrapperTabs = withStyles({
  root: {
    margin: 0,
  },
})(TkExtendedWrapper);

export const ImgAssignee = styled.img`
  margin-top: 7px;
  margin-right: 10px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
`;

export const TabsCustom = withStyles({
  root: {
    maxWidth: '282px',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 0,
      width: '100%',
      backgroundColor: '#ffffff',
    },
  },
})(Tabs);

export const TabCustom = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 141,
      backgroundColor: '#f0f0f0',
    },
    selected: {
      backgroundColor: '#FFFFFF',
    },
  })
)(Tab);

export const BaseGrid = withStyles({
  root: {
    marginBottom: '30px',
  },
})(Grid);

export const DateGroup = withStyles({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
})(Grid);

export const Selector = styled.div`
  font: normal normal normal 16px/24px 'Lato';
  padding-top: 20px;
  color: #4952a9;
  position: relative;
  cursor: pointer;
`;

export const DividerCustom = styled(Divider)`
  &.MuiDivider-root {
    margin-bottom: 20px;
  }
`;

export const CheckboxInput = withStyles({
  root: {},
})(Checkbox);

export const AssigneeButton = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '15px',
    cursor: 'pointer',
  },
})(Grid);

export const AssigneeSelectedButton = withStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '15px',
    cursor: 'pointer',
  },
})(Grid);

export const IconAssigneeButton = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #4a4da6;
  border: #4a4da6 1px solid;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: 'Lato';
  font-weight: normal;
  font-size: 16px;
`;

export const IconNotAssignedButton = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #feb334;
  border: #feb334 1px solid;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
`;

export const IconAssigneeButtonHover = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #ffffff;
  border: #feb334 1px solid;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #feb334;
  text-align: center;
`;

export const FormControlLabelCustom = styled(FormControlLabel)`
  .MuiTypography-body1 {
    font-size: 16px;
    font-family: 'Lato';
    margin-right: 30px;
    color: #061f41;
  }

  .MuiSvgIcon-root {
    color: #061f41;
  }
`;

export const RadioGroupCustom = styled(RadioGroup)`
  &.MuiFormGroup-root {
    display: flex;
    flex: 1;
    flex-direction: row;
  }
`;
