import React from 'react';

import { useTranslation } from 'react-i18next';

import { Skeleton, Divider } from '@mui/material';

import {
  ButtonWrapper,
  ListItem,
  ListWrapper,
  TaskId,
  TkTaskIdWrapper,
} from './styles';
import { TkDropDown, TkIcon, TkTooltip, TkTypography } from '@components/index';

interface ShowMoreActionsOptions {
  enable?: boolean;
  markCompleteForEveryone?: boolean;
  contactCustomerSupport?: boolean;
}
interface TkDrawerHeaderProps {
  id: number;
  dismissIcon?: string;
  showBackButton?: boolean;
  showMoreActions?: ShowMoreActionsOptions;
  isLoading?: boolean;
  taskId?: boolean;
  handlers: {
    handleDeleteTask?: () => void;
    handleBack?: () => void;
    handleClose: () => void;
    handleMarkAsComplete?: () => void;
  };
}

const TkDrawerHeader = ({
  id,
  handlers: {
    handleMarkAsComplete = () => null,
    handleDeleteTask = () => null,
    handleClose,
    handleBack = () => null,
  },
  dismissIcon = 'fa fa-chevron-right',
  showBackButton = false,
  taskId = false,
  showMoreActions = {
    enable: false,
    markCompleteForEveryone: false,
    contactCustomerSupport: true,
  },
  isLoading = false,
}: TkDrawerHeaderProps): JSX.Element => {
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <TkTaskIdWrapper>
        <Skeleton variant="rectangular" width={385} height={20} />
      </TkTaskIdWrapper>
    );
  }

  const Backbutton = () => (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={handleBack || handleClose}
    >
      <TkIcon
        iconSize={16}
        width={8}
        marginRight={5}
        marginTop={2}
        className="fa fa-angle-left"
        style={{ cursor: 'pointer' }}
      />

      <TkTypography
        fontSize={14}
        fontFamily="Muli"
        textAlign="center"
        marginTop={1}
        style={{ cursor: 'pointer' }}
      >
        {t('back', { ns: 'common' })}
      </TkTypography>
    </div>
  );

  const TaskIdText = () => (
    <TkTypography
      fontWeight="bold"
      fontSize={16}
      fontFamily="Muli"
      textAlign="center"
      marginTop={1}
    >
      {t('task', { ns: 'common' })} #{id}
    </TkTypography>
  );

  const DeleteTaskButton = () => (
    <TkTooltip placement="top" title={`${t('delete', { ns: 'common' })}`}>
      <TkIcon
        className="fa fa-trash"
        color="#011F41"
        verticalAlign="middle"
        cursor="pointer"
        onClick={handleDeleteTask}
      />
    </TkTooltip>
  );

  const ShowMoreActionsButton = ({
    showMoreActions,
  }: {
    showMoreActions: ShowMoreActionsOptions;
  }) => {
    const dropDownItems = [
      {
        enable: showMoreActions.markCompleteForEveryone,
        label: 'markCompleteForEveryone',
        labelColor: 'default',
        ns: 'events',
        onClick: handleMarkAsComplete,
        icon: 'fa fa-check',
        iconColor: 'default',
      },
      // {
      //   enable: showMoreActions.contactCustomerSupport,
      //   label: 'contactCustomerSupport',
      //   labelColor: 'default',
      //   ns: 'events',
      //   onClick: () => null,
      //   icon: 'fa fa-question-circle',
      //   iconColor: 'default',
      // },
      {
        enable: true,
        label: 'deleteTask',
        labelColor: 'error',
        ns: 'events',
        onClick: handleDeleteTask,
        icon: 'fa fa-trash',
        iconColor: 'error',
      },
    ];

    return (
      <TkDropDown
        popoverStyle={{
          width: '272px',
          height: 'auto',
          marginTop: '5px',
          padding: '0',
        }}
        Activator={({ toggle }) => (
          <ButtonWrapper toggle={toggle}>
            <TkIcon
              className="fa fa-ellipsis-v"
              color="#011F41"
              verticalAlign="middle"
              cursor="pointer"
              iconSize={16}
            />
          </ButtonWrapper>
        )}
        ListItem={() => (
          <ListWrapper>
            {dropDownItems.map((item, index) =>
              item.enable ? (
                <React.Fragment key={index}>
                  <ListItem onClick={item.onClick}>
                    <TkIcon
                      className={item.icon}
                      color={item.iconColor}
                      verticalAlign="middle"
                      marginRight={10}
                      cursor="pointer"
                    />
                    <TkTypography fontSize={16} color={item.labelColor}>
                      {t(item.label, { ns: item.ns })}
                    </TkTypography>
                  </ListItem>
                  {index < dropDownItems.length - 1 && (
                    <Divider sx={{ margin: '1px 5px' }} />
                  )}
                </React.Fragment>
              ) : null
            )}
          </ListWrapper>
        )}
      />
    );
  };

  return (
    <TkTaskIdWrapper>
      <TaskId>
        {showMoreActions.enable && (
          <ShowMoreActionsButton showMoreActions={showMoreActions} />
        )}
        {showBackButton && <Backbutton />}
        {taskId && <TaskIdText />}
      </TaskId>
      <div>
        {!showMoreActions.enable && <DeleteTaskButton />}
        <TkIcon
          className={dismissIcon}
          color="#011F41"
          verticalAlign="middle"
          marginLeft={30}
          style={{ cursor: 'pointer' }}
          onClick={handleClose}
        />
      </div>
    </TkTaskIdWrapper>
  );
};

export default TkDrawerHeader;
