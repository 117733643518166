export default {
  tkModalAlertIntegrated:
    "Esta tarea es de un calendario externo las fechas no pueden ser cambiadas",
  tkModalAlertRepeat:
    "Esta tarea es una instancia de una serie de repeticiones y no puede ser movida a una nueva fecha",
  tkModalAlertTurnoverbnb:
    "Esta tarea es de Turno y el cliente no puede ser editado",
  tkModalAlertInProgress:
    "No puedes editar las fechas de las tareas en marcha o completadas",
  tkModalAlertWontReflectOnTurnoverBNB:
    "Cualquier cambio que hagas en esta tarea no se reflejará en Turno",
  alerts: {
    sendNotifications: "¿Enviar notificaciones?",
    doYouWantToNotify: "¿Quieres notificar?",
    doNotShowAgainAndSendAllNotifications: "No mostrar de nuevo y enviar todas las notificaciones",
  }
};
