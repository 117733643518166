import { atom, atomFamily } from "recoil";

const filterCrews = atomFamily<boolean, number>({
  key: "filterCrews",
  default: false,
});

const isAllFilterCrewsSelected = atom<boolean>({
  key: "isAllFilterCrewsSelected",
  default: false,
});

export { filterCrews, isAllFilterCrewsSelected };
