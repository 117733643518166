import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TkTypography, TkIcon } from '@components/index';

interface TkDrawerPhoneProps {
  phoneNumber: string;
  hideText?: boolean;
  hideCopy?: boolean;
}

const TkDrawerPhone = ({
  phoneNumber,
  hideCopy = false,
  hideText = false,
}: TkDrawerPhoneProps): JSX.Element => {
  const { t } = useTranslation();

  const handleCopyNumber = () => {
    navigator.clipboard.writeText(phoneNumber);
  };

  const formatMobileNumber = (phoneNumber) => {
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);

    if (!match) {
      return phoneNumber;
    }

    const intlCode = match[1] ? '+1 ' : '';
    const number = [
      intlCode,
      '(',
      match[2],
      ') ',
      match[3],
      '-',
      match[4],
    ].join('');

    return number;
  };

  return (
    <Grid container justify="space-between">
      <Grid
        container
        direction="row"
        style={{ margin: '0 0 5px 0', maxWidth: '80%' }}
      >
        <TkIcon
          className="fa fa-phone"
          iconSize={14}
          width={14}
          marginTop={6}
          marginRight={7}
        />
        {hideText ? null : (
          <TkTypography fontFamily={'Lato'} fontSize={16}>
            {`${t('phoneNumber', { ns: 'common' })}: `}
          </TkTypography>
        )}
        <TkTypography
          marginLeft={'5px'}
          fontFamily={'Lato'}
          fontSize={16}
          fontWeight={hideText ? 'normal' : 'bold'}
        >
          {formatMobileNumber(phoneNumber)}
        </TkTypography>
      </Grid>
      {hideCopy ? null : (
        <Grid>
          <TkIcon
            className="fa fa-copy"
            onClick={handleCopyNumber}
            cursor={'pointer'}
            iconSize={14}
            width={18}
            marginTop={5}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default TkDrawerPhone;
