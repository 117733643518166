import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SuggestionItem, TkInputWidgetBooking } from './styles';
import PlacesAutocomplete from 'react-places-autocomplete';
import { TkFormControlInputWrapper } from '@components/index';
import { Autocomplete } from '@mui/material';

const AutocompleteLocation: React.FC<AutocompleteLocation> = ({
  type,
  value,
  hasError,
  handleSelect,
  popupIcon = null,
  countryCode = '',
  isReadOnly = false,
  setValue = () => {},
}) => {
  const { t, i18n } = useTranslation();
  const [inputString, setInputString] = useState<string>(value || '');
  const [open, setOpen] = useState(false);

  const placeholder = t(
    [
      `searchAddress.${type}.${countryCode}`,
      `searchAddress.${type}.default`,
      `searchAddress.${type}`,
      `${type}`,
    ],
    { ns: ['bookingWidget', 'common'] }
  );

  const googleTypesMap = {
    address: ['address'],
    country: ['country'],
    state: ['administrative_area_level_1'],
    city: ['administrative_area_level_2', 'locality'],
  };

  const searchOptions = {
    language: i18n.language,
    types: googleTypesMap[type],
    componentRestrictions: {
      country: ['address', 'country'].includes(type) ? [] : countryCode,
    },
  };

  const handleOnClose = () => normalizeInputAndValue();

  const handleOnChange = (value: string) => {
    setValue(value);
    setOpen(true);
    setInputString(value);
  };

  const closeOption = () => setOpen(false);

  const handleOnSelect = (_address: string, placeId: string) =>
    handleSelect(_address, placeId, type);

  const normalizeInputAndValue = () => {
    inputString !== value && setInputString(value || '');
  };

  const errorTokenizedLabel = useMemo(() => {
    const field = placeholder.toLowerCase();
    const key = type === 'address' ? 'pleaseEnterYour' : 'pleaseEnterA';
    return t(`others.${key}`, {
      ns: 'app',
      field,
      interpolation: { escapeValue: false },
    });
  }, [placeholder]);

  useEffect(() => {
    normalizeInputAndValue();
  }, [value]);

  return (
    <PlacesAutocomplete
      value={inputString}
      onChange={handleOnChange}
      onSelect={handleOnSelect}
      searchOptions={searchOptions}
    >
      {({ loading, suggestions, getInputProps, getSuggestionItemProps }) => {
        return (
          <Autocomplete
            noOptionsText={t('not_found_options', { ns: 'bookingWidget' })}
            blurOnSelect
            open={open}
            onBlur={closeOption}
            loading={loading}
            disableClearable
            popupIcon={popupIcon}
            readOnly={isReadOnly}
            onClose={handleOnClose}
            inputValue={inputString}
            filterOptions={(options: Array<Option>) => options}
            options={suggestions.map(
              (suggestion) =>
                ({
                  data: suggestion,
                  id: suggestion.placeId,
                  label: suggestion.description,
                } as Option)
            )}
            renderOption={(params, option: Option, __) => (
              <SuggestionItem {...getSuggestionItemProps(option.data, params)}>
                <span>{option.label}</span>
              </SuggestionItem>
            )}
            renderInput={(params) => (
              <TkFormControlInputWrapper
                error={hasError}
                errorLabel={errorTokenizedLabel}
              >
                <TkInputWidgetBooking
                  {...getInputProps({
                    ...params,
                    placeholder,
                    variant: 'standard',
                  })}
                  error={hasError}
                />
              </TkFormControlInputWrapper>
            )}
          />
        );
      }}
    </PlacesAutocomplete>
  );
};

export default AutocompleteLocation;
