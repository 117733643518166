import React, { useContext, useState } from 'react';

import { Channel, DefaultGenerics } from 'stream-chat';
import { Chat, LoadingIndicator, Streami18n } from 'stream-chat-react';
import { ComponentWithChildren } from 'types/common.type';

import { ChatCustomContext, IChatContext } from './ChatCustomContext';
import { useAuth } from '@contexts/AuthContext/AuthProvider';
import { isChatEnabled } from '@helpers/environment';
import { useClient } from '@hooks/chat/useChatClient';

const ChatProvider = ({ children }: ComponentWithChildren): JSX.Element => {
  const [isNewChannelBeingCreated, setCreateNewChannel] = useState(false);
  const { userInfo, signed } = useAuth();

  const streami18n = new Streami18n({
    language: 'en',
  });

  const chatClient = useClient({
    user: {
      id: userInfo?.chat_profile?.id,
      name: userInfo?.user?.full_name,
      image:
        userInfo?.user?.full_photo_url ??
        `https://getstream.io/random_svg/?name=${userInfo?.user?.full_name}`,
      role: 'admin',
    },
    tokenOrProvider: userInfo?.chat_profile?.user_token,
  });

  const createNewChannel = async (
    members: IAssignee[],
    callback: (
      newChannel?: Channel<DefaultGenerics>,
      watchers?: {
        limit?: number;
        offset?: number;
      },
      // eslint-disable-next-line @typescript-eslint/ban-types
      event?: React.BaseSyntheticEvent<object, any, any>
    ) => void
  ) => {
    if (!userInfo.chat_profile.id) return;

    const membersId: string[] = [userInfo.chat_profile.id];

    members.forEach(({ chat_user_id }) => {
      if (!chat_user_id) return;

      membersId.push(chat_user_id);
    });

    if (membersId.length <= 1) {
      setCreateNewChannel(false);
      return;
    }

    const newChannel = await chatClient.channel('messaging', {
      members: membersId,
    });

    setCreateNewChannel(false);
    callback(newChannel);
  };

  const hooks: IChatContext = {
    isNewChannelBeingCreated,
    createNewChannel,
    setCreateNewChannel,
  };

  if (!isChatEnabled || !signed) {
    return (children as JSX.Element) ?? <></>;
  }

  if (isChatEnabled && !chatClient) {
    return <LoadingIndicator />;
  }

  return (
    <ChatCustomContext.Provider value={hooks}>
      <Chat
        client={chatClient}
        theme="str-chat__theme-light"
        i18nInstance={streami18n}
      >
        {children}
      </Chat>
    </ChatCustomContext.Provider>
  );
};

const useCustomChatContext = (): IChatContext => useContext(ChatCustomContext);

export { ChatProvider, useCustomChatContext };
