import React, { useEffect, useState } from 'react';
import { Grid, GridProps, Tooltip } from '@mui/material';
import { TkIcon, TkTypography } from '@components/index';
import { ButtonCustom, InputWrapper, NameDisplay, TkInputEdit } from './styles';
import { TkIconSelected } from '@web-ui/index';

const TkInputDisplay = ({
  value,
  preIcon,
  setValue,
  onChange,
  displayProps,
  displayTextProps,
  editIconTip = '',
  commitChange = () => {},
  alwaysShowEditIcon = false,
}: ITkInputDisplay): JSX.Element => {
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [showEditIcon, setShowEditIcon] = useState<boolean>(false);
  const [valueChange, setValueChange] = useState(value as string);

  const cancelValueChange = () => {
    setValueChange(value);
    setIsEdit(false);
  };

  const applyValueChange = () => {
    setValue(valueChange);
    setIsEdit(false);
    commitChange();
  };

  const handleOnChange = (e) => {
    const value = e.target.value;
    const valueChange = onChange ? onChange(value) : value;
    setValueChange(valueChange);
  };

  useEffect(() => {
    setValueChange(value);
  }, [value]);

  const defaultDisplayProps: GridProps = {
    justifyContent: 'center',
    alignItems: 'center',
  };

  const usableDisplayProps = {
    ...defaultDisplayProps,
    ...displayProps,
  };

  const defaultTextDisplayProps = {
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'center',
  };

  const usableTextDisplayProps = {
    ...defaultTextDisplayProps,
    ...displayTextProps,
  };

  return (
    <InputWrapper>
      {isEdit ? (
        <Grid
          container
          style={{ height: 'auto' }}
          justifyContent="space-between"
          alignItems="center"
          direction="row"
        >
          <TkInputEdit
            value={valueChange}
            disableUnderline
            onChange={handleOnChange}
          />
          <ButtonCustom color="#FFFFFF" onClick={cancelValueChange}>
            <TkIcon
              marginLeft={5}
              className="fa fa-times"
              iconSize={16}
              color="primary"
            />
          </ButtonCustom>
          <ButtonCustom color="#4952a9" onClick={applyValueChange}>
            <TkIcon className="fa fa-check" iconSize={16} color="white" />
          </ButtonCustom>
        </Grid>
      ) : (
        <NameDisplay
          {...usableDisplayProps}
          onMouseEnter={() => setShowEditIcon(true)}
          onMouseLeave={() => setShowEditIcon(false)}
        >
          <TkTypography {...usableTextDisplayProps}>
            <div>
              {preIcon}
              {valueChange}
              {showEditIcon || alwaysShowEditIcon ? (
                <Tooltip title={editIconTip} placement="top">
                  <TkIconSelected
                    verticalAlign="middle"
                    className="fa fa-pencil"
                    onClick={() => setIsEdit(true)}
                    {...(alwaysShowEditIcon && { color: 'black' })}
                  />
                </Tooltip>
              ) : null}
            </div>
          </TkTypography>
        </NameDisplay>
      )}
    </InputWrapper>
  );
};

export default TkInputDisplay;
