export default {
  title: 'Mensajes',
  sendAMessage: 'Enviar un mensaje',
  suggestionNewChat: 'Escribe un nombre para obtener sugerencias',
  startChat: 'Comenzar chat',
  chat: 'Chat',
  deleteChat: 'Eliminar chat',
  emptyStateChannelMessage:
    'Selecciona un contacto de la izquierda para ver los mensajes o comienza un nuevo chat.',
  searchBarPlaceHolder: 'Buscar mensajes...',
  info: 'Información',
  editMessagesMenu: {
    reply: 'Responder',
    copy: 'Copiar mensaje',
    editMessage: 'Editar mensaje',
    deleteMessage: 'Eliminar mensaje',
  },
  dropDown: {
    seeAllMessages: 'Ver todos los mensajes',
    emptyState1: 'No hay nuevos mensajes',
    emptyState2:
      '¡Comienza una conversación! Invita a tus compañeros de equipo a chatear.',
    inviteTeammates: 'Invitar compañeros',
  },
  memberInfo: {
    taskInProgressSince: 'Tarea en progreso desde: {{time}}',
  },
};
