export default {
  login: {
    title: 'Logar com e-mail',
  },
  forgotPasswordModal: {
    passwordUpdated: 'Sua senha foi atualizada. Inicie uma sessão com suas novas credenciais.',
  },
  calendar: {
    search_no_results: 'Nenhum resultado',
  },
  event: {
    errors: {
      repeating_task_edit_dates_error:
        'As datas de tarefas recorrentes não podem ser editadas',
      imported_task_edit_dates_error:
        'As datas de tarefas importadas não podem ser editadas',
    },
  },
  task: {
    tasks_today: 'Tarefas Hoje',
    no_task_today: 'Nenhuma Tarefa Hoje',
    x_tasks_today: '1 Tarefa Hoje',
    x_tasks_today_plural: '{{count}} Tarefas Hoje',
    no_task_week: 'Nenhuma Tarefa Esta Semana',
    x_tasks_week: '1 Tarefa Esta Semana',
    x_tasks_week_plural: '{{count}} Tarefas Esta Semana',
  },
  navbar: {
    tasks: 'Tarefas',
    team: 'Time',
    customers: 'Cliente',
    checklists: 'Lista de Tarefas',
    bookingWidget: 'Ferramenta de Reserva',
    integrations: 'Integrações',
  },
  tkTags: {
    notCompleted: 'Não Concluída',
    finishedLate: 'Concluída tarde',
    startedLate: 'Iniciada tarde',
    notStarted: 'Não Iniciada',
    completedInTurnoverBnB: 'Concluída no Turno',
    completed: 'Concluída',
    repeating: 'Recorrente',
    bookingWidget: 'Widget de Reserva',
  },
  others: {
    invalidCode:
      'O código que você inseriu expirou ou já foi utilizado. Por favor, tente novamente',
  },
  error: {
    somethingWentWrong: 'Algo deu errado',
    tryAgain: 'Tente novamente',
  }
};
