import { EventBillingTypeEnum } from '@consts/index';

type ProjectPricingLabel = Pick<
  IProject,
  'project_offer_payment_type' | 'client' | 'price'
>;

export const getOfferPricingLabel = (
  project_offer_payment_type: ProjectPricingLabel['project_offer_payment_type'],
  client: ProjectPricingLabel['client'] | string
): string => {
  if (!project_offer_payment_type) {
    return 'priceNotSet';
  }

  if (client.includes('Turno')) {
    switch (project_offer_payment_type.toUpperCase().replace(/\s+/g, '_')) {
      case EventBillingTypeEnum.HOURLY:
        return 'pricePerHourTurno';
      case EventBillingTypeEnum.PER_PROJECT:
        return 'pricePerProject';
      default:
        break;
    }
  }

  switch (project_offer_payment_type.toUpperCase()) {
    case EventBillingTypeEnum.HOURLY:
      return 'offer_price_person';

    case EventBillingTypeEnum.PER_PROJECT:
      return 'offer_price_project';

    default:
      break;
  }
};
