import React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useRecoilState, RecoilState } from 'recoil';

import {
  Span,
  ImageDiv,
  ImgAssignee,
  TkAssigneeItem,
  TkAssigneeContent,
  TkListItemSubText,
  IconAssigneeButton,
} from '@components/ExtendedDrawers/styles';
import TkCheckBox from '@components/TkCheckBox';
import TkIcon from '@components/TkIcon';
import TkTypography from '@components/TkTypography';
import { generateInitials } from '@helpers/index';
import { useDrawer } from '@hooks/index';
import { Grid } from '@material-ui/core';

interface ITkAssigneeListItem {
  assignee: IAssignee;
  eventAssigneesRecoilState(param: number): RecoilState<boolean>;
}

const TkAssigneeListItem: React.FC<ITkAssigneeListItem> = ({
  assignee,
  eventAssigneesRecoilState,
}) => {
  const { t } = useTranslation();
  const { closeExtDrawer } = useDrawer();

  const [assigneeState, setAssigneeState] = useRecoilState(
    eventAssigneesRecoilState(assignee.id)
  );

  const handleSelectAssignee = (checked: boolean, unique = false) => {
    setAssigneeState(checked);
    if (unique) closeExtDrawer();
  };

  return (
    <TkAssigneeItem
      key={assignee.id}
      name={`assignee-${assignee.id}`}
      checked={assigneeState}
      control={
        <TkCheckBox
          checked={assigneeState}
          onChange={(_, checked) => handleSelectAssignee(checked)}
        />
      }
      label={
        <div onClick={() => handleSelectAssignee(!assigneeState, true)}>
          <TkAssigneeContent container direction="row">
            <ImageDiv item>
              {assignee.photo_url !== null ? (
                <ImgAssignee src={assignee.photo_url} />
              ) : (
                <IconAssigneeButton>
                  {generateInitials(assignee.full_name)}
                </IconAssigneeButton>
              )}
            </ImageDiv>
            <Grid item direction="column" style={{ maxWidth: '75%' }}>
              <TkTypography
                fontWeight="bold"
                fontFamily="Lato"
                fontSize={16}
                style={{ maxHeight: '24px' }}
              >
                <Span>{assignee.full_name}</Span>
              </TkTypography>
              <TkListItemSubText item>
                {assignee.taskCountForToday !== undefined &&
                assignee.taskCountForToday > 0 ? (
                  <>
                    <TkIcon
                      width={20}
                      iconSize={14}
                      marginRight={1}
                      className="fa fa-exclamation-triangle"
                      color="#011F41"
                      fontSize="inherit"
                      verticalAlign="text-top"
                    />
                    <Trans
                      i18nKey="task.x_tasks_today"
                      count={assignee.taskCountForToday}
                    >
                      {{ count: assignee.taskCountForToday }} Tasks Today
                    </Trans>
                  </>
                ) : (
                  t('task.no_task_today')
                )}
              </TkListItemSubText>
              <TkListItemSubText item alignItems="center">
                {assignee.taskCountForToday !== undefined &&
                assignee.taskCountForToday > 0 ? (
                  <Trans
                    i18nKey="task.x_tasks_week"
                    count={assignee.taskCountForWeek}
                  >
                    {{ count: assignee.taskCountForWeek }} Tasks This Week.
                  </Trans>
                ) : (
                  t('task.no_task_week')
                )}
              </TkListItemSubText>
            </Grid>
          </TkAssigneeContent>
        </div>
      }
    />
  );
};

export default TkAssigneeListItem;
