import React from 'react';

import { useTranslation } from 'react-i18next';

import { Container } from './styles';
import { IOptionStruct } from './types.type';
import { IOptionsSelect } from '@components/TkModalComponents/types.type';
import {
  TkTypography,
  SelectableOption,
  OptionWrapper,
} from '@components/index';

interface WelcomeBaseContentProps {
  name: string;
  options: Record<string, IOptionStruct>;
  selectedOption: IOptionsSelect;
}

const WelcomeBaseContent = ({
  name,
  options,
  selectedOption,
}: WelcomeBaseContentProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Container direction="column">
      <TkTypography
        textAlign="center"
        fontSize="24px"
        fontWeight="bold"
        fontFamily="Muli"
        marginTop="20px"
        marginBottom="10px"
      >
        {t('welcomeSetupModal.welcomeToTaskbirdName', { name: name })}
      </TkTypography>
      <TkTypography
        textAlign="center"
        fontSize="16px"
        fontFamily="Lato"
        marginBottom="10px"
      >
        {t('welcomeSetupModal.whatsYourMainGoal')}
      </TkTypography>

      <OptionWrapper>
        {Object.values(options).map((option) => (
          <SelectableOption
            key={option.id}
            option={option}
            selectedOption={selectedOption}
          />
        ))}
      </OptionWrapper>
    </Container>
  );
};

export default WelcomeBaseContent;
