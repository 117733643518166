import { nameSplitter } from '@helpers/index';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { ChannelMemberResponse } from 'stream-chat/dist/types/types';

export const generateChannelNames = (channel: any): string => {
  const { members, membership } = channel.state;
  const { user } = membership;
  let channelName = channel.data?.name;

  if (typeof channelName === 'string' && channelName.length === 0) {
    channelName = undefined;
  }

  const membersList: string[] = Object.values(
    members as Record<string, ChannelMemberResponse<DefaultStreamChatGenerics>>
  ).map((member) => {
    if (member.user?.id === user.id) return;

    const memberName = member.user?.name
      ? nameSplitter(member.user?.name)[0] ?? ''
      : '';

    if (memberName === '') return;

    return memberName;
  });

  const filteredMembersList = membersList.filter(
    (member) => member !== undefined
  );

  const memberNames =
    filteredMembersList.length > 1
      ? filteredMembersList.splice(0, 4).join(', ')
      : filteredMembersList[0];

  const finalChannelName = channelName ?? memberNames;

  return finalChannelName;
};

export const generateChannelHeaderImage = () => {};
