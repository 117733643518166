import { useEffect, useState } from 'react';

type UseLoadingTimeProps = {
  isLoading: boolean;
  interval: number;
  onOvertime?: (elapsedTime: number) => void;
};

export const useLoadingOvertime = ({
  isLoading,
  interval,
  onOvertime,
}: UseLoadingTimeProps) => {
  const [elapsedTime, setElapsedTime] = useState<number | undefined>(undefined);

  useEffect(() => {
    let intervalFn;

    if (isLoading) {
      intervalFn = setInterval(() => {
        setElapsedTime((prevElapsedTime) => {
          if (prevElapsedTime === undefined) {
            return interval;
          }
          return prevElapsedTime + interval;
        });
      }, interval);
    }

    return () => {
      clearInterval(intervalFn);
      setElapsedTime(undefined);
    };
  }, [isLoading, interval]);

  useEffect(() => {
    if (onOvertime && elapsedTime) {
      onOvertime(elapsedTime);
    }
  }, [elapsedTime]);

  return {
    elapsedTime,
  };
};
