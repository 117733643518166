import React from 'react';
import { useTranslation } from 'react-i18next';

const TkBookinSiteBlockResponsiveSubTitle = () => {

  const { t } = useTranslation(['blockResponsive']);

  return (
    <div style={{ fontSize: '16px' }}>
      {t('subTitleBookingSite1')}
      <span style={{ color: '#4952A9', fontWeight: 'bold' }}>
        {t('subTitleBookingSiteUrl')}
      </span>
      {t('subTitleBookingSite2')}
    </div>
  );
}

export default TkBookinSiteBlockResponsiveSubTitle;
