export default {
  crews: {
    crew: "Equipo",
    crew_plural: "Equipos",
    delete_crew: "Eliminar equipo",
    crew_name: "Nombre del equipo",
    crew_create_enter_name_error: "Por favor ingresa el nombre del equipo",
    crew_create_successfully: "Equipo creado con éxito",
    crew_deleted_successfully: "Equipo eliminado con éxito",
    crew_create_enter_members_minimum:
      "Un equipo debe tener al menos un miembro",
    crew_details: "Detalles del equipo",
    click_to_see_details_and_edit:
      "Haz click en un equipo para ver sus detalles y editarlo aquí",
    create_crew_drawer_warning_message:
      "Los nombres de los equipos son visibles a todos los compañeros.",
    no_crews_warning: "Hey, no tienes <0/>ningún equipo todavía",
    create_crew_message:
      "Los equipos permiten asignar una tarea<0/> a múltiples compañeros fácilmente.",
    no_members_to_create_crew:
      "Para crear un equipo primero debes agregar miembros",
    create_first_crew: "Crea tu primer equipo",
    remove_crews_warning: "¿Estás seguro/a que quieres remover este equipo?",
    remove_crews_warning_message:
      "No afectará a los miembros del equipo y sus tareas asignadas.",
    create_crew: "Crear equipo",
    create_crew_drawer_message:
      "Los equipos son grupos de miembros que trabajan juntos, úsalos para organizar el trabajo.",
    selected_crew_warning_add_members:
      "Agregar o remover miembros de un equipo no afectará sus tareas existentes.",
    crews_must_contain_one_member:
      "Los equipos deben contener al menos un miembro.",
  },
  teammates: {
    member: "Miembro",
    member_plural: "Miembros",
    member_removed_successfully: "Compañero removido con éxito",
    click_to_see_details_and_edit: "Haz click en un compañero de equipo para ver y editar su perfil aquí",
    remove_teammate: "Remover compañero de equipo",
    get_started: "Empieza a añadir miembros a tu<0/>empresa enviándoles invitaciones.",
    team_members: "Miembros del equipo",
    pending_invites: "Invitaciones pendientes",
    pending_invites_count: "Ver {{ pendingInvitationsCount }} invitaciones pendientes",
    resend_invite: "Reenviar invitación",
    cancel_invite: "Cancelar invitación",
    invitation_canceled: "Invitación cancelada con éxito",
    invitation_resent: "Invitación reenviada con éxito",
    no_tasks: "Sin tareas",
    no_teammates_warning:
      "Hey, no tienes ningún<0/> compañero de equipo todavía",
    remove_teammate_warning:
      "Estás seguro/a que quieres remover este compañero de equipo?",
  },
  lets_send_invites_message: "¡Envía invitaciones!",
  send_invites_message: "Enviar invitaciones",
  invite_message: "Invitar compañeros",
  add_members: "Agregar miembros",
  add_another_contact: "Agregar otro contacto",
  see_tasks: "Ver tareas",
  sms_error_message_empty: "Por favor ingresa un número de teléfono",
  last_name_error_message_empty: "Por favor ingresa un apellido",
  street_address_error_message_empty: "Por favor ingresa una dirección",
  email_error_message_empty: "Por favor ingresa un email",
  sms_error_message: "Por favor ingresa un número de teléfono válido",
  email_error_message: "Por favor ingresa un email en un formato válido",
  invitation_success: "Tu invitación está en camino!",
  invitation_success_plural: "Tus invitacion están en camino!",
  add_to_crew: "Agregar a un equipo",

};
