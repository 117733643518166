import styled from 'styled-components';
import { Grid } from '@mui/material';

export const CodeInputWrapper = styled(Grid)`
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: center;
  margin: 0;
`;

export const InputWrapper = styled.div`
  width: 44px;
  height: 53px;
  margin: 0 2.5px;
  display: inline-block;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  background-color: #f6f6f6;
  border-radius: 5px;
  font-family: 'Lato';
  user-select: none;
  border-width: 1px;
  border-style: solid;
`;

export const InputCode = styled.input`
  border: none;
  color: transparent;
  text-shadow: 0 0 0 gray;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 55px;
  opacity: 0;
  top: 0;

  &:focus {
    outline: none;
  }
`;

export const Divider = styled.div`
  width: 10px;
  border-bottom: 2px #3d4465 solid;
  margin: 0 5px;
`;
