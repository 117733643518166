import React from 'react';
import styled from 'styled-components';
import { TkIcon } from '@components/index';

const TkIconSelected = styled((props) => <TkIcon {...props} />)`
  &.MuiIcon-root {
    color: ${(props) => props.color || '#4952a9'};
    margin-left: 5px;
    font-size: 14px;
    padding: 5px;
    transition: 0.3s ease;
    border-radius: 4px;
    cursor: pointer;
    position: absolute;
    background-color: ${(props) => (props.color ? 'transparent' : '#f4f7fb')};
    /* top: 6px; */
    width: auto;
    height: auto;

    &:hover {
      color: #ffffff;
      background-color: #4952a9;
    }
  }
`;

export default TkIconSelected;
