import React from 'react';
import { TkTypography } from '@components/index';
import { useTranslation } from 'react-i18next';
import { TkIcon } from '@components/index';
import { userTimezone } from '@helpers/TimezoneHelper';
import { getDatetimeFromDate } from '@helpers/index';
import { Grid } from '@mui/material';
interface ITkDrawerRequestedTime {
  date: Date;
  timezone: string;
}

const TkDrawerRequestedTime: React.FC<ITkDrawerRequestedTime> = ({
  date,
  timezone = userTimezone(),
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid container direction="row" style={{ margin: '10px 0 0 0' }}>
        <TkIcon
          className="fa fa-calendar-day"
          iconSize={14}
          width={12}
          marginTop={5}
          marginRight={10}
        />
        <TkTypography fontSize={'16px'} fontFamily={'Lato'}>
          {t('requested_date', { ns: 'offers' })}
        </TkTypography>
      </Grid>
      <Grid container direction="row" style={{ margin: '10px 0 10px 0' }}>
        <TkTypography fontSize={'18px'} fontFamily={'Muli'} fontWeight={'bold'}>
          {getDatetimeFromDate(date, timezone).toFormat("LLL dd '('ccc')'")}
        </TkTypography>
      </Grid>
    </>
  );
};

export default TkDrawerRequestedTime;
