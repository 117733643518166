import React from 'react';
import { TkInput } from '@components/index';
import { FormHelperText, Grid } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 368px;
  padding: 5px 2px;
`;

export const HelpText = styled(FormHelperText)`
  display: flex;
  align-items: center;
  text-align: left;
  font: normal normal normal 14px/20px Lato;
  letter-spacing: 0px;
  color: ${(props) => (props.error ? '#FF3B30' : '#011f41')};
  margin-top: 10px;
`;

export const SpanFormInput = styled((props) => <TkInput {...props}></TkInput>)`
  text-align: left;
  width: 100%;
  height: 19px;
  padding: 15px 15px 15px 0;
  margin-top: 3rem;
  border: none;
  text-align: left;
  border-bottom: 1px solid #6b7b8b;
  font: normal normal normal 16px/24px Lato;
  letter-spacing: 0px;
  opacity: 1;
`;

export const SpanFormPassword = styled(SpanFormInput)`
  padding: 15px 0 15px 0;
  margin-bottom: 5px;
`;
