import { makeStyles, createStyles, Card } from "@material-ui/core";
import { FaCheck } from "react-icons/fa";
import styled from "styled-components";

export const useStyles = makeStyles(() =>
  createStyles({
    sectionContent: {
      width: "99%",
      borderRadius: 2,
      flexDirection: "row",
      minHeight: 87,
      margin: 0,
      display: "flex",
      background: "#F5F6FB",
    },
    sectionContentRead: {
      width: "99%",
      borderRadius: 2,
      flexDirection: "row",
      minHeight: 87,
      margin: 0,
      display: "flex",
      background: "#FFFFFF",
    },
    sectionContentActive: {
      width: "1%",
      minHeight: 87,
      background: "#4952A9",
    },
    sectionContentActiveRead: {
      width: "1%",
      minHeight: 87,
      background: "#CBCBCB",
    },
    sectionContentInfo: {
      display: "flex",
      flexDirection: "column",
      padding: "8px 12px",
      width: "99%",
    },
    sectionContentInfoTitle: {
      display: "flex",
      textAlign: "left",
      font: "normal normal bold 16px/24px Lato",
      height: 22,
      letterSpacing: 0,
      color: "#4952A9",
      fontFamily: "Lato",
      fontSize: "16px",
      marginBottom: 4,
    },
    sectionContentInfoBody: {
      display: "flex",
      flex: 1,
      textAlign: "left",
      font: "normal normal regular 16px/24px Lato",
      height: 22,
      letterSpacing: 0,
      color: "#011F41",
      marginBottom: 4,
      whiteSpace: "break-spaces",
    },
    sectionContentInfoDate: {
      display: "flex",
      textAlign: "left",
      font: "normal normal normal 14px/24px Lato",
      height: 19,
      letterSpacing: 0,
      color: "#999999",
      fontFamily: "Lato, Regular",
      opacity: 0.7,
      marginBottom: 4,
    },
  })
);

export const SectionContentEmptySubBody = styled.div`
  width: 146px;
  height: 17px;
  color: #aaaaaa;
  letter-spacing: 0;
  text-align: center;
  font-family: "Lato";
  font-size: 14px;
  font-weight: normal;
  /* font: "normal normal normal 14px/22px Lato"; */
`;

export const SectionContentEmptyBody = styled.div`
  width: 248px;
  height: 50px;
  text-align: center;
  color: #011f41;
  font-family: "Lato";
  font-weight: bold;
  margin-bottom: 4px;
`;

export const SectionContentLogoI = styled(FaCheck)`
  width: 60px;
  height: 61px;
  color: #3699fb;
  margin-top: 41px;
  margin-bottom: 8px;
`;

export const SectionContentEmpty = styled(Card)`
  display: flex;
  width: 325px;
  border-radius: 2px;
  border: none;
  box-shadow: none;
  height: 234px;
  margin: 4px;
  flex-direction: column;
  background: #ffffff;
  align-items: center;
`;
