import { Theme, Tooltip, withStyles } from '@material-ui/core';

export const TooltipWrapper = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.navyBlue,
    color: theme.palette.white,
    fontSize: '12px',
    marginBottom: 5,
    marginTop: 5,
  },
}))(Tooltip);
