export enum LoginResponseEnum {
  ACCOUNT_NOT_FOUND = 'account not found',
  RECAPTCHA_FAIL = 'The recaptcha field is required.',
  INVALID_CREDENTIALS = 'Invalid credentials',
  EMAIL_TAKEN = 'The email has already been taken.',
  PASSWORD_MIN_LENGTH = 'password must be at least 8 characters',
}

export enum LoginEnum {
  ACCOUNT_NOT_FOUND = 'accountNotFund',
  INVALID_CREDENTIALS = 'invalidCredentials',
  ADMIN_ONLY = 'adminOnly',
  NOT_ADMIN = 'notAdmin',
  EMAIL_TAKEN = 'emailTaken',
  RECAPTCHA_FAIL = 'recaptchaFail',
  ERROR_REQUEST = 'errorRequest',
  SOCIAL_MEDIA_ERROR = 'socialMediaError',
}
