import React from 'react';
import { ScTkTeamsTab, ScTkTeamsTabs } from '@components/TkTeams/TkTabs/style';
export enum TkTabs {
  Members = 'Members',
  Crews = 'Crews',
}

export const tkTabsData = [
  { tab: TkTabs.Members, label: 'Members' },
  { tab: TkTabs.Crews, label: 'Crews' },
];

export interface TkTabsProps {
  currentTab: TkTabs;
  changeTab: (tab: TkTabs) => void;
}

function getActiveTkTabIndex(currentTab: TkTabs) {
  return tkTabsData.findIndex((tab) => tab.tab === currentTab);
}

export const TkTeamTabs = ({
  currentTab,
  changeTab,
}: TkTabsProps): JSX.Element => {
  const tabs = tkTabsData.map(({ tab, label }) => {
    return {
      label,
      onChange: () => changeTab(tab),
    };
  });

  return (
    <ScTkTeamsTabs value={getActiveTkTabIndex(currentTab)} centered>
      {tabs.map(({ label, onChange }, index) => (
        <ScTkTeamsTab key={index} label={label} onClick={onChange} />
      ))}
    </ScTkTeamsTabs>
  );
};
