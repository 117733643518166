import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState, useRecoilState } from 'recoil';

import { FormControl, Grid } from '@mui/material';

import TkCustomerListItem from './TkCustomerListItem';
import { TkExtendedWrapper, TkListWrapper, TkSelectAllItem } from './styles';
import { TkExtendedDrawerSearch } from '@components/ExtendedDrawers';
import TkCheckBox from '@components/TkCheckBox';
import { TkTypography, TkIcon } from '@components/index';
import {
  searchFilterCustomerData,
  selectAllFilterCustomers,
  getFilteredCustomers,
  isAllFilterCustomersSelected,
} from '@recoilData/index';

const TkCustomerList: React.FC = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const customersList = useRecoilValue(searchFilterCustomerData(search));
  const filterCustomers = useRecoilValue(getFilteredCustomers);
  const handleSelectAll = useSetRecoilState(selectAllFilterCustomers);
  const [selectAll, setSelectAll] = useRecoilState(
    isAllFilterCustomersSelected
  );

  useEffect(() => {
    const res = filterCustomers.every((c) => c.checked === true);
    if (res) setSelectAll(true);
  }, [filterCustomers]);

  useEffect(() => {
    const res = filterCustomers.some((c) => c.checked === false);
    if (res) setSelectAll(false);
  }, [filterCustomers]);

  return (
    <TkExtendedWrapper>
      <FormControl>
        <TkTypography fontWeight="bold" fontFamily="Muli" fontSize="16px">
          {t('selectACustomer', { ns: 'events' })}
        </TkTypography>
        <Grid container alignItems="flex-end">
          <Grid item>
            <TkIcon className="fa fa-search" color="#4952A9" />
          </Grid>
          <Grid item sm={10}>
            <TkExtendedDrawerSearch search={search} setSearch={setSearch} />
          </Grid>
        </Grid>
      </FormControl>
      <Grid container>
        <TkSelectAllItem
          checked={selectAll}
          control={
            <TkCheckBox
              checked={selectAll}
              onChange={(_, checked) => handleSelectAll(checked)}
              name="check-alert-assignee"
            />
          }
          label={
            <TkTypography fontFamily="Lato" fontSize="14px">
              {selectAll
                ? t('unselect_all', { ns: 'common' })
                : t('select_all', { ns: 'common' })}
            </TkTypography>
          }
        />
      </Grid>
      <TkListWrapper>
        {customersList.map((customer: ICustomerTask) => (
          <TkCustomerListItem key={customer.id} customer={customer} />
        ))}
      </TkListWrapper>
    </TkExtendedWrapper>
  );
};

export default TkCustomerList;
