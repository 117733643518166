export default {
  search: {
    customers: 'Type customer name',
    locations: 'Type location name',
  },
  noData: {
    title: "Hey, you don't have any customers",
    description: 'Use the side pane to start adding customers',
  },
  noDataLocations: {
    title: 'Hey, you don’t have any Locations',
    description: "Let's add your customers locations",
  },
  emptyPanelDescription:
    'Click on a Customer to see and edit their profile or locations here',
  locationEmptyPanelDescription: 'Click on a Location to edit it here',
  addCustomer: '+ Customer',
  addLocation: '+ Location',
  newCustomer: 'New Customer',
  customerAdded: 'Customer added successfully',
  addNewCustomer: 'Add Customer',
  addNewLocation: 'Add Location',
  newLocation: 'New Location',
  locationAdded: 'Location added successfully',
  customers: 'Customers',
  locations: 'Locations',
  tasksThisMonth: 'Tasks this month',
  actions: 'Actions',
  remove: {
    customerTitle: 'Remove Customer',
    locationTitle: 'Remove Location',
    customerWithTasks:
      'You have #{{tasks}} tasks for {{name}}. These tasks will remain on your calendar without a customer. This action cannot be undone',
    customer:
      'Are you sure you want to remove {{name}}? This action cannot be undone.',
    location:
      'Are you sure you want to remove {{name}}? This action cannot be undone.',
    locationWithTasks:
      'You have #{{tasks}} tasks for {{name}}. These tasks will remain on your calendar without a location. This action cannot be undone.',
    success: 'Customer removed successfully',
    locationRemoved: 'Location removed successfully',
    customerDeleteBlock: 'Customers imported from Turno cannot be removed in Taskbird. Please <0>contact Customer Support</0>',
    locationDeleteBlock: "Locations imported from Turno cannot be removed in Taskbird. Please <0>contact Customer Support</0>",
  },
  editCustomer: 'Edit Customer',
  editCustomerSuccess: 'Customer edited successfully',
  tasksToDo: 'Tasks to-do',
  tasksToDoToday: 'Tasks to-do Today',
  today: 'Today',
  thisWeek: 'This week',
  thisMonth: 'This month',
  editLocation: 'Edit Location',
  unitBuildingName: 'Unit #, Building Name, etc.',
  unitBuildingNamePlaceholder: 'Add unit number or name if needed',
  accessCodeHelpText:
    'Access code will be visible to teammate 6 hours before the task start time',
  alias: 'Alias',
  customer: 'Customer',
  noCustomerSet: 'No customer set',
  noCustomersMatchWith: 'No customers match with "<bold>{{query}}</bold>"',
  noLocationsMatchWith: 'No locations match with "<bold>{{query}}</bold>"',
  customerInfoMessage:
    "Customer's contact details can be added to your tasks. This information is not shown to your teammates, but you can make it visible on Team Settings.",
};
