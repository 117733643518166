import { useEffect, useRef } from 'react';

function useInitialize(initialize) {
  const isInitialize = useRef(false);

  useEffect(() => {
    if (!isInitialize.current) {
      initialize();
      isInitialize.current = true;
    }
  }, []);
}

export { useInitialize };
