import styled from 'styled-components';
import { Tabs, Tab } from '@mui/material';

export const ScTkTeamsTabs = styled(Tabs)`
  width: calc(100% - 419px);
  height: 60px;
  box-shadow: 1px 2px 2px #00000014;
  z-index: 1;

  .MuiTabs-flexContainer {
    gap: 10rem;
  }

  .MuiButtonBase-root {
    height: 60px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: bold;
  }

  & > .MuiTabs-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.6rem;
    left: 0;
    width: 100%;
    max-width: 55px;
    background-color: #4952a9;
  }
`;

export const ScTkTeamsTab = styled(Tab)`
  text-transform: none;
  color: #fff;
  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  font-size: ${(props) => props.theme.typography.pxToRem(15)};
  margin-right: ${(props) => props.theme.spacing(1)}px;
  &:focus {
    opacity: 1;
  }
`;
