import React, { useState, useEffect } from 'react';

import { saveAs } from 'file-saver';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState } from 'recoil';

import { Grid } from '@mui/material';

import {
  TkExtendedWrapper,
  WrapperTeammatesList,
  WrapperBaseListItem,
  WrapperTeammatesHeader,
  TkListActions,
  OverlayContainer,
  OverlayWrapper,
  CircleButton,
  ChecklistDetail,
  ImagePreviewSmall,
  ChecklistWrapper,
  ChecklistWrapperFiller,
  ChecklistDetailFill,
  IconAssigneeButton,
  MoreImages,
  ChecklistSubWrapper,
  ImgAssignee,
} from '../styles';
import {
  DynamicButton,
  TkIcon,
  TkCheckBox,
  TkTooltip,
  TkTypography,
} from '@components/index';
import { generateInitials } from '@helpers/index';
import { useDrawer } from '@hooks/drawer';
import { editEventSelectedChecklist } from '@recoilData/index';
import api from '@services/api';

const TkTaskChecklistList = (): JSX.Element => {
  const { t } = useTranslation(['common']);
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const [blockDownload, setBlockDownload] = useState<boolean>(false);
  const [selectedImg, setSelectedImg] = useState<string>('');
  const [selectedItem, setSelectedItem] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [imageCount, setImageCount] = useState<number>(0);
  const [list, setList] = useState<ITkChecklistItemStatus[]>([]);
  const selectedChecklist = useRecoilValue(editEventSelectedChecklist);
  const resetSelectedChecklist = useResetRecoilState(
    editEventSelectedChecklist
  );
  const { closeExtDrawer } = useDrawer();

  const downloadImageTokenized = t('downloadItem', {
    item: t('image').toLowerCase(),
  });
  const downloadNImagesTokenized = t('downloadXItems', {
    count: imageCount,
    item: t('images').toLowerCase(),
  });

  useEffect(() => {
    if (selectedChecklist) {
      api
        .get(`project-checklist/${selectedChecklist.projectChecklistId}`)
        .then(({ data }) => {
          setShowOverlay(false);
          setList(data.items);

          let images = 0;

          data.items.forEach((element) => {
            images += element.images.length;
          });

          setImageCount(images);
        });
    }
  }, [selectedChecklist]);

  useEffect(() => {
    return resetSelectedChecklist();
  }, []);

  const handleCloseOverlay = () => {
    setShowOverlay(false);
  };

  const handleDownloadImage = () => {
    saveAs(selectedImg, 'download.jpg');
  };

  const handleDownloadAllImages = () => {
    if (blockDownload) {
      return;
    }

    if (!selectedChecklist) {
      return;
    }

    setBlockDownload(true);
    api
      .get(
        `image/download/projectChecklist/${selectedChecklist.projectChecklistId}`,
        {
          responseType: 'blob',
        }
      )
      .then((res) => {
        setBlockDownload(false);
        const blob = new Blob([res.data]);
        saveAs(blob, 'checklistsImages.zip');
      });
  };

  const handleSelectedImage = (
    id: number,
    index: number,
    img: string | null
  ) => {
    if (img !== null) setSelectedImg(img);
    setSelectedItem(id);
    setSelectedIndex(index);
    setShowOverlay(true);
  };

  const handleChangeImage = (more: boolean) => {
    const images = list.find((i) => i.id === selectedItem);
    if (more) {
      const image = images?.images[selectedIndex + 1];
      if (image !== undefined) {
        setSelectedIndex(selectedIndex + 1);
        setSelectedImg(image.fullImageUrl);
      }
    } else {
      const image = images?.images[selectedIndex - 1];
      if (image !== undefined) {
        setSelectedIndex(selectedIndex - 1);
        setSelectedImg(image.fullImageUrl);
      }
    }
  };

  return (
    <>
      {showOverlay ? (
        <OverlayWrapper>
          <OverlayContainer backgroundImage={selectedImg}>
            <Grid
              container
              justifyContent="flex-end"
              style={{ padding: '15px' }}
            >
              <CircleButton onClick={handleCloseOverlay}>
                <TkIcon iconSize={20} color="#ffffff" className="fa fa-times" />
              </CircleButton>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              style={{ padding: '15px' }}
            >
              <CircleButton onClick={() => handleChangeImage(false)}>
                <TkIcon
                  iconSize={20}
                  marginRight={3}
                  color="#ffffff"
                  className="fa fa-chevron-left"
                />
              </CircleButton>
              <CircleButton onClick={() => handleChangeImage(true)}>
                <TkIcon
                  iconSize={20}
                  color="#ffffff"
                  marginLeft={3}
                  className="fa fa-chevron-right"
                />
              </CircleButton>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              direction="row"
              style={{ padding: '15px' }}
            >
              <DynamicButton
                bgcolor="secondary"
                variant="contained"
                onClick={handleDownloadImage}
              >
                {downloadImageTokenized}
              </DynamicButton>
              <DynamicButton
                bgcolor="primary"
                variant="contained"
                loading={blockDownload}
                onClick={handleDownloadAllImages}
              >
                {downloadNImagesTokenized}
              </DynamicButton>
            </Grid>
          </OverlayContainer>
        </OverlayWrapper>
      ) : null}
      <>
        <TkExtendedWrapper maxWidth="xl">
          <WrapperTeammatesHeader
            container
            direction="row" //@ts-ignore
            justifyContent="space-between"
            alignItems="center"
          >
            <TkTypography fontWeight="bold" fontFamily="Muli" fontSize={20}>
              {selectedChecklist?.title}
            </TkTypography>
            <TkIcon
              onClick={closeExtDrawer}
              style={{ cursor: 'pointer' }}
              className="fa fa-chevron-right"
              color="default"
              marginLeft={10}
              verticalAlign="baseline"
              fontSize="small"
            />
          </WrapperTeammatesHeader>
          <WrapperTeammatesList>
            <WrapperBaseListItem>
              {list.map((item) =>
                item.is_subtitle === 1 ? (
                  <Grid
                    container
                    alignItems="center"
                    direction="row"
                    marginTop="10px"
                  >
                    <TkTypography
                      fontSize={16}
                      fontWeight="bold"
                      fontFamily="Lato"
                    >
                      {item.title}
                    </TkTypography>
                  </Grid>
                ) : (
                  <ChecklistWrapper container>
                    <ChecklistSubWrapper
                      container
                      direction="row"
                      color={item.is_highlighted === 1 ? 'highlight' : ''}
                    >
                      <Grid>
                        <TkCheckBox
                          checked={item.checked === 1 ? true : false}
                          name="check-alert-assignee"
                          style={{ paddingLeft: 0 }}
                        />
                      </Grid>
                      <ChecklistDetail>
                        {item.image_url ? (
                          <ImagePreviewSmall
                            style={{ cursor: 'pointer' }}
                            src={item.image_url}
                            onClick={() =>
                              handleSelectedImage(item.id, 0, item.image_url)
                            }
                          />
                        ) : (
                          ''
                        )}
                        <TkTypography
                          fontFamily="Lato"
                          fontWeight="normal"
                          fontSize="14px"
                          style={{
                            textDecoration:
                              item.checked === 1 ? 'line-through' : 'none',
                          }}
                          marginLeft={1}
                        >
                          {item.photo_required === 1 ? (
                            <TkTooltip
                              title="Photo required from Cleaner"
                              placement="top-start"
                            >
                              <TkIcon
                                className="fa fa-camera"
                                iconSize={18}
                                color="#6B7B8B"
                                marginRight={7}
                                verticalAlign="sub"
                              />
                            </TkTooltip>
                          ) : (
                            ''
                          )}
                          {item.title}
                        </TkTypography>
                      </ChecklistDetail>
                    </ChecklistSubWrapper>
                    {item.comment !== null || item.images.length !== 0 ? (
                      <Grid container>
                        <ChecklistWrapperFiller direction="row">
                          <Grid>
                            {item.user === null ? (
                              <IconAssigneeButton>
                                <TkIcon className="fa fa-user" iconSize={19} />
                              </IconAssigneeButton>
                            ) : item.user.photo_url === null ? (
                              <IconAssigneeButton>
                                {generateInitials(item.user.full_name)}
                              </IconAssigneeButton>
                            ) : (
                              <ImgAssignee src={item.user.photo_url} />
                            )}
                          </Grid>
                          <ChecklistDetailFill alignItems="center">
                            {item.images.map((img, index) =>
                              index < 2 ? (
                                <ImagePreviewSmall
                                  style={{ cursor: 'pointer' }}
                                  src={img.fullImageUrl}
                                  onClick={() =>
                                    handleSelectedImage(
                                      item.id,
                                      index,
                                      img.fullImageUrl
                                    )
                                  }
                                />
                              ) : item.images.length > 3 ? (
                                index === 2 ? (
                                  <MoreImages
                                    onClick={() => {
                                      handleSelectedImage(
                                        item.id,
                                        2,
                                        img.fullImageUrl
                                      );
                                    }}
                                  >
                                    <TkTooltip
                                      placement="top-start"
                                      title={t('andXMore', {
                                        count: item.images.length - 2,
                                      })}
                                    >
                                      <TkTypography
                                        height="100%"
                                        fontSize={14}
                                        color="primary"
                                        marginLeft="3px"
                                        sx={{
                                          overflow: 'hidden',
                                          whiteSpace:
                                            item.images.length - 2 > 99
                                              ? 'nowrap'
                                              : 'pre-line',
                                          textOverflow: 'ellipsis',
                                        }}
                                      >
                                        {t('andXMore', {
                                          count: item.images.length - 2,
                                        })}
                                      </TkTypography>
                                    </TkTooltip>
                                  </MoreImages>
                                ) : (
                                  ''
                                )
                              ) : (
                                <ImagePreviewSmall
                                  style={{ cursor: 'pointer' }}
                                  src={img.fullImageUrl}
                                  onClick={() =>
                                    handleSelectedImage(
                                      item.id,
                                      index,
                                      img.fullImageUrl
                                    )
                                  }
                                />
                              )
                            )}
                            <TkTypography
                              fontFamily="Lato"
                              fontWeight="normal"
                              fontSize="14px"
                              marginLeft={1}
                            >
                              {item.comment}
                            </TkTypography>
                          </ChecklistDetailFill>
                        </ChecklistWrapperFiller>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </ChecklistWrapper>
                )
              )}
            </WrapperBaseListItem>
          </WrapperTeammatesList>
        </TkExtendedWrapper>
        <TkListActions>
          {imageCount > 0 ? (
            <DynamicButton
              bgcolor="primary"
              variant="contained"
              loading={blockDownload}
              onClick={handleDownloadAllImages}
            >
              {downloadNImagesTokenized}
            </DynamicButton>
          ) : (
            <DynamicButton disabled />
          )}

          <DynamicButton
            variant="contained"
            bgcolor="primary"
            onClick={closeExtDrawer}
          >
            <TkTypography>{t('ok', { ns: 'common' })}</TkTypography>
          </DynamicButton>
        </TkListActions>
      </>
    </>
  );
};

export default TkTaskChecklistList;
