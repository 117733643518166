import React, { useCallback, useContext } from 'react';

import mixpanel from 'mixpanel-browser';
import { useRecoilValue } from 'recoil';

import { sessionState } from '@contexts/AuthContext/state';
import {
  MixpanelContext,
  IMixpanelContext,
  MIXPANEL_EVENT_ACTIONS,
  MIXPANEL_EVENT_NAMES_ORIGIN,
  MIXPANEL_PAGE_NAMES,
} from '@contexts/MixpanelContext';
import { debounce } from '@helpers/index';
import { useInitialize } from '@hooks/index';

const MixpanelProvider: React.FC = ({ children }) => {
  const env_check = process.env.NODE_ENV === 'production';
  // Enabling the debug mode flag is useful during implementation and testing.
  const debug_check = false;
  const session = useRecoilValue(sessionState);
  const initializeMixpanel = useCallback(() => {
    if (!process.env.REACT_APP_MIXPANEL_KEY) return;
    debug_check
      ? mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, { debug: true })
      : mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY);
  }, []);

  useInitialize(initializeMixpanel);
  const trackFunction = (name, props) => {
    try {
      mixpanel.track(name, props);
    } catch (error) {
      console.error(error);
    }
  };
  const trackDebounced = debounce(trackFunction, 1500);

  const actions: IMixpanelContext = {
    identify: (id) => {
      if (env_check || debug_check) {
        try {
          mixpanel.identify(id);
        } catch (error) {
          console.error(error);
        }
      }
    },
    setOnceProps: (props) => {
      if (env_check || debug_check) {
        try {
          mixpanel.people.set_once(props);
        } catch (error) {
          console.error(error);
        }
      }
    },
    reset: () => {
      if (env_check || debug_check) {
        try {
          mixpanel.reset();
        } catch (error) {
          console.error(error);
        }
      }
    },
    alias: (id) => {
      if (env_check || debug_check) {
        try {
          mixpanel.alias(id);
        } catch (error) {
          console.error(error);
        }
      }
    },
    track: (name, props, callback) => {
      if (env_check || debug_check) {
        try {
          session?.user?.id && mixpanel.identify(String(session.user.id));

          mixpanel.track(name, props, callback);
        } catch (error) {
          console.error(error);
        }
      }
    },
    trackDebounced,
    consts: {
      MIXPANEL_EVENT_ACTIONS,
      MIXPANEL_EVENT_NAMES_ORIGIN,
      MIXPANEL_PAGE_NAMES,
    },
  };

  return (
    <MixpanelContext.Provider value={actions}>
      {children}
    </MixpanelContext.Provider>
  );
};

const useMixpanel = (): IMixpanelContext => useContext(MixpanelContext);

export { MixpanelProvider, useMixpanel };
