import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { ISimpleObject } from 'types/common.type';

import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { FormControl, Grid, Select } from '@mui/material';

import {
  InputDaily,
  OptionWrapper,
  SelectYearItem,
  SelectYearWrapper,
  useStyles,
  YearlyDayItem,
  YearlyDayItemChecked,
  YearlyDayPicker,
} from '../styles';
import { TkTypography } from '@components/index';

interface ITkTaskRepeatOptionsYearly {
  taskRepeat: ITaskRepeat;
  handleIntervalChange: (value: string) => void;
  setTaskRepeat: (taskRepeat: ITaskRepeat) => void;
  monthlyOnTheOptionsInterval: string[];
  monthlyOnTheOptionsPeriod: string[];
  errorMonth: IErrorMessageRepeatEvents;
}

const TkTaskRepeatOptionsYearly: React.FC<ITkTaskRepeatOptionsYearly> = ({
  taskRepeat,
  handleIntervalChange,
  setTaskRepeat,
  monthlyOnTheOptionsInterval,
  monthlyOnTheOptionsPeriod,
  errorMonth,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [monthOptionList, setMonthOptionList] = useState<ISimpleObject[]>([
    { id: 0, value: 'january', checked: false },
    { id: 1, value: 'february', checked: false },
    { id: 2, value: 'march', checked: false },
    { id: 3, value: 'april', checked: false },
    { id: 4, value: 'may', checked: false },
    { id: 5, value: 'june', checked: false },
    { id: 6, value: 'july', checked: false },
    { id: 7, value: 'august', checked: false },
    { id: 8, value: 'september', checked: false },
    { id: 9, value: 'october', checked: false },
    { id: 10, value: 'november', checked: false },
    { id: 11, value: 'december', checked: false },
  ]);

  const handleCheckYearlyItem = (month: ISimpleObject) => {
    const oL: number[] = [];
    taskRepeat.yearlyMonthRepetition.forEach((item) => oL.push(item));

    if (!month.checked) {
      oL.push(month.id + 1);
      if (taskRepeat.yearlyOnTheRepetition.length === 0)
        setTaskRepeat({
          ...taskRepeat,
          yearlyMonthRepetition: oL,
          yearlyOnTheRepetition: ['first', 'sunday'],
        });
      else setTaskRepeat({ ...taskRepeat, yearlyMonthRepetition: oL });
    } else {
      const nL = oL.filter((n) => {
        if (n !== month.id + 1) return n;
      });
      setTaskRepeat({ ...taskRepeat, yearlyMonthRepetition: nL });
    }

    const nL = monthOptionList.map((i) => {
      if (i.id === month.id) i.checked = !i.checked;
      return i;
    });

    setMonthOptionList(nL);
  };

  const handleYearOnTheSelection = (value: any, flag: number) => {
    const oL: string[] = [];
    taskRepeat.yearlyOnTheRepetition.forEach((item) => oL.push(item));
    oL[flag] = value;
    setTaskRepeat({ ...taskRepeat, yearlyOnTheRepetition: oL });
  };

  return (
    <>
      <OptionWrapper>
        {t('every', { ns: 'common' })}
        <InputDaily
          type="number"
          value={taskRepeat.interval}
          onChange={(e) => handleIntervalChange(e.target.value)}
        />
        {t('yearsIn', { ns: 'common' })}:
      </OptionWrapper>
      <OptionWrapper
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid direction="column">
          <YearlyDayPicker>
            {monthOptionList.map((month) =>
              month.checked ? (
                <YearlyDayItemChecked
                  onClick={() => handleCheckYearlyItem(month)}
                >
                  {t(`months.${month.value}`, { ns: 'common' }).substring(0, 3)}
                </YearlyDayItemChecked>
              ) : (
                <YearlyDayItem onClick={() => handleCheckYearlyItem(month)}>
                  {t(`months.${month.value}`, { ns: 'common' }).substring(0, 3)}
                </YearlyDayItem>
              )
            )}
          </YearlyDayPicker>
          {errorMonth.errors.error && (
            <TkTypography
              fontSize={'14px'}
              fontFamily="Lato"
              color="red"
              sx={{ textDecoration: 'overline' }}
            >
              {t(errorMonth.errors.message, { ns: 'events' })}:
            </TkTypography>
          )}
        </Grid>
        <SelectYearWrapper direction="column">
          <TkTypography
            fontSize={16}
            fontFamily="Lato"
            color="default"
            fontWeight="bold"
          >
            {t('onThe', { ns: 'common' })}:
          </TkTypography>
          <SelectYearItem>
            <FormControl className={classes.formControl}>
              <Select
                native
                fullWidth
                variant="standard"
                labelId="select-repeat-task"
                id="select-repeat-task"
                value={taskRepeat.yearlyOnTheRepetition[0]}
                onChange={(e) => handleYearOnTheSelection(e.target.value, 0)}
                IconComponent={ExpandMoreIcon}
                color="primary"
              >
                {monthlyOnTheOptionsPeriod.map((i) => (
                  <option value={i.toLowerCase()} key={i.toLowerCase()}>
                    {t(`positionInterval.${i}`, { ns: 'common' })}
                  </option>
                ))}
                <option disabled>───────────────</option>
                <option value="last">
                  {t('positionInterval.last', { ns: 'common' })}
                </option>
              </Select>
            </FormControl>
          </SelectYearItem>
          <SelectYearItem>
            <FormControl className={classes.formControl}>
              <Select
                native
                fullWidth
                variant="standard"
                labelId="select-repeat-task"
                id="select-repeat-task"
                value={taskRepeat.yearlyOnTheRepetition[1]}
                onChange={(e) => handleYearOnTheSelection(e.target.value, 1)}
                IconComponent={ExpandMoreIcon}
                color="primary"
              >
                {monthlyOnTheOptionsInterval.map((i) => (
                  <option value={i.toLowerCase()} key={i.toLowerCase()}>
                    {t(`weekdays.${i}`, { ns: 'common' })}
                  </option>
                ))}
                <option disabled>───────────────</option>
                <option value="day">{t('day', { ns: 'common' })}</option>
              </Select>
            </FormControl>
          </SelectYearItem>
        </SelectYearWrapper>
      </OptionWrapper>
    </>
  );
};

export default TkTaskRepeatOptionsYearly;
