import common from './common';
import app from './app';
import chat from './chat';
import daysOnboarding from './daysOnboarding';
import blockResponsive from './blockResponsive';
import bookingWidget from './bookingWidget';
import calendar from './calendar';
import dates from './dates';
import demoCall from './demoCall';
import events from './events';
import footer from './footer';
import integrationsPage from './integrationsPage';
import modalAlert from './modalAlert';
import notifications from './notifications';
import offers from './offers';
import phoneVerification from './phoneVerification';
import teamsPage from './teamsPage';
import unavailableFeature from './unavailableFeature';
import webOnboarding from './webOnboarding';
import profile from './profile';
import customers from "./customers"
import days from './days';

export default {
  app,
  blockResponsive,
  bookingWidget,
  customers,
  calendar,
  chat,
  common,
  dates,
  days,
  daysOnboarding,
  demoCall,
  events,
  footer,
  integrationsPage,
  modalAlert,
  notifications,
  offers,
  phoneVerification,
  teamsPage,
  unavailableFeature,
  webOnboarding,
  profile,
};
