import React, { useState } from 'react';

import { Grid, InputLabel, FormControl, Box } from '@mui/material';

import { SettingsContent, SettingsTitle, SettingsActions } from './styles';
import TkSelect from '@components/TkSelect';
import { TkTypography, DynamicButton, TkSwitch } from '@components/index';
import { SessionUserSettings } from '@contexts/AuthContext/types/session';
import { useUserSettings } from '@hooks/index';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

interface ISettings {
  handleCloseModal: () => void;
}
const SettingsModal = (props: ISettings): JSX.Element => {
  const { userSettings, handleSettingsUpdate: onSettingsSave } =
    useUserSettings();
  const [settings, setSettings] =
    useState<Partial<SessionUserSettings>>(userSettings);

  return (
    <>
      <SettingsTitle disableTypography>
        <TkTypography variant="h2">Calendar Settings</TkTypography>
      </SettingsTitle>
      <SettingsContent dividers>
        <Box mb={6}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  Preferred time format
                </InputLabel>
                <TkSelect
                  native
                  IconComponent={KeyboardArrowDownIcon}
                  value={settings.time_format}
                  onChange={(event) => {
                    setSettings({
                      ...settings,
                      time_format: event.currentTarget.value as 'AMPM' | '24',
                    });
                  }}
                >
                  <option value="AMPM">AM-PM</option>
                  <option value="24">24 Hours</option>
                </TkSelect>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth>
                <InputLabel shrink htmlFor="age-native-label-placeholder">
                  First day of the week
                </InputLabel>
                <TkSelect
                  IconComponent={KeyboardArrowDownIcon}
                  native
                  value={settings.first_day}
                  onChange={(event) => {
                    setSettings({
                      ...settings,
                      first_day: event.currentTarget.value as
                        | 'sunday'
                        | 'monday',
                    });
                  }}
                >
                  <option value="sunday">Sunday</option>
                  <option value="monday">Monday</option>
                </TkSelect>
              </FormControl>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={6}>
          <Grid item xs={12} md={6}>
            <Box mb={6}>
              <TkTypography variant="h5">Collapse projects</TkTypography>
              <TkTypography variant="subtitle1">
                In month view, collapse projects when there are more than 3 per
                day
              </TkTypography>
            </Box>
            <TkSwitch
              checked={settings.collapse_projects}
              onChange={() => {
                setSettings({
                  ...settings,
                  collapse_projects: !settings.collapse_projects,
                });
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box mb={6}>
              <TkTypography variant="h5">Projects In-progress</TkTypography>
              <TkTypography variant="subtitle1">
                Show animations for projects In-progress
              </TkTypography>
            </Box>
            <TkSwitch
              checked={settings.project_in_progress}
              onChange={() => {
                setSettings({
                  ...settings,
                  project_in_progress: !settings.project_in_progress,
                });
              }}
            />
          </Grid>
        </Grid>
      </SettingsContent>
      <SettingsActions>
        <Grid container>
          <Grid item xs={6}>
            <DynamicButton
              color="primary"
              onClick={() => props.handleCloseModal()}
            >
              Cancel
            </DynamicButton>
          </Grid>
          <Grid item container xs={6} justifyContent="flex-end">
            <DynamicButton
              variant="contained"
              bgcolor="primary"
              onClick={() => {
                onSettingsSave(settings);
                props.handleCloseModal();
              }}
            >
              Save
            </DynamicButton>
          </Grid>
        </Grid>
      </SettingsActions>
    </>
  );
};

export default SettingsModal;
