export default {
  pleaseEnterYourPhoneNumber: 'Please enter your phone number',
  weNeedItInCaseYourLoseAccount:
    'We need it in case you lose access to your account.',
  wontShareWithAnyone: 'Don’t worry, we won’t share it with anyone!',
  addPhoneNumber: 'Add phone number',
  verifyPhoneNumber: 'Verify your phone number',
  weSentYouASmsMessage:
    'We sent you a message with a 6 digit-code to <0>+{{number}}</0>',
  pleaseEnterBelow: 'Please enter it below:',
  phoneNumberVerified: 'Phone number verified successfully',
  phoneRequired: 'Please insert a valid phone number',
  phoneVerifiedSuccessfully: 'Phone number verified successfully',
  errorToVerifyPhoneNumber: 'Error to verify phone number',
  pleaseEnterYourPhoneVerification: 'Please enter your phone number',
  pleaseEnterAValidPhoneNumber: 'Please enter a valid phone number',
  weSentYouASmsMessageFormatted:
    'We sent you a message with a 6 digit-code to <0>{{number}}</0>',
  validatingPhoneNumberPleaseWait: 'Validating phone number, please wait',
};
