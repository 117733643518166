export default {
  tkModalAlertIntegrated:
    "Cette tâche provient d'un calendrier externe et sa date ne peut pas être modifiée",
  tkModalAlertRepeat:
    "Il s'agit d'une tâche répétitive. Elle ne peut pas être déplacée pour une autre date",
  tkModalAlertTurnoverbnb:
    "Cette tâche provient de Turno, le/la client(e) ne peut pas être modifié(e)",
  tkModalAlertInProgress:
    "Vous ne pouvez pas modifier les dates de tâches en cours ou terminées",
  tkModalAlertWontReflectOnTurnoverBNB:
    "Toute modification de cette tâche n'apparaîtra pas sur Turno",
  alerts: {
    sendNotifications: "Envoyer des notifications?",
    doYouWantToNotify: "Voulez-vous notifier?",
    doNotShowAgainAndSendAllNotifications: "Ne plus afficher et envoyer toutes les notifications",
  }
};
