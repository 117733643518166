import React from 'react';

import { useTranslation } from 'react-i18next';
import { useRecoilState } from 'recoil';

import { Grid, Box, FormControlLabel } from '@mui/material';

import { ChecklistSelectedItem } from '@components/TkDrawerComponents/styles';
import { TkIcon, TkTooltip, TkCheckBox, TkTypography } from '@components/index';
import { eventChecklists } from '@recoilData/index';

interface TkChecklistSelectedListItemProps {
  checklist: IChecklistTask;
}

const TkChecklistSelectedListItem = ({
  checklist,
}: TkChecklistSelectedListItemProps): JSX.Element => {
  const { t } = useTranslation();
  const [checklistState, setChecklistState] = useRecoilState(
    eventChecklists(checklist.id)
  );

  const removeChecklist = () => {
    setChecklistState({ ...checklistState, checked: false });
  };

  const handleMandatoryCompletion = (isMandatory: boolean) => {
    setChecklistState({ ...checklistState, isMandatory });
  };

  return (
    <ChecklistSelectedItem container>
      <Grid container justifyContent="space-between">
        <Box mr={1} display="inline" width="90%" justifyContent="center">
          <TkTypography
            fontWeight="normal"
            fontFamily="Lato"
            fontSize={16}
            color="default"
          >
            {checklist.title}
          </TkTypography>
        </Box>
        <Box fontSize={16}>
          <TkIcon
            onClick={removeChecklist}
            className="fa fa-trash"
            color="#011F41"
            cursor="pointer"
          />
        </Box>
      </Grid>
      <Grid container>
        <FormControlLabel
          key={checklist.id}
          checked={checklistState.isMandatory}
          control={
            <TkCheckBox
              onChange={(_, checked) => handleMandatoryCompletion(checked)}
              checked={checklistState.isMandatory}
            />
          }
          label={
            <TkTypography
              fontSize={14}
              fontFamily="Lato"
              fontWeight="normal"
              color="rgba(1,31,65,0.8)"
            >
              {t('mandatoryCompletion', { ns: 'events' })}
              <TkTooltip
                title={`${t('mandatoryCompletionTooltip', { ns: 'events' })}`}
              >
                <TkIcon
                  marginLeft={10}
                  className="fa fa-info-circle"
                  color="#1565C0"
                  fontSize="inherit"
                  verticalAlign="text-top"
                />
              </TkTooltip>
            </TkTypography>
          }
        />
      </Grid>
    </ChecklistSelectedItem>
  );
};

export default TkChecklistSelectedListItem;
