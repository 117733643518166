import React from 'react';
import  TkTypography from '@components/TkTypography';
import styled from 'styled-components';

export interface TkFormError {
  errorMessage: string;
}

const ScTkFormError = styled(TkTypography)`
  color: #ff3b30;
`;

export const TkFormError = ({
  errorMessage,
  ...props
}: TkFormError): JSX.Element => {
  return <ScTkFormError {...props}>{errorMessage}</ScTkFormError>;
};
