import React from 'react';
import { TkTypography } from '@components/index';
import { BookingModalContentStart } from '../styles';
import { SNAPSHOTS_PAGE_NAMES, useCrazyEggTrackByName } from '@hooks/index';
import { useTranslation } from 'react-i18next';
const StepTwo = () => {
  useCrazyEggTrackByName(SNAPSHOTS_PAGE_NAMES.ON_BOARDING_ABOUT_2);
  const { t } = useTranslation();
  return (
    <>
      <TkTypography
        fontFamily="Muli"
        fontSize={20}
        color="#061F41"
        align="left"
        letterSpacing={0}
        fontWeight="bold"
      >
        {t('make_it_your_own', { ns: 'bookingWidget' })}
      </TkTypography>

      <BookingModalContentStart>
        <li>
          <TkTypography
            fontFamily="Lato"
            fontSize={16}
            color="#011F41"
            align="left"
            letterSpacing={0}
          >
            {t('add_your_services_prices_and_times', { ns: 'bookingWidget' })}
          </TkTypography>
        </li>
        <li>
          <TkTypography
            fontFamily="Lato"
            fontSize={16}
            color="#011F41"
            align="left"
            letterSpacing={0}
          >
            {t('get_notified_via_email_and_sms', { ns: 'bookingWidget' })}
          </TkTypography>
        </li>
        <li>
          <TkTypography
            fontFamily="Lato"
            fontSize={16}
            color="#011F41"
            align="left"
            letterSpacing={0}
          >
            {t('customize_your_booking_widget', { ns: 'bookingWidget' })}
          </TkTypography>
        </li>
      </BookingModalContentStart>
    </>
  );
};

export { StepTwo };
