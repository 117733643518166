import { atom } from 'recoil';
import { getBookingBusinessId } from '@services/apiBooking';

const hasBusinessInfoChanged = atom<boolean>({
  key: 'hasBusinessInfoChanged',
  default: false,
});

const editPreviewOptionButton = atom<boolean>({
  key: 'editPreviewOptionButton',
  default: false,
});

const editPreviewOptionObject = atom<IBookingEditPreviewOption>({
  key: 'editPreviewOptionObject',
  default: {
    index: 0,
    option: {},
  },
});

const editPreviewQuestionObject = atom<IBookingEditPreviewOption>({
  key: 'editPreviewQuestionObject',
  default: {
    index: 0,
    option: {},
  },
});

const editPreviewQuestionButton = atom<boolean>({
  key: 'editPreviewQuestionButton',
  default: false,
});

const isFirstTimeSetup = atom<boolean>({
  key: 'isFirstTimeSetup',
  default: getBookingBusinessId() == undefined,
  // default:  true
});

const isFirstAccessServices = atom<boolean>({
  key: 'isFirstAccessServices',
  default: true,
  // default:  true
});

const isFirstTimeBookingTour = atom<IBookingTourControll>({
  key: 'isFirstTimeBookingTour',
  default: {
    openTour: false,
    openSideBar: false,
    openDraftTour: false,
    openServiceTab: false,
    openServiceTour: false,
    openServiceDraft: false,
    stepIndex: 0,
  },
});

const selectedSubMenuOption = atom<string>({
  key: 'selectedSubMenuOption',
  default: 'services',
});

const selectedCustomization = atom<IBookingCustomization>({
  key: 'selectedCustomization',
  default: {
    id: 0,
    logo: [],
    newLogo: [],
    primaryColor: '#4952a9',
    headerColor: '#4952a9',
    backgroundColor: '#ffffff',
  },
});

const bookingBusiness = atom<IBookingBusiness>({
  key: 'bookingBusiness',
  default: {
    id: 0,
    name: '',
    user_id: 0,
    address: {
      id: 0,
      address: '',
      city: { name: '' },
      complement: '',
      state: { abbr: '', name: '' },
      country: { abbr: '', name: '' },
      zip_code: '',
    },
    emails: [],
    categories: undefined,
    phones: [],
    schedules: { days: {} },
    services: undefined,
    socialNetworks: undefined,
  },
});

const bookingBusinessCategory = atom<IBookingBusinessCategories>({
  key: 'bookingBusinessCategory',
  default: {
    id: 0,
    name: '',
  },
});

const isBusinessNameChanged = atom<boolean>({
  key: 'isBusinessNameChanged',
  default: false,
});

const bookingBusinessServices = atom<IBookingBusinessServices[]>({
  key: 'bookingBusinessServices',
  default: [],
});

const bookingBusinessFaqs = atom<IBookingBusinessFaq[]>({
  key: 'bookingBusinessFaqs',
  default: [
    {
      id: 0,
      answer: '',
      business_id: 0,
      placement: 0,
      question: '',
    },
  ],
});

const businessSetupError = atom<IBookingSetupErrors>({
  key: 'businessSetupError',
  default: {
    category: false,
    businessInfo: {
      address: {
        address: false,
        city: false,
        state: false,
        zip_code: false,
      },
      name: false,
      emails: false,
      phones: false,
    },
    businessHours: false,
    businessServices: false,
  },
});

const draftBookingBusiness = atom<IBookingBusiness>({
  key: 'draftBookingBusiness',
  default: {
    id: 0,
    name: '',
    user_id: 0,
    address: {
      id: 0,
      address: '',
      city: { name: '' },
      complement: '',
      state: { abbr: '', name: '' },
      country: { abbr: '', name: '' },
      zip_code: '',
    },
    emails: [],
    categories: undefined,
    phones: [],
    schedules: { days: {} },
    services: undefined,
    socialNetworks: undefined,
  },
});

export {
  businessSetupError,
  isFirstAccessServices,
  isBusinessNameChanged,
  bookingBusiness,
  isFirstTimeSetup,
  isFirstTimeBookingTour,
  draftBookingBusiness,
  selectedSubMenuOption,
  hasBusinessInfoChanged,
  bookingBusinessCategory,
  bookingBusinessServices,
  selectedCustomization,
  bookingBusinessFaqs,
  editPreviewOptionButton,
  editPreviewOptionObject,
  editPreviewQuestionObject,
  editPreviewQuestionButton,
};
