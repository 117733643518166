import React, { useEffect, useState } from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { object, string } from 'yup';

import { Grid } from '@mui/material';

import ChosenOptionWrapper from './ChosenOptionWrapper';
import { IOptionStruct } from './types.type';
import { HelpText } from '@components/TkLogin/TkForgotPasswordModal/styles';
import {
  IOptionsSelect,
  SetupStatesEnum,
} from '@components/TkModalComponents/types.type';
import { TkTypography, TkInput, DynamicButton } from '@components/index';
import { LocalStorage } from '@consts/index';
import { SessionData } from '@contexts/AuthContext/types/session';
import { MIXPANEL_EVENTS, useAuth, useMixpanel } from '@contexts/index';
import { apiMethods } from '@services/index';

interface NewTeamContentProps {
  name: string;
  teamName: string;
  userData: SessionData;
  options: Record<string, IOptionStruct>;
  selectedOption: IOptionsSelect;
  setTeamName(name: string): void;
  setSelectedOption(option: IOptionsSelect): void;
}

const NewTeamContent = ({
  name,
  userData,
  teamName,
  setTeamName,
  setSelectedOption,
  options,
  selectedOption,
}: NewTeamContentProps): JSX.Element => {
  const { t } = useTranslation();
  const { createNewTeam } = apiMethods;
  const [newTeamErrors, setNewTeamErrors] = useState<string[]>([]);
  const { newTeamLogin } = useAuth();

  const { track } = useMixpanel();

  useEffect(() => {
    track(MIXPANEL_EVENTS.WELCOME_TO_TASKBIRD_SET_UP_A_NEW_TEAM);
  }, []);

  const handleCreateNewTeam = async () => {
    const schema = object({
      teamName: string().required('teamName'),
    });

    try {
      await schema.validate({ teamName });
      const { data } = await createNewTeam(teamName, userData.user.id);

      localStorage.setItem(LocalStorage.ACCOUNT_ID, data.id);
      setSelectedOption(SetupStatesEnum.ALL_DONE_NEW_TEAM);

      setTimeout(async () => {
        await newTeamLogin({ ...userData, account: data });
      }, 3000);
    } catch (err) {
      setNewTeamErrors(err.errors as string[]);
    }
  };

  return (
    <ChosenOptionWrapper
      options={options}
      selectedOption={selectedOption}
      name={name}
    >
      <Grid container direction="column">
        <TkTypography
          fontFamily="Lato"
          fontWeight="bold"
          fontSize="16px"
          margin="50px 0 10px"
        >
          {t('welcomeSetupModal.letsAddYourTeamName')}
        </TkTypography>
        <TkInput
          value={teamName}
          margin="10px 0"
          onChange={(e) => setTeamName(e.target.value)}
          fullWidth
          placeholder={`${t('welcomeSetupModal.teamName')}`}
          error={newTeamErrors.includes('teamName')}
        />
        {newTeamErrors.includes('teamName') && (
          <HelpText style={{ marginBottom: 5 }}>
            <TkTypography
              fontFamily="Lato"
              fontSize="12px"
              fontWeight="bold"
              marginTop="1px"
              color="#FF3B30"
            >
              {t('welcomeSetupModal.teamNameRequired', { ns: 'app' })}
            </TkTypography>
          </HelpText>
        )}
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        marginTop="50px"
      >
        <TkTypography fontFamily="Lato" fontWeight="bold" fontSize="16px">
          <Trans i18nKey="welcomeSetupModal.didYouReceiveAnInvite">
            <button
              type="button"
              onClick={() => setSelectedOption(SetupStatesEnum.JOIN_TEAM)}
              style={{
                all: 'unset',
                fontWeight: 'bold',
                color: '#4952A9',
                cursor: 'pointer',
              }}
            />
          </Trans>
        </TkTypography>
        <DynamicButton
          variant="contained"
          bgcolor="#4952A9"
          onClick={handleCreateNewTeam}
        >
          {t('next', { ns: 'common' })}
        </DynamicButton>
      </Grid>
    </ChosenOptionWrapper>
  );
};

export default NewTeamContent;
