import React, { useState, forwardRef, useEffect } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Grid } from '@material-ui/core';
import TkIcon from '@components/TkIcon';
import {
  Divider,
  TimeItem,
  TimeSelector,
  TimeSelectorItem,
  TimeSelectorItemSelected,
  TimeWrapper,
  TkHourPickerWrapper,
} from './styles';
import { DateTime } from 'luxon';

const ActivatorSlot = styled.div`
  width: 100%;
`;
const ComponentInput = forwardRef((props: any, ref) => {
  const activatorClone = React.cloneElement(props.activator, {
    value: props.value,
  });
  return (
    <ActivatorSlot
      onClick={() => {
        props.setCurrentView('false');
        props.onClick();
      }}
    >
      {activatorClone}
    </ActivatorSlot>
  );
});

const TkHourPicker = (props: any) => {
  const [currentView, setCurrentView] = useState('false');
  const { startDate, handleDateChange, activator, format24h } = props;
  const [option, setOption] = useState<string>(() => {
    const date = startDate ?? new Date();

    return DateTime.fromJSDate(date).toFormat('a').toUpperCase();
  });
  const [lHours, setLHours] = useState<string>(() => {
    const date = DateTime.fromJSDate(startDate ?? new Date());

    return format24h == '24' ? date.toFormat('HH') : date.toFormat('hh');
  });
  const [lMinutes, setLMinutes] = useState<string>(() => {
    return startDate ? startDate.getMinutes() : 0;
  });

  useEffect(() => {
    const date = DateTime.fromJSDate(startDate ?? new Date());
    const newHour = getDateAmPm();

    if (newHour.getTime() !== date.toJSDate().getTime()) {
      setLHours(format24h == '24' ? date.toFormat('HH') : date.toFormat('hh'));
      setLMinutes(date.toFormat('mm'));
      setOption(date.toFormat('a'));
    }
  }, [startDate]);

  const CustomHeader = (props: any) => {
    const [month, year] = props.title.split(' ');
    return (
      <Grid
        container
        justify="space-between"
        className="react-datepicker__custom-header"
      >
        <Grid item xs={2}>
          <span onClick={props.decreaseMonth}>
            <TkIcon className="fas fa-chevron-left" color="primary" />
          </span>
        </Grid>
        <Grid item xs={8}>
          {currentView === 'false' ? (
            <>
              <span onClick={() => setCurrentView('month')}>{month}</span>&nbsp;
            </>
          ) : (
            ''
          )}
          <span onClick={() => setCurrentView('year')}>{year}</span>
        </Grid>
        <Grid item xs={2}>
          <span onClick={props.increaseMonth}>
            <TkIcon className="fas fa-chevron-right" color="primary" />
          </span>
        </Grid>
      </Grid>
    );
  };

  const viewChangeMapping: any = {
    year: 'month',
    month: 'false',
  };

  const handleViewChangeBasedOnCurrentView = (view: string): string => {
    return viewChangeMapping[view] ?? undefined;
  };

  const handleOption = (op: string) => {
    const newDate = getDateAmPm(op);
    handleDateChange(newDate);
    setOption(op);
  };

  const getDateAmPm = (
    op: string = option,
    hours: any = lHours,
    minutes: any = lMinutes
  ) => {
    const luxonStart = DateTime.fromJSDate(startDate ?? new Date());
    const newHour = DateTime.fromFormat(`${hours}:${minutes} ${op}`, 'h:mm a');
    const newDate = newHour
      .set({
        year: luxonStart.year,
        month: luxonStart.month,
        day: luxonStart.day,
      })
      .toJSDate();
    return newDate;
  };

  const handle24Hour = (v: any, value: any) => {
    if (v == '-1' || value > 23) {
      value = 23;
    }

    setLHours(value.toString());

    const luxonStart = DateTime.fromJSDate(startDate ?? new Date());
    const newHour = DateTime.fromFormat(`${value}:${lMinutes}`, 't');
    const newDate = newHour
      .set({
        year: luxonStart.year,
        month: luxonStart.month,
        day: luxonStart.day,
      })
      .toJSDate();

    handleDateChange(newDate);
  };

  const handleAMPMHour = (v: any, value: any) => {
    if (v == '-1' || value > 12) {
      value = 12;
    }
    const newDate = getDateAmPm(option, value);
    handleDateChange(newDate);
    setLHours(value.toString());
  };

  const handleHourChange = (v: any) => {
    let value: number = parseInt(v);
    value = Number.isNaN(value) ? 0 : value;
    if (format24h === '24') {
      return handle24Hour(v, value);
    }
    return handleAMPMHour(v, value);
  };

  const handleMinuteChange = (v: any) => {
    let value: number = parseInt(v);
    //avoiding type error for luxon
    value = Number.isNaN(value) ? 0 : value;
    const luxonStart = DateTime.fromJSDate(startDate ?? new Date());

    if (value <= -1 || value >= 60) {
      value = 59;
    }

    if (value >= 0 && value <= 9) {
      setLMinutes('0' + value);
    } else {
      setLMinutes(value.toString());
    }

    const newMinute = DateTime.fromFormat(value.toString(), 'm');

    const hour =
      format24h == 'AMPM' ? getDateAmPm().getHours() : parseInt(lHours);

    const newDate = newMinute
      .set({
        day: luxonStart.day,
        month: luxonStart.month,
        year: luxonStart.year,
        hour: hour,
      })
      .toJSDate();

    handleDateChange(newDate);
  };

  useEffect(() => {
    if (typeof currentView !== 'undefined') {
      setCurrentView(handleViewChangeBasedOnCurrentView(currentView));
    }
  }, [startDate]);

  return (
    <TkHourPickerWrapper>
      <DatePicker
        {...props}
        selected={startDate}
        timeFormat="HH:mm"
        onChange={async (date: any, props: any) => {}}
        customInput={
          props.activator && (
            <ComponentInput
              setCurrentView={(value: string) => setCurrentView(value)}
              activator={activator}
            />
          )
        }
        renderCustomHeader={(props: any) => {
          if (currentView !== 'year') {
            const title = DateTime.fromJSDate(props.date).toFormat('LLLL y');
            return (
              <CustomHeader
                title={title}
                increaseMonth={props.increaseMonth}
                decreaseMonth={props.decreaseMonth}
              />
            );
          }
          return '';
        }}
        showPopperArrow={false}
        showMonthYearPicker={false}
        showYearPicker={false}
        adjustDateOnChange={false}
        shouldCloseOnSelect={currentView == 'false'}
        yearItemNumber={15}
        showTimeInput
        customTimeInput={
          <Grid container justify="center" alignItems="center">
            <TimeWrapper container>
              <TimeItem
                type="number"
                value={lHours}
                disableUnderline={true}
                onChange={(e) => handleHourChange(e.target.value)}
              />
              <Divider>:</Divider>
              <TimeItem
                type="number"
                value={lMinutes}
                disableUnderline={true}
                onChange={(e) => handleMinuteChange(e.target.value)}
              />
              {format24h === '24' ? (
                ''
              ) : (
                <TimeSelector style={{ marginLeft: '20px' }}>
                  {option === 'AM' ? (
                    <TimeSelectorItemSelected id="top-item">
                      AM
                    </TimeSelectorItemSelected>
                  ) : (
                    <TimeSelectorItem
                      id="top-item"
                      onClick={() => handleOption('AM')}
                    >
                      AM
                    </TimeSelectorItem>
                  )}
                  {option === 'PM' ? (
                    <TimeSelectorItemSelected id="bottom-item">
                      PM
                    </TimeSelectorItemSelected>
                  ) : (
                    <TimeSelectorItem
                      id="bottom-item"
                      onClick={() => handleOption('PM')}
                    >
                      PM
                    </TimeSelectorItem>
                  )}
                </TimeSelector>
              )}
            </TimeWrapper>
          </Grid>
        }
      />
    </TkHourPickerWrapper>
  );
};

export default TkHourPicker;
