import React, { useState, useEffect } from 'react';

import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import {
  useRecoilState,
  useRecoilValue,
  useSetRecoilState,
  useResetRecoilState,
} from 'recoil';

import {
  ExpandMore as ExpandMoreIcon,
  AccessTime as AccessTimeIcon,
  CalendarToday as CalendarTodayIcon,
} from '@mui/icons-material';
import { Box, Grid, FormGroup } from '@mui/material';

import TkAssigneeSelectedListItem from './components/TkAssigneeSelectedListItem';
import TkChecklistSelectedListItem from './components/TkChecklistSelectedListItem';
import TkDatePickerActivator from './components/TkDatePickerActivator';
import { verifyNotificationSetting, isTimeLess } from './helpers';
import {
  TkRadio,
  Selector,
  BaseGrid,
  DateGroup,
  DividerCustom,
  TkTaskWrapper,
  TkNewTaskActions,
  RadioGroupCustom,
  FormControlLabelCustom,
} from './styles';
import { useSnackbar } from '@components/TkSnackbar/useSnackbar';
import {
  TkTypography,
  TkDragNDrop,
  TkDatePicker,
  TkHourPicker,
  DynamicButton,
  TkAssigneeAlertModal,
  TkTaskRepeatOptions,
  TkDrawerCustomerSelector,
  TkLocationSelector,
  TkNotesField,
  TkAssigneeButton,
  TkDrawerTaskNameInput,
} from '@components/index';
import {
  ApiResultsKind,
  EventTypeEnum,
  TaskFrequencyIntervalEnum,
} from '@consts/index';
import { useModal, useLoading, useAuth } from '@contexts/index';
import { generateFinalEventDate, addTimeToDate } from '@helpers/event';
import { getDateState } from '@helpers/index';
import { useDrawer, useUserSettings } from '@hooks/index';
import {
  event as eventRecoil,
  eventTitle,
  resetEvent,
  eventNotes,
  eventCustomer,
  eventLocation,
  eventTaskType,
  selectedEventId,
  eventImagesFile,
  addEventImagesFile,
  removeEventImagesFile,
  getSelectedEventAssignees,
  getSelectedEventChecklists,
  getSelectedEventAssigneesCount,
  eventEndDate,
  eventEndTime,
  eventStartDate,
  eventStartTime,
  eventEnd,
  eventStart,
} from '@recoilData/index';
import { eventMethods } from '@services/index';

const TaskType = {
  SINGLE_TASK: 'single-task',
  REPEAT: 'repeat',
} as const;

const TkNewTaskDrawer = (): JSX.Element => {
  const { t } = useTranslation();
  const {
    createNewEvent,
    generateApiEvent,
    createNewRepeatEvent,
    generateRepeatApiEvent,
  } = eventMethods;
  const { fireTError } = useSnackbar();
  const { userSettings } = useUserSettings();
  const { setupModal, handleCloseModal } = useModal();
  const { toggleShowLoading } = useLoading();

  const { userInfo } = useAuth();
  const {
    closeDrawers,
    closeExtDrawer,
    currentDrawer,
    callDrawer,
    types: { MainDrawersTypes, ExtendedDrawersTypes },
  } = useDrawer();
  const queryClient = useQueryClient();
  const event = useRecoilValue(eventRecoil);
  const customer = useRecoilValue(eventCustomer);
  const location = useRecoilValue(eventLocation);
  const setEventEnd = useSetRecoilState(eventEnd);
  const clearEvent = useResetRecoilState(resetEvent);
  const imagesFile = useRecoilValue(eventImagesFile);
  const [name, setName] = useRecoilState(eventTitle);
  const setEventStart = useSetRecoilState(eventStart);
  const [notes, setNotes] = useRecoilState(eventNotes);
  const setEventId = useSetRecoilState(selectedEventId);
  const [endDate, setEndDate] = useRecoilState(eventEndDate);
  const [endTime, setEndTime] = useRecoilState(eventEndTime);
  const addImageFile = useSetRecoilState(addEventImagesFile);
  const [taskType, setTaskType] = useRecoilState(eventTaskType);
  const checklists = useRecoilValue(getSelectedEventChecklists);
  const assignedUsers = useRecoilValue(getSelectedEventAssignees);
  const [startDate, setStartDate] = useRecoilState(eventStartDate);
  const [startTime, setStartTime] = useRecoilState(eventStartTime);
  const removeImageFile = useSetRecoilState(removeEventImagesFile);
  const assignedUsersCount = useRecoilValue(getSelectedEventAssigneesCount);

  const {
    main: { id: drawerId },
  } = currentDrawer;

  const [eventError, setEventError] = useState({
    taskName: { error: false, message: '' },
    startDate: { error: false, message: '' },
    startTime: { error: false, message: '' },
    endDate: { error: false, message: '' },
    endTime: { error: false, message: '' },
    monthRepeatTime: { error: false, message: '' },
  });

  const taskTypes = [
    {
      value: TaskType.SINGLE_TASK,
      label: t('singleTask', { ns: 'events' }),
    },
    {
      value: TaskType.REPEAT,
      label: t(TaskType.REPEAT, { ns: 'events' }),
    },
  ];

  const setErrorEvent = (field: string, value: boolean, message = ''): void => {
    setEventError({
      ...eventError,
      [field]: { error: value, message: message },
    });
  };

  const emptyEventData = () => {
    clearEvent();
    setStartDate(null);
    setStartTime(null);
    setEndDate(null);
    setEndTime(null);
  };

  const handleCloseTask = () => {
    closeDrawers();
    emptyEventData();
  };

  const handleTaskRepeat = (value: string) => {
    if (value === TaskType.REPEAT) {
      setEndDate(addTimeToDate(startDate, 1, 'YEAR'));
    } else {
      setEndDate(startDate);
    }

    setTaskType(value);
  };

  useEffect(() => {
    setEventStart(generateFinalEventDate(startDate, startTime));
    setEndDate(generateFinalEventDate(startDate, endTime));
  }, [startDate]);

  useEffect(() => {
    setEventStart(generateFinalEventDate(startDate, startTime));

    setEndTime(
      DateTime.fromJSDate(startTime ?? new Date())
        .plus({ hours: 2 })
        .toJSDate()
    );
  }, [startTime]);

  useEffect(() => {
    setEventEnd(generateFinalEventDate(endDate, endTime));
  }, [endDate, endTime]);

  useEffect(() => {
    if (drawerId !== MainDrawersTypes.NEW_TASK) emptyEventData();

    const timeNow = new Date();
    const startDateState = getDateState(DateTime.fromJSDate(startDate));

    if (startDateState === 'afterDate' || startDateState === 'sameDate') {
      setEndDate(startDate);
    }

    if (startDateState === 'beforeDate') {
      setEndDate(timeNow);
    }

    setStartTime(new Date(new Date().setHours(12, 0, 0, 0)));
    setEndTime(new Date(new Date().setHours(14, 0, 0, 0)));
  }, [drawerId]);

  const validateEvent = () => {
    let flag = false;
    let startIsPast = false;
    let endIsPast = false;

    if (name.length === 0) {
      setErrorEvent('taskName', true);
      flag = true;
    }

    if (startDate === null) {
      setErrorEvent('starDate', true, t('pleaseSelectADate', { ns: 'events' }));
      flag = true;
    } else {
      startIsPast = isTimeLess(startDate, 'day');
    }

    if (endDate === null) {
      setErrorEvent('endDate', true, t('pleaseSelectADate', { ns: 'events' }));
      flag = true;
    } else {
      endIsPast = isTimeLess(endDate, 'day');
    }

    if (startIsPast) {
      setErrorEvent(
        'endDate',
        true,
        t('startDateCantBeInThePast', { ns: 'events' })
      );
      flag = true;
    }

    if (endIsPast) {
      setErrorEvent(
        'endDate',
        true,
        t('startDateCantBeInThePast', { ns: 'events' })
      );
      flag = true;
    }

    if (startTime === null) {
      setErrorEvent(
        'startTime',
        true,
        t('pleaseSelectATime', { ns: 'events' })
      );
      flag = true;
    }

    if (endTime === null) {
      setErrorEvent('endTime', true, t('pleaseSelectATime', { ns: 'events' }));
      flag = true;
    }

    const allTimeSet = startTime !== null && endTime !== null;
    const isTaskTypeRepeat = taskType === TaskType.REPEAT;
    const isDateTheSame =
      startDate !== null && endDate !== null
        ? DateTime.fromJSDate(startDate).equals(DateTime.fromJSDate(endDate))
        : false;

    if (allTimeSet) {
      const startTimeIsPast = isTimeLess(startTime, 'hour');
      const endTimeIsPast = isTimeLess(endTime, 'hour');
      const startTimeIsPastMinute = isTimeLess(startTime, 'minute');
      const endTimeIsPastMinute = isTimeLess(endTime, 'minute');
      const isEndTimeLessOrEqual = endTime <= startTime;
      const isEndTimeLess = endTime < startTime;

      if (startIsPast && (startTimeIsPast || startTimeIsPastMinute)) {
        setErrorEvent(
          'startTime',
          true,
          t('endTimeCantBeInThePast', { ns: 'events' })
        );
        flag = true;
      }

      if (startIsPast && (endTimeIsPast || endTimeIsPastMinute)) {
        setErrorEvent(
          'endTime',
          true,
          t('endTimeCantBeInThePast', { ns: 'events' })
        );
        flag = true;
      }

      if (isDateTheSame && isTaskTypeRepeat) {
        setEventError({
          ...eventError,
          startDate: {
            error: true,
            message: t('startDateCantBeTheSameDay', { ns: 'events' }),
          },
          endDate: {
            error: true,
            message: t('endDateCantBeTheSameDay', { ns: 'events' }),
          },
        });
        flag = true;
      }

      if (
        isTaskTypeRepeat &&
        event.taskRepeat.frequency === TaskFrequencyIntervalEnum.YEARLY
      ) {
        if (
          addTimeToDate(startDate, event.taskRepeat.interval, 'YEAR') > endDate
        ) {
          setEventError({
            ...eventError,
            endDate: {
              error: true,
              message: t('endDateOutOfInterval', { ns: 'events' }),
            },
          });
          flag = true;
        }
      }

      if (isDateTheSame && isEndTimeLessOrEqual) {
        setEventError({
          ...eventError,
          startTime: {
            error: true,
            message: t('startTimeCantBeEqualEnd', { ns: 'events' }),
          },
          endTime: {
            error: true,
            message: t('endTimeCantBeBeforeStart', { ns: 'events' }),
          },
        });

        flag = true;
      }

      if (isDateTheSame && isEndTimeLess) {
        setErrorEvent(
          'endTime',
          true,
          t('endTimeCantBeBeforeStart', { ns: 'events' })
        );
        flag = true;
      }
    }
    return flag;
  };

  const showModalAlert = () => {
    const flag = validateEvent();

    if (!flag && assignedUsersCount > 0 && !verifyNotificationSetting()) {
      setupModal({
        content: (
          <TkAssigneeAlertModal
            eventTitle={name}
            handleCloseModal={handleCreateEvent}
            assignees={assignedUsers}
          />
        ),
        disableBackdropClick: true,
      });
    }

    if (!flag && assignedUsersCount === 0) {
      handleCreateEvent(false);
    }
  };

  const handleCreateEvent = (flagNotify: boolean) => {
    handleCloseModal();
    toggleShowLoading(true);

    const setEvent = (res) => {
      closeDrawers();
      emptyEventData();
      setEventId(res);
      queryClient.invalidateQueries('accountEvents');
    };

    const createEvent = {
      [TaskType.SINGLE_TASK]: async () => {
        const localEvent: IEvent = {
          ...event,
          start: generateFinalEventDate(startDate, startTime),
          end: generateFinalEventDate(endDate, endTime),
        };

        const newEvent = await generateApiEvent(
          localEvent,
          userInfo.account.id,
          flagNotify,
          checklists,
          assignedUsers
        );

        try {
          const { id } = await createNewEvent(newEvent);
          setEvent(id);
        } catch (error) {
          fireTError('errorCreatingTask', { ns: 'events' });
        }
        toggleShowLoading(false);
      },
      [TaskType.REPEAT]: async () => {
        const localEvent: IEvent = {
          ...event,
          start: generateFinalEventDate(startDate, startTime),
          end: generateFinalEventDate(endDate, endTime),
        };

        const newEvent = await generateRepeatApiEvent(
          localEvent,
          userInfo.account.id,
          flagNotify,
          checklists,
          assignedUsers
        );

        if (
          newEvent.frequency === TaskFrequencyIntervalEnum.YEARLY &&
          newEvent.months.length === 0
        ) {
          setErrorEvent(
            'monthRepeatTime',
            true,
            t('monthUnselectedError', { ns: 'events' })
          );
          toggleShowLoading(false);
          fireTError('errorCreatingTask', { ns: 'events' });
        } else {
          const { kind, firstId } = await createNewRepeatEvent(newEvent);

          toggleShowLoading(false);

          if (kind === ApiResultsKind.ERROR) {
            fireTError('errorCreatingTask', { ns: 'events' });
            return;
          }

          if (kind === ApiResultsKind.OK) {
            setEvent(firstId);
          }
        }
      },
    };

    handleCloseModal();
    toggleShowLoading(true);
    closeExtDrawer();
    createEvent[taskType]();
  };

  useEffect(() => {
    if (name.length > 0) setErrorEvent('taskName', false);
    if (startDate !== null) setErrorEvent('startDate', false);
    if (endDate !== null) setErrorEvent('endDate', false);
    if (startTime !== null) setErrorEvent('startTime', false);
    if (endTime !== null) setErrorEvent('endTime', false);
  }, [name, startDate, endDate, startTime, endTime]);

  const handleCustomer = () => {
    callDrawer({
      extended: {
        id: ExtendedDrawersTypes.NEW_TASK_CUSTOMER,
      },
    });
  };

  const handleLocation = () => {
    callDrawer({
      extended: {
        id: ExtendedDrawersTypes.NEW_TASK_LOCATION,
      },
    });
  };

  const handleChecklists = () => {
    callDrawer({
      extended: {
        id: ExtendedDrawersTypes.TASK_CHECKLIST,
        props: {
          eventType: EventTypeEnum.NEW,
        },
      },
    });
  };

  const handleAssignee = () => {
    callDrawer({
      extended: {
        id: ExtendedDrawersTypes.TASK_ASSIGNEE_LIST,
        props: {
          eventType: EventTypeEnum.NEW,
        },
      },
    });
  };

  return (
    <>
      <TkTaskWrapper>
        <BaseGrid container direction="column" marginTop="1rem">
          <TkDrawerTaskNameInput
            error={eventError.taskName.error}
            name={name}
            setName={setName}
          />
        </BaseGrid>
        <BaseGrid container direction="column">
          <TkTypography
            fontWeight="bold"
            fontFamily="Lato"
            fontSize={16}
            userselect="none"
          >
            {t('customerAndLocation', { ns: 'events' })}
          </TkTypography>
          <Grid container paddingTop="20px">
            <TkDrawerCustomerSelector
              customer={customer}
              handleClick={handleCustomer}
            />
          </Grid>
          <Grid container paddingTop="20px">
            <TkLocationSelector
              location={location}
              handleClick={handleLocation}
            />
          </Grid>
        </BaseGrid>
        <BaseGrid container direction="column">
          <TkTypography
            fontWeight="bold"
            fontFamily="Lato"
            fontSize={16}
            userselect="none"
          >
            {t('checklists', { ns: 'events' })}
          </TkTypography>
          <Grid container>
            {checklists.map((checklist) => (
              <TkChecklistSelectedListItem
                key={checklist.id}
                checklist={checklist}
              />
            ))}
          </Grid>
          <Selector onClick={handleChecklists}>
            <TkTypography
              fontWeight="bold"
              fontFamily="Lato"
              fontSize={16}
              color="primary"
              userselect="none"
            >
              + {t('checklists', { ns: 'events' })}
            </TkTypography>
          </Selector>
        </BaseGrid>
        <DividerCustom />
        <BaseGrid container>
          <RadioGroupCustom
            aria-label="task-type"
            name="task-type"
            value={taskType}
            onChange={(e) => handleTaskRepeat(e.target.value)}
          >
            {taskTypes.map((type) => (
              <FormControlLabelCustom
                key={`taskType-${type.value}`}
                value={type.value}
                control={<TkRadio />}
                label={type.label}
              />
            ))}
          </RadioGroupCustom>
          <DateGroup>
            <Box
              marginRight="10px"
              marginTop="15px"
              justifyContent="space-between"
              width="100%"
            >
              <Box>
                <TkDatePicker
                  startDate={startDate}
                  handleDateChange={setStartDate}
                  activator={
                    <TkDatePickerActivator
                      Placeholder={t('startDate', { ns: 'events' })}
                      StartDate={startDate}
                      ErrorForm={eventError.startDate.error}
                      ErrorFormMessage={eventError.startDate.message}
                      Format="LLL dd, y"
                      StartAdornment={CalendarTodayIcon}
                      EndAdornment={ExpandMoreIcon}
                    />
                  }
                />
              </Box>
              <Box marginTop="25px" width="100%">
                <TkDatePicker
                  startDate={endDate}
                  handleDateChange={setEndDate}
                  activator={
                    <TkDatePickerActivator
                      Placeholder={
                        taskType === TaskType.SINGLE_TASK
                          ? t('endDate', { ns: 'events' })
                          : t('endRepeat', { ns: 'events' })
                      }
                      StartDate={endDate}
                      ErrorForm={eventError.endDate.error}
                      ErrorFormMessage={eventError.endDate.message}
                      Format="LLL dd, y"
                      StartAdornment={CalendarTodayIcon}
                      EndAdornment={ExpandMoreIcon}
                    />
                  }
                />
              </Box>
            </Box>
            <Box marginLeft="10px" width="100%">
              <Box marginTop="15px" width="100%">
                <TkHourPicker
                  startDate={startTime}
                  handleDateChange={setStartTime}
                  format24h={userSettings.time_format}
                  activator={
                    <TkDatePickerActivator
                      Placeholder={t('startTime', { ns: 'events' })}
                      ErrorFormMessage={eventError.startTime.message}
                      StartDate={startTime}
                      ErrorForm={eventError.startTime.error}
                      Format="t"
                      StartAdornment={AccessTimeIcon}
                      EndAdornment={ExpandMoreIcon}
                    />
                  }
                />
              </Box>
              <Box marginTop="25px" width="100%">
                <TkHourPicker
                  startDate={endTime}
                  handleDateChange={setEndTime}
                  format24h={userSettings.time_format}
                  activator={
                    <TkDatePickerActivator
                      Placeholder={t('endTime', { ns: 'events' })}
                      StartDate={endTime}
                      ErrorFormMessage={eventError.endTime.message}
                      ErrorForm={eventError.endTime.error}
                      Format="t"
                      StartAdornment={AccessTimeIcon}
                      EndAdornment={ExpandMoreIcon}
                    />
                  }
                />
              </Box>
            </Box>
          </DateGroup>
          {taskType === TaskType.REPEAT && (
            <TkTaskRepeatOptions errors={eventError.monthRepeatTime} />
          )}
        </BaseGrid>
        <DividerCustom />
        <BaseGrid container direction="column">
          <TkTypography fontWeight="bold" fontFamily="Lato" fontSize={16}>
            {t('assignedTo', { ns: 'events' })}
          </TkTypography>
          {assignedUsers.map((assignee) => (
            <TkAssigneeSelectedListItem key={assignee.id} assignee={assignee} />
          ))}
          {assignedUsersCount === 0 ? (
            <TkAssigneeButton handleClick={handleAssignee} />
          ) : (
            <Selector onClick={handleAssignee}>
              <TkTypography
                fontWeight="bold"
                fontFamily="Lato"
                fontSize={16}
                color="primary"
                userselect="none"
              >
                + {t('teammates', { ns: 'common' })}
              </TkTypography>
            </Selector>
          )}
        </BaseGrid>
        <DividerCustom />
        <BaseGrid container direction="column">
          <TkTypography
            fontWeight="bold"
            fontFamily="Muli"
            fontSize={16}
            userselect="none"
          >
            {t('guidance_notes', { ns: 'common' })}
          </TkTypography>
          <FormGroup>
            <TkNotesField
              value={notes}
              variant="standard"
              placeholder={`${t('leaveANoteToYourTeammates', {
                ns: 'events',
              })}`}
              helperText={`${notes.length}/500`}
              onChange={(e) => setNotes(e.target.value)}
              fullWidth
              inputProps={{
                maxLength: 500,
              }}
              multiline
            />
          </FormGroup>
        </BaseGrid>
        <BaseGrid container direction="column">
          <TkTypography fontWeight="bold" fontFamily="Lato" fontSize={16}>
            {t('guidance_images', { ns: 'common' })}
          </TkTypography>
          <TkDragNDrop
            images={[]}
            removeUploadedImage={() => null}
            newImages={imagesFile}
            addImage={addImageFile}
            removeImage={removeImageFile}
          />
        </BaseGrid>
      </TkTaskWrapper>
      <TkNewTaskActions>
        <DynamicButton
          color="primary"
          disableElevation
          onClick={handleCloseTask}
          style={{ justifyContent: 'flex-start' }}
        >
          {t('cancel', { ns: 'common' })}
        </DynamicButton>
        <DynamicButton
          variant="contained"
          bgcolor="primary"
          disableElevation
          onClick={showModalAlert}
        >
          {t('create', { ns: 'common' })}
        </DynamicButton>
      </TkNewTaskActions>
    </>
  );
};

export default TkNewTaskDrawer;
