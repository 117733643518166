import React from "react";
import { Map, GoogleApiWrapper } from "google-maps-react";

interface IMapLocation {
  longitude: number;
  latitude: number;
  width?: string;
  height?: string;
  google: any;
}

const TkGoogleMap: React.FC<IMapLocation> = ({
  latitude,
  longitude,
  google,
  height = "100%",
  width = "100%",
}) => {
  const containerStyle = {
    position: "relative",
    minWidth: width,
    minHeight: height,
    borderRadius: "14px",
  };

  return (
    <Map
      containerStyle={containerStyle}
      google={google}
      initialCenter={{
        lat: latitude,
        lng: longitude,
      }}
      zoom={15}
      draggable={false}
      disableDefaultUI={false}
      panControl={false}
      zoomControl={false}
      scaleControl={false}
      fullscreenControl={false}
      keyboardShortcuts={false}
      disableDoubleClickZoom={false}
      mapTypeControl={false}
      streetViewControl={false}
      clickableIcons={false}
    />
  );
};

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY ?? "",
})(TkGoogleMap);
