import { useHistory } from 'react-router-dom';
import { useChatContext } from 'stream-chat-react';

import { CrewMember } from '@components/TkTeams/types';

interface StartChat {
  handleStartChat: (
    memberChatUserId: string | string[],
    chatMember?: CrewMember[] | CrewMember,
    channelID?: string,
    channelName?: string
  ) => Promise<void>;
}

type ChatMember = CrewMember;

export const useStartChat = (): StartChat => {
  const { client, setActiveChannel } = useChatContext();
  const history = useHistory();
  async function handleStartChat(
    memberChatUserId: string | string[],
    chatMember?: ChatMember[] | ChatMember,
    channelID?: string,
    channelName?: string
  ) {
    const members = Array.isArray(memberChatUserId)
      ? [...memberChatUserId, client.userID]
      : [memberChatUserId, client.userID];

    try {
      if (chatMember && Array.isArray(chatMember)) {
        await Promise.allSettled(
          chatMember.map(async (member) => {
            createChatUser(member);
          })
        );
      } else {
        createChatUser(chatMember as ChatMember);
      }

      const conversation = await client.channel('messaging', channelID, {
        name: channelName,
        members,
      });

      await conversation.create();

      setActiveChannel?.(conversation);
      history.push(`/chat?channel=${conversation.data.id}`);
    } catch (error) {
      console.log('[Chat] Error creating channel', error?.message);
    }
  }

  const createChatUser = async (member: ChatMember) => {
    try {
      await client.upsertUser({
        id: `${member.chat_user_id}`,
        name: member.full_name,
        role: 'admin',
        image: member.photo_url,
      });
    } catch (err) {
      console.log('[Chat] An error occurred while creating a chat user', err);
    }
  };

  return {
    handleStartChat,
  };
};
