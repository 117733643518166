import { selectorFamily } from "recoil";
import { eventCustomer, searchLocationsData } from "@recoilData/index";

const searchEventLocationsData = selectorFamily({
  key: "searchEventLocationsData",
  get: (search: string) => ({ get }) => {
    const locations = get(searchLocationsData(search));
    const selectedEventCustomer = get(eventCustomer);
    if (selectedEventCustomer === undefined) return locations;
    return locations.filter(
      (location) => location.customerId === selectedEventCustomer.id
    );
  },
});

export { searchEventLocationsData };
