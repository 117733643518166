import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TkTypography } from '@components/index';
import {
  PhoneNumberInput,
  PhoneNumberTextSelect,
  TkSelectFlag,
} from './styles';
import { Grid } from '@mui/material';

interface ITkPhoneInputNumberWithFlag {
  phoneNumber: string;
  setPhoneNumber: React.Dispatch<React.SetStateAction<string>>;
  isError?: boolean;
  Button?: React.FC;
  mt?: number;
  errorMessage?: string;
  errorMessagePosition?: string;
}

const TkPhoneInputNumberWithFlag: React.FC<ITkPhoneInputNumberWithFlag> = ({
  phoneNumber,
  setPhoneNumber,
  isError = false,
  mt = 0,
  Button,
  errorMessage,
  errorMessagePosition,
}) => {
  const { t } = useTranslation();
  const backgroundColor = !Button ? '#ffffff' : '#f5f6fb';
  const inputStyle = {
    backgroundColor,
    width: !Button ? '100%' : '',
  };
  const [isValidPhone, setIsValidPhone] = useState(true);

  const onChange = (phone, country) => {
    setPhoneNumber(phone);
    const validPhone = phone.startsWith(country.dialCode);
    setIsValidPhone(validPhone);
  };

  return (
    <>
      <PhoneNumberInput mt={mt}>
        <PhoneNumberTextSelect
          className="phoneNumberTextSelect"
          style={inputStyle}
        >
          <TkSelectFlag
            country={'us'}
            isValid={!isError}
            value={phoneNumber}
            inputStyle={inputStyle}
            inputClass={'inputStyle'}
            buttonClass={'buttonStyle'}
            dropdownClass={'countryList'}
            buttonStyle={{ backgroundColor }}
            onChange={onChange}
          />
        </PhoneNumberTextSelect>
        {Button && <Button />}
      </PhoneNumberInput>
      {isError ? (
        <Grid container style={{ marginTop: 1.25 }}>
          <TkTypography fontSize={'12px'} color={'#FF3B30'}>
            {errorMessage ||
              t('pleaseEnterYourPhoneVerification', {
                ns: 'phoneVerification',
              })}
          </TkTypography>
        </Grid>
      ) : null}
      {!isValidPhone && (
        <Grid container style={{ marginTop: 1.25 }}>
          <TkTypography fontSize={'12px'} color={'#FF3B30'}>
            {t('pleaseEnterAValidPhoneNumber', { ns: 'phoneVerification' })}
          </TkTypography>
        </Grid>
      )}
    </>
  );
};

export default TkPhoneInputNumberWithFlag;
