import React from 'react';

import styled from 'styled-components';

export const MainContainer = styled.div`
  position: relative;
  overflow-x: hidden;
  height: calc(100vh - 64px);
`;

export const Container = styled.div<{
  direction?: React.CSSProperties['flexDirection'];
}>`
  position: relative;
  background-color: #fff;
  opacity: 1;
  display: flex;
  flex: 1;
  flex-direction: ${(props) => props.direction ?? 'column'};
  width: 100%;
  overflow: hidden;
  height: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
