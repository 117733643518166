export default {
  title: 'Messages',
  sendAMessage: 'Send a message',
  suggestionNewChat: 'Type a name for suggestions',
  startChat: 'Start chat',
  chat: 'Chat',
  deleteChat: 'Delete chat',
  emptyStateChannelMessage:
    'Select a contact on the left to view messages or start a new chat',
  searchBarPlaceHolder: 'Search messages...',
  info: 'Info',
  header: {
    tooltip: 'Edit chat name',
  },
  editMessagesMenu: {
    reply: 'Reply',
    copy: 'Copy Message',
    editMessage: 'Edit Message',
    deleteMessage: 'Delete Message',
  },
  dropDown: {
    seeAllMessages: 'See all messages',
    emptyState1: 'No new messages',
    emptyState1Message: 'Start a chat',
    emptyState2:
      "Let's get the conversation going!<0/>Invite your teammates to chat.",
    inviteTeammates: 'Invite teammates',
  },
  memberInfo: {
    taskInProgressSince: 'Task in progress since: {{time}}',
    numberTasksInProgress: '{{number}} tasks in progress',
    taskInProgressSincePreviousDay: 'Task in progress since {{date}} at {{time}}',
    nextTaskStartsAt: 'Next task starts at {{time}}',
    nextTaskStartTomorrow: 'Next task tomorrow at {{time}}',
    nextTaskStartOnOtherDay: 'Next task {{date}} at {{time}}',
    noUpcomingTasks: 'No upcoming tasks',
  },
};
