import { Crew } from '@components/TkTeams/types';
import { checkedCrewsState } from '@recoilData/atoms';
import { useRecoilState } from 'recoil';

export const useCheckableCrews = () => {
  const [checkedCrews, setCheckedCrews] = useRecoilState(checkedCrewsState);

  const handleCheckCrew = (crew) => {
    if (checkedCrews.find((item) => item.id === crew.id)) {
      setCheckedCrews((prev) => prev.filter((item) => item.id !== crew.id));
    } else {
      setCheckedCrews([...checkedCrews, crew]);
    }
  };

  const handleCheckAllCrews = (checked: boolean, crews: Crew[]) => {
    if (checked) {
      setCheckedCrews([
        ...checkedCrews,
        ...crews.filter((crew) => {
          return !checkedCrews.find((item) => item.id === crew.id);
        }),
      ]);
    } else {
      setCheckedCrews([]);
    }
  };

  return {
    handleCheckAllCrews,
    handleCheckCrew,
    checkedCrews,
  };
};
