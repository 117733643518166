import { AppLocationURLs } from '@consts/index';
import {
  NotificationMeta,
  NotificationHandler,
} from '@hooks/notification/types';

const getRoute = () => ({
  url: AppLocationURLs.HOME,
  drawer: '',
});

const getAttributes = (notificationMetadata: NotificationMeta) => ({
  tasks: notificationMetadata.tasks,
  type: notificationMetadata.type,
});

export default {
  getRoute,
  getAttributes,
} as NotificationHandler;
