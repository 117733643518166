import React from 'react';

import { useTranslation } from 'react-i18next';

import TkIcon from '@components/TkIcon';
import { IconWrapperAlt } from '@components/TkTags/styles';
import TkTooltip from '@components/TkTooltip';

export const SameDayArrivalIcon = ({
  iconSize = 10,
}: {
  iconSize?: number;
}): JSX.Element => {
  const { t } = useTranslation();
  const containerSize = iconSize + 4;
  return (
    <TkTooltip
      style={{ display: 'flex' }}
      title={`${t('tkTags.sameDayArrival')}`}
    >
      <IconWrapperAlt
        color="invert"
        style={{ width: containerSize, height: containerSize }}
      >
        <TkIcon iconSize={iconSize} className="fa fa-exchange" />
      </IconWrapperAlt>
    </TkTooltip>
  );
};
