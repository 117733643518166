import { Grid } from '@mui/material';
import styled from 'styled-components';

export const Container = styled(Grid)`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 403px;
  padding: 5px 2px;
`;
